import { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionCard from '../../Cards/SubscriptionCard/SubscriptionCard';
import CheckoutForm from '../../Forms/CheckoutForm/CheckoutForm';
import CustomSelect from '../../Inputs/CustomSelect/CustomSelect';
import Label from '../../Typography/Label/Label';
import SolidButton from '../../Buttons/SolidButton/SolidButton';
import Price from '../../Typography/Price/Price';
import InfoBlock from '../../Blocks/InfoBlock/InfoBlock';
import {licenceTypes, rezzilPlayerLicenceOptions} from '../../../options/licenceOptions';
import {
  Container,
  PricePerLicence,
  LicenceContainer,
  SelectContainer,
  RowContainer,
  InfoText,
  TitleText,
  CountText,
  LicenceCount,
} from './SubscriptionModal.styles';
import { squadDeviceOptions, squadPlayerOptions } from '../../../options/squadLicenceOptions';
import { getSubscriptionPrice, setupSubscriptionHelper } from '../../../utils/helpers/subscriptionsHelper';


const SubscriptionModal = ({numberOfExitingLicences, stripeProducts}) => {
  const [selectedLicence, setSelectedLicence] = useState(licenceTypes.annualSub);
  const [numberOfLicences, setNumberOfLicences] = useState(0);
  const [clientSecret, setClientSecret] = useState();
  const [formattedSubscriptionPrice, setFormattedSubscriptionPrice] = useState();
  const [subscriptionPrice, setSubsriptionPrice] = useState();
  const [selectedSquadDevices, setSelectedSquadDevices] = useState(5);
  const [selectedSquadPlayers, setSelectedSquadPlayers] = useState(50);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const selectedLicenceDetails = rezzilPlayerLicenceOptions.find((licence) => licence.title === selectedLicence);

  useEffect(() => {
    // Calculate Number of licences needed for the selected squad players and devices
    // 1 licence = 5 squad devices and 50 squad players
    const newLicencesNeeded = Math.max(
      selectedSquadDevices / 5,
      selectedSquadPlayers / 50
    );
    setNumberOfLicences(newLicencesNeeded);
  }, [selectedSquadPlayers, selectedSquadDevices]);

  useEffect(() => {
    const subscriptionCost = getSubscriptionPrice(stripeProducts, 'squadspro', selectedLicenceDetails.billingPeriod);
    setFormattedSubscriptionPrice(`${subscriptionCost.currencySymbol}${subscriptionCost.price * numberOfLicences}`);
  }, [numberOfLicences, selectedLicenceDetails, stripeProducts])

  const setupSubscription = async () => {
    try {
      const { clientSecret, subscriptionPrice } = await setupSubscriptionHelper('squadspro', selectedLicenceDetails.billingPeriod, numberOfLicences);
      setSubsriptionPrice(subscriptionPrice)
      setClientSecret(clientSecret);
      // setLoading(false);
    } catch (error) {
      console.log('error');
      console.log(error);
    }
  }

  return (<>
    {!clientSecret &&
    <Container>
    {
      !numberOfExitingLicences &&
      <>
        <LicenceContainer>
          {rezzilPlayerLicenceOptions.map(
            ({ id, avatarBgColor, icon, title, features, isRecommended, billingPeriod, licenceId }) =>
            <SubscriptionCard
              key={id}
              avatarBgColor={avatarBgColor}
              icon={icon}
              title={title}
              features={
                features.map(
                  (feature) =>
                    feature.replace(
                      '{formattedPrice}',
                      getSubscriptionPrice(stripeProducts, licenceId, billingPeriod).formatted
                    )
                )
              }
              isRecommended={isRecommended}
              onClick={() => setSelectedLicence(title)}
              isSelected={selectedLicence === title}
            />
          )}
        </LicenceContainer>
      <PricePerLicence>* Price per licence</PricePerLicence>
      </>
    }

    {numberOfExitingLicences && <>
      <RowContainer>
        <LicenceCount>
          <TitleText>Current number of licences</TitleText>
          <CountText>{numberOfExitingLicences}</CountText>
        </LicenceCount>

        <InfoBlock noMargin info={['1 licence = 5 Squad Leaders and 50 Squad Players']} />
      </RowContainer>

    </>
    }

    <RowContainer>
      <SelectContainer>
        <Label
          text={'Squad Devices'}
          required
        />
        <CustomSelect
          name={`squad-devices`}
          placeholder={'Select option'}
          onChange={(event) => {
            setSelectedSquadDevices(event.target.value);
            setSelectedSquadPlayers(event.target.value * 10)
          }}
          value={selectedSquadDevices}
          options={squadDeviceOptions}
        />
        <InfoText>
          Total number of licences
        </InfoText>
        <Price price={numberOfLicences.toString()} />
      </SelectContainer>
      <SelectContainer>
        <Label
          text={'Squad Players'}
          required
        />
        <CustomSelect
          name={`squad-players`}
          placeholder={'Select option'}
          onChange={(event) => {
            setSelectedSquadPlayers(event.target.value);
            setSelectedSquadDevices(event.target.value / 10);
          }}
          value={selectedSquadPlayers}
          options={squadPlayerOptions}
        />
        <InfoText>
          Total Cost
        </InfoText>
        <Price price={formattedSubscriptionPrice} />
      </SelectContainer>
    </RowContainer>
    <SolidButton
      text={'Proceed to payment'}
      onClick={setupSubscription}
    />
    </Container>
    }
    {
      clientSecret &&
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <CheckoutForm
          clientSecret={clientSecret}
          licence={selectedLicenceDetails}
          paymentAmount={subscriptionPrice}
          stripeProducts={stripeProducts}
        />
      </Elements>
    }
    </>
  )
}

export default SubscriptionModal;
