import {useEffect, useState, useCallback, createContext, useContext} from 'react';

import {routes} from '../../options/routes';
import { getClubPlayersList } from '../../utils/helpers/getClubPlayersList';
// API calls
import { getGlobalLeaderBoardStats, getClubPlayers } from '../../api';
// Contexts
import {BaseContext} from '../Global/BaseProvider';
import { formatGlobalLeaderBoardStats } from '../../utils/helpers/leaderboardHelpers';

const GlobalLeaderBoardsContext = createContext({});

const GlobalLeaderBoardsProvider = ({children}) => {
  const baseContext = useContext(BaseContext);
  const [globalLeaderBoardsData, setGlobalLeaderBoardsData] = useState({});

  const getGlobalLeaderBoardsData = useCallback(async () => {
    // Only trigger API call for globalLeaderBoards route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.indexGlobalLeaderBoards && activeRoute !== routes.rezzilPlayerGlobalLeaderBoards ) return;
    // Call API
    if (!baseContext.baseData.loading && !globalLeaderBoardsData.loading) {
      setGlobalLeaderBoardsData({
        loading: true,
      })
      const { id } = baseContext.baseData.clubProfile;
      const playerProfilesData = await getClubPlayers(id);
      const globalLeaderBoardsData = await getGlobalLeaderBoardStats();
      if (
        !globalLeaderBoardsData ||
        !globalLeaderBoardsData.success ||
        !globalLeaderBoardsData.data ||
        !playerProfilesData ||
        !playerProfilesData.success ||
        !playerProfilesData.data
      ) {
        setGlobalLeaderBoardsData({
          loading: false,
          globalLeaderBoardStats: null,
          playerProfiles: null,
          apiError: true,
          allLoaded: true,
        });

        return;
      }
      const { profiles } = playerProfilesData.data;
      const { leaderboards } = globalLeaderBoardsData.data;
      const playerProfiles = getClubPlayersList(profiles, true);
      const globalLeaderBoardStats = formatGlobalLeaderBoardStats(leaderboards);
      // Set data
      setGlobalLeaderBoardsData({
        loading: false,
        globalLeaderBoardStats,
        playerProfiles,
        apiError: false,
        allLoaded: true,
      });
    }

  }, [baseContext]);

  useEffect(() => {
    getGlobalLeaderBoardsData();
  }, [getGlobalLeaderBoardsData]);

  return (
    <GlobalLeaderBoardsContext.Provider
      value={{
        globalLeaderBoardsData,
        getGlobalLeaderBoardsData
      }}
    >
      {children}
    </GlobalLeaderBoardsContext.Provider>
  );
}

export {GlobalLeaderBoardsProvider, GlobalLeaderBoardsContext};

export default GlobalLeaderBoardsProvider;
