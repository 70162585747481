import {
  StyledLink,
  LinkContentContainer
} from './SubscriptionNavLink.styles';
import { Link as RouterLink } from 'react-router-dom';

const SubscriptionNavLink = ({ icon, name, route, isExternal, active, disabled }) => {
  return (
    <StyledLink
      component={RouterLink}
      to={isExternal ? { pathname: route } : route}
      target={isExternal ? '_blank' : undefined}
      active={active ? 'true' : undefined}
      disabled={disabled ? 'true' : undefined}
    >
      <LinkContentContainer>
        {icon}
        {name}
      </LinkContentContainer>
    </StyledLink>
  );
};

export default SubscriptionNavLink;
