import {
  styled,
  Card,
} from "@mui/material";

export const StyledCard = styled(Card)(({ theme, isrow }) => ({
  display: 'flex',
  flexDirection: isrow ? 'row' : 'column',
  padding: 20,
  background: 'rgba(0, 0, 0, 0.4)',
  borderRadius: 5,
  boxShadow: 'none',
  backdropFilter: 'blur(20px)',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  '@media print': {
    background: 'none',
  },
}));
