import {
  Container,
  BannerContainer,
} from './AuthenticationWrapper.styles';
import Banner from '../../Alerts/Banner/Banner';

const AuthenticationWrapper = ({
  children,
  error,
  apiMessage,
  handleBannerClose,
  hideClose,
  showBtn,
  btnText,
  onClick
}) => {
  return (
    <Container>
      {
        apiMessage &&
        <BannerContainer>
          <Banner
            onClose={handleBannerClose}
            open={!!apiMessage}
            text={apiMessage}
            isSuccess={!error}
            isFixed
            showClose={!hideClose}
            showBtn={showBtn}
            btnText={btnText}
            onClick={onClick}
          />
        </BannerContainer>
      }
      {children}
    </Container>
  );
};

export default AuthenticationWrapper;
