import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({
  marginBottom: 26,
  textAlign: 'center',
}));

export const StyledTitle = styled(Typography)(({ theme, recommended }) => ({
  ...theme.typography.h2,
  color: recommended ? colors.primaryOrange : colors.white,
}));
