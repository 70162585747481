import {useEffect, useState, useCallback, createContext, useContext} from 'react';
import {routes} from '../../options/routes';

// Helpers
import {
  getBestPlayerStats,
  getClubOverallIndexStats,
  getClubTopTenPlayersStats,
  getClubBestDrills,
} from '../../utils/helpers/indexPanelHelpers';

// API calls
import {
  getBoardsRezzilIndex,
  getBoardsClubDrills,
  getClubPlayerProfile,
} from '../../api';

// Contexts
import {BaseContext} from '../Global/BaseProvider';

const PanelContext = createContext({});

const PanelProvider = ({children}) => {
  const baseContext = useContext(BaseContext);
  const [panelData, setPanelData] = useState({
    loading: true,
    bestPlayer: null,
    overallIndex: null,
    topTenPlayers: null,
    bestDrills: null,
    apiError: false,
  });

  const getPanelData = useCallback(async () => {
    // Only trigger API call for index panel route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.indexPanel) return;

    if (!baseContext.baseData.loading) {
      const {id} = baseContext.baseData.clubProfile;
      // Call Rezzil Index leaderboard API
      const clubRezzilIndexData = await getBoardsRezzilIndex(id);
      if (!clubRezzilIndexData || !clubRezzilIndexData.success || clubRezzilIndexData.code !== 200) {
        setPanelData({
          loading: false,
          bestPlayer: null,
          overallIndex: null,
          topTenPlayers: null,
          bestDrills: null,
          apiError: true,
        });
        return;
      }
      // Call club best drills API
      const clubDrillsData = await getBoardsClubDrills(id);
      if (!clubDrillsData || !clubDrillsData.success || clubDrillsData.code !== 200) {
        setPanelData({
          loading: false,
          bestPlayer: null,
          overallIndex: null,
          topTenPlayers: null,
          bestDrills: null,
          apiError: true,
        });
        return;
      }
      // Destructure relevant params from data
      const {scores} = clubRezzilIndexData.data.leaderboard;
      const {leaderboards, worldAverages} = clubDrillsData.data;
      // Get and set best player profile data
      let bestPlayerProfile = {};
      if (scores.length > 0) {
        const {id} = scores[0].profile;
        const bestPlayerData = await getClubPlayerProfile(id);
        if (bestPlayerData && bestPlayerData.success && bestPlayerData.code === 200)
          bestPlayerProfile = bestPlayerData.data.profile;
      }

      const bestPlayer = getBestPlayerStats(scores, bestPlayerProfile);
      const overallIndex = getClubOverallIndexStats(scores);
      const topTenPlayers = getClubTopTenPlayersStats(scores);
      const bestDrills = getClubBestDrills(leaderboards, worldAverages);
      setPanelData({
        loading: false,
        bestPlayer,
        overallIndex,
        topTenPlayers,
        bestDrills,
        apiError: false,
      });
    }
  }, [baseContext]);

  useEffect(() => {
    getPanelData();
  }, [getPanelData]);

  return (
    <PanelContext.Provider
      value={{
        panelData,
        getPanelData
      }}
    >
      {children}
    </PanelContext.Provider>
  );
}

export {PanelProvider, PanelContext};

export default PanelProvider;
