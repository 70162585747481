import {useState} from 'react';
import {
  ClubsContainer,
  IconsContainer,
} from './ClubsList.styles';
import {routes} from '../../../options/routes';
import {buttonTypes} from '../../../options/buttonTypes';
import SectionContainer from '../../../components/Containers/SectionContainer/SectionContainer';
import ClubsDetails from '../../ClubsPage/ClubsDetails/ClubsDetails';
import IconButton from '../../../components/Buttons/IconButton/IconButton';
import LinkButton from '../../Buttons/LinkButton/LinkButton';
import NoResultsText from '../../../components/Typography/NoResultsText/NoResultsText';
import StandardModal from '../../Modals/StandardModal/StandardModal';
import ClubForm from '../../Forms/ClubForm/ClubForm';
import {EditOutlined} from '@mui/icons-material';

const ClubsList = ({
  clubs,
  onFieldChange,
  onSubmit,
  formData,
  formErrors,
  setFormData,
  setFormErrors,
  updateLoading,
}) => {
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [managingClub, setManagingClub] = useState(localStorage.getItem('manageClubId'));
  const unManageClub = () => {
    setManagingClub(undefined);
    localStorage.removeItem('manageClubId');
    localStorage.removeItem('manageClubProfile');
  }

  const setManageClub = (clubId) => {
    unManageClub();
    localStorage.setItem('manageClubId', clubId);
    setManagingClub(clubId);
    window.location.href = routes.home;
  }

  const handleFormModalClose = () => {
    // Reset state back to original values
    setFormModalOpen(false);
    setIsAdd(true);
    setFormData({
      id: undefined,
      name: '',
      contact: '',
      phone: '',
      location: '',
      indexLicenceCount: '',
      indexLicenceDiscount: '',
      analysisLicenceCount: '',
      analysisLicenceDiscount: '',
      dbLicenceCount: '',
      dbLicenceDiscount: '',
      rezzilPlayerLicenceCount: '',
      rezzilPlayerLicenceDiscount: '',
      salesRep: '',
      revenue: '',
      link: '',
    });
    setFormErrors({
      name: '',
      contact: '',
      phone: '',
      location: '',
      indexLicenceCount: '',
      indexLicenceDiscount: '',
      analysisLicenceCount: '',
      analysisLicenceDiscount: '',
      dbLicenceCount: '',
      dbLicenceDiscount: '',
      rezzilPlayerLicenceCount: '',
      rezzilPlayerLicenceDiscount: '',
      salesRep: '',
      revenue: '',
      link: '',
    });
  };

  const handleFormModalOpen = id => {
    if (id) {
      // Find index of item with matching id and pass those details into modal
      const index = clubs.findIndex(el => el.id === id);
      setFormData(clubs[index]);
      setIsAdd(false);
    }
    setFormModalOpen(true);
  };

  const handleFormModalSubmit = async () => {
    const result = await onSubmit();
    if (result) {
      handleFormModalClose();
    }
  };

  return (
    <SectionContainer
      showBtn
      btnType={buttonTypes.link}
      btnText={'Add club'}
      onClick={() => handleFormModalOpen()}
      caption={'Club not listed?'}
    >
      {
        clubs && clubs.length > 0 && clubs.map(club => (
          <ClubsContainer
            key={club.id}
          >
            <ClubsDetails
              club={club}
              showLicenceInfo
            />
            <IconsContainer>
              <IconButton
                icon={<EditOutlined/>}
                onClick={() => handleFormModalOpen(club.id)}
              />
              {

              }
              {
                managingClub === club.id &&
                <LinkButton
                  text={'Unmanage Club'}
                  onClick={() => {
                    unManageClub();
                    window.location.reload();
                  }}
                />
              }
              {
                managingClub !== club.id &&
                <LinkButton
                  text={'Manage Club'}
                  onClick={() => setManageClub(club.id)}
                />
              }
            </IconsContainer>
          </ClubsContainer>
        ))
      }
      {
        (!clubs || clubs.length === 0) &&
        <NoResultsText
          text={'No Clubs to display'}
        />
      }
      {
        formModalOpen &&
        <StandardModal
          open={formModalOpen}
          onClose={handleFormModalClose}
          title={`${isAdd ? 'Add' : 'Edit'} Club`}
          btnText={`${isAdd ? 'Add' : 'Update'} Club`}
          onClick={handleFormModalSubmit}
          loading={updateLoading}
        >
          <ClubForm
            formData={formData}
            errors={formErrors}
            onChange={onFieldChange}
          />
        </StandardModal>
      }
    </SectionContainer>
  );
};

export default ClubsList;
