import {
  ClubLogoContainer,
} from './ClubLogoAvatar.styles';
import DefaultBadge from '../../../assets/images/default-badge.png';

const ClubLogoAvatar = ({clubLogo, small}) => {
  return (
    <ClubLogoContainer
      logo={clubLogo ? clubLogo : DefaultBadge}
      small={small ? 'true' : undefined}
    />
  );
};

export default ClubLogoAvatar;
