import {memo} from 'react';
import {
  StandardTable,
  StyledTeamMembersTable,
  StyledProfilesTable,
  StyledGroupsTable,
} from './MemoizedTable.styles';
import {tableParentOptions} from '../../../options/tableParentOptions';

const MemoizedTable = ({
  list,
  parentComponent,
  children,
}) => {

  const getParentComponent = () => {
    // Return correct table component based on parent component
    switch (parentComponent) {
      case tableParentOptions.teamMembers:
        return <StyledTeamMembersTable>{children}</StyledTeamMembersTable>;
      case tableParentOptions.playerProfiles:
        return <StyledProfilesTable>{children}</StyledProfilesTable>;
      case tableParentOptions.groups:
        return <StyledGroupsTable>{children}</StyledGroupsTable>;
      default:
        return <StandardTable>{children}</StandardTable>;
    }
  };

  return (
    <>
      {
        list.length > 0 && getParentComponent()
      }
    </>
  )
};

// Use memo HOC to only re-render component if change to the profiles array
const areEqual = (prevProps, nextProps) => prevProps.list === nextProps.list;

export default memo(MemoizedTable, areEqual);
