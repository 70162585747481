import {useEffect, useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  Container,
} from './SubscriptionPaymentForm.styles';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import SectionSpinner from '../../Loading/SectionSpinner/SectionSpinner';
import { setupSubscriptionHelper } from '../../../utils/helpers/subscriptionsHelper';


const SubscriptionPaymentForm = ({ licence }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState();
  const [subscriptionPrice, setSubsriptionPrice] = useState();

  const setupSubscription = async (licenceId, billingPeriod) => {
    try {
      const { clientSecret, subscriptionPrice } = await setupSubscriptionHelper(licenceId, billingPeriod, 1);
      setSubsriptionPrice(subscriptionPrice)
      setClientSecret(clientSecret);
      setLoading(false);
    } catch (error) {
      console.log('error');
      console.log(error);
    }
  }

  useEffect(() => {
    setupSubscription(licence.licenceId, licence.billingPeriod);
  }, [licence])

  return (
    <Container>
      {
        loading &&
        <SectionSpinner/>
      }
      {
        !loading && clientSecret &&
        <>
          <Elements stripe={stripePromise} options={{clientSecret}}>
            <CheckoutForm clientSecret={clientSecret} licence={licence} paymentAmount={subscriptionPrice} />
          </Elements>
        </>
      }
    </Container>
  );
};

export default SubscriptionPaymentForm;
