import { useEffect, useState, useCallback, createContext, useContext } from 'react';

import { routes } from '../../options/routes';
// API calls
import { getSquadMembers, getSquadPrimaryInfo } from '../../api';
// Contexts
import { BaseContext } from '../Global/BaseProvider';
import { getSquadLeaderBoardData } from '../../utils/helpers/leaderboardsHelpers';
import { getAPIData, hasAPIError } from '../../utils/helpers/apiHelper';
import { squadLeaderboardTableNames } from '../../options/squadLeaderboards';

const ClubLeaderBoardsContext = createContext({});

const ClubLeaderBoardsProvider = ({ children }) => {
  const baseContext = useContext(BaseContext);
  const [clubLeaderBoardsData, setClubLeaderBoardsData] = useState({});

  const getClubLeaderBoardsData = useCallback(async () => {
    // Only trigger API call for clubLeaderBoards route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.rezzilPlayerClubLeaderBoards) return;
    // Call API
    if (!baseContext.baseData.loading && !clubLeaderBoardsData.loading) {
      setClubLeaderBoardsData({
        loading: true,
      });
      const { id } = baseContext.baseData.clubProfile;
      const squadPrimaryInfoResponse = await getSquadPrimaryInfo();
      if (
        hasAPIError(squadPrimaryInfoResponse)
      ) {
        setClubLeaderBoardsData({
          loading: false,
          clubLeaderBoardStats: {},
          squadMembers: [],
          apiError: true,
          allLoaded: true,
        });
        return;
      }
      const primarySquadInfo = getAPIData(squadPrimaryInfoResponse);
      if (!primarySquadInfo || (Array.isArray(primarySquadInfo) && !primarySquadInfo.length)) {
        setClubLeaderBoardsData({
          loading: false,
          clubLeaderBoardStats: {},
          squadMembers: [],
          apiError: false,
          allLoaded: true,
        });
        return;
      }

      const squadMembersResponse = await getSquadMembers(id);
      if (
        hasAPIError(squadMembersResponse)
      ) {
        setClubLeaderBoardsData({
          loading: false,
          clubLeaderBoardStats: {},
          squadMembers: [],
          apiError: true,
          allLoaded: true,
        });

        return;
      }
      const primarySquadId = primarySquadInfo.primaryId;
      const { squadMembers } = getAPIData(squadMembersResponse);
      const leaderBoards = [];
      const PRELOADAED_TABLES = 10;
      const formattedSquadMembers = squadMembers.map((member) => {
        return {
          ...member,
          id: `${primarySquadId}...${member.name}`
        }
      })
      for (let i = 0; i < PRELOADAED_TABLES; i++) {
        const leaderboardData = await getSquadLeaderBoardData({
          playerChoicesIds: [formattedSquadMembers[0].id],
          leaderboardName: squadLeaderboardTableNames[i],
          squadId: primarySquadId,
        });
        leaderBoards.push(leaderboardData);
      }

      // Set data
      setClubLeaderBoardsData({
        loading: false,
        numberOfTablesPreLoaded: PRELOADAED_TABLES,
        leaderBoards,
        squadMembers: formattedSquadMembers,
        apiError: false,
        allLoaded: true,
        primarySquadId,
      });
    }

  }, [baseContext]);

  useEffect(() => {
    getClubLeaderBoardsData();
  }, [getClubLeaderBoardsData]);

  return (
    <ClubLeaderBoardsContext.Provider
      value={{
        clubLeaderBoardsData,
        getClubLeaderBoardsData
      }}
    >
      {children}
    </ClubLeaderBoardsContext.Provider>
  );
}

export { ClubLeaderBoardsProvider, ClubLeaderBoardsContext };

export default ClubLeaderBoardsProvider;
