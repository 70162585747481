import {useState, useEffect} from 'react';
import {
  StyledMainModal,
  ModalHeader,
  ModalContent,
  ModalActions,
  CloseButton,
  CloseIcon,
  StyledNestedModal,
  NestedModalTitle,
  NestedModalText,
} from './NestedModal.styles';
import ModalTitle from '../../Typography/ModalTitle/ModalTitle';
import LinkButton from '../../Buttons/LinkButton/LinkButton';
import OutlinedButton from '../../Buttons/OutlinedButton/OutlinedButton';
import SectionSpinner from '../../Loading/SectionSpinner/SectionSpinner';
import { shoeSizeTypeOptions } from '../../../options/playerOptions';

const getShoeSizeLabel = (shoeSizeType, shoeSize) => {
  if (!shoeSizeType) {
    return 'Warning - Shoe Size Type not selected.'
  }
  if (!shoeSize) {
    return 'Warning - Shoe Size not selected.'
  }
  return `${shoeSizeTypeOptions.find((option) => option.value === shoeSizeType).label}: ${shoeSize}`;
}

const NestedModal = ({ children, openMain, onMainClose, title, btnText, onClick, loading, openNested, setNestedClose, shoeSize, shoeSizeType }) => {
  const [show, setShow] = useState(openMain);
  const [showNested, setShowNested] = useState(openNested);

  const handleMainModalClose = () => {
    setShow(false);
    onMainClose();
  };

  const handleNestedModalClose = () => {
    setShowNested(false);
    setNestedClose(false);
  };

  useEffect(() => {
    if (openNested) setShowNested(true);
  }, [openNested]);

  return (
    <>
      <StyledMainModal
        open={show}
        scroll={'body'}
      >
        <ModalHeader>
          <ModalTitle
            text={title}
          />
          <CloseButton
            aria-label='close'
            onClick={handleMainModalClose}
          >
            <CloseIcon/>
          </CloseButton>
        </ModalHeader>
        <ModalContent>
          {children}
        </ModalContent>
        <ModalActions>
          {
            loading &&
            <SectionSpinner/>
          }
          {
            !loading &&
            <>
              <LinkButton
                text={'Cancel'}
                onClick={handleMainModalClose}
                isReset
              />
              <OutlinedButton
                text={btnText}
                onClick={onClick}
              />
            </>
          }
        </ModalActions>
      </StyledMainModal>
      {
        showNested &&
        <StyledNestedModal
          open={showNested}
          scroll={'body'}
        >
          <ModalHeader>
            <ModalTitle
              text={'Confirmation'}
            />
            <CloseButton
              aria-label='close'
              onClick={handleNestedModalClose}
            >
              <CloseIcon/>
            </CloseButton>
          </ModalHeader>
          <ModalContent>
            <NestedModalTitle>
              Shoe Size
            </NestedModalTitle>
            <NestedModalText>
                {getShoeSizeLabel(shoeSizeType, shoeSize)}
            </NestedModalText>
            <NestedModalText>
              Please check you have entered the correct shoe size
            </NestedModalText>
          </ModalContent>
          <ModalActions>
            <OutlinedButton
              text={'Close'}
              onClick={handleNestedModalClose}
            />
          </ModalActions>
        </StyledNestedModal>
      }
    </>
  );
};

export default NestedModal;
