import {createHash} from 'crypto-browserify';

export const createColorHex = name => {
  // Convert name into a hash and return a hex value from the first 6 chars of the hash
  const hash = createHash('sha256').update(name).digest('hex');
  return `#${hash.substring(0, 6)}`;
};

export const createColorRgba = name => {
  // Convert name into a hash and return a rbga value the first 6 chars of the hash with a 0.6 opacity
  const hex = createColorHex(name);
  const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, 0.6)`;
};
