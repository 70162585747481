import { useState, useEffect } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const getBreakdownDimensions = (windowWidth, dataLength) => {
  // Set maxItems, itemWidth, containerWidth and showArrows based on window width
  let maxItems, widthToDeduct, itemWidth;

  // Set dynamic widths based on dataLength
  if (windowWidth < 1520 && windowWidth >= 1350) {
    maxItems = dataLength === 5 ? 4 : dataLength;
    widthToDeduct = dataLength === 1 ? 580 : 610;
    itemWidth = (windowWidth - widthToDeduct) / maxItems;
  } else if (windowWidth < 1350 && windowWidth >= 1230) {
    maxItems = dataLength;
    widthToDeduct = dataLength === 1 ? 290 : 330;
    itemWidth = (windowWidth - widthToDeduct) / maxItems;
  } else if (windowWidth < 1230 && windowWidth >= 1040) {
    maxItems = dataLength === 5 ? 4 : dataLength;
    widthToDeduct = dataLength === 1 ? 290 : 320;
    itemWidth = (windowWidth - widthToDeduct) / maxItems;
  } else if ((windowWidth < 1040 && windowWidth >= 850)) {
    maxItems = dataLength > 3 ? 3 : dataLength;
    widthToDeduct = dataLength === 1 ? 290 : 310;
    itemWidth = (windowWidth - widthToDeduct) / maxItems;
  } else if ((windowWidth < 850 && windowWidth >= 750)) {
    maxItems = dataLength > 2 ? 2 : dataLength;
    widthToDeduct = dataLength === 1 ? 290 : 300;
    itemWidth = (windowWidth - widthToDeduct) / maxItems;
  } else if (windowWidth < 750 && windowWidth >= 640) {
    maxItems = dataLength > 3 ? 3 : dataLength;
    widthToDeduct = dataLength === 1 ? 80 : 100;
    itemWidth = (windowWidth - widthToDeduct) / maxItems;
  } else if (windowWidth < 640 && windowWidth >= 450) {
    maxItems = dataLength > 2 ? 2 : dataLength;
    widthToDeduct = dataLength === 1 ? 80 : 90;
    itemWidth = (windowWidth - widthToDeduct) / maxItems;
  } else if (windowWidth < 450) {
    maxItems = 1;
    widthToDeduct = 80;
    itemWidth = (windowWidth - widthToDeduct) / maxItems;
  } else {
    maxItems = dataLength;
    widthToDeduct = dataLength === 1 ? 580 : 620;
    itemWidth = (windowWidth - widthToDeduct) / maxItems;
  }

  const containerWidth = (itemWidth * dataLength) + (dataLength > 1 ? 50 : 0);
  const showArrows = maxItems < dataLength;

  return {
    maxItems,
    itemWidth,
    containerWidth,
    showArrows,
  };
};

const getDimensionsData = dataLength => {
  // Add breakdown dimensions to windowDimensions object
  const windowDimensions = getWindowDimensions();
  const breakdownDimensions = getBreakdownDimensions(windowDimensions.width, dataLength);
  return {
    ...windowDimensions,
    ...breakdownDimensions,
  };
};

const useDimensions = data => {
  // Ignore averages from data
  const [dataLength] = useState(Object.keys(data).length - 1);
  const [dimensions, setDimensions] = useState(getDimensionsData(dataLength));

  useEffect(() => {
    const handleResize = () => {
      const dimensions = getDimensionsData(dataLength);
      setDimensions(dimensions);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dataLength]);

  return dimensions;
};

export default useDimensions;
