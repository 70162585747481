import {useEffect, useState, useCallback, createContext, useContext} from 'react';
import {routes} from '../../options/routes';

// API calls
import {
  getSquadCode,
  getSquadDevices,
  getSquadMembers,
  getClubLicences,
  getSquadPrimaryInfo,
  getProducts,
} from '../../api';

// Contexts
import {BaseContext} from '../Global/BaseProvider';
import { getAPIData, hasAPIError } from '../../utils/helpers/apiHelper';
import { getSpecificLicences } from '../../utils/helpers/subscriptionsHelper';

const ManageUsersContext = createContext({});

const ManageUsersProvider = ({children}) => {
  const baseContext = useContext(BaseContext);
  const [manageUsersData, setManageUsers] = useState({
    loading: true,
    squadCode: null,
    squadDevices: [],
    squadMembers: [],
    apiError: false,
    primarySquad: null,
  });

  const getManageUsersData = useCallback(async () => {
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.rezzilPlayerManageUsers) return;

    if (!baseContext.baseData.loading) {
      const { id } = baseContext.baseData.clubProfile;



      const primarySquadInfoRequest = await getSquadPrimaryInfo();

      if (hasAPIError(primarySquadInfoRequest)) {
        setManageUsers({
          loading: false,
          apiError: true,
          squadCode: null,
          squadDevices: [],
          squadMembers: [],
          rezzilPlayerLicences: 0,
          primarySquad: null,
        });
        return;
      }
      const products = await getProducts(id);

      const primarySquadInfo = getAPIData(primarySquadInfoRequest);
      const squadCodeResponse = await getSquadCode(id);
      const clubLicencesResponse = await getClubLicences(id);
      const numberOfRezzilPlayerLicences = getSpecificLicences(getAPIData(clubLicencesResponse), 'squadspro');
      if (!primarySquadInfo || (Array.isArray(primarySquadInfo) && !primarySquadInfo.length)) {
        // There is no primary squad info to get so stop here..
        setManageUsers({
          loading: false,
          apiError: false,
          squadCode: getAPIData(squadCodeResponse).code,
          squadDevices: [],
          squadMembers: [],
          rezzilPlayerLicences: numberOfRezzilPlayerLicences.length,
          primarySquad: null,
        });
        return;
      }
      const squadMembersResponse = await getSquadMembers(id);
      const squadDevicesResponse = await getSquadDevices(id);


      if (hasAPIError(squadCodeResponse) ||
        hasAPIError(squadDevicesResponse) ||
        hasAPIError(squadMembersResponse) ||
        hasAPIError(clubLicencesResponse)) {
        setManageUsers({
          loading: false,
          apiError: true,
          squadCode: null,
          squadDevices: [],
          squadMembers: [],
          rezzilPlayerLicences: 0,
          primarySquad: null,
        });
        return;
      }

      setManageUsers({
        loading: false,
        apiError: false,
        squadCode: getAPIData(squadCodeResponse).code,
        squadDevices: getAPIData(squadDevicesResponse).squadDevices,
        squadMembers: getAPIData(squadMembersResponse).squadMembers,
        rezzilPlayerLicences: numberOfRezzilPlayerLicences.length,
        primarySquad: primarySquadInfo.name,
        products: getAPIData(products),
      });

    }
  }, [baseContext]);

  useEffect(() => {
    getManageUsersData();
  }, [getManageUsersData]);

  return (
    <ManageUsersContext.Provider
      value={{
        manageUsersData,
        getManageUsersData
      }}
    >
      {children}
    </ManageUsersContext.Provider>
  );
}

export {ManageUsersProvider, ManageUsersContext};

export default ManageUsersProvider;
