import {
  StyledInput,
  StyledLabel,
} from './CustomFileInput.styles';
import OutlinedButton from '../../Buttons/OutlinedButton/OutlinedButton';

const CustomFileInput = ({id, btnText, onChange}) => {
  return (
    <StyledLabel htmlFor={id}>
      <StyledInput
        style={{ display: 'none' }}
        // accept={'image/png, image/jpg'}
        accept={'image/*'}
        id={id}
        type='file'
        onChange={onChange}
      />
      <OutlinedButton
        text={btnText}
        fileInput
      />
    </StyledLabel>
  );
};

export default CustomFileInput;
