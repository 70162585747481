import {
  Container,
  TextContainer,
  LabelText,
  ValueText,
} from './IndexBreakdownStatRow.styles';

const IndexBreakdownStatRow = ({
  firstScoreLabel,
  firstScoreValue,
  secondScoreLabel,
  secondScoreValue,
  isAverage,
  rowWidth,
}) => {
  return (
    <Container
      average={isAverage ? 'true' : undefined}
      width={rowWidth}
    >
      <TextContainer>
        <LabelText>
          {firstScoreLabel}
        </LabelText>
        <ValueText>
          {firstScoreValue}
        </ValueText>
      </TextContainer>
      <TextContainer>
        <LabelText>
          {secondScoreLabel}
        </LabelText>
        <ValueText>
          {secondScoreValue}
        </ValueText>
      </TextContainer>
    </Container>
  );
};

export default IndexBreakdownStatRow;
