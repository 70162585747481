import {
  styled,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledBackdrop = styled(Backdrop)(() => ({
  zIndex: 100,
  background: 'rgba(0, 0, 0, 0.7)',
  backgroundColor: 'red',
}));

export const SpinnerContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
}));
export const StyledSpinner = styled(CircularProgress)(() => ({
  color: colors.primaryOrange,
}));
