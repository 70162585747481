import {useEffect, useState, useCallback} from 'react';
import {
  InnerContainer,
  StyledText,
  FieldContainer,
} from './ResetPassword.styles';
import {routes} from '../../../options/routes';
import AuthenticationWrapper from '../../../components/Wrappers/AuthenticationWrapper/AuthenticationWrapper';
import AuthFormContainer from '../../../components/Containers/AuthFormContainer/AuthFormContainer';
import CustomTextField from '../../../components/Inputs/CustomTextField/CustomTextField';
import OutlinedButton from '../../../components/Buttons/OutlinedButton/OutlinedButton';
import StandardLink from '../../../components/Links/StandardLink/StandardLink';
import SectionSpinner from '../../../components/Loading/SectionSpinner/SectionSpinner';

// Validators & helpers
import {passwordIsValid, repeatPasswordIsValid} from '../../../utils/validation/password';
import {getUrlLoginIDParam} from '../../../utils/helpers/getUrlParam';

// API calls
import {resetPassword} from '../../../api';

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
    confirmpassword: ''
  });
  const [errors, setErrors] = useState({
    password: '',
    confirmpassword: ''
  });
  const [apiResponse, setApiResponse] = useState({
    apiMessage: '',
    error: false,
    success: false,
  });

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    // Set form errors
    let formErrors = {...errors};
    let hasErrors = false;
    if(!passwordIsValid(formData.password)) {
      formErrors = {
        ...formErrors,
        password: 'Password must include at least 8 characters, 1 number, 1 lowercase letter, 1 uppercase letter'
      };
      hasErrors = true;
    }
    if(!repeatPasswordIsValid(formData.password, formData.confirmpassword)) {
      formErrors = {
        ...formErrors,
        confirmpassword: 'Password doesn\'t match'
      };
      hasErrors = true;
    }

    // Set errors and return if there are errors
    setErrors(formErrors);
    if (hasErrors) {
      setLoading(false);
      return;
    }

    // Get token from URL params
    const token = getUrlLoginIDParam();

    // Trigger API call
    const data = await resetPassword(token, formData);
    if (!data || !data.success) {
      setApiResponse({
        ...apiResponse,
        apiMessage: 'There was an error with the request, please try again',
        error: true,
        success: false,
      });
      setLoading(false);
      return;
    }
    setApiResponse({
      ...apiResponse,
      apiMessage: 'Request successfully submitted',
      error: false,
      success: true,
    });
    setFormData({
      password: '',
      confirmpassword: ''
    });
    setLoading(false);
  }, [errors, formData, apiResponse]);

  useEffect(() => {
    const onEnterKeyPress = e => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.preventDefault();
        if (!loading) {
          handleSubmit();
        }
      }
    };

    document.addEventListener('keydown', onEnterKeyPress);

    return () => {
      document.removeEventListener('keydown', onEnterKeyPress);
    };
  }, [formData, handleSubmit, loading]);

  const handleBannerClose = () => {
    setApiResponse({
      apiMessage: '',
      error: false,
      success: false,
    });
  };

  const handleFieldChange = e => {
    // Clear errors
    setErrors({
      password: '',
      confirmpassword: ''
    });
    setApiResponse({
      apiMessage: '',
      error: false,
      success: false,
    });

    // Update formData
    let data = {...formData};
    data[e.target.id] = e.target.value;
    setFormData(data);
  };

  const handleLinkClick = () => {
    window.location.href = routes.login;
  };

  return (
    <AuthenticationWrapper
      apiMessage={apiResponse.apiMessage}
      error={apiResponse.error}
      handleBannerClose={handleBannerClose}
      hideClose={apiResponse.success}
    >
      <AuthFormContainer
        btnText={'Back to Login'}
        onClick={handleLinkClick}
      >
        <InnerContainer>
          {
            !apiResponse.success &&
            <>
              <FieldContainer>
                <CustomTextField
                  label={'New password'}
                  id={'password'}
                  placeholder={'Password'}
                  required
                  onChange={handleFieldChange}
                  error={errors.password}
                  type={'password'}
                  value={formData.password}
                />
              </FieldContainer>
              <FieldContainer>
                <CustomTextField
                  label={'Confirm password'}
                  id={'confirmpassword'}
                  placeholder={'Confirm password'}
                  required
                  onChange={handleFieldChange}
                  error={errors.confirmpassword}
                  type={'password'}
                  value={formData.confirmpassword}
                />
              </FieldContainer>
              {
                loading &&
                <SectionSpinner/>
              }
              {
                !loading &&
                <OutlinedButton
                  text={'Submit'}
                  onClick={handleSubmit}
                  fullWidth
                />
              }
            </>
          }
          {
            apiResponse.success &&
            <StyledText
              success={apiResponse.success ? 'true' : undefined}
            >
              You have successfully reset your password. You can now{' '}
              <StandardLink
                text={'login'}
                onClick={handleLinkClick}
              />
              {' '}using your new credentials.
            </StyledText>
          }
        </InnerContainer>
      </AuthFormContainer>
    </AuthenticationWrapper>
  )
};

export default ResetPassword;
