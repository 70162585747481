import {
  SectionTitleContainer,
  ButtonContainer,
  CaptionContainer,
  CaptionText,
  DescriptionText,
  InlineDescriptionText
} from './SectionContainer.styles';
import {buttonTypes} from '../../../options/buttonTypes';
import IconButton from '../../Buttons/IconButton/IconButton';
import SectionTitle from '../../Typography/SectionTitle/SectionTitle';
import Subtitle from '../../Typography/Subtitle/Subtitle';
import SectionCard from '../../Cards/SectionCard/SectionCard';
import OutlinedButton from '../../Buttons/OutlinedButton/OutlinedButton';
import LinkButton from '../../Buttons/LinkButton/LinkButton';
import TriggerButton from '../../Buttons/TriggerButton/TriggerButton';
import StandardLink from '../../Links/StandardLink/StandardLink';

const SectionContainer = ({
  children,
  sectionTitle,
  sectionTitleInlineDescription,
  sectionDescription,
  cardTitle,
  titleBtnText,
  onTitleBtnClick,
  titleIcon,
  onTitleIconClick,
  showBtn,
  btnType,
  btnText,
  onClick,
  caption,
  isLoad,
  displayForTV,
}) => {
  return (
    <>
      {
        sectionTitle &&
        <SectionTitleContainer
          hasbtn={titleBtnText && onTitleBtnClick ? 'true' : undefined}
          hasdescription={Boolean(sectionDescription) ? 'true' : undefined}
          hasinlinedescription={Boolean(sectionTitleInlineDescription) ? 'true' : undefined}
          displayForTV={displayForTV}
        >
          <SectionTitle
            text={sectionTitle}
          />
            {
              sectionTitleInlineDescription &&
              <InlineDescriptionText>
                  {sectionTitleInlineDescription}
                </InlineDescriptionText>
            }
          {
            titleBtnText && onTitleBtnClick &&
            <OutlinedButton
              text={titleBtnText}
              onClick={onTitleBtnClick}
            />
          }
            {
              titleIcon && onTitleIconClick &&
              <IconButton
                icon={titleIcon}
                onClick={onTitleIconClick}
              />
            }
        </SectionTitleContainer>
      }
      {
        sectionDescription && <DescriptionText>{sectionDescription}</DescriptionText>
      }
      <SectionCard>
        {
          cardTitle &&
          <Subtitle
            text={cardTitle}
          />
        }

        {children}

        {
          showBtn && btnType && btnText && onClick &&
          <>
            <ButtonContainer
              type={btnType}
            >
              {
                btnType === buttonTypes.trigger ? (
                  <TriggerButton
                    text={btnText}
                    onClick={onClick}
                    isLoad={isLoad}
                  />
                ) : (
                  <LinkButton
                    text={btnText}
                    onClick={onClick}
                    isAdd
                  />
                )
              }
            </ButtonContainer>
          </>
        }
        {
          caption &&
          <CaptionContainer>
            <CaptionText>
              {`${caption} Contact`}
            </CaptionText>
            <StandardLink
              text={'info@rezzil.com'}
              href={'mailto:info@rezzil.com'}
              isMailto
            />
          </CaptionContainer>
        }
      </SectionCard>
    </>
  );
};

export default SectionContainer;
