import {useEffect, useContext, useState} from 'react';
import {
  NewCardsContainer,
  TwitterCardsContainer,
} from './Home.styles';
import MainWrapper from '../../components/Wrappers/MainWrapper/MainWrapper';
import NewsCards from '../../components/HomePage/NewsCards/NewsCards';
import TwitterCards from '../../components/HomePage/TwitterCards/TwitterCards';

// Contexts
import {BaseContext} from '../../providers/Global/BaseProvider';
import {HomeContext} from '../../providers/Home/HomeProvider';

import { getBoardsRezzilIndex } from '../../api';
import { routes } from '../../options/routes';

const Home = () => {
  const baseContext = useContext(BaseContext);
  const homeContext = useContext(HomeContext);
  const [loading, setLoading] = useState(true);
  const [newsData, setNewsData] = useState([]);
  const [twitterData, setTwitterData] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    if (!baseContext.baseData.loading && homeContext.homeData.allLoaded) {
      const { id } = baseContext.baseData.clubProfile;
      setLoading(false);
      if (homeContext.homeData.apiError) {
        setError({
          show: true,
          message: 'Unable to load some or all page content. Please refresh and try again.'
        });
      }

      // Set news and Twitter data
      const {news, twitter} = homeContext.homeData;
      setNewsData(news);
      setTwitterData(twitter);
      // Prefetch rezzil index leaderboard for caching on this page. This does not block any other loading.
      getBoardsRezzilIndex(id);
    }
  }, [baseContext, homeContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.home}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={loading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <NewCardsContainer>
            <NewsCards
              articles={newsData}
            />
          </NewCardsContainer>
          <TwitterCardsContainer>
            <TwitterCards
              posts={twitterData}
            />
          </TwitterCardsContainer>
        </MainWrapper>
      }
    </>
  )
};

export default Home;
