import {createColorHex} from './createColorHex';

export const getRezzilIndexLeaderboardStats = leaderboard => {
  // Create data for Rezzil Index Leaderboard in leaderboard screens
  const {name, scores} = leaderboard;

  // Return data in form of array of objects with the required data structure
  let scoreStats = [];
  if (scores.length > 1) {
    scores.forEach(score => {
      const singleScore = {
        id: score.profile.id ? score.profileId.toString() : '',
        rank: score.rank ? score.rank : 'N/A',
        name: score.name,
        yob: Number(score.yob) ? Number(score.yob) : '--',
        topIndex: score.rezzilIndex && score.rezzilIndex.best && Number(score.rezzilIndex.best.index)
          ? Number(score.rezzilIndex.best.index).toFixed(2)
          : 'N/A',
        averageIndex: score.rezzilIndex && score.rezzilIndex.avg && Number(score.rezzilIndex.avg.index)
          ? Number(score.rezzilIndex.avg.index).toFixed(2)
          : 'N/A',
        lastIndex: score.rezzilIndex && Number(score.rezzilIndex.index)
          ? Number(score.rezzilIndex.index).toFixed(2)
          : 'N/A',
        worldRank: score.worldRank && score.worldRank !== '-1' ? score.worldRank : 'N/A',
        color: createColorHex(score.name),
        showColor: false,
      };
      scoreStats.push(singleScore);
    });
  }

  return {
    leaderboard: {
      name,
      scores: scoreStats,
    }
  }
};

export const getClubLeaderBoardStats = leaderboards => {
  // Create data for all other leaderboards in leaderboard screens
  // Return empty placeholders if no data
  if (Object.entries(leaderboards).length === 0)
    return {
      blocking: {
        name: 'Pressure Pass',
        scores: [],
      },
      colour_combo_warmup: {
        name: 'Colour Combo',
        scores: [],
      },
      headers: {
        name: 'Head Smart',
        scores: [],
      },
      number_pass: {
        name: 'Shoulder Sums',
        scores: [],
      },
      pass_awareness_1_4: {
        name: 'Rondo Scan',
        scores: [],
      },
      wolfy_180: {
        name: 'Wolfy 180',
        scores: [],
      },
    };
  // Return data in form of array of objects with the required data structure
  let leaderboardStats = {};
  Object.entries(leaderboards).forEach(leaderboard => {
    if (leaderboard.length > 1) {
      const leaderboardId = leaderboard[0];
      const leaderboardDetails = leaderboard[1];
      const {name, scores} = leaderboardDetails;

      // Get scores details
      let scoreStats = [];
      if (scores.length > 0) {
        scores.forEach(score => {
          const singleScore = {
            id: score.profileId ? score.profileId.toString() : '',
            rank: score.rank ? score.rank : 'N/A',
            name: score.name,
            yob: Number(score.yob) ? Number(score.yob) : '--',
            topIndex: Number(score.topIndex) ? Number(score.topIndex) : 'N/A',
            averageIndex: Number(score.averageIndex) ? Number(score.averageIndex) : 'N/A',
            lastIndex: Number(score.lastIndex) ? Number(score.lastIndex) : 'N/A',
            worldRank: score.worldRank && score.worldRank !== '-1' ? score.worldRank : 'N/A',
            color: createColorHex(score.name),
            showColor: false,
          };
          scoreStats.push(singleScore);
        });
      }

      // Set leaderboardId as key in leaderboardStats object
      leaderboardStats[leaderboardId] = {
        name,
        scores: scoreStats,
      };
    }
  });

  return Object.entries(leaderboardStats).length > 0 ? leaderboardStats : null;
};

export const formatGlobalLeaderBoardStats = leaderboards => {
  // Create data for all other leaderboards in leaderboard screens
  // Return null if no data
  if (Object.entries(leaderboards).length === 0) return null;

  // Return data in form of array of objects with the required data structure
  let leaderboardStats = {};
  Object.entries(leaderboards).forEach(leaderboard => {
    if (leaderboard.length > 1) {
      const leaderboardId = leaderboard[0];
      const leaderboardDetails = leaderboard[1];
      const {name, scores} = leaderboardDetails;
      // The global scores are currently returned in reverse order - so we have to reverse it here.
      const sortedScores = scores.reverse();
      // Get scores details
      let scoreStats = [];
      if (sortedScores.length > 0) {
        sortedScores.forEach(score => {
          const singleScore = {
            id: score.profileId ? score.profileId.toString() : '',
            rank: score.rank ? score.rank : 'N/A',
            name: `${score.firstname} ${score.lastname}`,
            yob: Number(score.yob) ? Number(score.yob) : '--',
            topIndex: Number(score.bestScore) ? Number(score.bestScore) : 'N/A',
            averageIndex: Number(score.averageScore) ? Number(score.averageScore) : 'N/A',
            lastIndex: Number(score.latestScore) ? Number(score.latestScore) : 'N/A',
            worldRank: score.worldRank && score.worldRank !== '-1' ? score.worldRank : 'N/A',
            color: createColorHex(`${score.firstname} ${score.lastname}`),
            showColor: false,
          };
          scoreStats.push(singleScore);
        });
      }

      // Set leaderboardId as key in leaderboardStats object
      leaderboardStats[leaderboardId] = {
        name,
        scores: scoreStats,
      };
    }
  });

  return Object.entries(leaderboardStats).length > 0 ? leaderboardStats : null;
};
