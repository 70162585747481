import {useEffect, useState, useCallback} from 'react';
import {
  InnerContainer,
  StyledText,
  FieldContainer,
} from './ForgotPassword.styles';
import {routes} from '../../../options/routes';
import AuthenticationWrapper from '../../../components/Wrappers/AuthenticationWrapper/AuthenticationWrapper';
import AuthFormContainer from '../../../components/Containers/AuthFormContainer/AuthFormContainer';
import CustomTextField from '../../../components/Inputs/CustomTextField/CustomTextField';
import OutlinedButton from '../../../components/Buttons/OutlinedButton/OutlinedButton';
import SectionSpinner from '../../../components/Loading/SectionSpinner/SectionSpinner';

// Validators
import emailIsValid from '../../../utils/validation/email';

// API calls
import {forgotPassword} from '../../../api';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
  });
  const [errors, setErrors] = useState({
    email: '',
  });
  const [apiResponse, setApiResponse] = useState({
    apiMessage: '',
    error: false,
    success: false,
  });

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    // Set form errors
    let formErrors = {...errors};
    let hasErrors = false;
    if(!emailIsValid(formData.email)) {
      formErrors = {...formErrors, email: 'A valid Email Address is required'};
      hasErrors = true;
    }

    // Set errors and return if there are errors
    setErrors(formErrors);
    if (hasErrors) {
      setLoading(false);
      return;
    }

    // Trigger API call
    const data = await forgotPassword(formData);
    if (!data || !data.success) {
      setApiResponse({
        ...apiResponse,
        apiMessage: 'There was an error with the request, please try again',
        error: true,
        success: false,
      });
      setLoading(false);
      return;
    }
    setApiResponse({
      ...apiResponse,
      apiMessage: 'Request successfully submitted',
      error: false,
      success: true,
    });
    setFormData({
      email: '',
    });
    setLoading(false);
  }, [errors, formData, apiResponse]);

  useEffect(() => {
    const onEnterKeyPress = e => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.preventDefault();
        if (!loading) {
          handleSubmit();
        }
      }
    };

    document.addEventListener('keydown', onEnterKeyPress);

    return () => {
      document.removeEventListener('keydown', onEnterKeyPress);
    };
  }, [formData, handleSubmit, loading]);

  const handleBannerClose = () => {
    setApiResponse({
      apiMessage: '',
      error: false,
      success: false,
    });
  };

  const handleFieldChange = e => {
    // Clear errors
    setErrors({
      email: '',
    });
    setApiResponse({
      apiMessage: '',
      error: false,
      success: false,
    });

    // Update formData
    let data = {...formData};
    data[e.target.id] = e.target.value;
    setFormData(data);
  };

  const handleLinkClick = () => {
    window.location.href = routes.login;
  };

  return (
    <AuthenticationWrapper
      apiMessage={apiResponse.apiMessage}
      error={apiResponse.error}
      handleBannerClose={handleBannerClose}
    >
      <AuthFormContainer
        btnText={'Back to Login'}
        onClick={handleLinkClick}
      >
        <InnerContainer>
          {
            !apiResponse.success &&
            <>
              <StyledText>
                Enter the email address associated with your account and we'll send you a link to reset your password.
              </StyledText>
              <FieldContainer>
                <CustomTextField
                  label={'Email address'}
                  id={'email'}
                  placeholder={'email@email.com'}
                  required
                  onChange={handleFieldChange}
                  error={errors.email}
                  type={'email'}
                  value={formData.email}
                />
              </FieldContainer>
              {
                loading &&
                <SectionSpinner/>
              }
              {
                !loading &&
                <OutlinedButton
                  text={'Submit'}
                  onClick={handleSubmit}
                  fullWidth
                />
              }
            </>
          }
          {
            apiResponse.success &&
            <StyledText
              success={apiResponse.success ? 'true' : undefined}
            >
              Check your inbox for the next steps. If you don't receive an email, and it's not in your spam folder
              this could mean you signed up with a different address.
            </StyledText>
          }
        </InnerContainer>
      </AuthFormContainer>
    </AuthenticationWrapper>
  )
};

export default ForgotPassword;
