import {Fragment} from 'react';
import {
  Container,
  InnerContainer,
} from './IndexBreakdownDrillStats.styles';
import {indexBreakdownDrillScoresOptions} from '../../../options/indexBreakdownOptions';
import IndexBreakdownDrillStatHeader from '../IndexBreakdownDrillStatHeader/IndexBreakdownDrillStatHeader';
import IndexBreakdownStatsList from '../../Tables/IndexBreakdownStatsList/IndexBreakdownStatsList';

const IndexBreakdownDrillStats = ({data, rowWidth}) => {
  let counter = 0;

  return (
    <Container>
      {
        indexBreakdownDrillScoresOptions.length > 0 && indexBreakdownDrillScoresOptions.map(({statId}) => {
          // Increment counter if data exists for selected drill to enable setting correct incrementing id
          if (data[statId]) counter++;

          return (
            <Fragment
              key={statId}
            >
              {
                data && data[statId] &&
                <InnerContainer
                  id={`breakdown-stat-${counter}`}
                >
                  <IndexBreakdownDrillStatHeader
                    level={data[statId].level}
                    nextLevel={data[statId].nextLevel}
                  />
                  <IndexBreakdownStatsList
                    statId={statId}
                    data={data[statId]}
                    rowWidth={rowWidth}
                  />
                </InnerContainer>
              }
            </Fragment>
          )
        })
      }
    </Container>
  );
};

export default IndexBreakdownDrillStats;
