import {useState, useEffect, Fragment} from 'react';
import {
  Container,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  DetailsContainer,
  TextContainer,
  TitleText,
  DescriptionText,
  ScoreContainer,
  ScoreText,
  MaxScoreText,
  EmptyBlock,
  ButtonContainer,
} from './IndexAccordion.styles';
import {indexAccordionOptions} from '../../../options/indexAccordionOptions';
import TriggerButton from '../../Buttons/TriggerButton/TriggerButton';

const IndexAccordion = ({id, data, hideHeartRate, expanded, onClick}) => {
  const [accordionOptions, setAccordionOptions] = useState([]);

  useEffect(() => {
    // Remove heartRate from options if set to hide
    const noHeartRateOptions = [...indexAccordionOptions];
    if (hideHeartRate) noHeartRateOptions.pop();
    setAccordionOptions(hideHeartRate ? noHeartRateOptions : indexAccordionOptions);
  }, [hideHeartRate]);

  return (
    <>
      {
        accordionOptions && accordionOptions.length > 0 && data && Object.keys(data).length > 0 &&
        <Container>
          <StyledAccordion
            expanded={expanded}
          >
            <StyledAccordionSummary
              id={id}
            >
              <DetailsContainer>
                <TextContainer>
                  <TitleText>
                    {accordionOptions[0].title}
                  </TitleText>
                  <DescriptionText>
                    {accordionOptions[0].description}
                  </DescriptionText>
                </TextContainer>
                <ScoreContainer>
                  {
                    data[accordionOptions[0].statId] > 0 &&
                    <>
                      <ScoreText>
                        {data[accordionOptions[0].statId]}
                      </ScoreText>
                      <MaxScoreText>
                        / 100
                      </MaxScoreText>
                    </>
                  }
                  {
                    !data[accordionOptions[0].statId] &&
                    <MaxScoreText>
                      --
                    </MaxScoreText>
                  }
                </ScoreContainer>
              </DetailsContainer>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {
                accordionOptions && accordionOptions.length > 0 && accordionOptions.map(({statId, title, description}) => (
                  <Fragment
                    key={statId}
                  >
                    {
                      statId !== 'rezzilIndex' &&
                      <DetailsContainer>
                        <TextContainer>
                          <TitleText>
                            {title}
                          </TitleText>
                          <DescriptionText>
                            {description}
                          </DescriptionText>
                        </TextContainer>
                        <ScoreContainer>
                          {
                            data[statId] > 0 &&
                            <>
                              <ScoreText>
                                {data[statId]}
                              </ScoreText>
                              <MaxScoreText>
                                / 100
                              </MaxScoreText>
                            </>
                          }
                          {
                            !data[statId] &&
                            <MaxScoreText>
                              --
                            </MaxScoreText>
                          }
                        </ScoreContainer>
                      </DetailsContainer>
                    }
                  </Fragment>
                ))
              }
              {
                // Add this for > md screens to resolve 'jumping' bug when expanding/collapsing accordions
                accordionOptions && accordionOptions.length === 4 &&
                <EmptyBlock/>
              }
            </StyledAccordionDetails>
          </StyledAccordion>
          <ButtonContainer>
            <TriggerButton
              text={expanded ? 'Collapse' : 'Expand'}
              onClick={onClick}
            />
          </ButtonContainer>
        </Container>
      }
    </>
  );
};

export default IndexAccordion;
