import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({
  display: 'flex',
  minWidth: 180,
}));

export const ColumnContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  textAlign: 'center',
  padding: '7px 5px',
}));

export const StyledImage = styled('img')(() => ({
  height: 32,
  marginBottom: 15,
}));

export const CaptionText = styled(Typography)(({ theme, next }) => ({
  ...theme.typography.h5,
  color: next ? colors.white : colors.primaryGrey,
}));
