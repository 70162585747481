import {
  PlayerDropdownsContainer,
  SelectContainer,
  ButtonsContainer,
} from './PlayersDropdownSelects.styles';
import CustomSelect from '../../../components/Inputs/CustomSelect/CustomSelect';
import LinkButton from '../../../components/Buttons/LinkButton/LinkButton';

const PlayersDropdownSelects = ({ choices, dropdownOptions, handleChange, handleAdd, handleReset, loading }) => {
  if (!dropdownOptions.length) {
    return <></>;
  }

  return (
    <PlayerDropdownsContainer>
      {
        choices && choices.length > 0 && choices.map((choice, idx) => (
          <SelectContainer
            key={idx}
          >
            <CustomSelect
              key={idx}
              name={`player${idx}`}
              placeholder={'Select option'}
              onChange={handleChange(idx)}
              value={choices[idx]}
              options={dropdownOptions}
              disabled={loading}
            />
          </SelectContainer>
        ))
      }
      <ButtonsContainer>
        <LinkButton
          text={'Add Player'}
          onClick={handleAdd}
          isAdd
          disabled={loading}
        />
        <LinkButton
          text={'Reset'}
          onClick={handleReset}
          isReset
          disabled={loading}
        />
      </ButtonsContainer>
    </PlayerDropdownsContainer>
 );
};

export default PlayersDropdownSelects;
