import {useEffect, useContext, useState} from 'react';
import {
  UserProfileContainer,
  ClubProfileContainer,
} from './Account.styles';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import SectionTitle from '../../../components/Typography/SectionTitle/SectionTitle';
import FileInputCard from '../../../components/Cards/FileInputCard/FileInputCard';
import ActionCard from '../../../components/Cards/ActionCard/ActionCard';
import UserProfileForm from '../../../components/Forms/UserProfileForm/UserProfileForm';
import UserPasswordForm from '../../../components/Forms/UserPasswordForm/UserPasswordForm';
import TeamMembersList from '../../../components/Tables/TeamMembersList/TeamMembersList';
import ClubProfileForm from '../../../components/Forms/ClubProfileForm/ClubProfileForm';

// Validators & Helpers
import convertToBase64 from '../../../utils/helpers/convertToBase64';
import emailIsValid from '../../../utils/validation/email';
import phoneNumberIsValid from '../../../utils/validation/phoneNumber';
import {passwordIsValid, repeatPasswordIsValid} from '../../../utils/validation/password';
import convertNumberToString from '../../../utils/helpers/convertNumberToString';

// Contexts
import {BaseContext} from '../../../providers/Global/BaseProvider';
import {AccountContext} from '../../../providers/Admin/AccountProvider';

// API Calls
import {
  addUserProfile,
  updateUserProfile,
  updateUserPicture,
  updateUserPassword,
  updateUserTwoStep,
  deleteUserProfile,
  getClubUsers,
  getClubProfile,
  updateClubLogo,
  updateClubProfile,
} from '../../../api';
import { routes } from '../../../options/routes';
import { capitaliseUserRole, isAboveClubMember } from '../../../utils/helpers/userRoleHelper';

const Account = () => {
  const baseContext = useContext(BaseContext);
  const accountContext = useContext(AccountContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [userImageLoading, setUserImageLoading] = useState(false);
  const [userProfileLoading, setUserProfileLoading] = useState(false);
  const [userPasswordLoading, setUserPasswordLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [teamMemberLoading, setTeamMemberLoading] = useState(false);
  const [error, setError] = useState({
    show: false,
    message: '',
  });
  const [userId, setUserId] = useState('');
  const [clubId, setClubId] = useState('');
  const [clubName, setClubName] = useState('');
  const [userImageData, setUserImageData] = useState({
    picturefile: '',
  });
  const [userProfileData, setUserProfileData] = useState({
    firstname: '',
    lastname: '',
    telephoneCountryCode: '',
    telephone: '',
    email: '',
  });
  const userRole = JSON.parse(localStorage.getItem('userRole'));
  const [userProfileErrors, setUserProfileErrors] = useState({
    firstname: '',
    lastname: '',
    telephoneCountryCode: '',
    telephone: '',
    email: '',
  });
  const [userPasswordData, setUserPasswordData] = useState({
    oldPassword: '',
    updatePassword: '',
    confirmPassword: '',
  });
  const [userPasswordErrors, setUserPasswordErrors] = useState({
    oldPassword: '',
    updatePassword: '',
    confirmPassword: '',
  });
  const [twoStepData, setTwoStepData] = useState({
    userId: null,
    twoStep: 0,
  });
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState({
    id: undefined,
    clubId: '',
    firstname: '',
    lastname: '',
    email: '',
    telephoneCountryCode: '',
    telephone: '',
    clubname: '',
  });
  const [teamMemberErrors, setTeamMemberErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    telephoneCountryCode: '',
    telephone: '',
  });
  const [clubLogoLoading, setClubLogoLoading] = useState(false);
  const [clubProfileLoading, setClubProfileLoading] = useState(false);
  const [clubLogoData, setClubLogoData] = useState({
    logofile: '',
  });
  const [clubProfileData, setClubProfileData] = useState({
    name: '',
    addr1: '',
    addr2: '',
    addr3: '',
    city: '',
    postcode: '',
    country: '',
  });
  const [clubProfileErrors, setClubProfileErrors] = useState({
    name: '',
    addr1: '',
    addr2: '',
    addr3: '',
    city: '',
    postcode: '',
    country: '',
  });

  useEffect(() => {
    if (!baseContext.baseData.loading && !accountContext.accountData.loading) {
      setDataLoading(false);
      const {userProfile, clubProfile} = baseContext.baseData;
      const {teamMembers, userRoles} = accountContext.accountData;
      const {id, picture, firstname, lastname, telephoneCountryCode, telephone, email, twoStep} = userProfile;
      const {logo, name, addr1, addr2, addr3, city, postcode, country} = clubProfile;
      // Set user data
      setUserId(id);
      setUserImageData({ picturefile: picture });
      const userRoleDisplayName = userRoles.length ? userRoles.find((role) => userRole === role.type)?.display_name : '--';
      setUserProfileData({
        firstname,
        lastname,
        telephoneCountryCode,
        telephone,
        email,
        role: capitaliseUserRole(userRoleDisplayName),
      });
      setTwoStepData({
        userId: id,
        twoStep: Number(twoStep),
      });
      setClubLogoData({logofile: logo});
      setClubProfileData({
        clubId: clubProfile.id,
        name,
        addr1,
        addr2,
        addr3,
        city,
        postcode,
        country,
      });

      // Set team members list data - don't display current user
      const teamMembersList = teamMembers && teamMembers.length
        ? teamMembers.filter(member => member.id !== id)
        : [];
      setTeamMembers(teamMembersList);

      // Set clubId and clubname in teamMemberData
      setClubId(clubProfile.id);
      setClubName((name));
      setTeamMemberData({
        id: undefined,
        clubId: clubProfile.id,
        firstname: '',
        lastname: '',
        email: '',
        telephoneCountryCode: '',
        telephone: '',
        clubname: name,
      });
    }
  }, [baseContext, accountContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handleImageChange = async e => {
    await setUserImageLoading(true);
    if (e.target.files.length) {
      // Create new FormData instance and append file to it to enable successful POST request
      const file = e.target.files[0];
      let formData = new FormData();
      formData.append('picturefile', file);

      // Trigger API call
      const data = await updateUserPicture(userId, formData);
      if (!data || !data.success || data.code !== 201) {
        setError({
          show: true,
          message: 'There was an error with the request. Please try again.'}
        );
        setUserImageLoading(false);
        return;
      }

      setError({
        ...error,
        message: 'Your profile photo has been successfully updated',
      });

      // Convert image to base64 string to display in image area
      await convertToBase64(file, setUserImageData);
    }
    setUserImageLoading(false);
  };

  const handleUserProfileChange = (e, id, val) => {
    // Clear errors
    setUserProfileErrors({
      firstname: '',
      lastname: '',
      telephoneCountryCode: '',
      telephone: '',
      email: '',
    });
    handleBannerClose();

    // Update userProfileData
    let data = {...userProfileData};
    if (e && id) {
      // For autocomplete fields
      data[id] = val;
    } else {
      data[e.target.id] = e.target.value;
    }
    setUserProfileData(data);
  };

  const handleUserProfileSubmit = async () => {
    await setUserProfileLoading(true);
    // Set form errors
    let formErrors = {...userProfileErrors};
    let hasErrors = false;
    if (!userProfileData.firstname) {
      formErrors = {...formErrors, firstname: 'First Name is required'};
      hasErrors = true;
    }
    if (!userProfileData.lastname) {
      formErrors = {...formErrors, lastname: 'Last Name is required'};
      hasErrors = true;
    }
    if(!userProfileData.telephoneCountryCode && userProfileData.telephone) {
      formErrors = {...formErrors, telephoneCountryCode: 'A valid Area Code is required'};
      hasErrors = true;
    }
    if(userProfileData.telephone && !phoneNumberIsValid(userProfileData.telephone)) {
      formErrors = {...formErrors, telephone: 'A valid Phone number is required'};
      hasErrors = true;
    }
    if(!emailIsValid(userProfileData.email)) {
      formErrors = {...formErrors, email: 'A valid Email Address is required'};
      hasErrors = true;
    }

    // Set errors and return if there are errors
    setUserProfileErrors(formErrors);
    if (hasErrors) {
      setUserProfileLoading(false);
      return;
    }

    // Remove leading zeros from phone and convert to string
    const formData = {
      ...userProfileData,
      telephone: convertNumberToString(userProfileData.telephone),
    };

    // Trigger API call
    const data = await updateUserProfile(userId, formData);
    if (!data || !data.success || !data.data.length || data.code !== 200) {
      setError({
        show: true,
        message: 'There was an error with the request. Please try again.'}
      );
      setUserProfileLoading(false);
      return;
    }

    setError({
      ...error,
      message: 'Your profile details have been successfully updated',
    });

    // Update local storage with latest info
    localStorage.setItem('userProfile', JSON.stringify(data.data.user));
    setUserProfileLoading(false);
  };

  const handleUserPasswordChange = e => {
    // Clear errors
    setUserPasswordErrors({
      oldPassword: '',
      updatePassword: '',
      confirmPassword: '',
    });
    handleBannerClose();

    // Update userPasswordData
    let data = {...userPasswordData};
    data[e.target.id] = e.target.value;
    setUserPasswordData(data);
  };

  const handleUserPasswordSubmit = async () => {
    await setUserPasswordLoading(true);
    // Set form errors
    let formErrors = {...userPasswordErrors};
    let hasErrors = false;
    if(!userPasswordData.oldPassword) {
      formErrors = {
        ...formErrors,
        oldPassword: 'Current Password is required'
      };
      hasErrors = true;
    }
    if(!passwordIsValid(userPasswordData.updatePassword)) {
      formErrors = {
        ...formErrors,
        updatePassword: 'Password must include at least 8 characters, 1 number, 1 lowercase letter, 1 uppercase letter'
      };
      hasErrors = true;
    }
    if(!repeatPasswordIsValid(userPasswordData.updatePassword, userPasswordData.confirmPassword)) {
      formErrors = {
        ...formErrors,
        confirmPassword: 'Password doesn\'t match'
      };
      hasErrors = true;
    }

    // Set errors and return if there are errors
    setUserPasswordErrors(formErrors);
    if (hasErrors) {
      setUserPasswordLoading(false);
      return;
    }

    // Trigger API call
    const data = await updateUserPassword(userId, userPasswordData);
    if (!data || !data.success || data.code !== 200 || !data.data.accessToken) {
      setError({
        show: true,
        message: 'There was an error with the request. Please try again.'}
      );
      setUserPasswordLoading(false);
      return;
    }

    setError({
      ...error,
      message: 'Your password details have been successfully updated',
    });

    // Update local storage with access token returned from request
    localStorage.setItem('token', JSON.stringify(data.data.accessToken));
    setUserPasswordLoading(false);
  };

  const handleTwoStepChange = async e => {
    // Update twoStep data - convert checked bool to string (either '0' or '1')
    let formData = {...twoStepData};
    const value = e.target.checked;
    formData[e.target.id] = value;
    await setUserPasswordLoading(true);
    await handleBannerClose();

    // Trigger API call
    const data = await updateUserTwoStep(formData);
    if (!data || !data.success || data.code !== 200) {
      setError({
        show: true,
        message: 'There was an error with the request. Please try again.'}
      );
      setUserPasswordLoading(false);
      return;
    }

    setError({
      ...error,
      message: 'Your 2-step details have been successfully updated',
    });

    setTwoStepData({
      ...twoStepData,
      twoStep: Number(value),
    });
    setUserPasswordLoading(false);
  };

  const handleTeamMemberDelete = async id => {
    await setDeleteLoading(true);
    await handleBannerClose();

    // Trigger API call
    const data = await deleteUserProfile(id);
    if (!data || !data.success || data.code !== 200) {
      setError({
        show: true,
        message: 'There was an error with the request. Please try again.'}
      );
      setDeleteLoading(false);
      return false;
    }

    setError({
      ...error,
      message: 'User has been successfully deleted',
    })

    // Remove profile from array on success
    const teamMembersList = teamMembers.filter(member => member.id !== id);
    setTeamMembers(teamMembersList);

    setDeleteLoading(false);
    return true;
  };

  const handleTeamMemberChange = (e, id, val) => {
    // Clear errors
    setTeamMemberErrors({
      firstname: '',
      lastname: '',
      email: '',
      telephoneCountryCode: '',
      telephone: '',
      // TODO: comment in role once API work is done
      // role: '',
    });
    handleBannerClose();

    // Update teamMemberData
    let data = {...teamMemberData};
    if (e && id) {
      // For autocomplete fields
      data[id] = val;
    } else {
      // For all other fields
      data[e.target.id] = e.target.value;
    }
    setTeamMemberData(data);
  };

  const handleTeamMemberSubmit = async () => {
    await setTeamMemberLoading(true);
    // Set form errors
    let formErrors = {...teamMemberErrors};
    let hasErrors = false;
    if(!teamMemberData.firstname) {
      formErrors = {
        ...formErrors,
        firstname: 'First Name is required'
      };
      hasErrors = true;
    }
    if(!teamMemberData.lastname) {
      formErrors = {
        ...formErrors,
        lastname: 'Last Name is required'
      };
      hasErrors = true;
    }
    if(!emailIsValid(teamMemberData.email)) {
      formErrors = {
        ...formErrors,
        email: 'A valid Email is required'
      };
      hasErrors = true;
    }
    if(!teamMemberData.telephoneCountryCode && teamMemberData.telephone) {
      formErrors = {...formErrors, telephoneCountryCode: 'A valid Area Code is required'};
      hasErrors = true;
    }
    if(teamMemberData.telephone && !phoneNumberIsValid(teamMemberData.telephone)) {
      formErrors = {
        ...formErrors,
        telephone: 'A valid Phone number is required'
      };
      hasErrors = true;
    }

    // Set errors and return if there are errors
    setTeamMemberErrors(formErrors);
    if (hasErrors) {
      setTeamMemberLoading(false);
      return false;
    }

    const postData = {
      ...teamMemberData,
      telephone: convertNumberToString(teamMemberData.telephone),
    };

    // Trigger API call - update if id or create if no id
    try {
      const data = teamMemberData.id
        ? await updateUserProfile(teamMemberData.id, postData)
        : await addUserProfile(postData);
      if (!data || !data.success || data.code !== 200) {
        setTeamMemberErrors({
          saveError: 'There was an error with the request. Please check email and phone number are unique.',
        });
        setTeamMemberLoading(false);
        return false;
      }
    } catch (error) {
      setTeamMemberErrors({
        saveError: 'There was an error with the request. Please check email and phone number are unique.',
      });
      setTeamMemberLoading(false);
      return false;
    }



    // Get new team members list
    const membersData = await getClubUsers(clubProfileData.clubId);
    if (!membersData || !membersData.success || !membersData.data) {
      setError({
        show: true,
        message: `User has been ${teamMemberData.id ? 'updated' : 'added'}, but there was an error when getting the updated information`
      });
      setTeamMemberLoading(false);
      return false;
    }

    // Reset team members list data - don't display current user
    const teamMembersList = membersData.data && membersData.data.length
      ? membersData.data.filter(member => member.id !== userId)
      : [];
    setTeamMembers(teamMembersList);

    setError({
      ...error,
      message: `User has been successfully ${teamMemberData.id ? 'updated' : 'added'}`,
    });

    setTeamMemberData({
      id: undefined,
      clubId,
      firstname: '',
      lastname: '',
      email: '',
      telephoneCountryCode: '',
      telephone: '',
      clubname: clubName,
    });
    setTeamMemberLoading(false);
    return true;
  };

  const resetClubProfileDetails = async () => {
    // Get club profile details and update in local storage
    const data = await getClubProfile(clubId);
    if (!data || !data.success || data.code !== 200 || !(data.data && (Object.keys(data.data).length))) {
      return false;
    }

    if (localStorage.getItem('manageClubId') === clubId) {
      localStorage.setItem('manageClubProfile', JSON.stringify(data.data));
    } else {
      localStorage.setItem('clubProfile', JSON.stringify(data.data));
    }

    return true;
  };

  const handleLogoChange = async e => {
    await setClubLogoLoading(true);
    if (e.target.files.length) {
      // Create new FormData instance and append file to it to enable successful POST request
      const file = e.target.files[0];
      let formData = new FormData();
      formData.append('logofile', file);

      // Trigger API call
      const data = await updateClubLogo(clubId, formData);
      if (!data || !data.success || data.code !== 200) {
        setError({
          show: true,
          message: 'There was an error with the request. Please try again.'}
        );
        setClubLogoLoading(false);
        return;
      }

      // Convert image to base64 string to display in image area
      await convertToBase64(file, setClubLogoData, true);
    }

    // Get updated club profile data with new logo and update local storage
    const getClubProfileSuccess = await resetClubProfileDetails();
    if (!getClubProfileSuccess) {
      setError({
        show: true,
        message: 'Club photo has been successfully updated, but there was an error when getting the updated information'}
      );
      setClubLogoLoading(false);
      return;
    }

    // Show success message providing all logic is successful
    setError({
      ...error,
      message: 'Club photo has been successfully updated',
    });

    setClubLogoLoading(false);
  };

  const handleClubProfileChange = (e, id, val) => {
    // Clear errors
    setClubProfileErrors({
      name: '',
      addr1: '',
      addr2: '',
      addr3: '',
      city: '',
      postcode: '',
      country: '',
    });
    handleBannerClose();

    // Update userProfileData
    let data = {...clubProfileData};
    if (e && id) {
      // For autocomplete fields
      data[id] = val;
    } else {
      // For all other fields
      data[e.target.id] = e.target.value;
    }
    setClubProfileData(data);
  };

  const handleClubProfileSubmit = async () => {
    await setClubProfileLoading(true);
    // Set form errors
    let formErrors = {...clubProfileErrors};
    let hasErrors = false;
    if (!clubProfileData.name) {
      formErrors = {...formErrors, name: 'Club Name is required'};
      hasErrors = true;
    }
    if (!clubProfileData.addr1) {
      formErrors = {...formErrors, addr1: 'Address 1 is required'};
      hasErrors = true;
    }
    if (!clubProfileData.city) {
      formErrors = {...formErrors, city: 'City is required'};
      hasErrors = true;
    }
    if (!clubProfileData.postcode) {
      formErrors = {...formErrors, postcode: 'Post/Zip Code is required'};
      hasErrors = true;
    }
    if (!clubProfileData.country) {
      formErrors = {...formErrors, country: 'Country is required'};
      hasErrors = true;
    }

    // Set errors and return if there are errors
    setClubProfileErrors(formErrors);
    if (hasErrors) {
      setClubProfileLoading(false);
      return;
    }

    // Trigger API call
    const data = await updateClubProfile(clubId, clubProfileData);
    if (!data || !data.success || data.code !== 200) {
      setError({
        show: true,
        message: 'There was an error with the request. Please try again.'}
      );
      setClubProfileLoading(false);
      return;
    }

    // Get updated club profile data with new logo and update local storage
    const getClubProfileSuccess = await resetClubProfileDetails();
    if (!getClubProfileSuccess) {
      setError({
        show: true,
        message: 'Club details have been successfully updated, but there was an error when getting the updated information'}
      );
      setClubLogoLoading(false);
      return;
    }

    // Show success message providing all logic is successful
    setError({
      ...error,
      message: 'Club details have been successfully updated',
    });

    setClubProfileLoading(false);
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.adminAccount}
          clubLogo={clubLogoData.logofile}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <Title
            text={'Account'}
          />
          <SectionTitle
            text={'User Profile'}
          />
          <UserProfileContainer>
            <FileInputCard
              id={'picturefile'}
              imageUrl={userImageData.picturefile}
              btnText={'Update image'}
              onChange={handleImageChange}
              loading={userImageLoading}
            />
            <ActionCard
              btnText={'Update profile'}
              onClick={handleUserProfileSubmit}
              loading={userProfileLoading}
            >
              <UserProfileForm
                formData={userProfileData}
                errors={userProfileErrors}
                onChange={handleUserProfileChange}
              />
            </ActionCard>
            <ActionCard
              btnText={'Update password'}
              onClick={handleUserPasswordSubmit}
              loading={userPasswordLoading}
            >
              <UserPasswordForm
                formData={userPasswordData}
                twoStepData={twoStepData}
                errors={userPasswordErrors}
                onPasswordChange={handleUserPasswordChange}
                onTwoStepChange={handleTwoStepChange}
              />
            </ActionCard>
          </UserProfileContainer>
          <TeamMembersList
            teamMembers={teamMembers}
            clubId={clubId}
            clubName={clubName}
            handleDelete={handleTeamMemberDelete}
            onFieldChange={handleTeamMemberChange}
            onSubmit={handleTeamMemberSubmit}
            formData={teamMemberData}
            formErrors={teamMemberErrors}
            setFormData={setTeamMemberData}
            setFormErrors={setTeamMemberErrors}
            updateLoading={teamMemberLoading}
            deleteLoading={deleteLoading}
          />
          {
            isAboveClubMember(userRole) &&
            <>
              <SectionTitle
                text={'Club Details'}
              />
              <ClubProfileContainer>
                <FileInputCard
                  id={'logo'}
                  imageUrl={clubLogoData.logofile}
                  btnText={'Update logo'}
                  onChange={handleLogoChange}
                  loading={clubLogoLoading}
                  isClub
                />
                <ActionCard
                  btnText={'Update club'}
                  onClick={handleClubProfileSubmit}
                  loading={clubProfileLoading}
                >
                  <ClubProfileForm
                    clubId={clubId}
                    formData={clubProfileData}
                    errors={clubProfileErrors}
                    onChange={handleClubProfileChange}
                  />
                </ActionCard>
              </ClubProfileContainer>
            </>
          }

        </MainWrapper>
      }
    </>
  );
};

export default Account;
