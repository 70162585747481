import {
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableSortLabel, TextWithTooltipContainer, TooltipContainer,
} from './CustomTableHead.styles';
import {ArrowDropDown} from '@mui/icons-material';
import InfoTooltip from '../../Tooltips/InfoTooltip/InfoTooltip';

const CustomTableHead = ({order, orderBy, onRequestSort, columnHeadings, small}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead
      small={small ? 'true' : undefined}
    >
      <StyledTableRow>
        {
          columnHeadings.map(({id, label, align, sortable, showTooltip, tooltipText}) => (
            <StyledTableCell
              key={id}
              align={align}
              sortDirection={sortable && orderBy === id ? order : false}
            >
              {
                sortable &&
                <StyledTableSortLabel
                  active={orderBy === id}
                  direction={orderBy === id ? order : 'asc'}
                  onClick={createSortHandler(id)}
                  IconComponent={ArrowDropDown}
                >
                  {
                    !showTooltip &&
                    label
                  }
                  {
                    showTooltip && tooltipText &&
                    <TextWithTooltipContainer>
                      {label}
                      <TooltipContainer>
                        <InfoTooltip
                          title={tooltipText}
                        />
                      </TooltipContainer>
                    </TextWithTooltipContainer>
                  }
                </StyledTableSortLabel>
              }
              {
                !sortable &&
                <>
                  {
                    !showTooltip &&
                    label
                  }
                  {
                    showTooltip && tooltipText &&
                    <TextWithTooltipContainer>
                      {label}
                      <TooltipContainer>
                        <InfoTooltip
                          title={tooltipText}
                        />
                      </TooltipContainer>
                    </TextWithTooltipContainer>
                  }
                </>
              }
            </StyledTableCell>
          ))
        }
      </StyledTableRow>
    </StyledTableHead>
  );
};

export default CustomTableHead;
