import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../options/colors';

export const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 5,
  '&:nth-of-type(even)': {
    background: 'rgba(255, 255, 255, 0.03)',
  },
}));

export const TextContainer = styled('div')(({ adjustwidth }) => ({
  flex: adjustwidth ? undefined : 1,
  '&:first-of-type': {
    width: adjustwidth ? '60%' : undefined,
  },
  '&:last-of-type': {
    width: adjustwidth ? '40%' : undefined,
  },
}));

export const LabelText = styled(Typography)(({ theme, highlight }) => ({
  ...theme.typography.body1,
  color: colors.primaryGrey,
  padding: '7px 5px 7px 10px',
  fontWeight: highlight ? '900' : 'normal',
  fontSize: highlight ? 18 : 'inherit',
  '@media print': {
    padding: 5,
  },
}));

export const ValueText = styled(Typography)(({ theme, highlight }) => ({
  ...theme.typography.body1,
  padding: '7px 10px 7px 5px',
  fontWeight: highlight ? '900' : 'normal',
  fontSize: highlight ? 18 : 'inherit',
  '@media print': {
    padding: 5,
  },
}));
