import {
  styled,
  Switch,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledLabel = styled('span')(({ theme }) => ({
  ...theme.typography.body1,
}));

export const StyledToggle = styled(Switch)(() => ({
  padding: 0,
  borderRadius: 50,
  height: 30,
  width: 48,
  '& .MuiSwitch-switchBase': {
    color: colors.white,
    padding: 5,
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: colors.white,
    transform: 'translateX(18px)',
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: colors.secondaryGrey,
    opacity: '1 !important',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: colors.primaryGreen,
  },
}));
