import {
  styled,
  Typography,
} from '@mui/material';
import {
  StyledTableBody,
  StyledTableRow,
} from '../CustomTableWrapper/CustomTableWrapper.styles';
import {StyledTableCell} from '../CustomTableHead/CustomTableHead.styles';

export const Container = styled('div')(() => ({}));

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 15,
  '& .MuiButton-root': {
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
}));

export const SearchContainer = styled('div')(({ theme }) => ({
  width: '50%',
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    marginRight: 15,
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    marginRight: 0,
    marginBottom: 15,
  },
}));

export const StyledTableContainer = styled('div')(({ expanded, count }) => ({
  transition: 'all 0.3s ease',
  overflow: 'hidden',
  // Dynamically set maxHeight based on number of rows (table head height is 35px and each row is 62px)
  maxHeight: expanded ? (count * 62) + 35 : 660,
}));

export const ModalText = styled(Typography)(({ theme }) =>({
  ...theme.typography.body1,
}));

export {
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
}
