import {
  Container,
} from './DrillTabsAndMenu.styles';
import CustomTabGroup from '../../Inputs/CustomTabGroup/CustomTabGroup';
import CustomIconSelect from '../../Inputs/CustomIconSelect/CustomIconSelect';

const DrillTabsAndMenu = ({tabOptions, menuOptions, selected, handleChange}) => {
  return (
    <Container>
      <CustomTabGroup
        options={tabOptions}
        value={selected}
        onChange={handleChange}
      />
      {
        menuOptions.length > 0 &&
        <CustomIconSelect
          options={menuOptions}
          selected={selected}
          onChange={handleChange}
        />
      }
    </Container>
  );
};

export default DrillTabsAndMenu;
