import {
  StyledText,
} from './FeedbackText.styles';

const FeedbackText = ({isSuccess, text}) => {
  return (
    <StyledText
      success={isSuccess ? 'true' : undefined}
    >
      {text}
    </StyledText>
  );
};

export default FeedbackText;
