import { StyledLink } from './SecondaryNavLink.styles';
import {routes} from '../../../options/routes';

const SecondaryNavLink = ({icon, name, route, isExternal, active, handleModalOpen}) => {
  const handleClick = () => {
    if (route === routes.updates) {
      handleModalOpen();
      return;
    }

    // Open in new tab if link to external site
    isExternal ? window.open(route, '_blank') : window.location.href = route;
  };
  return (
    <StyledLink
      onClick={handleClick}
      active={active ? 'true' : undefined}
    >
      {icon}
      {name}
    </StyledLink>
  );
};

export default SecondaryNavLink;
