import {useEffect, useContext, useState, useCallback} from 'react';
import {useMediaQuery} from '@mui/material';
import {
  StyledSectionContainer,
  BarChartContainer,
  SelectContainer
} from './DrillStats.styles';
import {colors} from '../../options/colors';
import {
  rezzilPlayerDrillStatsToggleOptions,
} from '../../options/drillStatsToggleOptions';
import SectionSpinner from '../Loading/SectionSpinner/SectionSpinner';
import MainWrapper from '../Wrappers/MainWrapper/MainWrapper';
import Title from '../Typography/Title/Title';
import PlayersDropdownSelects from '../StatsPage/PlayersDropdownSelects/PlayersDropdownSelects';
import DrillTabsAndMenu from '../StatsPage/DrillTabsAndMenu/DrillTabsAndMenu';
import SectionContainer from '../Containers/SectionContainer/SectionContainer';
import PlayerCardStats from '../StatsPage/PlayerCardStats/PlayerCardStats';
import TitleWithLegend from '../Typography/TitleWithLegend/TitleWithLegend';
import CustomToggleGroup from '../Inputs/CustomToggleGroup/CustomToggleGroup';
import SectionTitleWithArrows from '../StatsPage/SectionTitleWithArrows/SectionTitleWithArrows';
import BarChart from '../Charts/BarChart/BarChart';
import BarChartLegend from '../StatsPage/BarChartLegend/BarChartLegend';
import ComparisonCardGroup from '../StatsPage/ComparisonCardGroup/ComparisonCardGroup';
import CustomSelect from '../Inputs/CustomSelect/CustomSelect';


// Contexts & Helpers
import { BaseContext } from '../../providers/Global/BaseProvider';
import { RezzilPlayerDrillStatsContext } from '../../providers/RezzilPlayer/DrillStatsProvider'
import {createColorHex} from '../../utils/helpers/createColorHex';
import {getPlayerChoices} from '../../utils/helpers/playerChoicesHelpers';
import duplicateExists from '../../utils/helpers/duplicateExists';

// API Calls
import { rezzilPlayerDrillStats } from '../../testing/rezzilPlayerDrillStatsOptions';

import { rezzilPlayerPlayerDrillStatsOptions } from '../../options/drillStatsOptions';
import SectionTitle from '../Typography/SectionTitle/SectionTitle';
import { routes } from '../../options/routes';
import { getSquadStatAverages, getSquadStatsLast10 } from '../../api';
import { squadStatsHelper } from '../../utils/helpers/drillStatsHelper';
import { getAPIData } from '../../utils/helpers/apiHelper';

const DrillStatsPage = ({isRezzilPlayer}) => {
  // Dynamically set orientation based on breakpoint
  const playerDrillStats = rezzilPlayerDrillStats;
  const fourTabs = useMediaQuery((theme) => theme.breakpoints.down(1100));
  const threeTabs = useMediaQuery((theme) => theme.breakpoints.down(900));
  const twoTabs = useMediaQuery((theme) => theme.breakpoints.down(700));
  const oneTab = useMediaQuery((theme) => theme.breakpoints.down(500));
  const baseContext = useContext(BaseContext);
  const drillStatsContext = useContext(RezzilPlayerDrillStatsContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [playerDataLoading, setPlayerDataLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const [playerDropdownOptions, setPlayerDropdownOptions] = useState([]);
  const [playerChoices, setPlayerChoices] = useState([]);
  const [playerStatsList, setPlayerStatsList] = useState([]);
  const [drillTabOptions, setDrillTabOptions] = useState([]);
  const [drillMenuOptions, setDrillMenuOptions] = useState([]);
  const [selectedDrillId, setSelectedDrillId] = useState('');
  const [selectedSubDrillId, setSelectedSubDrillId] = useState('');
  const [subDrillOptions, setSubDrillOptions] = useState([]);
  const [selectedDrillName, setSelectedDrillName] = useState('');
  const [playerCardStats, setPlayerCardStats] = useState({});
  const [avgGameStats, setAvgGameStats] = useState([]);
  const [levelStats, setLevelStats] = useState([]);
  // const [selectedToggleId, setSelectedToggleId] = useState('');
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  // const [clubName, setClubName] = useState('');
  const [compareStats, setCompareStats] = useState([]);
  const [newDrillStats, setNewDrillStats] = useState({});
  // const [newWorldDrillStats, setNewWorldDrillStats] = useState({});
  const [drillLevels, setDrillLevels] = useState([]);
  const [selectedDrillLevel, setSelectdDrillLevel] = useState(1);
  const [playerChoicesDataArr, setPlayerChoicesDataArr] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: '',
  });

  const drillStatsToggleOptions = rezzilPlayerDrillStatsToggleOptions;
  const drillStats = rezzilPlayerDrillStats;
  useEffect(() => {
    if (drillStatsContext.drillStatsData.allLoaded) {
      setDataLoading(false);
      setPlayerStatsList([drillStats]);

      const { squadMembers, playerStats } = drillStatsContext.drillStatsData;
      // Set options for player dropdowns
      let options = [];
      if (squadMembers && squadMembers.length > 0) {
        squadMembers.forEach(player => {
          const playerName = player.name;
          const option = {
            value: player.id,
            label: playerName,
            color: createColorHex(playerName),
          };
          options.push(option);
        });
      }
      const intialSelectedDrill = rezzilPlayerPlayerDrillStatsOptions[0];
      setPlayerDropdownOptions(options);
      setPlayerChoices([options.length ? options[0].value : null]);
      setSelectedDrillId(intialSelectedDrill.drillId);
      setSelectedSubDrillId(intialSelectedDrill.subDrills[0].id);

      // Set drill stats tab and hidden menu options
      setPlayerChoicesDataArr([{
        drillStats: playerStats.drillStats,
        worldDrillStats: playerStats.worldDrillStats,
        sessionStats: playerStats.sessionStats,
        playerChoiceId: options.length ? options[0].value : null,
        level: 1,
      }]);
      if (playerStats.drillStats) {
        setNewDrillStats(playerStats.drillStats);
        setNewDrillData(
          playerStats.drillStats,
          playerStats.worldDrillStats,
          playerStats.sessionStats,
          intialSelectedDrill.drillId,
          intialSelectedDrill.subDrills[0].id
        );
      }
      setSelectedDrillName(intialSelectedDrill.drillName);
      setSubDrillOptions(intialSelectedDrill.subDrills);
    }
  }, [baseContext, drillStatsContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const setPlayerStats = (drillId, drillStats) => {
    // Set selected drill and stats for first player for player card
    if (drillTabOptions.length > 0) {
      const statIndex = drillId ? drillTabOptions.findIndex(el => el.id === drillId) : 0;
      setSelectedDrillId(drillTabOptions[statIndex].id);
      setSelectedDrillName(drillTabOptions[statIndex].label);
      const optionData = rezzilPlayerPlayerDrillStatsOptions.find(option => option.drillId === drillId);
      setSelectedSubDrillId(optionData.subDrills.length ? optionData.subDrills[0].id : null);
      if (optionData.subDrills.length) {
        setSubDrillOptions(optionData.subDrills);
        setSelectedSubDrillId(optionData.subDrills[0].id);
      } else {
        setSubDrillOptions([]);
      }
    }
  };

  const getDrillOptions = drillStats => {
    // Create array of drill options with correct object props for rendering in tab and menu components
    let drillOptions = [];
    drillStats.forEach(drill => {
      const option = {
        id: drill.drillId,
        icon: drill.drillLogo,
        label: drill.drillName,
      }
      drillOptions.push(option);
    });
    return drillOptions;
  };

  const setDrillTabAndMenuOptions = useCallback(drillOptions => {
    // Set drill tab and menu options based on screen width and options length
    let tabOptionsMaxLength;
    if (fourTabs || threeTabs || twoTabs || oneTab) {
      if (fourTabs) {
        tabOptionsMaxLength = 4;
      }
      if (threeTabs) {
        tabOptionsMaxLength = 3;
      }
      if (twoTabs) {
        tabOptionsMaxLength = 2;
      }
      if (oneTab) {
        tabOptionsMaxLength = 1
      }
    } else {
      tabOptionsMaxLength = 6;
    }
    if (drillOptions.length > tabOptionsMaxLength) {
      const tabOptions = drillOptions.slice(0, tabOptionsMaxLength);
      const menuOptions = drillOptions.slice(tabOptionsMaxLength);
      setDrillTabOptions(tabOptions);
      setDrillMenuOptions(menuOptions);
    } else {
      setDrillTabOptions(drillOptions);
      setDrillMenuOptions([]);
    }
  }, [fourTabs, threeTabs, twoTabs, oneTab]);

  useEffect(() => {
    // Set tab and menu options dynamically as screen width breakpoint threshold is hit
    if (playerStatsList.length > 0 && playerStatsList[0].drillStats.length > 0) {
      const drillOptions = getDrillOptions(rezzilPlayerPlayerDrillStatsOptions);
      setDrillTabAndMenuOptions(drillOptions);
      // setSelectedDrillId(rezzilPlayerPlayerDrillStatsOptions[0].drillId);
    }
  }, [playerStatsList, setDrillTabAndMenuOptions]);



  const renderNewData = (dataArr, playerChoices, selectedDrillId, selectedSubDrillId, selectedDrillLevel) => {
    // All the rendering controlled here...
    // We only want to render the first player option
    const firstPlayerStats = dataArr.find((playerChoice) => playerChoice.playerChoiceId === playerChoices[0] && playerChoice.level === selectedDrillLevel);

    if (!firstPlayerStats) {
      return;
    }

    const { drillStats, worldDrillStats, sessionStats } = firstPlayerStats;
    setNewDrillData(drillStats, worldDrillStats, sessionStats, selectedDrillId, selectedSubDrillId);

    const compareStats = playerChoicesDataArr.filter((playerChoice) => playerChoices.includes(playerChoice.playerChoiceId) && playerChoice.level === selectedDrillLevel);
    setNewCompareStats(compareStats, selectedDrillId, selectedSubDrillId);
  }

  const updatePlayerChoicesArr = useCallback(async (playerChoices, selectedDrillLevel, selectedSubDrillId, selectedDrillId) => {
    for (let i = 0; i < playerChoices.length; i++) {
      const player = playerChoices[i];
      const hasExistingEntry = playerChoicesDataArr.find((playerChoice) => playerChoice.playerChoiceId === player && playerChoice.level === selectedDrillLevel);
      if (!hasExistingEntry && player) {
      // fetch new entry and add to playerChoicesDataArr
        const drillStatAverages = await getSquadStatAverages(player, selectedDrillLevel);
        const drillHistory = await getSquadStatsLast10(player, selectedDrillLevel);
        const { drillStats, worldDrillStats, sessionStats } = squadStatsHelper(getAPIData(drillStatAverages), getAPIData(drillHistory));
        playerChoicesDataArr.push({
          drillStats,
          worldDrillStats,
          sessionStats,
          level: selectedDrillLevel,
          playerChoiceId: player,
        });
        setPlayerChoicesDataArr([...playerChoicesDataArr]);
    }
  }
    // Now Pass the playerChoicesDataArr to the rendering function - this now has everything we need to render!

    renderNewData(playerChoicesDataArr, playerChoices, selectedDrillId, selectedSubDrillId, selectedDrillLevel );
    // }))

  }, [playerChoicesDataArr]);

  useEffect(() => {


    updatePlayerChoicesArr(playerChoices, selectedDrillLevel, selectedSubDrillId, selectedDrillId);
    // Watch the 4 varables


  }, [updatePlayerChoicesArr, playerChoices, selectedDrillLevel, selectedSubDrillId, selectedDrillId])


  const setNewDrillData = (drillStats, worldDrillStats, sessionStats, drillId, subDrillId) => {
    if (!drillStats) return;
    const playerStats = subDrillId ? drillStats[drillId][subDrillId] : drillStats[drillId];
    const worldStats = subDrillId ? worldDrillStats[drillId][subDrillId] : worldDrillStats[drillId];
    const playerSessionStats = subDrillId ? sessionStats[drillId][subDrillId] : sessionStats[drillId];
    updateChartData(0, playerSessionStats);
    setPlayerCardStats(playerStats);
    setNewWorldDrillData(worldStats, drillId, subDrillId);
    setNewLevelStats(playerStats, worldStats, [...drillStatsToggleOptions]);

    const drillLevels = rezzilPlayerPlayerDrillStatsOptions.find((drillOption) => drillOption.drillId === drillId);

    if (subDrillId) {
      setDrillLevels(drillLevels.subDrills.find((subDrill) => subDrill.id === subDrillId).levels);
    } else {
      setDrillLevels(drillLevels.levels);
    }
  }

  const setNewWorldDrillData = (worldStats, drillId, subDrillId) => {
    const stats = getNewWorldAvgStats(worldStats, [...drillStatsToggleOptions]);
    setAvgGameStats(stats);
  }

  const setNewCompareStats = (playerStats, drillId, subDrillId) => {
    if (!playerStats || !playerStats[0].drillStats) return;
    const statsTocompare = playerStats.map(stat => {
      return { ...stat.drillStats, playerId: stat.playerChoiceId }
    });
    const drillCompareStats = statsTocompare.map((stat) => {
      const statsToReturn = subDrillId ? stat[drillId][subDrillId] : stat[drillId];
      return {
        ...statsToReturn,
        playerId: stat.playerId
      }
    });
    setCompareStats(drillCompareStats.map(stat => {
      return {
        ...stat,
        playerName: playerDropdownOptions.find((option) => option.value === stat.playerId).label,
      }
    }));
  }

  const getNewWorldAvgStats = (stats, referenceStats) => {
    // We will go through the available stats and matchup with the appropriate reference stats
    const statIdsToRender = Object.keys(stats).filter((stat) => stat !== 'drillId' && stat !== 'drillName' && stat !== 'singleDrillLevel');

    const toggleStats = statIdsToRender.map((statId) => {
      const refStat = referenceStats.find((ref) => ref.id === statId);
      if (!refStat) {
        return {
          id: `missing-${statId}`,
          icon: undefined,
          title: `Missing - ${statId}`,
          playerScores: [],
          worldScore: '',
        }
      }
      return {
        ...refStat,
        worldScore: stats[statId]
      };
    });
    return toggleStats;
  }

  const setNewLevelStats = (drillStats, worldStats, referenceStats) => {
    // We will go through the available stats and matchup with the appropriate reference stats
    const statIdsToRender = Object.keys(drillStats).filter((stat) => stat !== 'drillId' && stat !== 'drillName' && stat !== 'singleDrillLevel');

    const stats = statIdsToRender.map((statId) => {
      const refStat = referenceStats.find((ref) => ref.id === statId);
      if (!refStat) {
        return {
          id: `missing-${statId}`,
          icon: undefined,
          title: `Missing - ${statId}`,
          playerScores: [],
          worldScore: '',
        }
      }
      return {
        ...refStat,
        worldScore: worldStats[statId],
        playerScores: [drillStats[statId]],
      };
    });
    setLevelStats(stats);
  }

  const getChartData = (drillId, sessionStats) => {
    // Set chart data for selected programme
    const labels = [];
    const scores = [];
    const desiredLength = 10;

    const sessionStatData = sessionStats ? sessionStats : [];

    sessionStatData.forEach(score => {
      labels.push('');
      scores.push(score.score);
    });
    if (labels.length < desiredLength) {
      // Push empty strings into labels array to give it 10 elements to enable correct chart rendering
      const missingLength = desiredLength - labels.length;
      for (let i = 0; i < missingLength; i++) {
        labels.push('');
        scores.push(0);
      }
    }
    // Reverse data arrays to show oldest to newest from left to right in chart
    const reversedLabels = labels.reverse();
    const reversedScores = scores.reverse();

    // const average = scores.reduce()
    // Set labels and data
    const chartData = {
      avgColor: colors.comparisonRed,
      avgScore: scores.reduce((a, b) => a + b) / scores.length,
      color: colors.secondaryOrange,
      data: reversedScores,
    };
    return {labels: reversedLabels, chartData};
  };

  const updateChartData = (idx, sessionStatsData) => {
    const { labels, chartData } = getChartData(
      sessionStatsData.drillId,
      sessionStatsData.scores
    );
    setChartLabels(labels);
    setChartData([chartData]);
  };

  const handlePlayerChange = idx => async (event) => {
    handleBannerClose();

    // Return if the Please Select option is selected in any dropdown
    if (event.target.value === '') return;
    const {id} = baseContext.baseData.userProfile;
    const rows = getPlayerChoices(playerChoices, idx, event.target.value, id);
    // Ensure duplicates do not exist
    if (duplicateExists(rows)) {
      setError({
        show: true,
        message: 'That player has already been selected',
      });
      return;
    }
    setPlayerChoices(rows);

    setPlayerDataLoading(true);

    // Set player stats data for relevant dropdown
    const statsList = [...playerStatsList];
    statsList[idx] = playerDrillStats;
    setPlayerStatsList(statsList);


    setPlayerDataLoading(false);
  };

  const handlePlayerAdd = () => {
    if (!playerChoices.at(-1)) return;
    const row = '';
    setPlayerChoices([...playerChoices, row]);
  };

  const handlePlayerReset = async () => {
    // Reset options
    const intialSelectedDrill = rezzilPlayerPlayerDrillStatsOptions[0];
    setSelectedDrillId(intialSelectedDrill.drillId);
    setSelectedSubDrillId(intialSelectedDrill.subDrills[0].id);
    setPlayerChoices([playerDropdownOptions.length ? playerDropdownOptions[0].value : null]);
    // Reset the drill level to 1 too
    setSelectdDrillLevel(1);
  };

  const handleDrillChange = drillId => {
    setPlayerStats(drillId, newDrillStats);
  };

  const handleShareClick = async () => {
    await setShareLoading(true);

    // TODO: Trigger API call
    // const data = await shareIndexStats();
    // if (!data || !data.success) {
    //   setError({
    //     show: true,
    //     message: 'There was an error with the request, please try again',
    //   });
    //   setShareLoading(false);
    //   return;
    // }
    setError({
      show: false,
      message: 'Request successfully submitted',
    });
    setShareLoading(false);
  };

  const handleToggleChange = (id) => {
    // setSelectedToggleId(id);
  };

  const handleSessionsRangeChange = async (isNext) => {
    // Get previous or next (if isNext) 10 sessions data for bar chart
    await setChartDataLoading(true);

    setChartDataLoading(false);
  };

  const handleSubDrillChange = (event) => {
    const newSelectedSubDrillId = event.target.value;
    setSelectedSubDrillId(newSelectedSubDrillId);
  }

  const handleDrillLevelChange = (event) => {
    setSelectdDrillLevel(event.target.value);
  }

  const selectedPlayer = playerDropdownOptions.find((option) => option.value === playerChoices[0]);

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.rezzilPlayerDrillStats}
          clubLogo={baseContext.baseData.clubProfile.logo}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
          dataLoading={dataLoading}
        >
          <Title
            text={'Drill Stats'}
          />
          <PlayersDropdownSelects
            choices={playerChoices}
            dropdownOptions={playerDropdownOptions}
            handleChange={handlePlayerChange}
            handleAdd={handlePlayerAdd}
            handleReset={handlePlayerReset}
          />
          {
            playerDataLoading &&
            <SectionSpinner/>
          }
          {
            !playerDataLoading &&
            <>
              {
                drillTabOptions.length > 0 &&
                <DrillTabsAndMenu
                  tabOptions={drillTabOptions}
                  menuOptions={drillMenuOptions}
                  selected={selectedDrillId}
                  handleChange={handleDrillChange}
                />
              }
              {isRezzilPlayer &&
                <StyledSectionContainer>
                  <TitleWithLegend
                    title={'World Average - Overall Stats'}
                    labelOne={'World'}
                  />
                  <CustomToggleGroup
                    options={avgGameStats}
                    onChange={() => isRezzilPlayer ? false : handleToggleChange}
                    singlePlayerScore={!isRezzilPlayer}
                    preventToggle={isRezzilPlayer}
                  />
                </StyledSectionContainer>
              }
              <StyledSectionContainer>
                <SectionContainer
                  cardTitle={`Player Card - ${selectedDrillName}`}
                >
                  {
                    playerStatsList.length > 0 &&
                    <PlayerCardStats
                      // playerImage={playerStatsList[0].picture}
                      playerName={selectedPlayer?.label || '--'}
                      playerStats={playerCardStats}
                      onShareClick={handleShareClick}
                      shareLoading={shareLoading}
                      isRezzilPlayer={isRezzilPlayer}
                      subDrills={subDrillOptions}
                      onSubDrillChange={handleSubDrillChange}
                      selectedSubDrillId={selectedSubDrillId}
                    />
                  }
                </SectionContainer>
              </StyledSectionContainer>
              {
                isRezzilPlayer && drillLevels &&
                <StyledSectionContainer>
                  <SectionTitle
                    text={'Level Stats'}
                  />
                    <SelectContainer>
                    <CustomSelect
                    name={`level-stats`}
                    placeholder={'Select option'}
                    onChange={handleDrillLevelChange}
                    value={selectedDrillLevel}
                    options={drillLevels}
                  />
                  </SelectContainer>
                </StyledSectionContainer>
              }
              <StyledSectionContainer>

                <SectionTitleWithArrows
                  title={'Last 10 games played'}
                  handleRangeChange={handleSessionsRangeChange}
                  disablePrev
                  disableNext
                />
                <SectionContainer>
                  <BarChartContainer
                    id={'drillsChartContainer'}
                  >
                    <BarChart
                      loading={chartDataLoading}
                      id={'drills'}
                      labels={chartLabels}
                      data={chartData}
                      isDrillStats
                    />
                  </BarChartContainer>
                  <BarChartLegend
                    data={chartData}
                    isDrills
                  />
                </SectionContainer>
              </StyledSectionContainer>
              {isRezzilPlayer &&
                <StyledSectionContainer>
                  <TitleWithLegend
                    title={'Level Stats'}
                    iconColor={colors.primaryGreen}
                    labelOne={'Player'}
                    labelTwo={'World Average'}
                  />
                  <CustomToggleGroup
                    options={levelStats}
                    onChange={() => isRezzilPlayer ? false : handleToggleChange}
                    singlePlayerScore
                    // preventToggle={isRezzilPlayer}
                  />
                </StyledSectionContainer>
              }
              <StyledSectionContainer>
                <SectionTitle
                  text={'Compare'}
                />
                <ComparisonCardGroup
                  isRezzilPlayer={isRezzilPlayer}
                  data={compareStats}
                  highlightTop
                />
              </StyledSectionContainer>
            </>
          }
        </MainWrapper>
      }
    </>
  );
};

export default DrillStatsPage;
