import {StyledButton} from './SolidButton.styles';

const SolidButton = ({text, onClick, loading}) => {
  return (
    <StyledButton
      variant='text'
      onClick={onClick}
      loading={loading}
    >
      {text}
    </StyledButton>
  );
};

export default SolidButton;
