import {useEffect, useContext, useState} from 'react';
import {
  OverviewContainer,
  BreakdownContainer,
  ChartContainer,
  SelectContainer,
} from './Revenue.styles';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import SectionTitle from '../../../components/Typography/SectionTitle/SectionTitle';
import CustomSelect from '../../../components/Inputs/CustomSelect/CustomSelect';
import RevenueCards from '../../../components/RevenuePage/RevenueCards/RevenueCards';
import SectionContainer from '../../../components/Containers/SectionContainer/SectionContainer';
import RevenueChart from '../../../components/Charts/RevenueChart/RevenueChart';

// Contexts
import {BaseContext} from '../../../providers/Global/BaseProvider';

// API Calls

// Dropdown options
import {overviewOptions, breakdownOptions} from '../../../options/revenueDropdownOptions';

import {overviewData, breakdownData, chartData} from '../../../testing/revenueOptions';
import { routes } from '../../../options/routes';

const Revenue = () => {
  const baseContext = useContext(BaseContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [overviewLoading, setOverviewLoading] = useState(false);
  const [breakdownLoading, setBreakdownLoading] = useState(false);
  const [overviewOption, setOverviewOption] = useState('index');
  const [overviewList, setOverviewList] = useState([]);
  const [breakdownOption, setBreakdownOption] = useState(6);
  const [breakdownList, setBreakdownList] = useState([]);
  const [revenueChartList, setRevenueChartList] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    if (!baseContext.baseData.loading) {
      setDataLoading(false);
      setOverviewList(overviewData);
      setBreakdownList(breakdownData);
      setRevenueChartList(chartData);
    }
    // Set auto-renew on page load
  }, [baseContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const onDropdownChange = async e => {
    // Clear errors
    await handleBannerClose();

    // Do nothing if no value is selected
    if (!e.target.value) {
      return;
    }

    if (e.target.name === 'overview') {
      await setOverviewLoading(true);
      setOverviewOption(e.target.value);

      // TODO: trigger API call

      setOverviewLoading(false);
    } else {
      await setBreakdownLoading(true);
      setBreakdownOption(e.target.value);

      // TODO: trigger API call

      setBreakdownLoading(false);
    }
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.revenue}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <Title
            text={'Revenue'}
          />
          <OverviewContainer>
            <SectionTitle
              text={'Overview'}
            />
            <SelectContainer>
              <CustomSelect
                name={'overview'}
                placeholder={'Select option'}
                onChange={onDropdownChange}
                value={overviewOption}
                options={overviewOptions}
              />
            </SelectContainer>
            <RevenueCards
              data={overviewList}
              loading={overviewLoading}
            />
          </OverviewContainer>
          <BreakdownContainer>
            <SectionTitle
              text={'Revenue Breakdown'}
            />
            <SelectContainer>
              <CustomSelect
                name={'breakdown'}
                placeholder={'Select option'}
                onChange={onDropdownChange}
                value={breakdownOption}
                options={breakdownOptions}
              />
            </SelectContainer>
            <RevenueCards
              data={breakdownList}
              loading={breakdownLoading}
            />
          </BreakdownContainer>
          <SectionContainer>
            <ChartContainer
              id={'revenueChartContainer'}
            >
              <RevenueChart
                loading={dataLoading}
                data={revenueChartList}
              />
            </ChartContainer>
          </SectionContainer>
        </MainWrapper>
      }
    </>
  );
};

export default Revenue;
