export const adminPrefix = '/admin';
export const managementPrefix = '/management';
export const indexPrefix = '/index';
export const statsPrefix = '/stats';
export const leaderBoardsPrefix = '/leaderboards'
export const rezzilPlayerPrefix = '/rezzil-player';

export const routes = {
  home: '/home',
  login: '/login',
  verify: '/verify',
  register: '/register',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  acceptInvitation: '/accept-invitation',
  logout: '/logout',
  updates: '/updates',
  share: '/share',
  shareTV: '/share-tv',
  adminAccount: `${adminPrefix}/account`,
  adminProducts: `${adminPrefix}/products`,
  adminBilling: `${adminPrefix}/billing`,
  repManagement: `${managementPrefix}/reps`,
  clubManagement: `${managementPrefix}/clubs`,
  repCompanyManagement: `${managementPrefix}/rep-companies`,
  revenue: `${managementPrefix}/revenue`,
  indexPanel: indexPrefix,
  indexLicences: `${indexPrefix}/licences`,
  indexPlayerProfiles: `${indexPrefix}/player-profiles`,
  indexStats: `${indexPrefix}${statsPrefix}${indexPrefix}`,
  indexDrillStats: `${indexPrefix}${statsPrefix}/drill`,
  indexClubLeaderBoards: `${indexPrefix}${leaderBoardsPrefix}/club`,
  indexGlobalLeaderBoards: `${indexPrefix}${leaderBoardsPrefix}/global`,
  rezzilPlayerPanel: rezzilPlayerPrefix,
  rezzilPlayerManageUsers: `${rezzilPlayerPrefix}/manage-users`,
  rezzilPlayerDrillStats: `${rezzilPlayerPrefix}${statsPrefix}/drill`,
  rezzilPlayerClubLeaderBoards: `${rezzilPlayerPrefix}${leaderBoardsPrefix}/club`,
  rezzilPlayerGlobalLeaderBoards: `${rezzilPlayerPrefix}${leaderBoardsPrefix}/global`,
};

export const authenticatedRoutes = [
  routes.home,
  routes.updates,
  routes.adminAccount,
  routes.adminProducts,
  routes.adminBilling,
  routes.repManagement,
  routes.clubManagement,
  routes.repCompanyManagement,
  routes.revenue,
  routes.indexPanel,
  routes.indexLicences,
  routes.indexPlayerProfiles,
  routes.indexStats,
  routes.indexDrillStats,
  routes.indexClubLeaderBoards,
  routes.indexGlobalLeaderBoards,
  routes.rezzilPlayerManageUsers,
  routes.rezzilPlayerDrillStats,
  routes.rezzilPlayerClubLeaderBoards,
  routes.rezzilPlayerGlobalLeaderBoards,
  routes.rezzilPlayerSubscribe,
];

export const nonAuthenticatedRoutes = [
  routes.login,
  routes.verify,
  routes.register,
  routes.forgotPassword,
  routes.resetPassword,
  routes.acceptInvitation,
  routes.share,
  routes.shareTV,
];
