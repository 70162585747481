import {styled, Typography} from '@mui/material';

export const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const NameText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontSize: 18,
  marginLeft: 10,
}));
