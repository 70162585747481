import {styled} from '@mui/material';
import {colors} from '../../../options/colors';

export const PriceContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  color: colors.secondaryOrange,
  fontSize: 30,
}));

export const DisplayPrice = styled('div')(() => ({
  flexDirection: 'row',
  display: 'flex',
}));

export const Pence = styled('div')(() => ({
  fontSize: 15,
}));

export const TermCopy = styled('div')(() => ({
  fontSize: 12,
  color: colors.primaryGrey
}));