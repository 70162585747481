import { useEffect, useContext, useState } from 'react';
import {
  SectionWrapper,
  ContainerWrapper,
  ButtonContainer,
} from './ClubLeaderBoards.styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SectionSpinner from '../Loading/SectionSpinner/SectionSpinner';
import MainWrapper from '../Wrappers/MainWrapper/MainWrapper';
import Title from '../Typography/Title/Title';
import PlayersDropdownSelects from '../StatsPage/PlayersDropdownSelects/PlayersDropdownSelects';
import SectionContainer from '../Containers/SectionContainer/SectionContainer';
import LeaderBoardList from '../Tables/LeaderBoardList/LeaderBoardList';

// Contexts & Helpers
import { BaseContext } from '../../providers/Global/BaseProvider';
import { ClubLeaderBoardsContext } from '../../providers/RezzilPlayer/ClubLeaderBoardsProvider';
import { createColorHex } from '../../utils/helpers/createColorHex';
import { getSquadLeaderBoardData } from '../../utils/helpers/leaderboardsHelpers';
import { getPlayerChoices } from '../../utils/helpers/playerChoicesHelpers';
import duplicateExists from '../../utils/helpers/duplicateExists';
import { squadLeaderboardTableNames } from '../../options/squadLeaderboards';
import { capitaliseUserRole } from '../../utils/helpers/userRoleHelper';
import { routes } from '../../options/routes';

import { ShareOutlined } from '@mui/icons-material';
import StandardModal from '../Modals/StandardModal/StandardModal';
import OutlinedButton from '../Buttons/OutlinedButton/OutlinedButton';

const ClubLeaderBoardsPage = () => {
  // Dynamically set orientation based on breakpoint
  const baseContext = useContext(BaseContext);
  const clubLeaderBoardsContext = useContext(ClubLeaderBoardsContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [squadId, setSquadId] = useState();
  const [playerDataLoading, setPlayerDataLoading] = useState(false);
  const [playerDropdownOptions, setPlayerDropdownOptions] = useState([]);
  const [playerChoices, setPlayerChoices] = useState([]);
  const [clubLeaderBoardStats, setClubLeaderBoardStats] = useState([]);
  const [shareModalParams, setShareModalParams] = useState(false);
  const [error, setError] = useState({
    show: false,
    message: '',
  });

  const fetchLeaderboards = async (existingLeaderboards = [], leaderboardsToFetch, playerChoicesIds, primarySquadId) => {
    const newLeaderBoards = [];
    for (let i = 0; i < leaderboardsToFetch.length; i++) {
      const leaderboardData = await getSquadLeaderBoardData({ playerChoicesIds: playerChoicesIds, leaderboardName: leaderboardsToFetch[i], squadId: primarySquadId });
      newLeaderBoards.push(leaderboardData);
    }

    const updatedLeaderboards = existingLeaderboards.concat(newLeaderBoards);
    setClubLeaderBoardStats(updatedLeaderboards);
  }

  useEffect(() => {
    const { allLoaded, squadMembers, leaderBoards, numberOfTablesPreLoaded, primarySquadId } = clubLeaderBoardsContext.clubLeaderBoardsData;
    if (allLoaded) {
      setDataLoading(false);
      // Set options for player dropdowns
      let options = [];
      if (squadMembers && squadMembers.length > 0) {
        squadMembers.forEach(squadMember => {
          const playerName = squadMember.name;
          const option = {
            value: squadMember.id,
            label: playerName,
            color: createColorHex(playerName),
          };
          options.push(option);
        });
      }
      setPlayerDropdownOptions(options);
      const playerChoicesIds = [squadMembers.length ? squadMembers[0].id : undefined];
      setPlayerChoices(playerChoicesIds);
      setSquadId(primarySquadId);

      // Set leaderboard data
      setClubLeaderBoardStats(leaderBoards || []);

      // Now to fetch the additional leaderboards
      fetchLeaderboards(leaderBoards, squadLeaderboardTableNames.slice(numberOfTablesPreLoaded), playerChoicesIds, primarySquadId);
    }
  }, [clubLeaderBoardsContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handlePlayerChange = idx => async e => {
    // Remove any displayed banner errors
    handleBannerClose();

    const { id } = baseContext.baseData.userProfile;
    const rows = getPlayerChoices(playerChoices, idx, e.target.value, id);
    // Ensure duplicates do not exist
    if (duplicateExists(rows)) {
      setError({
        show: true,
        message: 'That player has already bseen selected',
      });
      return;
    }
    setPlayerChoices(rows);
    setPlayerDataLoading(true);
    const newLeaderBoards = [];
    for (let i = 0; i < squadLeaderboardTableNames.length; i++) {
      const leaderboardData = await getSquadLeaderBoardData(
        {
          playerChoicesIds: rows,
          leaderboardName: squadLeaderboardTableNames[i],
          leaderboardData: clubLeaderBoardStats.find((stat) => stat.name === squadLeaderboardTableNames[i]),
          squadId,
        }
      );
      newLeaderBoards.push(leaderboardData);
    }
    // const updatedLeaderboards = existingLeaderboards.concat(newLeaderBoards);
    setClubLeaderBoardStats(newLeaderBoards);
    setPlayerDataLoading(false);
  };

  const handlePlayerAdd = () => {
    if (!playerChoices.at(-1)) return;
    const row = '';
    setPlayerChoices([...playerChoices, row]);
  };

  const handlePlayerReset = async () => {
    // Reset options and set the logged in player as the first choice
    const newLeaderBoards = [];
    const playerChoicesIds = [Number(playerDropdownOptions[0]?.value)];
    setPlayerChoices(playerChoicesIds);

    // TODO: Get player stats for the logged in player
    setPlayerDataLoading(true);
    for (let i = 0; i < squadLeaderboardTableNames.length; i++) {
      const leaderboardData = await getSquadLeaderBoardData({
        playerChoicesIds,
        leaderboardName: squadLeaderboardTableNames[i],
        leaderboardData: clubLeaderBoardStats.find((stat) => stat.name === squadLeaderboardTableNames[i]),
        squadId,
      }

      );
      newLeaderBoards.push(leaderboardData);
    }
    setClubLeaderBoardStats(newLeaderBoards);
    setPlayerDataLoading(false);
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.rezzilPlayerClubLeaderBoards}
          clubLogo={baseContext.baseData.clubProfile.logo}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
          dataLoading={dataLoading}
        >
          <Title
            text={'Club Leader Boards'}
          />
          <PlayersDropdownSelects
            choices={playerChoices}
            dropdownOptions={playerDropdownOptions}
            handleChange={handlePlayerChange}
            handleAdd={handlePlayerAdd}
            handleReset={handlePlayerReset}
          />
          {
            playerDataLoading &&
            <SectionSpinner />
          }
          {
            !playerDataLoading &&
            <SectionWrapper>
              {
                clubLeaderBoardStats.length > 0 && clubLeaderBoardStats.map(({ name, scores }) => (
                  <ContainerWrapper
                    key={name}
                    datalength={clubLeaderBoardStats.length}
                  >
                    <SectionContainer
                      sectionTitle={capitaliseUserRole(name.replaceAll('-', ' '))}
                      titleIcon={<ShareOutlined />}
                      onTitleIconClick={() => setShareModalParams(`/${squadId}/${name}/${encodeURIComponent(playerChoices.join(','))}`)}
                    >
                      <LeaderBoardList
                        data={scores}
                        isRezzilPlayer
                      />
                    </SectionContainer>
                  </ContainerWrapper>
                ))
              }
            </SectionWrapper>
          }
        </MainWrapper>
      }
      {shareModalParams &&
        <StandardModal
          width={300}
          open={!!shareModalParams}
          onClose={() => setShareModalParams(false)}
          title={'Share Leaderboard'}
          btnText={'Close'}
          onClick={() => setShareModalParams(false)}
          hideCancelBtn
        >
          <ButtonContainer>
            <CopyToClipboard text={`${window.location.host}${routes.share}${shareModalParams}`}>
              <OutlinedButton
                text={'Copy sharing Link'}
                onClick={() => setShareModalParams(false)}
                fullWidth
              />
            </CopyToClipboard>
            <CopyToClipboard text={`${window.location.host}${routes.shareTV}${shareModalParams}`}>
              <OutlinedButton
                text={'Copy sharing link for TV'}
                onClick={() => setShareModalParams(false)}
                fullWidth
              />
            </CopyToClipboard>

          </ButtonContainer>
        </StandardModal>
      }
    </>
  );
};

export default ClubLeaderBoardsPage;
