import {
  styled,
  Table,
} from '@mui/material';
import {
  StyledTableBody,
  StyledTableRow,
} from '../CustomTableWrapper/CustomTableWrapper.styles';
import {StyledTableCell} from '../CustomTableHead/CustomTableHead.styles';

export const StyledTable = styled(Table)(() => ({}));

export const LargeTableBody = styled(StyledTableBody)(() => ({
  '& .MuiTableCell-root': {
    padding: '20px 10px 20px 0',
    '&:first-of-type': {
      paddingLeft: 10,
    },
  },
}));

export {
  StyledTableRow,
  StyledTableCell,
};
