import {styled} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledText = styled('span')(({ theme, active, small }) => ({
  ...theme.typography.h4,
  color: active ? colors.successDarkGreen : colors.primaryRed,
  padding: small ? '5px 8px' : '11px 8px',
  display: small ? undefined : 'block',
  width: small ? undefined : '100%',
  textAlign: 'center',
  backgroundColor: active ? colors.successLightGreen : colors.secondaryRed,
  borderRadius: 5,
}));
