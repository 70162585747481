import {styled} from '@mui/material';

export const OverviewContainer = styled('div')(() => ({
  marginBottom: 40,
}));

export const SelectContainer = styled('div')(() => ({
  width: 220,
  marginBottom: 15,
}));

export const BreakdownContainer = styled('div')(() => ({
  marginBottom: 30,
}));

export const ChartContainer = styled('div')(() => ({}));
