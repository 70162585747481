import {
  RepeatOutlined,
  SyncAltOutlined,
  HdrWeakOutlined,
  RadarOutlined,
  SportsSoccerOutlined,
  FilterTiltShiftOutlined,
  EarbudsOutlined,
  FlipOutlined,
} from '@mui/icons-material';

export const playerCardStatsOptions = [
  {
    labelId: 'bestScore',
    label: 'Best Score',
  },
  {
    labelId: 'goals',
    label: 'Goals',
  },
  {
    labelId: 'totalBalls',
    label: 'Total Balls',
  },
  {
    labelId: 'correct',
    label: 'Correct',
  },
  {
    labelId: 'incorrect',
    label: 'Incorrect',
  },
  {
    labelId: 'avgDwell',
    label: 'Avg. Dwell',
  },
  {
    labelId: 'avgDecision',
    label: 'Avg. Decision',
  },
  {
    labelId: 'bonus',
    label: 'Bonus',
  },
  {
    labelId: 'rightFoot',
    label: 'Right Foot',
  },
  {
    labelId: 'leftFoot',
    label: 'Left Foot',
  },
];

export const rezzilPlayerLevelStatsOptions = [
  {
    labelId: 'totalScore',
    label: 'Total Score',
  },
  {
    labelId: 'totalBalls',
    label: 'Total Balls',
  },
  {
    labelId: 'totalHits',
    label: 'Total Hits',
  },
  {
    labelId: 'bestStreak',
    label: 'Best Streak',
  },
  {
    labelId: 'accuracy',
    label: 'Accuracy',
  },
  {
    labelId: 'headSpeed',
    label: 'Head Speed',
  },
  {
    labelId: 'strikePoint',
    label: 'Strike Point',
  },
  {
    labelId: 'performanceRatio',
    label: 'Perf. Ratio',
  },
]

export const rezzilPlayerPlayerCardStatsOptions = [
  {
    labelId: 'timePlayed',
    label: 'Time Played',
  },
  {
    labelId: 'totalBalls',
    label: 'Total Balls',
  },
  {
    labelId: 'totalPortalsHit',
    label: 'Total Portals Hit',
  },
  {
    labelId: 'totalMisses',
    label: 'Total Misses',
  },
  {
    labelId: 'bestStreak',
    label: 'Best Streak',
  },
  {
    labelId: 'avgAccuracy',
    label: 'Avg. Accuracy',
  },
  {
    labelId: 'avgStrikePoint',
    label: 'Avg. Strike Point',
  },
  {
    labelId: 'avgHeadSpeed',
    label: 'Avg. Head Speed',
  },
  {
    labelId: 'performanceRatio',
    label: 'Performance Ratio',
  },
  {
    labelId: 'totalScore',
    label: 'Total Score',
  },
  {
    labelId: 'clubRank',
    label: 'Club Rank',
  },
  {
    labelId: 'worldRank',
    label: 'World Rank',
  },
];

export const indexPlayerComparisonStatsOptions = [
  {
    labelId: 'bestScore',
    label: 'Best Score',
  },
  {
    labelId: 'avgScore',
    label: 'Average Score',
  },
  {
    labelId: 'lastScore',
    label: 'Last Score',
  },
  {
    labelId: 'highestLevel',
    label: 'Highest Level',
  },
  {
    labelId: 'clubRank',
    label: 'Club Rank',
  },
  {
    labelId: 'worldRank',
    label: 'World Rank',
  },
  {
    labelId: 'clubName',
    label: 'Club Name',
  },
];

export const rezzilPlayerPlayerComparisonStatsOptions = [
  {
    labelId: 'totalScore',
    label: 'Total Score',
    bold: true,
  },
  {
    labelId: 'timePlayed',
    label: 'Time Played',
  },
  {
    labelId: 'totalBallsLaunched',
    label: 'Total Balls Launched',
  },
  {
    labelId: 'totalBallsCleared',
    label: 'Total Balls Cleared',
  },
  {
    labelId: 'totalBallsHit',
    label: 'Total Balls Hit',
  },
  {
    labelId: 'totalMissed',
    label: 'Total Missed',
  },
  {
    labelId: 'bestStreak',
    label: 'Best Ever Streak',
  },
  {
    labelId: 'avgHeadSpeed',
    label: 'Overall Average Head Speed',
  },
  {
    labelId: 'avgAccuracy',
    label: 'Average Accuracy',
  },
  {
    labelId: 'avgStrikePoint',
    label: 'Overall Average Strike Point',
  },
  {
    labelId: 'totalGoalsScored',
    label: 'Total Goals Scored',
  },
  {
    labelId: 'totalGoalsSaved',
    label: 'Total Goals Saved',
  },
  {
    labelId: 'totalCorrectPasses',
    label: 'Total Correct Passes',
  },
  {
    labelId: 'totalWrongPasses',
    label: 'Total Wrong Passes',
  },
  {
    labelId: 'totalSnaps',
    label: 'Total Snaps',
  },
  {
    labelId: 'totalCaught',
    label: 'Total Caught',
  },
  {
    labelId: 'totalPassesComplete',
    label: 'Total Passes Complete',
  },
  {
    labelId: 'bestStreakEver',
    label: 'Best Streak Ever',
  },
  {
    labelId: 'averageReleaseTime',
    label: 'Average Release Time',
  },
  {
    labelId: 'overallAverageAccuracy',
    label: 'Overall Average Accuracy',
  },
  {
    labelId: 'bestScore',
    label: 'Best Score',
  },
  {
    labelId: 'bestScoreChase',
    label: 'Best Chase Score',
  },
  {
    labelId: 'totalHits',
    label: 'Total Hits',
  },
  {
    labelId: 'fastestReaction',
    label: 'Fastest Reaction',
  },
  {
    labelId: 'slowestReaction',
    label: 'Slowest Reaction',
  },
  {
    labelId: 'avgReactionTime',
    label: 'Avg. Reaction Time',
  },
  {
    labelId: 'totalHit',
    label: 'Total Hit',
  },
  {
    labelId: 'totalBonusHits',
    label: 'Total Bonus Hits',
  },
  {
    labelId: 'avgMoveSpeed',
    label: 'Avg. Move Speed',
  },
];

export const indexPlayerDrillStatsOptions = [
  {
    drillId: 'rondo-scan',
    drillName: 'Rondo Scan',
    drillLogo: <RepeatOutlined />,
  },
  {
    drillId: 'colour-combo',
    drillName: 'Colour Combo',
    drillLogo: <HdrWeakOutlined />,
  },
  {
    drillId: 'shoulder-sums',
    drillName: 'Shoulder Sums',
    drillLogo: <RadarOutlined />,
  },
  {
    drillId: 'head-smart',
    drillName: 'Head Smart',
    drillLogo: <SportsSoccerOutlined />,
  },
  {
    drillId: 'pressure-pass',
    drillName: 'Pressure Pass',
    drillLogo: <SyncAltOutlined />,
  },
  {
    drillId: 'wolfy-180',
    drillName: 'Wolfy 180',
    drillLogo: <FilterTiltShiftOutlined />,
  },
  {
    drillId: 'additional-drill',
    drillName: 'Additional Drill',
    drillLogo: <RepeatOutlined />,
  },
  {
    drillId: 'additional-drill-2',
    drillName: 'Additional Drill 2',
    drillLogo: <HdrWeakOutlined />,
  },
];


export const rezzilPlayerPlayerDrillStatsOptions = [
  {
    drillId: 'headers',
    drillName: 'Headers',
    drillLogo: <EarbudsOutlined />,
    subDrills: [{
      id: 'control',
      name: 'Control',
      levels: [
        { label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }, { label: '4', value: '4' }, { label: '5', value: '5' },
        { label: '6', value: '6' }, { label: '7', value: '7' }, { label: '8', value: '8' }, { label: '9', value: '9' }, { label: '10', value: '10' },
        { label: '11', value: '11' }, { label: '12', value: '12' }, { label: '13', value: '13' }, { label: '14', value: '14' }, { label: '15', value: '15' },
        { label: '16', value: '16' }, { label: '17', value: '17' }, { label: '18', value: '18' }, { label: '19', value: '19' }, { label: '20', value: '20' },
        { label: '21', value: '21' }, { label: '22', value: '22' }, { label: '23', value: '23' }, { label: '24', value: '24' }, { label: '25', value: '25' },
        { label: '26', value: '26' }, { label: '27', value: '27' }, { label: '28', value: '28' }, { label: '29', value: '29' }, { label: '30', value: '30' },
      ],
    },
      {
      id: 'shooting',
      name: 'Shooting',
      levels: [
        { label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }, { label: '4', value: '4' }, { label: '5', value: '5' },
        { label: '6', value: '6' }, { label: '7', value: '7' }, { label: '8', value: '8' }, { label: '9', value: '9' }, { label: '10', value: '10' },
        { label: '11', value: '11' }, { label: '12', value: '12' }, { label: '13', value: '13' }, { label: '14', value: '14' }, { label: '15', value: '15' },
        { label: '16', value: '16' }, { label: '17', value: '17' }, { label: '18', value: '18' }, { label: '19', value: '19' }, { label: '20', value: '20' },
        { label: '21', value: '21' }, { label: '22', value: '22' }, { label: '23', value: '23' }, { label: '24', value: '24' }, { label: '25', value: '25' },
        { label: '26', value: '26' }, { label: '27', value: '27' }, { label: '28', value: '28' }, { label: '29', value: '29' }, { label: '30', value: '30' },
      ],
    }, {
      id: 'passing',
      name: 'Passing',
      levels: [
        { label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }, { label: '4', value: '4' }, { label: '5', value: '5' },
        { label: '6', value: '6' }, { label: '7', value: '7' }, { label: '8', value: '8' }, { label: '9', value: '9' }, { label: '10', value: '10' },
        { label: '11', value: '11' }, { label: '12', value: '12' }, { label: '13', value: '13' }, { label: '14', value: '14' }, { label: '15', value: '15' },
      ],
    },
    {
      id: 'clearing',
      name: 'Clearing',
      levels: [
        { label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }, { label: '4', value: '4' }, { label: '5', value: '5' },
        { label: '6', value: '6' }, { label: '7', value: '7' }, { label: '8', value: '8' }, { label: '9', value: '9' }, { label: '10', value: '10' },
        { label: '11', value: '11' }, { label: '12', value: '12' }, { label: '13', value: '13' }, { label: '14', value: '14' }, { label: '15', value: '15' },
      ],
    }]
  },
  {
    drillId: 'fieldGeneral',
    drillName: 'Field General',
    drillLogo: <FilterTiltShiftOutlined />,
    subDrills: [],
    levels: [
      { label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }, { label: '4', value: '4' }, { label: '5', value: '5' },
      { label: '6', value: '6' }, { label: '7', value: '7' }, { label: '8', value: '8' }, { label: '9', value: '9' }, { label: '10', value: '10' },
      { label: '11', value: '11' }, { label: '12', value: '12' }, { label: '13', value: '13' }, { label: '14', value: '14' }, { label: '15', value: '15' },
      { label: '16', value: '16' }, { label: '17', value: '17' }, { label: '18', value: '18' }, { label: '19', value: '19' }, { label: '20', value: '20' },
      { label: '21', value: '21' }, { label: '22', value: '22' }, { label: '23', value: '23' }, { label: '24', value: '24' }, { label: '25', value: '25' },
      { label: '26', value: '26' }, { label: '27', value: '27' }, { label: '28', value: '28' }, { label: '29', value: '29' }, { label: '30', value: '30' },
    ],
  },
  {
    drillId: 'reactionWall',
    drillName: 'Reaction Wall',
    drillLogo: <SyncAltOutlined />,
    subDrills: [
      {
        id: 'micro',
        name: 'Micro',
        levels: ['normal', 'chase'],
        levelLabels: [{ label: 'Normal', value: '1' }, {label: 'Chase', value: '2'}]
      },
      {
        id: '60',
        name: '60',
        levels: ['normal', 'chase'],
        levelLabels: [{ label: 'Normal', value: '1' }, {label: 'Chase', value: '2'}]
      },
      {
        id: '180',
        name: '180',
        levels: [{ label: 'Normal', value: '1' }, {label: 'Chase', value: '2'}]
      },
      {
        id: '360',
        name: '360',
        levels: [{ label: 'Normal', value: '1' }, {label: 'Chase', value: '2'}]
      }
    ],
  },
  {
    drillId: 'hoops',
    drillName: 'Hoops',
    drillLogo: <FlipOutlined />,
    subDrills: [
      {
        id: 'omw',
        name: 'OMW',
        levels: [{ label: 'Easy', value: '1' } , {label: 'Medium', value: '2'}, {label: 'Hard', value: '3'}]
      },
      {
        id: '2020',
        name: '2020',
        levels: [{ label: 'Easy', value: '1' } , {label: 'Medium', value: '2'}, {label: 'Hard', value: '3'}]

      },
      {
        id: 'gotw',
        name: 'GOTW',
        levels: [{ label: 'Easy', value: '1' } , {label: 'Medium', value: '2'}, {label: 'Hard', value: '3'}]

      },
      {
        id: 'caution',
        name: 'Caution',
        levels: [{ label: 'Easy', value: '1' } , {label: 'Medium', value: '2'}, {label: 'Hard', value: '3'}]

      },
      {
        id: 'partyUp',
        name: 'Party Up',
        levels: [{ label: 'Easy', value: '1' } , {label: 'Medium', value: '2'}, {label: 'Hard', value: '3'}]
      },
    ],
  },
];

export const squadStatOptions = [
  {
    labelId: 'totalScore',
    label: 'Total Score',
  },
  {
    labelId: 'timePlayed',
    label: 'Time Played',
  },
  {
    labelId: 'totalBallsLaunched',
    label: 'Total Balls Launched',
  },
  {
    labelId: 'totalBallsCleared',
    label: 'Total Balls Cleared',
  },
  {
    labelId: 'totalBallsHit',
    label: 'Total Balls Hit',
  },
  {
    labelId: 'totalMissed',
    label: 'Total Missed',
  },
  {
    labelId: 'bestStreak',
    label: 'Best Ever Streak',
  },
  {
    labelId: 'avgHeadSpeed',
    label: 'Overall Average Head Speed',
  },
  {
    labelId: 'avgAccuracy',
    label: 'Average Accuracy',
  },
  {
    labelId: 'avgStrikePoint',
    label: 'Overall Average Strike Point',
  },
  {
    labelId: 'totalGoalsScored',
    label: 'Total Goals Scored',
  },
  {
    labelId: 'totalGoalsSaved',
    label: 'Total Goals Saved',
  },
  {
    labelId: 'totalCorrectPasses',
    label: 'Total Correct Passes',
  },
  {
    labelId: 'totalWrongPasses',
    label: 'Total Wrong Passes',
  },
  {
    labelId: 'totalSnaps',
    label: 'Total Snaps',
  },
  {
    labelId: 'totalCaught',
    label: 'Total Caught',
  },
  {
    labelId: 'totalPassesComplete',
    label: 'Total Passes Complete',
  },
  {
    labelId: 'bestStreakEver',
    label: 'Best Streak Ever',
  },
  {
    labelId: 'averageReleaseTime',
    label: 'Average Release Time',
  },
  {
    labelId: 'overallAverageAccuracy',
    label: 'Overall Average Accuracy',
  },
  {
    labelId: 'bestScore',
    label: 'Best Score',
  },
  {
    labelId: 'bestScoreChase',
    label: 'Best Chase Score',
  },
  {
    labelId: 'totalHits',
    label: 'Total Hits',
  },
  {
    labelId: 'fastestReaction',
    label: 'Fastest Reaction',
  },
  {
    labelId: 'slowestReaction',
    label: 'Slowest Reaction',
  },
  {
    labelId: 'avgReactionTime',
    label: 'Avg. Reaction Time',
  },
  {
    labelId: 'totalHit',
    label: 'Total Hit',
  },
  {
    labelId: 'totalBonusHits',
    label: 'Total Bonus Hits',
  },
  {
    labelId: 'avgMoveSpeed',
    label: 'Avg. Move Speed',
  },
]

