import {
  styled,
  Button,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledButton = styled(Button)(({ theme, full }) => ({
  ...theme.typography.h5,
  borderColor: colors.primaryOrange,
  padding: '10px 20px',
  height: 36,
  width: full ? '100%' : undefined,
  '&.Mui-disabled': {
    opacity: 0.7,
    color: colors.primaryGrey,
  },
}));
