export const currencyCodeToSymbol = (currenyCode) => {
  switch (currenyCode) {
    case 'eur':
      return '€';
    case 'usd':
      return '$';
    default:
      return '£';
  };
}
