import {
  styled,
  Card,
  CardContent,
  CardActions,
  Typography
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledCard = styled(Card)(() => ({
  padding: 0,
  width: '100%',
  background: 'transparent',
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'none',
}));

export const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
  width: '100%',
  background: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 'auto',
}));

export const StyledCardActions = styled(CardActions)(() => ({
  padding: 0,
  width: '100%',
  background: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

export const ImageContainer = styled('div')(({ image }) => ({
  width: '100%',
  height: 163,
  borderRadius: 5,
  background: `url(${image}) no-repeat center / cover`,
  marginBottom: 20,
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 15,
  margin: '0 0 10px 0',
}));

export const StyledDate = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 13,
  margin: '0 0 15px',
  color: colors.secondaryGrey,
}));
