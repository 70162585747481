import {
  StyledText,
} from './NoResultsText.styles';

const NoResultsText = ({text}) => {
  return (
    <StyledText>
      {text}
    </StyledText>
  );
};

export default NoResultsText;
