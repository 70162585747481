import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(({ theme, average, width }) => ({
  display: 'flex',
  borderRadius: 5,
  minWidth: 180,
  width: average ? 180 : width,
  '&:nth-of-type(odd)': {
    background: average ? 'transparent' : 'rgba(255, 255, 255, 0.03)',
  },
  [theme.breakpoints.down(750)]: {
    width: average ? '100%' : width,
  },
}));

export const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  textAlign: 'center',
  padding: '7px 5px',
}));

export const LabelText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 12,
  color: colors.primaryGrey,
  whiteSpace: 'nowrap',
}));

export const ValueText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  whiteSpace: 'nowrap',
}));
