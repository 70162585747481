import {
  styled,
  Table,
} from '@mui/material';
import {
  StyledTableBody,
  StyledTableRow,
} from '../CustomTableWrapper/CustomTableWrapper.styles';
import {StyledTableCell} from '../CustomTableHead/CustomTableHead.styles';

export const StyledTable = styled(Table)(() => ({
  '& .MuiTableCell-root': {
    '&:last-of-type': {
      width: 50,
    },
  },
}));

export {
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
};
