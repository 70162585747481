import {
  StyledCard,
  StyledCardContent,
  IconAvatarContainer,
  DetailsContainer,
  CardTitle,
  PriceText,
} from './RevenueCard.styles';
import IconAvatar from '../../Avatars/IconAvatar/IconAvatar';

const RevenueCard = ({avatarBgColor, icon, title, price}) => {
  return (
    <StyledCard>
      <StyledCardContent>
        <IconAvatarContainer>
          <IconAvatar
            bgColor={avatarBgColor}
            icon={icon}
          />
        </IconAvatarContainer>
        <DetailsContainer>
          <CardTitle>
            {title}
          </CardTitle>
          <PriceText>
            {price}
          </PriceText>
        </DetailsContainer>
      </StyledCardContent>
    </StyledCard>
  );
};

export default RevenueCard;
