export const teamMembersColumns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'role',
    label: 'Role',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'edit',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'delete',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
];

export const subscriptionColumns = [
  // {
  //   id: 'licences',
  //   label: 'Licence No.',
  //   align: 'left',
  //   sortable: false,
  //   showTooltip: false,
  //   tooltipText: '',
  // },
  // {
  //   id: 'add-ons',
  //   label: 'No. Add-Ons',
  //   align: 'left',
  //   sortable: false,
  //   showTooltip: false,
  //   tooltipText: '',
  // },
  {
    id: 'frequency',
    label: 'Frequency',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'next-amount',
    label: 'Next Billing Amount',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'expires',
    label: 'Renewal Date',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'auto-renew',
    label: 'Auto Renew',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
];

export const paymentMethodColumns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'expiry',
    label: 'Expiration date',
    align: 'left',
    sortable: true,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    sortable: true,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'type',
    label: 'Type',
    align: 'left',
    sortable: true,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'edit',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'delete',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
];

export const invoicesColumns = [
  {
    id: 'date',
    label: 'Date',
    align: 'left',
    sortable: true,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'docId',
    label: 'Document ID',
    align: 'left',
    sortable: true,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'docType',
    label: 'Document type',
    align: 'left',
    sortable: true,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'billedAmount',
    label: 'Billed amount',
    align: 'left',
    sortable: true,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'link',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
];

export const indexTopTenColumns = [
  {
    id: 'rank',
    label: 'No.',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'name',
    label: 'Player',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'birthYear',
    label: 'YOB',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'topScore',
    label: 'Top Score',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'avgScore',
    label: 'Avg Score',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'lastScore',
    label: 'Last Score',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'worldRank',
    label: 'World Rank',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
];

export const indexBestDrillsColumns = [
  {
    id: 'drill',
    label: 'Drill',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'name',
    label: 'Player',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'highScore',
    label: 'Highest Score',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'worldAvg',
    label: 'World Average',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
];

export const playerProfilesColumns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'dob',
    label: 'DOB',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'position',
    label: 'Position',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'shoe_size',
    label: 'Shoe size',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'gender',
    label: 'Gender',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'nationality',
    label: 'Nationality',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'leaderboards_active',
    label: 'LB',
    align: 'left',
    sortable: false,
    showTooltip: true,
    tooltipText: 'Show or hide in leaderboards',
  },
  {
    id: 'edit',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'forgotPassword',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'delete',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
];

export const groupsColumns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'edit',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'delete',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
];

export const indexLeaderboardColumns = [
  {
    id: 'rank',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'yob',
    label: 'YOB',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'topScore',
    label: 'Top Score',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'avgScore',
    label: 'Avg Score',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'lastScore',
    label: 'Last Score',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'worldRank',
    label: 'World Rank',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
];

export const rezzilPlayerLeaderboardColumns = [
  {
    id: 'rank',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'userName',
    label: 'User Name',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'score',
    label: 'Score',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
];

export const squadDeviceColumns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'dateRegistered',
    label: 'Date Registered',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  // {
  //   id: 'mainDevice',
  //   label: 'Main Device',
  //   align: 'left',
  //   sortable: false,
  //   showTooltip: false,
  //   tooltipText: '',
  // },
  {
    id: 'delete',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
];

export const squadPlayerColumns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'dateRegistered',
    label: 'Date Registered',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
  {
    id: 'delete',
    label: '',
    align: 'left',
    sortable: false,
    showTooltip: false,
    tooltipText: '',
  },
];