
import { useState } from 'react';
import { Autorenew, EditOutlined, VpnKeyOutlined } from '@mui/icons-material';
import {
  StyledSectionContainer,
  DescriptionText,
  EditableDataContainer,
  EditableInfo,
  CodeContainer,
  Icon,
  CodeTitle,
  CodeBody,
  Code,
  StyledButton,
} from './SquadManager.styles';
import { SectionTitleContainer
} from '../Containers/SectionContainer/SectionContainer.styles';

import SectionTitle from '../Typography/SectionTitle/SectionTitle';
import IconButton from '../Buttons/IconButton/IconButton';
import SectionCard from '../Cards/SectionCard/SectionCard';
import Subtitle from '../Typography/Subtitle/Subtitle';
import StandardModal from '../Modals/StandardModal/StandardModal';
import SubscriptionModal from '../RezzilPlayerSubscribe/SubscriptionModal/SubscriptionModal';

const SquadManager = ({ squadCode, handleFetchNewSquadCode, numberOfLicences, accountName, stripeProducts }) => {
  const [licencesModalOpen, setLicencesModalOpen] = useState(false);

  const handleLicencesModalClose = () => {
    setLicencesModalOpen(false);
  }

  return (
    <>
      <StyledSectionContainer>
        <SectionTitleContainer>
          <SectionTitle title={'Squad Manager'} />
        </SectionTitleContainer>
        <SectionCard isRow>
          <EditableInfo>
            <Subtitle text='Name' />
            <DescriptionText>Your Oculus account</DescriptionText>
            <EditableDataContainer>
              {accountName}
            </EditableDataContainer>
            <Subtitle text='No. Licences' />
            <DescriptionText>1 licence = 5 Squad Leaders and 50 Squad Players</DescriptionText>
            <EditableDataContainer>
              {numberOfLicences}
              <IconButton
                icon={<EditOutlined />}
                onClick={() => setLicencesModalOpen(true)}
              />
            </EditableDataContainer>
          </EditableInfo>
          <CodeContainer>
            <Icon>
              <VpnKeyOutlined/>
            </Icon>
            <CodeTitle>Unique Code</CodeTitle>
            {
              squadCode && <>
              <CodeBody>Keep this code safe</CodeBody>
              <Code>{squadCode}</Code>
              </>
            }
              <StyledButton
                variant='text'
                onClick={handleFetchNewSquadCode}
              >
              <Autorenew />
              {squadCode ? `Regenerate` : `Generate`}
            </StyledButton>
          </CodeContainer>
        </SectionCard>
      </StyledSectionContainer>
      {
        licencesModalOpen && <StandardModal
          open={licencesModalOpen}
          onClose={handleLicencesModalClose}
          title={`Edit Licences`}
          hideCancelBtn
          btnText={'Cancel'}
          width={600}
        >
          <SubscriptionModal numberOfExitingLicences={numberOfLicences} stripeProducts={stripeProducts} />
        </StandardModal>
      }
      </>
  );
}

export default SquadManager;