import {
  styled,
  CircularProgress,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledSpinner = styled(CircularProgress)(() => ({
  color: colors.primaryOrange,
  height: '38px !important',
  width: '38px !important',
}));
