import {styled} from '@mui/material';

export const ClubsContainer = styled('div')(() => ({
  marginBottom: 20,
  padding: 10,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  '&:nth-of-type(even)': {
    background: 'rgba(255, 255, 255, 0.03)',
  },
}));

export const IconsContainer = styled('div')(() => ({
  display: 'flex',
  '& .MuiButtonBase-root': {
    marginLeft: 10,
  },
}));
