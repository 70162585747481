import {
  styled,
  Collapse,
  Alert,
  IconButton,
} from '@mui/material';
import {colors} from '../../../options/colors';
import {Close} from '@mui/icons-material';

export const StyledCollapse = styled(Collapse)(() => ({}));

export const StyledAlert = styled(Alert)(({ success, fixed }) => ({
  padding: 20,
  borderRadius: fixed ? '0px 0px 5px 5px' : 5,
  backgroundColor: success ? colors.successLightGreen : colors.secondaryRed,
  '.MuiAlert-message': {
    padding: 0,
  },
  '.MuiAlert-action': {
    padding: '0 0 0 5px',
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  padding: 2,
}));

export const StyledCloseIcon = styled(Close)(({ success }) => ({
  color: success ? colors.successDarkGreen : colors.primaryRed,
  fontSize: 22,
}));

export const TextContainer = styled('div')(({ button }) => ({
  marginBottom: button ? 17 : 0,
}));
