import {styled} from '@mui/material';

export const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const InnerContainer = styled('div')(({ theme }) => ({
  marginRight: 10,
  '&:first-of-type': {
    marginLeft: 10,
  },
  '&:last-of-type': {
    marginRight: 0,
  },
  [theme.breakpoints.down(750)]: {
    '&:first-of-type': {
      marginLeft: 0,
    },
  },
}));
