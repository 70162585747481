import {routes} from './routes';
import {
  ManageAccountsOutlined,
  SourceOutlined,
  PaymentsOutlined,
  HowToRegOutlined,
  SupervisedUserCircleOutlined,
  AnalyticsOutlined,
  TrendingUpOutlined,
  AssignmentIndOutlined,
  // ExtensionOutlined,
  // SchoolOutlined,
  ChangeCircle,
  Settings,
  Lock
} from '@mui/icons-material';
import {ReactComponent as HomeIcon} from '../assets/svgs/home-icon.svg';
import {ReactComponent as AdminIcon} from '../assets/svgs/admin-icon.svg';
import {ReactComponent as DrillIcon} from '../assets/svgs/drill-builder-icon.svg';
import {ReactComponent as AnalyticsIcon} from '../assets/svgs/analytics-icon.svg';
import {ReactComponent as IndexLogo} from '../assets/svgs/index-logo.svg';
// import { ReactComponent as RezzilPlayerLogo } from '../assets/svgs/rezzil-player-logo.svg';
import RezzilPlayerLogo from '../assets/images/rezzil-player-logo.png'


export const mainNavLinks = [
  {
    id: 'home',
    icon: <HomeIcon/>,
    name: 'Home',
    route: routes.home,
    isExternal: false,
  },
  {
    id: 'admin',
    icon: <AdminIcon/>,
    name: 'Admin',
    route: routes.adminAccount,
    isExternal: false,
  },
  {
    id: 'drillBuilder',
    icon: <DrillIcon/>,
    name: 'Drill Builder',
    route: 'https://cms.mhs-services.com/drill-builder',
    isExternal: true,
  },
  {
    id: 'vrAnalysis',
    icon: <AnalyticsIcon/>,
    name: 'VR Analysis',
    route: 'https://cms.mhs-services.com/analysis',
    isExternal: true,
  },
];

export const adminLinks = [
  {
    icon: <ManageAccountsOutlined/>,
    name: 'Account',
    route: routes.adminAccount,
    isExternal: false,
  },
  {
    icon: <SourceOutlined/>,
    name: 'Your Products',
    route: routes.adminProducts,
    isExternal: false,
  },
  {
    icon: <PaymentsOutlined/>,
    name: 'Bills & Payments',
    route: routes.adminBilling,
    isExternal: false,
  },
];

export const adminManageLinks = [
  {
    id: 'repManagement',
    icon: <HowToRegOutlined/>,
    name: 'Rep Management',
    route: routes.repManagement,
    isExternal: false,
  },
  {
    id: 'repCompanyManagement',
    icon: <SupervisedUserCircleOutlined/>,
    name: 'Rep Company Management',
    route: routes.repCompanyManagement,
    isExternal: false,
  },
  {
    id: 'clubManagement',
    icon: <SupervisedUserCircleOutlined/>,
    name: 'Club Management',
    route: routes.clubManagement,
    isExternal: false,
  },
  // {
  //   icon: <AnalyticsOutlined/>,
  //   name: 'Revenue',
  //   route: routes.revenue,
  //   isExternal: false,
  // },
];

export const adminExpandedRoutes = [
  routes.adminAccount,
  routes.adminProducts,
  routes.adminBilling,
  routes.repManagement,
  routes.clubManagement,
  routes.repCompanyManagement,
  // routes.revenue,
];

export const indexLink = {
  icon: <IndexLogo/>,
  name: 'Index Panel',
  route: routes.indexPanel,
  isExternal: false,
};

export const indexNavLinks = [
  {
    icon: <ManageAccountsOutlined/>,
    name: 'Player Profiles',
    route: routes.indexPlayerProfiles,
    isExternal: false,
  },
  {
    icon: <TrendingUpOutlined/>,
    name: 'Index Stats',
    route: routes.indexStats,
    isExternal: false,
  },
  {
    icon: <AnalyticsOutlined/>,
    name: 'Drill Stats',
    route: routes.indexDrillStats,
    isExternal: false,
  },
  {
    icon: <SupervisedUserCircleOutlined/>,
    name: 'Club Boards',
    route: routes.indexClubLeaderBoards,
    isExternal: false,
  },
  {
    icon: <AssignmentIndOutlined/>,
    name: 'Global Boards',
    route: routes.indexGlobalLeaderBoards,
    isExternal: false,
  },
  // {
  //   icon: <ExtensionOutlined/>,
  //   name: 'Add-ons',
  //   route: 'https://google.com',
  //   isExternal: true,
  // },
  // {
  //   icon: <SchoolOutlined/>,
  //   name: 'Training',
  //   route: 'https://google.com',
  //   isExternal: true,
  // },
];

export const indexExpandedRoutes = [
  routes.indexPanel,
  routes.indexLicences,
  routes.indexPlayerProfiles,
  routes.indexStats,
  routes.indexDrillStats,
  routes.indexClubLeaderBoards,
  routes.indexGlobalLeaderBoards,
];

export const rezzilPlayerLink = {
  icon: <img src={RezzilPlayerLogo} width={130} alt={'Rezzil Player Logo'} />,
  name: 'Rezzil Player',
  route: routes.rezzilPlayerPanel,
};

export const rezzilPlayerNavLinks = [
  {
    icon: <ManageAccountsOutlined/>,
    name: 'Manage Users',
    route: routes.rezzilPlayerManageUsers,
    isExternal: false,
  },
  {
    icon: <AnalyticsOutlined/>,
    name: 'View Drill Stats',
    route: routes.rezzilPlayerDrillStats,
    isExternal: false,
  },
  {
    icon: <SupervisedUserCircleOutlined/>,
    name: 'Club Boards',
    route: routes.rezzilPlayerClubLeaderBoards,
    isExternal: false,
  },
  {
    icon: <AssignmentIndOutlined/>,
    name: 'Global Boards',
    route: routes.rezzilPlayerGlobalLeaderBoards,
    isExternal: false,
  },
];

export const rezzilPlayerExpandedRoutes = [
  routes.rezzilPlayerPanel,
  routes.rezzilPlayerManageUsers,
  routes.rezzilPlayerDrillStats,
  routes.rezzilPlayerClubLeaderBoards,
  routes.rezzilPlayerGlobalLeaderBoards,
];

export const subNavLinks = [
  {
    icon: <ChangeCircle/>,
    name: 'Updates',
    route: routes.updates,
    isExternal: false,
  },
  {
    icon: <Settings/>,
    name: 'Support',
    route: 'https://rezzil.zendesk.com/',
    isExternal: true,
  },
  {
    icon: <Lock/>,
    name: 'Log Out',
    route: routes.logout,
  },
];
