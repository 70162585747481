import { useEffect, useState, useCallback, createContext, useContext } from 'react';
import {routes} from '../../options/routes';
import {BaseContext} from '../Global/BaseProvider';
// API calls
import {getRepsByCompany} from '../../api';


const RepsContext = createContext({});

const RepsProvider = ({children}) => {
  const baseContext = useContext(BaseContext);
  const [repsData, setRepsData] = useState({
    loading: true,
    reps: null,
    apiError: false,
  });

  const getRepsData = useCallback(async (id) => {
    // Only trigger API call for admin reps route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.repManagement) return;

    try {
      const repsApiData = await getRepsByCompany(id);
      if (!repsApiData || !repsApiData.success || !repsApiData.data) {
        setRepsData({
          loading: false,
          reps: null,
          apiError: true,
        });
        return;
      }

      const formattedReps = repsApiData.data && repsApiData.data.length ? repsApiData.data[0].map(({role, user}) => {
        return {
          id: user.id,
          name: `${user.firstname} ${user.lastname}`,
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          telephoneCountryCode: user.telephoneCountryCode,
          telephone: user.telephone,
          location: user.location,
          role
        }
      }) : [];

      setRepsData({
        loading: false,
        reps: formattedReps,
        apiError: false,
      });
    } catch (error) {
      setRepsData({
        loading: false,
        reps: null,
        apiError: true,
      });
    }
  }, []);

  useEffect(() => {
    if (!baseContext.baseData.loading) {
      const { id } = baseContext.baseData.clubProfile;
      const manageRepCompanyId = localStorage.getItem('manageRepCompanyId');
      getRepsData(manageRepCompanyId ? manageRepCompanyId : id);
    }
  }, [getRepsData, baseContext]);

  return (
    <RepsContext.Provider
      value={{
        repsData,
        getRepsData
      }}
    >
      {children}
    </RepsContext.Provider>
  );
}

export {RepsProvider, RepsContext};

export default RepsProvider;
