import {useEffect, useContext, useState} from 'react';
import SectionSpinner from '../../components/Loading/SectionSpinner/SectionSpinner';
import UpdatesModal from '../../components/Modals/UpdatesModal/UpdatesModal';
import UpdateRow from '../../components/UpdateRow/UpdateRow';
import NoResultsText from '../../components/Typography/NoResultsText/NoResultsText';

// Contexts
import {BaseContext} from '../../providers/Global/BaseProvider';
import {UpdatesContext} from '../../providers/Global/UpdatesProvider';

// Helpers
import {formatStandardDateString} from '../../utils/helpers/formatDateString';

const Updates = ({open, onClose}) => {
  const baseContext = useContext(BaseContext);
  const updatesContext = useContext(UpdatesContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);


  useEffect(() => {
    if (!baseContext.baseData.loading && !updatesContext.updatesData.loading) {
      setLoading(false);
      if (updatesContext.updatesData.apiError) {
        setError(true);
      }
    }
  }, [baseContext, updatesContext]);

  const handleClose = () => {
    onClose();
  };

  return (
    <UpdatesModal
      open={open}
      onClose={handleClose}
      title={'Updates'}
    >
      {
        loading &&
        <SectionSpinner/>
      }
      {
        !loading &&
        <>
          {
            !error &&
            updatesContext.updatesData.updates.length > 0 &&
            updatesContext.updatesData.updates.map(({id, created, subject, body, link}) => (
              <UpdateRow
                key={id}
                created={formatStandardDateString(created)}
                subject={subject}
                body={body}
                link={link}
              />
            ))
          }
          {
            (error || updatesContext.updatesData.updates.length === 0) &&
            <NoResultsText
              text={'No updates'}
            />
          }
        </>
      }
    </UpdatesModal>
  )
};

export default Updates;
