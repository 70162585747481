import {StyledText} from './StatusPill.styles';

const StatusPill = ({text, isActive, small}) => {
  return (
    <StyledText
      active={isActive ? 'true' : undefined}
      small={small ? 'true' : undefined}
    >
      {text}
    </StyledText>
  );
};

export default StatusPill;
