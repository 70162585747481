import {
  FilterCenterFocusOutlined,
  SportsSoccerOutlined,
  AnimationOutlined,
  ViewColumnOutlined,
  CategoryOutlined,
} from '@mui/icons-material';

import {colors} from './colors';

export const gridOptions = [
  {
    heading: 'Field General',
    body: 'Improve your throwing technique, reactions and arm strength in our quarterback training game. See if you can make it in the big leagues',
    logo: <FilterCenterFocusOutlined/>,
    color: colors.primaryOrange,
  },
  {
    heading: 'Headers',
    body: 'Improve your soccer heading technique without ball impact in 2 training games that can be used in the real world.',
    logo: <SportsSoccerOutlined/>,
    color: colors.primaryGreen,
  },
  {
    heading: 'Hoops Vision',
    body: 'Push your fitness level with our Basketball rhythm game, an intense workout that will also improve your vision on the court.',
    logo: <AnimationOutlined/>,
    color: colors.primaryPurple,
  },
  {
    heading: 'Reaction Wall',
    body: 'Improve your reaction times in any sport. With a choice of wall sizes, see how you stack up on the world leader boards!',
    logo: <ViewColumnOutlined/>,
    color: colors.beige,
  },
  {
    heading: 'Challenges',
    body: 'Compete for glory on global leader boards across multiple games. Can you claim your spot in the Hall of Fame!?',
    logo: <CategoryOutlined/>,
    color: colors.secondaryOrange,
  },
];
