import {
  styled,
  Link,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(({ theme, mobile }) => ({
  width: 250,
  height: '100vh',
  position: mobile ? undefined : 'fixed',
  left: mobile ? undefined : 0,
  top: mobile ? undefined : 0,
  background: colors.menuPrimaryBlack,
  display: 'flex',
  flexDirection: 'column',
  '@media print': {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    maxWidth: 375,
    height: '100%',
    position: 'relative',
    left: 'unset',
    top: 'unset',
    overflowY: 'unset',
    display: mobile ? 'flex' : 'none',
  },
  [theme.breakpoints.up('lg')]: {
    '&::before': {
      content: '""',
      width: 1,
      height: '100%',
      position: 'absolute',
      top: 0,
      right: 0,
      background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
    },
  },
}));

export const InnerContainer = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflowY: 'auto',
  padding: '27px 0 10px',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    padding: '14px 0 30px',
  },
}));

export const LogosContainer = styled('div')(({ theme }) => ({
  padding: '0 20px',
  marginBottom: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const StyledLink = styled(Link)(() => ({
  cursor: 'pointer',
}));

export const IndexLinksContainer = styled('div')(() => ({
  padding: '0 20px',
  marginBottom: 10,
}));

export const RezzilPlayerLinksContainer = styled('div')(() => ({
  padding: '0 20px',
  marginBottom: 40,
}));

export const PrimaryLinksContainer = styled('div')(() => ({
  padding: '0 10px',
  marginBottom: 40,
}));

export const SubLinksContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: '20px 10px 0',
  margin: 'auto 0 80px',
  '&::before': {
    content: '""',
    height: 1,
    width: 'calc(100% - 20px)',
    position: 'absolute',
    top: 0,
    left: 10,
    background: colors.dividerGrey,
  },
  [theme.breakpoints.down('lg')]: {
    marginBottom: 0,
    paddingTop: 10,
  },
}));
