import {StyledLink} from './AdminNavLink.styles';
import { Link as RouterLink } from 'react-router-dom';

const AdminNavLink = ({icon, name, route, isExternal, active}) => {
  return (
    <StyledLink
      component={RouterLink}
      to={isExternal ? { pathname: route } : route}
      target={isExternal ? '_blank' : undefined}
      active={active ? 'true' : undefined}
    >
      {icon}
      {name}
    </StyledLink>
  );
};

export default AdminNavLink;
