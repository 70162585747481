import {styled} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({
  '& > div:last-of-type': {
    marginBottom: 0,
  },
}));

export const InlineFieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& > div:nth-of-type(1)': {
    display: 'inline-block',
    width: '47%',
  },
  '& > div:nth-of-type(2)': {
    display: 'inline-block',
    width: '47%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'unset',
    '& > div': {
      width: '100% !important',
    }
  },
}));

export const FieldContainer = styled('div')(() => ({
  marginBottom: 20,
}));

export const DiscountFieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& > div:nth-of-type(1)': {
    display: 'inline-block',
    width: '64%',
  },
  '& > div:nth-of-type(2)': {
    display: 'inline-block',
    width: '30%',
  },
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    '& > div': {
      width: '100% !important',
    },
    '& > div:nth-of-type(1)': {
      marginBottom: 20,
    },
  },
}));

export const PhoneFieldContainer = styled('div')(() => ({
  display: 'flex !important',
  justifyContent: 'space-between',
  '& > div:nth-of-type(1)': {
    display: 'inline-block',
    width: 125,
    marginRight: 20,
  },
  '& > div:nth-of-type(2)': {
    display: 'inline-block',
    width: '100%',
  },
}));

export const ColourSelectorInput = styled('div')(({selectedcolour}) => ({
  border: `1px solid ${colors.secondaryGrey}`,
  borderRadius: 5,
  height: 53,
  cursor: 'pointer',
  backgroundColor: selectedcolour ? selectedcolour : colors.black,
}));

export const ColourPickerContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

