import { Fragment, useState, useEffect } from 'react';
import {useMediaQuery} from '@mui/material';
import {
  Container,
  InnerContainer,
  LogosContainer,
  StyledLink,
  IndexLinksContainer,
  RezzilPlayerLinksContainer,
  PrimaryLinksContainer,
  SubLinksContainer,
} from './MainNavMenu.styles';
import {
  mainNavLinks,
  adminExpandedRoutes,
  subNavLinks,
} from '../../../options/navLinks';
import {routes} from '../../../options/routes';
import RezzilLogoAvatar from '../../Avatars/RezzilLogoAvatar/RezzilLogoAvatar';
import ClubLogoAvatar from '../../Avatars/ClubLogoAvatar/ClubLogoAvatar';
import NavAccordion from '../../Accordions/NavAccordion/NavAccordion';
import AdminNavAccordion from '../../Accordions/AdminNavAccordion/AdminNavAccordion';
import PrimaryNavLink from '../../Links/PrimaryNavLink/PrimaryNavLink';
import SecondaryNavLink from '../../Links/SecondaryNavLink/SecondaryNavLink';
import Updates from '../../../pages/Updates/Updates';
import { canViewAdmin } from '../../../utils/helpers/userRoleHelper';
import { showIndexNavigation, showRezzilPlayerNavigation } from '../../../utils/helpers/navigationHelper';

const MainNavMenu = ({ activeRoute, clubLogo, disableIndex, mobile, hasEliteLicence, hasRezzilPlayerLicence }) => {
  const [open, setOpen] = useState(false);
  const [navLinks, setNavLinks] = useState([]);
  const userRole = JSON.parse(localStorage.getItem('userRole'));

  useEffect(() => {
    if (canViewAdmin(userRole)) {
      setNavLinks(mainNavLinks);
      return;
    }
    // Remove Admin from navLinks
    setNavLinks(mainNavLinks.filter((link) => link.id !== 'admin'))
  }, [userRole]);


  // Dynamically set orientation based on breakpoint
  const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const handleLinkClick = () => {
    window.location.href = routes.home;
  };

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <Container
      mobile={mobile ? 'true' : undefined}
    >
      <InnerContainer
        mobile={mobile ? 'true' : undefined}
      >
        <LogosContainer>
          <StyledLink
            onClick={handleLinkClick}
          >
            <RezzilLogoAvatar/>
          </StyledLink>
          <StyledLink
            onClick={handleLinkClick}
          >
            <ClubLogoAvatar
              clubLogo={clubLogo}
            />
          </StyledLink>
        </LogosContainer>
        {
          showIndexNavigation() && hasEliteLicence ? <IndexLinksContainer>
            <NavAccordion
              activeRoute={activeRoute}
              disabled={disableIndex}
            />
          </IndexLinksContainer> : <></>
        }

        {

          <RezzilPlayerLinksContainer>
            <NavAccordion
            isRezzilPlayer
              activeRoute={activeRoute}
              onlyShowHeader={!(showRezzilPlayerNavigation() && hasRezzilPlayerLicence)}
            /> </RezzilPlayerLinksContainer>
        }

        <PrimaryLinksContainer>
          {
            navLinks && navLinks.map(({icon, name, id, route, isExternal}) => (
              <Fragment
                key={id}
              >
                {
                  id === 'admin' && adminExpandedRoutes.includes(activeRoute) && !matches ? (
                    <AdminNavAccordion
                      activeRoute={activeRoute}
                    />
                  ) : (
                    <PrimaryNavLink
                      icon={icon}
                      name={name}
                      route={route}
                      isExternal={isExternal}
                      active={activeRoute === route}
                    />
                  )
                }
              </Fragment>
            ))
          }
        </PrimaryLinksContainer>
        <SubLinksContainer>
          {
            subNavLinks && subNavLinks.map(({icon, name, route, isExternal}) => (
              <SecondaryNavLink
                key={name}
                icon={icon}
                name={name}
                route={route}
                isExternal={isExternal}
                active={activeRoute === route}
                handleModalOpen={handleModalOpen}
              />
            ))
          }
        </SubLinksContainer>
        {
          open &&
          <Updates
            open={open}
            onClose={handleModalClose}
          />
        }
      </InnerContainer>
    </Container>
  );
};

export default MainNavMenu;
