import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({
  marginBottom: 15,
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  color: colors.primaryGrey,
}));
