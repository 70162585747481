import {useContext, useState, useEffect} from 'react';
import {
  Container,
  HeadingContainer,
  TopContentContainer,
  GridContentContainer,
  ContentItem,
  MainPanelHeader,
  MainPanelSubHeader,
  MainPanelBody,
  PriceHeader,
  StyledButton,
} from './Subscribe.styles';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import StandardModal from '../../../components/Modals/StandardModal/StandardModal';
import YoutubeEmbed from '../../../components/Video/YouTubeEmbed';
// import { ReactComponent as RezzilPlayerLogo } from '../../../assets/svgs/rezzil-player-logo.svg';
import RezzilPlayerLogo from '../../../assets/images/rezzil-player-logo.png'
import InfoGridItem from '../../../components/InfoGridItem/InfoGridItem';
import Footer from '../../../components/RezzilPlayerSubscribe/Footer';
import SubscriptionModal from '../../../components/RezzilPlayerSubscribe/SubscriptionModal/SubscriptionModal';
import {routes} from '../../../options/routes';
// Contexts & Helpers
import {BaseContext} from '../../../providers/Global/BaseProvider';
import {SubscribeContext} from '../../../providers/RezzilPlayer/Subscribe';
import {gridOptions} from '../../../options/rezzilPlayerInfoGrid';
import Price from '../../../components/Typography/Price/Price';
import { getSubscriptionPrice } from '../../../utils/helpers/subscriptionsHelper';


const Subscribe = () => {
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false);
  const baseContext = useContext(BaseContext);
  const subscribeContext = useContext(SubscribeContext);
  const [monthlyPrice, setMonthlyPrice] = useState('--');
  const [annualPrice, setAnnualPrice] = useState('--');
  const [stripeProducts, setStripeProducts] = useState();
  // const [clubId, setClubId] = useState('');
  const [error, setError] = useState({
    show: false,
    message: '',
  });
  const [dataLoading, setDataLoading] = useState(true);
  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handleSubscribeModalClose = () => {
    setSubscribeModalOpen(false);
  }

  const handleSubscribeClick = () => {
    setSubscribeModalOpen(true);
  }

  useEffect(() => {
    if (!baseContext.baseData.loading && !subscribeContext.subscribeData.loading) {
      setDataLoading(false);
      if (subscribeContext.subscribeData.apiError) {
        setError({
          show: true,
          message: 'Unable to load some or all page content. Please refresh and try again.'
        });
        return;
      }
      const { products } = subscribeContext.subscribeData;
      setStripeProducts(products);
      const monthPrice = getSubscriptionPrice(products, 'squadspro', 'month').formatted;
      const annualPrice = getSubscriptionPrice(products, 'squadspro', 'year').formatted;
      setMonthlyPrice(monthPrice);
      setAnnualPrice(annualPrice);
    }
  }, [baseContext.baseData.loading, subscribeContext]);

  return (
    <>
      {
          <MainWrapper
            error={error.show}
            apiMessage={error.message}
            handleBannerClose={handleBannerClose}
            activeRoute={routes.rezzilPlayerPanel}
            clubLogo={baseContext.baseData.clubProfile.logo}
            hasEliteLicence={baseContext.baseData.hasEliteLicence}
            hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
            dataLoading={dataLoading}
          >

            <Container>
              <HeadingContainer>
              <img src={RezzilPlayerLogo} height={50} alt={'Rezzil Player Logo'} />
              </HeadingContainer>
              <TopContentContainer>
                <ContentItem>
                <YoutubeEmbed embedId={'RW_k6sgD2eE'} />
                </ContentItem>
                <ContentItem>
                  <MainPanelHeader>Smarter, stronger, sharper, faster.</MainPanelHeader>
                  <MainPanelBody>
                    Rezzil Player is an ever-growing collection of athlete designed sports games that will improve your fitness and reaction times across a wide range of sports.
                  </MainPanelBody>
                  <MainPanelBody>
                    Want the reaction times of a F1 driver? The fitness level of a pro athlete? Step into Rezzil Player and train in games developed with the world's best sports professionals.
                  </MainPanelBody>
                  <MainPanelSubHeader>Squads Pro</MainPanelSubHeader>
                  <MainPanelBody>Bring together your entire squad using a single headset. Track training progress, compare player stats and view leaderboards, perfect for schools and colleges.</MainPanelBody>
                </ContentItem >
              </TopContentContainer>
              <GridContentContainer>
                {gridOptions.map((gridItem) => <ContentItem key={gridItem.heading}><InfoGridItem gridItem={gridItem} /></ContentItem>)}
                <ContentItem border>
                  <Price price={'$18.99'} />
                  <PriceHeader>Available on Oculus Store</PriceHeader>
                  <MainPanelBody>Requires Oculus Quest headset</MainPanelBody>
                  <StyledButton
                    variant='text'
                    href={'https://www.oculus.com/experiences/quest/3600836956645933'}>
                    Purchase Now
                  </StyledButton>
                </ContentItem>
              </GridContentContainer>
              <Footer
                onSubscribeClick={handleSubscribeClick}
                monthlyPrice={monthlyPrice}
                annualPrice={annualPrice} />
          </Container>

          {
            subscribeModalOpen &&
            <StandardModal
              open={subscribeModalOpen}
              onClose={handleSubscribeModalClose}
              title={`Subscription`}
              hideCancelBtn
              btnText={'Cancel'}
              width={600}
            >
              <SubscriptionModal stripeProducts={stripeProducts} />
            </StandardModal>
          }
        </MainWrapper>

      }
      </>
  );
}

export default Subscribe;