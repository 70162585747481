import {useContext, useState, useEffect} from 'react';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';


// Contexts & Helpers
import {BaseContext} from '../../../providers/Global/BaseProvider';
import {ManageUsersContext} from '../../../providers/RezzilPlayer/ManageUsers';
import SectionContainer from '../../../components/Containers/SectionContainer/SectionContainer';
import InfoBlock from  '../../../components/Blocks/InfoBlock/InfoBlock';
import {RowContentWrapper, StyledSectionContainer} from './ManageUsers.styles';
import SquadDevicesList from '../../../components/Tables/SquadDevicesList/SquadDevicesList';
import SquadPlayersList from '../../../components/Tables/SquadPlayersList/SquadPlayersList';
// import {squadDevices} from '../../../testing/squadDevices';
// import {squadPlayers} from '../../../testing/squadPlayers';
import { routes } from '../../../options/routes';
import SquadManager from '../../../components/ManageUsersPage/SquadManager';
import { generateNewSquadCode, deleteSquadMember } from '../../../api';


const ManageUsers = () => {
  const baseContext = useContext(BaseContext);
  const manageUsersContext = useContext(ManageUsersContext);
  // const [clubId, setClubId] = useState('');
  const [squadCode, setSquadCode] = useState('');
  const [squadDevices, setSquadDevices] = useState([]);
  const [squadMembers, setSquadMembers] = useState([]);
  const [oculusAccountName, setOculusAccountName] = useState();
  const [numberOflicences, setNumberOflicences] = useState();
  const [squadDeviceLimit, setSquadDeviceLimit] = useState();
  const [squadMemberLimit, setSquadMemberLimit] = useState();
  const [stripeProducts, setStripeProducts] = useState();
  const [error, setError] = useState({
    show: false,
    message: '',
  });
  const [dataLoading, setDataLoading] = useState(true);
  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  useEffect(() => {
    const { apiError, loading } = manageUsersContext.manageUsersData;
    if (!baseContext.baseData.loading && !loading) {
      setDataLoading(false);
      if (apiError) {
        setError({
          show: true,
          message: 'Unable to load some or all page content. Please refresh and try again.'
        });
        return;
      }
      const { squadCode, squadDevices, squadMembers, rezzilPlayerLicences, primarySquad, products } = manageUsersContext.manageUsersData;
      setSquadCode(squadCode);
      setSquadDevices(squadDevices);
      setSquadMembers(squadMembers);
      setNumberOflicences(rezzilPlayerLicences);
      setSquadDeviceLimit(rezzilPlayerLicences * 5);
      setSquadMemberLimit(rezzilPlayerLicences * 50);
      setOculusAccountName(primarySquad);
      setStripeProducts(products);

      if (!primarySquad) {
        setError({
          show: true,
          message: 'Please register your Squad  through your Oculus Device'
        });
        return;
      }
    }

  }, [
    baseContext.baseData.loading,
    manageUsersContext.manageUsersData,
  ]);

  const handleFetchNewSquadCode = async () => {
    const { id } = baseContext.baseData.clubProfile;
    const newSquadCode = await generateNewSquadCode(id);
    setSquadCode(newSquadCode.data.code);
  };

  const handlePlayerDelete = async (playerId) => {
    await deleteSquadMember(playerId);
    manageUsersContext.getManageUsersData();
    // Fetch new player list
    return true;
  };

  const onPlayerSubmit = async () => {
    manageUsersContext.getManageUsersData();
  }

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          clubLogo={baseContext.baseData.clubProfile.logo}
          activeRoute={routes.rezzilPlayerManageUsers}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
          dataLoading={dataLoading}
        >
          <Title
            text={'Manage Users'}
            />
            <RowContentWrapper>
              <SquadManager
              squadCode={squadCode}
              handleFetchNewSquadCode={handleFetchNewSquadCode}
              numberOfLicences={numberOflicences}
              accountName={oculusAccountName ? oculusAccountName : '--'}
              stripeProducts={stripeProducts}
            />
              <InfoBlock info={
                [
                  'Your unique code must first be registered with the device you wish to become Squad Manager.',
                  'Keep your code safe and only give it to users you wish to become a squad leader.',
                  'Regenerate your code anytime.'
                ]} />
            </RowContentWrapper>

            <RowContentWrapper>
              <StyledSectionContainer>
                <SectionContainer
                  sectionTitle={'Squad Devices'}
                  sectionDescription={'To add a device, enter your unique code in the Squad Pro panel in Rezzil Player.'}
                  sectionTitleInlineDescription={`${squadDevices.length} of ${squadDeviceLimit} devices used`}
                >
                  <SquadDevicesList data={squadDevices}/>
                </SectionContainer>
              </StyledSectionContainer>
              <InfoBlock
                info={['To add more Squad leaders input your unique code in the Squad Pro panel.']}
                isMultiLine
              />
            </RowContentWrapper>
            <RowContentWrapper>
              <StyledSectionContainer>
                <SectionContainer
                  sectionTitle={'Squad Players'}
                  sectionDescription={'Secondary users can be added to your club here or in the Oculus headset.'}
                  sectionTitleInlineDescription={`${squadMembers.length} of ${squadMemberLimit} players used`}
                >
                  <SquadPlayersList
                    data={squadMembers}
                    handlePlayerDelete={handlePlayerDelete}
                    clubId={baseContext.baseData.clubProfile.id}
                    onPlayerSubmit={onPlayerSubmit}
                    showAddButton={oculusAccountName && (squadMembers.length <= squadMemberLimit)}
                  />
                </SectionContainer>
              </StyledSectionContainer>
              <InfoBlock
                info={['Once a Squad Player is deleted their stats will be lost.']}
                isMultiLine
              />
            </RowContentWrapper>
        </MainWrapper>
      }
      </>
  );
}

export default ManageUsers;