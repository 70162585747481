import {useState, useEffect, useCallback} from 'react';
import {
  Container,
  AverageContainer,
  BodyContainer,
  ButtonsContainer,
  ShieldsContainer,
  StyledImage,
  ChartWrapper,
  ChartLine,
  ChartContainer,
} from './IndexBreakdownBody.styles';
import {indexBreakdownDrillScoresOptions} from '../../../options/indexBreakdownOptions';
import AverageLevelStats from '../AverageLevelStats/AverageLevelStats';
import IndexBreakdownDrillStats from '../IndexBreakdownDrillStats/IndexBreakdownDrillStats';
import IndexBreakdownChart from '../../Charts/IndexBreakdownChart/IndexBreakdownChart';
import IconButton from '../../Buttons/IconButton/IconButton';
import BronzeShield from '../../../assets/svgs/shield-bronze.svg';
import SilverShield from '../../../assets/svgs/shield-silver.svg';
import GoldShield from '../../../assets/svgs/shield-gold.svg';
import {ArrowBackOutlined, ArrowForwardOutlined} from '@mui/icons-material';

// Helpers
import useDimensions from '../../../utils/helpers/useDimensions';

const IndexBreakdownBody = ({data}) => {
  const {maxItems, itemWidth, containerWidth, showArrows} = useDimensions(data);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [linePositions] = useState(['top', 'middle', 'bottom']);
  const [statIndexInView, setStatIndexInView] = useState(1);
  const [scrollDirection, setScrollDirection] = useState('next');
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(true);

  useEffect(() => {
    // Reset scrolled states on window resize
    const handleResize = () => {
      setStatIndexInView(1);
      setScrollDirection('next');
      setShowPrev(false);
      setShowNext(true);
      const bodyContainer = document.getElementById('bodyContainer');
      if (bodyContainer) bodyContainer.scrollTo(0, 0);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getChartData = useCallback(() => {
    // Get labels and values for line chart
    let labels = [];
    let values = [];
    if (data && indexBreakdownDrillScoresOptions.length > 0) {
      indexBreakdownDrillScoresOptions.forEach(({statId, title}) => {
        // Only set the label and value if the data for the relevant statId exists
        if (data[statId]) {
          labels.push(title);
          values.push(data[statId].path ? data[statId].path : 0);
        }
      });
    }
    setChartLabels(labels);
    setChartData(values);
  }, [data]);

  useEffect(() => getChartData(), [getChartData]);

  const handlePrevClick = () => {
    // Scroll to previous items on click
    if (statIndexInView <= 1) return;
    setShowNext(true);
    let indexToScroll = statIndexInView;
    if (maxItems === 4) {
      indexToScroll = 1;
    } else if (maxItems === 3) {
      if (statIndexInView === 5) {
        indexToScroll = 2;
      } else {
        indexToScroll = 1;
      }
    } else if (maxItems === 2) {
      if (statIndexInView === 5) {
        indexToScroll = 3
      } else if (statIndexInView === 4 || (statIndexInView === 3 && scrollDirection === 'prev')) {
        indexToScroll = 2
      } else {
        indexToScroll = 1;
      }
    } else {
      indexToScroll--;
    }
    const el = document.getElementById(`breakdown-stat-${indexToScroll}`);
    el.scrollIntoView();
    setStatIndexInView(indexToScroll);
    setScrollDirection('prev');
    if (indexToScroll === 1) setShowPrev(false);
  };

  const handleNextClick = () => {
    // Scroll to next items on click
    const dataLength = Object.keys(data).length - 1;
    if (statIndexInView >= dataLength) return;
    setShowPrev(true);
    let indexToScroll = statIndexInView;
    if (maxItems === 4 && maxItems < dataLength) {
      indexToScroll = 5;
    } else if (maxItems === 3 && maxItems < dataLength) {
      if (statIndexInView === 1) {
        indexToScroll = 4;
      } else {
        indexToScroll = dataLength;
      }
    } else if (maxItems === 2 && maxItems < dataLength) {
      if (statIndexInView === 1) {
        indexToScroll = 3;
      } else if (
        (indexToScroll === 2 || (indexToScroll === 3 && scrollDirection === 'next'))
        && maxItems < dataLength
      ) {
        indexToScroll = 4;
      } else {
        indexToScroll = dataLength;
      }
    } else {
      indexToScroll++;
    }
    const el = document.getElementById(`breakdown-stat-${indexToScroll}`);
    el.scrollIntoView();
    setStatIndexInView(indexToScroll);
    setScrollDirection('next');
    if (indexToScroll === dataLength) setShowNext(false);
  };

  return (
    <Container>
      <AverageContainer>
        <AverageLevelStats
          data={data.averages}
        />
      </AverageContainer>
      <BodyContainer id='bodyContainer'>
        {
          showArrows &&
          <ButtonsContainer>
            {
              showPrev &&
              <IconButton
                id='prev'
                icon={<ArrowBackOutlined/>}
                onClick={handlePrevClick}
              />
            }
            {
              showNext &&
              <IconButton
                id='next'
                icon={<ArrowForwardOutlined/>}
                onClick={handleNextClick}
              />
            }
          </ButtonsContainer>
        }
        <ShieldsContainer>
          <StyledImage
            src={GoldShield}
            alt={'Gold Shield'}
          />
          <StyledImage
            src={SilverShield}
            alt={'Silver Shield'}
          />
          <StyledImage
            src={BronzeShield}
            alt={'Bronze Shield'}
          />
        </ShieldsContainer>
        <ChartWrapper
          width={containerWidth}
        >
          {
            linePositions.length > 0 && linePositions.map(position => (
              <ChartLine
                key={position}
                position={position}
              />
            ))
          }
          <ChartContainer
            id='breakdownChartContainer'
          >
            <IndexBreakdownChart
              loading={false}
              labels={chartLabels}
              data={chartData}
            />
          </ChartContainer>
        </ChartWrapper>
        <IndexBreakdownDrillStats
          data={data}
          rowWidth={itemWidth}
        />
      </BodyContainer>
    </Container>
  );
};

export default IndexBreakdownBody;
