import {
  Container,
  FieldContainer,
} from './InvitePlayerForm.styles';
import CustomTextField from '../../Inputs/CustomTextField/CustomTextField';

const InvitePlayerForm = ({formData, errors, onChange}) => {
  return (
    <Container>
      <FieldContainer>
        <CustomTextField
          id={'email'}
          placeholder={'email@email.com'}
          onChange={onChange}
          error={errors.email}
          type={'email'}
          value={formData.email}
        />
      </FieldContainer>
    </Container>
  )
};

export default InvitePlayerForm;
