import {
  FactCheckOutlined,
  ThumbUpAltOutlined,
  ThumbDownAltOutlined,
  SportsSoccerOutlined,
  AlarmOnOutlined,
  CalendarViewDayOutlined,
  RadarOutlined,
  CallSplitOutlined,
  ExploreOutlined,
} from '@mui/icons-material';

export const drillStatsToggleOptions = [
  // {
  //   id: 'bestScore',
  //   icon: <FactCheckOutlined/>,
  //   title: 'Score',
  //   playerScores: [],
  //   worldScore: '',
  // },
  {
    id: 'totalScore',
    icon: <FactCheckOutlined />,
    title: 'Total Score',
    playerScores: [],
    worldScore: '',
  },
  {
    id: 'score',
    icon: <FactCheckOutlined />,
    title: 'Score',
    playerScores: [],
    worldScore: '',
  },
  {
    id: 'correct',
    icon: <ThumbUpAltOutlined />,
    title: 'Correct',
    playerScores: [],
    worldScore: '',
  },
  {
    id: 'incorrect',
    icon: <ThumbDownAltOutlined />,
    title: 'Incorrect',
    playerScores: [],
    worldScore: '',
  },
  {
    id: 'rightFoot',
    icon: <SportsSoccerOutlined />,
    title: 'Right Foot',
    playerScores: [],
    worldScore: '',
  },
  {
    id: 'leftFoot',
    icon: <SportsSoccerOutlined />,
    title: 'Left Foot',
    playerScores: [],
    worldScore: '',
  },
  {
    id: 'avgDwell',
    icon: <AlarmOnOutlined />,
    title: 'Dwell Time',
    playerScores: [],
    worldScore: '',
  },
  {
    id: 'level',
    icon: <CalendarViewDayOutlined />,
    title: 'Level',
    playerScores: [],
    worldScore: '',
  },
];






export const rezzilPlayerDrillStatsToggleOptions = [
  {
    id: 'totalScore',
    icon: <FactCheckOutlined />,
    title: 'Total Score',
    worldScore: '',
  },
  {
    id: 'timePlayed',
    icon: <FactCheckOutlined />,
    title: 'Time Played',
    worldScore: '',
  },
  {
    id: 'totalBallsLaunched',
    icon: <SportsSoccerOutlined />,
    title: 'Total Balls Launched',
    worldScore: '',
  },
  {
    id: 'totalBallsHit',
    icon: <RadarOutlined />,
    title: 'Total Balls Hit',
    worldScore: '',
  },
  {
    id: 'totalMissed',
    icon: <CallSplitOutlined />,
    title: 'Total Missed',
    worldScore: '',
  },
  {
    id: 'bestStreak',
    icon: <CallSplitOutlined />,
    title: 'Best Streak',
    worldScore: '',
  },
  {
    id: 'avgHeadSpeed',
    icon: <ExploreOutlined />,
    title: 'Avg. Head Speed',
    worldScore: '',
  },
  {
    id: 'avgAccuracy',
    icon: <ExploreOutlined />,
    title: 'Accuracy',
    worldScore: '',
  },
  {
    id: 'avgStrikePoint',
    icon: <RadarOutlined />,
    title: 'Avg. Strike Point',
    worldScore: '',
  },
  {
    id: 'totalSnaps',
    icon: <RadarOutlined />,
    title: 'Total Snaps',
    worldScore: '',
  },
  {
    id: 'totalCaught',
    icon: <RadarOutlined />,
    title: 'Total Caught',
    worldScore: '',
  },
  {
    id: 'totalPassesComplete',
    icon: <RadarOutlined />,
    title: 'Total Passes Complete',
    worldScore: '',
  },
  {
    id: 'bestStreakEver',
    icon: <RadarOutlined />,
    title: 'Best Streak',
    worldScore: '',
  },
  {
    id: 'averageReleaseTime',
    icon: <RadarOutlined />,
    title: 'Average Release Time',
    worldScore: '',
  },
  {
    id: 'overallAverageAccuracy',
    icon: <RadarOutlined />,
    title: 'Overall Average Accuracy',
    worldScore: '',
  },
  {
    id: 'bestScore',
    icon: <RadarOutlined />,
    title: 'Best Score',
    worldScore: '',
  },
  {
    id: 'bestScoreChase',
    icon: <RadarOutlined />,
    title: 'Best Score Chase',
    worldScore: '',
  },
  {
    id: 'totalHits',
    icon: <RadarOutlined />,
    title: 'Total Hits',
    worldScore: '',
  },
  {
    id: 'fastestReaction',
    icon: <RadarOutlined />,
    title: 'Fastest Reaction',
    worldScore: '',
  },
  {
    id: 'slowestReaction',
    icon: <RadarOutlined />,
    title: 'Slowest Reaction',
    worldScore: '',
  },
  {
    id: 'avgReactionTime',
    icon: <RadarOutlined />,
    title: 'Avg. Reaction Time',
    worldScore: '',
  },
  {
    id: 'totalHit',
    icon: <RadarOutlined />,
    title: 'Total Hit',
    worldScore: '',
  },
  {
    id: 'avgMoveSpeed',
    icon: <RadarOutlined />,
    title: 'Avg. Move Speed',
    worldScore: '',
  },
  {
    id: 'totalBonusits',
    icon: <RadarOutlined />,
    title: 'totalBonusHits',
    worldScore: '',
  },
  {
    id: 'totalGoalsScored',
    icon: <RadarOutlined />,
    title: 'Total Goals Scored',
    worldScore: '',
  },
  {
    id: 'totalGoalsMissed',
    icon: <RadarOutlined />,
    title: 'Total Goals Missed',
    worldScore: '',
  },
  {
    id: 'totalGoalsSaved',
    icon: <RadarOutlined />,
    title: 'Total Goals Saved',
    worldScore: '',
  },
  {
    id: 'totalCorrectPasses',
    icon: <RadarOutlined />,
    title: 'Total Correct Passes',
    worldScore: '',
  },
  {
    id: 'totalWrongPasses',
    icon: <RadarOutlined />,
    title: 'Total Wrong Passes',
    worldScore: '',
  },


];

export const rezzilPlayerLevelStatsToggleOptions = [
  {
    id: 'totalScore',
    title: 'Total Score',
    worldScore: '',
  },
  {
    id: 'totalBalls',
    title: 'Total Balls',
    worldScore: '',
  },
  {
    id: 'totalPortalsHit',
    title: 'Total Hits',
    worldScore: '',
  },
  {
    id: 'bestStreak',
    title: 'Best Streak',
    worldScore: '',
  },
  {
    id: 'avgAccuracy',
    title: 'Accuracy',
    worldScore: '',
    suffix: '%',
  },
  {
    id: 'avgHeadSpeed',
    title: 'Head Speed',
    worldScore: '',
    suffix: 'm/s',
  },
  {
    id: 'avgStrikePoint',
    title: 'Strike Point',
    worldScore: '',
  },
  {
    id: 'performanceRatio',
    title: 'Perf. Ratio',
    worldScore: '',
  },
]