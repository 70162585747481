import {
  RezzilLogoContainer,
  RezzilLogo,
} from './RezzilLogoAvatar.styles';
import Logo from '../../../assets/images/rezzil-logo-nav.png';

const RezzilLogoAvatar = () => {
  return (
    <RezzilLogoContainer>
      <RezzilLogo
        src={Logo}
        alt='Rezzil'
      />
    </RezzilLogoContainer>
  );
};

export default RezzilLogoAvatar;
