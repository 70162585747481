import {useEffect, useState} from 'react';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {
  Container,
  ContentContainer,
  SubscriptionDetailsContainer,
  Form,
  FormContainer,
  InputRow,
  InputColumn,
  StripeInputWrapper,
  InputWrapper,
  InputSpacing,
  FeatureLinesContainer,
  IconAvatarContainer,
  SubscriptionTitle,
  PaymentContainer,
  PaymentTitle,
  PaymentAmount
} from './CheckoutForm.styles';
import IconAvatar from '../../Avatars/IconAvatar/IconAvatar';
import FeatureLine from '../../Typography/FeatureLine/FeatureLine';
import {colors} from '../../../options/colors';
import Label from '../../Typography/Label/Label';
import FieldError
  from '../../Inputs/FieldError/FieldError';
import SolidButton from '../../Buttons/SolidButton/SolidButton';
import SectionSpinner from '../../Loading/SectionSpinner/SectionSpinner';
import {routes} from '../../../options/routes';
import { getSubscriptionPrice } from '../../../utils/helpers/subscriptionsHelper';

const fieldOptions = {
  style: {
    base: {
      iconColor: colors.primaryGreen,
      color: colors.white,
      fontWeight: '500',
      fontFamily: 'GraphikRegular, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      lineHeight: '46px',
    },
    invalid: {
      iconColor: colors.secondaryRed,
      color: colors.secondaryRed,
    },
  },
};

// const returnURL = `http://localhost:3000${routes.adminProducts}`
const returnURL = `https://rezzil-portal.test-link.co.uk${routes.adminProducts}/checkoutRedirect`

const CheckoutForm = ({ clientSecret, licence, paymentAmount, stripeProducts }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [cardNumberError, setCardNumberError] = useState();
  const [cardExpiryError, setCardExpiryError] = useState();
  const [cardCVCError, setCardCVCError] = useState();
  const [miscCheckoutError, setMiscCheckoutError] = useState();

  useEffect(() => {
    const cardNumberElement = elements?.create("cardNumber", fieldOptions);
    const cardExpiryElement = elements?.create("cardExpiry", fieldOptions);
    const cardCvvElement = elements?.create("cardCvc", fieldOptions);

    cardNumberElement?.mount("#card-number-element");
    cardExpiryElement?.mount("#card-expiry-element");
    cardCvvElement?.mount("#card-cvv-element");
  }, [elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || processing) {
      // Stripe.js has not yet loaded.
      return;
    }
    setProcessing(true);

    setCardNumberError('');
    setCardCVCError('');
    setCardExpiryError('');
    setMiscCheckoutError('');

    try {
      const result = await stripe.confirmCardPayment(
        clientSecret,
        {
          return_url: returnURL,
          payment_method: {
            card: elements.getElement('cardNumber'),
          },
          // setup_future_usage: 'off_session', // Use this for saving the card for later
        }
      )

      if (result.error) {
      // Show error to customer (for example, payment details incomplete)
      /*
        {
            "invalid_number": "The card number is not a valid credit card number",
            "invalid_expiry_month": "The card’s expiration month is invalid",
            "invalid_expiry_year": "The card’s expiration year is invalid",
            "invalid_cvc": "The card’s security code is invalid",
            "incorrect_number": "The card number is incorrect",
            "incomplete_number": "The card number is incomplete",
            "incomplete_cvc": "The card’s security code is incomplete",
            "incomplete_expiry": "The card’s expiration date is incomplete",
            "expired_card": "The card has expired",
            "incorrect_cvc": "The card’s security code is incorrect",
            "incorrect_zip": "The card’s zip code failed validation",
            "invalid_expiry_year_past": "The card’s expiration year is in the past",
            "card_declined": "The card was declined",
            "missing": "There is no card on a customer that is being charged",
            "processing_error": "An error occurred while processing the card"
        }
      */
        switch (result.error?.code) {
          case 'invalid_number':
          case 'incorrect_number':
          case 'incomplete_number':
            setCardNumberError(result.error.message);
            break;
          case 'incomplete_expiry':
          case 'invalid_expiry_month':
          case 'invalid_expiry_year':
          case 'invalid_expiry_year_past':
            setCardExpiryError(result.error.message);
            break;
          case 'invalid_cvc':
          case 'incomplete_cvc':
          case 'incorrect_cvc':
            setCardCVCError(result.error.message);
            break;
          default:
            console.log('Unknown / Misc error code:')
            console.log(result.error)
            if (result.error.message) {
              setMiscCheckoutError(result.error.message);
            }
        }
        console.log(result.error.message);
      } else if (result.paymentIntent?.status === 'succeeded') {
        const subscriptionTokens = JSON.parse(localStorage.getItem('subscriptionTokens')) || [];
        if (subscriptionTokens && subscriptionTokens.length) {
          // Remove subscription local storage for subscription that has just been used
          const existingTokens = subscriptionTokens.filter((token) => token.clientSecret !== clientSecret);
          localStorage.setItem('subscriptionTokens', JSON.stringify(existingTokens));
        }

        window.location.href = `${returnURL}/#checkout-complete`;
        // Customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
      setProcessing(false);
    } catch (error) {
      console.log('Unknown checkout error:');
      console.log(error);
      setProcessing(false);
    }
  }

  return (
    <Container>
      {!stripe || !elements ?
        <SectionSpinner /> :
        <ContentContainer>
          <SubscriptionDetailsContainer>
            {
              paymentAmount &&
              <PaymentContainer>
                <PaymentTitle>Total Payment</PaymentTitle>
                <PaymentAmount>{paymentAmount}</PaymentAmount>
              </PaymentContainer>
            }
            <IconAvatarContainer>
              <IconAvatar
                bgColor={licence.avatarBgColor}
                icon={licence.icon}
                />
              <SubscriptionTitle>{licence.shortTitle}</SubscriptionTitle>
            </IconAvatarContainer>

          {
            licence.features && licence.features.map(feature => (
              <FeatureLinesContainer
                key={feature}
              >
                <FeatureLine
                  text={
                    feature.replace(
                      '{formattedPrice}', getSubscriptionPrice(stripeProducts, licence.licenceId, licence.billingPeriod).formatted
                    )
                  }
                />
              </FeatureLinesContainer>
            ))
          }
          </SubscriptionDetailsContainer>
          <FormContainer>
            <Form onSubmit={handleSubmit}>
            <InputWrapper>
              <Label
                text={'Card Number'}
                required={true}
              />
              <StripeInputWrapper id="card-number-element" />
              {cardNumberError && <FieldError error={cardNumberError} />}
            </InputWrapper>
            <InputRow>
              <InputColumn>
                <InputWrapper>
                  <Label
                    text={'Expiry Date'}
                    required={true}
                  />
                  <StripeInputWrapper id="card-expiry-element" />
                  {cardExpiryError && <FieldError error={cardExpiryError} />}
                </InputWrapper>
              </InputColumn>
              <InputSpacing />
              <InputColumn>
                <InputWrapper>
                  <Label
                    text={'CVV'}
                    required={true}
                  />
                  <StripeInputWrapper id="card-cvv-element" />
                  {cardCVCError && <FieldError error={cardCVCError} />}
                </InputWrapper>
              </InputColumn>
            </InputRow>
            {miscCheckoutError && <FieldError error={miscCheckoutError} />}
            <SolidButton
              text={'Confirm Payment'}
              onClick={handleSubmit}
              loading={processing}
            />
          </Form>
          </FormContainer>
        </ContentContainer>
      }
    </Container>
  );
};

export default CheckoutForm;
