import {StyledButton} from './OutlinedButton.styles';

const OutlinedButton = ({text, href, onClick, fullWidth, fileInput, disabled}) => {
  return (
    <StyledButton
      variant='text'
      component={fileInput ? 'span' : undefined}
      href={href ? href : undefined}
      target={href ? '_blank' : undefined}
      onClick={onClick ? onClick : undefined}
      full={fullWidth ? 'true' : undefined}
      disabled={disabled}
    >
      {text}
    </StyledButton>
  );
};

export default OutlinedButton;
