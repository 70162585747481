import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../options/colors';

export const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: `1px solid ${colors.dividerGrey}`,
  borderBottom: `1px solid ${colors.dividerGrey}`,
  padding: '15px 0',
  '&:first-of-type': {
    borderTop: 'none',
    paddingTop: 0,
  },
  '&:last-of-type': {
    borderBottom: 'none',
    paddingBottom: 0,
  },
}));

export const TextContainer = styled('div')(() => ({
  marginRight: 15,
}));

export const HeadlineContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginBottom: 10,
}));

export const DateText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontSize: 15,
  marginRight: 30,
}));

export const SubjectText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  fontSize: 15,
}));

export const BodyContainer = styled('div')(() => ({}));

export const BodyText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 14,
  color: colors.secondaryGrey,
}));

export const ButtonContainer = styled('div')(() => ({}));
