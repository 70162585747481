import {useEffect, useState, useCallback, createContext, useContext} from 'react';
import {routes} from '../../options/routes';

// Helpers
import {
  getPlayerProfiles,
  getGroups,
} from '../../utils/helpers/playerProfilesHelpers';

// API calls
import {
  getClubPlayers,
  getClubGroups,
} from '../../api';

// Contexts
import {BaseContext} from '../Global/BaseProvider';

const PlayerProfilesContext = createContext({});

const PlayerProfilesProvider = ({children}) => {
  const baseContext = useContext(BaseContext);
  const [playerProfilesData, setPlayerProfilesData] = useState({
    playerProfiles: null,
    groups: null,
    apiError: false,
  });

  const getPlayerProfilesData = useCallback(async () => {
    // Only trigger API call for index playerProfiles route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.indexPlayerProfiles) return;

    if (!baseContext.baseData.loading && !playerProfilesData.loading) {
      setPlayerProfilesData({ loading: true });
      const {id} = baseContext.baseData.clubProfile;
      // Call player profiles API
      const clubPlayerProfilesData = await getClubPlayers(id);
      if (!clubPlayerProfilesData || !clubPlayerProfilesData.success || clubPlayerProfilesData.code !== 200) {
        setPlayerProfilesData({
          loading: false,
          playerProfiles: null,
          groups: null,
          apiError: true,
          allLoaded: true,
        });
      }

      // Call club groups API
      const clubGroupsData = await getClubGroups(id);
      if (!clubGroupsData || !clubGroupsData.success || clubGroupsData.code !== 200) {
        setPlayerProfilesData({
          loading: false,
          playerProfiles: null,
          groups: null,
          apiError: true,
          allLoaded: true,
        });
      }
      const {profiles} = clubPlayerProfilesData.data;
      const {data} = clubGroupsData;

      const playerProfiles = getPlayerProfiles(profiles);
      const groups = getGroups(data);

      setPlayerProfilesData({
        loading: false,
        playerProfiles,
        groups,
        apiError: false,
        allLoaded: true,
      });
    }
  }, [baseContext]);

  useEffect(() => {
    getPlayerProfilesData();
  }, [getPlayerProfilesData]);

  return (
    <PlayerProfilesContext.Provider
      value={{
        playerProfilesData,
        getPlayerProfilesData
      }}
    >
      {children}
    </PlayerProfilesContext.Provider>
  );
}

export {PlayerProfilesProvider, PlayerProfilesContext};

export default PlayerProfilesProvider;
