import {styled} from '@mui/material';

export const InnerContainer = styled('div')(() => ({
  padding: '30px 20px',
}));

export const InlineFieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& > div:nth-of-type(1)': {
    display: 'inline-block',
    width: '47%',
  },
  '& > div:nth-of-type(2)': {
    display: 'inline-block',
    width: '47%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'unset',
    '& > div': {
      width: '100% !important',
    }
  },
}));

export const PhoneFieldContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& > div:nth-of-type(1)': {
    display: 'inline-block',
    width: 125,
    marginRight: 20,
  },
  '& > div:nth-of-type(2)': {
    display: 'inline-block',
    width: '100%',
  },
}));

export const FieldContainer = styled('div')(() => ({
  marginBottom: 20,
}));
