import {useState} from 'react';
import {
  StyledTable,
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
} from './InvoicesList.styles';
import {invoicesColumns} from '../../../options/tableColumns';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import CustomTableHead from '../CustomTableHead/CustomTableHead';
import IconButton from '../../Buttons/IconButton/IconButton';
import {FileDownloadOutlined} from '@mui/icons-material';

// Helpers
import {getComparator, stableSort, handleRequestSort} from '../../../utils/tableSortHelpers/tableSortHelpers';
import {formatStandardDateString} from '../../../utils/helpers/formatDateString';

const InvoicesList = ({invoicesList}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleDownloadClick = link => {
    window.open(link, '_blank');
  };

  return (
    <>
      <CustomTableWrapper
        noResultsText={(!invoicesList || invoicesList.length === 0) && 'No Invoices to display'}
      >
        <StyledTable>
          <CustomTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(e, id) => handleRequestSort(e, id, orderBy, order, setOrder, setOrderBy)}
            columnHeadings={invoicesColumns}
          />
          <StyledTableBody>
            {
              invoicesList.length > 0 &&
              stableSort(invoicesList, getComparator(order, orderBy))
                .map(({id, date, docId, docType, billedAmount, link}) => (
                  <StyledTableRow
                    key={id}
                  >
                    <StyledTableCell>
                      {formatStandardDateString(date, true)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {docId}
                    </StyledTableCell>
                    <StyledTableCell>
                      {docType}
                    </StyledTableCell>
                    <StyledTableCell>
                      {billedAmount}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        icon={<FileDownloadOutlined/>}
                        onClick={() => handleDownloadClick(link)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            }
          </StyledTableBody>
        </StyledTable>
      </CustomTableWrapper>
    </>
  );
};

export default InvoicesList;
