const getUserRole = () => JSON.parse(localStorage.getItem('userRole'));
const managingClub = () => JSON.parse(localStorage.getItem('manageClubId'));

export const isRezzilSuperAdmin = (role) => {
  const userRole = role || getUserRole();
  return userRole === 'REZZIL_SUPER_ADMIN';
};

export const isRezzilTeamAdmin = (role) => {
  const userRole = role || getUserRole();
  return userRole === 'REZZIL_TEAM_ADMIN';
};

export const isRezzilTeamMember = (role) => {
  const userRole = role || getUserRole();
  return userRole === 'REZZIL_TEAM_MEMBER';
};

export const isRepTeamAdmin = (role) => {
  const userRole = role || getUserRole();
  return userRole === 'REP_TEAM_ADMIN';
};

export const isRepTeamMember = (role) => {
  const userRole = role || getUserRole();
  return userRole === 'REP_TEAM_MEMBER';
};

export const isClubSuperAdmin = (role) => {
  const userRole = role || getUserRole();
  return userRole === 'CLUB_SUPER_ADMIN';
};

export const isClubAdmin = (role) => {
  const userRole = role || getUserRole();
  return userRole === 'CLUB_ADMIN';
};

export const isClubMember = (role) => {
  const userRole = role || getUserRole();
  return userRole === 'CLUB_MEMBER';
};

export const isClubPlayer = (role) => {
  const userRole = role || getUserRole();
  return userRole === 'CLUB_PLAYER';
};

export const canViewManageAdmin = (role) => {
  return isRezzilSuperAdmin(role) ||
    isRezzilTeamAdmin(role) ||
    isRezzilTeamMember(role) ||
    isRepTeamAdmin(role) ||
    isRepTeamMember(role);
}

export const canViewAdmin = (role) => {
  return canViewManageAdmin(role) || isClubSuperAdmin(role) || isClubAdmin(role);
}

export const isAboveClubPlayer = (role) => {
  return !isClubPlayer(role);
}

export const isAboveClubMember = (role) => {
  return isAboveClubPlayer(role) && !isClubMember(role);
}

export const isAboveClubAdmin = (role) => {
  return isAboveClubMember(role) && !isClubAdmin(role);
}

export const isRep = (role) => {
  return isRepTeamAdmin(role) || isRepTeamAdmin(role);
}

export const isRepManagingClub = () => {
  return isRep() && managingClub();
}

export const capitaliseUserRole = (role) => {
  return role.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}