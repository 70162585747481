import {useState, useEffect} from 'react';
import {
  StyledTabs,
  StyledTab,
} from './CustomTabGroup.styles';

const CustomTabGroup = ({ options, value, onChange }) => {
  // Set selected option to value, or the first option if no value
  const [selected, setSelected] = useState(value ? value : options[0].id);

  useEffect(() => {
    setSelected(value ? value : options[0].id);
  }, [value, options]);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    onChange(newValue);
  };

  if (!selected) {
    return <></>;
  }

  return (
    <StyledTabs
      value={selected}
      onChange={handleChange}
      selectionFollowsFocus
      justify={options.length > 3 ? 'true' : undefined}
    >
      {
        options && options.map(({id, icon, label}) => (
          <StyledTab
            key={id}
            value={id}
            icon={icon}
            label={label}
          />
        ))
      }
    </StyledTabs>
  );
}

export default CustomTabGroup;
