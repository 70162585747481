import {
  styled,
  Typography,
  Table,
} from '@mui/material';
import {
  StyledTableBody,
  StyledTableRow,
} from '../CustomTableWrapper/CustomTableWrapper.styles';
import {StyledTableCell} from '../CustomTableHead/CustomTableHead.styles';
import {colors} from '../../../options/colors';

export const StyledTable = styled(Table)(() => ({}));

export const LargeTableBody = styled(StyledTableBody)(() => ({
  '& .MuiTableCell-root': {
    padding: '20px 10px 20px 0',
    '&:first-of-type': {
      paddingLeft: 10,
    },
  },
}));

export const DeviceText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 18,
  marginBottom: 20,
}));

export const DeviceName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: colors.primaryGrey,
  marginBottom: 30,
  '&:last-of-type': {
      marginBottom: 0,
    },
}));

export {
  StyledTableRow,
  StyledTableCell,
};
