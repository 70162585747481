import { useEffect, useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledAccordionLink,
  HeadingContainer,
  AdminLinksContainer,
  ManageLinksContainer,
} from './AdminNavAccordion.styles';
import {
  mainNavLinks,
  adminLinks,
  adminManageLinks,
} from '../../../options/navLinks';
import AdminNavLink from '../../Links/AdminNavLink/AdminNavLink';
import Label from '../../Typography/Label/Label';

import { BaseContext } from '../../../providers/Global/BaseProvider';
import { canViewManageAdmin, isRezzilSuperAdmin } from '../../../utils/helpers/userRoleHelper';

const AdminNavAccordion = ({ activeRoute }) => {
  const baseContext = useContext(BaseContext);
  const [manageLinks, setManageLinks] = useState([]);
  const [showManageAdmin, setShowManageAdmin] = useState(false);

  useEffect(() => {
    if (!baseContext.baseData.loading) {
      if (!isRezzilSuperAdmin(baseContext.baseData.userRole)) {
        setManageLinks(adminManageLinks.filter((link) => link.id !== 'repCompanyManagement'))
      } else {
        const manageRepCompanyId = localStorage.getItem('manageRepCompanyId');
        if (!manageRepCompanyId) {
          // Hide reps list unless managing a rep company...
          setManageLinks(adminManageLinks.filter((link) => link.id !== 'repManagement'))
        } else {
          setManageLinks(adminManageLinks);
        }

      }
      setShowManageAdmin(
        canViewManageAdmin(baseContext.baseData.userRole)
      );
    }
  }, [baseContext])

  return (
    <StyledAccordion
      expanded
    >
      <StyledAccordionSummary
        id={'admin-nav-links'}
      >
        <StyledAccordionLink
          component={RouterLink}
          to={mainNavLinks[1].isExternal ? { pathname: mainNavLinks[1].route } : mainNavLinks[1].route}
          target={mainNavLinks[1].isExternal ? '_blank' : undefined}
        >
          {mainNavLinks[1].icon}{mainNavLinks[1].name}
        </StyledAccordionLink>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <AdminLinksContainer>
          <HeadingContainer>
            <Label
              text={'Admin'}
            />
          </HeadingContainer>
          {
            adminLinks && adminLinks.map(({icon, name, route, isExternal }) => (
              <AdminNavLink
                key={name}
                icon={icon}
                name={name}
                route={route}
                isExternal={isExternal}
                active={activeRoute === route}
              />
            ))
          }
        </AdminLinksContainer>
        {showManageAdmin &&
          <ManageLinksContainer>
            <HeadingContainer>
              <Label
                text={'Manage'}
              />
            </HeadingContainer>
            {
              manageLinks && manageLinks.map(({ icon, name, route, isExternal }) => (
                <AdminNavLink
                  key={name}
                  icon={icon}
                  name={name}
                  route={route}
                  isExternal={isExternal}
                  active={activeRoute === route}
                />
              ))
            }
          </ManageLinksContainer>
        }
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default AdminNavAccordion;
