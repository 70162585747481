import {
  StyledCard,
} from './SectionCard.styles';

const SectionCard = ({isRow, children}) => {
  return (
    <StyledCard isrow={isRow ? 'true' : undefined}>
      {children}
    </StyledCard>
  );
};

export default SectionCard;
