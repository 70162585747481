import {
  styled,
  Drawer,
  Link,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: 80,
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'none',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: colors.menuPrimaryBlack,
  zIndex: 999,
  '&::before': {
    content: '""',
    width: '100%',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: `
      linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255,255,255,1) 50%, rgba(255, 255, 255, 0) 100%)
    `,
  },
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
  },
  [theme.breakpoints.down('sm')]: {
    height: 60,
  },
  '@media print': {
    display: 'none',
  }
}));

export const BurgerContainer = styled('div')(() => ({
  margin: '0 20px',
}));

export const RezzilLogoContainer = styled('div')(({ theme }) => ({
  margin: '0 20px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 3,
    marginRight: 'auto',
  },
}));

export const ClubLogoContainer = styled('div')(({ theme }) => ({
  margin: '0 20px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

export const StyledLink = styled(Link)(() => ({
  cursor: 'pointer',
}));

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  top: 'unset',
  height: 'calc(100vh - 80px)',
  '& .MuiBackdrop-root': {
    height: 'inherit',
    top: 'inherit',
  },
  '& .MuiPaper-root': {
    height: 'inherit',
    top: 'inherit',
    maxWidth: 375,
    width: '100%',
    background: colors.menuPrimaryBlack,
  },
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 60px)',
  },
}));
