import {
  Container,
  StyledBurger,
  StyledBurgerText,
} from './CustomBurger.styles';

const CustomBurger = ({open, onClick}) => {
  return (
    <Container
      onClick={onClick}
    >
      <StyledBurger
        open={open}
      >
        <div/>
        <div/>
      </StyledBurger>
      <StyledBurgerText>
        Menu
      </StyledBurgerText>
    </Container>
  );
};

export default CustomBurger;
