import {
  styled,
  Typography
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'stretch',
}));

export const LicenceContainer = styled('div')(({ theme }) => ({
  '.MuiPaper-root': {
    backgroundColor: 'green',
    width: 215,
    background: 'rgba(255, 255, 255, 0.05)',
    // opacity: '0.05',
    padding: '20px 20px 25px 20px',
    margin: '30px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  '.MuiCardActions-root': {
    marginTop: 0,
  },
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 10,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  }

}));

export const PricePerLicence = styled('div')(() => ({
  fontSize: 12,
  color: colors.primaryGrey,
  marginBottom: 10,
}));

export const SelectContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 255,
}));

export const RowContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: 20,
  marginBottom: 20,
}));

export const InfoText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  marginTop: 20,
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 18,
  marginBottom: 5,
}));

export const CountText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: colors.primaryGrey,
}));

export const LicenceCount = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const LicencesContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))


