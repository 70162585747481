import axios from 'axios';
import { isClubPlayer, isClubSuperAdmin } from '../utils/helpers/userRoleHelper';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const BASE_LEADERBOARD_URL = process.env.REACT_APP_LEADERBOARD_API_ENDPOINT;
const LEADERBOARD_KEY = process.env.REACT_APP_LEADERBOARD_KEY;

const authWebService = async (url, method, data) => {
  // Generic API call function for unauthenticated endpoints
  try {
    const response = await axios({
      method,
      baseURL: BASE_URL,
      url: url,
      params: method === 'GET' ? data || {} : {},
      data: method !== 'GET' ? data || {} : {},
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 90000,
    });

    return response.data;
  } catch(error) {
    console.log(`Authentication error from ${url}`);
    console.error(error);
    console.log(error.message);
    return false;
  }
};

const emptyRepsonse = { data: [], success: true, code: 200 };

/* ------------------------------------------- Unauthenticated endpoints ------------------------------------------- */
// Same endpoint for direct and 2FA login
export const login = async data =>
  authWebService('user/login', 'POST', data);

export const forgotPassword = async data =>
  authWebService(`user/forgot-password`, 'GET', data);

export const resetPassword = async (token, data) =>
  authWebService(`user/reset-password/${token}`, 'PUT', data);

export const register = async data =>
  authWebService('user/signup', 'POST', data);

export const invokeWebService = async (url, method, data, isFile) => {
  // Generic API call function for authenticated endpoints
  try {
    // account object stored as string in localStorage - need to parse it and get the accessToken
    const token = JSON.parse(localStorage.getItem('token'));
    if (!token) {
      localStorage.clear();
      window.location.href = '/login';
      return;
    }
    // Set contentType to 'multipart/form-data' if passing files in the data
    const contentType = isFile ? 'multipart/form-data' : 'application/json';

    const response = await axios({
      method,
      baseURL: BASE_URL,
      url: url,
      // Pass data as params for GET request, or as data for all other requests
      params: method === 'GET' ? data || {} : {},
      data: method !== 'GET' ? data || {} : {},
      headers: {
        'Authorization': token,
        'Content-Type': contentType,
      },
      timeout: 90000,
    });

    return response.data;
  } catch(error) {
    console.log(`Request error from ${url}`);
    console.error(error);
    console.log(error.message);
    return false;
  }
}

/* ------------------------------------------------ Logout endpoint ------------------------------------------------ */
export const logout = async () =>
  invokeWebService('user/logout', 'GET');

/* -------------------------------------------- User profile endpoints -------------------------------------------- */
// Append id to the endpoint URL if provided
export const getUserProfile = async id =>
  invokeWebService(`user${id ? `/${id}` : ''}`, 'GET');

export const addUserProfile = async data =>
  invokeWebService('/user', 'POST', data);

export const updateUserProfile = async (id, data) =>
  invokeWebService(`user/${id}`, 'PUT', data);

export const updateUserPicture = async (id, data) =>
  invokeWebService(`user/upload-picture/${id}`, 'POST', data, true);

export const updateUserPassword = async (id, data) =>
  invokeWebService(`user/update-password/${id}`, 'PUT', data);

export const updateUserTwoStep = async data =>
  invokeWebService('user/set-twostep/', 'POST', data);

export const deleteUserProfile = async id =>
  invokeWebService(`user/${id}`, 'DELETE');

export const getUserRoles = async () =>
  invokeWebService('user/roles', 'GET');

/* ---------------------------------------------- Club User endpoints ---------------------------------------------- */
export const getClubUsers = async id =>
  invokeWebService(`club/club-users/${id}`, 'GET');

/* -------------------------------------------- Club Profile endpoints -------------------------------------------- */
export const getClubProfile = id =>
  invokeWebService(`club/${id}`, 'GET');

export const updateClubLogo = (id, data) =>
  invokeWebService(`club/upload-logo/${id}`, 'POST', data, true);

export const updateClubProfile = (id, data) =>
  invokeWebService(`club/${id}`, 'PUT', data);

/* --------------------------------------------- Club Player endpoints --------------------------------------------- */
export const getClubPlayers = id => {
  // Check for that user is not a club player and has access
  if (isClubPlayer()) {
    return { ...emptyRepsonse, data: { profiles: [] } };
  }
  return invokeWebService(`club/club-profiles/${id}`, 'GET');
}


export const getClubPlayerProfile = id =>
  invokeWebService(`profile/${id}`, 'GET');

export const addClubPlayerProfile = data =>
  invokeWebService('profile', 'POST', data);

export const updateClubPlayerProfile = (id, data) =>
  invokeWebService(`profile/${id}`, 'PUT', data);

export const updateClubPlayerBoardStatus = (id, data) =>
  invokeWebService(`profile/set-leaderboards-active/${id}`, 'PUT', data);

export const deleteClubPlayerProfile = id =>
  invokeWebService(`profile/${id}`, 'DELETE');

/* ----------------------------------------------- Groups endpoints ----------------------------------------------- */
export const getPlayerProfileGroups = id =>
  invokeWebService(`group/profile-group/${id}`, 'GET');

export const addPlayerProfileGroup = data =>
  invokeWebService('group/profile-group', 'POST', data);

export const deletePlayerProfileGroup = data =>
  invokeWebService('group/profile-group', 'DELETE', data);

export const getClubGroups = id =>
  invokeWebService(`group/club/${id}`, 'GET');

export const addClubGroup = data =>
  invokeWebService('group', 'POST', data);

export const updateClubGroup = (id, data) =>
  invokeWebService(`group/${id}`, 'PUT', data);

export const deleteClubGroup = id =>
  invokeWebService(`group/${id}`, 'DELETE');

/* ---------------------------------------- Club updates and feed endpoints ---------------------------------------- */
export const getUpdates = async clubId => {
    if (isClubPlayer()) {
      return { ...emptyRepsonse };
  }
  return  invokeWebService(`feed/updates/${clubId}`, 'GET');
}

export const getNewsFeed = async () =>
  invokeWebService('feed/news', 'GET');

export const getTwitterFeed = async () =>
  invokeWebService('feed/twitter', 'GET');

/* ---------------------------------------------- Club Admin endpoints ---------------------------------------------- */
export const getAllClubs = async () =>
  invokeWebService('club/all', 'GET');

export const getClub = async id =>
  invokeWebService(`club/${id}`);

export const getClubsByRep = async repId =>
  invokeWebService(`reps/clubs/${repId}`);

export const addClub = async (data) =>
  invokeWebService('club', 'POST', data);

export const updateClub = async (clubId, data) =>
  invokeWebService(`club/${clubId}`, 'PUT', data);

/* ---------------------------------------------- Rep Admin endpoints ---------------------------------------------- */
export const getRepsByCompany = async (id) =>
  invokeWebService(`reps/by-company/${id}`, 'GET');

export const addRep = async (data) =>
  invokeWebService(`reps`, 'POST', data);

export const updateRep = async (repId, data) =>
  invokeWebService(`reps/${repId}`, 'PUT', data);

export const deleteRep = async (repId) =>
  invokeWebService(`reps/${repId}`, 'DELETE');

export const getRepCompanies = async () =>
  invokeWebService('reps/company', 'GET');

export const addRepCompany = async (data) =>
  invokeWebService('reps/company', 'POST', data);

export const updateRepCompany = async (repCoId, data) =>
  invokeWebService(`reps/company/${repCoId}`, 'PUT', data);

/* ---------------------------------------- Index and drill stats endpoints ---------------------------------------- */
export const getAllProgrammeStats = async profileId =>
  invokeWebService(`stats/programmes/${profileId}`, 'GET');

export const getSingleProgrammeStats = async (profileId, programmeId) =>
  invokeWebService(`stats/programme/${profileId}/${programmeId}`, 'GET');

export const getLatestProgrammeStats = async (profileId) =>
  invokeWebService(`stats/programme/${profileId}/latest`, 'GET');

  export const getDrillSummaryStats = async (profileId) =>
  invokeWebService(`stats/drill-summaries/${profileId}`, 'GET');

/* --------------------------------------------- Leaderboard endpoints --------------------------------------------- */
export const getBoardsRezzilIndex = async id => {

  // Temp fix - We want to cach the call from this for now if possibe as the call is expensive

  const cachedLeaderboard = sessionStorage.getItem(`RezzilIndexLeaderboard[${id}]`);

  if (cachedLeaderboard && cachedLeaderboard !== 'false' && cachedLeaderboard !== 'undefined') {
    return JSON.parse(cachedLeaderboard)
  }

  // There is no cached leaderboard so fetch and store a new copy
  const newLeaderboard = await invokeWebService(`leaderboard/rezzil-index/${id}`, 'GET');
  sessionStorage.setItem(`RezzilIndexLeaderboard[${id}]`, JSON.stringify(newLeaderboard));
  return newLeaderboard;
}


export const getBoardsClubDrills = async id =>
  invokeWebService(`leaderboard/players/${id}`, 'GET');

export const getGlobalLeaderBoardStats = async () =>
  invokeWebService('leaderboard/global/', 'GET');

/* --------------------------------------------- Payment endpoints --------------------------------------------- */

export const getProducts = async () =>
  invokeWebService('payment/products', 'GET');

export const createSubscription = async (priceId, quantity = 1) =>
  invokeWebService('payment/subscribeInit', 'POST', {
    priceId,
    quantity,
  });

export const getSubscriptions = async (clubId) =>
  invokeWebService(`payment/subscriptions/${clubId}`, 'GET');

export const getPaymentMethods = async () => {
  if (!isClubSuperAdmin()) {
    return { ...emptyRepsonse };
  }
  return invokeWebService('payment/paymentMethods', 'GET');
}


export const getInvoices = async (clubId) =>
  invokeWebService(`/payment/invoices/${clubId}`, 'GET');

export const getClubLicences = async (clubId) =>
  invokeWebService(`club/licenses/${clubId}`, 'GET');

export const updatePaymentMethod = async (paymentMethodId, data) =>
  invokeWebService(`payment/paymentMethod/${paymentMethodId}`, 'PUT', data);

export const deletePaymentMethod = async (paymentMethodId) =>
  invokeWebService(`payment/paymentMethod/${paymentMethodId}`, 'DELETE');

export const subscriptionRenew = async (data) =>
  invokeWebService(`payment/subscriptionRenew/`, 'POST', data);

export const upgradeSubscription = async (data) =>
  invokeWebService(`payment/subscriptionUpgrade/`, 'POST', data);

export const downgradeSubscription = async (data) =>
  invokeWebService(`payment/subscriptionDowngrade/`, 'POST', data);


/* --------------------------------------------- Squad endpoints --------------------------------------------- */

export const getSquadCode = async (clubId) =>
  invokeWebService(`squads/code/${clubId}`, 'GET');

export const generateNewSquadCode = async (clubId) =>
  invokeWebService(`squads/code/${clubId}`, 'POST');

export const getSquadDevices = async (clubId) =>
  invokeWebService(`squads/devices/${clubId}`, 'GET');

export const getSquadMembers = async (clubId) =>
  invokeWebService(`squads/members/${clubId}`, 'GET');

export const addSquadMember = async (data) =>
  invokeWebService(`squads/members/`, 'POST', data);

export const deleteSquadMember = async (squadMemberId) =>
  invokeWebService(`squads/members/${squadMemberId}`, 'DELETE');

export const getSquadStatAverages = async (squadMemberId, level) =>
  invokeWebService(`squads/summaries/${squadMemberId}/${level}`, 'GET');

export const getSquadStatsLast10 = async (squadMemberId, level) =>
  invokeWebService(`squads/stats/${squadMemberId}/${level}`, 'GET');

export const getSquadPrimaryInfo = async () =>
  invokeWebService(`squads/primary`, 'GET');

export const invokeLeaderboardWebService = async (url) => {
  // Generic API call function for authenticated endpoints
  try {
    const response = await axios({
      method: 'GET',
      baseURL: BASE_LEADERBOARD_URL,
      url: url,
      // Pass data as params for GET request, or as data for all other requests
      params: {},
      data: {},
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 90000,
    });

    return response.data;
  } catch(error) {
    console.log(`Request error from ${url}`);
    console.error(error);
    console.log(error.message);
    return false;
  }
}

export const getTopLeaderboard = async (level, squadId) =>
  invokeLeaderboardWebService(`leaderboards/top/${level}/${LEADERBOARD_KEY}/10${squadId ? `/${squadId}` : ''}`, 'GET');

export const getNearUserLeaderboard = async (level, userPlatformId, squadId) => {
  const requestURL = `leaderboards/around/${level}/${encodeURIComponent(userPlatformId)}/${LEADERBOARD_KEY}/1${squadId ? `/${squadId}` : ''}`;
  console.log(requestURL);
  return invokeLeaderboardWebService(requestURL, 'GET');
}
