import {useEffect, useContext, useState} from 'react';
import {
  Container,
  BannerContainer,
  CaptionContainer,
  CaptionText,
} from './Licences.styles';
import {licenceTypes, licenceOptions} from '../../../options/licenceOptions';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import Banner from '../../../components/Alerts/Banner/Banner';
import StandardModal from '../../../components/Modals/StandardModal/StandardModal';
import SubscriptionNonPaymentForm from '../../../components/Forms/SubscriptionNonPaymentForm/SubscriptionNonPaymentForm';
import SubscriptionPaymentForm from '../../../components/Forms/SubscriptionPaymentForm/SubscriptionPaymentForm';
import StandardLink from '../../../components/Links/StandardLink/StandardLink';

// Contexts
import { BaseContext } from '../../../providers/Global/BaseProvider';
import { LicencesContext } from '../../../providers/Index/LicencesProvider';
import SubscriptionCard from '../../../components/Cards/SubscriptionCard/SubscriptionCard';
import { routes } from '../../../options/routes';

// API Calls

const Licences = () => {
  // Collapse accordions when matches value changes
  const baseContext = useContext(BaseContext);
  const licencesContext = useContext(LicencesContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [licenceDetails, setLicenceDetails] = useState([]);
  const [activeLicences, setActiveLicences] = useState([]);
  const [selectedLicence, setSelectedLicence] = useState('');
  const [activeLicence, setActiveLicence] = useState('');
  const [activeLicenceExpiry, setActiveLicenceExpiry] = useState(null);
  const [nonPaymentModalOpen, setNonPaymentModalOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [bannerError] = useState('');
  const [error, setError] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    if (!baseContext.baseData.loading && !licencesContext.licencesData.loading) {
      setDataLoading(false);
      setLicenceDetails(licencesContext.licencesData.licences.length > 0 ? licenceOptions.filter(licence => licence.id !== 1): licenceOptions);
      const date = new Date();
      setActiveLicenceExpiry(new Date(date.setDate(date.getDate() + 30)));
      setActiveLicences(licencesContext.licencesData.licences)
    }
  }, [baseContext, licencesContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handleLicenceClick = type => {
    switch (type) {
      case licenceTypes.nonCommercial:
        setSelectedLicence(type);
        setNonPaymentModalOpen(true);
        break;
      case licenceTypes.trial:
        setSelectedLicence(type);
        setNonPaymentModalOpen(true);
        break;
      case licenceTypes.monthlySub:
        setSelectedLicence(type);
        setPaymentModalOpen(true);
        break;
      case licenceTypes.annualSub:
        setSelectedLicence(type);
        setPaymentModalOpen(true);
        break;
      default:
        setError({
          show: true,
          message: 'Please choose a valid subscription type',
        });
    }
  };

  const handleNonPaymentModalClose = () => {
    setNonPaymentModalOpen(false);
    setSelectedLicence('');
  };

  const handleNonPaymentModalSubmit = async () => {
    await setModalLoading(true);
    setError({
      show: false,
      message: `Successfully activated ${selectedLicence} Subscription`,
    });
    setActiveLicence(selectedLicence);
    setModalLoading(false);
    handleNonPaymentModalClose();
  };

  const handlePaymentModalClose = () => {
    setPaymentModalOpen(false);
    setSelectedLicence('');
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.indexLicences}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <Title
            text={'Licences'}
          />
          {
            bannerError &&
            <BannerContainer>
              <Banner
                open={bannerError}
                text={bannerError}
              />
            </BannerContainer>
          }
          <Container
            length={activeLicences.length > 0 ? 2 : 3}
          >
            {
              licenceDetails && licenceDetails.length && licenceDetails.map(({
                id, avatarBgColor, icon, title, features, isRecommended
              }) => (
                <SubscriptionCard
                  key={id}
                  avatarBgColor={avatarBgColor}
                  icon={icon}
                  title={title}
                  features={features}
                  isRecommended={isRecommended}
                  onClick={() => handleLicenceClick(title)}
                  isExpired={
                    [licenceTypes.nonCommercial, licenceTypes.monthlySub, licenceTypes.annualSub].includes(activeLicence) &&
                    title === licenceTypes.trial
                  }
                  isActive={!activeLicences.length && id ===1}
                  showDowngrade={
                    (activeLicence === licenceTypes.annualSub && [licenceTypes.nonCommercial, licenceTypes.monthlySub].includes(title)) ||
                    (activeLicence === licenceTypes.monthlySub && title === licenceTypes.nonCommercial)
                  }
                  showUpgrade={
                    (activeLicence === licenceTypes.trial && title !== licenceTypes.trial) ||
                    (activeLicence === licenceTypes.nonCommercial && [licenceTypes.monthlySub, licenceTypes.annualSub].includes(title)) ||
                    (activeLicence === licenceTypes.monthlySub && title === licenceTypes.annualSub)
                  }
                />
              ))
            }
          </Container>
          <CaptionContainer>
            <CaptionText>
              For more info on Rezzil Index please visit
            </CaptionText>
            <StandardLink
              text={'rezzil.com'}
              href={'https://rezzil.com/'}
              isMailto
            />
          </CaptionContainer>
          {
            nonPaymentModalOpen &&
            <StandardModal
              open={nonPaymentModalOpen}
              onClose={handleNonPaymentModalClose}
              title={`${selectedLicence} Subscription`}
              btnText={'Cancel'}
              hideCancelBtn
            >
              <SubscriptionNonPaymentForm
                expiryDate={selectedLicence === licenceTypes.trial ? activeLicenceExpiry : null}
                licence={licenceDetails.find(licence => licence.title === selectedLicence)}
                loading={modalLoading}
                handleSubmit={handleNonPaymentModalSubmit}
              />
            </StandardModal>
          }
          {
            paymentModalOpen &&
            <StandardModal
              open={paymentModalOpen}
              onClose={handlePaymentModalClose}
              title={`${selectedLicence} Subscription`}
              btnText={'Cancel'}
              hideCancelBtn
            >
              <SubscriptionPaymentForm
                licence={licenceDetails.find(licence => licence.title === selectedLicence)}
              />
            </StandardModal>
          }
        </MainWrapper>
      }
    </>
  );
};

export default Licences;
