import {useState} from 'react';
import {
  RepCompaniesContainer,
  IconsContainer,
} from './RepCompaniesList.styles';
import {routes} from '../../../options/routes';
import {buttonTypes} from '../../../options/buttonTypes';
import SectionContainer from '../../Containers/SectionContainer/SectionContainer';
import ClubsDetails from '../../ClubsPage/ClubsDetails/ClubsDetails';
import IconButton from '../../Buttons/IconButton/IconButton';
import LinkButton from '../../Buttons/LinkButton/LinkButton';
import NoResultsText from '../../Typography/NoResultsText/NoResultsText';
import StandardModal from '../../Modals/StandardModal/StandardModal';
import RepCompanyForm from '../../Forms/RepCompanyForm/RepCompanyForm';
import {EditOutlined} from '@mui/icons-material';

const RepCompaniesList = ({
  repCompanies,
  onFieldChange,
  onSubmit,
  formData,
  formErrors,
  setFormData,
  setFormErrors,
  updateLoading,
}) => {
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [managingRepCompany, setManagingClub] = useState(localStorage.getItem('manageRepCompanyId'));
  const unManageRepCompany = () => {
    setManagingClub(undefined);
    localStorage.removeItem('manageRepCompanyId');
    localStorage.removeItem('manageRepCompanyName');
  }

  const setManageRepCompany = (repCompanyId, repCompanyName) => {
    unManageRepCompany();
    localStorage.setItem('manageRepCompanyId', repCompanyId);
    localStorage.setItem('manageRepCompanyName', repCompanyName);
    setManagingClub(repCompanyId);
    window.location.href = routes.repManagement;
  }

  const handleFormModalClose = () => {
    // Reset state back to original values
    setFormModalOpen(false);
    setIsAdd(true);
    setFormData({
      id: undefined,
      name: '',
      contact: '',
      phone: '',
      location: '',
      indexLicenceCount: '',
      indexLicenceDiscount: '',
      analysisLicenceCount: '',
      analysisLicenceDiscount: '',
      dbLicenceCount: '',
      dbLicenceDiscount: '',
      player22LicenceCount: '',
      player22LicenceDiscount: '',
      salesRep: '',
      revenue: '',
      link: '',
    });
    setFormErrors({
      name: '',
      contact: '',
      phone: '',
      location: '',
      indexLicenceCount: '',
      indexLicenceDiscount: '',
      analysisLicenceCount: '',
      analysisLicenceDiscount: '',
      dbLicenceCount: '',
      dbLicenceDiscount: '',
      player22LicenceCount: '',
      player22LicenceDiscount: '',
      salesRep: '',
      revenue: '',
      link: '',
    });
  };

  const handleFormModalOpen = id => {
    if (id) {
      // Find index of item with matching id and pass those details into modal
      const index = repCompanies.findIndex(el => el.id === id);
      setFormData(repCompanies[index]);
      setIsAdd(false);
    }
    setFormModalOpen(true);
  };

  const handleFormModalSubmit = async () => {
    const result = await onSubmit();
    if (result) {
      handleFormModalClose();
    }
  };

  return (
    <SectionContainer
      showBtn
      btnType={buttonTypes.link}
      btnText={'Add Rep Company'}
      onClick={() => handleFormModalOpen()}
    >
      {
        repCompanies && repCompanies.length > 0 && repCompanies.map(repCompany => (
          <RepCompaniesContainer
            key={repCompany.id}
          >
            <ClubsDetails
              club={repCompany}
            />
            <IconsContainer>
              <IconButton
                icon={<EditOutlined/>}
                onClick={() => handleFormModalOpen(repCompany.id)}
              />
              {
                managingRepCompany === repCompany.id.toString() &&
                <LinkButton
                  text={'Unmanage Rep Company'}
                  onClick={() => {
                    unManageRepCompany();
                    window.location.reload();
                  }}
                />
              }
              {
                managingRepCompany !== repCompany.id.toString() &&
                <LinkButton
                  text={'Manage Rep Company'}
                  onClick={() => setManageRepCompany(repCompany.id, repCompany.name)}
                />
              }
            </IconsContainer>
          </RepCompaniesContainer>
        ))
      }
      {
        (!repCompanies || repCompanies.length === 0) &&
        <NoResultsText
          text={'No Rep Companies to display'}
        />
      }
      {
        formModalOpen &&
        <StandardModal
          open={formModalOpen}
          onClose={handleFormModalClose}
          title={`${isAdd ? 'Add' : 'Edit'} Rep Company`}
          btnText={`${isAdd ? 'Add' : 'Update'} Rep Company`}
          onClick={handleFormModalSubmit}
          loading={updateLoading}
        >
            <RepCompanyForm
            formData={formData}
            errors={formErrors}
            onChange={onFieldChange}
          />
        </StandardModal>
      }
    </SectionContainer>
  );
};

export default RepCompaniesList;
