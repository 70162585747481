import {styled} from '@mui/material';

export const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 30,
  '@media print': {
    display: 'none',
  }
}));
