import {
  styled,
  Tooltip,
  Button,
} from '@mui/material';
import {colors} from '../../../options/colors';
import {Info} from '@mui/icons-material';

export const StyledTooltip = styled(Tooltip)(() => ({
  color: colors.primaryGrey,
  cursor: 'pointer',
}));

export const InfoButton = styled(Button)(() => ({
  width: 'auto',
  padding: 0,
  minWidth: 'unset',
  border: 'none',
  background: 'none',
  '&:hover': {
    border: 'none !important',
    background: 'none !important',
  },
  '&:focus': {
    border: 'none !important',
    background: 'none !important',
  },
}));

export const InfoIcon = styled(Info)(() => ({
  color: `${colors.beige} !important`,
  fontSize: 20,
}));
