import {useState} from 'react';
import {
  StyledCollapse,
  StyledAlert,
  StyledIconButton,
  StyledCloseIcon,
  TextContainer,
} from './Banner.styles';
import FeedbackText from '../../Typography/FeedbackText/FeedbackText';
import BannerButton from '../../Buttons/BannerButton/BannerButton';

const Banner = ({open, showClose, text, isSuccess, showBtn, btnText, onClick, isFixed, onClose}) => {
  const [show, setShow] = useState(open);

  const handleBannerClose = () => {
    setShow(false);
    // call setTimeout to allow close transition to complete before resetting open state
    setTimeout(() => onClose(), 500);
  };

  return (
    <StyledCollapse
      in={show}
    >
      <StyledAlert
        icon={false}
        action={
          showClose &&
          <StyledIconButton
            onClick={handleBannerClose}
          >
            <StyledCloseIcon
              success={isSuccess ? 'true' : undefined}
            />
          </StyledIconButton>
        }
        success={isSuccess ? 'true' : undefined}
        fixed={isFixed ? 'true' : undefined}
      >
        <TextContainer
          button={showBtn ? 'true' : undefined}
        >
          <FeedbackText
            text={text}
            isSuccess={isSuccess}
          />
        </TextContainer>
        {
          showBtn && btnText && onClick &&
          <BannerButton
            text={btnText}
            onClick={onClick}
            isSuccess={isSuccess}
          />
        }
      </StyledAlert>
    </StyledCollapse>
  );
};

export default Banner;
