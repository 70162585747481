import PlayerPic from '../assets/images/rezzil-profile.png';
import {
  RepeatOutlined,
  SyncAltOutlined,
  HdrWeakOutlined,
  RadarOutlined,
  SportsSoccerOutlined,
  FilterTiltShiftOutlined,
} from '@mui/icons-material';

export const rezzilPlayerDrillStats = {
  id: 15,
  picture: PlayerPic,
  firstname: 'Gaz',
  lastname: 'Thatcher',
  clubName: 'MHS',
  avgStats: {
    score: '625',
    totalBalls: '20000',
    timePlayed: '9h 10m',
    totalPortalsHit: '9233',
    totalMisses: '2000',
    bestStreak: '49',
    avgAccuracy: '89',
    avgStrikePoint: '29',
    avgHeadSpeed: '3.3',
    performanceRatio: '1.9',
    totalScore: '125000',
    clubRank: 10,
    worldRank: 23,
    lastScore: 600,
    averageScore: 10,
    levelScore: 15,
    levelStats: 21,
  },
  drillStats: [
    {
      drillId: 'control',
      drillName: 'Control',
      drillLogo: <RepeatOutlined/>,
      score: '625',
      totalBalls: '20,000',
      timePlayed: '9h 10m',
      totalPortalsHit: '9233',
      totalMisses: '2000',
      bestStreak: '49',
      avgAccuracy: '89%',
      avgStrikePoint: '29',
      avgHeadSpeed: '3.3m/s',
      performanceRatio: '1.9',
      totalScore: '125,000',
      clubRank: '5',
      worldRank: '35',
    },
    {
      drillId: 'shooting',
      drillName: 'Shooting',
      drillLogo: <HdrWeakOutlined/>,
      score: '523',
      totalBalls: '18,000',
      timePlayed: '5h 50m',
      totalPortalsHit: '7820',
      totalMisses: '1700',
      bestStreak: '32',
      avgAccuracy: '72%',
      avgStrikePoint: '18',
      avgHeadSpeed: '3.1m/s',
      performanceRatio: '1.6',
      totalScore: '122,000',
      clubRank: '9',
      worldRank: '41',
    },
    {
      drillId: 'passing',
      drillName: 'Passing',
      drillLogo: <RadarOutlined/>,
      score: '924',
      totalBalls: '15,300',
      timePlayed: '6h 20m',
      totalPortalsHit: '6230',
      totalMisses: '1287',
      bestStreak: '48',
      avgAccuracy: '81%',
      avgStrikePoint: '29',
      avgHeadSpeed: '3.6m/s',
      performanceRatio: '2.1',
      totalScore: '148,000',
      clubRank: '2',
      worldRank: '13',
    },
    {
      drillId: 'clearing',
      drillName: 'Clearing',
      drillLogo: <SportsSoccerOutlined/>,
      score: '625',
      totalBalls: '28,000',
      timePlayed: '15h 23m',
      totalPortalsHit: '13000',
      totalMisses: '6300',
      bestStreak: '74',
      avgAccuracy: '82%',
      avgStrikePoint: '67',
      avgHeadSpeed: '3.1m/s',
      performanceRatio: '1.8',
      totalScore: '160,000',
      clubRank: '4',
      worldRank: '34',
    },
    {
      drillId: 'hoops-vision',
      drillName: 'Hoops Vision',
      drillLogo: <SyncAltOutlined/>,
      score: '780',
      totalBalls: '12,230',
      timePlayed: '4h 20m',
      totalPortalsHit: '9,230',
      totalMisses: '1030',
      bestStreak: '61',
      avgAccuracy: '79%',
      avgStrikePoint: '32',
      avgHeadSpeed: '3.2m/s',
      performanceRatio: '1.9',
      totalScore: '136,000',
      clubRank: '7',
      worldRank: '18',
    },
    {
      drillId: 'reaction-wall',
      drillName: 'Reaction Wal',
      drillLogo: <FilterTiltShiftOutlined />,
      score: '630',
      totalBalls: '9,750',
      timePlayed: '6h 10m',
      totalPortalsHit: '5,192',
      totalMisses: '4,00',
      bestStreak: '29',
      avgAccuracy: '50%',
      avgStrikePoint: '17',
      avgHeadSpeed: '2.3m/s',
      performanceRatio: '1.9',
      totalScore: '67,000',
      clubRank: '19',
      worldRank: '30',
    },
  ],
};

export const rezzilPlayerWorldAvgDrillStats = {
  score: '89,000',
  totalBalls: '10,200',
  totalHits: '8,000',
  totalMisses: '3,200',
  accuracy: '74%',
  performanceRatio: '86%',
};

 export const worldAvgLevelStats = {
  totalScore: 100000,
  totalBalls: 19500,
  totalPortalsHit: 8500,
  bestStreak: 52,
  avgAccuracy: 86,
  avgHeadSpeed: 2.8,
  avgStrikePoint: 31,
  performanceRatio: 2
};
