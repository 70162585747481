import {styled} from '@mui/material';

export const CardsContainer = styled('div')(({ theme, data }) => ({
  display: 'flex',
  justifyContent: data ? 'space-between' : 'center',
  '& > div': {
    margin: '0 15px',
    width: '33%',
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    '& > div': {
      margin: '0 0 30px',
      width: '41%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > div': {
      width: 'unset',
      maxWidth: 'unset',
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
}));
