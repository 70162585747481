import {colors} from './colors';
import {
  CardGiftcardOutlined,
  // HistoryOutlined,
  EventOutlined,
  VerifiedOutlined
} from '@mui/icons-material';
import { subscriptionAnnualFeatures, subscriptionMonthlyFeatures, nonCommercialFeatures } from './features';

export const licenceTypes = {
  nonCommercial: 'Non Commercial',
  trial: 'Limited Trial',
  monthlySub: 'Subscription - Monthly',
  annualSub: 'Subscription - Annual',
};

export const licenceOptions = [
  {
    id: 1,
    avatarBgColor: colors.beige,
    icon: <CardGiftcardOutlined/>,
    title: licenceTypes.nonCommercial,
    features: nonCommercialFeatures,
    isRecommended: false,
  },
  // {
  //   id: 2,
  //   avatarBgColor: colors.primaryPurple,
  //   icon: <HistoryOutlined/>,
  //   title: licenceTypes.trial,
  //   features: [
  //     'FREE for 30 days',
  //     'Index Drills',
  //     'Limited Access',
  //     '5 Profiles',
  //   ],
  //   isRecommended: false,
  // },
  {
    id: 3,
    avatarBgColor: colors.secondaryOrange,
    icon: <EventOutlined/>,
    title: licenceTypes.monthlySub,
    shortTitle: 'Monthly',
    features: [
      '£400 per month',
      'Club Level Access',
      'Unlimited Profiles',
      'Full Support',
      'World Stats',
    ],
    isRecommended: false,
    type: 'month',
    licenceId: 'elite',
    billingPeriod: 'month',
  },
  {
    id: 4,
    avatarBgColor: colors.primaryOrange,
    icon: <VerifiedOutlined/>,
    title: licenceTypes.annualSub,
    shortTitle: 'Annual',
    features: [
      '£4,200 per year (best value)',
      'Club Level Access',
      'Unlimited Profiles',
      'Full Support',
      'World Stats',
    ],
    isRecommended: true,
    type: 'year',
    licenceId: 'elite',
    billingPeriod: 'year',
  },
];


export const rezzilPlayerLicenceOptions = [
    {
    id: 1,
    avatarBgColor: colors.secondaryOrange,
    icon: <EventOutlined/>,
    title: licenceTypes.monthlySub,
    features: subscriptionMonthlyFeatures,
    isRecommended: false,
    licenceId: 'squadspro',
    billingPeriod: 'month',
  },
  {
    id: 2,
    avatarBgColor: colors.primaryOrange,
    icon: <VerifiedOutlined/>,
    title: licenceTypes.annualSub,
    features: subscriptionAnnualFeatures,
    isRecommended: true,
    licenceId: 'squadspro',
    billingPeriod: 'year',
  },
]