import {
  CardsContainer,
} from './RevenueCards.styles';
import SectionSpinner from '../../Loading/SectionSpinner/SectionSpinner';
import RevenueCard from '../../Cards/RevenueCard/RevenueCard';
import NoResultsText from '../../Typography/NoResultsText/NoResultsText';

const RevenueCards = ({data, loading}) => {
  return (
    <CardsContainer
      data={!loading && data.length > 0 ? 'true' : undefined}
    >
      {
        loading &&
        <SectionSpinner/>
      }
      {
        !loading && data.length > 0 && data.map(({id, avatarBgColor, icon, title, price}) => (
          <RevenueCard
            key={id}
            avatarBgColor={avatarBgColor}
            icon={icon}
            title={title}
            price={price}
          />
        ))
      }
      {
        (!loading && (!data || data.length === 0)) &&
        <NoResultsText
          text={'No results'}
        />
      }
    </CardsContainer>
  );
};

export default RevenueCards;
