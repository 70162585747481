import EmptyShield from '../../assets/svgs/shield-empty.svg';
import BronzeShield from '../../assets/svgs/shield-bronze.svg';
import SilverShield from '../../assets/svgs/shield-silver.svg';
import GoldShield from '../../assets/svgs/shield-gold.svg';

const getLevelDetails = (level) => {
  // Return level icon and textual value based on level value
  switch (level) {
    case 1:
      return {
        icon: BronzeShield,
        value: 'Bronze',
      }
    case 2:
      return {
        icon: SilverShield,
        value: 'Silver',
      }
    case 3:
      return {
        icon: GoldShield,
        value: 'Gold',
      }
    default:
      return {
        icon: EmptyShield,
        value: '-',
      }
  }
};

export default getLevelDetails;
