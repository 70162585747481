import {
  styled,
  TextField,
  IconButton,
} from '@mui/material';
import {colors} from '../../../options/colors';
import {Search, Close} from '@mui/icons-material';

export const Container = styled('div')(() => ({
  width: '100%',
  position: 'relative',
}));

export const StyledSearchField = styled(TextField)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    ...theme.typography.body1,
    padding: 0,
    borderRadius: 5,
    overflow: 'hidden',
    '.MuiOutlinedInput-input': {
      padding: '15px 65px 15px 20px',
      background: 'transparent',
      '&::placeholder': {
        color: colors.secondaryGrey,
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      padding: 0,
      borderColor: colors.secondaryGrey,
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-input': {
        background: 'rgba(255, 255, 255, 0.2)'
      },
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.2)',
        borderWidth: 1,
      },
      '&:hover': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(255, 255, 255, 0.2)',
          borderWidth: 1,
        },
      }
    },
    '&:hover': {
      '.MuiOutlinedInput-notchedOutline': {
        padding: 0,
        borderColor: colors.secondaryGrey,
      },
    },
  },
}));

export const SearchIconContainer = styled('div')(() => ({
  position: 'absolute',
  right: 12,
  top: 13,
  zIndex: -1,
}));

export const SearchIcon = styled(Search)(() => ({
  color: colors.beige,
  fontSize: 26,
}));

export const ClearButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: 38,
  top: 13,
  padding: 2,
  transition: 'all 0.3s ease',
}));

export const ClearIcon = styled(Close)(() => ({
  color: colors.white,
  fontSize: 22,
}));
