import {
  Container,
  StyledIcon,
  StyledText,
} from './FeatureLine.styles';

const FeatureLine = ({text, isRecommended}) => {
  return (
    <Container>
      <StyledIcon
        recommended={isRecommended ? 'true' : undefined}
      />
      <StyledText>
        {text}
      </StyledText>
    </Container>
  );
};

export default FeatureLine;
