import {useEffect, useState, useCallback, createContext, useContext} from 'react';
import {routes} from '../../options/routes';

// API calls
import { getClubUsers, getUserRoles } from '../../api';

// Contexts
import {BaseContext} from '../Global/BaseProvider';

const AccountContext = createContext({});

const AccountProvider = ({children}) => {
  const baseContext = useContext(BaseContext);
  const [accountData, setAccountData] = useState({
    loading: true,
    teamMembers: null,
  });

  const getAccountData = useCallback(async () => {
    // Only trigger API call for admin account route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.adminAccount) return;

    if (!baseContext.baseData.loading) {
      const {id} = baseContext.baseData.clubProfile;
      // Call API
      const teamMembersData = await getClubUsers(id);
      const userRoles = await getUserRoles();
      if (!teamMembersData || !teamMembersData.success || !teamMembersData.data || !teamMembersData.data.length) {
        setAccountData({
          loading: false,
          teamMembers: null,
          userRoles: []
        });
      }

      // Set data
      setAccountData({
        loading: false,
        teamMembers: teamMembersData.data.filter((teamMember) => teamMember.id.toString() !== baseContext.baseData.userProfile.id),
        userRoles: userRoles.data || []
      });
    }
  }, [baseContext]);

  useEffect(() => {
    getAccountData();
  }, [getAccountData]);

  return (
    <AccountContext.Provider
      value={{
        accountData,
        getAccountData
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export {AccountProvider, AccountContext};

export default AccountProvider;
