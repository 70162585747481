import {useEffect, useContext, useState} from 'react';
import {
  SectionWrapper,
  ContainerWrapper,
} from './GlobalLeaderBoards.styles';
import SectionSpinner from '../../../components/Loading/SectionSpinner/SectionSpinner';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import PlayersDropdownSelects from '../../../components/StatsPage/PlayersDropdownSelects/PlayersDropdownSelects';
import SectionContainer from '../../../components/Containers/SectionContainer/SectionContainer';
import LeaderBoardList from '../../../components/Tables/LeaderBoardList/LeaderBoardList';

// Contexts & Helpers
import {BaseContext} from '../../../providers/Global/BaseProvider';
import {GlobalLeaderBoardsContext} from '../../../providers/Index/GlobalLeaderBoardsProvider';

import {createColorHex} from '../../../utils/helpers/createColorHex';
import getAllLeaderBoardsData from '../../../utils/helpers/leaderboardsHelpers';
import {getPlayerChoices} from '../../../utils/helpers/playerChoicesHelpers';
import duplicateExists from '../../../utils/helpers/duplicateExists';
import { routes } from '../../../options/routes';

const loggedInUserInPlayerList = (userId, players) =>
  players.find((player) => player.value === userId);

const GlobalLeaderBoards = () => {
  // Dynamically set orientation based on breakpoint
  const baseContext = useContext(BaseContext);
  const globalLeaderBoardsContext = useContext(GlobalLeaderBoardsContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [playerDataLoading, setPlayerDataLoading] = useState(false);
  const [playerDropdownOptions, setPlayerDropdownOptions] = useState([]);
  const [playerChoices, setPlayerChoices] = useState([]);
  const [globalLeaderBoardStats, setGlobalLeaderBoardStats] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    if (globalLeaderBoardsContext.globalLeaderBoardsData.allLoaded) {
      setDataLoading(false);
      if (globalLeaderBoardsContext.globalLeaderBoardsData.apiError) {
        setError({
          show: true,
          message: 'Unable to load some or all page content. Please refresh and try again.'
        });
      }
      const {globalLeaderBoardStats, playerProfiles} = globalLeaderBoardsContext.globalLeaderBoardsData;

      // Set options for player dropdowns
      let options = [];
      if (playerProfiles && playerProfiles.length > 0) {
        playerProfiles.forEach(player => {
          const playerName = `${player.firstname} ${player.lastname}`;
          const option = {
            value: player.id,
            label: playerName,
            color: createColorHex(playerName),
          };
          options.push(option);
        });
      }
      setPlayerDropdownOptions(options);

      // Add logged in player id to playerChoices
      const {id} = baseContext.baseData.userProfile;
      const playerChoicesIds = [loggedInUserInPlayerList(id, options) ? Number(id) : playerProfiles.length ? options[0].value : undefined];
      setPlayerChoices(playerChoicesIds);

      // Set leaderboard data
      const allLeaderBoardData = getAllLeaderBoardsData(
        [],
        Object.values(globalLeaderBoardStats),
        playerChoicesIds,
      );
      setGlobalLeaderBoardStats(allLeaderBoardData);
    }
  }, [baseContext, globalLeaderBoardsContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handlePlayerChange = idx => async e => {
    // Remove any displayed banner errors
    handleBannerClose();

    const {id} = baseContext.baseData.userProfile;
    const rows = getPlayerChoices(playerChoices, idx, e.target.value, id);
    // Ensure duplicates do not exist
    if (duplicateExists(rows)) {
      setError({
        show: true,
        message: 'That player has already been selected',
      });
      return;
    }
    setPlayerChoices(rows);

    // TODO: Implement logic to make API calls to get player stats data for each selected player
    setPlayerDataLoading(true);
    const { globalLeaderBoardStats } = globalLeaderBoardsContext.globalLeaderBoardsData;
    // Set leaderboard data


    const allLeaderBoardData = getAllLeaderBoardsData(
      [],
      Object.values(globalLeaderBoardStats),
      rows,
    );
    setGlobalLeaderBoardStats(allLeaderBoardData);

    setPlayerDataLoading(false);
  };

  const handlePlayerAdd = () => {
    if (!playerChoices.at(-1)) return;
    const row = '';
    setPlayerChoices([...playerChoices, row]);
  };

  const getGlobalLeaderBoardName = (name) => {
    switch (name) {
      case 'blocking':
        return 'Pressure Pass';
      case 'colour_combo_warmup':
        return 'Colour Combo';
      case 'headers':
        return 'Head Smart';
      case 'number_pass':
        return 'Shoulder Sums';
      case 'pass_awareness_1_4':
        return 'Rondo Scan';
      case 'rezzil_index':
        return 'Rezzil Index';
      case 'wolfy_180':
        return 'Wolfy 180';
      default:
        return name;
    }
  };

  const handlePlayerReset = () => {
    // Reset options and set the logged in player as the first choice
    const {id} = baseContext.baseData.userProfile;
    const playerChoicesIds =
      [loggedInUserInPlayerList(id, playerDropdownOptions) ? Number(id) : playerDropdownOptions.length ? playerDropdownOptions[0].value : undefined];
    setPlayerChoices(playerChoicesIds);

    setPlayerDataLoading(true);

    // Set leaderboard data
    const allLeaderBoardData = getAllLeaderBoardsData(
      [],
      Object.values(globalLeaderBoardStats),
      playerChoicesIds,
    );
    setGlobalLeaderBoardStats(allLeaderBoardData);

    setPlayerDataLoading(false);
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.indexGlobalLeaderBoards}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <Title
            text={'Global Leader Boards'}
          />
          <PlayersDropdownSelects
            choices={playerChoices}
            dropdownOptions={playerDropdownOptions}
            handleChange={handlePlayerChange}
            handleAdd={handlePlayerAdd}
            handleReset={handlePlayerReset}
          />
          {
            playerDataLoading &&
            <SectionSpinner/>
          }
          {
            !playerDataLoading &&
            <SectionWrapper>
              {
                globalLeaderBoardStats.length > 0 && globalLeaderBoardStats.map(({name, scores}) => (
                  <ContainerWrapper
                    key={name}
                    datalength={globalLeaderBoardStats.length}
                  >
                    <SectionContainer
                      sectionTitle={getGlobalLeaderBoardName(name)}
                    >
                      <LeaderBoardList
                        data={scores}
                        isGlobalBoards
                      />
                    </SectionContainer>
                  </ContainerWrapper>
                ))
              }
            </SectionWrapper>
          }
        </MainWrapper>
      }
    </>
  );
};

export default GlobalLeaderBoards;
