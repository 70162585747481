import {
  PlayerImageContainer,
} from './LargePlayerAvatar.styles';
import DefaultUser from '../../../assets/images/default-user.png';

const LargePlayerAvatar = ({playerImage}) => {
  return (
    <PlayerImageContainer
      image={playerImage ? playerImage : DefaultUser}
    />
  );
};

export default LargePlayerAvatar;
