import {
  styled,
  Typography,
} from "@mui/material";
import {colors} from "../../../options/colors";

export const Container = styled('div')(() => ({
  marginBottom: 8,
}));

export const StyledText = styled(Typography)(({ theme}) => ({
  ...theme.typography.h3,
  color: colors.primaryGrey,
  textTransform: 'uppercase',
  letterSpacing: 1.4,
}));

export const RequiredLabel = styled('span')(() => ({
  color: colors.secondaryOrange,
}));
