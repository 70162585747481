import {
  Container,
  TextContainer,
  LabelText,
  ValueText,
} from './StatRow.styles';

const UpdateRow = ({label, value, unevenColumns, highlight}) => {
  return (
    <Container>
      <TextContainer
        adjustwidth={unevenColumns ? 'true' : undefined}
      >
        <LabelText highlight={highlight ? 'true' : undefined}>
          {label}
        </LabelText>
      </TextContainer>
      <TextContainer
        adjustwidth={unevenColumns ? 'true' : undefined}
      >
        <ValueText highlight={highlight ? 'true' : undefined}>
          {value}
        </ValueText>
      </TextContainer>
    </Container>
  );
};

export default UpdateRow;
