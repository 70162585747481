import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const InnerContainer = styled('div')(() => ({
  padding: '30px 20px',
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: colors.primaryGrey,
}));

export const FieldContainer = styled('div')(() => ({
  marginBottom: 20,
}));
