import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const ColorIcon = styled('div')(({ color }) => ({
  height: 14,
  width: 14,
  borderRadius: 8,
  background: color ? color : colors.primaryGrey,
  marginRight: 10,
}));

export const LegendText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: colors.primaryGrey,
  textTransform: 'uppercase',
}));

export const LegendContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  marginTop: 20,
  marginBottom: -20,
  [theme.breakpoints.down(750)]: {
    flexDirection: 'column',
    marginBottom: 0,
  },
  '@media print': {
    marginTop: 0,
  }
}));

export const LabelContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: 20,
  marginLeft: 15,
  marginRight: 15,
  [theme.breakpoints.down(750)]: {
    marginLeft: 0,
    marginRight: 0,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
