import {styled, Typography} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.03)',
  padding: '118px 0 98px',
  borderRadius: 5,
  [theme.breakpoints.down(750)]: {
    padding: '20px 0 10px',
  },
}));

export const LevelContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: 45,
  [theme.breakpoints.down(750)]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 25,
  },
}));

export const ImageContainer = styled('div')(() => ({
  textAlign: 'center',
}));

export const DesktopAvgTitleText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: colors.primaryGrey,
  textTransform: 'uppercase',
  marginBottom: 20,
  [theme.breakpoints.down(750)]: {
    display: 'none',
  },
}));

export const StyledImage = styled('img')(({ theme }) => ({
  height: 70,
  marginBottom: 15,
  [theme.breakpoints.down(750)]: {
    marginBottom: 0,
    marginRight: 20,
  },
}));

export const TextContainer = styled('div')(() => ({}));

export const MobileAvgTitleText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: colors.primaryGrey,
  textTransform: 'uppercase',
  marginBottom: 10,
  display: 'none',
  [theme.breakpoints.down(750)]: {
    display: 'block',
  },
}));

export const LevelText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontSize: 20,
}));

export const TableContainer = styled('div')(() => ({}));
