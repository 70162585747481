import {useState} from 'react';
import {
  StyledButton,
  StyledMenu,
  StyledIcon,
  StyledMenuItem,
} from './CustomIconSelect.styles';

const CustomIconSelect = ({options, onChange, selected}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(selected);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    if (typeof option !== 'string') return;
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <>
      <StyledButton
        onClick={handleClick}
      >
        <StyledIcon/>
      </StyledButton>
      <StyledMenu
        id='more-drills'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          options && options.map(({id, icon, label}) => (
            <StyledMenuItem
              key={id}
              selected={selectedOption === id}
              onClick={() => handleClose(id)}
            >
              {icon && icon}{label}
            </StyledMenuItem>
          ))
        }
      </StyledMenu>
    </>
  );
};

export default CustomIconSelect;
