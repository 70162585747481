import {
  styled,
  Table,
} from '@mui/material';
import {
  StyledTableBody,
  StyledTableRow,
} from '../CustomTableWrapper/CustomTableWrapper.styles';
import {StyledTableCell} from '../CustomTableHead/CustomTableHead.styles';
import {colors} from '../../../options/colors';
import {
  TrendingUpOutlined,
  TrendingDownOutlined,
  TrendingFlatOutlined,
} from '@mui/icons-material';

export const StyledTable = styled(Table)(() => ({}));

export const LargeTableBody = styled(StyledTableBody)(() => ({
  '& .MuiTableCell-root': {
    padding: '20px 10px 20px 0',
    '&:first-of-type': {
      paddingLeft: 10,
    },
  },
}));

export const WorldAvgContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
}));

export const AvgStat = styled('span')(() => ({
  display: 'flex',
  marginRight: 7,
  '&:last-of-type': {
    margin: 0,
    color: colors.white,
  },
}));

export const TrendingUpIcon = styled(TrendingUpOutlined)(() => ({
  color: colors.primaryGreen,
}));

export const TrendingDownIcon = styled(TrendingDownOutlined)(() => ({
  color: colors.primaryRed,
}));

export const TrendingFlatIcon = styled(TrendingFlatOutlined)(() => ({
  color: colors.secondaryOrange,
}));

export {
  StyledTableRow,
  StyledTableCell,
};
