import {useEffect, useCallback} from 'react';
import {colors} from '../../../options/colors';
import Chart from 'chart.js/auto';
import SectionSpinner from '../../Loading/SectionSpinner/SectionSpinner';
import NoResultsText from '../../Typography/NoResultsText/NoResultsText';

const IndexBreakdownChart = ({loading, labels, data}) => {

  const createChart = useCallback((chartLabels, chartData) => {
    // Get canvas by ID - allows rendering different chart for desktop and mobiles to set different font sizes
    const id = 'breakdownChart';
    const ctx = document.getElementById(id).getContext('2d');

    // Create data object for chart config
    const data = {
      // Get array of last 6 months for labels
      labels: chartLabels,
      datasets: [{
        label: '',
        data: chartData,
      }],
    };

    // Create chart config object
    const config = {
      type: 'line',
      data,
      options: {
        plugins: {
          // Hide legend
          legend: {
            display: false,
          },
          // Customise hover tooltip
          tooltip: {
            enabled: false,
          },
        },
        maintainAspectRatio: false,
        // Customise line tension
        tension: 0,
        // Add gradient below chart line
        fill: false,
        // Customise line and points properties
        borderColor: colors.primaryGreen,
        borderWidth: 3,
        pointRadius: 5,
        pointBackgroundColor: colors.primaryGreen,
        pointHoverBorderWidth: 5,
        borderJoinStyle: 'round',
        // Prevent top and bottom point clipping when min and max values set on y scale
        clip: {
          left: false,
          top: false,
          right: false,
          bottom: false
        },
        // Customise x and y axes, including ticks
        scales: {
          x: {
            position: 'top',
            offset: true,
            grid: {
              color: 'transparent',
              borderColor: 'transparent',
              tickColor: 'transparent',
            },
            ticks: {
              color: colors.white,
              padding: 50,
              font: {
                family: 'GraphikRegular, sans-serif',
                size: 20,
              },
            },
          },
          y: {
            beginAtZero: false,
            max: 14,
            min: 0,
            grid: {
              color: 'transparent',
              borderColor: 'transparent',
              tickColor: 'transparent',
            },
            ticks: {
              color: 'transparent',
              padding: -10,
            },
          },
        },
      },
    };

    // Instantiate chart with config object
    new Chart(ctx, config);
  }, []);

  useEffect(() => {
    if (!loading && labels && labels.length > 0 && data && data.length > 0) {
      // Remove existing canvas before re-drawing to resolve error when parent re-renders
      const canvas = '<canvas id="breakdownChart"/>';
      const chart = document.getElementById('breakdownChart');
      if (chart) chart.remove();
      const chartContainer = document.getElementById('breakdownChartContainer');
      chartContainer.innerHTML = canvas;
      createChart(labels, data);
    }
  }, [loading, createChart, labels, data]);

  return (
    <>
      {
        loading &&
        <SectionSpinner/>
      }
      {
        !loading && (!labels || labels.length === 0 || !data || data.length === 0) &&
        <NoResultsText
          text={'No Index Breakdown data'}
        />
      }
    </>
  );
};

export default IndexBreakdownChart;
