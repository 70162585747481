import {styled} from '@mui/material';
import { colors } from '../../options/colors';

export const Container = styled('div')(() => ({
  color: colors.white,
  display: 'flex',
  flexDirection: 'column',
  // marginTop: 38,
}));

export const Heading = styled('div')(() => ({
  color: colors.white,
  fontSize: 18,
  fontWeight: 700,
  marginTop: 15,
}));

export const Body = styled('div')(() => ({
  color: colors.primaryGrey,
  fontSize: 16,
  marginTop: 11,
}));

export const Logo = styled('div')(({ color }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: color,
  borderRadius: 25,
  height: 50,
  width: 50,
}));
