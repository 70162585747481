import {
  styled,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@mui/material';

export const StyledTableHead = styled(TableHead)(({ small }) => ({
  '& .MuiTableCell-root': {
    fontSize: small ? 12 : 14,
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({}));

export const StyledTableCell = styled(TableCell)(({email}) => ({
  maxWidth: email ? 175 : 'unset',
  whiteSpace: email ? 'nowrap' : 'unset',
  overflow: email ? 'hidden' : 'unset',
  textOverflow: email ? 'ellipsis' : 'unset',
}));

export const StyledTableSortLabel = styled(TableSortLabel)(() => ({}));

export const TextWithTooltipContainer = styled('div')(() => ({
  display: 'flex',
}));

export const TooltipContainer = styled('div')(() => ({
  marginLeft: 5,
  transform: 'translateY(-1px)',
}));
