import {playerPositionOptions} from '../../options/playerOptions';
import getAgeFromDOB from './getAgeFromDOB';
import {formatStandardDateString} from './formatDateString';

export const getPlayerPositionString = position => {
  // Convert position value to a string value based on lookup data
  const playerPosition = playerPositionOptions.find(p => p.value === position);
  return playerPosition ? playerPosition.label : '--';
};

// Calculate the average score and return value to set dp (2 by default) - return 0 if no totalValue
const getAverageScore = (totalValue, totalCount, dp=2) =>
  totalValue ? (totalValue / totalCount).toFixed(dp) : totalValue;

// Remove '-' from start of number if present and return value as number
const convertNegativeToPositive = num =>
  num.toString()[0] === '-' ? Number(num.toString().substring(1)) : num;

export const getBestPlayerStats = (stats, profileData) => {
  // Create object for best performing player data in Index Panel page
  // Return null if no data
  if (stats.length === 0) return null;
  // Get the first player and get the profile and rezzilIndex objects
  const playerOne = stats[0];
  const {profile, rezzilIndex} = playerOne;

  // Manipulate and return data in required format
  return {
    rezzilId: profile.id,
    picture: profileData.picture ? profileData.picture : '',
    name: playerOne.name,
    age: getAgeFromDOB(profile.dob),
    position: getPlayerPositionString(profile.position),
    bestOverallScore: rezzilIndex.best && Number(rezzilIndex.best.index)
      ? Number(rezzilIndex.best.index).toFixed(2) : '--',
    lastTested: playerOne.lastTested ? formatStandardDateString(playerOne.lastTested, true) : '',
    clubRank: playerOne.rank ? playerOne.rank : '--',
    worldRank: playerOne.worldRank && playerOne.worldRank !== '-1'? playerOne.worldRank : '--',
    avgScore: {
      rezzilIndex: rezzilIndex.avg && Number(rezzilIndex.avg.index)
        ? Number(rezzilIndex.avg.index).toFixed(2) : 0,
      composure: rezzilIndex.avg && Number(rezzilIndex.avg.composure)
        ? Number(rezzilIndex.avg.composure).toFixed(2) : 0,
      reaction: rezzilIndex.avg && Number(rezzilIndex.avg.reaction)
        ? Number(rezzilIndex.avg.reaction).toFixed(2) : 0,
      accuracy: rezzilIndex.avg && Number(rezzilIndex.avg.accuracy)
        ? Number(rezzilIndex.avg.accuracy).toFixed(2) : 0,
      adaptability: rezzilIndex.avg && Number(rezzilIndex.avg.technical)
        ? Number(rezzilIndex.avg.technical).toFixed(2) : 0,
    },
    bestScore: {
      rezzilIndex: rezzilIndex.best && Number(rezzilIndex.best.index)
        ? Number(rezzilIndex.best.index).toFixed(2) : 0,
      composure: rezzilIndex.best && Number(rezzilIndex.best.composure)
        ? Number(rezzilIndex.best.composure).toFixed(2) : 0,
      reaction: rezzilIndex.best && Number(rezzilIndex.best.reaction)
        ? Number(rezzilIndex.best.reaction).toFixed(2) : 0,
      accuracy: rezzilIndex.best && Number(rezzilIndex.best.accuracy)
        ? Number(rezzilIndex.best.accuracy).toFixed(2) : 0,
      adaptability: rezzilIndex.best && Number(rezzilIndex.best.technical)
        ? Number(rezzilIndex.best.technical).toFixed(2) : 0,
    },
  };
};

export const getClubOverallIndexStats = stats => {
  // Create object for club's overall index data in Index Panel page
  // Return null if no data
  if (stats.length === 0) return null;

  // Calculate and return averages for world rank, index, composure, reaction, accuracy and adaptability
  // const totalPlayers = stats.length;
  let totalWorldRank = 0,
      totalRezzilIndex = 0,
      totalComposure = 0,
      totalReaction = 0,
      totalAccuracy = 0,
      totalAdaptability = 0,
      totalPlayersWithStats = 0;

  stats.forEach(player => {
    const { rezzilIndex } = player;
    if (player.worldRank) totalWorldRank += player.worldRank;
    if (rezzilIndex && rezzilIndex.avg) {
      totalPlayersWithStats++;
      if (Number(rezzilIndex.avg.index)) totalRezzilIndex += Number(rezzilIndex.avg.index);
      if (Number(rezzilIndex.avg.composure)) totalComposure += Number(rezzilIndex.avg.composure);
      if (Number(rezzilIndex.avg.reaction)) totalReaction += Number(rezzilIndex.avg.reaction);
      if (Number(rezzilIndex.avg.accuracy)) totalAccuracy += Number(rezzilIndex.avg.accuracy);
      if (Number(rezzilIndex.avg.technical)) totalAdaptability += Number(rezzilIndex.avg.technical);
    }
  });

  // We're only going to calculate the average figure over players that have stats
  return {
    players: stats.length,
    worldRank: getAverageScore(totalWorldRank, totalPlayersWithStats, 0)
      ? getAverageScore(totalWorldRank, totalPlayersWithStats, 0)
      : '--',
    scores: {
      rezzilIndex: getAverageScore(totalRezzilIndex, totalPlayersWithStats),
      composure: getAverageScore(totalComposure, totalPlayersWithStats),
      reaction: getAverageScore(totalReaction, totalPlayersWithStats),
      accuracy: getAverageScore(totalAccuracy, totalPlayersWithStats),
      adaptability: getAverageScore(totalAdaptability, totalPlayersWithStats),
    },
  };
};

export const getClubTopTenPlayersStats = stats => {
  // Create object for club's top 10 players data in Index Panel page
  // Return null if no data
  if (stats.length === 0) return null;

  // Get stats for first 10 players, or all players if less than 10
  let players;
  if (stats.length > 10) {
    players = stats.slice(0, 10);
  } else {
    players = stats;
  }

  // Return data in form of array of objects with the required data structure
  let topTenPlayerStats = [];
  players.forEach(player => {
    const playerStats = {
      id: player.profile.id,
      rank: player.rank ? player.rank : 'N/A',
      name: player.name,
      birthYear: player.yob,
      topScore: player.rezzilIndex && player.rezzilIndex.best && Number(player.rezzilIndex.best.index)
        ? Number(player.rezzilIndex.best.index).toFixed(2)
        : 'N/A',
      avgScore: player.rezzilIndex && player.rezzilIndex.avg && Number(player.rezzilIndex.avg.index)
        ? Number(player.rezzilIndex.avg.index).toFixed(2)
        : 'N/A',
      lastScore: player.rezzilIndex && Number(player.rezzilIndex.index)
        ? Number(player.rezzilIndex.index).toFixed(2)
        : 'N/A',
      worldRank: player.worldRank && player.worldRank !== '-1' ? player.worldRank : 'N/A',
    };
    topTenPlayerStats.push(playerStats);
  });

  return topTenPlayerStats.length > 0 ? topTenPlayerStats : null;
};

export const getClubBestDrills = (stats, worldAverages) => {
  // Create object for best performing player data in Index Panel page
  // Return null if no data
  if (Object.entries(stats).length === 0) return null;

  // Return data in form of array of objects with the required data structure
  let bestDrillsStats = [];
  Object.entries(stats).forEach(drill => {
    if (drill.length > 1) {
      const drillId = drill[0];
      const drillDetails = drill[1];
      const {scores} = drillDetails;
      // const currentWorldAvg = scores.length > 0 && scores[0].currentWorldAverage
      //   ? scores[0].currentWorldAverage
      //   : 0;
      const currentWorldAvg = worldAverages[drillId];
      const prevWorldAvg = scores.length > 0 && scores[0].previousWorldAverage
        ? scores[0].previousWorldAverage
        : 0;
      const avgDifference = currentWorldAvg - prevWorldAvg;

      const drillStats = {
        id: drillId,
        drill: drillDetails.name,
        name: scores.length > 0 && scores[0].name ? scores[0].name : '--',
        highScore: scores.length > 0 && Number(scores[0].topIndex) ? Number(scores[0].topIndex) : 'N/A',
        worldAvg: currentWorldAvg,
        trend: currentWorldAvg > prevWorldAvg ? 'up' : currentWorldAvg < prevWorldAvg ? 'down' : 'unchanged',
        positionChange: avgDifference ? convertNegativeToPositive(avgDifference) : 0,
      };
      bestDrillsStats.push(drillStats);
    }
  });

  return bestDrillsStats.length > 0 ? bestDrillsStats : null;
};

// Set radar chart max value to 100, or the highest value in dataset if greater than 100
export const getRadarChartMax = dataset => Math.max(...dataset) > 100 ? Math.max(...dataset) : 100;
