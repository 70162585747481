import {StyledButton} from './IconButton.styles';

const IconButton = ({id, icon, onClick, disabled}) => {
  return (
    <StyledButton
      id={id || undefined}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
    </StyledButton>
  );
};

export default IconButton;
