import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(({ theme }) => ({
  marginBottom: 32,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 18,
  },
}));

export const StyledMarker = styled('div')(({ theme }) => ({
  height: 36,
  width: 10,
  borderRadius: 5,
  marginRight: 10,
  backgroundColor: colors.markerRed,
  [theme.breakpoints.down('sm')]: {
    height: 20,
    width: 5,
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1,
  transform: 'translateY(1px)',
}));
