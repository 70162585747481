import {
  styled,
  Link,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledLink = styled(Link)(({ theme, active }) => ({
  ...theme.typography.body1,
  fontSize: 18,
  textDecoration: 'none',
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  minHeight: 50,
  padding: '0 20px',
  borderRadius: 5,
  color: active ? colors.primaryGreen : colors.white,
  background: active ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
  '&:hover': {
    color: colors.primaryGreen,
    '& svg': {
      color: colors.primaryGreen,
    },
  },
  '& svg': {
    marginRight: 20,
    color: active ? colors.primaryGreen : colors.white,
    transition: 'inherit',
  },
}));
