import {
  Container,
  StatName,
  ScoreContainer,
  TrendingUpIcon,
  TrendingDownIcon,
  TrendingFlatIcon,
  ScoreText,
  MaxScoreText,
} from './StatNameAndScore.styles';

const StatNameAndScore = ({statName, showTrend, trend, score, showMaxScore}) => {
  return (
    <Container>
      <StatName>
        {statName}
      </StatName>
      <ScoreContainer>
        {
          showTrend &&
          <>
            {
              trend === 'up'
                ? <TrendingUpIcon/>
                : trend === 'down'
                  ? <TrendingDownIcon/>
                  : <TrendingFlatIcon/>
            }
          </>
        }
        <ScoreText>
          {score}
        </ScoreText>
        {
          showMaxScore &&
          <MaxScoreText>
            /100
          </MaxScoreText>
        }
      </ScoreContainer>
    </Container>
  )
};

export default StatNameAndScore;
