import {Container, Heading, Body, Logo} from './InfoGridItem.styles';

const InfoGridItem = ({gridItem}) => {
  return (<Container>
    <Logo color={gridItem.color}>{gridItem.logo}</Logo>
    <Heading>{gridItem.heading}</Heading>
    <Body>{gridItem.body}</Body>
  </Container>);
}

export default InfoGridItem;