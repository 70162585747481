import {
  Container,
  LogoContainer,
  StyledLogo,
  ButtonContainer,
  SecondButtonContainer,
} from './AuthFormContainer.styles';
import Logo from '../../../assets/images/rezzil-logo-full.png';
import AuthFormCard from '../../Cards/AuthFormCard/AuthFormCard';
import LinkButton from '../../Buttons/LinkButton/LinkButton';

const AuthFormContainer = ({children, btnText, onClick, showSecondBtn, secondBtnText, secondBtnOnClick}) => {
  return (
    <Container>
      <LogoContainer>
        <StyledLogo
          src={Logo}
          alt='Rezzil Logo'
        />
      </LogoContainer>
      <AuthFormCard>
        {children}
      </AuthFormCard>
      <ButtonContainer>
        <LinkButton
          text={btnText}
          onClick={onClick}
        />
        {
          showSecondBtn &&
          <SecondButtonContainer>
            <LinkButton
              text={secondBtnText}
              onClick={secondBtnOnClick}
            />
          </SecondButtonContainer>
        }
      </ButtonContainer>
    </Container>
  );
};

export default AuthFormContainer;
