import {StyledAvatar} from './IconAvatar.styles';

const IconAvatar = ({icon, bgColor}) => {
  return (
    <StyledAvatar
      background={bgColor}
    >
      {icon}
    </StyledAvatar>
  );
};

export default IconAvatar;
