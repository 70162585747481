import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const StyledBurger = styled('div')(({ open }) => ({
  width: 31,
  height: 22,
  transition: 'all 0.3s linear',
  display: 'flex',
  justifyContent: 'space-around',
  transform: open ? 'translateX(8px)' : 'undefined',
  flexFlow: 'column nowrap',
  '& div': {
    background: colors.white,
    height: 3,
    borderRadius: 5,
    transformOrigin: 1,
    transition: 'all 0.3s linear',
    '&:nth-of-type(1)': {
      width: open ? 18 : 20,
      transform: open ? 'rotate(45deg)' : 'rotate(0)',
    },
    '&:nth-of-type(2)': {
      width: open ? 18 : 28,
      transform: open ? 'rotate(-45deg)' : 'rotate(0)',
    },
  },
}));

export const StyledBurgerText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontSize: 10,
  letterSpacing: 1,
  textTransform: 'uppercase',
  marginTop: 6,
}));
