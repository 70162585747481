import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  '@media print': {
    height: 500,
  },
}));

export const LeftContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '65%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginBottom: 20,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const ImageContainer = styled('div')(({ theme }) => ({
  marginRight: 40,
  [theme.breakpoints.down('sm')]: {
    margin: 'auto',
    marginBottom: 20,
  },
}));

export const StatsContainer = styled('div')(() => ({
  width: '100%',
}));

export const PlayerName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontSize: 30,
  marginBottom: 20,
  lineHeight: 'unset',
}));

export const RightContainer = styled('div')(({ theme }) => ({
  width: '30%',
  maxWidth: 400,
  marginLeft: 40,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    maxWidth: 'unset',
    margin: 0,
  }
}));

export const RankContainer = styled('div')(() => ({
  marginTop: 30,
}));

export const RankRow = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  '&:first-of-type': {
    paddingBottom: 15,
    borderBottom: `1px solid ${colors.dividerGrey}`,
  },
  '&:last-of-type': {
    paddingTop: 15,
  },
}));

export const RankName = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 18,
}));

export const RankValue = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1,
  fontSize: 24,
  color: colors.secondaryOrange,
}));
