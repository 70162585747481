import {
  styled,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
}));

export const RecommendedContainer = styled('div')(() => ({
  textAlign: 'center',
  width: '100%',
  position: 'absolute',
  top: 8,
}));

export const RecommendedText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  letterSpacing: 1.4,
  textTransform: 'uppercase',
}));

export const StyledCard = styled(Card)(({ theme, recommended }) => ({
  padding: '20px 20px 25px',
  background: 'rgba(0, 0, 0, 0.4)',
  width: '100%',
  height: recommended ? 'unset' : 'calc(100% - 76px)',
  borderRadius: 5,
  marginTop: recommended ? `0 !important` : `auto`,
  display: 'flex',
  flexDirection: 'column',
  border: recommended ? `3px solid ${colors.primaryOrange}` : undefined,
  borderTopWidth: recommended ? 30 : undefined,
  [theme.breakpoints.down('sm')]: {
    height: 'unset',
    margin: 0,
  },
}));

export const StyledCardActions = styled(CardActions)(() => ({
  padding: 0,
  margin: 'auto 0 0',
  textAlign: 'center',
}));

export const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
  marginBottom: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const IconAvatarContainer = styled('div')(() => ({
  marginBottom: 25,
}));

export const FeatureLinesContainer = styled('div')(() => ({
  width: '100%',
}));
