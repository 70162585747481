import {
  Container,
  LeftContainer,
  ImageContainer,
  StatsContainer,
  PlayerName,
  RightContainer,
  RankContainer,
  RankRow,
  RankName,
  RankValue,
} from './PlayerCardStats.styles';
import { playerCardStatsOptions, squadStatOptions } from '../../../options/drillStatsOptions';
import StatsList from '../../Tables/StatsList/StatsList';
import LargePlayerAvatar from '../../Avatars/LargePlayerAvatar/LargePlayerAvatar';
import SharePrintBtnGroup from '../../ButtonGroups/SharePrintBtnGroup/SharePrintBtnGroup';
import CustomSelect from '../../Inputs/CustomSelect/CustomSelect';

const PlayerCardStats = ({ isRezzilPlayer, playerImage, playerName, playerStats, onShareClick, shareLoading, subDrills, onSubDrillChange, selectedSubDrillId }) => {
  return (
    <Container>
      <LeftContainer>
        {
          playerImage &&
          <ImageContainer>
            <LargePlayerAvatar
              playerImage={playerImage}
            />
          </ImageContainer>
        }

        <StatsContainer>
          <PlayerName>
            {playerName}
          </PlayerName>
          {
            (subDrills && subDrills.length) &&
              <CustomSelect
                name={`subDrills`}
                placeholder={'Select option'}
                onChange={(event) => onSubDrillChange(event)}
                value={selectedSubDrillId}
                options={subDrills.map((subDrill) => {
                  return {
                    value: subDrill.id,
                    label: subDrill.name,
                  }
                })
                }
              />
            }
          <StatsList
            options={isRezzilPlayer ? squadStatOptions : playerCardStatsOptions }
            data={playerStats}
          />
        </StatsContainer>
      </LeftContainer>
      <RightContainer>
        <SharePrintBtnGroup
          onShareClick={onShareClick}
          shareLoading={shareLoading}
          // showPrint
        />
        <RankContainer>
          <RankRow>
            <RankName>
              Club Rank
            </RankName>
            <RankValue>
              {playerStats.clubRank ? playerStats.clubRank : '--'}
            </RankValue>
          </RankRow>
          <RankRow>
            <RankName>
              World Rank
            </RankName>
            <RankValue>
              {playerStats.worldRank ? playerStats.worldRank : '--'}
            </RankValue>
          </RankRow>
        </RankContainer>
      </RightContainer>
    </Container>
  );
};

export default PlayerCardStats;
