import {useState, useEffect, useCallback} from 'react';
import {
  Container,
  LevelContainer,
  ImageContainer,
  DesktopAvgTitleText,
  StyledImage,
  TextContainer,
  MobileAvgTitleText,
  LevelText,
  TableContainer,
} from './AverageLevelStats.styles';
import IndexBreakdownStatsList from '../../Tables/IndexBreakdownStatsList/IndexBreakdownStatsList';

// Helpers
import getLevelDetails from '../../../utils/helpers/getLevelIcon';

const AverageLevelStats = ({data}) => {
  const [iconDetails, setIconDetails] = useState({});

  const getIconDetails = useCallback(() => {
    // Set icon details based on score
    if (data) {
      const details = getLevelDetails(data.level);
      setIconDetails(details);
    }
  }, [data]);

  useEffect(() => getIconDetails(), [getIconDetails]);

  return (
    <Container>
      <LevelContainer>
        <ImageContainer>
          <DesktopAvgTitleText>
            Average Level
          </DesktopAvgTitleText>
          <StyledImage
            src={iconDetails.icon}
            alt={`${iconDetails.value} Shield`}
          />
        </ImageContainer>
        <TextContainer>
          <MobileAvgTitleText>
            Average Level
          </MobileAvgTitleText>
          <LevelText>
            {iconDetails.value}
          </LevelText>
        </TextContainer>
      </LevelContainer>
      <TableContainer>
        <IndexBreakdownStatsList
          data={data}
          statId={'averages'}
          isAverage
        />
      </TableContainer>
    </Container>
  );
};

export default AverageLevelStats;
