import {useState} from 'react';
import {
  Container,
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  DeleteText,
} from './TeamMembersList.styles';
import {buttonTypes} from '../../../options/buttonTypes';
import {teamMembersColumns} from '../../../options/tableColumns';
import {tableParentOptions} from '../../../options/tableParentOptions';
import SectionContainer from '../../Containers/SectionContainer/SectionContainer';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import MemoizedTable from '../MemoizedTable/MemoizedTable';
import CustomTableHead from '../CustomTableHead/CustomTableHead';
import IconButton from '../../Buttons/IconButton/IconButton';
import StandardModal from '../../Modals/StandardModal/StandardModal';
import TeamMemberForm from '../../Forms/TeamMemberForm/TeamMemberForm';
import {EditOutlined, DeleteOutlined} from '@mui/icons-material';

// Helpers
import {getComparator, stableSort, handleRequestSort} from '../../../utils/tableSortHelpers/tableSortHelpers';
import { capitaliseUserRole } from '../../../utils/helpers/userRoleHelper';

const TeamMembersList = ({
  teamMembers,
  clubId,
  clubName,
  handleDelete,
  onFieldChange,
  onSubmit,
  formData,
  formErrors,
  setFormData,
  setFormErrors,
  updateLoading,
  deleteLoading,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState({});
  const [isAdd, setIsAdd] = useState(true);

  const handleFormModalClose = () => {
    // Reset state back to original values
    setFormModalOpen(false);
    setIsAdd(true);
    setFormData({
      id: undefined,
      clubId,
      firstname: '',
      lastname: '',
      email: '',
      telephoneCountryCode: '',
      telephone: '',
      clubname: clubName,
    });
    setFormErrors({
      firstname: '',
      lastname: '',
      email: '',
      telephoneCountryCode: '',
      telephone: '',
    });
  };

  const handleFormModalOpen = id => {
    if (id) {
      // Find index of item with matching id and pass those details into modal
      const index = teamMembers.findIndex(el => el.id === id);
      setFormData(teamMembers[index]);
      setIsAdd(false);
    }
    setFormModalOpen(true);
  };

  const handleFormModalSubmit = async () => {
    const result = await onSubmit();
    if (result) {
      handleFormModalClose();
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setDeleteUser({});
  };

  const handleDeleteModalOpen = id => {
    const index = teamMembers.findIndex(el => el.id === id);
    setDeleteUser(teamMembers[index]);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalSubmit = async () => {
    const result = await handleDelete(deleteUser.id);
    if (result) {
      handleDeleteModalClose();
    }
  };

  return (
    <Container>
      <SectionContainer
        sectionTitle={'Team Members'}
        showBtn
        btnText={'Add team members'}
        btnType={buttonTypes.link}
        onClick={() => handleFormModalOpen()}
      >
        <CustomTableWrapper
          noResultsText={(!teamMembers || teamMembers.length === 0) && 'No Team Members to display'}
        >
          {
            teamMembers && teamMembers.length > 0 &&
            <MemoizedTable
              list={teamMembers}
              parentComponent={tableParentOptions.teamMembers}
            >
              <CustomTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(e, id) => handleRequestSort(e, id, orderBy, order, setOrder, setOrderBy)}
                columnHeadings={teamMembersColumns}
              />
              <StyledTableBody>
                {
                  stableSort(teamMembers, getComparator(order, orderBy))
                    .map(({id, firstname, lastname, email, role}) => (
                      <StyledTableRow
                        key={id}
                      >
                        <StyledTableCell>
                          {firstname} {lastname}
                        </StyledTableCell>
                        <StyledTableCell>
                          {email}
                        </StyledTableCell>
                        <StyledTableCell>
                          {role ? capitaliseUserRole(role.display_name)  : '--'}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            icon={<EditOutlined/>}
                            onClick={() => handleFormModalOpen(id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            icon={<DeleteOutlined/>}
                            onClick={() => handleDeleteModalOpen(id)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                }
              </StyledTableBody>
            </MemoizedTable>
          }
        </CustomTableWrapper>
        {
          formModalOpen &&
          <StandardModal
            open={formModalOpen}
            onClose={handleFormModalClose}
            title={`${isAdd ? 'Add' : 'Edit'} Team Member Profile`}
            btnText={`${isAdd ? 'Add' : 'Update'} Profile`}
            onClick={handleFormModalSubmit}
            loading={updateLoading}
          >
            <TeamMemberForm
              formData={formData}
              errors={formErrors}
              onChange={onFieldChange}
            />
          </StandardModal>
        }
        {
          deleteModalOpen &&
          <StandardModal
            open={deleteModalOpen}
            onClose={handleDeleteModalClose}
            title={'Delete Team Member Profile'}
            btnText={'Delete Profile'}
            onClick={handleDeleteModalSubmit}
            loading={deleteLoading}
          >
            <DeleteText>
              Are you sure you want to delete {deleteUser.firstname} {deleteUser.lastname}?
              Once confirmed, this action cannot be undone.
            </DeleteText>
          </StandardModal>
        }
      </SectionContainer>
    </Container>
  );
};

export default TeamMembersList;
