import {
  styled,
  Card,
  CardContent,
  Typography
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledCard = styled(Card)(() => ({
  padding: 20,
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'none',
}));

export const StyledCardContent = styled(CardContent)(() => ({
  padding: '0 !important',
  width: '100%',
  background: 'transparent',
  display: 'flex',
  flexDirection: 'column',
}));

export const CardHeaderContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 24,
}));

export const ImageContainer = styled('div')(() => ({
  height: 50,
  width: 50,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 20,
}));

export const TextContainer = styled('div')(() => ({}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 15,
  lineHeight: '15px',
  margin: '0 0 10px 0',
}));

export const StyledDate = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 13,
  lineHeight: '13px',
  color: colors.secondaryGrey,
}));

export const StyledBodyText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 14,
  lineHeight: '14px',
  color: colors.secondaryGrey,
}));
