import {useState} from 'react';
import {
  Container,
  StyledSearchField,
  SearchIconContainer,
  SearchIcon,
  ClearButton,
  ClearIcon
} from './CustomSearchField.styles';

const CustomSearchField = ({id, onChange, onClear, value}) => {
  const [searchTerm, setSearchTerm] = useState(value);

  const handleChange = e => {
    setSearchTerm(e.target.value);
    onChange(e);
  };

  const handleClear = () => {
    setSearchTerm('');
    onClear();
  };

  return (
    <Container>
      <StyledSearchField
        variant='outlined'
        id={id}
        placeholder='Search...'
        type='text'
        onChange={handleChange}
        value={searchTerm}
        fullWidth
      />
      {
        searchTerm &&
        <ClearButton
          aria-label='clear'
          onClick={handleClear}
        >
          <ClearIcon/>
        </ClearButton>
      }
      <SearchIconContainer>
        <SearchIcon/>
      </SearchIconContainer>
    </Container>
  );
};

export default CustomSearchField;
