import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';
import {Warning} from '@mui/icons-material';

export const Container = styled('div')(() => ({
  borderRadius: 5,
  color: colors.primaryRed,
  backgroundColor: colors.secondaryRed,
  padding: 8,
  display: 'flex',
  alignItems: 'center',
  transform: 'translateY(-1px)',
}));

export const WarningIcon = styled(Warning)(() => ({
  marginRight: 5,
  fontSize: 18,
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  color: colors.primaryRed,
}));
