import {
  Container,
} from './StatsList.styles';
import StatRow from '../../StatRow/StatRow';

// Helpers
import {formatStandardDateString} from '../../../utils/helpers/formatDateString';


const getValue = (data) => {
  if (!data) {
    return '--'
  }
  if (typeof data === 'object') {
    return formatStandardDateString(data, true);
  } else if (typeof data === 'number' && !Number.isInteger(data)) {
    return data.toFixed(2);
  } else if (typeof data === 'string' && !isNaN(Number(data)) && !Number.isInteger(Number(data))) {
    // data is a number saved as a string
    return Number(data).toFixed(2);
  }
  return data;
}

const StatsList = ({options, data, unevenColumns}) => {
  return (
    <Container>
      {
        options && options.length > 0 && options.map(({ labelId, label }, index) => {
          // Only render if key exists in data.
          if (labelId in data) {

            return (
              <StatRow
                key={labelId}
                label={label}
                value={getValue(data[labelId])}
                unevenColumns={unevenColumns}
                highlight={index === 0}
              />
            )
          }
          return undefined;
        })
      }
    </Container>
  );
};

export default StatsList;
