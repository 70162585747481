import {
  styled,
  TableBody,
  TableContainer,
  TableRow,
} from '@mui/material';
import {colors} from '../../../options/colors';
import tinycolor from 'tinycolor2';

export const TableWrapper = styled('div')(() => ({
  width: '100%',
  overflow: 'hidden',
  '& > p': {
    marginTop: 20,
  },
}));

export const StyledTableContainer = styled(TableContainer)(({ fullheight }) => ({
  maxHeight: fullheight ? 'unset' : 660,
}));

export const StyledTableBody = styled(TableBody)(() => ({}));

export const StyledTableRow = styled(TableRow)(({ color }) => ({
  '& .MuiTableCell-root': {
    background: color ? color : 'transparent',
    // Dynamically set color based on presence of color prop and if it is perceived to be light or dark
    color: color
      ? tinycolor(color).isDark()
        ? colors.white
        : colors.disabledBlack
      : colors.primaryGrey,
  },
  '&:nth-of-type(even)': {
    '& .MuiTableCell-root': {
      background: color ? color : 'rgba(255, 255, 255, 0.03)',
    },
  },
}));
