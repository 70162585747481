import {styled} from '@mui/material';
import {colors} from '../../../options/colors';

export const PlayerImageContainer = styled('div')(({ theme, image }) => ({
  background: `url(${image}) no-repeat center / cover`,
  backgroundColor: colors.white,
  height: 246,
  width: 246,
  borderRadius: 125,
  border: `3px solid ${colors.white}`,
  [theme.breakpoints.down('sm')]: {
    height: 200,
    width: 200,
  },
}));
