import {
  ModalHeader,
  ModalContent,
  ModalActions,
  CloseButton,
  CloseIcon,
} from '../StandardModal/StandardModal.styles';
import {styled, Dialog,} from '@mui/material';

export const StyledModal = styled(Dialog)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.4)',
  '.MuiPaper-root': {
    margin: '30px 0',
    padding: 0,
    background: 'rgba(0 ,0 ,0 ,0.3)',
    width: '100%',
    maxWidth: '800px !important',
    borderRadius: 5,
    backdropFilter: 'blur(20px)',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
}));

export {
  ModalHeader,
  ModalContent,
  ModalActions,
  CloseButton,
  CloseIcon,
};
