import {colors} from '../options/colors';
import {
  DescriptionOutlined,
  TrendingUpOutlined,
  ExtensionOutlined,
} from '@mui/icons-material';

export const overviewData = [
  {
    id: 1,
    avatarBgColor: colors.primaryOrange,
    icon: <DescriptionOutlined/>,
    title: 'Licences Sold',
    price: '£5,890',
  },
  {
    id: 2,
    avatarBgColor: colors.primaryGreen,
    icon: <TrendingUpOutlined/>,
    title: 'Add-ons Sold',
    price: '£1,580',
  },
  {
    id: 3,
    avatarBgColor: colors.primaryPurple,
    icon: <ExtensionOutlined/>,
    title: 'Lifetime Revenue',
    price: '£7,470',
  },
];

export const breakdownData = [
  {
    id: 1,
    avatarBgColor: colors.primaryOrange,
    icon: <DescriptionOutlined/>,
    title: 'Licences Sold',
    price: '£1,580',
  },
  {
    id: 2,
    avatarBgColor: colors.primaryGreen,
    icon: <TrendingUpOutlined/>,
    title: 'Add-ons Sold',
    price: '£850',
  },
  {
    id: 3,
    avatarBgColor: colors.primaryPurple,
    icon: <ExtensionOutlined/>,
    title: 'Lifetime Revenue',
    price: '£3,390',
  },
];

export const chartData = [1000, 2000, 3000, 3250, 4000, 4500];
