import {
  Container,
  StyledFormGroup,
  StyledLabel,
  UncheckedIcon,
  CheckedIcon,
  StyledCheckbox,
} from './CustomCheckbox.styles';
import FieldError from '../FieldError/FieldError';

const CustomCheckbox = ({id, checked, onChange, label, error}) => {
  return (
    <Container>
      <StyledFormGroup
        error={error ? 'true' : undefined}
      >
        <StyledLabel
          control={
            <StyledCheckbox
              id={id}
              checkedIcon={<CheckedIcon/>}
              icon={<UncheckedIcon/>}
              checked={checked}
              onChange={onChange}
            />
          }
          label={label}
        />
      </StyledFormGroup>
      {
        error &&
        <FieldError
          error={error}
        />
      }
    </Container>
  );
}

export default CustomCheckbox;
