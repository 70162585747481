import {useEffect, useState, useCallback, createContext, useContext} from 'react';
import {routes} from '../../options/routes';

// Helpers
import {getClubPlayersList} from '../../utils/helpers/getClubPlayersList';
import {
  loggedInPlayerInChoicesById,
} from '../../utils/helpers/playerChoicesHelpers';
import {
  getNewPlayerStats,
} from '../../utils/helpers/indexStatsHelpers';

// API calls
import {
  getClubPlayers,
  getBoardsRezzilIndex,
  getGlobalLeaderBoardStats,
} from '../../api';

// Contexts
import {BaseContext} from '../Global/BaseProvider';

const IndexStatsContext = createContext({});

const IndexStatsProvider = ({children}) => {
  const baseContext = useContext(BaseContext);
  const [indexStatsData, setIndexStatsData] = useState({});

  const getIndexStatsData = useCallback(async () => {
    // Only trigger API call for indexStats route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.indexStats) return;

    // Call API
    if (!baseContext.baseData.loading && !indexStatsData.loading) {
      setIndexStatsData({ loading: true });
      // Set score variables
      let playerProfiles = null,
          allProgrammeSessions = null,
          allProgrammeSessionsDeep = null,
          playerStats = null,
          worldAvgStats = null,
          programmeSessionStats = null,
          indexBreakdownData = null;
      const { id } = baseContext.baseData.clubProfile;
      const clubRezzilIndexData = await getBoardsRezzilIndex(id);
      const globalRezzilIndexData = await getGlobalLeaderBoardStats();
      // Call club player profiles API
      const playerProfilesData = await getClubPlayers(id);
      if (
        !playerProfilesData ||
        !playerProfilesData.success ||
        playerProfilesData.code !== 200 ||
        !clubRezzilIndexData ||
        !clubRezzilIndexData.success ||
        clubRezzilIndexData.code !== 200 ||
        !globalRezzilIndexData ||
        !globalRezzilIndexData.success ||
        globalRezzilIndexData.code !== 200
      ) {
        setIndexStatsData({
          loading: false,
          playerProfiles,
          allProgrammeSessions,
          allProgrammeSessionsDeep,
          playerStats,
          worldAvgStats,
          programmeSessionStats,
          indexBreakdownData,
          apiError: true,
          allLoaded: true,
        });
        return;
      }
      // Get playerProfiles from playerProfilesData
      const {profiles} = playerProfilesData.data;
      playerProfiles = getClubPlayersList(profiles);

      const clubLeaderboard = clubRezzilIndexData.data.leaderboard.scores;
      const globalLeaderboard = globalRezzilIndexData.data.leaderboards.rezzil_index.scores;

      // Get logged in player stats if in playerProfiles
      const loggedInPlayerId = baseContext.baseData.userProfile.id;
      const hasLoggedInPlayerInChoicesById = loggedInPlayerInChoicesById(loggedInPlayerId, playerProfiles);
      const initialPlayerId = hasLoggedInPlayerInChoicesById ? loggedInPlayerId : playerProfiles[0]?.id;
      // if (loggedInPlayerInChoicesById(loggedInPlayerId, playerProfiles)) {
        // Make relevant API calls to get data

      if (!initialPlayerId) {
        // const generalWorldAvgStats = await getWorldAverageStatsForNoPlayers();
        setIndexStatsData({
          loading: false,
          playerProfiles,
          allProgrammeSessions,
          allProgrammeSessionsDeep,
          playerStats,
          worldAvgStats: {},
          programmeSessionStats,
          indexBreakdownData,
          clubLeaderboard,
          globalLeaderboard,
          apiError: false,
          allLoaded: true,
        });
        return;
      }
        const {success, newPlayerStats} = await getNewPlayerStats(initialPlayerId, clubLeaderboard, globalLeaderboard);
        if (!success) {
          setIndexStatsData({
            loading: false,
            playerProfiles,
            allProgrammeSessions,
            allProgrammeSessionsDeep,
            playerStats,
            worldAvgStats,
            programmeSessionStats,
            indexBreakdownData,
            apiError: true,
            allLoaded: true,
          });
          return;
        }

        allProgrammeSessions = newPlayerStats.allProgrammeSessions;
        allProgrammeSessionsDeep = newPlayerStats.allProgrammeSessionsDeep;
        playerStats = newPlayerStats.playerStats;
        worldAvgStats = newPlayerStats.worldAvgStats;
        programmeSessionStats = newPlayerStats.programmeSessionStats;
        indexBreakdownData = newPlayerStats.indexBreakdownData;

      setIndexStatsData({
        loading: false,
        playerProfiles,
        allProgrammeSessions,
        allProgrammeSessionsDeep,
        playerStats,
        worldAvgStats,
        programmeSessionStats,
        indexBreakdownData,
        clubLeaderboard,
        globalLeaderboard,
        apiError: false,
        allLoaded: true,
      });
    }
  }, [baseContext]);

  useEffect(() => {
    getIndexStatsData();
  }, [getIndexStatsData]);

  return (
    <IndexStatsContext.Provider
      value={{
        indexStatsData,
        getIndexStatsData
      }}
    >
      {children}
    </IndexStatsContext.Provider>
  );
}

export {IndexStatsProvider, IndexStatsContext};

export default IndexStatsProvider;
