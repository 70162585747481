export const colors = {
  black: '#000000',
  menuPrimaryBlack: '#181818',
  menuSecondaryBlack: '#252525',
  chartTooltipBlack: '#262626',
  chartPointHoverBlack: '#242125',
  disabledBlack: '#222222',
  buttonTextBlack: '#331805',
  white: '#FFFFFF',
  opaqueWhite: 'rgba(255, 255, 255, 0.5)',
  primaryGrey: '#999999',
  secondaryGrey: '#717171',
  iconBtnBgGrey: '#4a4d57',
  dividerGrey: '#333333',
  chartGridGrey: '#555555',
  primaryOrange: '#F98F43',
  secondaryOrange: '#F2AF00',
  secondaryOrangeOpaque: '#F2AF0073',
  primaryRed: '#AA001D',
  secondaryRed: '#F8CBD2',
  markerRed: '#C70101',
  comparisonRed: '#F00F53',
  primaryGreen: '#52D259',
  primaryGreenOpaque: '#52D25973',
  secondaryGreen: '#B5E8B8',
  successDarkGreen: '#0F5C2E',
  successLightGreen: '#C6F6D9',
  buttonTextGreen: '#0E340E',
  rezzilPlayerBlue: '#3488AF',
  secondaryBlue: '#B2D1DF',
  beige: '#BEB08D',
  primaryPurple: '#4B2B7E',
  secondaryPurple: '#BCA8E1',
};
