import {styled} from '@mui/material';

export const HeaderContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const StyledSectionContainer = styled('div')(() => ({
  marginBottom: 40,
}));

export const FirstPLayerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > div:first-of-type': {
    flex: 1,
  },
  '& > div:last-of-type': {
    width: '33%',
    marginLeft: 20,
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    '& > div:first-of-type': {
      flex: 'unset',
      width: 'auto',
      marginBottom: 20,
    },
    '& > div:last-of-type': {
      width: 'auto',
      marginLeft: 0,
    },
  },
}));

export const StatsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const StatsLeftContainer = styled('div')(({ theme }) => ({
  minWidth: 185,
  marginRight: 30,
  width: '30%',
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    marginRight: 0,
    marginBottom: 20,
  },
}));

export const StatsRightContainer = styled('div')(() => ({
  flex: 1,
}));

export const AvatarContainer = styled('div')(() => ({
  marginBottom: 30,
}));

export const RadarChartContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: 300,
    margin: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: 'unset',
    width: '100%',
  },
  '@media print': {
    display: 'none',
  },
}));


export const BarChartIncLegendContainer = styled('div')(() => ({
  '@media print': {
    width: `500px !important`,
    // display: 'none',
  }
}));


export const BarChartContainer = styled('div')(() => ({
  '@media print': {
    // width: '60% !important',
  }
}));
