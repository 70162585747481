import {useState} from 'react';
import {
  RepsContainer,
  IconsContainer,
  DeleteText,
  DeleteInstructionsContainer,
  DeleteInstructionsText,
  FieldContainer,
} from './RepsList.styles';
import {buttonTypes} from '../../../options/buttonTypes';
import SectionContainer from '../../../components/Containers/SectionContainer/SectionContainer';
import RepsDetails from '../../../components/RepsPage/RepsDetails/RepsDetails';
import IconButton from '../../../components/Buttons/IconButton/IconButton';
import NoResultsText from '../../../components/Typography/NoResultsText/NoResultsText';
import StandardModal from '../../Modals/StandardModal/StandardModal';
import RepForm from '../../Forms/RepForm/RepForm';
import CustomTextField from '../../Inputs/CustomTextField/CustomTextField';
import {EditOutlined, DeleteOutlined} from '@mui/icons-material';

const RepsList = ({
  reps,
  onFieldChange,
  onSubmit,
  formData,
  formErrors,
  setFormData,
  setFormErrors,
  updateLoading,
  handleDelete,
  deleteLoading,
}) => {
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteRep, setDeleteRep] = useState({});
  const [deleteValue, setDeleteValue] = useState('');
  const [enableDelete, setEnableDelete] = useState(false);

  const handleFormModalClose = () => {
    // Reset state back to original values
    setFormModalOpen(false);
    setIsAdd(true);
    setFormData({
      id: undefined,
      firstname: '',
      lastname: '',
      email: '',
      role: 'REP_TEAM_MEMBER',
      telephoneCountryCode: '',
      telephone: '',
      location: '',
    });
    setFormErrors({
      firstname: '',
      lastname: '',
      email: '',
      role: '',
      telephoneCountryCode: '',
      telephone: '',
      location: '',
    });
  };

  const handleFormModalOpen = id => {
    if (id) {
      // Find index of item with matching id and pass those details into modal
      const index = reps.findIndex(el => el.id === id);
      setFormData(reps[index]);
      setIsAdd(false);
    }
    setFormModalOpen(true);
  };

  const handleFormModalSubmit = async () => {
    const result = await onSubmit();
    if (result) {
      handleFormModalClose();
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setDeleteRep({});
  };

  const handleDeleteModalOpen = id => {
    const index = reps.findIndex(el => el.id === id);
    setDeleteRep(reps[index]);
    setDeleteModalOpen(true);
  };

  const handleDeleteChange = e => {
    // Only enable delete button in modal after user has typed 'delete' in the text field
    setDeleteValue(e.target.value);
    const enable = e.target.value === 'delete';
    setEnableDelete(enable);
  };

  const handleDeleteModalSubmit = async () => {
    const result = await handleDelete(deleteRep.id);
    if (result) {
      handleDeleteModalClose();
    }

    // Reset delete text value
    setDeleteValue('');
    setEnableDelete(false);
  };

  return (
    <SectionContainer
      showBtn
      btnType={buttonTypes.link}
      btnText={'Add rep'}
      onClick={() => handleFormModalOpen()}
    >
      {
        reps && reps.length > 0 && reps.map(rep => (
          <RepsContainer
            key={rep.id}
          >
            <RepsDetails
              rep={rep}
            />
            <IconsContainer>
              <IconButton
                icon={<EditOutlined/>}
                onClick={() => handleFormModalOpen(rep.id)}
              />
              <IconButton
                icon={<DeleteOutlined/>}
                onClick={() => handleDeleteModalOpen(rep.id)}
              />
            </IconsContainer>
          </RepsContainer>
        ))
      }
      {
        (!reps || reps.length === 0) &&
        <NoResultsText
          text={'No Reps to display'}
        />
      }
      {
        formModalOpen &&
        <StandardModal
          open={formModalOpen}
          onClose={handleFormModalClose}
          title={`${isAdd ? 'Add' : 'Edit'} Rep`}
          btnText={`${isAdd ? 'Add' : 'Update'} Rep`}
          onClick={handleFormModalSubmit}
          loading={updateLoading}
        >
          <RepForm
            formData={formData}
            errors={formErrors}
            onChange={onFieldChange}
          />
        </StandardModal>
      }
      {
        deleteModalOpen &&
        <StandardModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          title={'Delete Rep'}
          btnText={'Delete Rep'}
          onClick={handleDeleteModalSubmit}
          disabled={!enableDelete}
          loading={deleteLoading}
        >
          <DeleteText>
            Are you sure you want to delete {deleteRep.name}?
            Once confirmed, this action cannot be undone.
          </DeleteText>
          <DeleteInstructionsContainer>
            <DeleteInstructionsText>
              Type 'delete' in the below field to confirm
            </DeleteInstructionsText>
            <FieldContainer>
              <CustomTextField
                id={'deleteText'}
                placeholder={'delete'}
                onChange={handleDeleteChange}
                type={'text'}
                value={deleteValue}
              />
            </FieldContainer>
          </DeleteInstructionsContainer>
        </StandardModal>
      }
    </SectionContainer>
  );
};

export default RepsList;
