import {useEffect, useState} from 'react';
import {useMediaQuery} from '@mui/material';
import {
  Container,
  BurgerContainer,
  RezzilLogoContainer,
  ClubLogoContainer,
  StyledLink,
  StyledDrawer,
} from './MobileNavMenu.styles';
import {routes} from '../../../options/routes';
import CustomBurger from '../CustomBurger/CustomBurger';
import RezzilLogoAvatar from '../../Avatars/RezzilLogoAvatar/RezzilLogoAvatar';
import ClubLogoAvatar from '../../Avatars/ClubLogoAvatar/ClubLogoAvatar';
import MainNavMenu from '../MainNavMenu/MainNavMenu';

const MobileNavMenu = ({activeRoute, clubLogo, disableIndex}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (matches) setOpen(false);
  }, [matches]);

  const toggleDrawer = e => {
    if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
      return;
    }

    setOpen(!open);
  };

  const handleLinkClick = () => {
    window.location.href = routes.home;
  };

  return (
    <>
      <Container>
        <BurgerContainer>
          <CustomBurger
            open={open}
            onClick={toggleDrawer}
          />
        </BurgerContainer>
        <RezzilLogoContainer>
          <StyledLink
            onClick={handleLinkClick}
          >
            <RezzilLogoAvatar/>
          </StyledLink>
        </RezzilLogoContainer>
        <ClubLogoContainer>
          <StyledLink
            onClick={handleLinkClick}
          >
            <ClubLogoAvatar
              clubLogo={clubLogo}
            />
          </StyledLink>
        </ClubLogoContainer>
      </Container>
      <StyledDrawer
        anchor={'left'}
        open={open}
        onClose={toggleDrawer}
      >
        <MainNavMenu
          activeRoute={activeRoute}
          clubLogo={clubLogo}
          disableIndex={disableIndex}
          mobile
        />
      </StyledDrawer>
    </>
  );
};

export default MobileNavMenu;
