import {
  StyledTooltip,
  InfoButton,
  InfoIcon,
} from './InfoTooltip.styles';

const InfoTooltip = ({title}) => {
  return (
    <StyledTooltip
      id={'custom-tooltip'}
      title={title}
      arrow
      placement='top'
    >
      <InfoButton>
        <InfoIcon/>
      </InfoButton>
    </StyledTooltip>
  );
};

export default InfoTooltip;
