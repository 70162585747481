import { useEffect, useState, useCallback, createContext } from 'react';
import {routes} from '../../options/routes';

// API calls
import {
  getNewsFeed,
  getTwitterFeed,
} from '../../api';

const HomeContext = createContext({});

const HomeProvider = ({children}) => {
  const [homeData, setHomeData] = useState({
    news: null,
    twitter: null,
    apiError: false,
  });

  const getHomeData = useCallback(async () => {
    // Only trigger API call for home route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.home) return;

    // Call API
    let apiError = false,
        news = [],
        twitter = [];

    // Get news feed data - set error value if API error, else set news value
    const newsData = await getNewsFeed();
    if (!newsData || !newsData.success || !newsData.data.news.length) {
      apiError = true;
    } else {
      news = newsData.data.news
    }

    // Get Twitter feed data - set error value if API error, else set twitter value
    const twitterData = await getTwitterFeed();
    if (!twitterData || !twitterData.success || !twitterData.data.twitter.length) {
      apiError = true;
    } else {
      twitter = twitterData.data.twitter;
    }

    // Set data
    setHomeData({
      loading: false,
      news,
      twitter,
      apiError,
      allLoaded: true,
    });
  }, []);

  useEffect(() => {
    getHomeData();
  }, [getHomeData]);

  return (
    <HomeContext.Provider
      value={{
        homeData,
        getHomeData
      }}
    >
      {children}
    </HomeContext.Provider>
  );
}

export {HomeProvider, HomeContext};

export default HomeProvider;
