import {useEffect, useState, useCallback} from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router} from 'react-router-dom';
import theme from './theme/muiTheme';
import {ThemeProvider} from '@mui/material';
import {routes, nonAuthenticatedRoutes} from './options/routes';
import Content from './parts/Content';

// Instantiate createBrowserHistory to enable redirecting if #! is in URL
const history = createBrowserHistory();

const App = () => {
  const [userAuthenticated, setUserAuthenticated] = useState();

  // Remove #! in URL and redirect user to relevant path
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  const getUserProfile = useCallback(async () => {
    // Check if profile exists in local storage - redirect to home if trying to access unauthenticated route
    const activeRoute = window.location.pathname;
    const token = localStorage.getItem('token');
    const userProfile = localStorage.getItem('userProfile');
    const clubProfile = localStorage.getItem('clubProfile');
    if (!token) {
      setUserAuthenticated(false);
      if (
        !nonAuthenticatedRoutes.includes(activeRoute) &&
        !activeRoute.includes('/verify/') &&
        !activeRoute.includes('/accept-invitation/') &&
        !activeRoute.includes('/reset-password/') &&
        !activeRoute.includes('/share')
      ) {
        window.location.href = routes.login;
      }
      return;
    }
    if (
      (token || (userProfile && Object.keys(userProfile).length) || (clubProfile && Object.keys(clubProfile).length)) &&
      nonAuthenticatedRoutes.some(v => activeRoute.includes(v))
    ) {
      if (activeRoute.includes('share')) {
        // Do not redirect if the route is a share route.
        return;
      }
      window.location.href = routes.home;
      return;
    }

    setUserAuthenticated(true);
  }, []);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  if (userAuthenticated === 'undefined') {
    return <div></div>
  }
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div>
          <Content
            userAuthenticated={userAuthenticated}
          />
        </div>
      </ThemeProvider>
    </Router>
  );
};

export default App;
