import {
  styled,
  Link,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledLink = styled(Link)(({ theme, active, disabled }) => ({
  ...theme.typography.body1,
  textDecoration: 'none',
  transition: 'all 0.3s ease',
  cursor: disabled ? 'default' : 'pointer',
  display: 'flex',
  alignItems: 'center',
  minHeight: 50,
  padding: '0 12px',
  borderRadius: 5,
  color: disabled ? colors.secondaryGrey : colors.buttonTextGreen,
  background: active ? colors.opaqueWhite : 'transparent',
  '&:hover': {
    color: disabled ? colors.secondaryGrey : colors.buttonTextGreen,
    background: disabled ? 'transparent' : colors.opaqueWhite,
    '& svg': {
      color: disabled ? colors.secondaryGrey : colors.buttonTextGreen,
    },
  },
  '& svg': {
    marginRight: 20,
    color: disabled ? colors.secondaryGrey : colors.buttonTextGreen,
    transition: 'inherit',
  },
}));

export const LinkContentContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
}));
