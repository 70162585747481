import {createColorHex} from './createColorHex';

export const getPlayerDropdownOptions = players => {
  // Create array of player options to render in select dropdowns
  let options = [];
  // Return empty array if no players
  if (!players || players.length === 0) return options;

  players.forEach(player => {
    const playerName = `${player.firstname} ${player.lastname}`;
    const option = {
      value: player.id,
      label: playerName,
      color: createColorHex(playerName),
    };
    options.push(option);
  });

  return options;
};

export const getPlayerChoices = (choices, idx, value, defaultPlayerId) => {
  // Create array of player IDs base on player selection
  // Remove element from rows if player is removed/set blank, else add id to rows
  const rows = [...choices];
  if (value === '') {
    rows.splice(idx, 1);
    if (rows.length === 0) {
      // Add logged in player id to playerChoices
      rows.push(defaultPlayerId);
    } else if (rows.length === 1 && rows[0] === '') {
      // Set logged in player id as first dropdown option if no options selected
      rows[0] = defaultPlayerId;
    }
  } else {
    rows[idx] = value;
  }

  return rows;
};

export const loggedInPlayerInChoices = (id, players) => {
  // Check if id is in players array and return result as bool
  if (!players || players.length === 0) return false;
  return !!players.find(player => player.value === id);
};

export const loggedInPlayerInChoicesById = (id, players) => {
  // Check if id is in players array (based on id) and return result as bool
  if (!players || players.length === 0) return false;
  return !!players.find(player => player.id === id);
};
