import {styled} from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down(850)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export const SelectsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down(750)]: {
    flexDirection: 'column-reverse',
  },
}));

export const SelectContainer = styled('div')(() => ({
  width: 294,
  marginRight: 30,
  marginBottom: 15,
  '&:last-of-type': {
    marginRight: 0,
  },
}));
