import {
  DetailsContainer,
} from './ClubsDetails.styles';
import ReadOnlyField from '../../../components/Inputs/ReadOnlyField/ReadOnlyField';

const ClubsDetails = ({club, showLicenceInfo}) => {
  return (
    <DetailsContainer>
      <ReadOnlyField
        label={'Name'}
        value={club.name}
      />
      <ReadOnlyField
        label={'Contact'}
        value={club.contact}
      />
      <ReadOnlyField
        label={'Phone'}
        value={club.telephone}
      />
      <ReadOnlyField
        label={'Location'}
        value={club.location}
      />
      {
        showLicenceInfo &&
        <ReadOnlyField
          label={'Index licence'}
          value={club.indexLicenceCount}
        />
      }
      {/* <ReadOnlyField
        label={'Analysis licence'}
        value={club.analysisLicenceCount}
        showDiscount
        discount={club.analysisLicenceDiscount}
      /> */}
      {/* <ReadOnlyField
        label={'DB licence'}
        value={club.dbLicenceCount}
        showDiscount
        discount={club.dbLicenceDiscount}
      /> */}
      {/* <ReadOnlyField
        label={'Rezzil Player licence'}
        value={club.rezzilPlayerLicenceCount}
        showDiscount
        discount={club.rezzilPlayerLicenceDiscount}
      /> */}
      {/* <ReadOnlyField
        label={'Sales Rep'}
        value={club.salesRep || 'None'}
      />
      <ReadOnlyField
        label={'Rep revenue'}
        value={club.revenue}
      /> */}
    </DetailsContainer>
  );
};

export default ClubsDetails;
