export const playerStatsOptions = [
  {
    labelId: 'rezzilId',
    label: 'Rezzil ID',
  },
  {
    labelId: 'age',
    label: 'Age',
  },
  {
    labelId: 'position',
    label: 'Position',
  },
  {
    labelId: 'bestOverallScore',
    label: 'Best Score',
  },
  {
    labelId: 'lastTested',
    label: 'Last Tested',
  },
  {
    labelId: 'clubRank',
    label: 'Club Rank',
  },
  {
    labelId: 'worldRank',
    label: 'World Rank',
  },
];

export const indexStatsOptions = [
  {
    labelId: 'rezzilId',
    label: 'Rezzil ID',
  },
  {
    labelId: 'age',
    label: 'Age',
  },
  {
    labelId: 'position',
    label: 'Position',
  },
  {
    labelId: 'lastTested',
    label: 'Last Tested',
  },
  {
    labelId: 'clubRank',
    label: 'Club Rank',
  },
  {
    labelId: 'worldRank',
    label: 'World Rank',
  },
];

export const clubStatsOptions = [
  {
    labelId: 'players',
    label: 'Players',
  },
  // {
  //   labelId: 'worldRank',
  //   label: 'World Rank',
  // },
];
