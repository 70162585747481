import {Container} from './SharePrintBtnGroup.styles';
import IconButton from '../../Buttons/IconButton/IconButton';
import { ShareOutlined, PrintOutlined } from '@mui/icons-material';
import SectionSpinner from '../../Loading/SectionSpinner/SectionSpinner';

const SharePrintBtnGroup = ({onShareClick, shareLoading, showPrint}) => {
  const handlePrintClick = () => {
    window.print();
    return false;
  };

  // Hide the share icon - to be implemented in a future release.
  const showShare = false;
  return (
    <Container
      print={showPrint ? 'true' : undefined}
    >
      {
        shareLoading &&
        <SectionSpinner/>
      }
      {
        !shareLoading && showShare &&
        <IconButton
          icon={<ShareOutlined/>}
          onClick={onShareClick}
        />
      }
      {
        showPrint &&
        <IconButton
          icon={<PrintOutlined/>}
          onClick={handlePrintClick}
        />
      }
    </Container>
  );
};

export default SharePrintBtnGroup;
