export const passwordIsValid = password => {
  // Password should include at least:
  //   - 1 number
  //   - 1 lower letter
  //   - 1 upper letter
  //   - 8 characters (special characters optional)
  return !(
    !password || !password.match(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])[\w~@#$%^&*+=`|{}:;!._,?"'/()\\<>[\]-]{8,}$/)
  );
}

export const repeatPasswordIsValid = (password, repeatPassword) => {
  return (!!repeatPassword && password === repeatPassword);
}
