import { SubscriptionFooter,
  StyledImage,
  SubscriptionHeader,
  SubscriptionBody,
  SubscriptionExplanation,
  PriceExplanation,
  PriceRow,
  PricePerLicence,
  StyledButton,
  PriceContainer
} from './Footer.styles';
import Price from '../Typography/Price/Price';
import { NotificationsNoneOutlined } from '@mui/icons-material';
import rezzilPlayerSubscribe from '../../assets/images/player-22-subscribe.png';


const Footer = ({ onSubscribeClick, monthlyPrice, annualPrice }) => (
  <SubscriptionFooter>
    <StyledImage
      src={rezzilPlayerSubscribe}
      alt={`Subscribe to Rezzil Player`}
    />
    <SubscriptionExplanation>
      <SubscriptionHeader>Squad Pro</SubscriptionHeader>
      <SubscriptionBody>Upgrade your Rezzil Player with Squads Pro! Link devices together and add unlimited players to pool stats and leader boards.</SubscriptionBody>
    </SubscriptionExplanation>
    <PriceExplanation>
      <PriceRow>
        <PriceContainer>
          <Price price={monthlyPrice} hasAsterisk perMonth/>
        </PriceContainer>
        <PriceContainer>
          <Price price={annualPrice} hasAsterisk perAnnum/>
        </PriceContainer>

      </PriceRow>
      <PriceRow singleLine>
      <StyledButton
        variant='text'
        onClick={onSubscribeClick}
      >
        <NotificationsNoneOutlined />
          Subscribe
      </StyledButton>
        <PricePerLicence>* Price per licence</PricePerLicence>
      </PriceRow>
    </PriceExplanation>



  </SubscriptionFooter>
)

export default Footer;