import {
  Container,
  FieldContainer,
} from './GroupForm.styles';
import CustomTextField from '../../Inputs/CustomTextField/CustomTextField';

const GroupForm = ({formData, errors, onChange}) => {
  return (
    <Container>
      <FieldContainer>
        <CustomTextField
          label={'Name'}
          id={'label'}
          placeholder={'Colleagues'}
          required
          onChange={onChange}
          error={errors.label}
          type={'text'}
          value={formData.label}
        />
      </FieldContainer>
    </Container>
  )
};

export default GroupForm;
