import {routes} from '../../options/routes';

const setLocalStorage = data => {
  // Set local storage details and redirect to home route
  localStorage.setItem('token', JSON.stringify(data.accessToken));
  localStorage.setItem('userRole', JSON.stringify(data.role));
  localStorage.setItem('userProfile', JSON.stringify(data.user));
  localStorage.setItem('clubProfile', JSON.stringify(data.club));
  window.location.href = routes.home;
};

export default setLocalStorage;
