import {
  Container,
  TextContainer,
  StyledText,
  DiscountContainer,
  DiscountHeading,
  DiscountText
} from './ReadOnlyField.styles';
import Label from '../../Typography/Label/Label';

const ReadOnlyField = ({label, value, showDiscount, discount}) => {
  return (
    <Container>
      <Label
        text={label}
      />
      <TextContainer>
        <StyledText
          discount={discount}
        >
          {value}
        </StyledText>
        {
          showDiscount &&
          <DiscountContainer>
            <DiscountHeading>
              Discount
            </DiscountHeading>
            <DiscountText>
              {discount}%
            </DiscountText>
          </DiscountContainer>
        }
      </TextContainer>
    </Container>
  )
};

export default ReadOnlyField;
