import {styled, Button} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({}));

export const StyledButton = styled(Button)(({ theme}) => ({
  ...theme.typography.h5,
  borderColor: colors.primaryOrange,
  backgroundColor: colors.primaryOrange,
  color: colors.buttonTextBlack,
  width: '100%',
}));
