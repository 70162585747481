export const overviewOptions = [
  {
    value: 'index',
    label: 'Rezzil Index',
  },
  {
    value: 'analysis',
    label: 'Rezzil Analysis',
  },
  {
    value: 'db',
    label: 'Rezzil DB',
  },
  {
    value: 'rezzilPlayer',
    label: 'Rezzil Player',
  },
];

export const breakdownOptions = [
  {
    value: 2,
    label: 'Last 2 months',
  },
  {
    value: 4,
    label: 'Last 4 months',
  },
  {
    value: 6,
    label: 'Last 6 months',
  },
];
