
import {
  RepeatOutlined,
  SyncAltOutlined,
  HdrWeakOutlined,
  RadarOutlined,
  SportsSoccerOutlined,
  FilterTiltShiftOutlined,
} from '@mui/icons-material';

import { getAllProgrammeStats, getSingleProgrammeStats } from '../../api';

const getRank = (profileId, leaderboard) => {
  if (!leaderboard) {
    return 0;
  }
  return leaderboard.find(
    (score) => score.profileId === Number(profileId))?.rank
};

export const getDeepProgrammeStats = async (profileId, programmes) => {
  // Get full stats for each programme (session)
  let hasErrors = false,
    data = [];

  let responses = [];
  for (const programme of programmes) {
    const singleProgrammeStatsData = await getSingleProgrammeStats(profileId, programme.id);
    responses.push(singleProgrammeStatsData);
  }

  // Set hasErrors to true if error with any of the API calls, else create array of the returned data
  responses.length > 0 && responses.forEach(response => {
    if (!response || !response.success || response.code !== 200) {
      hasErrors = true;
    } else {
      data.push(response.data);
    }
  });

  return {
    hasErrors,
    data,
  };
};

export const getSortedProgrammes = sessions => {
  // Sort programmes in date order - newest to oldest
  sessions.sort((a, b) => {
    const dateA = new Date(a.created),
      dateB = new Date(b.created);
    return dateB - dateA;
  });
  return sessions;
};

// Create a map of stat names
const programDrillStatsMap = {
  'score': {
    programStatName: 'score',
    averageStatName: 'averageScore'
  },
  'rightFoot': {
    programStatName: 'rightFootShots',
  },
  'leftFoot': {
    programStatName: 'leftFootShots',
  },
  'avgDwell': {
    programStatName: 'dwellTimeAverage',
    averageStatName: 'averageDwellTime',
  },
  'correct': {
    programStatName: 'correct',
  },
  'incorrect': {
    programStatName: 'incorrect',
  },
  'level': {
    programStatName: 'level',
  },
}

const getAverage = (drillStats, drillId, statName) => {
  // const drillStats.map((stat) => )
  const total = drillStats.map((stat) => stat[drillId]?.latestStat).reduce((previousValue, currentValue) => previousValue + (currentValue && currentValue[statName] ? currentValue[statName] : 0), 0);
  return total / drillStats.length;
}

const getDrillStatFromProgramme = (drillStats, displayDrillId, drillId, drillStatNames) => {
  const individialDrillStats = drillStatNames.map(statName => {
    // const statName = Array.isArray(statName) ? statName[0] : statName;
    // const average = programDrillStatsMap[statName]?.averageStatName ? drillStats[0][drillId]?.allTime[programDrillStatsMap[statName].averageStatName] : 0;
    const newAverage = getAverage(drillStats, drillId, statName);
    return {
      [statName]: {
        average: newAverage,
        scores: drillStats.map((stat, index) => {
          const statNameToUse = programDrillStatsMap[statName]?.programStatName;
          if (!statNameToUse) {
            console.error(`${statName} missing`);
          }
          return {
            score: stat[drillId]?.latestStat[statNameToUse],
            createdAt: new Date(stat[drillId]?.latestFullDate)
          }
          }),
      }
    }
  });
  return {
    drillId: displayDrillId,
    stats: individialDrillStats
  };
}

const generateDrilllStatsProgrammes = async (playerId, programmes) => {
  const { hasErrors, data } = await getDeepProgrammeStats(playerId, programmes);
    if (hasErrors) return { success: false };
    const drillStats = data.map((stat) => stat.drillStats);
    const drillStatsProgrammes = [
      getDrillStatFromProgramme(
        drillStats,
        'rondo-scan',
        'pass_awareness_1_4',
        [
          'score',
          'rightFoot',
          'leftFoot',
          'avgDwell',
        ]
      ),
      getDrillStatFromProgramme(
        drillStats,
        'colour-combo',
        'colour_combo_warmup',
        [
          'score',
          'correct',
          'incorrect',
          'level'
        ]
      ),
      getDrillStatFromProgramme(
        drillStats,
        'shoulder-sums',
        'number_pass',
        [
          'score',
          'correct',
          'incorrect',
          'rightFoot',
          'leftFoot',
          'level'
        ]
      ),
      getDrillStatFromProgramme(
        drillStats,
        'head-smart',
        'headers',
        [
          'score',
          'correct',
          'incorrect',
          'level'
        ]
      ),
      getDrillStatFromProgramme(
        drillStats,
        'blocking',
        'pressure-pass',
        [
          'score',
          'correct',
          'incorrect',
          'rightFoot',
          'leftFoot',
          'avgDwell',
          'level'
        ]
        /*
          'score',
          ['correct', 'successfulPasses'],
          ['incorrect', 'incorrectPasses'],
          ['rightFoot', 'rightFootShots'],
          ['leftFoot', 'leftFootShots'],
          ['avgDwellTime', 'dwellTimeAverage'],
          ['level', 'round']
        */
      ),
      getDrillStatFromProgramme(
        drillStats,
        'wolfy-180',
        'wolfy_180',
        [
          'score',
          'correct',
          'incorrect',
        ]
      )
    ];
  return drillStatsProgrammes;
}


export const fetchDrillStatsProgrammes = async (playerId, allProgrammeSessions, programmeIndex) => {
  const programLimit = programmeIndex + 10;
  const latestTenProgrammes = allProgrammeSessions.length > programLimit
      ? allProgrammeSessions.slice(programmeIndex, programLimit)
      : allProgrammeSessions.slice(programmeIndex,  allProgrammeSessions.length );

  const drillStatsProgrammes = await generateDrilllStatsProgrammes(playerId, latestTenProgrammes);
  return drillStatsProgrammes;
}

export const createDrillStatsProgrammes = async (playerId) => {
  if (!playerId) {
    return [
      {
        drillId: 'rondo-scan',
        stats: [],
      }, {
        drillId: 'colour-combo',
        stats: [],
      }, {
        drillId: 'shoulder-sums',
        stats: [],
      }, {
        drillId: 'head-smart',
        stats: [],
      }, {
        drillId: 'blocking',
        stats: [],
      }, {
        drillId: 'wolfy-180',
        stats: [],
      },
    ];
  };
  const allProgrammeStatsData = await getAllProgrammeStats(playerId);
  if (!allProgrammeStatsData || !allProgrammeStatsData.success || allProgrammeStatsData.code !== 200)
    return {success: false};

  // Call deep programme stats API for 10 most recent programmes
  const {programmes} = allProgrammeStatsData.data;
  const allProgrammeSessions = getSortedProgrammes(programmes);
  if (allProgrammeSessions.length === 0) {
    return [
      {
        drillId: 'rondo-scan',
        stats: [],
      }, {
        drillId: 'colour-combo',
        stats: [],
      }, {
        drillId: 'shoulder-sums',
        stats: [],
      }, {
        drillId: 'head-smart',
        stats: [],
      }, {
        drillId: 'blocking',
        stats: [],
      }, {
        drillId: 'wolfy-180',
        stats: [],
      },
    ];
  }
  const latestTenProgrammes = allProgrammeSessions.length > 10
      ? allProgrammeSessions.slice(0, 10)
      : allProgrammeSessions;


  const drillStatsProgrammes = await generateDrilllStatsProgrammes(playerId, latestTenProgrammes);

  return { drillStats: drillStatsProgrammes, allProgrammeSessions };

}

export const createPlayerStatsObject = (profileData, drillData, clubLeaderboard, worldLeaderboard, latestPlayerStats) => {
  const clubLeaderboardData = clubLeaderboard.leaderboards;
  const worldLeaderboardData = worldLeaderboard.leaderboards;
  const latestDrillStats = latestPlayerStats.drillStats;
  const { rondo_scan, colour_combo, shoulder_sums, head_smart, pressure_pass, wolfy_180 } = drillData;
  const drillDataObject = {
    id: profileData?.profile?.id,
    picture: profileData?.profile?.picture,
    firstname: profileData?.profile?.firstname,
    lastname: profileData?.profile?.lastname,
    clubName: profileData?.profile?.club_name,
    avgStats: [
      {
        drillId: 'rondo-scan',
        drillName: 'Rondo Scan',
        drillLogo: <RepeatOutlined />,
        score: {
          player: rondo_scan?.profile?.avgScore,
          global: rondo_scan?.global?.avgScore,
        },
        avgDwell: {
          player: rondo_scan?.profile?.avgDwellTime !== null && rondo_scan?.profile?.avgDwellTime !== undefined  ? `${rondo_scan?.profile?.avgDwellTime?.toFixed(2)}s` : `--` ,
          global: rondo_scan?.global?.avgDwellTime !== null && rondo_scan?.global?.avgDwellTime !== undefined? `${rondo_scan?.global?.avgDwellTime?.toFixed(2)}s` : `--` ,
        },
        rightFoot: {
          player: rondo_scan?.profile?.avgRightFoot,
          global:rondo_scan?.global?.avgRightFoot,
        },
        leftFoot: {
          player: rondo_scan?.profile?.avgLeftFoot,
          global: rondo_scan?.global?.avgLeftFoot,
        },
        goals: {
          player: rondo_scan?.profile?.avgGoals,
          global: rondo_scan?.global?.avgGoals,
        },
        level: {
          player: rondo_scan?.profile?.avgLevel,
          global: rondo_scan?.global?.avgLevel,
        },
      },
      {
        drillId: 'colour-combo',
        drillName: 'Colour Combo',
        drillLogo: <HdrWeakOutlined />,
        score: {
          player: colour_combo?.profile?.avgScore,
          global: colour_combo?.global?.avgScore,
        },
        correct: {
          player: colour_combo?.profile?.avgCorrect,
          global: colour_combo?.global?.avgCorrect,
        },
        incorrect: {
          player: colour_combo?.profile?.avgIncorrect,
          global: colour_combo?.global?.avgIncorrect,
        },
        bonus: {
          player: colour_combo?.profile?.avgBonus,
          global: colour_combo?.global?.avgBonus,
        },
        level: {
          player: colour_combo?.profile?.avgLevel,
          global: colour_combo?.global?.avgLevel,
        },
      },
      {
        drillId: 'shoulder-sums',
        drillName: 'Shoulder Sums',
        drillLogo: <RadarOutlined />,
        score: {
          player: shoulder_sums?.profile?.avgScore,
          global: shoulder_sums?.global?.avgScore
        },
        correct: {
          player: shoulder_sums?.profile?.avgCorrect,
          global: shoulder_sums?.global?.avgCorrect,
        },
        incorrect: {
          player: shoulder_sums?.profile?.avgIncorrect,
          global: shoulder_sums?.global?.avgIncorrect,
        },
        rightFoot: {
          player: shoulder_sums?.profile?.avgRightFoot,
          global: shoulder_sums?.global?.avgRightFoot,
        },
        leftFoot: {
          player: shoulder_sums?.profile?.avgLeftFoot,
          global: shoulder_sums?.global?.avgLeftFoot,
        },
        level:{
          player: shoulder_sums?.profile?.avgLevel,
          global: shoulder_sums?.global?.avgLevel,
        },
      },
      {
        drillId: 'head-smart',
        drillName: 'Head Smart',
        drillLogo: <SportsSoccerOutlined />,
        score: {
          player: head_smart?.profile?.avgScore,
          global: head_smart?.global?.avgScore,
        },
        correct: {
          player: head_smart?.profile?.avgSuccessful,
          global: head_smart?.global?.avgSuccessful,
        },
        incorrect: {
          player: head_smart?.profile?.avgWrong,
          global: head_smart?.global?.avgWrong,
        },
        level: {
          player: head_smart?.profile?.avgLevel,
          global: head_smart?.global?.avgLevel,
        },
      },
      {
        drillId: 'pressure-pass',
        drillName: 'Pressure Pass',
        drillLogo: <SyncAltOutlined />,
        score: {
          player: pressure_pass?.profile?.avgScore,
          global: pressure_pass?.global?.avgScore,
        },
        avgDwell: {
          player: pressure_pass?.profile?.avgDwellTime !== null && pressure_pass?.profile?.avgDwellTime !== undefined ? `${pressure_pass?.profile?.avgDwellTime.toFixed(2)}s` : `--` ,
          global: pressure_pass?.global?.avgDwellTime !== null && pressure_pass?.global?.avgDwellTime !== undefined ? `${pressure_pass?.global?.avgDwellTime.toFixed(2)}s` : `--` ,
        },
        correct: {
          player: pressure_pass?.profile?.avgCorrect,
          global: pressure_pass?.global?.avgCorrect,
        },
        incorrect: {
          player: pressure_pass?.profile?.avgIncorrect,
          global: pressure_pass?.global?.avgIncorrect,
        },
        rightFoot: {
          player: pressure_pass?.profile?.avgRightFoot,
          global: pressure_pass?.global?.avgRightFoot,
        },
        leftFoot: {
          player: pressure_pass?.profile?.avgLeftFoot,
          global: pressure_pass?.global?.avgLeftFoot,
        },
        level: {
          player: pressure_pass?.profile?.avgLevel,
          global: pressure_pass?.global?.avgLevel,
        }
      },
      {
        drillId: 'wolfy-180',
        drillName: 'Wolfy 180',
        drillLogo: <FilterTiltShiftOutlined />,
        score: {
          player: wolfy_180?.profile?.avgScore,
          global:  wolfy_180?.global?.avgScore,
        },
        correct: {
          player: wolfy_180?.profile?.avgCorrect,
          global: wolfy_180?.global?.avgCorrect,
        },
        incorrect: {
          player: wolfy_180?.profile?.avgIncorrect,
          global: wolfy_180?.global?.avgIncorrect,
        },
        avgDecision: {
          player: wolfy_180?.profile?.avgDwellTime !== null && wolfy_180?.profile?.avgDwellTime !== undefined ? `${wolfy_180?.profile?.avgDwellTime.toFixed(2)}s` : `--` ,
          global: wolfy_180?.global?.avgDwellTime !== null && wolfy_180?.global?.avgDwellTime !== undefined ? `${wolfy_180?.global?.avgDwellTime.toFixed(2)}s` : `--` ,
        },
      },
    ],
    compareStats: [
      {
        drillId: 'rondo-scan',
        drillName: 'Rondo Scan',
        drillLogo: <RepeatOutlined />,
        bestScore: rondo_scan?.profile?.maxScore,
        avgScore: rondo_scan?.profile?.avgScore,
        lastScore: latestDrillStats?.pass_awareness_1_4?.latestStat?.score,
        highestLevel: rondo_scan?.profile.maxLevel,
        clubRank: getRank(profileData?.profile?.id, clubLeaderboardData?.pass_awareness_1_4?.scores),
        worldRank: getRank(profileData?.profile?.id, worldLeaderboardData?.pass_awareness_1_4?.scores),
      },
      {
        drillId: 'colour-combo',
        drillName: 'Colour Combo',
        drillLogo: <HdrWeakOutlined />,
        bestScore: colour_combo?.profile?.bestScore,
        avgScore: colour_combo?.profile?.avgScore,
        lastScore: latestDrillStats?.colour_combo_warmup?.latestStat?.score,
        highestLevel: colour_combo?.profile.maxLevel,
        clubRank: getRank(profileData?.profile?.id, clubLeaderboardData?.colour_combo_warmup?.scores),
        worldRank: getRank(profileData?.profile?.id, worldLeaderboardData?.colour_combo_warmup?.scores),
      },
      {
        drillId: 'shoulder-sums',
        drillName: 'Shoulder Sums',
        drillLogo: <RadarOutlined />,
        bestScore: shoulder_sums?.profile?.maxScore,
        avgScore: shoulder_sums?.profile?.avgScore,
        lastScore: latestDrillStats?.number_pass?.latestStat?.score,
        highestLevel: shoulder_sums?.profile.maxLevel,
        clubRank: getRank(profileData?.profile?.id, clubLeaderboardData?.number_pass?.scores),
        worldRank: getRank(profileData?.profile?.id, worldLeaderboardData?.number_pass?.scores),
      },
      {
        drillId: 'head-smart',
        drillName: 'Head Smart',
        drillLogo: <SportsSoccerOutlined />,
        bestScore: head_smart?.profile?.bestScore,
        avgScore: head_smart?.profile?.avgScore,
        lastScore: latestDrillStats?.headers?.latestStat?.score,
        highestLevel: head_smart?.profile.maxLevel,
        clubRank: getRank(profileData?.profile?.id, clubLeaderboardData?.headers?.scores),
        worldRank: getRank(profileData?.profile?.id, worldLeaderboardData?.headers?.scores),
      },
      {
        drillId: 'pressure-pass',
        drillName: 'Pressure Pass',
        drillLogo: <SyncAltOutlined />,
        bestScore: pressure_pass?.profile?.maxScore,
        avgScore: pressure_pass?.profile?.avgScore,
        lastScore: latestDrillStats?.blocking?.latestStat?.score,
        highestLevel: pressure_pass?.profile.maxRound,
        clubRank: getRank(profileData?.profile?.id, clubLeaderboardData?.blocking?.scores),
        worldRank: getRank(profileData?.profile?.id, worldLeaderboardData?.blocking?.scores),
      },
      {
        drillId: 'wolfy-180',
        drillName: 'Wolfy 180',
        drillLogo: <FilterTiltShiftOutlined />,
        bestScore: wolfy_180?.profile?.maxScore,
        avgScore: wolfy_180?.profile?.avgScore,
        lastScore: latestDrillStats?.wolfy_180?.latestStat?.score,
        highestLevel: wolfy_180?.profile.maxLevel,
        clubRank: getRank(profileData?.profile?.id, clubLeaderboardData?.wolfy_180?.scores),
        worldRank: getRank(profileData?.profile?.id, worldLeaderboardData?.wolfy_180?.scores),
      },
    ],
    drillStats: [
      {
        drillId: 'rondo-scan',
        drillName: 'Rondo Scan',
        drillLogo: <RepeatOutlined />,
        bestScore: rondo_scan?.profile?.maxScore,
        avgDwell: rondo_scan?.profile?.avgDwellTime !== null ? `${rondo_scan?.profile?.avgDwellTime.toFixed(2)}s` : `--` ,
        rightFoot: rondo_scan?.profile.maxRightFoot,
        leftFoot: rondo_scan?.profile.maxLeftFoot,
        goals: rondo_scan?.profile.maxGoals,
        level: rondo_scan?.profile.maxLevel,
        clubRank: getRank(profileData?.profile?.id, clubLeaderboardData?.pass_awareness_1_4?.scores),
        worldRank: getRank(profileData?.profile?.id, worldLeaderboardData?.pass_awareness_1_4?.scores),
      },
      {
        drillId: 'colour-combo',
        drillName: 'Colour Combo',
        drillLogo: <HdrWeakOutlined />,
        bestScore: colour_combo?.profile?.bestScore,
        correct: colour_combo?.profile.maxCorrect,
        incorrect: colour_combo?.profile.maxIncorrect,
        bonus: colour_combo?.profile.maxBonus,
        level: colour_combo?.profile.maxLevel,
        clubRank:  getRank(profileData?.profile?.id, clubLeaderboardData?.colour_combo_warmup?.scores),
        worldRank: getRank(profileData?.profile?.id, worldLeaderboardData?.colour_combo_warmup?.scores),
      },
      {
        drillId: 'shoulder-sums',
        drillName: 'Shoulder Sums',
        drillLogo: <RadarOutlined />,
        bestScore: shoulder_sums?.profile?.maxScore,
        correct: shoulder_sums?.profile.maxCorrect,
        incorrect: shoulder_sums?.profile.maxIncorrect,
        rightFoot: shoulder_sums?.profile.maxRightFoot,
        leftFoot:  shoulder_sums?.profile.maxLeftFoot,
        level: shoulder_sums?.profile.maxLevel,
        clubRank: getRank(profileData?.profile?.id, clubLeaderboardData?.number_pass?.scores),
        worldRank: getRank(profileData?.profile?.id, worldLeaderboardData?.number_pass?.scores),
      },
      {
        drillId: 'head-smart',
        drillName: 'Head Smart',
        drillLogo: <SportsSoccerOutlined />,
        bestScore: head_smart?.profile?.bestScore,
        correct: head_smart?.profile.maxSuccessful,
        incorrect: head_smart?.profile.maxWrong,
        level: head_smart?.profile.maxLevel,
        clubRank: getRank(profileData?.profile?.id, clubLeaderboardData?.headers?.scores),
        worldRank: getRank(profileData?.profile?.id, worldLeaderboardData?.headers?.scores),
      },
      {
        drillId: 'pressure-pass',
        drillName: 'Pressure Pass',
        drillLogo: <SyncAltOutlined />,
        bestScore: pressure_pass?.profile?.maxScore,
        avgDwell: pressure_pass?.profile?.avgDwellTime !== null ? `${pressure_pass?.profile?.avgDwellTime.toFixed(2)}s` : `--` ,
        correct: pressure_pass?.profile.maxCorrect,
        incorrect: pressure_pass?.profile.maxIncorrect,
        rightFoot: pressure_pass?.profile.maxRightFoot,
        leftFoot:  pressure_pass?.profile.maxLeftFoot,
        level: pressure_pass?.profile.maxLevel,
        clubRank: getRank(profileData?.profile?.id, clubLeaderboardData?.blocking?.scores),
        worldRank: getRank(profileData?.profile?.id, worldLeaderboardData?.blocking?.scores),
      },
      {
        drillId: 'wolfy-180',
        drillName: 'Wolfy 180',
        drillLogo: <FilterTiltShiftOutlined />,
        bestScore: wolfy_180?.profile?.maxScore,
        correct: wolfy_180?.profile.maxCorrect,
        incorrect: wolfy_180?.profile.maxIncorrect,
        avgDecision: wolfy_180?.profile?.avgDwellTime !== null ? `${wolfy_180?.profile?.avgDwellTime.toFixed(2)}s` : `--` ,
        clubRank: getRank(profileData?.profile?.id, clubLeaderboardData?.wolfy_180?.scores),
        worldRank: getRank(profileData?.profile?.id, worldLeaderboardData?.wolfy_180?.scores),
      },
      // {
      //   drillId: 'additional-drill',
      //   drillName: 'Additional Drill',
      //   drillLogo: <RepeatOutlined />,
      //   score: '625',
      //   bestScore: '126,000',
      //   topScore: '8.06',
      //   totalBalls: '11,000',
      //   correct: '11,000',
      //   incorrect: '10,080',
      //   avgDwell: '2.64',
      //   rightFoot: '2,200',
      //   leftFoot: '2,100',
      //   level: '11',
      //   clubRank: '1',
      //   worldRank: '1',
      // },
      // {
      //   drillId: 'additional-drill-2',
      //   drillName: 'Additional Drill 2',
      //   drillLogo: <HdrWeakOutlined />,
      //   score: '625',
      //   bestScore: '125,000',
      //   topScore: '8.06',
      //   totalBalls: '12,000',
      //   correct: '12,000',
      //   incorrect: '9,080',
      //   avgDwell: '1.64',
      //   rightFoot: '1,200',
      //   leftFoot: '1,100',
      //   level: '12',
      //   clubRank: '5',
      //   worldRank: '35',
      // },
    ],
  };
  return drillDataObject;
};

export const squadStatsHelper = (avgStats, sessionStats) => {
  return {
    sessionStats: {
      headers: {
        control: {
          drillId: 'control',
          drillName: 'Control',
          scores: sessionStats.headers_control.player,
        },
        shooting: {
          drillId: 'shooting',
          drillName: 'Shooting',
          scores: sessionStats.headers_shooting.player,
        },
        clearing: {
          drillId: 'clearing',
          drillName: 'Clearing',
          scores: sessionStats.headers_clearing.player,
        },
        passing: {
          drillId: 'passing',
          drillName: 'Passing',
          scores: sessionStats.headers_passing.player,
        }
      },
      fieldGeneral: {
        singleDrillLevel: true,
        drillId: 'fieldGeneral',
        drillName: 'Field General',
        scores: sessionStats.field_general.player,
      },
      reactionWall: {
        micro: {
          drillId: 'micro',
          drillName: 'Micro',
          scores: sessionStats.reaction_wall_micro.player,
        },
        60: {
          drillId: '60',
          drillName: '60',
          scores: sessionStats.reaction_wall_60.player,
        },
        180: {
          drillId: '180',
          drillName: '180',
          scores: sessionStats.reaction_wall_180.player,
        },
        360: {
          drillId: '360',
          drillName: '360',
          scores: sessionStats.reaction_wall_360.player,
        }
      },
      hoops: {
        omw: {
          drillId: 'omw',
          drillName: 'OMW',
          scores: sessionStats.hoops_omw.player,
        },
        2020: {
          drillId: '2020',
          drillName: '2020',
          scores: sessionStats.hoops_2020.player,
        },
        gotw: {
          drillId: 'gotw',
          drillName: 'GOTW',
          scores: sessionStats.hoops_gotw.player,
        },
        caution: {
          drillId: 'caution',
          drillName: 'Caution',
          scores: sessionStats.hoops_caution.player,
        },
        partyUp: {
          drillId: 'partyUp',
          drillName: 'Party Up',
          scores: sessionStats.hoops_party_up.player,
        },
      }
    },
    drillStats: {
      headers: {
        control: {
          drillId: 'control',
          drillName: 'Control',
          totalScore: avgStats.headers_control.player.totalScore,
          timePlayed: avgStats.headers_control.player.timePlayed,
          totalBallsLaunched: avgStats.headers_control.player.totalBallsLaunched,
          totalBallsHit: avgStats.headers_control.player.totalBallsHit,
          totalMissed: avgStats.headers_control.player.totalMissed,
          bestStreak: avgStats.headers_control.player.bestStreak,
          avgHeadSpeed: avgStats.headers_control.player.avgHeadSpeed,
          avgAccuracy: avgStats.headers_control.player.avgAccuracy,
          avgStrikePoint: avgStats.headers_control.player.avgStrikePoint,
        },
        shooting: {
          drillId: 'shooting',
          drillName: 'Shooting',
          totalScore: avgStats.headers_shooting.player.totalScore,
          timePlayed: avgStats.headers_shooting.player.timePlayed,
          totalBallsLaunched: avgStats.headers_shooting.player.totalBallsLaunched,
          totalGoalsScored: avgStats.headers_shooting.player.totalGoalsScored,
          totalGoalsSaved: avgStats.headers_shooting.player.totalGoalsSaved,
          totalMissed: avgStats.headers_shooting.player.totalMissed,
          bestStreak: avgStats.headers_shooting.player.bestStreak,
          avgAccuracy: avgStats.headers_shooting.player.avgAccuracy,
          avgStrikePoint: avgStats.headers_shooting.player.avgStrikePoint,
        },
        clearing: {
          drillId: 'clearing',
          drillName: 'Clearing',
          totalScore: avgStats.headers_clearing.player.totalScore,
          timePlayed: avgStats.headers_clearing.player.timePlayed,
          totalBallsLaunched: avgStats.headers_clearing.player.totalBallsLaunched,
          totalBallsCleared: avgStats.headers_clearing.player.totalBallsCleared,
          totalMissed: avgStats.headers_clearing.player.totalMissed,
          bestStreak: avgStats.headers_clearing.player.bestStreak,
          avgHeadSpeed: avgStats.headers_clearing.player.avgHeadSpeed,
          avgAccuracy: avgStats.headers_clearing.player.avgAccuracy,
          avgStrikePoint: avgStats.headers_clearing.player.avgStrikePoint,
        },
        passing: {
          drillId: 'passing',
          drillName: 'Passing',
          totalScore: avgStats.headers_passing.player.totalScore,
          timePlayed: avgStats.headers_passing.player.timePlayed,
          totalBallsLaunched: avgStats.headers_passing.player.totalBallsLaunched,
          totalCorrectPasses: avgStats.headers_passing.player.totalCorrectPasses,
          totalWrongPasses: avgStats.headers_passing.player.totalWrongPasses,
          bestStreak: avgStats.headers_passing.player.bestStreak,
          avgHeadSpeed: avgStats.headers_passing.player.avgHeadSpeed,
          avgAccuracy: avgStats.headers_passing.player.avgAccuracy,
          avgStrikePoint: avgStats.headers_passing.player.avgStrikePoint,
        }
      },
      fieldGeneral: {
        singleDrillLevel: true,
        drillId: 'fieldGeneral',
        drillName: 'Field General',
        totalScore: avgStats.field_general.player.totalScore,
        timePlayed: avgStats.field_general.player.timePlayed,
        totalSnaps: avgStats.field_general.player.totalSnaps,
        totalCaught: avgStats.field_general.player.totalCaught,
        totalPassesComplete: avgStats.field_general.player.totalPassesComplete,
        totalMissed: avgStats.field_general.player.totalMissed,
        bestStreakEver: avgStats.field_general.player.bestStreakEver,
        averageReleaseTime: avgStats.field_general.player.averageReleaseTime,
        overallAverageAccuracy: avgStats.field_general.player.overallAverageAccuracy
      },
      reactionWall: {
        micro: {
          drillId: 'micro',
          drillName: 'Micro',
          bestScore: avgStats.reaction_wall_micro.player.bestScore,
          bestScoreChase: avgStats.reaction_wall_micro.player.bestScoreChase,
          timePlayed: avgStats.reaction_wall_micro.player.timePlayed,
          totalHits: avgStats.reaction_wall_micro.player.totalHits,
          fastestReaction: avgStats.reaction_wall_micro.player.fastestReaction,
          slowestReaction: avgStats.reaction_wall_micro.player.slowestReaction,
          avgReactionTime: avgStats.reaction_wall_micro.player.handBias,
        },
        60: {
          drillId: '60',
          drillName: '60',
          bestScore: avgStats.reaction_wall_60.player.bestScore,
          bestScoreChase: avgStats.reaction_wall_60.player.bestScoreChase,
          timePlayed: avgStats.reaction_wall_60.player.timePlayed,
          totalHits: avgStats.reaction_wall_60.player.totalHits,
          fastestReaction: avgStats.reaction_wall_60.player.fastestReaction,
          slowestReaction: avgStats.reaction_wall_60.player.slowestReaction,
          avgReactionTime: avgStats.reaction_wall_60.player.handBias,
        },
        180: {
          drillId: '180',
          drillName: '180',
          bestScore: avgStats.reaction_wall_180.player.bestScore,
          bestScoreChase: avgStats.reaction_wall_180.player.bestScoreChase,
          timePlayed: avgStats.reaction_wall_180.player.timePlayed,
          totalHits: avgStats.reaction_wall_180.player.totalHits,
          fastestReaction: avgStats.reaction_wall_180.player.fastestReaction,
          slowestReaction: avgStats.reaction_wall_180.player.slowestReaction,
          avgReactionTime: avgStats.reaction_wall_180.player.handBias,
        },
        360: {
          drillId: '360',
          drillName: '360',
          bestScore: avgStats.reaction_wall_360.player.bestScore,
          bestScoreChase: avgStats.reaction_wall_360.player.bestScoreChase,
          timePlayed: avgStats.reaction_wall_360.player.timePlayed,
          totalHits: avgStats.reaction_wall_360.player.totalHits,
          fastestReaction: avgStats.reaction_wall_360.player.fastestReaction,
          slowestReaction: avgStats.reaction_wall_360.player.slowestReaction,
          avgReactionTime: avgStats.reaction_wall_360.player.handBias,
        }
      },
      hoops: {
        omw: {
          drillId: 'omw',
          drillName: 'OMW',
          bestScore: avgStats.hoops_omw.player.bestScore,
          timePlayed: avgStats.hoops_omw.player.timePlayed,
          totalHit: avgStats.hoops_omw.player.totalHit,
          totalMissed: avgStats.hoops_omw.player.totalMissed,
          totalBonusHits: avgStats.hoops_omw.player.totalBonusHits,
          bestStreak: avgStats.hoops_omw.player.bestStreak,
          avgMoveSpeed: avgStats.hoops_omw.player.avgMoveSpeed,
          avgAccuracy: avgStats.hoops_omw.player.avgAccuracy,
        },
        2020: {
          drillId: '2020',
          drillName: '2020',
          timePlayed: avgStats.hoops_2020.player.timePlayed,
          totalHit: avgStats.hoops_2020.player.totalHit,
          totalMissed: avgStats.hoops_2020.player.totalMissed,
          totalBonusHits: avgStats.hoops_2020.player.totalBonusHits,
          bestStreak: avgStats.hoops_2020.player.bestStreak,
          avgMoveSpeed: avgStats.hoops_2020.player.avgMoveSpeed,
          avgAccuracy: avgStats.hoops_2020.player.avgAccuracy,
        },
        gotw: {
          drillId: 'gotw',
          drillName: 'GOTW',
          timePlayed: avgStats.hoops_gotw.player.timePlayed,
          totalHit: avgStats.hoops_gotw.player.totalHit,
          totalMissed: avgStats.hoops_gotw.player.totalMissed,
          totalBonusHits: avgStats.hoops_gotw.player.totalBonusHits,
          bestStreak: avgStats.hoops_gotw.player.bestStreak,
          avgMoveSpeed: avgStats.hoops_gotw.player.avgMoveSpeed,
          avgAccuracy: avgStats.hoops_gotw.player.avgAccuracy,
        },
        caution: {
          drillId: 'caution',
          drillName: 'Caution',
          timePlayed: avgStats.hoops_caution.player.timePlayed,
          totalHit: avgStats.hoops_caution.player.totalHit,
          totalMissed: avgStats.hoops_caution.player.totalMissed,
          totalBonusHits: avgStats.hoops_caution.player.totalBonusHits,
          bestStreak: avgStats.hoops_caution.player.bestStreak,
          avgMoveSpeed: avgStats.hoops_caution.player.avgMoveSpeed,
          avgAccuracy: avgStats.hoops_caution.player.avgAccuracy,
        },
        partyUp: {
          drillId: 'partyUp',
          drillName: 'Party Up',
          timePlayed: avgStats.hoops_party_up.player.timePlayed,
          totalHit: avgStats.hoops_party_up.player.totalHit,
          totalMissed: avgStats.hoops_party_up.player.totalMissed,
          totalBonusHits: avgStats.hoops_party_up.player.totalBonusHits,
          bestStreak: avgStats.hoops_party_up.player.bestStreak,
          avgMoveSpeed: avgStats.hoops_party_up.player.avgMoveSpeed,
          avgAccuracy: avgStats.hoops_party_up.player.avgAccuracy,
        },
      }
    },
    worldDrillStats: {
      headers: {
        control: {
          drillId: 'control',
          drillName: 'Control',
          totalScore: avgStats.headers_control.global.totalScore,
          timePlayed: avgStats.headers_control.global.timePlayed,
          totalBallsLaunched: avgStats.headers_control.global.totalBallsLaunched,
          totalBallsHit: avgStats.headers_control.global.totalBallsHit,
          totalMissed: avgStats.headers_control.global.totalMissed,
          bestStreak: avgStats.headers_control.global.bestStreak,
          avgHeadSpeed: avgStats.headers_control.global.avgHeadSpeed,
          avgAccuracy: avgStats.headers_control.global.avgAccuracy,
          avgStrikePoint: avgStats.headers_control.global.avgStrikePoint,
        },
        shooting: {
          drillId: 'shooting',
          drillName: 'Shooting',
          totalScore: avgStats.headers_shooting.global.totalScore,
          timePlayed: avgStats.headers_shooting.global.timePlayed,
          totalBallsLaunched: avgStats.headers_shooting.global.totalBallsLaunched,
          totalGoalsScored: avgStats.headers_shooting.global.totalGoalsScored,
          totalGoalsSaved: avgStats.headers_shooting.global.totalGoalsSaved,
          totalMissed: avgStats.headers_shooting.global.totalMissed,
          bestStreak: avgStats.headers_shooting.global.bestStreak,
          avgAccuracy: avgStats.headers_shooting.global.avgAccuracy,
          avgStrikePoint: avgStats.headers_shooting.global.avgStrikePoint,
        },
        clearing: {
          drillId: 'clearing',
          drillName: 'Clearing',
          totalScore: avgStats.headers_clearing.global.totalScore,
          timePlayed: avgStats.headers_clearing.global.timePlayed,
          totalBallsLaunched: avgStats.headers_clearing.global.totalBallsLaunched,
          totalBallsCleared: avgStats.headers_clearing.global.totalBallsCleared,
          totalMissed: avgStats.headers_clearing.global.totalMissed,
          bestStreak: avgStats.headers_clearing.global.bestStreak,
          avgHeadSpeed: avgStats.headers_clearing.global.avgHeadSpeed,
          avgAccuracy: avgStats.headers_clearing.global.avgAccuracy,
          avgStrikePoint: avgStats.headers_clearing.global.avgStrikePoint,
        },
        passing: {
          drillId: 'passing',
          drillName: 'Passing',
          totalScore: avgStats.headers_passing.global.totalScore,
          timePlayed: avgStats.headers_passing.global.timePlayed,
          totalBallsLaunched: avgStats.headers_passing.player.totalBallsLaunched,
          totalCorrectPasses: avgStats.headers_passing.global.totalCorrectPasses,
          totalWrongPasses: avgStats.headers_passing.global.totalWrongPasses,
          bestStreak: avgStats.headers_passing.global.bestStreak,
          avgHeadSpeed: avgStats.headers_passing.global.avgHeadSpeed,
          avgAccuracy: avgStats.headers_passing.global.avgAccuracy,
          avgStrikePoint: avgStats.headers_passing.global.avgStrikePoint,
        }
      },
      fieldGeneral: {
        singleDrillLevel: true,
        drillId: 'fieldGeneral',
        drillName: 'Field General',
        totalScore: avgStats.field_general.global.totalScore,
        timePlayed: avgStats.field_general.global.timePlayed,
        totalSnaps: avgStats.field_general.global.totalSnaps,
        totalCaught: avgStats.field_general.global.totalCaught,
        totalPassesComplete: avgStats.field_general.global.totalPassesComplete,
        totalMissed: avgStats.field_general.global.totalMissed,
        bestStreakEver: avgStats.field_general.global.bestStreakEver,
        averageReleaseTime: avgStats.field_general.global.averageReleaseTime,
        overallAverageAccuracy: avgStats.field_general.global.overallAverageAccuracy
      },
      reactionWall: {
        micro: {
          drillId: 'micro',
          drillName: 'Micro',
          bestScore: avgStats.reaction_wall_micro.global.bestScore,
          bestScoreChase: avgStats.reaction_wall_micro.global.bestScoreChase,
          timePlayed: avgStats.reaction_wall_micro.global.timePlayed,
          totalHits: avgStats.reaction_wall_micro.global.totalHits,
          fastestReaction: avgStats.reaction_wall_micro.global.fastestReaction,
          slowestReaction: avgStats.reaction_wall_micro.global.slowestReaction,
          avgReactionTime: avgStats.reaction_wall_micro.global.handBias,
        },
        60: {
          drillId: '60',
          drillName: '60',
          bestScore: avgStats.reaction_wall_60.global.bestScore,
          bestScoreChase: avgStats.reaction_wall_60.global.bestScoreChase,
          timePlayed: avgStats.reaction_wall_60.global.timePlayed,
          totalHits: avgStats.reaction_wall_60.global.totalHits,
          fastestReaction: avgStats.reaction_wall_60.global.fastestReaction,
          slowestReaction: avgStats.reaction_wall_60.player.slowestReaction,
          avgReactionTime: avgStats.reaction_wall_60.global.handBias,
        },
        180: {
          drillId: '180',
          drillName: '180',
          bestScore: avgStats.reaction_wall_180.global.bestScore,
          bestScoreChase: avgStats.reaction_wall_180.global.bestScoreChase,
          timePlayed: avgStats.reaction_wall_180.global.timePlayed,
          totalHits: avgStats.reaction_wall_180.global.totalHits,
          fastestReaction: avgStats.reaction_wall_180.global.fastestReaction,
          slowestReaction: avgStats.reaction_wall_180.global.slowestReaction,
          avgReactionTime: avgStats.reaction_wall_180.global.handBias,
        },
        360: {
          drillId: '360',
          drillName: '360',
          bestScore: avgStats.reaction_wall_360.global.bestScore,
          bestScoreChase: avgStats.reaction_wall_360.global.bestScoreChase,
          timePlayed: avgStats.reaction_wall_360.global.timePlayed,
          totalHits: avgStats.reaction_wall_360.global.totalHits,
          fastestReaction: avgStats.reaction_wall_360.global.fastestReaction,
          slowestReaction: avgStats.reaction_wall_360.global.slowestReaction,
          avgReactionTime: avgStats.reaction_wall_360.global.handBias,
        }
      },
      hoops: {
        omw: {
          drillId: 'omw',
          drillName: 'OMW',
          bestScore: avgStats.hoops_omw.global.bestScore,
          timePlayed: avgStats.hoops_omw.global.timePlayed,
          totalHit: avgStats.hoops_omw.global.totalHit,
          totalMissed: avgStats.hoops_omw.global.totalMissed,
          totalBonusHits: avgStats.hoops_omw.global.totalBonusHits,
          bestStreak: avgStats.hoops_omw.global.bestStreak,
          avgMoveSpeed: avgStats.hoops_omw.global.avgMoveSpeed,
          avgAccuracy: avgStats.hoops_omw.global.avgAccuracy,
        },
        2020: {
          drillId: '2020',
          drillName: '2020',
          timePlayed: avgStats.hoops_2020.global.timePlayed,
          totalHit: avgStats.hoops_2020.global.totalHit,
          totalMissed: avgStats.hoops_2020.global.totalMissed,
          totalBonusHits: avgStats.hoops_2020.global.totalBonusHits,
          bestStreak: avgStats.hoops_2020.global.bestStreak,
          avgMoveSpeed: avgStats.hoops_2020.global.avgMoveSpeed,
          avgAccuracy: avgStats.hoops_2020.global.avgAccuracy,
        },
        gotw: {
          drillId: 'gotw',
          drillName: 'GOTW',
          timePlayed: avgStats.hoops_gotw.global.timePlayed,
          totalHit: avgStats.hoops_gotw.global.totalHit,
          totalMissed: avgStats.hoops_gotw.global.totalMissed,
          totalBonusHits: avgStats.hoops_gotw.global.totalBonusHits,
          bestStreak: avgStats.hoops_gotw.global.bestStreak,
          avgMoveSpeed: avgStats.hoops_gotw.global.avgMoveSpeed,
          avgAccuracy: avgStats.hoops_gotw.global.avgAccuracy,
        },
        caution: {
          drillId: 'caution',
          drillName: 'Caution',
          timePlayed: avgStats.hoops_caution.global.timePlayed,
          totalHit: avgStats.hoops_caution.global.totalHit,
          totalMissed: avgStats.hoops_caution.global.totalMissed,
          totalBonusHits: avgStats.hoops_caution.global.totalBonusHits,
          bestStreak: avgStats.hoops_caution.global.bestStreak,
          avgMoveSpeed: avgStats.hoops_caution.global.avgMoveSpeed,
          avgAccuracy: avgStats.hoops_caution.global.avgAccuracy,
        },
        partyUp: {
          drillId: 'partyUp',
          drillName: 'Party Up',
          timePlayed: avgStats.hoops_party_up.global.timePlayed,
          totalHit: avgStats.hoops_party_up.global.totalHit,
          totalMissed: avgStats.hoops_party_up.global.totalMissed,
          totalBonusHits: avgStats.hoops_party_up.global.totalBonusHits,
          bestStreak: avgStats.hoops_party_up.global.bestStreak,
          avgMoveSpeed: avgStats.hoops_party_up.global.avgMoveSpeed,
          avgAccuracy: avgStats.hoops_party_up.global.avgAccuracy,
        },
      }
    }
  }
};
