import {
  Container,
  StyledSpinner,
} from './SectionSpinner.styles';

const SectionSpinner = () => {
  return (
    <Container>
      <StyledSpinner/>
    </Container>
  );
};

export default SectionSpinner;
