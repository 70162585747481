import moment from 'moment';

const getPrevMonths = (numOfMonths) => {
  // Return an array of abbreviated month strings for the past 6 months (current month first)
  let months = [];
  for (let i = 0; i < numOfMonths; i++) {
    if (i === 0) {
      months.push(moment().format('MMM'));
    } else {
      months.push(moment().subtract(i, 'months').format('MMM'));
    }
  }
  return months.reverse();
};

export default getPrevMonths;
