import {
  styled,
  Link,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledLink = styled(Link)(({ theme, active }) => ({
  ...theme.typography.h5,
  letterSpacing: 1.2,
  textDecoration: 'none',
  textTransform: 'uppercase',
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  minHeight: 45,
  padding: '0 20px',
  borderRadius: 5,
  color: active ? colors.primaryGreen : colors.primaryGrey,
  background: active ? 'transparent linear-gradient(258deg, #FFFFFF00 0%, #FFFFFF1A 100%)' : 'transparent',
  '&:hover': {
    color: colors.primaryGreen,
    '& svg': {
      color: colors.primaryGreen,
    },
  },
  '& svg': {
    marginRight: 20,
    color: active ? colors.primaryGreen : colors.secondaryGrey,
    transition: 'inherit',
  },
}));
