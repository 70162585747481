
import { styled, Typography } from '@mui/material';
import { colors } from '../../../options/colors';

export const InfoBlockContainer = styled('div')(({theme, multiline, nomargin}) => ({
  display: 'flex',
  flex: 1,
  marginTop: nomargin ? 0 : multiline ? 72 : 16,
  borderTop: `3px solid ${colors.rezzilPlayerBlue}`,
  marginLeft: 15,
  paddingTop: 24,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const Icon = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.rezzilPlayerBlue,
  color: colors.white,
  borderRadius: 25,
  height: 50,
  width: 50,
}));

export const InfoText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: colors.primaryGrey,
  marginBottom: 10,
}));

export const InfoTextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 20,
  flex:  1,
}));