import {useEffect, useCallback} from 'react';

// API calls
import {logout} from '../../../api';

const Logout = () => {
  const handleLogout = useCallback(async () => {
    await logout();
    localStorage.clear();
    window.location.href = '/login';
  }, []);

  useEffect(() => {
    handleLogout()
  }, [handleLogout])

  return (
    <></>
  );
};

export default Logout;
