import {
  styled,
  Button,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledButton = styled(Button)(({ theme, success }) => ({
  ...theme.typography.h5,
  padding: '10px 20px',
  backgroundColor: success ? colors.successDarkGreen : colors.primaryRed,
  borderColor: success ? colors.successDarkGreen : colors.primaryRed,
}));
