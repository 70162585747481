import { useEffect, useState, useCallback, createContext, useContext } from 'react';
import isPast from 'date-fns/isPast'
import fromUnixTime from 'date-fns/fromUnixTime'
import {routes} from '../../options/routes';
import {getPaymentMethods, getInvoices} from '../../api';
import {BaseContext} from '../Global/BaseProvider';

const BillingContext = createContext({});

const BillingProvider = ({ children }) => {
  const baseContext = useContext(BaseContext);
  const [billingData, setBillingData] = useState({
    loading: true,
    payments: null,
    invoices: null,
    apiError: false,
  });

  const getBillingData = useCallback(async (clubId) => {
    // Only trigger API call for admin billing route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.adminBilling) return;

    try {
      const paymentMethods = await getPaymentMethods();
      const invoices = await getInvoices(clubId);
      if (!paymentMethods || !paymentMethods.success || !invoices || !invoices.success) {
        setBillingData({
          loading: false,
          payments: null,
          invoices: null,
          apiError: true,
        });
        return;
      }

      const formattedPaymentMethods = paymentMethods.data.map((method) => {
        const expiryDate = new Date(method?.card?.exp_year, method?.card?.exp_month - 1, 0);
        const cardType = method?.card?.funding;
        const cardBrand = method?.card?.brand;
        return {
          id: method.id,
          name: `${cardBrand.charAt(0).toUpperCase() + cardBrand.slice(1)} ****${method?.card?.last4}`,
          expiry: `${method?.card?.exp_month}/${method?.card?.exp_year}`,
          expiryMonth: method?.card?.exp_month,
          expiryYear: method?.card?.exp_year,
          status: isPast(expiryDate) ? 'Expired' : 'Active',
          type: `${cardType.charAt(0).toUpperCase() + cardType.slice(1)} Card`,
        };
      });

      let invoicesArray = [];
      if (typeof invoices.data === 'object') {
        Object.values(invoices.data).forEach((arr) => {
          invoicesArray = invoicesArray.concat(arr.data.filter(invoice => invoice.status !== 'void' && invoice.status !== 'open'));
        });
      } else {
        invoicesArray.concat(invoices.data);
      }

      const formattedInvoices = invoicesArray.map((invoice) => {
        return {
          id: invoice.id,
          date: fromUnixTime(invoice.created),
          docId: invoice.number,
          docType: 'Invoice',
          billedAmount: `£${invoice.total / 100}`,
          link: invoice.invoice_pdf,
        }
      });

      setBillingData({
        loading: false,
        payments: formattedPaymentMethods,
        invoices: formattedInvoices,
        apiError: true,
      });
      } catch (error) {
        setBillingData({
          loading: false,
          payments: null,
          invoices: null,
          apiError: true,
        });
        return;
      }
  }, []);

  useEffect(() => {
    if (!baseContext.baseData.loading) {
      const { id } = baseContext.baseData.clubProfile;
      getBillingData(id);
    }
  }, [getBillingData, baseContext]);

  return (
    <BillingContext.Provider
      value={{
        billingData,
        getBillingData
      }}
    >
      {children}
    </BillingContext.Provider>
  );
}

export {BillingProvider, BillingContext};

export default BillingProvider;
