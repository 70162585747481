import moment from 'moment';

export const formatStandardDateString = (dateString, fullYear) => {
  // Format date string as DD/MM/YY string, or DD/MM/YYYY if showing the full year
  return moment(dateString).format(`${fullYear ? 'DD/MM/YYYY' : 'DD/MM/YY'}`);
};

export const formatNewsDateString = (dateString, fullMonth) => {
  // Format date string as 06 Sept 2021 string; or as 06 September 2021 if showing the full month
  return moment(dateString).format(`${fullMonth ? 'DD MMMM YYYY' : 'DD MMM YYYY'}`);
};

export const formatDateToSQLString = (date, showTime) => {
  // Format date object as SQL date (YYYY-MM-DD) or datetime (YYYY-MM-DD hh:mm:ss) string
  return moment(date).format(`YYYY-MM-DD${showTime ? ' hh:mm:ss' : ''}`);
};
