import {styled,Typography} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({}));

export const ContentContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const SubscriptionDetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  }
}));

export const FormContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
}));

export const Form = styled('form')(() => ({
  width: '100%',
}));

export const StripeInputWrapper = styled('div')(() => ({
  height: 46,
  border: `1px ${colors.secondaryGrey} solid`,
  borderRadius: 5,
  padding: '0 20px',
}));

export const InputWrapper = styled('div')(() => ({
  marginBottom: 20,
}));

export const InputRow = styled('div')(() => ({
  display: 'flex',
}));

export const InputColumn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 3,
}));

export const InputSpacing = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export const FeatureLinesContainer = styled('div')(() => ({
  width: '100%',
}));

export const IconAvatarContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 25,
}));

export const SubscriptionTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  color: colors.white,
  marginLeft: 10,
}));


export const PaymentContainer = styled('div')(() => ({
  display: 'flex',
  marginBottom: 25,
  flexDirection: 'column',
}));

export const PaymentTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 18,
  color: colors.white,
}));

export const PaymentAmount = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 18,
  color: colors.primaryOrange,
}));