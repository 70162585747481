import { useEffect, useState, useCallback, createContext, useContext } from 'react';
import fromUnixTime from 'date-fns/fromUnixTime'
import {BaseContext} from '../Global/BaseProvider';
// API calls
import {getSubscriptions, getProducts} from '../../api';
import { currencyCodeToSymbol } from '../../utils/helpers/currencyHelper';

const ProductsContext = createContext({});

const ProductsProvider = ({ children }) => {
  const baseContext = useContext(BaseContext);
  const [productsData, setProductsData] = useState({
    loading: true,
    indexSubscriptions: [],
    rezzilPlayerSubscriptions: [],
    apiError: false,
  });

  const getProductsData = useCallback(async (clubId) => {
    // Only trigger API call for admin products route
    // Call API
    try {
      const stripeProducts = await getProducts();
      const subscriptionsData = await getSubscriptions(clubId);
      if (!subscriptionsData || !subscriptionsData.success || !subscriptionsData.data ||
        !stripeProducts || !stripeProducts.success || !stripeProducts.data) {
        setProductsData({
          loading: false,
          indexSubscriptions: [],
          rezzilPlayerSubscriptions: [],
          apiError: true,
        });
        return;
      }
      const indexSubscriptionData = [];
      const rezzilPlayerSubscriptionData = [];
      const stripeProductsData = stripeProducts.data;
      const squadProProduct = stripeProductsData.products.find((product) => product.metadata?.LicenseId === 'squadspro');
      const indexProduct = stripeProductsData.products.find((product) => product.metadata?.LicenseId === 'elite');

      const { prices } = stripeProductsData;
      Object.values(subscriptionsData.data).forEach((subscriptionArr) => {
        subscriptionArr.data.forEach((subscription) => {
          if (subscription.status === 'active' && subscription.plan.product === indexProduct.id ) {
            indexSubscriptionData.push({
              id: subscription.id,
              frequency: subscription.plan.interval === 'year' ? 'Annual' : 'Monthly',
              nextAmount: `${currencyCodeToSymbol(subscription.plan.currency)}${subscription.plan.amount / 100}`,
              expires: fromUnixTime(subscription.current_period_end),
              autoRenew: subscription.cancel_at_period_end ? 0 : 1,
              changeTo: prices.find((price) => price.product === subscription.plan.product && price.id !== subscription.plan.id), // Use subscription.plan.product and stripeProducts to find the other priceId
            });
          }
          if (subscription.status === 'active' && subscription.plan.product === squadProProduct.id) {
            rezzilPlayerSubscriptionData.push({
              id: subscription.id,
              frequency: subscription.plan.interval === 'year' ? 'Annual' : 'Monthly',
              nextAmount: `${currencyCodeToSymbol(subscription.plan.currency)}${subscription.plan.amount / 100}`,
              expires: fromUnixTime(subscription.current_period_end),
              autoRenew: subscription.cancel_at_period_end ? 0 : 1,
              changeTo: prices.find((price) => price.product === subscription.plan.product && price.id !== subscription.plan.id), // Use subscription.plan.product and stripeProducts to find the other priceId
            });
          }

        });
      });
      setProductsData({
        loading: false,
        indexSubscriptions: indexSubscriptionData,
        rezzilPlayerSubscriptions: rezzilPlayerSubscriptionData,
        apiError: false,
        products: stripeProductsData,
      });
    } catch (error) {
      setProductsData({
          loading: false,
          subscriptions: null,
          apiError: true,
        });
    }
  }, []);

  useEffect(() => {
    if (!baseContext.baseData.loading) {
      const { id } = baseContext.baseData.clubProfile;
      getProductsData(id);
    }
  }, [getProductsData, baseContext]);

  return (
    <ProductsContext.Provider
      value={{
        productsData,
        getProductsData
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}

export {ProductsProvider, ProductsContext};

export default ProductsProvider;
