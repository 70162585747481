import {styled} from '@mui/material';

export const Container = styled('div')(() => ({
  overflow: 'hidden',
  paddingBottom: '56.25%',
  position: 'relative',
  height: 0,
}));

export const IFrame = styled('iframe')(() => ({
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  position: 'absolute',
}));


