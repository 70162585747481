import {
  StyledCard,
  StyledCardContent,
  StyledCardActions,
} from './ActionCard.styles';
import OutlinedButton from '../../Buttons/OutlinedButton/OutlinedButton';
import SectionSpinner from '../../Loading/SectionSpinner/SectionSpinner';

const ActionCard = ({children, btnText, onClick, loading}) => {
  return (
    <StyledCard>
      <StyledCardContent>
        {children}
      </StyledCardContent>
      <StyledCardActions>
        {
          loading &&
          <SectionSpinner/>
        }
        {
          !loading &&
          <OutlinedButton
            text={btnText}
            onClick={onClick}
          />
        }
      </StyledCardActions>
    </StyledCard>
  );
};

export default ActionCard;
