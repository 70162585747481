import {
  styled,
  Card,
} from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  boxShadow: '0px 20px 50px #00000029',
  width: 360,
  borderRadius: 5,
  backdropFilter: 'blur(20px)',
  position: 'relative',
  '&::before': {
    content: '""',
    width: '100%',
    height: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    background: `
      linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255,255,255,1) 50%, rgba(255, 255, 255, 0) 100%)
    `,
  },
  [theme.breakpoints.down('sm')]: {
    width: '85%',
  }
}));
