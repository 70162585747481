import {
  TitleContainer,
  ToggleLegendContainer,
  ToggleLabelContainer,
  ColorIcon,
  LegendText,
} from './TitleWithLegend.styles';
import SectionTitle from '../../../components/Typography/SectionTitle/SectionTitle';

const TitleWithLegend = ({title, iconColor, labelOne, labelTwo}) => {
  return (
    <TitleContainer>
      <SectionTitle
        text={title}
      />
      <ToggleLegendContainer>
        <ToggleLabelContainer>
          <ColorIcon
            color={iconColor}
          />
          <LegendText>
            {labelOne}
          </LegendText>
        </ToggleLabelContainer>
        {labelTwo && (
          <ToggleLabelContainer>
            <ColorIcon/>
            <LegendText>
              {labelTwo}
            </LegendText>
          </ToggleLabelContainer>
        )}
      </ToggleLegendContainer>
    </TitleContainer>
  );
};

export default TitleWithLegend;
