import {styled} from '@mui/material';
import BgImage from '../../../assets/images/bg-secondary.png';

export const Container = styled('div')(({ theme, admin }) => ({
  minHeight: '100vh',
  background: `url(${BgImage}) no-repeat center / cover`,
  paddingLeft: admin ? 550 : 250,
  backgroundAttachment: 'fixed',
  [theme.breakpoints.down('lg')]: {
    padding: 0,
  },
  '@media print': {
    background: 'none',
  }
}));

export const MainContainer = styled('div')(({ theme }) => ({
  padding: 40,
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    padding: '110px 20px 30px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '90px 20px 30px',
  },
  '@media print': {
    paddingTop: 20,
  }
}));

export const BannerContainer = styled('div')(({ theme, admin }) => ({
  position: 'fixed',
  width: admin ? 'calc(100% - 630px)' : 'calc(100% - 330px)',
  top: 0,
  right: 40,
  zIndex: 50,
  [theme.breakpoints.down('lg')]: {
    width: 'calc(100% - 40px)',
    top: 80,
    right: 20,
  },
  [theme.breakpoints.down('sm')]: {
    top: 60,
  },
}));
