import { useState, useContext, useEffect} from 'react';
import {
  Container,
  AdminLinksContainer,
  ManageLinksContainer,
  HeadingContainer,
} from './AdminNavMenu.styles';
import {adminLinks, adminManageLinks, adminExpandedRoutes} from '../../../options/navLinks';
import Label from '../../Typography/Label/Label';
import AdminNavLink from '../../Links/AdminNavLink/AdminNavLink';

import { BaseContext } from '../../../providers/Global/BaseProvider';
import { canViewManageAdmin, isRezzilSuperAdmin } from '../../../utils/helpers/userRoleHelper';

const AdminNavMenu = ({ activeRoute }) => {
  const baseContext = useContext(BaseContext);
  const [manageLinks, setManageLinks] = useState([]);
  const [showManageAdmin, setShowManageAdmin] = useState(false);
  useEffect(() => {
    if (!baseContext.baseData.loading) {
      if (!isRezzilSuperAdmin(baseContext.baseData.userRole)) {
        setManageLinks(adminManageLinks.filter((link) => link.id !== 'repCompanyManagement'))
      } else {
        const manageRepCompanyId = localStorage.getItem('manageRepCompanyId');
        if (!manageRepCompanyId) {
          // Hide reps list unless managing a rep company...
          setManageLinks(adminManageLinks.filter((link) => link.id !== 'repManagement'))
        } else {
          setManageLinks(adminManageLinks);
        }      }
      setShowManageAdmin(
        canViewManageAdmin(baseContext.baseData.userRole)
      );
    }
  }, [baseContext])
  return (
    <Container
      show={adminExpandedRoutes.includes(activeRoute) ? 'true' : undefined}
    >
      <AdminLinksContainer>
        <HeadingContainer>
          <Label
            text={'Admin'}
          />
        </HeadingContainer>
        {
          adminLinks && adminLinks.map(({icon, name, route, isExternal}) => (
            <AdminNavLink
              key={name}
              icon={icon}
              name={name}
              route={route}
              isExternal={isExternal}
              active={activeRoute === route}
            />
          ))
        }
      </AdminLinksContainer>
      {showManageAdmin &&
        <ManageLinksContainer>
          <HeadingContainer>
            <Label
              text={'Manage'}
            />
          </HeadingContainer>
          {
            manageLinks && manageLinks.map(({ icon, name, route, isExternal }) => (
              <AdminNavLink
                key={name}
                icon={icon}
                name={name}
                route={route}
                isExternal={isExternal}
                active={activeRoute === route}
              />
            ))
          }
        </ManageLinksContainer>
      }
    </Container>
  );
};

export default AdminNavMenu;
