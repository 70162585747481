import {
  Container,
  StyledMarker,
  StyledTitle,
} from './Subtitle.styles';

const Subtitle = ({text}) => {
  return (
    <Container>
      <StyledMarker/>
      <StyledTitle>
        {text}
      </StyledTitle>
    </Container>
  );
};

export default Subtitle;
