import {styled} from '@mui/material';

export const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const ButtonsContainer = styled('div')(() => ({
  display: 'flex',
  marginLeft: 5,
  marginBottom: 15,
  '@media print': {
    display: 'none',
  }
}));

export const ButtonContainer = styled('div')(() => ({
  '&:first-of-type': {
    marginRight: 10,
  },
}));
