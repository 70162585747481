import {
  styled,
  Table,
} from '@mui/material';
import {
  StyledTableBody,
  StyledTableRow,
} from '../CustomTableWrapper/CustomTableWrapper.styles';
import {StyledTableCell} from '../CustomTableHead/CustomTableHead.styles';

export const StyledTable = styled(Table)(() => ({
  '& .MuiTableCell-root': {
    '&:nth-of-type(5)': {
      width: 50,
    },
    '&:last-of-type': {
      width: 50,
    },
  },
}));

export {
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
};

export const InlineFieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& > div:nth-of-type(1)': {
    display: 'inline-block',
    width: '47%',
  },
  '& > div:nth-of-type(2)': {
    display: 'inline-block',
    width: '47%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'unset',
    '& > div': {
      width: '100% !important',
    }
  },
}));

export const FieldContainer = styled('div')(() => ({
  marginBottom: 20,
}));
