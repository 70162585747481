import {
  SupportingText,
  StyledLink,
} from './StandardLink.styles';

const StandardLink = ({supportingText, text, href, onClick, isMailto}) => {
  return (
    <>
      {
        supportingText &&
        <SupportingText>
          {supportingText}
        </SupportingText>
      }
      <StyledLink
        href={href ? href : undefined}
        onClick={onClick ? onClick : undefined}
        target={href ? '_blank' : undefined}
        mailto={isMailto ? 'true' : undefined}
      >
        {text}
      </StyledLink>
    </>
  );
};

export default StandardLink;
