import {
  Container,
  StyledCard,
  RecommendedContainer,
  RecommendedText,
  StyledCardContent,
  StyledCardActions,
  IconAvatarContainer,
  FeatureLinesContainer,
} from './SubscriptionCard.styles';
import IconAvatar from '../../Avatars/IconAvatar/IconAvatar';
import CardTitle from '../../Typography/CardTitle/CardTitle';
import FeatureLine from '../../Typography/FeatureLine/FeatureLine';
import OutlinedButton from '../../Buttons/OutlinedButton/OutlinedButton';
import SolidButton from '../../Buttons/SolidButton/SolidButton';
import StatusPill from '../../Pills/StatusPill/StatusPill';

const SubscriptionCard = ({
  avatarBgColor,
  icon,
  title,
  features,
  isRecommended,
  isExpired,
  isSelected,
  isActive,
  showDowngrade,
  showUpgrade,
  onClick
}) => {

  const getButtonText = () => {
    if (isSelected !== undefined) {
      return isSelected ? 'Selected' : 'Select';
    }
    if (isRecommended) {
      return showUpgrade ? 'Upgrade' : 'Activate'
    }
    return showDowngrade ? 'Downgrade' : showUpgrade ? 'Upgrade' : 'Activate'
  }

  return (
    <Container>
      {
        isRecommended &&
        <RecommendedContainer>
          <RecommendedText>
            Recommended
          </RecommendedText>
        </RecommendedContainer>
      }
      <StyledCard
        recommended={isRecommended ? 'true' : undefined}
      >
        <StyledCardContent>
          <IconAvatarContainer>
            <IconAvatar
              bgColor={avatarBgColor}
              icon={icon}
            />
          </IconAvatarContainer>
          <CardTitle
            text={title}
            isRecommended={isRecommended}
          />
          {
            features && features.map(feature => (
              <FeatureLinesContainer
                key={feature}
              >
                <FeatureLine
                  text={feature}
                  isRecommended={isRecommended}
                />
              </FeatureLinesContainer>
            ))
          }
        </StyledCardContent>
        <StyledCardActions>
          {
            isExpired &&
            <StatusPill
              text={`${title} Expired`}
            />
          }
          {
            isActive &&
            <StatusPill
              text={'Active'}
              isActive
            />
          }
          {
            isSelected && !isExpired && !isActive &&
            <SolidButton
              text={getButtonText()}
              onClick={onClick}
            />
          }
          {
            !isExpired && !isActive && !isSelected &&
            <OutlinedButton
              text={getButtonText()}
              onClick={onClick}
              fullWidth
            />
          }
        </StyledCardActions>
      </StyledCard>
    </Container>
  );
};

export default SubscriptionCard;
