import {
  Container,
} from './ComparisonCard.styles';
import {indexPlayerComparisonStatsOptions, rezzilPlayerPlayerComparisonStatsOptions} from '../../../options/drillStatsOptions';
import SectionContainer from '../../Containers/SectionContainer/SectionContainer';
import StatsList from '../../Tables/StatsList/StatsList';

const ComparisonCard = ({player, isRezzilPlayer, highlightTop}) => {
  return (
    <Container>
      <SectionContainer
        cardTitle={`${player.playerName}`}
      >
        <StatsList
          options={isRezzilPlayer ? rezzilPlayerPlayerComparisonStatsOptions : indexPlayerComparisonStatsOptions}
          data={player}
          unevenColumns
          highlightTop
        />
      </SectionContainer>
    </Container>
  );
};

export default ComparisonCard;
