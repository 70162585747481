import {
  Container,
  StyledFormGroup,
  StyledLabel,
  UncheckedIcon,
  CheckedIcon,
  StyledCheckbox,
} from './CustomCheckboxGroup.styles';
import FieldError from '../FieldError/FieldError';

const CustomCheckboxGroup = ({options, onChange, error}) => {
  return (
    <Container>
      <StyledFormGroup
        error={error ? 'true' : undefined}
      >
        {
          options && options.length > 0 && options.map(({id, checked, label}) => (
            <StyledLabel
              key={id}
              control={
                <StyledCheckbox
                  id={id.toString()}
                  checkedIcon={<CheckedIcon/>}
                  icon={<UncheckedIcon/>}
                  checked={checked}
                  onChange={onChange}
                />
              }
              label={label}
            />
          ))
        }
      </StyledFormGroup>
      {
        error &&
        <FieldError
          error={error}
        />
      }
    </Container>
  );
}

export default CustomCheckboxGroup;
