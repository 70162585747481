import {useState} from 'react';
import {
  Container,
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  DeleteText,
} from './GroupsList.styles';
import {groupsColumns} from '../../../options/tableColumns';
import {buttonTypes} from '../../../options/buttonTypes';
import {tableParentOptions} from '../../../options/tableParentOptions';
import SectionContainer from '../../Containers/SectionContainer/SectionContainer';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import MemoizedTable from '../MemoizedTable/MemoizedTable';
import CustomTableHead from '../CustomTableHead/CustomTableHead';
import IconButton from '../../Buttons/IconButton/IconButton';
import StandardModal from '../../Modals/StandardModal/StandardModal';
import GroupForm from '../../Forms/GroupForm/GroupForm';
import {EditOutlined, DeleteOutlined} from '@mui/icons-material';

// Helpers
import {getComparator, stableSort, handleRequestSort} from '../../../utils/tableSortHelpers/tableSortHelpers';

const GroupsList = ({
  groups,
  handleDelete,
  deleteLoading,
  onFieldChange,
  onSubmit,
  formData,
  formErrors,
  setFormData,
  setFormErrors,
  updateLoading,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState({});
  const [isAdd, setIsAdd] = useState(true);

  const handleFormModalClose = () => {
    // Reset state back to original values
    setFormModalOpen(false);
    setIsAdd(true);
    setFormData({
      id: undefined,
      label: '',
    });
    setFormErrors({
      label: '',
    });
  };

  const handleFormModalOpen = id => {
    if (id) {
      // Find index of item with matching id and pass those details into modal
      const index = groups.findIndex(el => el.id === id);
      setFormData(groups[index]);
      setIsAdd(false);
    }
    setFormModalOpen(true);
  };

  const handleFormModalSubmit = async () => {
    const result = await onSubmit();
    if (result) {
      handleFormModalClose();
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setDeleteGroup({});
  };

  const handleDeleteModalOpen = id => {
    const index = groups.findIndex(el => el.id === id);
    setDeleteGroup(groups[index]);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalSubmit = async () => {
    const result = await handleDelete(deleteGroup.id);
    if (result) {
      handleDeleteModalClose();
    }
  };

  return (
    <Container>
      <SectionContainer
        showBtn
        btnType={buttonTypes.link}
        btnText={'Add group'}
        onClick={() => handleFormModalOpen()}
      >
        <CustomTableWrapper
          noResultsText={(!groups || groups.length === 0) && 'No Groups to display'}
        >
          {
            groups && groups.length > 0 &&
            <MemoizedTable
              list={groups}
              parentComponent={tableParentOptions.groups}
            >
              <CustomTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(e, id) => handleRequestSort(e, id, orderBy, order, setOrder, setOrderBy)}
                columnHeadings={groupsColumns}
              />
              <StyledTableBody>
                {
                  stableSort(groups, getComparator(order, orderBy))
                    .map(({id, label}) => (
                      <StyledTableRow
                        key={id}
                      >
                        <StyledTableCell>
                          {label}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            icon={<EditOutlined/>}
                            onClick={() => handleFormModalOpen(id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            icon={<DeleteOutlined/>}
                            onClick={() => handleDeleteModalOpen(id)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                }
              </StyledTableBody>
            </MemoizedTable>
          }
        </CustomTableWrapper>
      </SectionContainer>
      {
        formModalOpen &&
        <StandardModal
          open={formModalOpen}
          onClose={handleFormModalClose}
          title={`${isAdd ? 'Add' : 'Edit'} Group`}
          btnText={`${isAdd ? 'Add' : 'Update'} Group`}
          onClick={handleFormModalSubmit}
          loading={updateLoading}
        >
          <GroupForm
            formData={formData}
            errors={formErrors}
            onChange={onFieldChange}
          />
        </StandardModal>
      }
      {
        deleteModalOpen &&
        <StandardModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          title={'Delete Group'}
          btnText={'Delete Group'}
          onClick={handleDeleteModalSubmit}
          loading={deleteLoading}
        >
          <DeleteText>
            Are you sure you want to delete {deleteGroup.label}?
            Once confirmed, this action cannot be undone.
          </DeleteText>
        </StandardModal>
      }
    </Container>
  );
};

export default GroupsList;
