import {
  Container,
  ExpiryTitle,
  ExpiryDate,
  TrialNameContainer,
  IconAvatarContainer,
  SubscriptionTitle,
  TrialDetailsContainer,
} from './SubscriptionNonPaymentForm.styles';
import {licenceTypes} from '../../../options/licenceOptions';
import IconAvatar from '../../Avatars/IconAvatar/IconAvatar';
import FeatureLine from '../../Typography/FeatureLine/FeatureLine';
import SolidButton from '../../Buttons/SolidButton/SolidButton';
import SectionSpinner from '../../Loading/SectionSpinner/SectionSpinner';

// Helpers
import {formatNewsDateString} from '../../../utils/helpers/formatDateString';

const SubscriptionNonPaymentForm = ({expiryDate, licence, loading, handleSubmit}) => {
  return (
    <Container>
      {
        licence.title === licenceTypes.trial &&
        <>
          <ExpiryTitle>
            Trial expiry date
          </ExpiryTitle>
          <ExpiryDate>
            {
              expiryDate &&
              formatNewsDateString(expiryDate, true)
            }
            {
              !expiryDate &&
              'N/A'
            }
          </ExpiryDate>
        </>
      }
      <TrialNameContainer>
        <IconAvatarContainer>
          <IconAvatar
            bgColor={licence.avatarBgColor}
            icon={licence.icon}
          />
        </IconAvatarContainer>
        <SubscriptionTitle>
          {licence.title}
        </SubscriptionTitle>
      </TrialNameContainer>
      <TrialDetailsContainer>
        {
          licence.features && licence.features.length > 0 && licence.features.map(feature => (
            <FeatureLine
              key={feature}
              text={feature}
            />
          ))
        }
      </TrialDetailsContainer>
      {
        loading &&
        <SectionSpinner/>
      }
      {
        !loading &&
        <SolidButton
          text={'Activate Subscription'}
          onClick={handleSubmit}
        />
      }
    </Container>
  );
};

export default SubscriptionNonPaymentForm;
