import {
  styled,
  Typography,
} from '@mui/material';

export const Container = styled('div')(() => ({
  marginBottom: 30,
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
}));
