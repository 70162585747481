import {styled, Table} from '@mui/material';

export const StandardTable = styled(Table)(() => {});

export const StyledTeamMembersTable = styled(Table)(() => ({
  '& .MuiTableCell-root': {
    '&:nth-of-type(4)': {
      width: 50,
    },
    '&:last-of-type': {
      width: 50,
    },
  },
}));

export const StyledProfilesTable = styled(Table)(() => ({
  '& .MuiTableCell-root': {
    whiteSpace: 'nowrap',
    '&:nth-of-type(8)': {
      width: 50,
    },
    '&:nth-of-type(9)': {
      width: 50,
    },
    '&:nth-of-type(10)': {
      width: 50,
    },
    '&:last-of-type': {
      width: 50,
    },
  },
}));

export const StyledGroupsTable = styled(Table)(() => ({
  '& .MuiTableCell-root': {
    whiteSpace: 'nowrap',
    '&:nth-of-type(2)': {
      width: 50,
    },
    '&:last-of-type': {
      width: 50,
    },
  },
}));
