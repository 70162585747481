import {useState} from 'react';
import {
  Container,
  HeaderContainer,
  SearchContainer,
  StyledTableContainer,
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  ModalText,
} from './PlayerProfilesList.styles';
import {playerProfilesColumns} from '../../../options/tableColumns';
import {playerPositionOptions, genderOptions} from '../../../options/playerOptions';
import {nationalities} from '../../../options/nationalities';
import {buttonTypes} from '../../../options/buttonTypes';
import {tableParentOptions} from '../../../options/tableParentOptions';
import CustomSearchField from '../../Inputs/CustomSearchField/CustomSearchField';
import SectionContainer from '../../Containers/SectionContainer/SectionContainer';
import OutlinedButton from '../../Buttons/OutlinedButton/OutlinedButton';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import MemoizedTable from '../MemoizedTable/MemoizedTable';
import CustomTableHead from '../CustomTableHead/CustomTableHead';
import IconButton from '../../Buttons/IconButton/IconButton';
import CustomToggle from '../../Inputs/CustomToggle/CustomToggle';
import StandardModal from '../../Modals/StandardModal/StandardModal';
import NestedModal from '../../Modals/NestedModal/NestedModal';
import PlayerProfileForm from '../../Forms/PlayerProfileForm/PlayerProfileForm';
import InvitePlayerForm from '../../Forms/InvitePlayerForm/InvitePlayerForm';
import {EditOutlined, EmailOutlined, DeleteOutlined} from '@mui/icons-material';

// Helpers
import {getComparator, stableSort, handleRequestSort} from '../../../utils/tableSortHelpers/tableSortHelpers';
import {formatStandardDateString} from '../../../utils/helpers/formatDateString';
import {debounce} from 'debounce';
import {getSinglePlayerProfile} from '../../../utils/helpers/playerProfilesHelpers';

// API calls
import {getClubPlayerProfile} from '../../../api';

const PlayerProfilesList = ({
  profiles,
  setExistingProfileGroupIds,
  clubId,
  onSearchChange,
  onSearchClear,
  searchData,
  handleDelete,
  deleteLoading,
  onToggleChange,
  onFieldChange,
  onSubmit,
  formData,
  formErrors,
  setFormData,
  setFormErrors,
  checkboxOptions,
  updateLoading,
  showNestedModal,
  setNestedModalClose,
  sendInviteData,
  setSendInviteData,
  sendInviteErrors,
  setSendInviteErrors,
  onSendInviteFieldChange,
  onSendInviteSubmit,
  sendInviteLoading,
  setError,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [tableExpanded, setTableExpanded] = useState(false);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteProfile, setDeleteProfile] = useState({});
  const [isAdd, setIsAdd] = useState(true);

  const handleTableTriggerClick = () => {
    setTableExpanded(!tableExpanded);
  };

  const handleFormModalClose = () => {
    // Reset state back to original values
    setFormModalOpen(false);
    setIsAdd(true);
    setFormData({
      id: undefined,
      clubId,
      firstname: '',
      lastname: '',
      email: '',
      dob: null,
      profile_role_id: '',
      position: '',
      dominant_foot: '',
      shoe_size_type: '',
      shoe_size: '',
      gender: '',
      nationality: '',
      profile_groups: [],
    });
    setFormErrors({
      firstname: '',
      lastname: '',
      email: '',
      dob: null,
      profile_role_id: '',
      position: '',
      dominant_foot: '',
      shoe_size_type: '',
      shoe_size: '',
      gender: '',
      nationality: '',
      profile_groups: [],
    });
    setExistingProfileGroupIds([]);
  };

  const handleFormModalOpen = async id => {
    if (id) {
      // Trigger API call to get single player profile - will include profile_groups
      const playerProfileData = await getClubPlayerProfile(id);
      if (!playerProfileData || !playerProfileData.success || playerProfileData.code !== 200) {
        setError({
          show: true,
          message: 'Unable to load profile information for that player. Please try again'
        });
        return;
      }

      const {profile, groups} = playerProfileData.data;

      // Set existingProfileGroups data to enable determining which groups to add/delete on save
      const playerProfile = getSinglePlayerProfile(profile, groups);
      setExistingProfileGroupIds([...playerProfile.profile_groups]);
      setFormData(playerProfile);
      setIsAdd(false);
    }
    setFormModalOpen(true);
  };

  const handleFormModalSubmit = async () => {
    const result = await onSubmit();
    if (result) {
      handleFormModalClose();
    }
  };

  const handleInviteModalClose = () => {
    // Reset sendInviteData and close modal
    setSendInviteData({
      id: undefined,
      email: '',
    });
    setSendInviteErrors({
      email: '',
    });
    setInviteModalOpen(false);
  };

  const handleInviteModalOpen = async id => {
    if (id) {
      // Set id in sendInviteData and open modal
      setSendInviteData({
        ...sendInviteData,
        id,
      });

      setInviteModalOpen(true);
    }
  };

  const handleInviteModalSubmit = async () => {
    const result = await onSendInviteSubmit();
    if (result) {
      handleInviteModalClose();
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setDeleteProfile({});
  };

  const handleDeleteModalOpen = id => {
    const index = profiles.findIndex(el => el.id === id);
    setDeleteProfile(profiles[index]);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalSubmit = async () => {
    const result = await handleDelete(deleteProfile.id);
    if (result) {
      handleDeleteModalClose();
    }
  };

  const getPositionLabel = position => {
    // position will be in lowercase and abbreviated - return the matching label (display name)
    const p = playerPositionOptions.find(n => n.value === position);
    return p ? p.label : 'None';
  };

  const getGenderLabel = gender => {
    // gender will be in lowercase and abbreviated - return the matching label (display name)
    const g = genderOptions.find(g => g.value === gender);
    return g ? g.label : 'None';
  };

  const getNationalityLabel = nationality => {
    // nationality will be in lowercase - return the matching label (display name)
    const n = nationalities.find(n => n.value === nationality);
    return n ? n.label : 'None';
  };

  return (
    <Container>
      <HeaderContainer>
        <SearchContainer>
          <CustomSearchField
            id={'profileSearch'}
            onChange={debounce(onSearchChange, 350)}
            onClear={onSearchClear}
            value={searchData.profileSearch}
          />
        </SearchContainer>
        <OutlinedButton
          text={'Create profile'}
          onClick={() => handleFormModalOpen()}
        />
      </HeaderContainer>
      <SectionContainer
        showBtn={profiles && profiles.length > 10}
        btnType={buttonTypes.trigger}
        btnText={tableExpanded ? 'Collapse' : 'Expand'}
        onClick={handleTableTriggerClick}
      >
        <StyledTableContainer
          expanded={tableExpanded ? 'true' : undefined}
          count={profiles && profiles.length ? profiles.length : 0}
        >
          <CustomTableWrapper
            noResultsText={(!profiles || profiles.length === 0) && 'No Player Profiles to display'}
            fullHeight
          >
            {
              profiles && profiles.length > 0 &&
              <MemoizedTable
                list={profiles}
                parentComponent={tableParentOptions.playerProfiles}
              >
                <CustomTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(e, id) => handleRequestSort(e, id, orderBy, order, setOrder, setOrderBy)}
                  columnHeadings={playerProfilesColumns}
                />
                <StyledTableBody>
                  {
                    stableSort(profiles, getComparator(order, orderBy))
                      .map(({
                              id, firstname, lastname, dob, position, shoe_size, gender, nationality, email,leaderboards_active
                            }) => (
                        <StyledTableRow
                          key={id}
                        >
                          <StyledTableCell>
                            {firstname} {lastname}
                          </StyledTableCell>
                          <StyledTableCell>
                            {dob ? formatStandardDateString(dob, true) : '--'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {position ? getPositionLabel(position) : '--'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {shoe_size ? shoe_size : '--'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {gender ? getGenderLabel(gender) : '--'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {nationality ? getNationalityLabel(nationality) : '--'}
                          </StyledTableCell>
                          <StyledTableCell
                            email={'true'}
                          >
                            {email ? email : '--'}
                          </StyledTableCell>
                          <StyledTableCell>
                            <CustomToggle
                              id={'leaderboards_active'}
                              checked={!!Number(leaderboards_active)}
                              onChange={e => onToggleChange(e, id)}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              icon={<EditOutlined/>}
                              onClick={() => handleFormModalOpen(id)}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              disabled={!!email}
                              icon={<EmailOutlined/>}
                              onClick={() => handleInviteModalOpen(id)}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              icon={<DeleteOutlined/>}
                              onClick={() => handleDeleteModalOpen(id)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                  }
                </StyledTableBody>
              </MemoizedTable>
            }
          </CustomTableWrapper>
        </StyledTableContainer>
      </SectionContainer>
      {
        formModalOpen &&
        <NestedModal
          openMain={formModalOpen}
          onMainClose={handleFormModalClose}
          title={`${isAdd ? 'Add' : 'Edit'} Player Profile`}
          btnText={`${isAdd ? 'Add' : 'Update'} Profile`}
          onClick={handleFormModalSubmit}
          loading={updateLoading}
          openNested={showNestedModal}
          setNestedClose={setNestedModalClose}
          shoeSize={formData.shoe_size}
          shoeSizeType={formData.shoe_size_type}
        >
          <PlayerProfileForm
            formData={formData}
            errors={formErrors}
            onChange={onFieldChange}
            checkboxOptions={checkboxOptions}
            setNestedOpen={setNestedModalClose}
          />
        </NestedModal>
      }
      {
        inviteModalOpen &&
        <StandardModal
          open={inviteModalOpen}
          onClose={handleInviteModalClose}
          title={'Invite Player'}
          btnText={'Invite Player'}
          onClick={handleInviteModalSubmit}
          loading={sendInviteLoading}
        >
          <ModalText>
            Please enter a valid email address for this player.
          </ModalText>
          <InvitePlayerForm
            formData={sendInviteData}
            errors={sendInviteErrors}
            onChange={onSendInviteFieldChange}
          />
        </StandardModal>
      }
      {
        deleteModalOpen &&
        <StandardModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          title={'Delete Player Profile'}
          btnText={'Delete Profile'}
          onClick={handleDeleteModalSubmit}
          loading={deleteLoading}
        >
          <ModalText>
            Are you sure you want to delete {deleteProfile.firstname} {deleteProfile.lastname}?
            Once confirmed, this action cannot be undone.
          </ModalText>
        </StandardModal>
      }
    </Container>
  );
};

export default PlayerProfilesList;
