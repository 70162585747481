import {
  styled,
  Typography,
} from "@mui/material";

export const Container = styled('div')(() => ({}));

export const InlineFieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& > div:nth-of-type(1)': {
    display: 'inline-block',
    width: '47%',
  },
  '& > div:nth-of-type(2)': {
    display: 'inline-block',
    width: '47%',
  },
  [theme.breakpoints.between('lg', 1450)]: {
    flexDirection: 'column',
    justifyContent: 'unset',
    '& > div': {
      width: '100% !important',
    },
  },
  [theme.breakpoints.between(760, 'md')]: {
    flexDirection: 'column',
    justifyContent: 'unset',
    '& > div': {
      width: '100% !important',
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'unset',
    '& > div': {
      width: '100% !important',
    }
  },
}));

export const PhoneFieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& > div:nth-of-type(1)': {
    display: 'inline-block',
    width: 125,
    marginRight: 20,
  },
  '& > div:nth-of-type(2)': {
    display: 'inline-block',
    width: '100%',
  },
  [theme.breakpoints.between('lg', 1450)]: {
    flexDirection: 'column',
    justifyContent: 'unset',
    '& > div': {
      width: '100% !important',
    },
  },
  [theme.breakpoints.between(760, 'md')]: {
    flexDirection: 'column',
    justifyContent: 'unset',
    '& > div': {
      width: '100% !important',
    },
  },
}));

export const FieldContainer = styled('div')(() => ({
  marginBottom: 20,
  '&#roleContainer': {
    marginBottom: 10,
  },
}));

export const RoleText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  marginTop: 15,
}));
