import {useState, useEffect} from 'react';
import {
  StyledTable,
  LargeTableBody,
  StyledTableRow,
  StyledTableCell,
} from './LeaderBoardList.styles';
import {indexLeaderboardColumns, rezzilPlayerLeaderboardColumns} from '../../../options/tableColumns';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import CustomTableHead from '../CustomTableHead/CustomTableHead';

// Helpers
import {getComparator, stableSort, handleRequestSort} from '../../../utils/tableSortHelpers/tableSortHelpers';

const LeaderBoardList = ({ data, isGlobalBoards, isRezzilPlayer }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (isRezzilPlayer) {
      setColumns(rezzilPlayerLeaderboardColumns);
      return;
    }
    if (isGlobalBoards) {
      setColumns(indexLeaderboardColumns.filter((column) => !(column.id === 'yob' || column.id === 'worldRank')));
    } else {
      setColumns(indexLeaderboardColumns);
    }

  }, [isRezzilPlayer, isGlobalBoards]);


  return (
    <>
      <CustomTableWrapper
        noResultsText={(!data || data.length === 0) && 'No details to display'}
        fullHeight
      >
        <StyledTable>
          <CustomTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(e, id) => handleRequestSort(e, id, orderBy, order, setOrder, setOrderBy)}
            columnHeadings={columns}
            small
          />
          <LargeTableBody>
            {
              (data.length > 0 && !isRezzilPlayer) &&
              stableSort(data, getComparator(order, orderBy))
                .map(({id, rank, name, yob, topIndex, averageIndex, lastIndex, worldRank, color, showColor}) => (
                  <StyledTableRow
                    key={id}
                    color={showColor ? color : undefined}
                  >
                    <StyledTableCell>
                      {rank}
                    </StyledTableCell>
                    <StyledTableCell>
                      {name}
                    </StyledTableCell>
                    {
                      !isGlobalBoards &&
                      <StyledTableCell>
                        {yob}
                      </StyledTableCell>
                    }
                    <StyledTableCell>
                      {topIndex}
                    </StyledTableCell>
                    <StyledTableCell>
                      {averageIndex}
                    </StyledTableCell>
                    <StyledTableCell>
                      {lastIndex}
                    </StyledTableCell>
                    {
                      !isGlobalBoards &&
                      <StyledTableCell>
                        {worldRank}
                      </StyledTableCell>
                    }
                  </StyledTableRow>
                ))
            }
            {
              (data.length > 0 && isRezzilPlayer) &&
              stableSort(data, getComparator(order, orderBy))
                .map(({ id, rank, userPlatformName, score, showColor, color}) => (
                  <StyledTableRow
                    key={id}
                    color={showColor ? color : undefined}
                  >
                    <StyledTableCell>
                      {rank}
                    </StyledTableCell>
                    <StyledTableCell>
                      {userPlatformName}
                    </StyledTableCell>
                    <StyledTableCell>
                      {score}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            }
          </LargeTableBody>
        </StyledTable>
      </CustomTableWrapper>
    </>
  );
};

export default LeaderBoardList;
