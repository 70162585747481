import {
  StyledCardContent,
  StyledCardActions,
} from '../ActionCard/ActionCard.styles';
import {
  styled,
  Card,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledCard = styled(Card)(() => ({
  padding: 0,
  width: 220,
  background: 'rgba(0, 0, 0, 0.4)',
  borderRadius: 5,
  boxShadow: 'none',
  backdropFilter: 'blur(20px)',
  display: 'flex',
  flexDirection: 'column',
}));

export const ImageContainer = styled('div')(() => ({
  height: 172,
  width: 172,
  borderRadius: 5,
  backgroundColor: colors.white,
  border: `4px solid ${colors.white}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
}));

export const Image = styled('img')(() => ({
  width: '100%',
  objectFit: 'cover',
}));

export {
  StyledCardContent,
  StyledCardActions,
};
