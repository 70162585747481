import {
  Container,
  InlineFieldContainer,
  FieldContainer,
  IdText,
} from './ClubProfileForm.styles';
import {countries} from '../../../options/countries';
import CustomTextField from '../../Inputs/CustomTextField/CustomTextField';
import CustomAutocomplete from '../../Inputs/CustomAutocomplete/CustomAutocomplete';
import Label from '../../Typography/Label/Label';

const ClubProfileForm = ({clubId, formData, errors, onChange}) => {
  return (
    <Container>
      <InlineFieldContainer>
        <FieldContainer>
          <Label
            text={'Club ID'}
          />
          <IdText>
            {clubId}
          </IdText>
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Club name'}
            id={'name'}
            placeholder={'Club FC'}
            onChange={onChange}
            error={errors.name}
            type={'text'}
            value={formData.name}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Address 1'}
            id={'addr1'}
            placeholder={'Address Line 1'}
            required
            onChange={onChange}
            error={errors.addr1}
            type={'text'}
            value={formData.addr1}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Address 2'}
            id={'addr2'}
            placeholder={'Address Line 2'}
            onChange={onChange}
            error={errors.addr2}
            type={'text'}
            value={formData.addr2}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Address 3'}
            id={'addr3'}
            placeholder={'Address Line 3'}
            onChange={onChange}
            error={errors.addr3}
            type={'text'}
            value={formData.addr3}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'City'}
            id={'city'}
            placeholder={'City'}
            required
            onChange={onChange}
            error={errors.city}
            type={'text'}
            value={formData.city}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <InlineFieldContainer>
        <FieldContainer
          id={'noMarginField'}
        >
          <CustomTextField
            label={'Post/Zip Code'}
            id={'postcode'}
            placeholder={'Post/Zip Code'}
            required
            onChange={onChange}
            error={errors.postcode}
            type={'text'}
            value={formData.postcode}
          />
        </FieldContainer>
        <FieldContainer
          id={'noMarginField'}
        >
          <CustomAutocomplete
            label={'Country'}
            id={'country'}
            placeholder={'Select Country'}
            required
            onChange={onChange}
            error={errors.country}
            value={formData.country}
            options={countries}
          />
        </FieldContainer>
      </InlineFieldContainer>
    </Container>
  )
};

export default ClubProfileForm;
