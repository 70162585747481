import {useEffect, useContext, useState} from 'react';
import {
  SectionWrapper,
  ContainerWrapper,
} from './GlobalLeaderBoards.styles';
import SectionSpinner from '../Loading/SectionSpinner/SectionSpinner';
import MainWrapper from '../Wrappers/MainWrapper/MainWrapper';
import Title from '../Typography/Title/Title';
import PlayersDropdownSelects from '../StatsPage/PlayersDropdownSelects/PlayersDropdownSelects';
import SectionContainer from '../Containers/SectionContainer/SectionContainer';
import LeaderBoardList from '../Tables/LeaderBoardList/LeaderBoardList';

// Contexts & Helpers
import { BaseContext } from '../../providers/Global/BaseProvider';
import { GlobalLeaderBoardsContext } from '../../providers/RezzilPlayer/GlobalLeaderBoardsProvider';
import {createColorHex} from '../../utils/helpers/createColorHex';
import { getSquadLeaderBoardData } from '../../utils/helpers/leaderboardsHelpers';
import {getPlayerChoices} from '../../utils/helpers/playerChoicesHelpers';
import duplicateExists from '../../utils/helpers/duplicateExists';

// API Calls
import { routes } from '../../options/routes';
import { capitaliseUserRole } from '../../utils/helpers/userRoleHelper';
import { squadLeaderboardTableNames } from '../../options/squadLeaderboards';

const GlobalLeaderBoardsPage = () => {
  // Dynamically set orientation based on breakpoint
  const baseContext = useContext(BaseContext);
  const globalLeaderBoardsContext = useContext(GlobalLeaderBoardsContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [playerDataLoading, setPlayerDataLoading] = useState(false);
  const [playerDropdownOptions, setPlayerDropdownOptions] = useState([]);
  const [playerChoices, setPlayerChoices] = useState([]);
  const [globalLeaderBoardStats, setGlobalLeaderBoardStats] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: '',
  });

  const fetchLeaderboards = async (existingLeaderboards = [], leaderboardsToFetch, playerChoicesIds) => {
    const newLeaderBoards = [];
    for (let i = 0; i < leaderboardsToFetch.length; i++) {
      const leaderboardData = await getSquadLeaderBoardData({ playerChoicesIds, leaderboardName: leaderboardsToFetch[i]});
      newLeaderBoards.push(leaderboardData);
    }

    const updatedLeaderboards = existingLeaderboards.concat(newLeaderBoards);
    setGlobalLeaderBoardStats(updatedLeaderboards);
  }

  useEffect(() => {
    const { allLoaded, squadMembers, leaderBoards, numberOfTablesPreLoaded } = globalLeaderBoardsContext.globalLeaderBoardsData;
    if (allLoaded) {
      setDataLoading(false);
      // Set options for player dropdowns
      let options = [];
      if (squadMembers && squadMembers.length > 0) {
        squadMembers.forEach(squadMember => {
          const playerName = squadMember.name;
          const option = {
            value: squadMember.id,
            label: playerName,
            color: createColorHex(playerName),
          };
          options.push(option);
        });
      }
      setPlayerDropdownOptions(options);
      const playerChoicesIds = [squadMembers.length ? squadMembers[0].id : undefined];
      setPlayerChoices(playerChoicesIds);

      // Set leaderboard data
      setGlobalLeaderBoardStats(leaderBoards || []);

      // Now to fetch the additional leaderboards
      fetchLeaderboards(leaderBoards, squadLeaderboardTableNames.slice(numberOfTablesPreLoaded), playerChoicesIds);
    }
  }, [globalLeaderBoardsContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handlePlayerChange = idx => async e => {
    // Remove any displayed banner errors
    handleBannerClose();

    const {id} = baseContext.baseData.userProfile;
    const rows = getPlayerChoices(playerChoices, idx, e.target.value, id);
    // Ensure duplicates do not exist
    if (duplicateExists(rows)) {
      setError({
        show: true,
        message: 'That player has already bseen selected',
      });
      return;
    }
    setPlayerChoices(rows);
    setPlayerDataLoading(true);
    const newLeaderBoards = [];
    for (let i = 0; i < squadLeaderboardTableNames.length; i++) {
      const leaderboardData = await getSquadLeaderBoardData({
        playerChoicesIds: rows,
        leaderboardName: squadLeaderboardTableNames[i],
        leaderboardData: globalLeaderBoardStats.find((stat) => stat.name === squadLeaderboardTableNames[i])
      });
      newLeaderBoards.push(leaderboardData);
    }
    // const updatedLeaderboards = existingLeaderboards.concat(newLeaderBoards);
    setGlobalLeaderBoardStats(newLeaderBoards);
    setPlayerDataLoading(false);
  };

  const handlePlayerAdd = () => {
    if (!playerChoices.at(-1)) return;
    const row = '';
    setPlayerChoices([...playerChoices, row]);
  };

  const handlePlayerReset = async () => {
    // Reset options and set the logged in player as the first choice
    const newLeaderBoards = [];
    const playerChoicesIds = [Number(playerDropdownOptions[0]?.value)];
    setPlayerChoices(playerChoicesIds);

    // TODO: Get player stats for the logged in player
    setPlayerDataLoading(true);
    for (let i = 0; i < squadLeaderboardTableNames.length; i++) {
      const leaderboardData = await getSquadLeaderBoardData({
        playerChoicesIds,
        leaderboardName: squadLeaderboardTableNames[i],
        leaderboardData: globalLeaderBoardStats.find((stat) => stat.name === squadLeaderboardTableNames[i])
      });
      newLeaderBoards.push(leaderboardData);
    }
    setGlobalLeaderBoardStats(newLeaderBoards);
    setPlayerDataLoading(false);
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.rezzilPlayerGlobalLeaderBoards}
          clubLogo={baseContext.baseData.clubProfile.logo}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
          dataLoading={dataLoading}
        >
          <Title
            text={'Global Leader Boards'}
          />
          <PlayersDropdownSelects
            choices={playerChoices}
            dropdownOptions={playerDropdownOptions}
            handleChange={handlePlayerChange}
            handleAdd={handlePlayerAdd}
            handleReset={handlePlayerReset}
          />
          {
            playerDataLoading &&
            <SectionSpinner/>
          }
          {
            !playerDataLoading &&
            <SectionWrapper>
              {
                globalLeaderBoardStats.length > 0 && globalLeaderBoardStats.map(({name, scores}) => (
                  <ContainerWrapper
                    key={name}
                    datalength={globalLeaderBoardStats.length}
                  >
                    <SectionContainer
                      sectionTitle={capitaliseUserRole(name.replaceAll('-', ' '))}
                    >
                      <LeaderBoardList
                        data={scores}
                        isRezzilPlayer
                      />
                    </SectionContainer>
                  </ContainerWrapper>
                ))
              }
            </SectionWrapper>
          }
        </MainWrapper>
      }
    </>
  );
};

export default GlobalLeaderBoardsPage;
