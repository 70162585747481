import {styled, Typography} from '@mui/material';
import {colors} from '../../../options/colors';
import {buttonTypes} from '../../../options/buttonTypes';


export const SectionTitleContainer = styled('div')(({ hasbtn, hasdescription, hasinlinedescription, displayForTV }) => ({
  display: 'flex',
  justifyContent: hasinlinedescription ? 'flex-start' : 'space-between',
  alignItems: 'center',
  marginBottom: hasbtn ? 15 : undefined,
  '& > div': {
    marginBottom: hasdescription ? 9 : hasbtn ? 0 : undefined,
  },
  paddingLeft: displayForTV ? 20 : 'inherit',
}));

export const ButtonContainer = styled('div')(({ type }) => ({
  marginTop: type === buttonTypes.trigger ? 20 : 15,
  textAlign: type === buttonTypes.trigger ? 'center' : undefined,
}));

export const CaptionContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: 20,
}));

export const CaptionText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: colors.primaryGrey,
  marginRight: 5,
}));

export const DescriptionText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 13,
  color: colors.primaryGrey,
  marginBottom: 15,
}));

export const InlineDescriptionText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontSize: 18,
  color: colors.white,
  marginLeft: 20,
  marginBottom: 9,
}));
