import {styled} from '@mui/material';

export const Container = styled('div')(({ theme, show }) => ({
  display: show ? undefined : 'none',
  width: 300,
  height: '100vh',
  position: 'fixed',
  left: 250,
  top: 0,
  overflowY: 'auto',
  background: 'rgba(255, 255, 255, 0.2)',
  backdropFilter: 'blur(20px)',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const AdminLinksContainer = styled('div')(() => ({
  padding: '40px 30px',
}));

export const ManageLinksContainer = styled('div')(() => ({
  padding: '0 30px 10px',
}));

export const HeadingContainer = styled('div')(() => ({
  marginBottom: 13,
}));
