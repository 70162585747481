import { styled } from '@mui/material';

export const StyledSectionContainer = styled('div')(() => ({
  marginBottom: 40,
  '&:last-of-type': {
    marginBottom: 0,
  },
}));



export const SelectContainer = styled('div')(() => ({
  width: 295,
}));

export const BarChartContainer = styled('div')(() => ({}));
