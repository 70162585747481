import {useEffect, useContext, useState} from 'react';
import {
  SearchContainer,
} from './RepManagement.styles';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import SectionTitle from '../../../components/Typography/SectionTitle/SectionTitle';
import CustomSearchField from '../../../components/Inputs/CustomSearchField/CustomSearchField';
import RepsList from '../../../components/Tables/RepsList/RepsList';

// Contexts
import { BaseContext } from '../../../providers/Global/BaseProvider';
import { RepsContext } from '../../../providers/Admin/RepsProvider';

// Validators & helpers
import emailIsValid from '../../../utils/validation/email';
import phoneNumberIsValid from '../../../utils/validation/phoneNumber';
import {debounce} from 'debounce';

// API Calls
import { addRep, deleteRep, updateRep } from '../../../api';
import { routes } from '../../../options/routes';

const RepManagement = () => {
  const baseContext = useContext(BaseContext);
  const repsContext = useContext(RepsContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [repLoading, setRepLoading] = useState(false);
  const [repsList, setRepsList] = useState([]);
  const [clubId, setClubId] = useState();
  const [error, setError] = useState({
    show: false,
    message: '',
  });
  const [searchData, setSearchData] = useState({
    repSearch: '',
  });
  const [searchResults, setSearchResults] = useState([]);
  const manageRepCompanyId = localStorage.getItem('manageRepCompanyId');
  const manageRepCompanyName = localStorage.getItem('manageRepCompanyName');

  const emptyState = {
    firstname: '',
    lastname: '',
    email: '',
    role: 'REP_TEAM_MEMBER',
    telephoneCountryCode: '',
    telephone: '',
    location: '',
  };

  const emptyErrors = {
    firstname: '',
    lastname: '',
    email: '',
    role: '',
    telephoneCountryCode: '',
    telephone: '',
    location: '',
  };

  const [repData, setRepData] = useState(emptyState);
  const [repErrors, setRepErrors] = useState(emptyErrors);

  useEffect(() => {
    if (!baseContext.baseData.loading && !repsContext.repsData.loading) {
      setClubId(manageRepCompanyId ? manageRepCompanyId : baseContext.baseData.clubProfile.id);
      setDataLoading(false);
      setRepsList(repsContext.repsData.reps);
    }
  }, [baseContext, repsContext, manageRepCompanyId]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handleSearchChange = e => {
    let data = {...searchData};
    data[e.target.id] = e.target.value;
    setSearchData(data);

    // Ensure all data is in lowercase for comparison logic to work correctly
    handleSearchSubmit(data.repSearch.toLowerCase());
  };

  const handleSearchSubmit = searchTerm => {
    // Filter results with name, email, phone or location matching searchTerm
    const results = repsList.filter(
      rep =>
        rep.name.toLowerCase()?.includes(searchTerm) ||
        rep.email.toLowerCase()?.includes(searchTerm) ||
        rep.telephone?.includes(searchTerm) ||
        rep.location.toLowerCase()?.includes(searchTerm)
    );
    setSearchResults(results);
  };

  const handleSearchClear = () => {
    setSearchData({
      repSearch: '',
    });
    setSearchResults([]);
  };

  const handleRepDelete = async id => {
    setDeleteLoading(true);
    handleBannerClose();

    // Trigger API call
    try {
      const deleteRepCall = await deleteRep(id);
      if (!deleteRepCall || !deleteRepCall.success || deleteRepCall.code !== 200) {
        setError({
          show: true,
          message: 'There was an error with the request. Please try again.'}
        );
        return;
      }
    await repsContext.getRepsData(clubId);
    } catch (error) {
      setError({
        show: true,
        message: 'There was an error with the request. Please try again.'}
      );
      setDeleteLoading(false);
      return;
    }
    setError({
      ...error,
      message: 'Rep has been successfully deleted',
    });
  };

  const handleRepChange = (e, id, val) => {
    // Clear errors
    setRepErrors(emptyErrors);
    handleBannerClose();

    // Update repData
    let data = {...repData};
    if (e && id) {
      // For autocomplete field
      data[id] = val;
    } else {
      data[e.target.id] = e.target.value;
    }
    setRepData(data);
  };

  const handleRepSubmit = async () => {
    await setRepLoading(true);
    // Set form errors
    let formErrors = {...repErrors};
    let hasErrors = false;
    if(!repData.firstname) {
      formErrors = {
        ...formErrors,
        firstname: 'Name is required'
      };
      hasErrors = true;
    }
    if(!repData.lastname) {
      formErrors = {
        ...formErrors,
        lastName: 'Name is required'
      };
      hasErrors = true;
    }
    if(!emailIsValid(repData.email)) {
      formErrors = {
        ...formErrors,
        email: 'A valid Contact email is required'
      };
      hasErrors = true;
    }
    if(!repData.telephoneCountryCode) {
      formErrors = {
        ...formErrors,
        telephoneCountryCode: 'Country Code is required'
      };
      hasErrors = true;
    }
    if(!phoneNumberIsValid(repData.telephone)) {
      formErrors = {
        ...formErrors,
        telephone: 'A valid Phone number is required'
      };
      hasErrors = true;
    }
    if(!repData.location) {
      formErrors = {
        ...formErrors,
        location: 'Location is required'
      };
      hasErrors = true;
    }

    if (!repData.role) {
      formErrors = {
        ...formErrors,
        role: 'Role is required'
      };
      hasErrors = true;
    }

    // Set errors and return if there are errors
    setRepErrors(formErrors);
    if (hasErrors) {
      setRepLoading(false);
      return false;
    }

    try {
      const saveRepCal = repData.id ? await updateRep(repData.id, { ...repData, repCoId: clubId }) : await addRep({ ...repData, repCoId: clubId });

      if (!saveRepCal || !saveRepCal.success || saveRepCal.code !== 200) {
        setRepLoading(false);
        setRepErrors({
          saveError: 'There was an error with the request. Please check email and phone number are unique.',
        });
        return;
      }
      await repsContext.getRepsData(clubId);

      setError({
        ...error,
        message: `Rep has been successfully ${repData.id ? 'updated' : 'added'}`,
      });

      setRepData(emptyState);
      setRepLoading(false);
    } catch (error) {
      setError({
        show: true,
        message: 'There was an error with the request. Please check phone number is unique'
      }
      );
    }
    // Trigger API call - update if id or create if no id

    return true;
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.repManagement}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <Title
            text={'Rep Management'}
          />
          <SectionTitle
            text={manageRepCompanyId ? `Managing Reps for "${manageRepCompanyName}"` : 'Reps'}
          />
          <SearchContainer>
            <CustomSearchField
              id={'repSearch'}
              onChange={debounce(handleSearchChange, 350)}
              onClear={handleSearchClear}
              value={searchData.repSearch}
            />
          </SearchContainer>
          <RepsList
            reps={searchData.repSearch ? searchResults : repsList}
            onFieldChange={handleRepChange}
            onSubmit={handleRepSubmit}
            formData={repData}
            formErrors={repErrors}
            setFormData={setRepData}
            setFormErrors={setRepErrors}
            updateLoading={repLoading}
            handleDelete={handleRepDelete}
            deleteLoading={deleteLoading}
          />
        </MainWrapper>
      }
    </>
  );
};

export default RepManagement;
