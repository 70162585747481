import {useState} from 'react';
import {
  StyledTable,
  LargeTableBody,
  StyledTableRow,
  StyledTableCell,
  StyledTableContainer,
  FieldContainer,
  LinkButtonContainer,
} from './SquadPlayersList.styles';
import { squadPlayerColumns } from '../../../options/tableColumns';
import SectionContainer from '../../Containers/SectionContainer/SectionContainer';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import CustomTableHead from '../CustomTableHead/CustomTableHead';
import IconButton from '../../Buttons/IconButton/IconButton';
import DeleteText from '../../Typography/DeleteText/DeleteText';
import {DeleteOutlined} from '@mui/icons-material';
import StandardModal from '../../Modals/StandardModal/StandardModal';
import LinkButton from '../../Buttons/LinkButton/LinkButton';
import CustomTextField from '../../Inputs/CustomTextField/CustomTextField';
import {buttonTypes} from '../../../options/buttonTypes';

// Helpers
import {getComparator, stableSort, handleRequestSort} from '../../../utils/tableSortHelpers/tableSortHelpers';
import { formatStandardDateString } from '../../../utils/helpers/formatDateString';
import { addSquadMember } from '../../../api';

const SquadPlayersList = ({data, handlePlayerDelete, clubId, onPlayerSubmit, showAddButton}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [deletePlayer, setDeletePlayer] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addPlayerModalOpen, setAddPlayerModalOpen] = useState(false);
  const [newPlayerName, setNewPlayerName] = useState('');
  const [newPlayerNameError, setNewPlayerNameError] = useState('');
  const [squadGroupName, setSquadGroupName] = useState('');
  const [squadGroupNameError, setSquadGroupNameError] = useState('');
  const [tableExpanded, setTableExpanded] = useState(false);

  const handleTableTriggerClick = () => {
    setTableExpanded(!tableExpanded);
  };

    const handleDeleteModalOpen = id => {
    const index = data.findIndex(el => el.id === id);
    setDeletePlayer(data[index]);
    setDeleteModalOpen(true);
    };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setDeletePlayer({});
  };

  const handleAddPlayerModalClose = () => {
    setNewPlayerName('');
    setNewPlayerNameError('');
    setSquadGroupName('');
    setSquadGroupNameError('');
    setAddPlayerModalOpen(false);
  }

  const handleDeleteModalSubmit = async () => {
    const result = await handlePlayerDelete(deletePlayer.id);
    if (result) {
      handleDeleteModalClose();
    }
  };

  const handleAddPlayerModalSubmit = async () => {
    if (!newPlayerName) {
      setNewPlayerNameError('Name Required');
    }
    // if (!squadGroupName) {
    //   setSquadGroupNameError('Squad Group Required');
    // }
    if (!newPlayerName) {
      return;
    }
    await addSquadMember({ clubId, name: newPlayerName });
    onPlayerSubmit();
    handleAddPlayerModalClose();
  };

  const tableCount = data && data.length ? data.length : 0;

  return (
    <>
      <SectionContainer
        showBtn={data && data.length > 10}
        btnType={buttonTypes.trigger}
        btnText={tableExpanded ? 'Collapse' : 'Expand'}
        onClick={handleTableTriggerClick}
      >
        <StyledTableContainer
          expanded={tableExpanded ? 'true' : undefined}
          count={tableCount}
        >
          <CustomTableWrapper
            noResultsText={(!data || data.length === 0) && 'No details to display'}
            fullHeight
          >
            <StyledTable>
              <CustomTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(e, id) => handleRequestSort(e, id, orderBy, order, setOrder, setOrderBy)}
                columnHeadings={squadPlayerColumns}
                small
              />
              <LargeTableBody>
                {
                  data.length > 0 &&
                  stableSort(data, getComparator(order, orderBy))
                    .map(({id, name, created}) => (
                      <StyledTableRow
                        key={id}
                      >
                        <StyledTableCell>
                          {name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatStandardDateString(created)}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            icon={<DeleteOutlined/>}
                            onClick={() => handleDeleteModalOpen(id)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                }
              </LargeTableBody>
            </StyledTable>

          </CustomTableWrapper>
        </StyledTableContainer>
        {
          showAddButton &&
        <LinkButtonContainer>
          <LinkButton
          text={'Add Player'}
          onClick={() => setAddPlayerModalOpen(true)}
          isAdd
          />
        </LinkButtonContainer>
        }


        </SectionContainer>
      {
        deleteModalOpen &&
        <StandardModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          title={'Delete Squad Player'}
          btnText={'Delete Player'}
          onClick={handleDeleteModalSubmit}
        >
          <DeleteText text={`Are you sure you want to delete ${deletePlayer?.name}? Once confirmed, this action cannot be undone.`} />
        </StandardModal>
      }
      {
        addPlayerModalOpen &&
        <StandardModal
          open={addPlayerModalOpen}
          onClose={handleAddPlayerModalClose}
          title={'Add Squad Player'}
          btnText={'Add Player'}
          onClick={handleAddPlayerModalSubmit}
        >
          <FieldContainer>
            <CustomTextField
              label={'Name'}
              id={'name'}
              placeholder={'Player Name'}
              required
              onChange={(e) => setNewPlayerName(e.target.value)}
              error={newPlayerNameError}
              type={'text'}
              value={newPlayerName}
            />
            </FieldContainer>
            <FieldContainer>
              <CustomTextField
              label={'Squad Group'}
              id={'squadGroup'}
              placeholder={'Squad Group'}
              // required
              onChange={(e) => setSquadGroupName(e.target.value)}
              error={squadGroupNameError}
              type={'text'}
              value={squadGroupName}
              />
            </FieldContainer>
      </StandardModal>
      }
    </>
  );
};

export default SquadPlayersList;
