import {
  styled,
  Button,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledButton = styled(Button)(({ theme}) => ({
  ...theme.typography.h5,
  borderColor: colors.primaryOrange,
  padding: '7px 20px',
  '.MuiButton-startIcon': {
    transition: 'inherit',
    color: colors.primaryOrange,
  },
}));
