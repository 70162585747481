import {
  styled,
  Autocomplete,
  TextField,
} from '@mui/material';
import {colors} from '../../../options/colors';
import {KeyboardArrowDown} from '@mui/icons-material';

export const Container = styled('div')(() => ({
  width: '100%',
}));

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  paddingRight: 0,
}));

export const StyledTextField = styled(TextField)(({ theme, error }) => ({
  '.MuiOutlinedInput-root': {
    ...theme.typography.body1,
    padding: '0 !important',
    borderRadius: 5,
    overflow: 'hidden',
    '.MuiOutlinedInput-input': {
      padding: '15px 50px 15px 20px',
      background: error ? colors.menuPrimaryBlack : 'transparent',
      '&::placeholder': {
        color: colors.secondaryGrey,
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      padding: 0,
      borderColor: error ? `${colors.primaryRed} !important` : colors.secondaryGrey,
    },
    '& .MuiAutocomplete-endAdornment': {
      background: 'transparent',
      position: 'absolute',
      right: 20,
      '& .MuiAutocomplete-clearIndicator': {
        color: colors.secondaryGrey,
      },
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-input': {
        background: 'rgba(255, 255, 255, 0.2)'
      },
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.2)',
        borderWidth: 1,
      },
      '&:hover': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(255, 255, 255, 0.2)',
          borderWidth: 1,
        },
      }
    },
    '&:hover': {
      '.MuiOutlinedInput-notchedOutline': {
        padding: 0,
        borderColor: error ? colors.primaryRed : colors.secondaryGrey,
      },
    },
  },
}));

export const DropdownIcon = styled(KeyboardArrowDown)(() => ({
  color: colors.secondaryGrey,
}));
