import {
  SpinnerContainer,
  StyledSpinner,
} from './PageSpinner.styles';

const PageSpinner = () => {
  return (
    <SpinnerContainer>
      <StyledSpinner />
    </SpinnerContainer>
  );
};

export default PageSpinner;
