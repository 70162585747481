export const getSinglePlayerProfile = (profile, groups) => {
  // Create data for single player profile in player profiles screen
  // Return null if no data
  if (!profile) return null;

  // Add group ids to profile_groups if player has any groups
  let profile_groups = [];
  groups && groups.length > 0 && groups.forEach(group => profile_groups.push(group.id));

  // Return data in form of object with the required data structure
  return {
    id: profile.id,
    firstname: profile.firstname,
    lastname: profile.lastname,
    email: profile.email && profile.email !== 'null' && profile.email !== 'none' ? profile.email : '',
    dob: profile.dob ? new Date(profile.dob) : '',
    gender: profile.gender ? profile.gender : '',
    position: profile.position ? profile.position : '',
    dominant_foot: profile.dominant_foot ? profile.dominant_foot : '',
    shoe_size_type: profile.shoe_size_type ? profile.shoe_size_type : '',
    shoe_size: profile.shoe_size ? profile.shoe_size : '',
    nationality: profile.nationality ? profile.nationality : '',
    profile_role_id: profile.profile_role_id ? profile.profile_role_id : '',
    leaderboards_active: profile.leaderboards_active ? profile.leaderboards_active : '0',
    profile_groups,
  };
};

export const getPlayerProfiles = profiles => {
  // Create data for all club player profiles in player profiles screen
  // Return null if no data
  if (profiles.length === 0) return null;

  // Return data in form of array of objects with the required data structure
  let playerProfiles = [];
  profiles.forEach(profile => {
    const playerProfile = getSinglePlayerProfile(profile);
    playerProfiles.push(playerProfile);
  });

  return playerProfiles.length > 0 ? playerProfiles : null;
};

export const getSingleGroup = group => {
  // Create data for single player profile in player profiles screen
  // Return null if no data
  if (!group) return null;

  // Return data in form of object with the required data structure
  return {
    id: group.id,
    label: group.label ? group.label : '',
  }
};

export const getGroups = groups => {
  // Create data for all club groups in player profiles screen
  // Return null if no data
  if (groups.length === 0) return null;

  // Return data in form of array of objects with the required data structure
  let clubGroups = [];
  groups.forEach(group => {
    const singleGroup = getSingleGroup(group);
    clubGroups.push(singleGroup);
  });

  return clubGroups.length > 0 ? clubGroups : null;
};
