import {
  styled,
  Typography,
} from '@mui/material';
import {
  StyledTableBody,
  StyledTableRow,
} from '../CustomTableWrapper/CustomTableWrapper.styles';
import {StyledTableCell} from '../CustomTableHead/CustomTableHead.styles';

export const Container = styled('div')(() => ({
  marginBottom: 40,
}));

export const DeleteText = styled(Typography)(({ theme }) =>({
  ...theme.typography.body1,
}));

export {
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
}
