import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({
  marginBottom: 20,
  display: 'flex',
  alignItems: 'center',
}));

export const StyledMarker = styled('div')(() => ({
  height: 16,
  width: 5,
  borderRadius: 5,
  marginRight: 10,
  backgroundColor: colors.markerRed,
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  transform: 'translateY(1px)',
}));
