import {
  styled,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';

export const StyledCard = styled(Card)(() => ({
  padding: 0,
  background: 'rgba(0, 0, 0, 0.4)',
  borderRadius: 5,
  boxShadow: 'none',
  backdropFilter: 'blur(20px)',
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledCardContent = styled(CardContent)(() => ({
  padding: 20,
  marginBottom: 'auto',
}));

export const StyledCardActions = styled(CardActions)(() => ({
  padding: '17px 20px',
  borderTop: `1px solid rgba(113, 113, 113, 0.5)`,
}));
