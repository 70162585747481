import {useState} from 'react';
import {
  Container,
  StyledTextField,
} from './CustomTextField.styles';
import Label from '../../Typography/Label/Label';
import FieldError from '../FieldError/FieldError';

const CustomTextField = ({label, id, placeholder, required, onChange, onBlur, error, type, value, multiline}) => {
  const [fieldVal, setFieldVal] = useState(value);

  const handleChange = e => {
    setFieldVal(e.target.value);
    onChange(e);
  };

  return (
    <Container>
      <Label
        text={label}
        required={required}
      />
      <StyledTextField
        variant='outlined'
        id={id}
        placeholder={placeholder}
        // Add check for date field to show placeholder when not focused and no value
        type={type}
        onChange={handleChange}
        onBlur={onBlur ? onBlur : undefined}
        value={fieldVal}
        fullWidth
        error={!!error}
        multiline={multiline}
        minRows={3}
        InputProps={{
          inputProps: {
            min: type === 'number' ? 0 : undefined,
          }
        }}
      />
      {
        error &&
        <FieldError
          error={error}
        />
      }
    </Container>
  );
};

export default CustomTextField;
