import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from "../../../options/colors";

export const RepsContainer = styled('div')(() => ({
  marginBottom: 20,
  padding: 10,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  '&:nth-of-type(even)': {
    background: 'rgba(255, 255, 255, 0.03)',
  },
}));

export const IconsContainer = styled('div')(() => ({
  display: 'flex',
  '& .MuiButtonBase-root': {
    marginLeft: 10,
  },
}));

export const DeleteText = styled(Typography)(({ theme }) =>({
  ...theme.typography.body1,
  marginBottom: 20,
}));

export const DeleteInstructionsContainer = styled('div')(({ theme }) =>({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 280,
  padding: 20,
  background: colors.menuPrimaryBlack,
  borderRadius: 5,
  margin: 'auto',
  [theme.breakpoints.down(450)]: {
    maxWidth: 'unset',
  },
}));

export const DeleteInstructionsText = styled(Typography)(({ theme }) =>({
  ...theme.typography.h3,
  marginBottom: 10,
}));

export const FieldContainer = styled('div')(({ theme }) => ({
  width: 280,
  [theme.breakpoints.down(450)]: {
    width: '100%',
  },
}));
