import {useEffect, useState, useCallback, createContext, useContext} from 'react';
import { routes } from '../../options/routes';

import {
  getLatestProgrammeStats,
  getClubPlayers,
  getDrillSummaryStats,
  getClubPlayerProfile,
  getBoardsClubDrills,
  getGlobalLeaderBoardStats,
} from '../../api';
// Contexts
import {BaseContext} from '../Global/BaseProvider';

//Helpers
import {createPlayerStatsObject,createDrillStatsProgrammes} from '../../utils/helpers/drillStatsHelper';
const DrillStatsContext = createContext({});

const DrillStatsProvider = ({children}) => {
  const baseContext = useContext(BaseContext);
  const [drillStatsData, setDrillStatsData] = useState({
    playerStats: null,
    apiError: false,
  });

  const getDrillStatsData = useCallback(async () => {
    // Only trigger API call for drillStats route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.indexDrillStats) return;

    // Call API
    if (!baseContext.baseData.loading && !drillStatsData.loading) {
      setDrillStatsData({ loading: true });
      const { userProfile, clubProfile } = baseContext.baseData;
      const clubData = await getClubPlayers(clubProfile.id);

      if ( !clubData || !clubData.success || !clubData.data) {
        setDrillStatsData({
          loading: false,
          playerStats: null,
          clubProfiles: null,
          allProgrammeSessions: [],
          drillStats: null,
          apiError: true,
          allLoaded: true,
        });
        return;
      }

      // Check if userProfile id is in the current club data
      const { profiles } = clubData.data;


      // Call club best drills API
      const playerStatsId = profiles.length ? (profiles.find((profile) => profile.id === userProfile.id)) ? userProfile.id : profiles[0].id : undefined;

      const clubLeaderboard = await getBoardsClubDrills(clubProfile.id);
      const globalLeaderboard = await getGlobalLeaderBoardStats();
      const { drillStats, allProgrammeSessions } = await createDrillStatsProgrammes(playerStatsId);


      if (!profiles.length) {
        setDrillStatsData({
          loading: false,
          playerStats: createPlayerStatsObject(
            {},
            {},
            clubLeaderboard?.data,
            globalLeaderboard?.data,
            {}
          ),
          clubProfiles: [],
          drillStats,
          allProgrammeSessions: [],
          clubLeaderboard: clubLeaderboard?.data,
          globalLeaderboard: globalLeaderboard?.data,
          apiError: false,
          allLoaded: true,
        });
        return;
      }

      const latestPlayerStatsData = await getLatestProgrammeStats(playerStatsId);

      const profileData = await getClubPlayerProfile(playerStatsId);
      const drillAverageStats = await getDrillSummaryStats(playerStatsId);


      if (
        !latestPlayerStatsData ||
        !latestPlayerStatsData.success ||
        !latestPlayerStatsData.data ||
        !profileData ||
        !profileData.success ||
        !profileData.data ||
        !drillAverageStats ||
        !drillAverageStats.success ||
        !drillAverageStats.data
      ) {
          setDrillStatsData({
            loading: false,
            playerStats: null,
            clubProfiles: null,
            drillStats: null,
            apiError: true,
            allLoaded: true,
          });
      }

      const tempPlayerStatsData = createPlayerStatsObject(
        profileData?.data,
        drillAverageStats?.data,
        clubLeaderboard?.data,
        globalLeaderboard?.data,
        latestPlayerStatsData.data
      );
      // Set data
      setDrillStatsData({
        loading: false,
        playerStats: tempPlayerStatsData,
        clubProfiles: clubData.data.profiles,
        drillStats,
        allProgrammeSessions,
        clubLeaderboard: clubLeaderboard?.data,
        globalLeaderboard: globalLeaderboard?.data,
        apiError: false,
        allLoaded: true,
      });
    }

  }, [baseContext]);

  useEffect(() => {
    getDrillStatsData();
  }, [getDrillStatsData]);

  return (
    <DrillStatsContext.Provider
      value={{
        drillStatsData,
        getDrillStatsData
      }}
    >
      {children}
    </DrillStatsContext.Provider>
  );
}

export {DrillStatsProvider, DrillStatsContext};

export default DrillStatsProvider;
