export const userRoles = [
  // {
  //   value: 'CLUB_SUPER_ADMIN',
  //   label: 'Club Super Administrator',
  // },
  {
    value: 'CLUB_ADMIN',
    label: 'Club Administrator',
  },
  {
    value: 'CLUB_MEMBER',
    label: 'Member',
  },
  // {
  //   value: 'CLUB_PLAYER',
  //   label: 'Player',
  // },
];


export const repRoles = [
  {
    value: 'REP_TEAM_ADMIN',
    label: 'Rep Administrator',
  },
  {
    value: 'REP_TEAM_MEMBER',
    label: 'Rep Member',
  },
];
