import { useEffect, useState, useCallback, createContext } from 'react';
import { routes } from '../../options/routes';

// API calls
import { getRepCompanies } from '../../api';

const RepCompaniesContext = createContext({});

const RepCompaniesProvider = ({ children }) => {
  const [repCompaniesData, setRepCompaniesData] = useState({
    loading: true,
    repCompanies: null,
    apiError: false,
  });

  const getRepCompaniesData = useCallback(async () => {
    // Only trigger API call for rep companies route
    const activeRoute = window.location.pathname;

    if (activeRoute !== routes.repCompanyManagement) return;

    // Call API
    try {
      const repCompaniesApiData = await getRepCompanies();
      if (!repCompaniesApiData || !repCompaniesApiData.success || !repCompaniesApiData.data) {
        setRepCompaniesData({
          loading: false,
          repCompanies: null,
          apiError: true,
        });
        return;
      }
      const formattedRepCompanyData = repCompaniesApiData.data.map(({ id, club }) => {
        let location = '';
        if (club.city && club.country) {
          location = `${club.city}, ${club.country}`;
        } else if (club.city) {
          location = `${club.city}`;
        } else if (club.country) {
          location = `${club.country}`;
        }
        return {
          id,
          name: club.name,
          contact: club.contact,
          telephone: club.telephone,
          telephoneCountryCode: club.telephoneCountryCode,
          addr1: club.addr1,
          addr2: club.addr2,
          addr3: club.addr3,
          city: club.city,
          colour: `#${club.colour}`,
          location,
          postcode: club.postcode,
          country: club.country,
          safe_zone_radius: club.safe_zone_radius || 0.74,
          // indexLicenceCount: '2',
          // indexLicenceDiscount: '20',
          // analysisLicenceCount: '0',
          // analysisLicenceDiscount: '0',
          // dbLicenceCount: '0',
          // dbLicenceDiscount: '0',
          // player22LicenceCount: '20',
          // player22LicenceDiscount: '0',
          // salesRep: 'PerfTeq',
          // revenue: '£125 PM',
          // link: '',
        };
      });
      setRepCompaniesData({
        loading: false,
        repCompanies: formattedRepCompanyData,
        apiError: false,
      });
      return;
    } catch (error) {
      setRepCompaniesData({
        loading: false,
        repCompanies: null,
        apiError: true,
      });
      return;
    }
  }, []);

  useEffect(() => {
    getRepCompaniesData();
  }, [getRepCompaniesData]);

  return (
    <RepCompaniesContext.Provider
      value={{
        repCompaniesData,
        getRepCompaniesData
      }}
    >
      {children}
    </RepCompaniesContext.Provider>
  );
}

export { RepCompaniesProvider, RepCompaniesContext };

export default RepCompaniesProvider;
