import {
  Container,
  NameText,
} from './NameAvatar.styles';
import ClubLogoAvatar from '../ClubLogoAvatar/ClubLogoAvatar';

const NameAvatar = ({image, name}) => {
  return (
    <Container>
      <ClubLogoAvatar
        clubLogo={image}
        small
      />
      <NameText>
        {name}
      </NameText>
    </Container>
  );
};

export default NameAvatar;
