import {Switch, Route, Redirect} from 'react-router-dom';
import {routes, adminPrefix, managementPrefix, indexPrefix} from '../options/routes';
import Home from '../pages/Home/Home';
import Login from '../pages/Authentication/Login/Login';
import LoginVerify from '../pages/Authentication/LoginVerify/LoginVerify';
import Register from '../pages/Authentication/Register/Register';
import ForgotPassword from '../pages/Authentication/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/Authentication/ResetPassword/ResetPassword';
import AcceptInvitation from '../pages/Authentication/AcceptInvitation/AcceptInvitation';
import Account from '../pages/Admin/Account/Account';
import Products from '../pages/Admin/Products/Products';
import Billing from '../pages/Admin/Billing/Billing';
import RepManagement from '../pages/Admin/RepManagement/RepManagement';
import RepCompanyManagement from '../pages/Admin/RepCompanyManagement/RepCompanyManagement';
import ClubManagement from '../pages/Admin/ClubManagement/ClubManagement';
import Revenue from '../pages/Admin/Revenue/Revenue';
import IndexPanel from '../pages/Index/IndexPanel/IndexPanel';
import Licences from '../pages/Index/Licences/Licences';
import PlayerProfiles from '../pages/Index/PlayerProfiles/PlayerProfiles';
import IndexStats from '../pages/Index/IndexStats/IndexStats';
import DrillStats from '../pages/Index/DrillStats/DrillStats';
import ClubLeaderBoards from '../pages/Index/ClubLeaderBoards/ClubLeaderBoards';
import GlobalLeaderBoards from '../pages/Index/GlobalLeaderBoards/GlobalLeaderBoards';
import Share from '../pages/Share/Share';

// rezzilPlayer Pages
import ManageUsers from '../pages/RezzilPlayer/ManageUsers/ManageUsers';
import rezzilPlayerDrillStats from '../pages/RezzilPlayer/DrillStats/DrillStats';
import rezzilPlayerClubLeaderBoards from '../pages/RezzilPlayer/ClubLeaderBoards/ClubLeaderBoards';
import rezzilPlayerGlobalLeaderBoards from '../pages/RezzilPlayer/GlobalLeaderBoards/GlobalLeaderBoards';
import Subscribe from '../pages/RezzilPlayer/Subscribe/Subscribe';


import Logout from '../pages/Authentication/Logout/Logout';

// Providers
import BaseProvider from '../providers/Global/BaseProvider';
import UpdatesProvider from '../providers/Global/UpdatesProvider';
import HomeProvider from '../providers/Home/HomeProvider';
import AccountProvider from '../providers/Admin/AccountProvider';
import ProductsProvider from '../providers/Admin/ProductsProvider';
import BillingProvider from '../providers/Admin/BillingProvider';
import RepsProvider from '../providers/Admin/RepsProvider';
import RepCompaniesProvider from '../providers/Admin/RepCompaniesProvider';
import ClubsProvider from '../providers/Admin/ClubProvider';
import RevenueProvider from '../providers/Admin/RevenueProvider';
import PanelProvider from '../providers/Index/PanelProvider';
import LicencesProvider from '../providers/Index/LicencesProvider';
import PlayerProfilesProvider from '../providers/Index/PlayerProfilesProvider';
import IndexStatsProvider from '../providers/Index/IndexStatsProvider';
import DrillStatsProvider from '../providers/Index/DrillStatsProvider';
import ClubLeaderBoardsProvider from '../providers/Index/ClubLeaderBoardsProvider';
import GlobalLeaderBoardsProvider from '../providers/Index/GlobalLeaderBoardsProvider';


import ManageUsersProvider from '../providers/RezzilPlayer/ManageUsers';
import SubscribeProvider from '../providers/RezzilPlayer/Subscribe';
import RezzilPlayerDrillStatsProvider from '../providers/RezzilPlayer/DrillStatsProvider';
import { GlobalLeaderBoardsProvider as RezzilPlayerGlobalLeaderBoardsProvider } from '../providers/RezzilPlayer/GlobalLeaderBoardsProvider';
import { ClubLeaderBoardsProvider as RezzilPlayerClubLeaderBoardsProvider } from '../providers/RezzilPlayer/ClubLeaderBoardsProvider';



const Content = ({userAuthenticated}) => {
  const authenticatedScreens = () => {
    return (
      <BaseProvider>
        <UpdatesProvider>
          <Route exact path='/'>
            <Redirect to={routes.home} />
          </Route>
          <Route exact path={routes.home} render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`}>
                <Redirect to={routes.home} />
              </Route>
              <HomeProvider>
                <Route exact path={routes.home} component={Home} />
              </HomeProvider>
            </>
          )} />
          <Route exact path={adminPrefix}>
            <Redirect to={routes.adminAccount} />
          </Route>
          <Route exact path={managementPrefix}>
            <Redirect to={routes.adminAccount} />
          </Route>
          <Route
            path={routes.adminAccount}
            render={({match: {url}}) => (
              <>
                <Route path={`${url}/`}>
                  <Redirect to={routes.adminAccount} />
                </Route>
                <AccountProvider>
                  <Route exact path={routes.adminAccount} component={Account} />
                </AccountProvider>
              </>
            )}
          />
          <Route
            path={routes.adminProducts}
            render={({match: {url}}) => (
              <>
                <ProductsProvider>
                  <Route exact path={`${routes.adminProducts}/:checkoutRedirect?`} component={Products} />
                </ProductsProvider>
              </>
            )}
          />
          <Route
            path={routes.adminBilling}
            render={({match: {url}}) => (
              <>
                <Route path={`${url}/`}>
                  <Redirect to={routes.adminBilling} />
                </Route>
                <BillingProvider>
                  <Route exact path={routes.adminBilling} component={Billing} />
                </BillingProvider>
              </>
            )}
          />
          <Route
            path={routes.repManagement}
            render={({match: {url}}) => (
              <>
                <Route path={`${url}/`}>
                  <Redirect to={routes.repManagement} />
                </Route>
                <RepsProvider>
                  <Route exact path={routes.repManagement} component={RepManagement} />
                </RepsProvider>
              </>
            )}
          />
          <Route
            path={routes.repCompanyManagement}
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`}>
                  <Redirect to={routes.repCompanyManagement} />
                </Route>
                <RepCompaniesProvider>
                  <Route exact path={routes.repCompanyManagement} component={RepCompanyManagement} />
                </RepCompaniesProvider>
              </>
            )}
          />
          <Route
            path={routes.clubManagement}
            render={({match: {url}}) => (
              <>
                <Route path={`${url}/`}>
                  <Redirect to={routes.clubManagement} />
                </Route>
                <ClubsProvider>
                  <Route exact path={routes.clubManagement} component={ClubManagement} />
                </ClubsProvider>
              </>
            )}
          />
          <Route
            path={routes.revenue}
            render={({match: {url}}) => (
              <>
                <Route path={`${url}/`}>
                  <Redirect to={routes.revenue} />
                </Route>
                <RevenueProvider>
                  <Route exact path={routes.revenue} component={Revenue} />
                </RevenueProvider>
              </>
            )}
          />
          <Route exact path={indexPrefix}>
            <Redirect to={routes.indexPanel} />
          </Route>
          <Route
            path={routes.indexPanel}
            render={({match: {url}}) => (
              <>
                <Route exact path={`${url}/`}>
                  <Redirect to={routes.indexPanel} />
                </Route>
                <PanelProvider>
                  <Route exact path={routes.indexPanel} component={IndexPanel} />
                </PanelProvider>
              </>
            )}
          />
          <Route
            path={routes.indexLicences}
            render={({match: {url}}) => (
              <>
                <Route exact path={`${url}/`}>
                  <Redirect to={routes.indexLicences} />
                </Route>
                <LicencesProvider>
                  <Route exact path={routes.indexLicences} component={Licences} />
                </LicencesProvider>
              </>
            )}
          />
          <Route
            path={routes.indexPlayerProfiles}
            render={({match: {url}}) => (
              <>
                <Route exact path={`${url}/`}>
                  <Redirect to={routes.indexPlayerProfiles} />
                </Route>
                <PlayerProfilesProvider>
                  <Route exact path={routes.indexPlayerProfiles} component={PlayerProfiles} />
                </PlayerProfilesProvider>
              </>
            )}
          />
          <Route
            path={routes.indexStats}
            render={({match: {url}}) => (
              <>
                <Route exact path={`${url}/`}>
                  <Redirect to={routes.indexStats} />
                </Route>
                <IndexStatsProvider>
                  <Route exact path={routes.indexStats} component={IndexStats} />
                </IndexStatsProvider>
              </>
            )}
          />
          <Route
            path={routes.indexDrillStats}
            render={({match: {url}}) => (
              <>
                <Route exact path={`${url}/`}>
                  <Redirect to={routes.indexDrillStats} />
                </Route>
                <DrillStatsProvider>
                  <Route exact path={routes.indexDrillStats} component={DrillStats} />
                </DrillStatsProvider>
              </>
            )}
          />
          <Route
            path={routes.indexClubLeaderBoards}
            render={({match: {url}}) => (
              <>
                <Route exact path={`${url}/`}>
                  <Redirect to={routes.indexClubLeaderBoards} />
                </Route>
                <ClubLeaderBoardsProvider>
                  <Route exact path={routes.indexClubLeaderBoards} component={ClubLeaderBoards} />
                </ClubLeaderBoardsProvider>
              </>
            )}
          />
          <Route
            path={routes.indexGlobalLeaderBoards}
            render={({match: {url}}) => (
              <>
                <Route exact path={`${url}/`}>
                  <Redirect to={routes.indexGlobalLeaderBoards} />
                </Route>
                <GlobalLeaderBoardsProvider>
                  <Route exact path={routes.indexGlobalLeaderBoards} component={GlobalLeaderBoards} />
                </GlobalLeaderBoardsProvider>
              </>
            )}
          />
        <Route
            path={routes.rezzilPlayerPanel}
            render={({match: {url}}) => (
              <>
                <Route exact path={`${url}/`}>
                  <Redirect to={routes.rezzilPlayerPanel} />
                </Route>
                <SubscribeProvider>
                  <Route exact path={routes.rezzilPlayerPanel} component={Subscribe} />
                </SubscribeProvider>
              </>
            )}
          />
          <Route
            path={routes.rezzilPlayerManageUsers}
            render={({match: {url}}) => (
              <>
                <Route exact path={`${url}/`}>
                  <Redirect to={routes.rezzilPlayerManageUsers} />
                </Route>
                <ManageUsersProvider>
                  <Route exact path={routes.rezzilPlayerManageUsers} component={ManageUsers} />
                </ManageUsersProvider>
              </>
            )}
          />
          <Route
            path={routes.rezzilPlayerDrillStats}
            render={({match: {url}}) => (
              <>
                <Route exact path={`${url}/`}>
                  <Redirect to={routes.rezzilPlayerDrillStats} />
                </Route>
                <RezzilPlayerDrillStatsProvider>
                  <Route exact path={routes.rezzilPlayerDrillStats} component={rezzilPlayerDrillStats} />
                </RezzilPlayerDrillStatsProvider>
              </>
            )}
          />
          <Route
            path={routes.rezzilPlayerClubLeaderBoards}
            render={({match: {url}}) => (
              <>
                <Route exact path={`${url}/`}>
                  <Redirect to={routes.rezzilPlayerClubLeaderBoards} />
                </Route>
                <RezzilPlayerClubLeaderBoardsProvider>
                  <Route exact path={routes.rezzilPlayerClubLeaderBoards} component={rezzilPlayerClubLeaderBoards} />
                </RezzilPlayerClubLeaderBoardsProvider>
              </>
            )}
          />
          <Route
            path={routes.rezzilPlayerGlobalLeaderBoards}
            render={({match: {url}}) => (
              <>
                <Route exact path={`${url}/`}>
                  <Redirect to={routes.rezzilPlayerGlobalLeaderBoards} />
                </Route>
                <RezzilPlayerGlobalLeaderBoardsProvider>
                  <Route exact path={routes.rezzilPlayerGlobalLeaderBoards} component={rezzilPlayerGlobalLeaderBoards} />
                </RezzilPlayerGlobalLeaderBoardsProvider>
              </>
            )}
          />
        </UpdatesProvider>
        <Route exact path={routes.logout} component={Logout} />
      </BaseProvider>
    );
  };

  return (
    <main>
      <Switch>
        <Route exact path={routes.login} component={Login} />
        <Route exact path={`${routes.verify}/:id/:verificationType`} component={LoginVerify} />
        <Route exact path={routes.register} component={Register} />
        <Route exact path={routes.forgotPassword} component={ForgotPassword} />
        <Route exact path={`${routes.resetPassword}/:token`} component={ResetPassword} />
        <Route exact path={`${routes.acceptInvitation}`} component={AcceptInvitation} />
        <Route exact path={`${routes.share}/:squadId/:drillId/:userIds?`} component={Share} />
        <Route exact path={`${routes.shareTV}/:squadId/:drillId/:userIds?`} component={(props) => <Share {...props} displayForTV />} />
        <Route exact path={`${routes.acceptInvitation}/:token`} component={AcceptInvitation} />
        {
          userAuthenticated &&
          authenticatedScreens()
        }
      </Switch>
    </main>
  );
};

export default Content;
