import { useEffect, useContext, useState } from 'react';
import { useParams } from "react-router-dom";
import {
  SubscriptionContainer,
} from './Products.styles';
import {routes} from '../../../options/routes';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import SectionContainer from '../../../components/Containers/SectionContainer/SectionContainer';
import SubscriptionsList from '../../../components/Tables/SubscriptionsList/SubscriptionsList';
import RezzilPlayerSubscriptionModal from '../../../components/RezzilPlayerSubscribe/SubscriptionModal/SubscriptionModal';
import StandardModal from '../../../components/Modals/StandardModal/StandardModal';
// Contexts
import {BaseContext} from '../../../providers/Global/BaseProvider';
import {ProductsContext} from '../../../providers/Admin/ProductsProvider';
import SubscriptionChangeForm from '../../../components/Forms/SubscriptionChangeForm/SubscriptionChangeForm';
import { useCallback } from 'react';

//API
import {downgradeSubscription, subscriptionRenew, upgradeSubscription} from '../../../api';


const Products = () => {
  const baseContext = useContext(BaseContext);
  const { checkoutRedirect } = useParams();
  const [refetchedLicences, setRefetchedLicences] = useState(false);
  const productsContext = useContext(ProductsContext);
  const [indexProductsList, setIndexProductsList] = useState([]);
  const [rezzilPlayerProductsList, setRezzilPlayerProductsList] = useState([]);
  const [stripeProducts, setStripeProducts] = useState();
  const [subscriptionChangeOpen, setSubscriptionChangeOpen] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const [autoRenewLoading, setAutoRenewLoading] = useState(false);
  const [licencesModalOpen, setLicencesModalOpen] = useState(false);
  const [error, setError] = useState({
    show: false,
    message: '',
  });


  const refetchBaseData = useCallback(() => {
    baseContext.getBaseData();
  }, [baseContext])

  useEffect(() => {
    if (checkoutRedirect && !refetchedLicences) {
      setRefetchedLicences(true);
      setTimeout(() => {
        refetchBaseData();
      }, [5000]);
    }
  }, [checkoutRedirect, refetchBaseData, refetchedLicences]);

  useEffect(() => {
    if (!baseContext.baseData.loading && !productsContext.productsData.loading) {
      setDataLoading(false);

      setIndexProductsList(productsContext.productsData.indexSubscriptions);
      setRezzilPlayerProductsList(productsContext.productsData.rezzilPlayerSubscriptions);
      setStripeProducts(productsContext.productsData.products);
    }
  }, [baseContext, productsContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handleAddLicenceClick = () => {
    window.location.href = routes.indexLicences;
  };

  const handleSubscriptionChangeClick = (currentSubscriptionId) => {
    const subscription = indexProductsList.concat(rezzilPlayerProductsList).find((subscription) => subscription.id === currentSubscriptionId);
    setCurrentSubscription(subscription);
    setSubscriptionChangeOpen(true);
  };

  const handleAutoRenewChange = async (id) => {
    // Update autoRenew data - convert checked bool to int (either 0 or 1)
    const subscriptionUpdate = indexProductsList.concat(rezzilPlayerProductsList).find((subscription) => subscription.id === id);

    // Trigger API call
    if (!subscriptionUpdate) {
      return;
    }
    setAutoRenewLoading(id);
    await subscriptionRenew({
      subscriptionId: id,
      renew: subscriptionUpdate.autoRenew === 0,
    });
    await productsContext.getProductsData(baseContext.baseData.clubProfile.id);
    setAutoRenewLoading(false);
  };

  const handleAddRezzilPlayerLicenceClick = () => setLicencesModalOpen(true);

  const handleLicencesModalClose = () => setLicencesModalOpen(false);
  const handleChangeSubscription = async () => {
    const isDowngrade = currentSubscription.frequency === 'Annual';
    if (isDowngrade) {
      await downgradeSubscription({
        subscriptionId: currentSubscription.id,
        newPriceId: currentSubscription.changeTo?.id
      })
      setError({
        show: false,
        message: 'Subscription successfully downgraded',
      });
    } else {
      await upgradeSubscription({
        subscriptionId: currentSubscription.id,
        newPriceId: currentSubscription.changeTo?.id
      });
      setError({
        show: false,
        message: 'Subscription successfully upgraded',
      });
    }

    setSubscriptionChangeOpen(false);
    setCurrentSubscription(undefined);
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.adminProducts}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <Title
            text={'Your Products'}
          />
          <SubscriptionContainer>
            <SectionContainer
              sectionTitle={'Rezzil Index'}
              titleBtnText={indexProductsList.length === 0 ? 'Add licence' : ''}
              onTitleBtnClick={handleAddLicenceClick}
            >
              <SubscriptionsList
                subscriptions={indexProductsList}
                onAutoRenewChange={handleAutoRenewChange}
                autoRenewLoading={autoRenewLoading}
                onSubscriptionChangeClick={handleSubscriptionChangeClick}
              />
              </SectionContainer>
          </SubscriptionContainer>
          <SubscriptionContainer>
            <SectionContainer
              sectionTitle={'Rezzil Player'}
              titleBtnText={'Add licence'}
              onTitleBtnClick={handleAddRezzilPlayerLicenceClick}
            >
              <SubscriptionsList
                subscriptions={rezzilPlayerProductsList}
                onAutoRenewChange={handleAutoRenewChange}
                autoRenewLoading={autoRenewLoading}
                onSubscriptionChangeClick={handleSubscriptionChangeClick}
              />
            </SectionContainer>
          </SubscriptionContainer>
          {
            licencesModalOpen &&
              <StandardModal
              open={licencesModalOpen}
              onClose={handleLicencesModalClose}
              title={`Add Licences`}
              hideCancelBtn
              btnText={'Cancel'}
              width={600}
          >
          <RezzilPlayerSubscriptionModal stripeProducts={stripeProducts} />
      </StandardModal>
      }
            {subscriptionChangeOpen &&
              <StandardModal
                width={600}
                open={subscriptionChangeOpen}
                onClose={setSubscriptionChangeOpen}
                title={currentSubscription.frequency === 'Monthly' ? 'Upgrade Subscription' : 'Downgrade Subscription'}
                btnText={currentSubscription.frequency === 'Monthly' ? 'Next' : 'Downgrade Subscription'}
                onClick={handleChangeSubscription}
              >
                <SubscriptionChangeForm subscription={currentSubscription} isDowngrade={currentSubscription.frequency !== 'Monthly'} />
              </StandardModal>
            }

        </MainWrapper>
      }
    </>
  );
};

export default Products;
