import {
  Container,
  StandardScoresContainer,
  BreakdownScoresContainer,
} from './IndexBreakdownHeaderStats.styles';
import {
  indexBreakdownStandardScoresOptions,
  indexBreakdownStatScoresOptions,
} from '../../../options/indexBreakdownOptions';
import StatNameAndScore from '../StatNameAndScore/StatNameAndScore';

const IndexBreakdownHeaderStats = ({data}) => {
  return (
    <Container>
      <StandardScoresContainer>
        {
          data && indexBreakdownStandardScoresOptions.map(({statId, title}) => (
            <StatNameAndScore
              key={title}
              statName={title}
              showTrend={data[statId].showTrend}
              trend={data[statId].showTrend && data[statId].trend}
              score={data[statId].score}
              showMaxScore={data[statId].showMax}
            />
          ))
        }
      </StandardScoresContainer>
      <BreakdownScoresContainer>
        {
          data && indexBreakdownStatScoresOptions.map(({statId, title}) => (
            <StatNameAndScore
              key={title}
              statName={title}
              showTrend={data[statId].showTrend}
              trend={data[statId].showTrend && data[statId].trend}
              score={data[statId].score}
              showMaxScore={data[statId].showMax}
            />
          ))
        }
      </BreakdownScoresContainer>
    </Container>
  )
};

export default IndexBreakdownHeaderStats;
