import {
  Container,
  SelectsContainer,
  SelectContainer,
} from './SectionTitleWithSelects.styles';
import SectionTitle from '../../../components/Typography/SectionTitle/SectionTitle';
import CustomSelect from '../../Inputs/CustomSelect/CustomSelect';

const SectionTitleWithSelects = ({
  title,
  playerOptions,
  handlePlayerChange,
  selectedPlayer,
  sessionOptions,
  handleSessionChange,
  selectedSession,
  loading,
}) => {
  return (
    <Container>
      <SectionTitle
        text={title}
      />
      <SelectsContainer>
        <SelectContainer>
          <CustomSelect
            name={`player`}
            placeholder={'Select option'}
            onChange={handlePlayerChange}
            value={playerOptions.length > 0 ? selectedPlayer : ''}
            options={playerOptions}
            disabled={loading}
          />
        </SelectContainer>
        <SelectContainer>
          <CustomSelect
            name={`session`}
            placeholder={'Select option'}
            onChange={handleSessionChange}
            value={selectedSession}
            options={sessionOptions}
            disabled={loading}
          />
        </SelectContainer>
      </SelectsContainer>
    </Container>
  );
};

export default SectionTitleWithSelects;
