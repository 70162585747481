import {styled} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  borderBottom: `1px solid ${colors.dividerGrey}`,
  marginBottom: 30,
  [theme.breakpoints.down(750)]: {
    flexDirection: 'column',
    marginBottom: 20,
  },
}));

export const StandardScoresContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  borderRight: `1px solid ${colors.dividerGrey}`,
  marginBottom: 30,
  marginRight: 25,
  [theme.breakpoints.down(750)]: {
    flexDirection: 'column',
    marginBottom: 20,
    marginRight: 0,
    borderRight: 'none',
    borderBottom: `1px solid ${colors.dividerGrey}`,
    paddingBottom: 20,
  },
}));

export const BreakdownScoresContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  marginBottom: 30,
  '& > div:last-of-type': {
    marginRight: 0,
  },
  [theme.breakpoints.down(750)]: {
    flexDirection: 'column',
    marginBottom: 20,
  },
}));
