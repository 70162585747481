import {useEffect, useContext, useState} from 'react';
import {
  SearchContainer,
} from './ClubManagement.styles';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import SectionTitle from '../../../components/Typography/SectionTitle/SectionTitle';
import CustomSearchField from '../../../components/Inputs/CustomSearchField/CustomSearchField';
import ClubsList from '../../../components/Tables/ClubsList/ClubsList';

// Contexts
import {BaseContext} from '../../../providers/Global/BaseProvider';
import {ClubsContext} from '../../../providers/Admin/ClubProvider';
// Validators & helpers
import emailIsValid from '../../../utils/validation/email';
import phoneNumberIsValid from '../../../utils/validation/phoneNumber';
import {debounce} from 'debounce';

// API Calls
import {addClub, getClubLicences, updateClub} from '../../../api';
import { routes } from '../../../options/routes';

const ClubManagement = () => {
  const baseContext = useContext(BaseContext);
  const clubsContext = useContext(ClubsContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [clubLoading, setClubLoading] = useState(false);
  const [clubsList, setClubsList] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: '',
  });
  const [searchData, setSearchData] = useState({
    clubSearch: '',
  });
  const initialClubState = {
    name: '',
    contact: '',
    telephoneCountryCode: '',
    telephone: '',
    location: '',
    addr1: '',
    addr2: '',
    addr3: '',
    city: '',
    postcode: '',
    safe_zone_radius: '0.74',
    colour:'',
  }

  const initialClubErrors = {
    name: '',
    contact: '',
    telephoneCountryCode: '',
    telephone: '',
    location: '',
    addr1: '',
    addr2: '',
    addr3: '',
    city: '',
    postcode: '',
    safe_zone_radius: '',
    colour:'',
  }
  const [searchResults, setSearchResults] = useState([]);
  const [clubData, setClubData] = useState(initialClubState);
  const [clubErrors, setClubErrors] = useState(initialClubErrors);
  const [clubLicences, setClubLicences] = useState([]);

  const getClubLicenceData = async (clubData) => {
    // Need to call the licence endpoint for each club to get the number of licences
    let updatedClubLicences = [];
    for (let i = 0; i < clubData.length; i++) {
      const currentIndexLicenceCount = clubLicences.find(club => club.clubId === clubData[i].id)?.indexLicenceCount;
      if (currentIndexLicenceCount !== undefined && currentIndexLicenceCount !== '--') {
        // Licence data already populated
        updatedClubLicences.push({ clubId: clubData[i].id, indexLicenceCount: currentIndexLicenceCount });
      } else {
        const clubLicence = await getClubLicences(clubData[i].id);
        if (!clubLicence || !clubLicence.data || !clubLicence?.data.length) {
          updatedClubLicences.push({ clubId: clubData[i].id, indexLicenceCount: 0 });
          // return;
        } else {
          const indexLicence = clubLicence?.data.filter((licence) => licence.code === 'elite');
          updatedClubLicences.push({ clubId: clubData[i].id, indexLicenceCount: indexLicence ? indexLicence.length : 0 });
        }
      }
    }
    const updatedClubData = clubData.map((club) => {
      const clubLicenceData = updatedClubLicences.find((licenceData) => licenceData.clubId === club.id);
      return {
        ...club,
        indexLicenceCount: clubLicenceData ? clubLicenceData.indexLicenceCount : 0
      }
    });
    setClubLicences([...updatedClubLicences]);
    setClubsList([...updatedClubData]);
  }

  useEffect(() => {
    if (!baseContext.baseData.loading && !clubsContext.clubsData.loading) {
      setDataLoading(false);
      setClubsList(clubsContext.clubsData.clubs);
      getClubLicenceData(clubsContext.clubsData.clubs);
    }
  }, [baseContext, clubsContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handleSearchChange = e => {
    let data = {...searchData};
    data[e.target.id] = e.target.value;
    setSearchData(data);

    // Ensure all data is in lowercase for comparison logic to work correctly
    handleSearchSubmit(data.clubSearch.toLowerCase());
  };

  const handleSearchSubmit = searchTerm => {
    // Filter results with name, email, phone or location matching searchTerm
    const results = clubsList.filter(
      club =>
        club.name.toLowerCase()?.includes(searchTerm) ||
        club.contact.toLowerCase()?.includes(searchTerm) ||
        club.telephone?.includes(searchTerm) ||
        club.location.toLowerCase()?.includes(searchTerm)
    );
    setSearchResults(results);
  };

  const handleSearchClear = () => {
    setSearchData({
      clubSearch: '',
    });
    setSearchResults([]);
  };

  const handleClubChange = (e, id, val) => {
    // Clear errors
    setClubErrors(initialClubErrors);
    handleBannerClose();

    // Update repData
    let data = {...clubData};
    if (e && id) {
      // For autocomplete field
      data[id] = val;
    } else {
      data[e.target.id] = e.target.value;
    }
    setClubData(data);
  };

  const handleClubSubmit = async () => {
    await setClubLoading(true);
    // Set form errors
    let formErrors = {...clubErrors};
    let hasErrors = false;
    if(!clubData.name) {
      formErrors = {
        ...formErrors,
        name: 'Name is required'
      };
      hasErrors = true;
    }
    if(!emailIsValid(clubData.contact)) {
      formErrors = {
        ...formErrors,
        contact: 'A valid Contact email is required'
      };
      hasErrors = true;
    }
    if(!phoneNumberIsValid(clubData.telephone)) {
      formErrors = {
        ...formErrors,
        telephone: 'A valid Phone number is required'
      };
      hasErrors = true;
    }
    if(!clubData.telephoneCountryCode) {
      formErrors = {
        ...formErrors,
        telephoneCountryCode: 'Country Code is required'
      };
      hasErrors = true;
    }
    if(!clubData.country) {
      formErrors = {
        ...formErrors,
        country: 'Country is required'
      };
      hasErrors = true;
    }
    if(!clubData.addr1) {
      formErrors = {
        ...formErrors,
        addr1: 'Address is required'
      };
      hasErrors = true;
    }
    if(!clubData.city) {
      formErrors = {
        ...formErrors,
        city: 'City is required'
      };
      hasErrors = true;
    }
    if(!clubData.postcode) {
      formErrors = {
        ...formErrors,
        postcode: 'Postcode is required'
      };
      hasErrors = true;
    }
    // if(!clubData.safe_zone_radius) {
    //   formErrors = {
    //     ...formErrors,
    //     safe_zone_radius: 'Safe Zone Radius is required'
    //   };
    //   hasErrors = true;
    // }
    if(!clubData.colour) {
      formErrors = {
        ...formErrors,
        colour: 'Colour is required'
      };
      hasErrors = true;
    }
    // if (!clubData.indexLicenceCount) {
    //   formErrors = {
    //     ...formErrors,
    //     indexLicenceCount: 'Index Licence is required'
    //   };
    //   hasErrors = true;
    // }
    // if (clubData.indexLicenceDiscount > 100) {
    //   formErrors = {
    //     ...formErrors,
    //     indexLicenceDiscount: 'The maximum available discount is 100%'
    //   };
    //   hasErrors = true;
    // }
    // if (!clubData.analysisLicenceCount) {
    //   formErrors = {
    //     ...formErrors,
    //     analysisLicenceCount: 'Analysis Licence is required'
    //   };
    //   hasErrors = true;
    // }
    // if (clubData.analysisLicenceDiscount > 100) {
    //   formErrors = {
    //     ...formErrors,
    //     analysisLicenceDiscount: 'The maximum available discount is 100%'
    //   };
    //   hasErrors = true;
    // }
    // if (!clubData.dbLicenceCount) {
    //   formErrors = {
    //     ...formErrors,
    //     dbLicenceCount: 'DB Licence is required'
    //   };
    //   hasErrors = true;
    // }
    // if (clubData.dbLicenceDiscount > 100) {
    //   formErrors = {
    //     ...formErrors,
    //     dbLicenceDiscount: 'The maximum available discount is 100%'
    //   };
    //   hasErrors = true;
    // }
    // if (!clubData.rezzilPlayerLicenceCount) {
    //   formErrors = {
    //     ...formErrors,
    //     rezzilPlayerLicenceCount: 'Rezzil Player Licence is required'
    //   };
    //   hasErrors = true;
    // }
    // if (clubData.rezzilPlayerLicenceDiscount > 100) {
    //   formErrors = {
    //     ...formErrors,
    //     rezzilPlayerLicenceDiscount: 'The maximum available discount is 100%'
    //   };
    //   hasErrors = true;
    // }
    // if(!clubData.salesRep) {
    //   formErrors = {
    //     ...formErrors,
    //     salesRep: 'Sales Rep is required'
    //   };
    //   hasErrors = true;
    // }
    // if(!clubData.revenue) {
    //   formErrors = {
    //     ...formErrors,
    //     revenue: 'Rep Revenue is required'
    //   };
    //   hasErrors = true;
    // }

    // Set errors and return if there are errors
    setClubErrors(formErrors);
    if (hasErrors) {
      setClubLoading(false);
      return false;
    }

    // Trigger API call
    const { id, location,  ...dataToSave } = clubData;
    if (id) {
      await updateClub(id, dataToSave);
    } else {
      await addClub(clubData);
    }

    clubsContext.getClubsData();
    setError({
      ...error,
      message: id ? 'Club has been successfully updated' : 'Club has been successfully added',
    });

    setClubData(initialClubState);
    setClubLoading(false);
    return true;
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.clubManagement}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <Title
            text={'Club Management'}
          />
          <SectionTitle
            text={'Clubs'}
          />
          <SearchContainer>
            <CustomSearchField
              id={'clubSearch'}
              onChange={debounce(handleSearchChange, 350)}
              onClear={handleSearchClear}
              value={searchData.clubSearch}
            />
          </SearchContainer>
          <ClubsList
            clubs={searchData.clubSearch ? searchResults : clubsList}
            onFieldChange={handleClubChange}
            onSubmit={handleClubSubmit}
            formData={clubData}
            formErrors={clubErrors}
            setFormData={setClubData}
            setFormErrors={setClubErrors}
            updateLoading={clubLoading}
          />
        </MainWrapper>
      }
    </>
  );
};

export default ClubManagement;
