
import {styled, Typography} from '@mui/material';
import { colors } from '../../../options/colors';

export const Container = styled('div')(() => ({}));

export const RowContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const SecondaryTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 18,
  color: colors.white,
}));

export const SubscriptionDetails = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: colors.primaryGrey,
  marginTop: 20,
}));

export const PaymentAmount = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 18,
  color: colors.primaryOrange,
  marginTop: 20,
}));
