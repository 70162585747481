import {StyledButton} from './BannerButton.styles';

const BannerButton = ({text, onClick, isSuccess}) => {
  return (
    <StyledButton
      variant='text'
      onClick={onClick}
      success={isSuccess ? 'true': undefined}
    >
      {text}
    </StyledButton>
  );
};

export default BannerButton;
