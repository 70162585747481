import {styled, Button} from '@mui/material';
import {colors} from '../../options/colors';

export const SubscriptionFooter = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 40,
  marginBottom: 40,
  background: `transparent linear-gradient(108deg, ${colors.menuPrimaryBlack} 0%, ${colors.menuSecondaryBlack} 100%) 0% 0% no-repeat padding-box;`,
  padding: 20,
  opacity: 1,
  backdropFilter: 'blur(20px)',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  }
}));

export const StyledImage = styled('img')(() => ({
  height: 130,
}));

export const SubscriptionHeader = styled('div')(() => ({
  display: 'flex',
  fontSize: 22,
  color: colors.white,
  marginBottom: 12,
}));

export const SubscriptionBody = styled('div')(() => ({
  display: 'flex',
  fontSize: 16,
  color: colors.primaryGrey,
}));

export const SubscriptionExplanation = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 32px',
  flex: 2,
  [theme.breakpoints.down('sm')]: {
    marginTop: 10,
  }
}));

export const PriceExplanation = styled('div')(({theme}) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  width: '100%',
  alignContent: 'center',
  alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
    marginTop: 10,
  }
}));

export const PriceRow = styled('div')(({singleLine}) => ({
  display: 'flex',
  flexDirection: 'row',
  height: singleLine ? 36 : '100%',
  marginTop: singleLine ? 21 : 0,
}));


export const PricePerLicence = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  fontSize: 12,
  color: colors.primaryGrey,
  marginLeft: 10,
  alignSelf: 'center',
}));

export const StyledButton = styled(Button)(({ theme}) => ({
  ...theme.typography.h5,
  borderColor: colors.primaryOrange,
  backgroundColor: colors.primaryOrange,
  color: colors.buttonTextBlack,
  width: 146,
  padding: '0 20',
  marginRight: 10,
  justifyContent: 'space-between',
}));

export const PriceContainer = styled('div')(() => ({
  marginRight: 15,
  '&:last-of-type': {
    marginRight: 0,
    marginLeft: 15,
  }
}));