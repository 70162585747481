import {StyledButton} from './TriggerButton.styles';
import {Autorenew, UnfoldMore} from '@mui/icons-material';

const TriggerButton = ({text, onClick, isLoad}) => {
  return (
    <StyledButton
      variant='text'
      onClick={onClick}
      startIcon={isLoad ? <Autorenew/> : <UnfoldMore/>}
    >
      {text}
    </StyledButton>
  );
};

export default TriggerButton;
