import {styled} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  position: 'relative',
  [theme.breakpoints.down(750)]: {
    display: 'block',
  },
}));

export const AverageContainer = styled('div')(({ theme }) => ({
  marginRight: 20,
  [theme.breakpoints.down(750)]: {
    marginRight: 0,
    marginBottom: 20,
  },
}));

export const BodyContainer = styled('div')(() => ({
  flex: 1,
  overflow: 'hidden',
  scrollBehavior: 'smooth',
}));

export const ButtonsContainer = styled('div')(({ theme }) => ({
  width: 'calc(100% - 194px)',
  position: 'absolute',
  left: 197,
  top: -14,
  zIndex: 1,
  '& > #prev': {
    position: 'absolute',
    left: 0,
  },
  '& > #next': {
    position: 'absolute',
    right: 0,
  },
  [theme.breakpoints.down(750)]: {
    width: '100%',
    left: 0,
    top: 196,
  },
}));

export const ShieldsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: 62,
  zIndex: 1,
  [theme.breakpoints.down(750)]: {
    top: 271,
  },
}));

export const StyledImage = styled('img')(() => ({
  height: 32,
  marginBottom: 20,
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const ChartWrapper = styled('div')(({ theme, width }) => ({
  height: 218,
  minWidth: width,
  overflowX: 'auto',
  position: 'relative',
  marginBottom: 20,
  zIndex: 0,
  transform: 'translateY(-30px)',
  [theme.breakpoints.down(750)]: {
    transform: 'translateY(-20px)',
    marginBottom: 20,
  },
}));

export const ChartContainer = styled('div')(() => ({
  height: '100%',
  zIndex: 1,
}));

export const ChartLine = styled('div')(({ position }) => ({
  width: '100%',
  backgroundColor: colors.chartGridGrey,
  height: 1,
  position: 'absolute',
  top: position === 'middle' ? 159 : position === 'bottom' ? 210 : 108,
  zIndex: -1,
}));
