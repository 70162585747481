import {
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  width: '100%',
}));

export const StyledToggleButton = styled(ToggleButton)(({ theme, options, matches }) => ({
  // Dynamically set width based on number of options - 100% for mobile
  width: matches ? `${100 / options.length}%` : '100%',
  padding: 20,
  border: `1px solid ${colors.primaryGrey}`,
  borderRadius: 5,
  background: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  '&.Mui-selected': {
    border: `2px solid ${colors.secondaryOrange} !important`,
    background: 'transparent',
    '& > div:first-of-type': {
      color: colors.secondaryOrange,
      '& .MuiTypography-root': {
        color: colors.secondaryOrange,
      },
    },
  },
  '&.Mui-disabled': {
    border: `1px solid ${colors.primaryGrey}`,
  },
  [theme.breakpoints.down(750)]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '@media print': {
    padding: 10,
  },
}));

export const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: colors.white,
  marginBottom: 10,
  [theme.breakpoints.down(750)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 0,
    textAlign: 'left',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  marginLeft: 12,
  transform: 'translateY(1px)',
  [theme.breakpoints.down(750)]: {
    transform: 'translateY(0px)',
    marginTop: 13,
    marginLeft: 0,
  },
}));

export const StatsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down(750)]: {
    alignItems: 'flex-end',
  },
  textTransform: 'lowercase',

}));

export const PlayerStatsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginBottom: 10,
  [theme.breakpoints.down(750)]: {
    marginBottom: 4,
    justifyContent: 'flex-end',
  },
}));

export const SingleStatContainer = styled('div')(({ theme, single }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: single ? 0 : 7,
  marginLeft: single ? 0 : 7,
  [theme.breakpoints.down(750)]: {
    marginLeft: single ? 0 : 14,
    marginRight: 0,
    '&:first-of-type': {
      marginLeft: 0,
    },
  },
}));

export const PlayerColor = styled('div')(({ color }) => ({
  height: 8,
  width: 8,
  marginRight: 5,
  borderRadius: '50%',
  backgroundColor: color,
}));

export const PlayerScore = styled(Typography)(({ theme, single }) => ({
  fontFamily: 'GraphikMedium, sans-serif',
  fontSize: 30,
  margin: 0,
  color: `${single ? colors.primaryGreen : colors.white} !important`,
  [theme.breakpoints.down(750)]: {
    fontSize: 20,
  },
  '@media print': {
    color: `${colors.black} !important`,
  },
}));

export const WorldScore = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  color: colors.primaryGrey,
  [theme.breakpoints.down(750)]: {
    fontSize: 16,
  },
}));
