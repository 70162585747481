import {useEffect, useCallback} from 'react';
import {useMediaQuery} from '@mui/material';
import {colors} from '../../../options/colors';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import NoResultsText from '../../Typography/NoResultsText/NoResultsText';

// Helpers
import generatePlaceholderArray from '../../../utils/helpers/generatePlaceholderArray';

// Register annotationPlugin to enable drawing annotation lines
Chart.register(annotationPlugin);

const BarChart = ({id, labels, data, handleClick, isDrillStats, selectedSession, selectedDataset}) => {
  // Dynamically display chart based on breakpoint
  const matches = useMediaQuery((theme) => theme.breakpoints.up(750));

  const createChart = useCallback((datasets, isMobile, isDrills, selSession, datasetIdx) => {
    // Get canvas by ID - allows rendering different chart for desktop and mobiles to set different font sizes
    const chartId = 'barChart';
    const ctx = document.getElementById(chartId).getContext('2d');
    const sessionIdx = !isDrills && labels[datasetIdx].findIndex(session => session === selSession, datasetIdx);

    let elDetails = [];
    const highlightInitialBar = {
      id: 'initialBar',
      beforeDraw: chart => {
        if (elDetails.length === 0 && chart._metasets.length > 0) {
          // Highlight bar for latest session by default on char initialisation
          const initialBar = chart._metasets[datasetIdx].data[sessionIdx];
          if (initialBar) {
            initialBar.options.backgroundColor = colors.secondaryOrange;
            elDetails.push({
              datasetIndex: 0,
              elIndex: 9,
              el: initialBar,
              originalColor: datasets[0].color,
            });
          }
        }
      },
    };

    // Create data object for chart config - iterate through datasets and generate dataset for each record
    const sets = [];
    const annotations = [];
    datasets.forEach((dataset, idx) => {
      // Create an array with length of 10 and fill all values with dataset.color - enables updating bg colour on click
      // Set initial bar colour for first dataset to secondary orange for index stats bar chart
      if (!dataset) {
        return;
      }
      const backgroundColor = generatePlaceholderArray(10, dataset.color);
      if (idx === datasetIdx && !isDrills) backgroundColor[sessionIdx] = colors.secondaryOrange;
      sets.push({
        data: dataset.data,
        backgroundColor,
        borderRadius: 5,
        barPercentage: 0.8,
        categoryPercentage: 0.9,
      });

      annotations.push({
        adjustScaleRange: true,
        drawTime: 'afterDatasetsDraw',
        type: 'line',
        scaleID: isMobile ? 'x' : 'y',
        value: dataset.avgScore,
        borderColor: dataset.avgColor,
        borderWidth: 3,
        borderDash: [5, 8],
      });
    });

    const data = {
      labels: isDrills ? labels : labels[datasetIdx],
      datasets: sets,
    };

    const handleBarClick = async (datasetIndex, elIndex, el) => {
      // Change clicked bar colour and reset all other bar colours
      if (el) {
        const originalColor = datasets[datasetIndex].color;
        elDetails.forEach(element => {
          element.el.options.backgroundColor = element.originalColor;
          sets[element.datasetIndex].backgroundColor[element.elIndex] = element.originalColor;
        });
        el.options.backgroundColor = colors.secondaryOrange;
        sets[datasetIndex].backgroundColor[elIndex] = colors.secondaryOrange;
        elDetails.push({
          datasetIndex,
          elIndex,
          el,
          originalColor,
        });
        await handleClick(datasetIndex, elIndex);
      }
    };

    // Create chart config object
    const config = {
      type: 'bar',
      data,
      options: {
        onClick: (event, elements) => {
          // Do not trigger onClick event for drill stats bar chart
          if (isDrills) return;
          let datasetIndex, elIndex, el;
          if (elements.length > 0) {
            datasetIndex = elements[0].datasetIndex;
            elIndex = elements[0].index;
            el = elements[0].element;
            handleBarClick(datasetIndex, elIndex, el);
          }
        },
        indexAxis: isMobile ? 'y' : 'x',
        borderSkipped: 'false',
        plugins: {
          // Hide legend
          legend: {
            display: false,
          },
          // Hide hover tooltip
          tooltip: {
            enabled: false,
          },
          autocolors: false,
          // Draw annotation lines
          annotation: {
            annotations,
          },
        },
        scales: {
          x: {
            max: isMobile ? 100 : undefined,
            grid: {
              color: 'transparent',
              borderColor: isMobile ? colors.chartGridGrey : colors.primaryGrey,
              tickColor: isMobile ? 'transparent' : colors.primaryGrey,
            },
            ticks: {
              color: isMobile ? colors.primaryGrey : 'transparent',
              padding: isMobile ? 5 : 10,
              font: {
                family: 'RobotoRegular, sans-serif',
                size: isMobile ? 10 : 13,
              },
            },
          },
          y: {
            position: isMobile ? 'left' : 'right',
            beginAtZero: true,
            // Dynamic y scale for drill stats
            max: isDrills || isMobile ? undefined : 100,
            grid: {
              color: colors.chartGridGrey,
              borderColor: isMobile ? colors.primaryGrey : 'transparent',
              tickColor: isMobile ? colors.primaryGrey : 'transparent',
            },
            ticks: {
              color: isMobile ? 'transparent' : colors.primaryGrey,
              padding: isMobile ? 5 : 10,
              font: {
                family: 'RobotoRegular, sans-serif',
                size: isMobile ? 10 : 13,
              },
            },
          },
        },
      },
      plugins: [!isDrills && highlightInitialBar],
    };

    // Instantiate chart with config object
    new Chart(ctx, config);
  },[labels, handleClick]);

  useEffect(() => {
    if (data && data.length > 0) {
      // Remove existing canvas before re-drawing to resolve error when parent re-renders
      const canvas = matches ? (
        '<canvas id="barChart" height="80"/>'
      ) : (
        '<canvas id="barChart" height="350"/>'
      );
      const chart = document.getElementById('barChart');
      if (chart) chart.remove();
      const chartContainer = document.getElementById(`${id}ChartContainer`);
      chartContainer.innerHTML = canvas;
      createChart(data, !matches, isDrillStats, selectedSession, selectedDataset);
    }
  }, [id, createChart, data, matches, isDrillStats, selectedSession, selectedDataset]);

  return (
    <>
      {
        (!data || data.length === 0) &&
        <NoResultsText text={'No chart data'}/>
      }
    </>
  );
};

export default BarChart;
