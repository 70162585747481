import {useEffect, useState, useCallback} from 'react';
import {
  InnerContainer,
  InlineFieldContainer,
  PhoneFieldContainer,
  FieldContainer,
} from './Register.styles';
import {routes} from '../../../options/routes';
import {countries} from '../../../options/countries';
import AuthenticationWrapper from '../../../components/Wrappers/AuthenticationWrapper/AuthenticationWrapper';
import AuthFormContainer from '../../../components/Containers/AuthFormContainer/AuthFormContainer';
import CustomTextField from '../../../components/Inputs/CustomTextField/CustomTextField';
import CustomAutocomplete from '../../../components/Inputs/CustomAutocomplete/CustomAutocomplete';
import CustomCheckbox from '../../../components/Inputs/CustomCheckbox/CustomCheckbox';
import StandardLink from '../../../components/Links/StandardLink/StandardLink';
import OutlinedButton from '../../../components/Buttons/OutlinedButton/OutlinedButton';
import SectionSpinner from '../../../components/Loading/SectionSpinner/SectionSpinner';

// Validators & Helpers
import emailIsValid from '../../../utils/validation/email';
import phoneNumberIsValid from '../../../utils/validation/phoneNumber';
import {passwordIsValid, repeatPasswordIsValid} from '../../../utils/validation/password';
import convertNumberToString from '../../../utils/helpers/convertNumberToString';
import getSortedCountryCodes from '../../../utils/helpers/getSortedCountryCodes';
import setLocalStorage from '../../../utils/helpers/setLocalStorage';

// API calls
import {register} from '../../../api';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    telephoneCountryCode: '',
    telephone: '',
    clubname: '',
    addr1: '',
    addr2: '',
    addr3: '',
    city: '',
    postcode: '',
    country: '',
    password: '',
    confirmpassword: '',
  });
  const [errors, setErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    telephoneCountryCode: '',
    telephone: '',
    clubname: '',
    addr1: '',
    city: '',
    postcode: '',
    country: '',
    password: '',
    confirmpassword: '',
    policy: '',
    api: ''
  });
  const [policyAccepted, setPolicyAccepted] = useState(false);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    // Set form errors
    let formErrors = {...errors};
    let hasErrors = false;
    if (!formData.firstname) {
      formErrors = {...formErrors, firstname: 'First Name is required'};
      hasErrors = true;
    }
    if (!formData.lastname) {
      formErrors = {...formErrors, lastname: 'Last Name is required'};
      hasErrors = true;
    }
    if(!emailIsValid(formData.email)) {
      formErrors = {...formErrors, email: 'A valid Email Address is required'};
      hasErrors = true;
    }
    if(!formData.telephoneCountryCode && formData.telephone) {
      formErrors = {...formErrors, telephoneCountryCode: 'A valid Area Code is required'};
      hasErrors = true;
    }
    if(formData.telephone && !phoneNumberIsValid(formData.telephone)) {
      formErrors = {...formErrors, telephone: 'A valid Phone number is required'};
      hasErrors = true;
    }
    if(!formData.clubname) {
      formErrors = {...formErrors, clubname: 'Club Name is required'};
      hasErrors = true;
    }
    if(!formData.addr1) {
      formErrors = {...formErrors, addr1: 'Club Address 1 is required'};
      hasErrors = true;
    }
    if(!formData.city) {
      formErrors = {...formErrors, city: 'Club City is required'};
      hasErrors = true;
    }
    if(!formData.postcode) {
      formErrors = {...formErrors, postcode: 'Club Post/Zip Code is required'};
      hasErrors = true;
    }
    if(!formData.country) {
      formErrors = {...formErrors, country: 'Club Country is required'};
      hasErrors = true;
    }
    if(!passwordIsValid(formData.password)) {
      formErrors = {
        ...formErrors,
        password: 'Password must include at least 8 characters, 1 number, 1 lowercase letter, 1 uppercase letter'
      };
      hasErrors = true;
    }
    if(!repeatPasswordIsValid(formData.password, formData.confirmpassword)) {
      formErrors = {
        ...formErrors,
        confirmpassword: 'Password doesn\'t match'
      };
      hasErrors = true;
    }
    if(!policyAccepted) {
      formErrors = {...formErrors, policy: 'You must accept the Privacy Policy'};
      hasErrors = true;
    }

    // Set errors and return if there are errors
    setErrors(formErrors);
    if (hasErrors) {
      setLoading(false);
      return;
    }

    // Remove leading zeros from phone and convert to string
    const postData = {
      ...formData,
      telephone: convertNumberToString(formData.telephone),
    };

    // Trigger API call
    const data = await register(postData);
    if (!data || !data.success || !(data.data.user && Object.keys(data.data.user).length)) {
      setErrors({...errors, api: 'There was an error when trying to create that user. Please try again.'})
      setLoading(false);
      return;
    }

    // Save account details in local storage and redirect to home if direct login
    setLocalStorage(data.data);
  }, [errors, formData, policyAccepted]);

  useEffect(() => {
    const onEnterKeyPress = e => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.preventDefault();
        if (!loading) {
          handleSubmit();
        }
      }
    };

    document.addEventListener('keydown', onEnterKeyPress);

    return () => {
      document.removeEventListener('keydown', onEnterKeyPress);
    };
  }, [formData, handleSubmit, loading]);

  const handleBannerClose = () => {
    setErrors({...errors, api: ''});
  };

  const handleFieldChange = (e, id, val) => {
    // Clear errors
    setErrors({
      firstname: '',
      lastname: '',
      email: '',
      telephoneCountryCode: '',
      telephone: '',
      clubname: '',
      addr1: '',
      city: '',
      postcode: '',
      country: '',
      password: '',
      confirmpassword: '',
      policy: '',
      api: ''
    });

    // Update formData
    let data = {...formData};
    if (e.target.id === 'policy') {
      // For policy checkbox
      setPolicyAccepted(e.target.checked);
    } else if (e && id) {
      // For autocomplete
      data[id] = val;
    } else {
      // For all other fields
      data[e.target.id] = e.target.value;
    }
    setFormData(data);
  };

  const handleLinkClick = () => {
    window.location.href = routes.login;
  };

  return (
    <AuthenticationWrapper
      apiMessage={errors.api}
      error={errors.api}
      handleBannerClose={handleBannerClose}
    >
      <AuthFormContainer
        btnText={'I already have an account'}
        onClick={handleLinkClick}
      >
        <InnerContainer>
          <InlineFieldContainer>
            <FieldContainer>
              <CustomTextField
                label={'First name'}
                id={'firstname'}
                placeholder={'Joe'}
                required
                onChange={handleFieldChange}
                error={errors.firstname}
                type={'text'}
                value={formData.firstname}
              />
            </FieldContainer>
            <FieldContainer>
              <CustomTextField
                label={'Last name'}
                id={'lastname'}
                placeholder={'Bloggs'}
                required
                onChange={handleFieldChange}
                error={errors.lastname}
                type={'text'}
                value={formData.lastname}
              />
            </FieldContainer>
          </InlineFieldContainer>
          <FieldContainer>
            <CustomTextField
              label={'Email address'}
              id={'email'}
              placeholder={'email@email.com'}
              required
              onChange={handleFieldChange}
              error={errors.email}
              type={'email'}
              value={formData.email}
            />
          </FieldContainer>
          <PhoneFieldContainer>
            <FieldContainer>
              <CustomAutocomplete
                label={'Code'}
                id={'telephoneCountryCode'}
                placeholder={'44'}
                onChange={handleFieldChange}
                error={errors.telephoneCountryCode}
                value={formData.telephoneCountryCode}
                options={getSortedCountryCodes()}
                telephoneCountryCode
              />
            </FieldContainer>
            <FieldContainer>
              <CustomTextField
                label={'Phone'}
                id={'telephone'}
                placeholder={'eg. 7135684527'}
                onChange={handleFieldChange}
                error={errors.telephone}
                type={'text'}
                value={formData.telephone}
              />
            </FieldContainer>
          </PhoneFieldContainer>
          <FieldContainer>
            <CustomTextField
              label={'Club name'}
              id={'clubname'}
              placeholder={'Club name'}
              required
              onChange={handleFieldChange}
              error={errors.clubname}
              type={'text'}
              value={formData.clubname}
            />
          </FieldContainer>
          <FieldContainer>
            <CustomTextField
              label={'Club Address 1'}
              id={'addr1'}
              placeholder={'Address Line 1'}
              required
              onChange={handleFieldChange}
              error={errors.addr1}
              type={'text'}
              value={formData.addr1}
            />
          </FieldContainer>
          <FieldContainer>
            <CustomTextField
              label={'Club Address 2'}
              id={'addr2'}
              placeholder={'Address Line 2'}
              onChange={handleFieldChange}
              error={errors.addr2}
              type={'text'}
              value={formData.addr2}
            />
          </FieldContainer>
          <FieldContainer>
            <CustomTextField
              label={'Club Address 3'}
              id={'addr3'}
              placeholder={'Address Line 3'}
              onChange={handleFieldChange}
              error={errors.addr3}
              type={'text'}
              value={formData.addr3}
            />
          </FieldContainer>
          <FieldContainer>
            <CustomTextField
              label={'Club City'}
              id={'city'}
              placeholder={'City'}
              required
              onChange={handleFieldChange}
              error={errors.city}
              type={'text'}
              value={formData.city}
            />
          </FieldContainer>
          <FieldContainer>
            <CustomTextField
              label={'Club Post/Zip Code'}
              id={'postcode'}
              placeholder={'Post/Zip Code'}
              required
              onChange={handleFieldChange}
              error={errors.postcode}
              type={'text'}
              value={formData.postcode}
            />
          </FieldContainer>
          <FieldContainer>
            <CustomAutocomplete
              label={'Club Country'}
              id={'country'}
              placeholder={'Select Club Country'}
              required
              onChange={handleFieldChange}
              error={errors.country}
              value={formData.country}
              options={countries}
            />
          </FieldContainer>
          <FieldContainer>
            <CustomTextField
              label={'Password'}
              id={'password'}
              placeholder={'Password'}
              required
              onChange={handleFieldChange}
              error={errors.password}
              type={'password'}
              value={formData.password}
            />
          </FieldContainer>
          <FieldContainer>
            <CustomTextField
              label={'Confirm Password'}
              id={'confirmpassword'}
              placeholder={'Confirm password'}
              required
              onChange={handleFieldChange}
              error={errors.confirmpassword}
              type={'password'}
              value={formData.confirmpassword}
            />
          </FieldContainer>
          <FieldContainer>
            <CustomCheckbox
              id={'policy'}
              checked={policyAccepted}
              onChange={handleFieldChange}
              label={
                <StandardLink
                  supportingText={'I confirm that I read, consent and agreed to Rezzil\'s '}
                  text={'Privacy Policy'}
                  href={'https://rezzil.com/privacy-policy/'}
                />
              }
              error={errors.policy}
            />
          </FieldContainer>
          {
            loading &&
            <SectionSpinner/>
          }
          {
            !loading &&
            <OutlinedButton
              text={'Register'}
              onClick={handleSubmit}
              fullWidth
            />
          }
        </InnerContainer>
      </AuthFormContainer>
    </AuthenticationWrapper>
  )
};

export default Register;
