import { useEffect, useState, useCallback, createContext, useContext } from 'react';
import {routes} from '../../options/routes';
import {BaseContext} from '../Global/BaseProvider';
// API calls
import { getSubscriptions } from '../../api';

const LicencesContext = createContext({});

const LicencesProvider = ({ children }) => {
  const baseContext = useContext(BaseContext);
  const [licencesData, setLicencesData] = useState({
    licences: null,
    loading: true,
    apiError: false,
  });

  const getLicencesData = useCallback(async (clubId) => {
    // Only trigger API call for index licences route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.indexLicences) return;

    // Call API
    try {
      const licencesApiData = await getSubscriptions(clubId);
      if (!licencesApiData || !licencesApiData.success || !licencesApiData.data) {
        setLicencesData({
          loading: false,
          licences: [],
          apiError: true,
        });
        return;
      }
      let licences = [];
      if (licencesApiData.data && licencesApiData.data.length) {
        licencesApiData.data.forEach((licenceObject) => {
          licenceObject.data.forEach(licence => {
            if (licence.status === 'active') {
              licences.push(licence);
            }
          });
        })
      }
      setLicencesData({
        loading: false,
        licences,
        apiError: true,
      });
    } catch (error) {
      setLicencesData({
        loading: false,
        licences: [],
        apiError: true,
      });
      return;
    }
  }, []);

  useEffect(() => {
    if (!baseContext.baseData.loading) {
      const { id } = baseContext.baseData.clubProfile;
      getLicencesData(id);
    }
  }, [getLicencesData, baseContext]);

  return (
    <LicencesContext.Provider
      value={{
        licencesData,
        getLicencesData
      }}
    >
      {children}
    </LicencesContext.Provider>
  );
}

export {LicencesProvider, LicencesContext};

export default LicencesProvider;
