import {useEffect, useContext, useState} from 'react';
import {
  TableContainer,
} from './Billing.styles';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import SectionContainer from '../../../components/Containers/SectionContainer/SectionContainer';
import PaymentMethodsList from '../../../components/Tables/PaymentMethodsList/PaymentMethodsList';
import InvoicesList from '../../../components/Tables/InvoicesList/InvoicesList';

// Contexts
import {BaseContext} from '../../../providers/Global/BaseProvider';
import {BillingContext} from '../../../providers/Admin/BillingProvider';

// API Calls
import {updatePaymentMethod, deletePaymentMethod} from '../../../api';
import { routes } from '../../../options/routes';
import { isClubSuperAdmin } from '../../../utils/helpers/userRoleHelper';

const Billing = () => {
  const baseContext = useContext(BaseContext);
  const billingContext = useContext(BillingContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [methodsList, setMethodsList] = useState([]);
  const [editMethodData, setEditMethodData] = useState({});
  const [editMethodErrors, setEditMethodErrors] = useState();
  const [invoicesList, setInvoicesList] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    if (!baseContext.baseData.loading && !billingContext.billingData.loading) {
      setDataLoading(false);
      setMethodsList(billingContext.billingData.payments || []);
      setInvoicesList(billingContext.billingData.invoices || []);
    }
    // Set auto-renew on page load
  }, [baseContext, billingContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handleEditMethodSubmit = async (methodId, expiryMonth, expiryYear) => {
    await setEditLoading(true);
    try {
      const cardUpdate = await updatePaymentMethod(methodId, { expiryMonth, expiryYear });
      if (cardUpdate.success) {
        // Refetch the data on success
        await billingContext.getBillingData();
        return true;
      } else {
        setEditMethodErrors('Something went wrong - Please check your details');
      }

    } catch (error) {
      console.log('error:');
      console.log(error);
      setEditMethodErrors('Something went wrong - Please check your details');
    }
    setEditLoading(false);
  };

  const handlePaymentMethodDelete = async id => {
    setDeleteLoading(true);
    await deletePaymentMethod(id);
    handleBannerClose();
    // Remove payment method from array on success
    const paymentMethodsList = methodsList.filter(method => method.id !== id);
    setMethodsList(paymentMethodsList);

    setDeleteLoading(false);
    return true;
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.adminBilling}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <Title
            text={'Bills & Payments'}
          />
          {
            isClubSuperAdmin() &&
            <TableContainer>
              <SectionContainer
                sectionTitle={'Payment Methods'}
              >
                <PaymentMethodsList
                  paymentMethods={methodsList}
                  handleEditSubmit={handleEditMethodSubmit}
                  formData={editMethodData}
                  setFormData={setEditMethodData}
                  formErrors={editMethodErrors}
                  setFormErrors={setEditMethodErrors}
                  editLoading={editLoading}
                  handleDelete={handlePaymentMethodDelete}
                  deleteLoading={deleteLoading}
                />
              </SectionContainer>
            </TableContainer>
          }
          <TableContainer>
            <SectionContainer
              sectionTitle={'Invoices'}
            >
              <InvoicesList
                invoicesList={invoicesList}
              />
            </SectionContainer>
          </TableContainer>
        </MainWrapper>
      }
    </>
  );
};

export default Billing;
