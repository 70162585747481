import { useEffect, useState, useCallback, createContext, useContext } from 'react';
import {routes} from '../../options/routes';

// Contexts
import {BaseContext} from '../Global/BaseProvider';

// API calls
import {getAllClubs, getClubsByRep} from '../../api';
import { isRep } from '../../utils/helpers/userRoleHelper';

const ClubsContext = createContext({});

const ClubsProvider = ({children}) => {
  const [clubsData, setClubsData] = useState({
    loading: true,
    clubs: null,
    apiError: false,
  });
  const baseContext = useContext(BaseContext);

  const getClubsData = useCallback(async () => {
    // Only trigger API call for admin clubs route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.clubManagement) return;

    // Call API - get payments and invoice data
    if (!baseContext.baseData.loading) {
      try {
        // Check to see if they have permission to see all clubs:
        const clubsApiData = isRep() ? await getClubsByRep(baseContext.baseData.userProfile.id): await getAllClubs();
        if (!clubsApiData || !clubsApiData.success || !clubsApiData.data) {
          setClubsData({
            loading: false,
            clubs: null,
            apiError: true,
          });
          return;
        }
        const formattedClubData = clubsApiData.data.map((club) => {
          let location = '';
          if (club.city && club.country) {
            location = `${club.city}, ${club.country}`;
          } else if (club.city) {
            location = `${club.city}`;
          } else if (club.country) {
            location = `${club.country}`;
          }
          return {
            id: club.id,
            name: club.name,
            contact: club.contact,
            telephone: club.telephone,
            telephoneCountryCode: club.telephoneCountryCode,
            addr1: club.addr1,
            addr2: club.addr2,
            addr3: club.addr3,
            city: club.city,
            colour: `#${club.colour}`,
            location,
            postcode: club.postcode,
            country: club.country,
            safe_zone_radius: club.safe_zone_radius || 0.74,
            indexLicenceCount: '--',
            // indexLicenceDiscount: '20',
            // analysisLicenceCount: '0',
            // analysisLicenceDiscount: '0',
            // dbLicenceCount: '0',
            // dbLicenceDiscount: '0',
            // player22LicenceCount: '20',
            // player22LicenceDiscount: '0',
            // salesRep: 'PerfTeq',
            // revenue: '£125 PM',
            // link: '',
          };
        });
        setClubsData({
          loading: false,
          clubs: formattedClubData,
          apiError: false,
        });
        return;
      } catch (error) {
        setClubsData({
          loading: false,
          clubs: null,
          apiError: true,
        });
        return;
      }
    }
  }, [baseContext]);

  useEffect(() => {
    getClubsData();
  }, [getClubsData]);

  return (
    <ClubsContext.Provider
      value={{
        clubsData,
        getClubsData
      }}
    >
      {children}
    </ClubsContext.Provider>
  );
}

export {ClubsProvider, ClubsContext};

export default ClubsProvider;
