import {createTheme} from '@mui/material';
import {colors} from '../options/colors';

let themeObj = {
  typography: {
    useNextVariants: true,
    h1: {
      fontFamily: 'GraphikMedium, sans-serif',
      fontSize: 36,
      margin: 0,
      color: colors.white,
      letterSpacing: 0,
      '@media (max-width:600px)': {
        fontSize: 24,
      },
      '@media print': {
        color: colors.black,
      }
    },
    h2: {
      fontFamily: 'GraphikMedium, sans-serif',
      fontSize: 20,
      margin: 0,
      color: colors.white,
      letterSpacing: 0,
      '@media print': {
        color: colors.black,
      }
    },
    h3: {
      fontFamily: 'GraphikMedium, sans-serif',
      fontSize: 14,
      margin: 0,
      color: colors.white,
      letterSpacing: 0,
      '@media print': {
        color: colors.black,
      }
    },
    h4: {
      fontFamily: 'GraphikMedium, sans-serif',
      fontSize: 13,
      margin: 0,
      color: colors.white,
      letterSpacing: 0,
      '@media print': {
        color: colors.black,
      }
    },
    h5: {
      fontFamily: 'GraphikMedium, sans-serif',
      fontSize: 12,
      margin: 0,
      color: colors.white,
      letterSpacing: 0,
      '@media print': {
        color: colors.black,
      }
    },
    body1: {
      fontFamily: 'GraphikRegular, sans-serif',
      fontSize: 16,
      margin: 0,
      color: colors.white,
      letterSpacing: 0,
      '@media print': {
        color: colors.black,
      }
    },
    body2: {
      fontFamily: 'GraphikRegular, sans-serif',
      fontSize: 14,
      margin: 0,
      color: colors.white,
      letterSpacing: 0,
      '@media print': {
        color: colors.black,
      }
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          border: '1px solid',
          letterSpacing: '1.2px !important',
          textDecoration: 'none',
          transition: 'all 0.3s ease',
          borderRadius: 20,
          padding: '10px 20px',
          '&:hover': {
            borderColor: `${colors.primaryGreen} !important`,
            backgroundColor: `${colors.primaryGreen} !important`,
            color: colors.buttonTextGreen,
            '.MuiButton-startIcon': {
              color: colors.buttonTextGreen,
            },
          },
          '&:focus': {
            borderColor: `${colors.primaryGreen} !important`,
            backgroundColor: `${colors.primaryGreen} !important`,
            color: colors.buttonTextGreen,
            '.MuiButton-startIcon': {
              color: colors.buttonTextGreen,
            },
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          maxHeight: 660,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            '&:first-of-type': {
              borderRadius: '5px 0 0 5px',
            },
            '&:last-of-type': {
              borderRadius: '0 5px 5px 0',
            },
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            fontFamily: 'GraphikMedium, sans-serif',
            fontSize: 14,
            color: colors.white,
            padding: '0px 10px 11px 0',
            borderBottomColor: colors.white,
            textTransform: 'uppercase',
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: colors.white,
            '& .MuiSvgIcon-root': {
              color: colors.white,
            },
          },
          '&:hover': {
            color: colors.white,
            '& .MuiSvgIcon-root': {
              color: colors.white,
            },
          },
          '&:focus': {
            color: colors.white,
            '& .MuiSvgIcon-root': {
              color: colors.white,
            },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            fontFamily: 'GraphikRegular, sans-serif',
            fontSize: 16,
            padding: '8px 10px 8px 0',
            border: 'none',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          margin: 0,
          color: colors.primaryGrey,
          letterSpacing: 0,
          '&:first-of-type': {
            paddingLeft: 10,
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 400,
      sm: 600,
      md: 900,
      lg: 1350,
      xl: 1536,
    },
  }
};

const theme = createTheme(themeObj);

export default theme;
