import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({}));

export const ExpiryTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 18,
  marginBottom: 15,
}));

export const ExpiryDate = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: colors.primaryGrey,
  marginBottom: 30,
}));

export const TrialNameContainer = styled('div')(() => ({
  marginBottom: 24,
  display: 'flex',
  alignItems: 'center',
}));

export const IconAvatarContainer = styled('div')(() => ({
  marginRight: 10,
}));

export const SubscriptionTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
}));

export const TrialDetailsContainer = styled('div')(() => ({
  marginBottom: 30
}));
