import {useEffect, useCallback} from 'react';
import {useMediaQuery} from '@mui/material';
import {colors} from '../../../options/colors';
import {fullMonths} from '../../../options/months';
import Chart from 'chart.js/auto';
import SectionSpinner from '../../Loading/SectionSpinner/SectionSpinner';
import NoResultsText from '../../Typography/NoResultsText/NoResultsText';

// Helpers
import getPrevMonths from '../../../utils/helpers/getPrevMonths';

const RevenueChart = ({loading, data}) => {
  // Dynamically display chart based on breakpoint
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const createChart = useCallback((chartData, isMobile) => {
    // Get canvas by ID - allows rendering different chart for desktop and mobiles to set different font sizes
    const id = 'revenueChart';
    const ctx = document.getElementById(id).getContext('2d');

    // Create gradient background under line
    let width, height, gradient;
    const getGradient = (ctx, chartArea) => {
      const chartWidth = chartArea.right - chartArea.left;
      const chartHeight = chartArea.bottom - chartArea.top;
      if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
        gradient.addColorStop(0, 'rgba(242, 175, 0, 0.2)');
        gradient.addColorStop(1, 'rgba(242, 175, 0, 0)');
      }
      return gradient;
    };

    // Create hover annotation line and outer border and box-radius effect on active point
    const drawHoverItems = {
      id: 'hoverItems',
      beforeDraw: chart => {
        // Only trigger action when hovering over a point
        if (chart.tooltip._active && chart.tooltip._active.length) {
          // Save chart context and get active point
          const chartCtx = chart.ctx;
          chartCtx.save();
          const activePoint = chart.tooltip._active[0];

          // Draw annotation line
          chartCtx.beginPath();
          chartCtx.setLineDash([1 , 3]);
          chartCtx.moveTo(activePoint.element.x, chart.chartArea.top);
          chartCtx.lineTo(activePoint.element.x, chart.chartArea.bottom);
          chartCtx.lineWidth = 1;
          chartCtx.strokeStyle = colors.primaryGreen;
          chartCtx.stroke();
          chartCtx.restore();

          // Draw solid border circle
          chartCtx.beginPath();
          chartCtx.arc(activePoint.element.x, activePoint.element.y, 6, 0, 2 * Math.PI);
          chartCtx.lineWidth = 2;
          chartCtx.strokeStyle = colors.secondaryOrange;
          chartCtx.stroke();
          chartCtx.restore();

          // Draw box shadow effect circle
          chartCtx.beginPath();
          chartCtx.arc(activePoint.element.x, activePoint.element.y, 9, 0, 2 * Math.PI);
          chartCtx.lineWidth = 6;
          chartCtx.strokeStyle = 'rgba(242, 175, 0, 0.4)';
          chartCtx.stroke();
          chartCtx.restore();
        }
      },
    };

    // Create array of past 6 months (abbreviated), ending with current month
    const months = getPrevMonths(6);

    // Create data object for chart config
    const data = {
      // Get array of last 6 months for labels
      labels: months,
      datasets: [{
        label: '',
        data: chartData,
      }],
    };

    // Create chart config object
    const config = {
      type: 'line',
      data,
      options: {
        plugins: {
          // Hide legend
          legend: {
            display: false,
          },
          // Customise hover tooltip
          tooltip: {
            yAlign: 'bottom',
            xAlign: 'center',
            backgroundColor: colors.white,
            padding: isMobile ? 5 : 13,
            cornerRadius: 5,
            caretPadding: 22,
            displayColors: false,
            titleColor: colors.chartTooltipBlack,
            titleFont: {
              family: 'GraphikMedium, sans-serif',
              size: isMobile ? 13 : 24,
            },
            titleAlign: 'center',
            titleMarginBottom: 2,
            bodyColor: colors.secondaryGrey,
            bodyFont: {
              family: 'GraphikRegular, sans-serif',
              size: isMobile ? 11 : 16,
            },
            bodyAlign: 'center',
            bodySpacing: 0,
            callbacks: {
              // Show amount as title and full month name as label
              title: context => `£${chartData[context[0].dataIndex].toLocaleString()}`,
              label: context => fullMonths[context.label],
            },
          },
        },
        // Customise line tension
        tension: 0.2,
        // Add gradient below chart line
        fill: true,
        backgroundColor: context => {
          const chart = context.chart;
          const {ctx, chartArea} = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea);
        },
        // Customise line and points properties
        borderColor: colors.secondaryOrange,
        borderWidth: 3,
        pointRadius: 1,
        pointBorderColor: 'transparent',
        pointHitRadius: 7,
        pointHoverBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: colors.chartPointHoverBlack,
        borderJoinStyle: 'round',
        // Customise x and y axes, including ticks
        scales: {
          x: {
            position: 'right',
            grid: {
              color: 'transparent',
              borderColor: colors.primaryGrey,
              tickColor: colors.primaryGrey,
            },
            ticks: {
              color: colors.primaryGrey,
              padding: isMobile ? 5 : 10,
              font: {
                family: 'RobotoRegular, sans-serif',
                size: isMobile ? 10 : 13,
              },
            },
          },
          y: {
            position: 'right',
            beginAtZero: true,
            max: Math.round(chartData[(chartData.length -1)] / 1000)*1000 + 2000,
            grid: {
              color: colors.chartGridGrey,
              borderColor: 'transparent',
              tickColor: 'transparent',
            },
            ticks: {
              color: colors.primaryGrey,
              padding: isMobile ? 5 : 10,
              font: {
                family: 'RobotoRegular, sans-serif',
                size: isMobile ? 10 : 13,
              },
              // Format axis labels to add currency symbol and add comma separators to value
              callback: value => `£${value.toLocaleString()}`,
            },
          },
        },
      },
      plugins: [drawHoverItems],
    };

    // Instantiate chart with config object
    new Chart(ctx, config);
  }, []);

  useEffect(() => {
    if (!loading && data && data.length > 0) {
      // Render charts for desktop and mobile - both need to be rendered on page initialisation to prevent errors
      // Remove existing canvas before re-drawing to resolve error when parent re-renders
      const canvas = '<canvas id="revenueChart"/>';
      const chart = document.getElementById('revenueChart');
      if (chart) chart.remove();
      const chartContainer = document.getElementById('revenueChartContainer');
      chartContainer.innerHTML = canvas;
      createChart(data, !matches);
    }
  }, [loading, createChart, data, matches]);

  return (
    <>
      {
        loading &&
        <SectionSpinner/>
      }
      {
        !loading && (!data || data.length === 0) &&
        <NoResultsText
          text={'No monthly Revenue data'}
        />
      }
    </>
  );
};

export default RevenueChart;
