import {styled} from '@mui/material';
import {colors} from '../../../options/colors';

export const ClubLogoContainer = styled('div')(({ theme, logo, small }) => ({
  background: `url(${logo}) no-repeat center / cover`,
  backgroundColor: colors.black,
  height: small ? 40 : 50,
  width: small ? 40 : 50,
  borderRadius: 25,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    height: 40,
    width: 40,
  },
}));
