import {
  styled,
  Fab,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledButton = styled(Fab)(() => ({
  width: 46,
  height: 46,
  boxShadow: 'none',
  background: 'transparent',
  transition: 'all 0.3s ease',
  '&.Mui-disabled': {
    background: 'transparent',
    '.MuiSvgIcon-root': {
      color: colors.secondaryGrey,
    },
  },
  '.MuiSvgIcon-root': {
    color: colors.beige,
    fontSize: 35,
    transition: 'inherit',
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: colors.iconBtnBgGrey,
    '.MuiSvgIcon-root': {
      color: colors.white,
    },
  },
  '&:focus': {
    boxShadow: 'none',
    backgroundColor: colors.iconBtnBgGrey,
    '.MuiSvgIcon-root': {
      color: colors.white,
    },
  },
  '&:active': {
    boxShadow: 'none',
    background: 'transparent',
  },
}));
