export const getUrlLoginIDParam = () => {
  // Return the user login ID param from the pathname
  // It will either be /verify/{login_id} or /verify/{login_id}{verification_type}
  const pathname = window.location.pathname;
  const params = pathname.split('/');
  if (params.length > 3) {
    return params[params.length - 2];
  } else {
    return params[params.length - 1];
  }
};

export const getUrlVerificationTypeParam = () => {
  // Return the user verification type param from the pathname
  // It will be /verify/{login_id}{verification_type}
  const pathname = window.location.pathname;
  return pathname.split('/')[pathname.split('/').length - 1]
};
