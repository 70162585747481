import {styled} from '@mui/material';

export const Container = styled('div')(({ theme, players }) => ({
  display: 'grid',
  gridTemplateColumns: players ? 'repeat(4, 1fr)' : '1fr',
  gridGap: 30,
  textAlign: players ? undefined : 'center',
  [theme.breakpoints.down(1250)]: {
    gridTemplateColumns: players ? 'repeat(3, 1fr)' : '1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: players ? 'repeat(2, 1fr)' : '1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
  '@media print': {
    gridGap: 10,
  }
}));
