export const squadLeaderboardTableNames = [
  'nfl-portals',
  'headers-control',
  'headers-shooting',
  'headers-clearing',
  'headers-passing',
  'ReactionWall-3x3',
  'ReactionWall-3x3-Chase',
  'ReactionWall-invictus',
  'ReactionWall-invictus-Chase',
  'ReactionWall-360',
  'ReactionWall-360-Chase',
  'ReactionWall-180',
  'ReactionWall-180-Chase',
  'ReactionWall-60',
  'ReactionWall-60-Chase',
  'hoops-2020-easy',
  'hoops-2020-medium',
  'hoops-2020-hard',
  'hoops-2020-expert',
  'hoops-partyup-easy',
  'hoops-partyup-medium',
  'hoops-partyup-hard',
  'hoops-partyup-expert',
  'hoops-omw-easy',
  'hoops-omw-medium',
  'hoops-omw-hard',
  'hoops-omw-expert',
  'hoops-gotw-easy',
  'hoops-gotw-medium',
  'hoops-gotw-hard',
  'hoops-gotw-expert',
  'hoops-caution-easy',
  'hoops-caution-expert',
  'hoops-caution-hard',
  'hoops-caution-medium',
];
