import {useEffect, useContext, useState, useCallback} from 'react';
import {useMediaQuery} from '@mui/material';
import {
  StyledSectionContainer,
  BarChartContainer,
} from './DrillStats.styles';
import {colors} from '../../../options/colors';
import {drillStatsToggleOptions} from '../../../options/drillStatsToggleOptions';
import SectionSpinner from '../../../components/Loading/SectionSpinner/SectionSpinner';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import PlayersDropdownSelects from '../../../components/StatsPage/PlayersDropdownSelects/PlayersDropdownSelects';
import DrillTabsAndMenu from '../../../components/StatsPage/DrillTabsAndMenu/DrillTabsAndMenu';
import SectionContainer from '../../../components/Containers/SectionContainer/SectionContainer';
import PlayerCardStats from '../../../components/StatsPage/PlayerCardStats/PlayerCardStats';
import TitleWithLegend from '../../../components/Typography/TitleWithLegend/TitleWithLegend';
import CustomToggleGroup from '../../../components/Inputs/CustomToggleGroup/CustomToggleGroup';
import SectionTitleWithArrows from '../../../components/StatsPage/SectionTitleWithArrows/SectionTitleWithArrows';
import BarChart from '../../../components/Charts/BarChart/BarChart';
import BarChartLegend from '../../../components/StatsPage/BarChartLegend/BarChartLegend';
import ComparisonCardGroup from '../../../components/StatsPage/ComparisonCardGroup/ComparisonCardGroup';

// Contexts & Helpers
import {BaseContext} from '../../../providers/Global/BaseProvider';
import {DrillStatsContext} from '../../../providers/Index/DrillStatsProvider';
import {createColorHex} from '../../../utils/helpers/createColorHex';
import {formatStandardDateString} from '../../../utils/helpers/formatDateString';
import {getPlayerChoices} from '../../../utils/helpers/playerChoicesHelpers';
import duplicateExists from '../../../utils/helpers/duplicateExists';
import {createPlayerStatsObject, createDrillStatsProgrammes, fetchDrillStatsProgrammes} from '../../../utils/helpers/drillStatsHelper';
import SectionTitle from '../../../components/Typography/SectionTitle/SectionTitle';

// API Calls
import {getLatestProgrammeStats, getClubPlayerProfile, getDrillSummaryStats} from '../../../api';
import { routes } from '../../../options/routes';

const DrillStats = () => {
  // Dynamically set orientation based on breakpoint
  const fourTabs = useMediaQuery((theme) => theme.breakpoints.down(1100));
  const threeTabs = useMediaQuery((theme) => theme.breakpoints.down(900));
  const twoTabs = useMediaQuery((theme) => theme.breakpoints.down(700));
  const oneTab = useMediaQuery((theme) => theme.breakpoints.down(500));
  const baseContext = useContext(BaseContext);
  const drillStatsContext = useContext(DrillStatsContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [playerDataLoading, setPlayerDataLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const [playerDropdownOptions, setPlayerDropdownOptions] = useState([]);
  const [playerChoices, setPlayerChoices] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [playerStatsList, setPlayerStatsList] = useState([]);
  const [drillTabOptions, setDrillTabOptions] = useState([]);
  const [drillMenuOptions, setDrillMenuOptions] = useState([]);
  const [selectedDrillId, setSelectedDrillId] = useState('');
  const [selectedDrillName, setSelectedDrillName] = useState('');
  const [playerCardStats, setPlayerCardStats] = useState({});
  const [avgGameStats, setAvgGameStats] = useState([]);
  const [selectedToggleId, setSelectedToggleId] = useState('');
  const [drillSessionStatsData, setDrillSessionStatsData] = useState([]);
  const [clubLeaderboard, setClubLeaderboard] = useState();
  const [globalLeaderboard, setGlobalLeaderboard] = useState();
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [clubName, setClubName] = useState('');
  const [compareStats, setCompareStats] = useState([]);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(true);
  const [chartAllProgrammeSessions, setChartAllProgrammeSessions] = useState();
  const [chartIndex, setChartIndex] = useState(0);
  const [cachedChartData, setCachedChartData] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    if (drillStatsContext.drillStatsData.allLoaded) {
      setDataLoading(false);
      const { playerStats, globalLeaderboard, clubLeaderboard, drillStats, allProgrammeSessions } = drillStatsContext.drillStatsData;
      const playerProfiles = drillStatsContext.drillStatsData.clubProfiles;

      setGlobalLeaderboard(globalLeaderboard);
      setClubLeaderboard(clubLeaderboard);
      setChartAllProgrammeSessions(allProgrammeSessions);

      // Data from API below:
      setPlayerStatsList([playerStats]);

      // Set options for player dropdowns
      let options = [];
      if (playerProfiles && playerProfiles.length > 0) {
        playerProfiles.forEach(player => {
          const playerName = `${player.firstname} ${player.lastname}`;
          const option = {
            value: player.id,
            label: playerName,
            color: createColorHex(playerName),
          };
          options.push(option);
        });
      }
      setPlayerDropdownOptions(options);

      // Add logged in player id to playerChoices
      const {id} = baseContext.baseData.userProfile;
      // Check if logged in player id is an existing option - if not select the first option in the list.
      const currentUserExists = playerProfiles.find((player) => player.id === id);
      const selectedId = currentUserExists ? Number(id) : playerProfiles[0] ? Number(playerProfiles[0]?.id) : undefined;
      setPlayerChoices([selectedId]);

      setSelectedPlayer(playerProfiles.find(profile => profile.id === selectedId.toString()));
      // Set drill stats tab and hidden menu options
      // if (drillStats && drillStats.length > 0) {
        setDrillData(playerStats.drillStats);
      // }

      // Set data for average game stats section
      const toggleOptions = [...drillStatsToggleOptions];
      const avgDrillStats = getDrillAvgGameStats(toggleOptions, playerStats.avgStats);
      setAvgGameStats(avgDrillStats);
      setSelectedToggleId(toggleOptions[0].id);

      // Set chart data for score stat
      const sessionStatsData = [drillStats];
      updateChartData(0, sessionStatsData);
      setCachedChartData([{ chartIndex: 0, chartData: sessionStatsData }]);
      setDisablePrev(allProgrammeSessions ? allProgrammeSessions && allProgrammeSessions.length < 11 : true);
      // Set comparison stats
      const {name} = baseContext.baseData.clubProfile;
      setClubName(name ? name : '--');
      setPlayerCompareStatsData([playerStats], name);
    }
  }, [baseContext, drillStatsContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const setPlayerStats = (drillId, drillStats) => {
    // Set selected drill and stats for first player for player card
    if (drillStats.length > 0) {
      const statIndex = drillId ? drillStats.findIndex(el => el.drillId === drillId) : 0;
      setSelectedDrillId(drillStats[statIndex].drillId);
      setSelectedDrillName(drillStats[statIndex].drillName);
      setPlayerCardStats(drillStats[statIndex]);
    }
  };

  const getDrillOptions = drillStats => {
    // Create array of drill options with correct object props for rendering in tab and menu components
    let drillOptions = [];
    drillStats.forEach(drill => {
      const option = {
        id: drill.drillId,
        icon: drill.drillLogo,
        label: drill.drillName,
      }
      drillOptions.push(option);
    });
    return drillOptions;
  };

  const setDrillTabAndMenuOptions = useCallback(drillOptions => {
    // Set drill tab and menu options based on screen width and options length
    let tabOptionsMaxLength;
    if (fourTabs || threeTabs || twoTabs || oneTab) {
      if (fourTabs) {
        tabOptionsMaxLength = 4;
      }
      if (threeTabs) {
        tabOptionsMaxLength = 3;
      }
      if (twoTabs) {
        tabOptionsMaxLength = 2;
      }
      if (oneTab) {
        tabOptionsMaxLength = 1
      }
    } else {
      tabOptionsMaxLength = 5;
    }
    if (drillOptions.length > tabOptionsMaxLength) {
      const tabOptions = drillOptions.slice(0, tabOptionsMaxLength);
      const menuOptions = drillOptions.slice(tabOptionsMaxLength);
      setDrillTabOptions(tabOptions);
      setDrillMenuOptions(menuOptions);
    } else {
      setDrillTabOptions(drillOptions);
      setDrillMenuOptions([]);
    }

    if (!selectedDrillId) setSelectedDrillId(drillOptions[0].id);
    if (!selectedDrillName) setSelectedDrillName(drillOptions[0].label);

  }, [fourTabs, threeTabs, twoTabs, oneTab]);

  useEffect(() => {
    // Set tab and menu options dynamically as screen width breakpoint threshold is hit
    if (playerStatsList.length > 0 && playerStatsList[0].drillStats.length > 0) {
      const drillOptions = getDrillOptions(playerStatsList[0].drillStats);
      setDrillTabAndMenuOptions(drillOptions);
    }
  }, [playerStatsList, setDrillTabAndMenuOptions]);

  const setDrillData = (drillStats, drillId) => {
    const drillOptions = getDrillOptions(drillStats);
    // Set selected drill option and player one drill stats
    setPlayerStats(drillId, drillStats);
    // Split drillOptions depending on length and screen width
    setDrillTabAndMenuOptions(drillOptions);
  };

  // const getAvgGameStats = (toggleStats, playerScores, worldAvgScores)  => {
  //   // Get initial avg stats to display in Average Index Stats section on page load (for logged in player)
  //   toggleStats.forEach((option) => {
  //     // Reset scores and worldScore values before setting initial stats
  //     option.playerScores = [];
  //     option.worldScore = '';
  //     const playerScore = Math.round(playerScores[option.id]);
  //     const worldScore = Math.round(worldAvgScores[option.id]);
  //     option.playerScores.push(playerScore);
  //     option.worldScore = worldScore;
  //   });
  //   return {toggleStats};
  // };

  const getDrillAvgGameStats = (toggleStats, drillScores) => {

    // Get initial avg stats to display in Average Index Stats section on page load (for logged in player)
    const avgGameStats = drillScores.map((drillScore) => {
      // Filter to only render stats that we we need for each drill type
      const filteredToggleStats = toggleStats.filter((stat) => Object.keys(drillScore).includes(stat.id));
      return {
        id: drillScore.drillId,
        stats: filteredToggleStats.map((option) => {
          // Reset scores and worldScore values before setting initial stats
          return {
            ...option,
            playerScores: [drillScore[option.id].player && !isNaN(drillScore[option.id].player) ? Number(drillScore[option.id].player).toFixed(2) : drillScore[option.id].player],
            worldScore: drillScore[option.id].global && !isNaN(drillScore[option.id].global) ? Number(drillScore[option.id].global).toFixed(2) : drillScore[option.id].global,
          }
        })
      }
    });
    return avgGameStats;
    // toggleStats.forEach((option) => {
    //   // Reset scores and worldScore values before setting initial stats
    //   option.playerScores = [];
    //   option.worldScore = '';
    //   const playerScore = Math.round(playerScores[option.id]);
    //   const worldScore = Math.round(worldAvgScores[option.id]);
    //   option.playerScores.push(playerScore);
    //   option.worldScore = worldScore;
    // });
    // return {toggleStats};
  };

  const getChartData = (drillId, sessionStats) => {
    // Set chart data for selected programme
    const labels = [];
    const scores = [];
    const desiredLength = 10;
    const selectedChartData = sessionStats.find((entry) => Object.keys(entry)[0] === drillId);
    if (!selectedChartData) {
      return {};
    }
    selectedChartData[drillId].scores.forEach(score => {
      labels.push(formatStandardDateString(score.created));
      scores.push(score.score);
    });
    if (labels.length < desiredLength) {
      // Push empty strings into labels array to give it 10 elements to enable correct chart rendering
      const missingLength = desiredLength - labels.length;
      for (let i = 0; i < missingLength; i++) {
        labels.push('');
        scores.push(0);
      }
    }
    // Reverse data arrays to show oldest to newest from left to right in chart
    const reversedLabels = labels.reverse();
    const reversedScores = scores.reverse();
    // Set labels and data
    const avgScore = selectedChartData[drillId].average ? selectedChartData[drillId].average : reversedScores.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    ) / scores.length;
    const chartData = {
      avgColor: colors.comparisonRed,
      avgScore,
      color: colors.secondaryOrange,
      data: reversedScores,
    };
    return {labels: reversedLabels, chartData};
  };

  const updateChartData = (idx, sessionStatsData) => {

    if (!sessionStatsData[0]) {
      return;
    }
    const statsData = sessionStatsData[0].find((stat) => stat.drillId === selectedDrillId) || sessionStatsData[0][0];
    // compareStats?.find((stat) => stat.drillId === selectedDrillId
    const { labels, chartData } = getChartData(
      drillStatsToggleOptions[idx].id,
      statsData.stats
    );

    setDrillSessionStatsData(sessionStatsData);
    setChartLabels(labels);
    setChartData([chartData]);
  };

  const setPlayerCompareStatsData = (playerStats, clubName) => {
    let playerCompareStats = [];
    if (playerStats.length > 0) {
      playerStats.forEach(player => {
        const compareStats = {
          compareStats: player.compareStats,
          playerName: `${player.firstname} ${player.lastname}`,
          clubName,
        };
        playerCompareStats.push(compareStats);
      });
    }
    setCompareStats(playerCompareStats);
  };

  const handlePlayerChange = idx => async e => {
    handleBannerClose();

    // Return if the Please Select option is selected in any dropdown
    if (e.target.value === '') return;
    const {id} = baseContext.baseData.userProfile;
    const rows = getPlayerChoices(playerChoices, idx, e.target.value, id);
    // Ensure duplicates do not exist
    if (duplicateExists(rows)) {
      setError({
        show: true,
        message: 'That player has already been selected',
      });
      return;
    }
    setPlayerChoices(rows);

    setPlayerDataLoading(true);
    const playerStatsId = rows[idx];
    // const latestStats = await getLatestProgrammeStats(rows[0]);
    const profileData = await getClubPlayerProfile(playerStatsId);
    const drillAverageStats = await getDrillSummaryStats(playerStatsId);
    const latestPlayerStats = await getLatestProgrammeStats(playerStatsId)
    const playerStats = createPlayerStatsObject(profileData?.data, drillAverageStats?.data, clubLeaderboard, globalLeaderboard, latestPlayerStats?.data);

    // Set player stats data for relevant dropdown
    const statsList = [...playerStatsList];
    statsList[idx] = playerStats;
    setPlayerStatsList(statsList);

    // Update relevant stats for player one
    if (idx === 0) {
      const toggleOptions = [...drillStatsToggleOptions];
      const avgDrillStats = getDrillAvgGameStats(toggleOptions, playerStats.avgStats);
      setPlayerStats(0, playerStats.drillStats);
      setSelectedPlayer(profileData?.data?.profile);

      // Set drill stats tab and hidden menu options
      setDrillData(playerStats.drillStats);

      // Set data for averages game stats section

      setAvgGameStats(avgDrillStats);

      // Set selectedToggleId to id of first drill stat
      setSelectedToggleId(toggleOptions[0].id);

      // Set chart data for score stat
      const drillStats = await createDrillStatsProgrammes(playerStatsId);
      const sessionStatsData = [drillStats.drillStats ? drillStats.drillStats : drillStats  ];
      updateChartData(0, sessionStatsData);
    }

    // Set player comparison stats
    setPlayerCompareStatsData(statsList, clubName);

    setSelectedDrillId(playerStats.drillStats.length > 0 ? playerStats.drillStats[0].drillId : '');
    setSelectedDrillName(playerStats.drillStats.length > 0 ? playerStats.drillStats[0].drillName : '');

    setPlayerDataLoading(false);
  };

  const handlePlayerAdd = () => {
    if (!playerChoices[playerChoices.length - 1]) return;
    const row = '';
    setPlayerChoices([...playerChoices, row]);
  };

  const handlePlayerReset = async () => {
    // Reset options and set the logged in player as the first choice
    const playerStatsId = playerDropdownOptions.length ? playerDropdownOptions[0].value : '';
    setPlayerChoices([Number(playerStatsId)]);

    setPlayerDataLoading(true);

    // const latestStats = await getLatestProgrammeStats(rows[0]);
    const profileData = await getClubPlayerProfile(playerStatsId);
    const drillAverageStats = await getDrillSummaryStats(playerStatsId);
    const latestPlayerStats = await getLatestProgrammeStats(playerStatsId)
    const playerStats = createPlayerStatsObject(profileData?.data, drillAverageStats?.data, clubLeaderboard, globalLeaderboard, latestPlayerStats?.data);

    // Set player stats data for relevant dropdown
    const statsList = [playerStats];
    // statsList[0] = playerStats;
    setPlayerStatsList(statsList);

    // Update relevant stats for player one
    const toggleOptions = [...drillStatsToggleOptions];
    const avgDrillStats = getDrillAvgGameStats(toggleOptions, playerStats.avgStats);
    setPlayerStats(0, playerStats.drillStats);
    setSelectedPlayer(profileData?.data?.profile);

    // Set drill stats tab and hidden menu options
    setDrillData(playerStats.drillStats);

    // Set data for averages game stats section

    setAvgGameStats(avgDrillStats);

    // Set selectedToggleId to id of first drill stat
    setSelectedToggleId(toggleOptions[0].id);

    // Set chart data for score stat
    const drillStats = await createDrillStatsProgrammes(playerStatsId);
    const sessionStatsData = [drillStats.drillStats ? drillStats.drillStats : drillStats];
    updateChartData(0, sessionStatsData);

    // Set player comparison stats
    setPlayerCompareStatsData(statsList, clubName);

    setSelectedDrillId(playerStats.drillStats.length > 0 ? playerStats.drillStats[0].drillId : '');
    setSelectedDrillName(playerStats.drillStats.length > 0 ? playerStats.drillStats[0].drillName : '');

    setPlayerDataLoading(false);
  };

  const handleDrillChange = drillId => {
    setPlayerStats(drillId, playerStatsList[0].drillStats);
  };

  const handleShareClick = async () => {
    await setShareLoading(true);

    setError({
      show: false,
      message: 'Request successfully submitted',
    });
    setShareLoading(false);
  };

  const handleToggleChange = (id) => {
    setSelectedToggleId(id);

    // Set chart data for index programme
    const statsData = drillSessionStatsData[0].find((stat) => stat.drillId === selectedDrillId) || drillSessionStatsData[0][0];

    const { labels, chartData } = getChartData(
      id,
      statsData.stats
    );
    setChartLabels(labels);
    setChartData([chartData]);
  };

  const handleSessionsRangeChangePrev = async () => {
    setChartDataLoading(true);
    setDisablePrev(true);
    setDisableNext(true);
    setChartDataLoading(true);
    const updatedChartIndex = chartIndex + 10;

    // Do we have this in cache?
    const cachedData = cachedChartData.find((data) => data.chartIndex === updatedChartIndex)
    if (cachedData && cachedData.chartData) {
      updateChartData(0, cachedData.chartData);
    } else {
      const updatedSessionsData = await fetchDrillStatsProgrammes(playerChoices[0], chartAllProgrammeSessions, updatedChartIndex);
      cachedChartData.push({ chartIndex: updatedChartIndex, chartData: [updatedSessionsData] })
      setCachedChartData([...cachedChartData]);
      const sessionStatsData = [updatedSessionsData];
      updateChartData(0, sessionStatsData);
    }
    setChartIndex(updatedChartIndex)
    setDisablePrev(chartAllProgrammeSessions.length < updatedChartIndex + 10);
    setDisableNext(false);
    setChartDataLoading(false);
  };

  const handleSessionsRangeChangeNext = async () => {
    setChartDataLoading(true);
    setDisablePrev(true);
    setDisableNext(true);
    setChartDataLoading(true);
    const updatedChartIndex = chartIndex - 10;

    // Do we have this in cache?
    const cachedData = cachedChartData.find((data) => data.chartIndex === updatedChartIndex)
    if (cachedData && cachedData.chartData) {
      updateChartData(0, cachedData.chartData);
    } else {
      const updatedSessionsData = await fetchDrillStatsProgrammes(playerChoices[0], chartAllProgrammeSessions, updatedChartIndex);
      cachedChartData.push({ chartIndex: updatedChartIndex, chartData: [updatedSessionsData] })
      setCachedChartData([...cachedChartData]);
      const sessionStatsData = [updatedSessionsData];
      updateChartData(0, sessionStatsData);
    }

    setChartIndex(updatedChartIndex);
    setDisablePrev(false);
    setDisableNext(updatedChartIndex === 0);
    setChartDataLoading(false);
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.indexDrillStats}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <Title
            text={'Drill Stats'}
          />
          <PlayersDropdownSelects
            choices={playerChoices}
            dropdownOptions={playerDropdownOptions}
            handleChange={handlePlayerChange}
            handleAdd={handlePlayerAdd}
            handleReset={handlePlayerReset}
          />
          {
            playerDataLoading &&
            <SectionSpinner/>
          }
          {
            !playerDataLoading &&
            <>
              {
                drillTabOptions.length > 0 &&
                <DrillTabsAndMenu
                  tabOptions={drillTabOptions}
                  menuOptions={drillMenuOptions}
                  selected={selectedDrillId}
                  handleChange={handleDrillChange}
                />
              }
              <StyledSectionContainer>
                {playerDropdownOptions.length > 0 &&  <SectionContainer
                  cardTitle={`Player Card - ${selectedDrillName}`}
                >
                  {
                    playerStatsList.length > 0 &&
                    <PlayerCardStats
                      playerImage={playerStatsList[0].picture}
                      playerName={`${selectedPlayer.firstname} ${selectedPlayer.lastname}`}
                      playerStats={playerCardStats}
                      onShareClick={handleShareClick}
                      shareLoading={shareLoading}
                    />
                  }
                </SectionContainer> }
              </StyledSectionContainer>
              <StyledSectionContainer>
                <TitleWithLegend
                  title={'Average Game Stats'}
                  iconColor={colors.primaryGreen}
                  labelOne={'Player'}
                  labelTwo={'World'}
                />
                <CustomToggleGroup
                  options={avgGameStats && avgGameStats.length ? avgGameStats.find((stat) => stat.id === selectedDrillId)?.stats: []}
                  value={selectedToggleId}
                  onChange={handleToggleChange}
                  singlePlayerScore
                />
              </StyledSectionContainer>
              <StyledSectionContainer>
                <SectionTitleWithArrows
                  title={'Last 10 games played'}
                  handleRangeChangePrev={handleSessionsRangeChangePrev}
                  handleRangeChangeNext={handleSessionsRangeChangeNext}
                  disablePrev={disablePrev}
                  disableNext={disableNext}
                  loading={chartDataLoading}
                />
                <SectionContainer>
                  <BarChartContainer
                    id={'drillsChartContainer'}
                  >
                    <BarChart
                      loading={chartDataLoading}
                      id={'drills'}
                      labels={chartLabels}
                      data={chartData}
                      isDrillStats
                    />
                  </BarChartContainer>
                  <BarChartLegend
                    data={chartData}
                    isDrills
                  />
                </SectionContainer>
              </StyledSectionContainer>
              {

                playerDropdownOptions.length > 0 && <StyledSectionContainer>
                  <SectionTitle
                    text={'Compare'}
                  />
                  <ComparisonCardGroup
                    data={[...compareStats].map((compareStat) => {
                      return {
                        ...compareStat,
                        ...compareStat.compareStats?.find((stat) => stat.drillId === selectedDrillId)
                      };
                    })}
                  />
                </StyledSectionContainer>
              }

            </>
          }
        </MainWrapper>
      }
    </>
  );
};

export default DrillStats;
