import {useState} from 'react';
import {
  StyledTable,
  LargeTableBody,
  StyledTableRow,
  StyledTableCell,
  DeviceText,
  DeviceName,
} from './SquadDevicesList.styles';
import {squadDeviceColumns} from '../../../options/tableColumns';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import CustomTableHead from '../CustomTableHead/CustomTableHead';
import IconButton from '../../Buttons/IconButton/IconButton';
import DeleteText from '../../Typography/DeleteText/DeleteText';
import {DeleteOutlined} from '@mui/icons-material';
import StandardModal from '../../Modals/StandardModal/StandardModal';

// Helpers
import {getComparator, stableSort, handleRequestSort} from '../../../utils/tableSortHelpers/tableSortHelpers';
import { formatStandardDateString } from '../../../utils/helpers/formatDateString';

const SquadDevicesList = ({data}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [deleteDevice, setDeleteDevice] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [mainDeviceModalOpen, setMainDeviceModalOpen] = useState(false);
  const [newMainDevice, setNewMainDevice] = useState({});
  const existingMainDevice = data ? data.find((device) => device.mainDevice) : {};

    const handleDeleteModalOpen = id => {
    const index = data.findIndex(el => el.id === id);
    setDeleteDevice(data[index]);
    setDeleteModalOpen(true);
    };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setDeleteDevice({});
  };

  const handleDeleteModalSubmit = async () => {
    // TODO: Incorporate delete api call
    // const result = await handleDelete(deleteDevice.id);
    // if (result) {
    //   handleDeleteModalClose();
    // }
  };

  // const handleMainDeviceToggle = (event) => {
  //   const value = event.target.checked;
  //   if (value === false) {
  //     // We do not let the user unselect the main device as there always has to be one selected
  //     return;
  //   }
  //   // Show Main device modal.
  //   const index = data.findIndex(el => el.id === event.target.id);
  //   setNewMainDevice(data[index]);
  //   setMainDeviceModalOpen(true);
  // }


  const handleMainDeviceModalCLose = () => {
    setMainDeviceModalOpen(false);
    setNewMainDevice({});
  };

  return (
    <>
      <CustomTableWrapper
        noResultsText={(!data || data.length === 0) && 'No details to display'}
        fullHeight
      >
        <StyledTable>
          <CustomTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(e, id) => handleRequestSort(e, id, orderBy, order, setOrder, setOrderBy)}
            columnHeadings={squadDeviceColumns}
            small
          />
          <LargeTableBody>
            {
              data.length > 0 &&
              stableSort(data, getComparator(order, orderBy))
                .map(({id, label, created, mainDevice}) => (
                  <StyledTableRow
                    key={id}
                  >
                    <StyledTableCell>
                      {label}
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatStandardDateString(created)}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      <CustomToggle
                        id={id.toString()}
                        checked={!!mainDevice}
                        onChange={handleMainDeviceToggle}
                      />
                    </StyledTableCell> */}
                    <StyledTableCell>
                      <IconButton
                        icon={<DeleteOutlined/>}
                        onClick={() => handleDeleteModalOpen(id)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            }
          </LargeTableBody>
        </StyledTable>
      </CustomTableWrapper>
      {
        deleteModalOpen &&
        <StandardModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          title={'Delete Squad Device'}
          btnText={'Delete Device'}
          onClick={handleDeleteModalSubmit}
        >
          <DeleteText text={`Are you sure you want to delete ${deleteDevice?.label}? Once confirmed, this action cannot be undone.`} />
        </StandardModal>
      }
      {
        mainDeviceModalOpen &&
        <StandardModal
          open={mainDeviceModalOpen}
          onClose={handleMainDeviceModalCLose}
          title={'Main Device Request'}
          btnText={'Confirm'}
          onClick={handleMainDeviceModalCLose}
        >
          <DeviceText>Device Name</DeviceText>
          <DeviceName>{newMainDevice?.name}</DeviceName>
          <DeviceName>{`Are you sure you want this device to be the main one? Doing so will replace the existing device (${existingMainDevice?.label}) as the main one.`}</DeviceName>
        </StandardModal>
      }
    </>
  );
};

export default SquadDevicesList;
