import {
  styled,
  Menu,
  MenuItem,
} from '@mui/material';
import {colors} from '../../../options/colors';
import {StyledButton} from '../../Buttons/IconButton/IconButton.styles';
import {MoreVert} from '@mui/icons-material';

export const StyledIcon = styled(MoreVert)(() => ({}));

export const StyledMenu = styled(Menu)(() => ({
  '& .MuiSvgIcon-root': {
    marginRight: 10,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.body1,
  '&.Mui-selected': {
    backgroundColor: `${colors.secondaryGrey} !important`,
    color: colors.secondaryOrange,
    '&:hover': {
      backgroundColor: `${colors.secondaryGrey} !important`,
    }
  },
  '&:hover': {
    backgroundColor: `${colors.secondaryGrey} !important`,
  }
}));

export {
  StyledButton,
};
