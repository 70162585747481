import {useEffect, useContext, useState} from 'react';
import {useMediaQuery} from '@mui/material';
import {
  SectionWrapper,
  ContainerWrapper,
  StatsWrapper,
  StatsContainer,
  DesktopAvatarContainer,
  MobileAvatarContainer,
  ChartContainer,
  ChartLegendContainer,
  LegendLabelContainer,
  BestScoreIcon,
  AvgScoreIcon,
  LegendText,
  AccordionWrapper,
  ButtonContainer,
} from './IndexPanel.styles';
import {playerStatsOptions, clubStatsOptions} from '../../../options/indexStatsOptions';
import {indexAccordionOptions} from '../../../options/indexAccordionOptions';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import SectionContainer from '../../../components/Containers/SectionContainer/SectionContainer';
import NoResultsText from '../../../components/Typography/NoResultsText/NoResultsText';
import NameAvatar from '../../../components/Avatars/NameAvatar/NameAvatar';
import StatsList from '../../../components/Tables/StatsList/StatsList';
import IndexAccordion from '../../../components/Accordions/IndexAccordion/IndexAccordion';
import TriggerButton from '../../../components/Buttons/TriggerButton/TriggerButton';
import ClubTopTenList from '../../../components/Tables/ClubTopTenList/ClubTopTenList';
import ClubBestDrillsList from '../../../components/Tables/ClubBestDrillsList/ClubBestDrillsList';
import IndexRadarChart from '../../../components/Charts/IndexRadarChart/IndexRadarChart';

// Contexts
import {BaseContext} from '../../../providers/Global/BaseProvider';
import {PanelContext} from '../../../providers/Index/PanelProvider';
import { routes } from '../../../options/routes';

const IndexPanel = () => {
  // Collapse accordions when matches value changes
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const baseContext = useContext(BaseContext);
  const panelContext = useContext(PanelContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [topPlayerStats, setTopPlayerStats] = useState({});
  const [playerChartAvgData, setPlayerChartAvgData] = useState([]);
  const [playerChartBestData, setPlayerChartBestData] = useState([]);
  const [clubChartData, setClubChartData] = useState([]);
  const [overallIndexStats, setOverallIndexStats] = useState({});
  const [topTenPlayers, setTopTenPlayers] = useState([]);
  const [bestDrills, setBestDrills] = useState([]);
  const [expandPlayer, setExpandPlayer] = useState(false);
  const [expandOverall, setExpandOverall] = useState(false);
  const [error, setError] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    if (!baseContext.baseData.loading && !panelContext.panelData.loading) {
      setDataLoading(false);
      if (panelContext.panelData.apiError) {
        setError({
          show: true,
          message: 'Unable to load some or all page content. Please refresh and try again.'
        });
      }

      // Get data from panelContext
      const {bestPlayer, overallIndex, topTenPlayers, bestDrills} = panelContext.panelData;

      // Remove heart rate from indexAccordionOptions
      const heartRateIndex = indexAccordionOptions.findIndex(({statId}) => statId === 'heartRate');
      if (heartRateIndex > 0) indexAccordionOptions.splice(heartRateIndex, 1);

      // Set player general and chart stats
      if (bestPlayer) {
        setTopPlayerStats(bestPlayer);
        let avgData = [], bestData = [];
        indexAccordionOptions.forEach(({statId}) => {
          avgData.push(Math.round(bestPlayer.avgScore[statId]));
          bestData.push(Math.round(bestPlayer.bestScore[statId]));
        });
        setPlayerChartAvgData(avgData);
        setPlayerChartBestData(bestData);
      }

      // Set club overall index general and chart stats - add club photo and name to data
      if (overallIndex) {
        const {logo, name} = baseContext.baseData.clubProfile;
        const indexStats = {
          ...overallIndex,
          logo,
          name
        };

        setOverallIndexStats(indexStats);
        let clubData = [];
        indexAccordionOptions.forEach(({statId}) => {
          clubData.push(Math.round(overallIndex.scores[statId]));
        });

        setClubChartData(clubData);
      }

      // Set top ten players stats
      if (topTenPlayers) setTopTenPlayers(topTenPlayers);

      // Set best drills stats
      if (bestDrills) setBestDrills(bestDrills);
    }
    // Set auto-renew on page load
  }, [baseContext, panelContext]);

  useEffect(() => {
    // Collapse both accordion panels when resizing screen from large to md and vice-versa
    setExpandPlayer(false);
    setExpandOverall(false);
  }, [matches]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handlePlayerCollapseClick = () => {
    setExpandPlayer(!expandPlayer);
  };

  const handleOverallCollapseClick = () => {
    setExpandOverall(!expandOverall);
  };

  const handleDualCollapseClick = () => {
    // Hide/expand both accordion panels on click
    handlePlayerCollapseClick();
    handleOverallCollapseClick();
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.indexPanel}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <Title
            text={'Index Panel'}
          />
          <SectionWrapper>
            <ContainerWrapper>
              <SectionContainer
                cardTitle={'Your best performing player'}
              >
                {
                  Object.keys(topPlayerStats).length > 0 &&
                  <>
                    <StatsWrapper>
                      <StatsContainer>
                        <DesktopAvatarContainer>
                          <NameAvatar
                            image={topPlayerStats.picture}
                            name={`${topPlayerStats.name}`}
                          />
                        </DesktopAvatarContainer>
                        <StatsList
                          options={playerStatsOptions}
                          data={topPlayerStats}
                        />
                      </StatsContainer>
                      <StatsContainer>
                        <MobileAvatarContainer>
                          <NameAvatar
                            image={topPlayerStats.picture}
                            name={`${topPlayerStats.name}`}
                          />
                        </MobileAvatarContainer>
                        <ChartContainer
                          id={'playerChartContainer'}
                        >
                          <IndexRadarChart
                            loading={dataLoading}
                            id={'player'}
                            firstDataset={playerChartAvgData}
                            secondDataset={playerChartBestData}
                          />
                        </ChartContainer>
                        <ChartLegendContainer>
                          <LegendLabelContainer>
                            <BestScoreIcon/>
                            <LegendText>
                              Best Score
                            </LegendText>
                          </LegendLabelContainer>
                          <LegendLabelContainer>
                            <AvgScoreIcon/>
                            <LegendText>
                              Avg. Score
                            </LegendText>
                          </LegendLabelContainer>
                        </ChartLegendContainer>
                      </StatsContainer>
                    </StatsWrapper>
                    <AccordionWrapper>
                      <IndexAccordion
                        id='playerStatsAccordion'
                        data={topPlayerStats.avgScore}
                        expanded={expandPlayer}
                        onClick={handlePlayerCollapseClick}
                      />
                    </AccordionWrapper>
                  </>
                }
                {
                  Object.keys(topPlayerStats).length === 0 &&
                  <NoResultsText
                    text={'No details to display'}
                  />
                }
              </SectionContainer>
            </ContainerWrapper>
            <ContainerWrapper>
              <SectionContainer
                cardTitle={'Your club\'s overall index'}
              >
                {
                  Object.keys(overallIndexStats).length > 0 &&
                  <>
                    <StatsWrapper>
                      <StatsContainer>
                        <DesktopAvatarContainer>
                          <NameAvatar
                            image={overallIndexStats.logo}
                            name={overallIndexStats.name}
                          />
                        </DesktopAvatarContainer>
                        <StatsList
                          options={clubStatsOptions}
                          data={overallIndexStats}
                        />
                      </StatsContainer>
                      <StatsContainer>
                        <MobileAvatarContainer>
                          <NameAvatar
                            image={overallIndexStats.logo}
                            name={overallIndexStats.name}
                          />
                        </MobileAvatarContainer>
                        <ChartContainer
                          id={'clubChartContainer'}
                        >
                          <IndexRadarChart
                            loading={dataLoading}
                            id={'club'}
                            firstDataset={clubChartData}
                          />
                        </ChartContainer>
                      </StatsContainer>
                    </StatsWrapper>
                    <AccordionWrapper>
                      <IndexAccordion
                        id='clubStatsAccordion'
                        data={overallIndexStats.scores}
                        expanded={expandOverall}
                        onClick={handleOverallCollapseClick}
                        // hideHeartRate
                      />
                    </AccordionWrapper>
                  </>
                }
                {
                  Object.keys(overallIndexStats).length === 0 &&
                  <NoResultsText
                    text={'No details to display'}
                  />
                }
              </SectionContainer>
            </ContainerWrapper>
          </SectionWrapper>
          {
            Object.keys(topPlayerStats).length > 0 &&
            Object.keys(overallIndexStats).length > 0 &&
            <ButtonContainer>
              <TriggerButton
                text={`${expandPlayer && expandOverall ? 'Collapse' : 'Expand'} All` }
                onClick={handleDualCollapseClick}
              />
            </ButtonContainer>
          }
          <SectionWrapper
            id={'tablesWrapper'}
          >
            <ContainerWrapper>
              <SectionContainer
                sectionTitle={'Rezzil Index - Club top 10 players'}
              >
                <ClubTopTenList
                  data={topTenPlayers}
                />
              </SectionContainer>
            </ContainerWrapper>
            <ContainerWrapper
              id={'bestDrills'}
            >
              <SectionContainer
                sectionTitle={'Your club best drills'}
              >
                <ClubBestDrillsList
                  data={bestDrills}
                />
              </SectionContainer>
            </ContainerWrapper>
          </SectionWrapper>
        </MainWrapper>
      }
    </>
  );
};

export default IndexPanel;
