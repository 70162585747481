import {useEffect, useContext, useState} from 'react';
import {
  SearchContainer,
} from './RepCompanyManagement.styles';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import SectionTitle from '../../../components/Typography/SectionTitle/SectionTitle';
import CustomSearchField from '../../../components/Inputs/CustomSearchField/CustomSearchField';
import RepCompaniesList from '../../../components/Tables/RepCompaniesList/RepCompaniesList';

// Contexts
import {BaseContext} from '../../../providers/Global/BaseProvider';
import { RepCompaniesContext } from '../../../providers/Admin/RepCompaniesProvider';
// Validators & helpers
import emailIsValid from '../../../utils/validation/email';
import phoneNumberIsValid from '../../../utils/validation/phoneNumber';
import {debounce} from 'debounce';

// API Calls
import {addRepCompany, updateRepCompany} from '../../../api';
import { routes } from '../../../options/routes';

const RepCompanyManagement = () => {
  const baseContext = useContext(BaseContext);
  const repCompaniesContext = useContext(RepCompaniesContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [repCompanyLoading, setRepCompanyLoading] = useState(false);
  const [repCompaniesList, setRepCompaniesList] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: '',
  });
  const [searchData, setSearchData] = useState({
    repCompanySearch: '',
  });
  const initialRepCompanyState = {
    name: '',
    contact: '',
    telephoneCountryCode: '',
    telephone: '',
    location: '',
    addr1: '',
    addr2: '',
    addr3: '',
    city: '',
    postcode: '',
    safe_zone_radius: '0.74',
    colour:'#ffffff',
  }

  const initialRepCompanyErrors = {
    name: '',
    contact: '',
    telephoneCountryCode: '',
    telephone: '',
    location: '',
    addr1: '',
    addr2: '',
    addr3: '',
    city: '',
    postcode: '',
    safe_zone_radius: '',
    colour:'',
  }
  const [searchResults, setSearchResults] = useState([]);
  const [repCompanyData, setRepCompanyData] = useState(initialRepCompanyState);
  const [repCompanyErrors, setRepCompanyErrors] = useState(initialRepCompanyErrors);

  useEffect(() => {
    if (!baseContext.baseData.loading && !repCompaniesContext.repCompaniesData.loading) {
      setDataLoading(false);
      setRepCompaniesList(repCompaniesContext.repCompaniesData.repCompanies);
    }
    // Set auto-renew on page load
  }, [baseContext, repCompaniesContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handleSearchChange = e => {
    let data = {...searchData};
    data[e.target.id] = e.target.value;
    setSearchData(data);

    // Ensure all data is in lowercase for comparison logic to work correctly
    handleSearchSubmit(data.clubSearch.toLowerCase());
  };

  const handleSearchSubmit = searchTerm => {
    // Filter results with name, email, phone or location matching searchTerm
    const results = repCompaniesList.filter(
      repCompany =>
        repCompany.name.toLowerCase()?.includes(searchTerm) ||
        repCompany.contact.toLowerCase()?.includes(searchTerm) ||
        repCompany.telephone?.includes(searchTerm) ||
        repCompany.location.toLowerCase()?.includes(searchTerm)
    );
    setSearchResults(results);
  };

  const handleSearchClear = () => {
    setSearchData({
      repCompanySearch: '',
    });
    setSearchResults([]);
  };

  const handleClubChange = (e, id, val) => {
    // Clear errors
    setRepCompanyErrors(initialRepCompanyErrors);
    handleBannerClose();

    // Update repData
    let data = {...repCompanyData};
    if (e && id) {
      // For autocomplete field
      data[id] = val;
    } else {
      data[e.target.id] = e.target.value;
    }
    setRepCompanyData(data);
  };

  const handleClubSubmit = async () => {
    await setRepCompanyLoading(true);
    // Set form errors
    let formErrors = {...repCompanyErrors};
    let hasErrors = false;
    if(!repCompanyData.name) {
      formErrors = {
        ...formErrors,
        name: 'Name is required'
      };
      hasErrors = true;
    }
    if (!emailIsValid(repCompanyData.contact)) {
      formErrors = {
        ...formErrors,
        contact: 'A valid Contact email is required'
      };
      hasErrors = true;
    }
    if (!phoneNumberIsValid(repCompanyData.telephone)) {
      formErrors = {
        ...formErrors,
        telephone: 'A valid Phone number is required'
      };
      hasErrors = true;
    }
    if (!repCompanyData.telephoneCountryCode) {
      formErrors = {
        ...formErrors,
        telephoneCountryCode: 'Country Code is required'
      };
      hasErrors = true;
    }
    if (!repCompanyData.country) {
      formErrors = {
        ...formErrors,
        country: 'Country is required'
      };
      hasErrors = true;
    }
    if (!repCompanyData.addr1) {
      formErrors = {
        ...formErrors,
        addr1: 'Address is required'
      };
      hasErrors = true;
    }
    if (!repCompanyData.city) {
      formErrors = {
        ...formErrors,
        city: 'City is required'
      };
      hasErrors = true;
    }
    if (!repCompanyData.postcode) {
      formErrors = {
        ...formErrors,
        postcode: 'Postcode is required'
      };
      hasErrors = true;
    }
    // Set errors and return if there are errors
    setRepCompanyErrors(formErrors);
    if (hasErrors) {
      setRepCompanyLoading(false);
      return false;
    }

    // Trigger API call
    const { id, location, ...dataToSave } = repCompanyData;
    const repCompanySaveResponse = id ? await updateRepCompany(id, dataToSave) : await addRepCompany(repCompanyData);

    if (!repCompanySaveResponse || !repCompanySaveResponse.success || repCompanySaveResponse.code !== 200) {
      setRepCompanyLoading(false);
      setRepCompanyErrors({
        saveError: 'There was an error with the request. Please check email and phone number are unique.',
      });
      return;
    }

    setError({
      ...error,
      message: 'Rep Company has been successfully added',
    });
    await repCompaniesContext.getRepCompaniesData();
    setRepCompanyData(initialRepCompanyState);
    setRepCompanyLoading(false);
    return true;
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.repCompanyManagement}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <Title
            text={'Rep Company Management'}
          />
          <SectionTitle
            text={'Rep Companies'}
          />
          <SearchContainer>
            <CustomSearchField
              id={'repCompanySearch'}
              onChange={debounce(handleSearchChange, 350)}
              onClear={handleSearchClear}
              value={searchData.repCompanySearch}
            />
          </SearchContainer>
          <RepCompaniesList
            repCompanies={searchData.repCompanySearch ? searchResults : repCompaniesList}
            onFieldChange={handleClubChange}
            onSubmit={handleClubSubmit}
            formData={repCompanyData}
            formErrors={repCompanyErrors}
            setFormData={setRepCompanyData}
            setFormErrors={setRepCompanyErrors}
            updateLoading={repCompanyLoading}
          />
        </MainWrapper>
      }
    </>
  );
};

export default RepCompanyManagement;
