import {useState, useEffect} from 'react';
import {
  Container,
  InlineFieldContainer,
  FieldContainer,
} from './PlayerProfileForm.styles';
import {nationalities} from '../../../options/nationalities';
import {
  playerRoleOptions,
  playerPositionOptions,
  dominantFootOptions,
  shoeSizeTypeOptions,
  genderOptions,
} from '../../../options/playerOptions';
import CustomTextField from '../../Inputs/CustomTextField/CustomTextField';
import CustomDateInput from '../../Inputs/CustomDateInput/CustomDateInput';
import CustomAutocomplete from '../../Inputs/CustomAutocomplete/CustomAutocomplete';
import Label from '../../Typography/Label/Label';
import CustomCheckboxGroup from '../../Inputs/CustomCheckboxGroup/CustomCheckboxGroup';
import {debounce} from 'debounce';

// Helpers
import cloneData from '../../../utils/helpers/cloneData';

const PlayerProfileForm = ({ formData, errors, onChange, setNestedOpen, checkboxOptions}) => {
  const [groupsCheckboxOptions, setGroupsCheckboxOptions] = useState([]);

  useEffect(() => {
    if (checkboxOptions && checkboxOptions.length > 0) {
      // Set checked status of each checkbox in the list based on whether group id appears in user's groups list
      // Deep clone checkboxOptions so original data remains unchanged - allows resetting values on form load
      let options = cloneData(checkboxOptions);
      if (formData.profile_groups && formData.profile_groups.length > 0) {
        options.forEach(option => {
          if (formData.profile_groups.includes(option.id)) option.checked = true;
        });
      }
      setGroupsCheckboxOptions(options);
    }
  }, [checkboxOptions, formData.profile_groups]);

  const handleCheckboxChange = e => {
    // Update checked status of relevant option on change before calling onChange function
    groupsCheckboxOptions.forEach(option => {
      if (e.target.id === option.id) option.checked = e.target.checked;
    });
    onChange(e);
  };

  return (
    <Container>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'First name'}
            id={'firstname'}
            placeholder={'Joe'}
            required
            onChange={onChange}
            error={errors.firstname}
            type={'text'}
            value={formData.firstname}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Last name'}
            id={'lastname'}
            placeholder={'Bloggs'}
            required
            onChange={onChange}
            error={errors.lastname}
            type={'text'}
            value={formData.lastname}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Email'}
            id={'email'}
            placeholder={'email@email.com'}
            onChange={onChange}
            error={errors.email}
            type={'email'}
            value={formData.email}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomDateInput
            label={'DOB'}
            id={'dob'}
            required
            onChange={onChange}
            error={errors.dob}
            value={formData.dob}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <FieldContainer>
        <CustomAutocomplete
          label={'Role'}
          id={'profile_role_id'}
          placeholder={'Select Role'}
          onChange={onChange}
          error={errors.profile_role_id}
          value={formData.profile_role_id}
          options={playerRoleOptions}
        />
      </FieldContainer>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomAutocomplete
            label={'Position'}
            id={'position'}
            placeholder={'Select Position'}
            required
            onChange={onChange}
            error={errors.position}
            value={formData.position}
            options={playerPositionOptions}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomAutocomplete
            label={'Dominant'}
            id={'dominant_foot'}
            placeholder={'Select Dominant Foot'}
            onChange={onChange}
            error={errors.dominant_foot}
            value={formData.dominant_foot}
            options={dominantFootOptions}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomAutocomplete
            label={'Shoe size type'}
            id={'shoe_size_type'}
            placeholder={'Select Shoe Size Type'}
            required
            onChange={onChange}
            error={errors.shoe_size_type}
            value={formData.shoe_size_type}
            options={shoeSizeTypeOptions}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Shoe size'}
            id={'shoe_size'}
            placeholder={'Shoe Size'}
            required
            onChange={debounce(onChange, 350)}
            error={errors.shoe_size}
            type={'number'}
            value={formData.shoe_size}
            onBlur={() => setNestedOpen(true)}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomAutocomplete
            label={'Gender'}
            id={'gender'}
            placeholder={'Select Gender'}
            required
            onChange={onChange}
            error={errors.gender}
            value={formData.gender}
            options={genderOptions}
          />
        </FieldContainer>
        <FieldContainer
          id={'nationalityContainer'}
        >
          <CustomAutocomplete
            label={'Nationality'}
            id={'nationality'}
            placeholder={'Select Nationality'}
            required
            onChange={onChange}
            error={errors.nationality}
            value={formData.nationality}
            options={nationalities}
          />
        </FieldContainer>
      </InlineFieldContainer>
      {
        groupsCheckboxOptions && groupsCheckboxOptions.length > 0 &&
        <>
          <Label
            text={'Groups'}
          />
          <CustomCheckboxGroup
            options={groupsCheckboxOptions}
            onChange={handleCheckboxChange}
          />
        </>
      }
    </Container>
  )
};

export default PlayerProfileForm;
