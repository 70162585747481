import {StyledButton} from './LinkButton.styles';
import {AddBoxOutlined} from '@mui/icons-material';

const LinkButton = ({text, onClick, isAdd, isReset, small, disabled}) => {
  return (
    <StyledButton
      variant='text'
      onClick={onClick}
      startIcon={isAdd ? <AddBoxOutlined/> : undefined}
      add={isAdd ? 'true' : undefined}
      reset={isReset ? 'true' : undefined}
      small={small ? 'true' : undefined}
      disabled={disabled}
    >
      {text}
    </StyledButton>
  );
};

export default LinkButton;
