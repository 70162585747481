import {
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from '@mui/material';
import {colors} from '../../../options/colors';
import {ExpandMore} from '@mui/icons-material';

export const StyledAccordion = styled(Accordion)(() => ({
  borderRadius: '5px !important',
  boxShadow: 'none',
  overflow: 'hidden',
  background: colors.menuPrimaryBlack,
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ index, expanded }) => ({
  height: 58,
  background: index ? colors.primaryGreen : colors.rezzilPlayerBlue,
  borderRadius: expanded ? '5px 5px 0 0' : 5,
  textAlign: 'center',
  '& .MuiAccordionSummary-content' : {
    justifyContent: 'center',
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ index, disabled }) => ({
  background: disabled ? colors.disabledBlack : index ? colors.secondaryGreen : colors.secondaryBlue,
  borderRadius: '0 0 5px 5px',
  padding: 10,
}));

export const StyledAccordionIcon = styled(ExpandMore)(() => ({
  color: colors.white,
}));

export const StyledAccordionLink = styled(Link)(() => ({
  width: '100%',
  height: '100%',
  color: colors.white,
}));
