import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down(450)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export const ToggleLegendContainer = styled('div')(() => ({
  display: 'flex',
  marginBottom: 15,
}));

export const ToggleLabelContainer = styled('div')(() => ({
  display: 'flex',
  '&:first-of-type': {
    marginRight: 30,
  },
}));

export const ColorIcon = styled('div')(({ color }) => ({
  height: 14,
  width: 14,
  borderRadius: 8,
  background: color ? color : colors.primaryGrey,
  marginRight: 10,
}));

export const LegendText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: colors.primaryGrey,
  textTransform: 'uppercase',
}));
