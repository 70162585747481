import {
  DetailsContainer,
} from './RepsDetails.styles';
import ReadOnlyField from '../../../components/Inputs/ReadOnlyField/ReadOnlyField';

const RepsDetails = ({rep}) => {
  return (
    <DetailsContainer>
      <ReadOnlyField
        label={'Name'}
        value={rep.name}
      />
      <ReadOnlyField
        label={'Contact'}
        value={rep.email}
      />
      <ReadOnlyField
        label={'Phone'}
        value={`+${rep.telephoneCountryCode} ${rep.telephone}`}
      />
      <ReadOnlyField
        label={'Location'}
        value={rep.location}
      />
      {/* <ReadOnlyField
        label={'Clubs signed'}
        value={rep.clubsSigned}
      />
      <ReadOnlyField
        label={'Monthly sales'}
        value={rep.monthlySales}
      />
      <ReadOnlyField
        label={'Rep revenue'}
        value={rep.revenue}
      /> */}
    </DetailsContainer>
  );
};

export default RepsDetails;
