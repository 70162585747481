export const playerRoleOptions = [
  {
    value: '1',
    label: 'Player',
  },
  {
    value: '2',
    label: 'Coach',
  },
  {
    value: '3',
    label: 'Physio',
  },
  {
    value: '4',
    label: 'Staff',
  },
  {
    value: '5',
    label: 'Other',
  },
];

export const playerPositionOptions = [
  {
    value: '0',
    label: '--',
  },
  {
    value: '1',
    label: 'Goalkeeper',
  },
  {
    value: '2',
    label: 'Right Full Back',
  },
  {
    value: '3',
    label: 'Left Full Back',
  },
  {
    value: '4',
    label: 'Right Half Back',
  },
  {
    value: '5',
    label: 'Centre Half Back',
  },
  {
    value: '6',
    label: 'Left Half Back',
  },
  {
    value: '7',
    label: 'Outside Right',
  },
  {
    value: '8',
    label: 'Inside Right',
  },
  {
    value: '9',
    label: 'Centre Forward',
  },
  {
    value: '10',
    label: 'Inside Left',
  },
  {
    value: '11',
    label: 'Outside Left',
  },
];

export const dominantFootOptions = [
  {
    value: 'l',
    label: 'Left',
  },
  {
    value: 'r',
    label: 'Right',
  },
];

export const shoeSizeTypeOptions = [
  {
    value: 'uk',
    label: 'UK',
  },
  {
    value: 'eu',
    label: 'EU (Male)',
  },
  {
    value: 'fe',
    label: 'EU (Female)',
  },
  {
    value: 'us',
    label: 'US (Male)',
  },
  {
    value: 'fu',
    label: 'US (Female)',
  },
];

export const genderOptions = [
  {
    value: 'm',
    label: 'Male',
  },
  {
    value: 'f',
    label: 'Female',
  },
  {
    value: 'o',
    label: 'Other',
  },
  {
    value: 'u',
    label: 'Prefer not to say',
  },
];
