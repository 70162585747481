import {styled} from '@mui/material';

export const DetailsContainer = styled('div')(({ theme }) => ({
  marginTop: -40,
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
  '& > div': {
    width: 200,
    overflowX: 'clip',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
      width: '100%',
      flexWrap: 'wrap',
    },
  },
}));
