import { useEffect, useState, useCallback, } from 'react';
import format from 'date-fns/format'
import { ShareContainer, ContainerWrapper, Timestamp } from './Share.styles';
import { useParams } from 'react-router-dom';
import LeaderBoardList from '../../components/Tables/LeaderBoardList/LeaderBoardList';
import SectionContainer from '../../components/Containers/SectionContainer/SectionContainer';
import { getSquadLeaderBoardData } from '../../utils/helpers/leaderboardsHelpers';
import { capitaliseUserRole } from '../../utils/helpers/userRoleHelper';


const Share = ({ displayForTV }) => {
  const [shareTableData, setShareTableData] = useState([]);
  const [shareTableName, setShareTableName] = useState();
  const [lastUpdated, setLastUpdated] = useState();
  const { drillId, userIds, squadId } = useParams();

  const fetchData = useCallback(async () => {
    const userIdArray = userIds ? decodeURIComponent(userIds).split(',') : [];
    const leaderboardData = await getSquadLeaderBoardData({ playerChoicesIds: userIdArray, leaderboardName: drillId, squadId });
    setShareTableData(leaderboardData.scores);
    setShareTableName(capitaliseUserRole(leaderboardData.name.replaceAll('-', ' ')));
    setLastUpdated(format(Date.now(), 'dd/MM/yyyy k:mm:ss'));
  }, [drillId, userIds]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const MINUTE_MS = 60000;
  // Repolling the leaderboard data every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, MINUTE_MS * 5);

    return () => clearInterval(interval);
  }, [fetchData])

  return (<ShareContainer>
    {shareTableName &&
      <ContainerWrapper
        key={shareTableName}
        datalength={10}
        displayForTV={displayForTV}
      >
        <SectionContainer
          sectionTitle={shareTableName}
          displayForTV={displayForTV}
        >
          <LeaderBoardList
            data={shareTableData}
            isRezzilPlayer
          />
          <Timestamp>Last updated: {lastUpdated}</Timestamp>
        </SectionContainer>
      </ContainerWrapper>
    }
  </ShareContainer>
  );
}

export default Share;
