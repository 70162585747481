export const nonCommercialFeatures = [
  'FREE',
  'Single Drill',
  'No Profiles',
];

export const limitedTrialFeatures = [
  'FREE for 30 days',
  'Index Drills',
  'Limited Access',
  '5 Profiles',
];

export const subscriptionMonthlyFeatures = [
  '{formattedPrice} per month',
  'Club Level Access',
  'Full Support',
  'World Stats',
];

export const subscriptionAnnualFeatures = [
  '{formattedPrice} per year (best value)',
  'Club Level Access',
  'Full Support',
  'World Stats',
];
