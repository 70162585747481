import {useState} from 'react';
import {HexColorPicker} from 'react-colorful';
import {
  Container,
  InlineFieldContainer,
  FieldContainer,
  PhoneFieldContainer,
  ColourSelectorInput,
  ColourPickerContainer,
} from './ClubForm.styles';
import StandardModal from '../../Modals/StandardModal/StandardModal';
import {countries} from '../../../options/countries';
import CustomTextField from '../../Inputs/CustomTextField/CustomTextField';
import Label from '../../Typography/Label/Label';
import CustomAutocomplete from '../../Inputs/CustomAutocomplete/CustomAutocomplete';
import getSortedCountryCodes from '../../../utils/helpers/getSortedCountryCodes';

const ClubForm = ({formData, errors, onChange}) => {
  const [showColourPicker, setShowColourPicker] = useState(false);
  const [colour, setColour] = useState(formData.colour);
  const [pickerColour, setPickerColour] = useState(formData.colour ? formData.colour : '#aabbcc');

  const updateColour = () => {
    setColour(pickerColour);
    setShowColourPicker(false);
    // Manually trigger on change.
    onChange(true, 'colour', pickerColour);
  }

  return (
    <Container>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Name'}
            id={'name'}
            placeholder={'Joe Bloggs'}
            required
            onChange={onChange}
            error={errors.name}
            type={'text'}
            value={formData.name}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Contact'}
            id={'contact'}
            placeholder={'email@email.com'}
            required
            onChange={onChange}
            error={errors.contact}
            type={'email'}
            value={formData.contact}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <InlineFieldContainer>
        <PhoneFieldContainer>
          <FieldContainer>
            <CustomAutocomplete
              label={'Code'}
              id={'telephoneCountryCode'}
              placeholder={'44'}
              onChange={onChange}
              error={errors.telephoneCountryCode}
              value={formData.telephoneCountryCode}
              options={getSortedCountryCodes()}
              telephoneCountryCode
            />
          </FieldContainer>
          <FieldContainer>
            <CustomTextField
              label={'Phone'}
              id={'telephone'}
              placeholder={'eg. 7135684527'}
              required
              onChange={onChange}
              error={errors.telephone}
              type={'text'}
              value={formData.telephone}
            />
          </FieldContainer>
        </PhoneFieldContainer>
        <FieldContainer>
          <CustomAutocomplete
            label={'Country'}
            id={'country'}
            placeholder={'Select Country'}
            required
            onChange={onChange}
            error={errors.country}
            value={formData.country}
            options={countries}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Address 1'}
            id={'addr1'}
            placeholder={'Address Line 1'}
            required
            onChange={onChange}
            error={errors.addr1}
            type={'text'}
            value={formData.addr1}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Address 2'}
            id={'addr2'}
            placeholder={'Address Line 2'}
            onChange={onChange}
            error={errors.addr2}
            type={'text'}
            value={formData.addr2}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Address 3'}
            id={'addr3'}
            placeholder={'Address Line 3'}
            onChange={onChange}
            error={errors.addr3}
            type={'text'}
            value={formData.addr3}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'City'}
            id={'city'}
            placeholder={'City'}
            required
            onChange={onChange}
            error={errors.city}
            type={'text'}
            value={formData.city}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Post/Zip Code'}
            id={'postcode'}
            placeholder={'Post/Zip Code'}
            required
            onChange={onChange}
            error={errors.postcode}
            type={'text'}
            value={formData.postcode}
          />
        </FieldContainer>
        {/* <FieldContainer>
          <CustomTextField
            label={'Safe Zone Radius'}
            id={'safe_zone_radius'}
            placeholder={'Safe Zone Radius'}
            required
            onChange={onChange}
            error={errors.safe_zone_radius}
            type={'text'}
            value={formData.safe_zone_radius || 0.74}
          />
        </FieldContainer> */}
        <FieldContainer>
          <Label
            text={'Colour'}
            required
          />
          <ColourSelectorInput selectedcolour={colour} onClick={() => {
            setShowColourPicker(true)
          }
          } />
        </FieldContainer>
      </InlineFieldContainer>
      {/* <InlineFieldContainer>
        <FieldContainer>
          <DiscountFieldContainer>
            <CustomTextField
              label={'Index licence'}
              id={'indexLicenceCount'}
              placeholder={'10'}
              required
              onChange={onChange}
              error={errors.indexLicenceCount}
              type={'number'}
              value={formData.indexLicenceCount}
            />
            <CustomTextField
              label={'Discount'}
              id={'indexLicenceDiscount'}
              placeholder={'20'}
              onChange={onChange}
              error={errors.indexLicenceDiscount}
              type={'number'}
              value={formData.indexLicenceDiscount}
            />
          </DiscountFieldContainer>
        </FieldContainer>
        <FieldContainer>
          <DiscountFieldContainer>
            <CustomTextField
              label={'Analysis licence'}
              id={'analysisLicenceCount'}
              placeholder={'10'}
              required
              onChange={onChange}
              error={errors.analysisLicenceCount}
              type={'number'}
              value={formData.analysisLicenceCount}
            />
            <CustomTextField
              label={'Discount'}
              id={'analysisLicenceDiscount'}
              placeholder={'20'}
              onChange={onChange}
              error={errors.analysisLicenceDiscount}
              type={'number'}
              value={formData.analysisLicenceDiscount}
            />
          </DiscountFieldContainer>
        </FieldContainer>
      </InlineFieldContainer>
      <InlineFieldContainer>
        <FieldContainer>
          <DiscountFieldContainer>
            <CustomTextField
              label={'DB licence'}
              id={'dbLicenceCount'}
              placeholder={'10'}
              required
              onChange={onChange}
              error={errors.dbLicenceCount}
              type={'number'}
              value={formData.dbLicenceCount}
            />
            <CustomTextField
              label={'Discount'}
              id={'dbLicenceDiscount'}
              placeholder={'20'}
              onChange={onChange}
              error={errors.dbLicenceDiscount}
              type={'number'}
              value={formData.dbLicenceDiscount}
            />
          </DiscountFieldContainer>
        </FieldContainer>
        <FieldContainer>
          <DiscountFieldContainer>
            <CustomTextField
              label={'Rezzil Player licence'}
              id={'rezzilPlayerLicenceCount'}
              placeholder={'10'}
              required
              onChange={onChange}
              error={errors.rezzilPlayerLicenceCount}
              type={'number'}
              value={formData.rezzilPlayerLicenceCount}
            />
            <CustomTextField
              label={'Discount'}
              id={'rezzilPlayerLicenceDiscount'}
              placeholder={'20'}
              onChange={onChange}
              error={errors.rezzilPlayerLicenceDiscount}
              type={'number'}
              value={formData.rezzilPlayerLicenceDiscount}
            />
          </DiscountFieldContainer>
        </FieldContainer>
      </InlineFieldContainer>
      <FieldContainer>
        <CustomAutocomplete
          label={'Sales rep'}
          id={'salesRep'}
          placeholder={'Select Sales Rep'}
          required
          onChange={onChange}
          error={errors.salesRep}
          value={formData.salesRep}
          options={salesReps}
        />
      </FieldContainer>
      <FieldContainer>
        <CustomTextField
          label={'Revenue'}
          id={'revenue'}
          placeholder={'£150 PM'}
          required
          onChange={onChange}
          error={errors.revenue}
          type={'text'}
          value={formData.revenue}
        />
      </FieldContainer> */}
      {showColourPicker &&
        <StandardModal
          width={300}
          open={showColourPicker}
          onClose={() => setShowColourPicker(false)}
          title={'Select Colour'}
          btnText={'Select Colour'}
          onClick={updateColour}
        >
          <ColourPickerContainer>
            <HexColorPicker color={colour} onChange={setPickerColour} />
          </ColourPickerContainer>

        </StandardModal>
      }
    </Container>
  )
};

export default ClubForm;
