import {
  Container,
  StyledTitle,
} from './ModalTitle.styles';

const ModalTitle = ({text}) => {
  return (
    <Container>
      <StyledTitle>
        {text}
      </StyledTitle>
    </Container>
  );
};

export default ModalTitle;
