import {styled} from '@mui/material';
import BgImage from '../../../assets/images/bg-primary.png';

export const Container = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  background: `url(${BgImage}) no-repeat center / cover`,
  position: 'relative',
  backgroundAttachment: 'fixed',
}));

export const BannerContainer = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  top: 0,
  left: 0,
}));
