import {
  styled,
  TextField,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({
  width: '100%',
}));

export const StyledTextField = styled(TextField)(({ theme, error }) => ({
  '.MuiOutlinedInput-root': {
    ...theme.typography.body1,
    padding: 0,
    borderRadius: 5,
    overflow: 'hidden',
    '.MuiOutlinedInput-input': {
      padding: '15px 20px',
      background: error ? colors.menuPrimaryBlack : 'transparent',
      '&::placeholder': {
        color: colors.secondaryGrey,
      },
      '&::-webkit-outer-spin-button': {
        'WebkitAppearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        display: 'none',
        'WebkitAppearance': 'none',
        margin: 0,
      },
      '&::-webkit-calendar-picker-indicator': {
        display: 'none',
      },
      '&[type=number]': {
        'MozAppearance': 'textfield',
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      padding: 0,
      borderColor: error ? `${colors.primaryRed} !important` : colors.secondaryGrey,
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-input': {
        background: 'rgba(255, 255, 255, 0.2)'
      },
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.2)',
        borderWidth: 1,
      },
      '&:hover': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(255, 255, 255, 0.2)',
          borderWidth: 1,
        },
      }
    },
    '&:hover': {
      '.MuiOutlinedInput-notchedOutline': {
        padding: 0,
        borderColor: error ? colors.primaryRed : colors.secondaryGrey,
      },
    },
  },
}));
