import { PriceContainer, Pence, DisplayPrice, TermCopy } from './Price.styles';

const Price = ({ price, hasAsterisk, perMonth, perAnnum }) => {
  // split price into pounds and pence
  if (!price) return <></>;
  const [pounds, pence] = price.split('.');
  const showTerm = perMonth || perAnnum;
  return (
    <PriceContainer>
      {showTerm && <TermCopy>From</TermCopy>}
      <DisplayPrice>
        {pounds}
        <Pence>
          {pence}
          {hasAsterisk && '*'}
        </Pence>
      </DisplayPrice>
      {showTerm && <TermCopy>{perMonth ? 'per month' : 'annually'}</TermCopy>}
    </PriceContainer>
  )
    ;
};

export default Price;