import {
  StyledCard,
  StyledCardContent,
  ImageContainer,
  StyledCardActions,
  StyledTitle,
  StyledDate,
} from './NewsCard.styles';
import OutlinedButton from '../../Buttons/OutlinedButton/OutlinedButton';

const NewsCard = ({imageUrl, title, date, link}) => {
  return (
    <StyledCard>
      <StyledCardContent>
        <ImageContainer
          image={imageUrl}
        />
        <StyledTitle>
          {title}
        </StyledTitle>
        <StyledDate>
          {date}
        </StyledDate>
      </StyledCardContent>
      <StyledCardActions>
        <OutlinedButton
          text={'Read More'}
          href={link}
        />
      </StyledCardActions>
    </StyledCard>
  );
};

export default NewsCard;
