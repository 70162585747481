import {useEffect, useContext, useState} from 'react';
import {
  HeaderContainer,
  StyledSectionContainer,
  FirstPLayerContainer,
  StatsWrapper,
  StatsLeftContainer,
  StatsRightContainer,
  AvatarContainer,
  RadarChartContainer,
  BarChartContainer,
  BarChartIncLegendContainer,
} from './IndexStats.styles';
import {indexStatsOptions} from '../../../options/indexStatsOptions';
import {indexAccordionOptions} from '../../../options/indexAccordionOptions';
import {indexStatsToggleOptions} from '../../../options/indexStatsToggleOptions';
import SectionSpinner from '../../../components/Loading/SectionSpinner/SectionSpinner';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import SharePrintBtnGroup from '../../../components/ButtonGroups/SharePrintBtnGroup/SharePrintBtnGroup';
import PlayersDropdownSelects from '../../../components/StatsPage/PlayersDropdownSelects/PlayersDropdownSelects';
import SectionContainer from '../../../components/Containers/SectionContainer/SectionContainer';
import NoResultsText from '../../../components/Typography/NoResultsText/NoResultsText';
import TitleWithLegend from '../../../components/Typography/TitleWithLegend/TitleWithLegend';
import NameAvatar from '../../../components/Avatars/NameAvatar/NameAvatar';
import StatsList from '../../../components/Tables/StatsList/StatsList';
import ProgrammeStatsList from '../../../components/Tables/ProgrammeStatsList/ProgrammeStatsList';
import IndexRadarChart from '../../../components/Charts/IndexRadarChart/IndexRadarChart';
import CustomToggleGroup from '../../../components/Inputs/CustomToggleGroup/CustomToggleGroup';
import SectionTitleWithArrows from '../../../components/StatsPage/SectionTitleWithArrows/SectionTitleWithArrows';
import BarChart from '../../../components/Charts/BarChart/BarChart';
import BarChartLegend from '../../../components/StatsPage/BarChartLegend/BarChartLegend';
import IndexBreakdownHeaderStats from '../../../components/StatsPage/IndexBreakdownHeaderStats/IndexBreakdownHeaderStats';
import Subtitle from '../../../components/Typography/Subtitle/Subtitle';
import IndexBreakdownBody from '../../../components/StatsPage/IndexBreakdownBody/IndexBreakdownBody';
import SectionTitleWithSelects from '../../../components/StatsPage/SectionTitleWithSelects/SectionTitleWithSelects';

// Contexts & Helpers
import {BaseContext} from '../../../providers/Global/BaseProvider';
import {IndexStatsContext} from '../../../providers/Index/IndexStatsProvider';
import cloneData from '../../../utils/helpers/cloneData';
import {createColorHex, createColorRgba} from '../../../utils/helpers/createColorHex';
import {
  getPlayerDropdownOptions,
  getPlayerChoices,
  loggedInPlayerInChoices,
} from '../../../utils/helpers/playerChoicesHelpers';
import {
  getDeepProgrammeStats,
  getProgrammeSessionStats,
  getIndexBreakdownData,
  getNewPlayerStats,
} from '../../../utils/helpers/indexStatsHelpers';
import duplicateExists from '../../../utils/helpers/duplicateExists';
import generatePlaceholderArray from '../../../utils/helpers/generatePlaceholderArray';
import { routes } from '../../../options/routes';

const IndexStats = () => {
  const baseContext = useContext(BaseContext);
  const indexStatsContext = useContext(IndexStatsContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [shareLoading, setShareLoading] = useState(false);
  const [playerDataLoading, setPlayerDataLoading] = useState(false);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const [playerDropdownOptions, setPlayerDropdownOptions] = useState([]);
  const [playerChoices, setPlayerChoices] = useState([]);
  const [playerStatsList, setPlayerStatsList] = useState([]);
  const [playerAvgData, setPlayerAvgData] = useState([]);
  const [playerAvgDataArr, setPlayerAvgDataArr] = useState([]);
  const [playerLegendColor, setPlayerLegendColor] = useState('');
  const [avgIndexStats, setAvgIndexStats] = useState([]);
  const [selectedToggleId, setSelectedToggleId] = useState(indexStatsToggleOptions[0].id);
  const [allProgrammeSessionsData, setAllProgrammeSessionsData] = useState([]);
  const [latestProgrammeSessionDeepData, setLatestProgrammeSessionDeepData] = useState([]);
  const [allProgrammeSessionsDeepData, setAllProgrammeSessionsDeepData] = useState([]);
  const [programmeStatsData, setProgrammeStatsData] = useState([]);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(true);
  const [sessionsBatchInView, setSessionsBatchInView] = useState(1);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [indexBreakdownStats, setIndexBreakdownStats] = useState({});
  const [breakdownPlayerOptions, setBreakdownPlayerOptions] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(0);
  const [selectedSession, setSelectedSession] = useState('');
  const [allSessionDropdownOptions, setAllSessionDropdownOptions] = useState([]);
  const [sessionDropdownOptions, setSessionDropdownOptions] = useState([]);
  const [clubLeaderboard, setClubLeaderboard] = useState([]);
  const [globalLeaderboard, setGlobalLeaderboard] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    if (indexStatsContext.indexStatsData.allLoaded) {
      setDataLoading(false);

      if (indexStatsContext.indexStatsData.apiError) {
        setError({
          show: true,
          message: 'Unable to load some or all page content. Please refresh and try again.'
        });
      }

      // Get data from indexStatsContext
      const {
        playerProfiles,
        allProgrammeSessions,
        allProgrammeSessionsDeep,
        programmeSessionStats,
        playerStats,
        indexBreakdownData,
        clubLeaderboard,
        globalLeaderboard,
        worldAvgStats,
      } = indexStatsContext.indexStatsData;

      // Set options for player dropdowns
      let options = getPlayerDropdownOptions(playerProfiles);
      setPlayerDropdownOptions(options);
      setClubLeaderboard(clubLeaderboard);
      setGlobalLeaderboard(globalLeaderboard);
      // Add logged in player id to playerChoices
      const {id} = baseContext.baseData.userProfile;
      const hasLoggedInPlayerInChoices = loggedInPlayerInChoices(id, options);
      const firstPlayer = hasLoggedInPlayerInChoices ? baseContext.baseData.userProfile : playerProfiles[0];
      const firstname = firstPlayer ? firstPlayer.firstName : '--';
      const lastname = firstPlayer ? firstPlayer.lastname : '--';
      const playerChoicesIds = [hasLoggedInPlayerInChoices ? id : options && options.length ? options[0].value : ''];
      setPlayerChoices(playerChoicesIds);

      // Set initial player stats for the logged in player
      setPlayerStatsList([playerStats]);

      // Set initial player stats for the radar chart - do not include heart rate
      const averages = getPlayerAvgData(playerStats);
      setPlayerAvgData(averages);
      setPlayerAvgDataArr([averages]);

      // Set data for average index stats section
      const stats = [...indexStatsToggleOptions];
      const {programmeStats, color} = getInitialAvgIndexStats(
        `${firstname} ${lastname}`, stats, averages, worldAvgStats
      );
      setAvgIndexStats(programmeStats);
      setPlayerLegendColor(color);

      // Set data for all programmes/sessions, chart data and index breakdown data
      setInitialPlayerData(
        playerChoicesIds[0],
        `${firstname} ${lastname}`,
        options,
        allProgrammeSessions,
        allProgrammeSessionsDeep,
        programmeSessionStats,
        indexBreakdownData,
      );
    }
  }, [baseContext, indexStatsContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handleShareClick = async () => {
    await setShareLoading(true);

    // TODO: Trigger API call
    // const data = await shareIndexStats();
    // if (!data || !data.success) {
    //   setError({
    //     show: true,
    //     message: 'There was an error with the request, please try again',
    //   });
    //   setShareLoading(false);
    //   return;
    // }
    setError({
      show: false,
      message: 'Request successfully submitted',
    });
    setShareLoading(false);
  };

  const getPlayerNameFromId = (options, playerId) => {
    // Return the label (player name) from the option that with a value that matches the playerId
    const player = options.find(option => option.value === playerId);
    return player && player.label ? player.label : '';
  };

  const getPlayerAvgData = stats => {
    // Convert player avg data to array of int values to render in radar chart
    const avgOptions = [...indexAccordionOptions];
    let averages = [];
    // We want avgOptions without heartRate
    avgOptions.filter((stat) => stat.statId !== 'heartRate').forEach(({ statId }) => {
      const avgValue = stats?.avgScore[statId];
      averages.push(isNaN(avgValue) ? '--' : Math.round(stats.avgScore[statId]));
    });
    return averages;
  };

  const getInitialAvgIndexStats = (playerName, programmeStats, scores, worldAvgScores)  => {
    // Get initial avg stats to display in Average Index Stats section on page load (for logged in player)
    const color = createColorHex(playerName);
    programmeStats.forEach((stat, idx) => {
      // Reset scores and worldScore values before setting initial stats
      stat.playerScores = [];
      stat.worldScore = '';
      const scoreData = {
        color,
        score: scores[idx],
      };
      const worldScore =  worldAvgScores[stat.id] ?  Math.round(worldAvgScores[stat.id]) : '--';
      stat.playerScores.push(scoreData);
      stat.worldScore = worldScore;
    });
    return {programmeStats, color};
  };

  const getUpdatedAvgIndexStats = (programmeStats, playersList) => {
    // Get initial avg stats to display in Average Index Stats section on player dropdown change (add/update players)
    programmeStats.forEach((stat) => {
      const scoreData = [];
      playersList.forEach(player => {
        const singleScoreData = {
          // Create colour hex from player's first/last name and get score from average scores data
          color: createColorHex(`${player.firstname} ${player.lastname}`),
          score: isNaN(player.avgScore[stat.id]) ? '--' : Math.round(player.avgScore[stat.id]),
        };
        scoreData.push(singleScoreData);
      });
      stat.playerScores = scoreData;
    });
    // Set colour hex for player 1 for section legend
    const color = createColorHex(`${playersList[0].firstname} ${playersList[0].lastname}`);
    return {programmeStats, color};
  };

  const getChartData = (programmeId, playerName, sessionStats) => {
    // Set chart data for selected programme
    const labels = [];
    const scores = [];
    const desiredLength = 10;
    sessionStats && sessionStats[programmeId].scores.forEach((score, idx) => {
        labels.push(idx + 1);
        scores.push(score.score ? score.score : 0);
    });
    if (labels.length < desiredLength) {
      // Push empty strings into labels array to give it 10 elements to enable correct chart rendering
      const missingLength = desiredLength - labels.length;
      for (let i = 0; i < missingLength; i++) {
        labels.push('');
        scores.push(0);
      }
    }
    // Reverse data arrays to show oldest to newest from left to right in chart
    const reversedLabels = labels.reverse();
    const reversedScores = scores.reverse();
    // Set labels and data
    const chartData = {
      avgColor: createColorRgba(playerName),
      avgScore: sessionStats ? sessionStats[programmeId].average : null,
      color: createColorHex(playerName),
      data: reversedScores,
    };
    return {labels: reversedLabels, chartData};
  };

  const getUpdatedChartData = (statGroupData, playerIds, playerData, id) => {
    // Get combined chart data when player is added or toggle option is changed (get rezzilIndex data as default)
    const programmeId = id ? id : indexStatsToggleOptions[0].id;
    let chartLabels = [];
    let allSessionDropdowns = [];
    let combinedChartData = [];
    statGroupData.forEach((statGroup, idx) => {
      // Get player name from the matching label in playerDropdownOptions
      const playerName = getPlayerNameFromId(playerDropdownOptions, playerIds[idx]);
      const {labels, chartData} = getChartData(programmeId, playerName, statGroup);
      // Set chart labels
      chartLabels[idx] = labels;
      allSessionDropdowns[idx] = getSessionDropdownOptions(labels);
      // Push player data into combinedChartData
      combinedChartData.push(chartData);
    });
    setAllSessionDropdownOptions(allSessionDropdowns);

    return { chartLabels, combinedChartData };
  };

  // Get index of option with matching id
  const getOptionIndex = (id, options) => options.findIndex(el => Number(el.value) === Number(id));

  const getPlayerOption = (id, dropdownOptions) => {
    // Return the player object for the selected id
    const playerIdx = getOptionIndex(id, dropdownOptions);
    return dropdownOptions[playerIdx];
  };

  const getSessionDropdownOptions = sessions => {
    // Create an array of object with value and label key pairs to enable rendering sessions as dropdown options
    let sessionDropdowns = [];
    const sortedSessions = [...sessions].reverse();
    sortedSessions.forEach(session => {
      if (session) sessionDropdowns.push({value: session, label: session});
    });
    return sessionDropdowns;
  };

  const setInitialPlayerData = (
    playerId,
    playerName,
    dropdownOptions,
    allProgrammeSessions,
    allProgrammeSessionsDeep,
    programmeSessionStats,
    indexBreakdownData
  ) => {
    // Set data for all programmes
    let programmeSessionsData = [],
      disablePrevBtn = true;
    if (allProgrammeSessions && allProgrammeSessions.length > 0) {
      programmeSessionsData = [allProgrammeSessions];
      if (allProgrammeSessions.length > 10) disablePrevBtn = false;
    }
    setAllProgrammeSessionsData(programmeSessionsData);
    setDisablePrev(disablePrevBtn);
    setDisableNext(true);

    let programmeSessionsDeepData = [],
      latestSessionDeepData = [];
    if (allProgrammeSessionsDeep && allProgrammeSessionsDeep.length > 0) {
      // Add empty objects to array to give it a min length of 10 to ensures bar chart click functionality works
      const desiredLength = 10;
      const missingLength = desiredLength - allProgrammeSessionsDeep.length;
      for (let i = 0; i < missingLength; i++) {
        allProgrammeSessionsDeep.push({});
      }
      programmeSessionsDeepData = [allProgrammeSessionsDeep];
      latestSessionDeepData = [allProgrammeSessionsDeep[0]];
    }
    setAllProgrammeSessionsDeepData(programmeSessionsDeepData);
    setLatestProgrammeSessionDeepData(latestSessionDeepData);

    // Set stats related to programmeSessionStats if data exists
    let programmeStatsData = [],
      chartLabels = [],
      stateChartData = [],
      breakdownPlayerOptions = [],
      sessionDropdownOptions = [],
      allSessionDropdownOptions = [],
      selectedSession = '';
    if (programmeSessionStats) {
      // Set chart data for index programme
      const sessionStatsData = [programmeSessionStats];
      const { labels, chartData } = getChartData(
        indexStatsToggleOptions[0].id,
        playerName,
        sessionStatsData[0]
      );
      programmeStatsData = sessionStatsData;
      chartLabels = labels;
      stateChartData = [chartData];

      // Set data for session dropdowns
      const player = getPlayerOption(playerId, dropdownOptions);
      const sessionDropdowns = getSessionDropdownOptions(labels);
      breakdownPlayerOptions = player ? [player] : [];
      sessionDropdownOptions = sessionDropdowns;
      allSessionDropdownOptions = [sessionDropdowns];
      selectedSession = labels[(labels.length - 1)];
    }
    setProgrammeStatsData(programmeStatsData);
    setChartLabels([chartLabels]);
    setChartData(stateChartData);
    setSessionsBatchInView(1);
    setBreakdownPlayerOptions(breakdownPlayerOptions);
    setSessionDropdownOptions(sessionDropdownOptions);
    setAllSessionDropdownOptions(allSessionDropdownOptions);
    setSelectedSession(selectedSession);
    setSelectedPlayer(0);

    // Set index breakdown stats
    setIndexBreakdownStats(indexBreakdownData ? indexBreakdownData : {});
  };

  const handlePlayerChange = idx => async e => {
    handleBannerClose();
    setPlayerDataLoading(true);

    // Return if the Please Select option is selected in any dropdown
    const playerId = e.target.value;
    if (playerId === '') return;
    const {id} = baseContext.baseData.userProfile;
    const defaultPlayerId = loggedInPlayerInChoices(id, playerDropdownOptions);
    const rows = getPlayerChoices(playerChoices, idx, playerId, defaultPlayerId);
    // Ensure duplicates do not exist
    if (duplicateExists(rows)) {
      setError({
        show: true,
        message: 'That player has already been selected',
      });
      setPlayerDataLoading(false);
      return;
    }
    setPlayerChoices(rows);

    // Make API calls to get player stats data and programme stats data
    const { success, newPlayerStats } = await getNewPlayerStats(playerId, clubLeaderboard, globalLeaderboard);
    const {
      allProgrammeSessions,
      allProgrammeSessionsDeep,
      programmeSessionStats,
      playerStats,
    } = newPlayerStats;
    if (!success) {
      setError({
        show: true,
        message: 'There was a problem with getting the data for that player.',
      });
      setPlayerDataLoading(false);
      return;
    }

    // Set player stats data for relevant dropdown
    const statsList = [...playerStatsList];
    statsList[idx] = playerStats;
    setPlayerStatsList(statsList);

    // Set/replace player average data for radar chart if change to first dropdown - do not include heart rate
    const averages = getPlayerAvgData(statsList[idx]);
    if (idx === 0) {
      setPlayerAvgData(averages);
    }
    // Replace item in array at index idx

    if (idx < playerAvgData.length) {
      playerAvgDataArr.splice(idx, 1, averages);
    } else {
      playerAvgDataArr.push(averages);
    }

    setPlayerAvgDataArr([...playerAvgDataArr])

    // Set data for average index stats section
    const stats = [...avgIndexStats];
    const {programmeStats, color} = getUpdatedAvgIndexStats(stats, statsList);
    setAvgIndexStats(programmeStats);
    setPlayerLegendColor(color);

    // Update allProgrammeSessionsData at relevant index
    const programmeSessionsData = cloneData(allProgrammeSessionsData);
    if (allProgrammeSessions && allProgrammeSessions.length > 0) {
      programmeSessionsData[idx] = allProgrammeSessions;
    } else {
      programmeSessionsData[idx] = [];
    }
    setAllProgrammeSessionsData(programmeSessionsData);

    // Enable previous button in bar chart if any of the data array lengths are greater than 10
    setDisablePrev((!programmeSessionsData.some(item => item.length > 10)));

    // Update allProgrammeSessionsDeepData and latestProgrammeSessionDeepData at relevant index
    const programmeSessionsDeep = cloneData(allProgrammeSessionsDeepData);
    const latestProgrammeSessionDeep = cloneData(latestProgrammeSessionDeepData);
    if (allProgrammeSessionsDeep && allProgrammeSessionsDeep.length > 0) {
      // Add empty objects to array to give it a min length of 10 to ensures bar chart click functionality works
      const desiredLength = 10;
      const missingLength = desiredLength - allProgrammeSessionsDeep.length;
      for (let i = 0; i < missingLength; i++) {
        allProgrammeSessionsDeep.push({});
      }
      programmeSessionsDeep[idx] = allProgrammeSessionsDeep;
      latestProgrammeSessionDeep[idx] = allProgrammeSessionsDeep[0];
    } else {
      programmeSessionsDeep[idx] = generatePlaceholderArray(10, {});
      latestProgrammeSessionDeep[idx] = {};
    }
    setAllProgrammeSessionsDeepData(programmeSessionsDeep);
    setLatestProgrammeSessionDeepData(latestProgrammeSessionDeep);

    // Get session stats for rezzilIndex programme to display in bar chart
    // Reset displayed chart data to first batch for all selected players
    const statsData = cloneData(programmeStatsData);
    statsData[idx] = programmeSessionStats;
    rows.forEach((player, playerIdx) => {
      if (player && playerIdx !== idx) {
        const sessionsDeep = programmeSessionsDeep[playerIdx].slice(0, 10);
        statsData[playerIdx] = getProgrammeSessionStats(sessionsDeep);
      }
    });
    setProgrammeStatsData(statsData);
    setSessionsBatchInView(1);
    // Reset toggle stats to index toggle
    handleToggleChange(indexStatsToggleOptions[0].id, statsData, statsList, rows);

    // Reset selected session and player for Index Breakdown section
    const player = getPlayerOption(rows[idx], playerDropdownOptions);
    const dropdownOptions = [...breakdownPlayerOptions];
    dropdownOptions[idx] = player;
    setBreakdownPlayerOptions(dropdownOptions);

    // Updated selected player and selected session based on changed player index
    const selSession = idx > 0
      ? allSessionDropdownOptions[0].length > 0 && allSessionDropdownOptions[0][0].label
        ? allSessionDropdownOptions[0][0].label
        : ''
      : allProgrammeSessionsDeep && allProgrammeSessionsDeep.length > 0
        ? 1
        : '';
    setSelectedSession(selSession);
    setSelectedPlayer(0);

    // Set index breakdown stats for player 1 - empty object if no data
    const updatedIndexBreakdownData = getIndexBreakdownData(latestProgrammeSessionDeep[0], programmeSessionsDeep[0][0]);
    setIndexBreakdownStats(updatedIndexBreakdownData ? updatedIndexBreakdownData : {});

    setDisableNext(true);
    setPlayerDataLoading(false);
  };

  const handlePlayerAdd = () => {
    if (!playerChoices[playerChoices.length - 1]) return;
    const row = '';
    setPlayerChoices([...playerChoices, row]);
  };

  const handlePlayerReset = () => {
    setPlayerDataLoading(true);
    // Get data from indexStatsContext
    const {
      allProgrammeSessions,
      allProgrammeSessionsDeep,
      programmeSessionStats,
      indexBreakdownData,
      playerStats,
    worldAvgStats,
    } = indexStatsContext.indexStatsData;

    // Reset options and set the logged in player as the first choice
    const {id} = baseContext.baseData.userProfile;
    const playerChoicesIds = [loggedInPlayerInChoices(id, playerDropdownOptions) ? id : playerDropdownOptions.length ? playerDropdownOptions[0].value : ''];
    setPlayerChoices(playerChoicesIds);

    setPlayerStatsList([playerStats]);

    // Set initial player stats for the radar chart - do not include heart rate
    const averages = getPlayerAvgData(playerStats);
    setPlayerAvgData(averages);
    setPlayerAvgDataArr([averages])

    // Set data for average index stats section
    const stats = [...indexStatsToggleOptions];
    const indexId = indexStatsToggleOptions[0].id;
    const playerName = `${playerStats.firstname} ${playerStats.lastname}`;
    const {programmeStats, color} = getInitialAvgIndexStats(playerName, stats, averages, worldAvgStats);
    setSelectedToggleId(indexId);
    setAvgIndexStats(programmeStats);
    setPlayerLegendColor(color);

    // Reset all other relevant states back to initial states based on indexStatsContext data
    setInitialPlayerData(
      id,
      playerName,
      playerDropdownOptions,
      allProgrammeSessions,
      allProgrammeSessionsDeep,
      programmeSessionStats,
      indexBreakdownData
    );
    setPlayerDataLoading(false);
  };

  const handleToggleChange = (id, progStats, players, selectedPlayers) => {
    setSelectedToggleId(id);

    const programmeStats = progStats ? progStats : programmeStatsData;
    const playerStats = players ? players : playerStatsList;
    const playerIds = selectedPlayers ? selectedPlayers : playerChoices;

    // Get session stats for selected programme to display in bar chart (collate stats per selected player)
    const {chartLabels, combinedChartData} = getUpdatedChartData(programmeStats, playerIds, playerStats, id);
    setChartLabels(chartLabels);
    setChartData(combinedChartData);

    // Set data for session dropdowns
    const sessionDropdowns = getSessionDropdownOptions(chartLabels[0]);
    setSessionDropdownOptions(sessionDropdowns);
  };

  const getUpdatedSessionsDeepData = async (
    playerId,
    datasetIndex,
    selectedSessions,
    startIndex,
    endIndex,
    combinedProgrammeSessionsDeepData,
    sessionStatsData,
    updatedChartLabels,
    updatedChartData,
    updatedAllSessionDropdowns,
  ) => {
    // Only make API call for previous sessions if not already cached
    const cachedSessions = allProgrammeSessionsDeepData[datasetIndex].slice(startIndex, endIndex);
    let deepData = cachedSessions;
    if (cachedSessions.length === 0) {
      // Iterate over sessions and get deep data
      const {hasErrors, data} = await getDeepProgrammeStats(playerId, selectedSessions);
      deepData = data;
      if (hasErrors) return false;
    }

    // Process data
    const allProgrammeSessionsDeep = deepData;
    const programmeSessionStats = getProgrammeSessionStats(allProgrammeSessionsDeep);
    // Only set indexBreakdownData for selected player

    // Update state values
    // Set data for all programmes
    if (allProgrammeSessionsDeep && allProgrammeSessionsDeep.length > 0) {
      // Add empty objects to array to give it a min length of 10 to ensures bar chart click functionality works
      const desiredLength = 10;
      const missingLength = desiredLength - allProgrammeSessionsDeep.length;
      for (let i = 0; i < missingLength; i++) {
        allProgrammeSessionsDeep.push({});
      }

      // Only add deep session data to existing data if API call has been made
      if (cachedSessions.length === 0) {
        combinedProgrammeSessionsDeepData[datasetIndex] = [
          ...combinedProgrammeSessionsDeepData[datasetIndex],
          ...allProgrammeSessionsDeep
        ];
      }
    }

    // Set stats related to programmeSessionStats if data exists
    if (programmeSessionStats) {
      const playerName = getPlayerNameFromId(playerDropdownOptions, playerId);
      // Set chart data for index programme
      sessionStatsData[datasetIndex] = programmeSessionStats;
      const data = getChartData(
        indexStatsToggleOptions[0].id,
        playerName,
        sessionStatsData[datasetIndex]
      );
      updatedChartLabels[datasetIndex] = data.labels;
      updatedChartData[datasetIndex] = data.chartData;

      // Set data for session dropdowns - reset to first selected player
      updatedAllSessionDropdowns[datasetIndex] = getSessionDropdownOptions(updatedChartLabels[datasetIndex]);
    }

    // Set index breakdown stats
    if (datasetIndex === 0) {
      const indexBreakdownData = getIndexBreakdownData(
        latestProgrammeSessionDeepData[0],
        allProgrammeSessionsDeep[0]
      );
      setIndexBreakdownStats(indexBreakdownData ? indexBreakdownData : {});
    }

    return true;
  };

  const getSessionData = async (
    idx,
    selectedSessions,
    startIndex,
    endIndex,
    combinedProgrammeSessionsDeepData,
    sessionStatsData,
    updatedChartLabels,
    updatedChartData,
    updatedAllSessionDropdowns,
    setDisable,
    batch,
  ) => {
    // Run reusable logic to get next or previous session data and set relevant states
    const playerId = playerChoices[idx];
    const getUpdatedDataSuccess = await getUpdatedSessionsDeepData(
      playerId,
      idx,
      selectedSessions,
      startIndex,
      endIndex,
      combinedProgrammeSessionsDeepData,
      sessionStatsData,
      updatedChartLabels,
      updatedChartData,
      updatedAllSessionDropdowns,
    );
    if (!getUpdatedDataSuccess) {
      setError({
        show: true,
        message: 'There was a problem with getting the data for previous sessions. Please try again',
      });
      setChartDataLoading(false);
      return false;
    }

    // Enable next/prev button and increment/decrement sessionsBatchInView
    setDisable(false);
    setSessionsBatchInView(batch);

    return true;
  }

  const getDefaultEmptyData = (
    idx,
    updatedChartLabels,
    updatedChartData,
    updatedAllSessionDropdowns,
    combinedProgrammeSessionsDeepData,
  ) => {
    // Get default empty data for chart labels, chart data, session dropdowns and deep session data
    updatedChartLabels[idx] = generatePlaceholderArray(10, '');
    updatedChartData[idx] = generatePlaceholderArray(10, 0);
    updatedAllSessionDropdowns[idx] = getSessionDropdownOptions(updatedChartLabels[idx]);
    const allProgrammeSessionsDeep = generatePlaceholderArray(10, {});
    combinedProgrammeSessionsDeepData[idx] = [
      ...combinedProgrammeSessionsDeepData[idx],
      ...allProgrammeSessionsDeep
    ];
  };

  const setSessionRangeStates = (
    combinedProgrammeSessionsDeepData,
    sessionStatsData,
    updatedChartLabels,
    updatedChartData,
    updatedAllSessionDropdowns,
  ) => {
    // Set all relevant states following logic to get session data for each selected player
    setAllProgrammeSessionsDeepData(combinedProgrammeSessionsDeepData);
    setProgrammeStatsData(sessionStatsData);
    setChartLabels(updatedChartLabels);
    setChartData(updatedChartData);
    setAllSessionDropdownOptions(updatedAllSessionDropdowns);
    setSessionDropdownOptions(updatedAllSessionDropdowns[0]);
    setSelectedPlayer(0);
    setSelectedSession(updatedChartLabels[0][updatedChartLabels[0].length - 1] ? updatedChartLabels[0][updatedChartLabels[0].length - 1] : '');
    setSelectedToggleId(indexStatsToggleOptions[0].id);
    if (allProgrammeSessionsData[0].length === 0) setIndexBreakdownStats({});
  };

  const handleSessionsRangeChangePrev = async () => {
    // Get previous 10 sessions data for bar chart
    setDisablePrev(true);
    setDisableNext(true);
    setChartDataLoading(true);

    // For previous sessions
    const prevBatch = sessionsBatchInView + 1;
    const startIndex = sessionsBatchInView * 10;
    const endIndex = prevBatch * 10;

    // Only run logic to get previous sessions if there are sessions to get
    let combinedProgrammeSessionsDeepData = cloneData(allProgrammeSessionsDeepData),
        sessionStatsData = cloneData(programmeStatsData),
        updatedChartLabels = cloneData(chartLabels),
        updatedChartData = cloneData(chartData),
        updatedAllSessionDropdowns = cloneData(allSessionDropdownOptions);

    for (const [idx, sessionData] of allProgrammeSessionsData.entries()) {
      if (sessionData.length > startIndex) {
        const selectedSessions = sessionData.slice(startIndex, endIndex);
        if (selectedSessions.length) {
          // Trigger API call to get previous batch of session data
          const getUpdatedDataSuccess = await getSessionData(
            idx,
            selectedSessions,
            startIndex,
            endIndex,
            combinedProgrammeSessionsDeepData,
            sessionStatsData,
            updatedChartLabels,
            updatedChartData,
            updatedAllSessionDropdowns,
            setDisableNext,
            prevBatch,
          );

          if (!getUpdatedDataSuccess) {
            setError({
              show: true,
              message: 'There was a problem with getting the data for previous sessions. Please try again',
            });
            setChartDataLoading(false);
            return;
          }
        }
      } else {
        // Set default empty data for chart labels, chart data, session dropdowns and deep session data
        getDefaultEmptyData(
          idx,
          updatedChartLabels,
          updatedChartData,
          updatedAllSessionDropdowns,
          combinedProgrammeSessionsDeepData,
        );
      }
    }

    // Set remaining states
    setSessionRangeStates(
      combinedProgrammeSessionsDeepData,
      sessionStatsData,
      updatedChartLabels,
      updatedChartData,
      updatedAllSessionDropdowns,
    )

    // Enable prev button if more previous sessions for any of the datasets
    // Temporarily disabled while fetching data to prevent double click
    if (allProgrammeSessionsData.some(sessions => sessions.length > endIndex)) setDisablePrev(false);

    setChartDataLoading(false);
  };

  const handleSessionsRangeChangeNext = async () => {
    // Get next 10 sessions data for bar chart
    setDisablePrev(true);
    setDisableNext(true);
    setChartDataLoading(true);

    // For next sessions
    const nextBatch = sessionsBatchInView - 1;
    const endIndex = nextBatch * 10;
    const startIndex = endIndex - 10;

    // Only run logic to get next sessions if there are sessions to get
    let combinedProgrammeSessionsDeepData = cloneData(allProgrammeSessionsDeepData),
        sessionStatsData = cloneData(programmeStatsData),
        updatedChartLabels = cloneData(chartLabels),
        updatedChartData = cloneData(chartData),
        updatedAllSessionDropdowns = cloneData(allSessionDropdownOptions);

    for (const [idx, sessionData] of allProgrammeSessionsData.entries()) {
      if (startIndex >= 0 && sessionData.length > 0) {
        const selectedSessions = sessionData.slice(startIndex, endIndex);
        if (selectedSessions.length) {
          // Get next batch of session data (these will be cached)
          const getUpdatedDataSuccess = await getSessionData(
            idx,
            selectedSessions,
            startIndex,
            endIndex,
            combinedProgrammeSessionsDeepData,
            sessionStatsData,
            updatedChartLabels,
            updatedChartData,
            updatedAllSessionDropdowns,
            setDisablePrev,
            nextBatch,
          );

          if (!getUpdatedDataSuccess) {
            setError({
              show: true,
              message: 'There was a problem with getting the data for previous sessions. Please try again',
            });
            setChartDataLoading(false);
            return;
          }
        }
      } else {
        // Set default empty data for chart labels, chart data, session dropdowns and deep session data
        getDefaultEmptyData(
          idx,
          updatedChartLabels,
          updatedChartData,
          updatedAllSessionDropdowns,
          combinedProgrammeSessionsDeepData,
        );
      }
    }

    // Set remaining states
    setSessionRangeStates(
      combinedProgrammeSessionsDeepData,
      sessionStatsData,
      updatedChartLabels,
      updatedChartData,
      updatedAllSessionDropdowns,
    )

    // Enable next button if more next sessions
    // Temporarily disabled while fetching data to prevent double click
    if (startIndex > 0) setDisableNext(false);

    setChartDataLoading(false);
  };

  const getUpdatedIndexBreakdownData = (playerIndex, sessionIndex, reverseData) => {
    // Update index breakdown data based on session selection
    // Get breakdown stats for selected player and session
    let selectedSessionDeepData = null;
    const endIndex = sessionsBatchInView * 10;
    const startIndex = endIndex - 10;
    const selectedSessions = allProgrammeSessionsDeepData[playerIndex].slice(startIndex, endIndex);
    if (selectedSessions.length > 0) {
      // Reverse allProgrammeSessionsDeepData if reverseData is true to enable selecting correct index
      const reversedSessionsDeepData = reverseData
        ? cloneData(selectedSessions).reverse()
        : null;
      selectedSessionDeepData = reverseData
        ? reversedSessionsDeepData[sessionIndex]
        : selectedSessions[sessionIndex];
    }

    const updatedIndexBreakdownStats = getIndexBreakdownData(
      latestProgrammeSessionDeepData[playerIndex],
      selectedSessionDeepData
    );
    setIndexBreakdownStats(updatedIndexBreakdownStats ? updatedIndexBreakdownStats : {});
  };

  const handleBarClick = (datasetIndex, elIndex) => {
    const session = chartLabels[datasetIndex][elIndex];
    setSelectedPlayer(datasetIndex);
    setSelectedSession(session);
    setSessionDropdownOptions(allSessionDropdownOptions[datasetIndex]);

    // Get updated breakdown stats for selected player and session
    getUpdatedIndexBreakdownData(datasetIndex, elIndex, true);
  };

  const handleIndexBreakdownPlayerChange = e => {
    if (e.target.value) {
      const datasetIndex = getOptionIndex(e.target.value, breakdownPlayerOptions);
      // Set session and dropdownOptions based on selectedSession value and length of available
      // dropdownOptions for selected player
      const dropdownSession = selectedSession ? selectedSession - 1 : 0;
      const selectedSessionDropdowns = allSessionDropdownOptions[datasetIndex];
      const session = selectedSessionDropdowns.length > 0
        ? selectedSessionDropdowns[dropdownSession] && selectedSessionDropdowns[dropdownSession].value === selectedSession
          ? selectedSession
          : 1
        : '';
      const sessionIndex = session ? session - 1 : 0;
      setSelectedPlayer(datasetIndex);
      setSelectedSession(session);
      setSessionDropdownOptions(selectedSessionDropdowns);

      // Get updated breakdown stats for selected player and session
      getUpdatedIndexBreakdownData(datasetIndex, sessionIndex);
    }
  };

  const handleIndexBreakdownSessionChange = e => {
    if (e.target.value) {
      const selectedOption = e.target.value;
      const elIndex = selectedOption - 1;
      setSelectedSession(selectedOption);

      // Get updated breakdown stats for selected player and session
      getUpdatedIndexBreakdownData(selectedPlayer, elIndex);
    }
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.indexStats}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <HeaderContainer>
            <Title
              text={'Index Stats'}
            />
            <SharePrintBtnGroup
              onShareClick={handleShareClick}
              shareLoading={shareLoading}
              // showPrint
            />
          </HeaderContainer>
          <PlayersDropdownSelects
            choices={playerChoices}
            dropdownOptions={playerDropdownOptions}
            handleChange={handlePlayerChange}
            handleAdd={handlePlayerAdd}
            handleReset={handlePlayerReset}
            loading={playerDataLoading}
          />
          {
            playerDataLoading &&
            <SectionSpinner/>
          }
          {
            !playerDataLoading &&
            <>
              <StyledSectionContainer>
                {
                  playerStatsList && playerStatsList.length > 0 &&
                  <FirstPLayerContainer>
                    <SectionContainer
                      cardTitle={'Rezzil Index'}
                    >
                      <StatsWrapper>
                        <StatsLeftContainer>
                            {playerStatsList[0] &&
                              <><AvatarContainer>
                                <NameAvatar
                                  image={playerStatsList[0].picture}
                                  name={`${playerStatsList[0]?.firstname} ${playerStatsList[0]?.lastname}`}
                                />
                              </AvatarContainer>
                                <StatsList
                                  options={indexStatsOptions}
                                  data={playerStatsList[0]}
                                /></>

                            }
                        </StatsLeftContainer>
                        <StatsRightContainer>
                          <ProgrammeStatsList
                              data={playerStatsList[0] && playerStatsList[0].avgScore ? playerStatsList[0].avgScore : {}}
                          />
                        </StatsRightContainer>
                      </StatsWrapper>
                    </SectionContainer>
                    {
                      playerAvgData && playerAvgData.length > 0 &&
                      <SectionContainer>
                        <RadarChartContainer
                          id={'playerChartContainer'}
                        >
                          <IndexRadarChart
                            loading={playerDataLoading}
                            id={'player'}
                            firstDataset={playerAvgData}
                            secondDataset={playerAvgDataArr.length > 1 ? playerAvgDataArr[1] : undefined}
                            datasetArray={playerAvgDataArr}
                          />
                        </RadarChartContainer>
                      </SectionContainer>
                    }
                  </FirstPLayerContainer>
                }
              </StyledSectionContainer>
              <StyledSectionContainer>
                <TitleWithLegend
                  title={'Average Index Stats'}
                  iconColor={playerLegendColor}
                  labelOne={' Player/s'}
                  labelTwo={'World'}
                />
                <CustomToggleGroup
                  options={avgIndexStats}
                  value={selectedToggleId}
                  onChange={handleToggleChange}
                />
              </StyledSectionContainer>
              <StyledSectionContainer>
                <SectionTitleWithArrows
                  title={'Last 10 index sessions played'}
                  handleRangeChangePrev={handleSessionsRangeChangePrev}
                  handleRangeChangeNext={handleSessionsRangeChangeNext}
                  disablePrev={disablePrev}
                  disableNext={disableNext}
                  loading={chartDataLoading}
                />
                <SectionContainer>
                  {
                    chartDataLoading &&
                    <SectionSpinner/>
                  }
                  {
                    !chartDataLoading &&
                    <>
                      {
                        // Only show chart if one or more players have data to display
                        !chartLabels.some(item => item[item.length - 1]) ? (
                          <NoResultsText
                            text={'No details to display'}
                          />
                        ) : (
                          <BarChartIncLegendContainer>
                            <BarChartContainer
                              id={'indexChartContainer'}
                            >
                              <BarChart
                                id={'index'}
                                labels={chartLabels}
                                data={chartData}
                                handleClick={handleBarClick}
                                selectedSession={selectedSession}
                                selectedDataset={selectedPlayer}
                              />
                            </BarChartContainer>
                            <BarChartLegend
                              data={chartData}
                            />
                          </BarChartIncLegendContainer>
                        )
                      }
                    </>
                  }
                </SectionContainer>
              </StyledSectionContainer>
              <StyledSectionContainer>
                <SectionTitleWithSelects
                  title={'Index Breakdown'}
                  playerOptions={breakdownPlayerOptions}
                  handlePlayerChange={handleIndexBreakdownPlayerChange}
                  selectedPlayer={playerChoices[selectedPlayer]}
                  sessionOptions={sessionDropdownOptions}
                  handleSessionChange={handleIndexBreakdownSessionChange}
                  selectedSession={selectedSession}
                  loading={chartDataLoading}
                />
                <SectionContainer
                  cardTitle={'Rezzil Index'}
                >
                  {
                    chartDataLoading &&
                    <SectionSpinner/>
                  }
                  {
                    !chartDataLoading &&
                    <>
                      {
                        Object.keys(indexBreakdownStats).length === 0 &&
                        <NoResultsText
                          text={'No details to display'}
                        />
                      }
                      {
                        Object.keys(indexBreakdownStats).length > 0 &&
                        <>
                          <IndexBreakdownHeaderStats
                            data={indexBreakdownStats}
                          />
                          <Subtitle
                            text={'Rezzil Board Test'}
                          />
                          <IndexBreakdownBody
                            data={indexBreakdownStats.drillScores}
                          />
                        </>
                      }
                    </>
                  }
                </SectionContainer>
              </StyledSectionContainer>
            </>
          }
        </MainWrapper>
      }
    </>
  );
};

export default IndexStats;
