import {
  Container,
  InlineFieldContainer,
  PhoneFieldContainer,
  FieldContainer,
} from './TeamMemberForm.styles';
import {userRoles} from '../../../options/userRoles';
import CustomTextField from '../../Inputs/CustomTextField/CustomTextField';
import CustomAutocomplete from '../../Inputs/CustomAutocomplete/CustomAutocomplete';
import FieldError from '../../Inputs/FieldError/FieldError';
// Helpers
import getSortedCountryCodes from '../../../utils/helpers/getSortedCountryCodes';

const TeamMemberForm = ({formData, errors, onChange}) => {
  return (
    <Container>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'First name'}
            id={'firstname'}
            placeholder={'Joe'}
            required
            onChange={onChange}
            error={errors.firstname}
            type={'text'}
            value={formData.firstname}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Last name'}
            id={'lastname'}
            placeholder={'Bloggs'}
            required
            onChange={onChange}
            error={errors.lastname}
            type={'text'}
            value={formData.lastname}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Email'}
            id={'email'}
            placeholder={'email@email.com'}
            required
            onChange={onChange}
            error={errors.email}
            type={'email'}
            value={formData.email}
          />
        </FieldContainer>
        <PhoneFieldContainer>
          <FieldContainer>
            <CustomAutocomplete
              label={'Code'}
              id={'telephoneCountryCode'}
              placeholder={'44'}
              onChange={onChange}
              error={errors.telephoneCountryCode}
              value={formData.telephoneCountryCode}
              options={getSortedCountryCodes()}
              telephoneCountryCode
            />
          </FieldContainer>
          <FieldContainer>
            <CustomTextField
              label={'Phone'}
              id={'telephone'}
              placeholder={'eg. 7135684527'}
              onChange={onChange}
              error={errors.telephone}
              type={'text'}
              value={formData.telephone}
            />
          </FieldContainer>
        </PhoneFieldContainer>
      </InlineFieldContainer>
      <FieldContainer
        id={'role'}
      >
        <CustomAutocomplete
          label={'Role'}
          id={'role'}
          placeholder={'Select Role'}
          required
          onChange={onChange}
          error={errors.role}
          value={formData.role}
          options={userRoles}
        />
      </FieldContainer>
      {errors.saveError && <FieldError error={errors.saveError} />}
    </Container>
  )
};

export default TeamMemberForm;
