import {useEffect, useContext, useState} from 'react';
import {
  PlayerProfilesContainer,
  GroupsContainer,
} from './PlayerProfiles.styles';
import PageSpinner from '../../../components/Loading/PageSpinner/PageSpinner';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import SectionTitle from '../../../components/Typography/SectionTitle/SectionTitle';
import PlayerProfilesList from '../../../components/Tables/PlayerProfilesList/PlayerProfilesList';
import GroupsList from '../../../components/Tables/GroupsList/GroupsList';

// Contexts & Helpers
import {BaseContext} from '../../../providers/Global/BaseProvider';
import {PlayerProfilesContext} from '../../../providers/Index/PlayerProfilesProvider';
import emailIsValid from '../../../utils/validation/email';
import dateIsValid from '../../../utils/validation/date';
import {formatDateToSQLString} from '../../../utils/helpers/formatDateString';
import {
  getPlayerProfiles,
  getGroups,
} from '../../../utils/helpers/playerProfilesHelpers';

// API Calls
import {
  getClubPlayers,
  addClubPlayerProfile,
  updateClubPlayerProfile,
  deleteClubPlayerProfile,
  updateClubPlayerBoardStatus,
  addPlayerProfileGroup,
  deletePlayerProfileGroup,
  getClubGroups,
  addClubGroup,
  updateClubGroup,
  deleteClubGroup,
} from '../../../api';
import { routes } from '../../../options/routes';

const PlayerProfiles = () => {
  const baseContext = useContext(BaseContext);
  const playerProfilesContext = useContext(PlayerProfilesContext);
  const [clubId, setClubId] = useState('');
  const [dataLoading, setDataLoading] = useState(true);
  const [profileLoading, setProfileLoading] = useState(false);
  const [sendInviteLoading, setSendInviteLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const [groupDeleteLoading, setGroupDeleteLoading] = useState(false);
  const [leaderBoardLoading, setLeaderBoardLoading] = useState(false);
  const [showShoeSizeCheck, setShowShoeSizeCheck] = useState(false);
  const [profilesList, setProfilesList] = useState([]);
  const [groupsCheckboxOptions, setGroupsCheckboxOptions] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: '',
  });
  const [searchData, setSearchData] = useState({
    profileSearch: '',
  });
  const [searchResults, setSearchResults] = useState([]);
  const [profileData, setProfileData] = useState({
    id: undefined,
    clubId: '',
    firstname: '',
    lastname: '',
    email: '',
    dob: null,
    profile_role_id: '',
    position: '',
    dominant_foot: '',
    shoe_size_type: '',
    shoe_size: '',
    gender: '',
    nationality: '',
    profile_groups: [],
  });
  const [profileErrors, setProfileErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    dob: null,
    profile_role_id: '',
    position: '',
    dominant_foot: '',
    shoe_size_type: '',
    shoe_size: '',
    gender: '',
    nationality: '',
    profile_groups: [],
  });
  const [existingProfileGroupsIds, setExistingProfileGroupIds] = useState([]);
  const [sendInviteData, setSendInviteData] = useState({
    id: undefined,
    email: '',
  });
  const [sendInviteErrors, setSendInviteErrors] = useState({
    email: '',
  });
  const [groupData, setGroupData] = useState({
    id: undefined,
    label: '',
  });
  const [groupErrors, setGroupErrors] = useState({
    label: '',
  });

  useEffect(() => {
    if (playerProfilesContext.playerProfilesData.allLoaded) {
      setDataLoading(false);
      if (playerProfilesContext.playerProfilesData.apiError) {
        setError({
          show: true,
          message: 'Unable to load some or all page content. Please refresh and try again.'
        });
      }

      // Set club id
      const {id} = baseContext.baseData.clubProfile;
      setProfileData({
        id: undefined,
        clubId: id,
        firstname: '',
        lastname: '',
        email: '',
        dob: null,
        profile_role_id: '',
        position: '',
        dominant_foot: '',
        shoe_size_type: '',
        shoe_size: '',
        gender: '',
        nationality: '',
        profile_groups: [],
      })
      setClubId(id);

      // Get data from playerProfilesContext
      const {playerProfiles, groups} = playerProfilesContext.playerProfilesData;

      // Set player profiles data
      if (playerProfiles && playerProfiles.length > 0) setProfilesList(playerProfiles);

      // Set groups data
      let checkboxOptions = getGroupsCheckboxOptions(groups);
      setGroupsList(groups);
      setGroupsCheckboxOptions(checkboxOptions);
    }
  }, [baseContext, playerProfilesContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handleSearchChange = e => {
    let data = {...searchData};
    data[e.target.id] = e.target.value;
    setSearchData(data);

    // Ensure all data is in lowercase for comparison logic to work correctly
    handleSearchSubmit(data.profileSearch.toLowerCase());
  };

  const handleSearchSubmit = searchTerm => {
    // Filter results with name and email
    const results = profilesList.filter(
      player =>
        `${player.firstname} ${player.lastname}`.toLowerCase().includes(searchTerm) ||
        player.email.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  };

  const handleSearchClear = () => {
    setSearchData({
      profileSearch: '',
    });
    setSearchResults([]);
  };

  const getGroupsCheckboxOptions = groups => {
    // Create checkbox options array to display in profile form
    let checkboxOptions = [];
    if (groups && groups.length > 0) {
      groups.forEach(group => {
        const option = {
          id: group.id,
          checked: false,
          label: group.label,
        };
        checkboxOptions.push(option);
      });
    }

    return checkboxOptions;
  };

  const handleLeaderBoardChange = async (e, id) => {
    const value = e.target.checked;
    const formData = {value};
    await setLeaderBoardLoading(true);
    await handleBannerClose();

    // Trigger API call
    const data = await updateClubPlayerBoardStatus(id, formData);
    if (!data || !data.success || data.code !== 200) {
      setError({
        show: true,
        message: 'There was an error with the request. Please try again.'}
      );
      setLeaderBoardLoading(false);
    }

    setError({
      ...error,
      message: 'Profile has been successfully updated',
    });

    // Update profile data for the profile being changed
    let profiles = [...profilesList];
    profiles.find(profile => profile.id === id).leaderboards_active = Number(value).toString();
    setProfilesList(profiles);

    setLeaderBoardLoading(false);
  };

  const addPlayerProfileGroups = async (profileId, existingGroupIds, groupIds) => {
    // Check for new groups (in groupIds but not in existingGroupIds) and create array of groupsToAdd
    let groupsToAdd = [],
      hasErrors = false;
    groupIds.length > 0 && groupIds.forEach(groupId => {
      if (!existingGroupIds.includes(groupId)) groupsToAdd.push(groupId);
    });

    // Iterate over groupIdsToAdd and call addPlayerProfileGroup
    if (groupsToAdd.length > 0) {
      let responses = [];
      for (const group of groupsToAdd) {
        const formData = {
          groupId: group,
          profileId,
          clubId,
        };
        const groupData = await addPlayerProfileGroup(formData);
        responses.push(groupData);
      }

      // Set hasErrors to true if any errors
      responses.length > 0 && responses.forEach(response => {
        if (!response || !response.success || response.code !== 201) hasErrors = true;
      });
    }

    // Return true if all calls are successful
    return !hasErrors;
  };

  const deletePlayerProfileGroups = async (profileId, existingGroupIds, groupIds) => {
    // Check for removed groups (not in groupIds but in existingGroupIds) and create array of groupsToDelete
    let groupsToDelete = [],
      hasErrors = false;
    existingGroupIds.length > 0 && existingGroupIds.forEach(groupId => {
      if (!groupIds.includes(groupId)) groupsToDelete.push(groupId);
    });

    // Iterate over groupIdsToAdd and call deletePlayerProfileGroup
    if (groupsToDelete.length > 0) {
      let responses = [];
      for (const group of groupsToDelete) {
        const formData = {
          groupId: group,
          profileId,
        };
        const groupData = await deletePlayerProfileGroup(formData);
        responses.push(groupData);
      }

      // Set hasErrors to true if any errors
      responses.length > 0 && responses.forEach(response => {
        if (!response || !response.success || response.code !== 200) hasErrors = true;
      });
    }

    // Return true if all calls are successful
    return !hasErrors;
  };

  const updatePlayerProfilesList = async () => {
    // Get new profiles list
    const profilesData = await getClubPlayers(clubId);
    if (!profilesData || !profilesData.success || profilesData.code !== 200) {
      return false;
    }

    // Reset player profiles list data
    const {profiles} = profilesData.data;
    const playerProfiles = getPlayerProfiles(profiles);
    setProfilesList(playerProfiles);
    return true;
  };

  const handleProfileChange = (e, id, val) => {
    // Clear errors
    setProfileErrors({
      firstname: '',
      lastname: '',
      email: '',
      dob: null,
      profile_role_id: '',
      position: '',
      dominant_foot: '',
      shoe_size_type: '',
      shoe_size: '',
      gender: '',
      nationality: '',
      profile_groups: [],
    });
    handleBannerClose();

    // Update profileData
    let data = {...profileData};
    if (e && e.target.type && e.target.type === 'checkbox') {
      // For checkbox fields
      if (e.target.checked && !data.profile_groups.includes(e.target.id)) {
        // Add to groups list if checked and id is not already in it
        data.profile_groups.push(e.target.id);
      } else {
        // Remove from groups list if unchecked
        const index = data.profile_groups.findIndex(el => el === e.target.id);
        if (index !== -1) data.profile_groups.splice(index, 1);
      }
    } else if (!e && (id)) {
      // For date field
      data[id] = val;
    } else if (e && id) {
      // For autocomplete field
      data[id] = val;
    } else {
      // For all other fields
      data[e.target.id] = e.target.value;
      // // Show shoe size check modal
      // if (e.target.id === 'shoe_size' && e.target.value) setShowShoeSizeCheck(true);
    }
    setProfileData(data);
  };

  const getFormData = (data, setDob=true) => {
    // Remove any blank params from form data and change dob to SQL string if setDob is true (true by default)
    const formData = {...data};
    if (setDob) formData.dob = formatDateToSQLString(formData.dob);

    const keysToDelete = ['id', 'profile_groups'];
    Object.entries(formData).forEach(([key, val]) => {
      if (!keysToDelete.includes(key) && !val) keysToDelete.push(key);
    });
    keysToDelete.forEach(key => delete formData[key]);

    return {
      clubId,
      ...formData
    };
  }

  const handleProfileSubmit = async () => {
    await setProfileLoading(true);
    // Set form errors
    let formErrors = {...profileErrors};
    let hasErrors = false;
    if(!profileData.firstname) {
      formErrors = {
        ...formErrors,
        firstname: 'First Name is required'
      };
      hasErrors = true;
    }
    if(!profileData.lastname) {
      formErrors = {
        ...formErrors,
        lastname: 'Last Name is required'
      };
      hasErrors = true;
    }
    if(profileData.email && !emailIsValid(profileData.email)) {
      formErrors = {
        ...formErrors,
        email: 'A valid Email is required'
      };
      hasErrors = true;
    }
    if(!dateIsValid(profileData.dob)) {
      formErrors = {
        ...formErrors,
        dob: 'A valid DOB is required'
      };
      hasErrors = true;
    }
    if(!profileData.position) {
      formErrors = {
        ...formErrors,
        position: 'Position is required'
      };
      hasErrors = true;
    }
    if(!profileData.shoe_size_type) {
      formErrors = {
        ...formErrors,
        shoe_size_type: 'Shoe Size Type is required'
      };
      hasErrors = true;
    }
    if(!profileData.shoe_size) {
      formErrors = {
        ...formErrors,
        shoe_size: 'Shoe Size is required'
      };
      hasErrors = true;
    }
    if(!profileData.gender) {
      formErrors = {
        ...formErrors,
        gender: 'Gender is required'
      };
      hasErrors = true;
    }
    if(!profileData.nationality) {
      formErrors = {
        ...formErrors,
        nationality: 'Nationality is required'
      };
      hasErrors = true;
    }

    // Set errors and return if there are errors
    setProfileErrors(formErrors);
    if (hasErrors) {
      setProfileLoading(false);
      return false;
    }

    // Change dob to SQL string, set clubId (for updating) and delete unwanted fields
    const formData = getFormData(profileData);

    // Trigger API call - update if id or create if no id
    const data = profileData.id
      ? await updateClubPlayerProfile(profileData.id, formData)
      : await addClubPlayerProfile(formData);
    // Success code when creating is 201, or 200 when deleting
    if (!data || !data.success || data.code !== (profileData.id ? 200 : 201)) {
      setError({
        show: true,
        message: 'There was an error with the request. Please try again.'}
      );
      setProfileLoading(false);
      return false;
    }

    // Add or delete groups if required
    const profileId = profileData.id ? profileData.id : data.data.id;
    const selectedGroupIds = profileData.profile_groups;
    const addGroupsSuccess = await addPlayerProfileGroups(profileId, existingProfileGroupsIds, selectedGroupIds);
    if (!addGroupsSuccess) {
      setError({
        show: true,
        message: `Profile has been ${profileData.id ? 'updated' : 'added'}, but there was an error when updating groups information`
      });
      setProfileLoading(false);
      return false;
    }
    const deleteGroupsSuccess = await deletePlayerProfileGroups(profileId, existingProfileGroupsIds, selectedGroupIds);
    if (!deleteGroupsSuccess) {
      setError({
        show: true,
        message: `Profile has been ${profileData.id ? 'updated' : 'added'}, but there was an error when updating groups information`
      });
      setProfileLoading(false);
      return false;
    }

    // Get new profiles list
    const updateProfilesListSuccess = await updatePlayerProfilesList();
    if (!updateProfilesListSuccess) {
      setError({
        show: true,
        message: `Profile has been ${profileData.id ? 'updated' : 'added'}, but there was an error when getting the updated information`
      });
      setProfileLoading(false);
      return false;
    }

    setError({
      ...error,
      message: `Profile has been successfully ${profileData.id ? 'updated' : 'added'}`,
    });

    // Reset relevant state data
    setProfileData({
      id: undefined,
      clubId,
      firstname: '',
      lastname: '',
      email: '',
      dob: null,
      profile_role_id: '',
      position: '',
      dominant_foot: '',
      shoe_size_type: '',
      shoe_size: '',
      gender: '',
      nationality: '',
      profile_groups: [],
    });
    setExistingProfileGroupIds([]);
    setProfileLoading(false);
    return true;
  };

  const handleProfileDelete = async id => {
    await setDeleteLoading(true);
    await handleBannerClose();

    // Trigger API call
    const data = await deleteClubPlayerProfile(id);
    if (!data || !data.success || data.code !== 200) {
      setError({
        show: true,
        message: 'There was an error with the request. Please try again.'}
      );
      setDeleteLoading(false);
      return false;
    }

    setError({
      ...error,
      message: 'Profile has been successfully deleted',
    })

    // Remove profile from array on success
    const profiles = profilesList.filter(profile => profile.id !== id);
    setProfilesList(profiles);

    setDeleteLoading(false);
    return true;
  };

  const handleSendInviteChange = e => {
    // Clear errors
    setSendInviteErrors({
      email: '',
    });
    handleBannerClose();

    // Update sendInviteData
    let data = {...sendInviteData};
    data[e.target.id] = e.target.value;
    setSendInviteData(data);
  };

  const handleSendInviteSubmit = async () => {
    await setSendInviteLoading(true);

    // Set form errors
    let formErrors = {...sendInviteErrors};
    let hasErrors = false;
    if(!emailIsValid(sendInviteData.email)) {
      formErrors = {
        ...formErrors,
        email: 'A valid Email is required'
      };
      hasErrors = true;
    }

    // Set errors and return false if there are errors
    setSendInviteErrors(formErrors);
    if (hasErrors) {
      setSendInviteLoading(false);
      return false;
    }

    // Get form data without id
    const formData = getFormData(sendInviteData, false);

    // Trigger API call to update profile
    const data = await updateClubPlayerProfile(sendInviteData.id, formData);
    if (!data || !data.success || data.code !== 200) {
      setError({
        show: true,
        message: 'There was an error with the request. Please try again.'}
      );
      setSendInviteLoading(false);
      return false;
    }

    // Get new profiles list
    const updateProfilesListSuccess = await updatePlayerProfilesList();
    if (!updateProfilesListSuccess) {
      setError({
        show: true,
        message: `Invitation has been sent, but there was an error when getting the updated information`
      });
      setSendInviteLoading(false);
      return false;
    }

    setError({
      show: false,
      message: 'Request successfully submitted. An invitation email has been sent to the user',
    });
    setSendInviteLoading(false);
    return true;
  };

  const handleGroupChange = e => {
    // Clear errors
    setGroupErrors({
      label: '',
    });
    handleBannerClose();

    // Update profileData
    let data = {...groupData};
    data[e.target.id] = e.target.value;
    setGroupData(data);
  };

  const handleGroupSubmit = async () => {
    await setGroupLoading(true);
    // Set form errors
    let formErrors = {...groupErrors};
    let hasErrors = false;
    if(!groupData.label) {
      formErrors = {
        ...formErrors,
        label: 'Name is required'
      };
      hasErrors = true;
    }

    // Set errors and return if there are errors
    setGroupErrors(formErrors);
    if (hasErrors) {
      setGroupLoading(false);
      return false;
    }

    // Add the clubId to the params if creating new group
    if (!groupData.id) groupData.clubId = clubId;

    // Trigger API call - update if id or create if no id
    const data = groupData.id ? await updateClubGroup(groupData.id, groupData) : await addClubGroup(groupData);
    if (!data || !data.success || data.code !== 200) {
      setError({
        show: true,
        message: 'There was an error with the request. Please try again.'
      });
      setGroupLoading(false);
      return false;
    }

    // Get new groups list
    const groupsData = await getClubGroups(clubId);
    if (!groupsData || !groupsData.success || groupsData.code !== 200) {
      setError({
        show: true,
        message: `Group has been ${groupData.id ? 'updated' : 'added'}, but there was an error when getting the updated information`
      });
      setGroupLoading(false);
      return true;
    }

    // Reset groups list data and add new/updated group to groupsCheckboxOptions options
    const groups = getGroups(groupsData.data);
    let checkboxOptions = getGroupsCheckboxOptions(groups);
    setGroupsList(groups);
    setGroupsCheckboxOptions(checkboxOptions);

    setError({
      ...error,
      message: `Group has been successfully ${groupData.id ? 'updated' : 'added'}`,
    });

    // Reset state groupData
    setGroupData({
      id: undefined,
      label: '',
    });
    setGroupLoading(false);
    return true;
  };

  const handleGroupDelete = async id => {
    await setGroupDeleteLoading(true);
    await handleBannerClose();

    // Trigger API call
    const data = await deleteClubGroup(id);
    if (!data || !data.success || data.code !== 200) {
      setError({
        show: true,
        message: 'There was an error with the request. Please try again.'}
      );
      setGroupDeleteLoading(false);
      return false;
    }

    setError({
      ...error,
      message: 'Group has been successfully deleted',
    });

    // Remove profile from array on success and remove from gropusCheckboxOptions
    const groups = groupsList.filter(group => group.id !== id);
    let checkboxOptions = getGroupsCheckboxOptions(groups);
    setGroupsList(groups);
    setGroupsCheckboxOptions(checkboxOptions);

    setGroupDeleteLoading(false);
    return true;
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.indexPlayerProfiles}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
        >
          {
            // Show in this component when leaderboard toggle is being changed to allow table to re-render afterwards
            leaderBoardLoading &&
            <PageSpinner
              open={leaderBoardLoading}
            />
          }
          <Title
            text={'Player Profiles'}
          />
          <PlayerProfilesContainer>
            <SectionTitle
              text={'Player Profiles'}
            />
            <PlayerProfilesList
              onSearchChange={handleSearchChange}
              onSearchClear={handleSearchClear}
              searchData={searchData}
              profiles={searchData.profileSearch ? searchResults : profilesList}
              setExistingProfileGroupIds={setExistingProfileGroupIds}
              handleDelete={handleProfileDelete}
              deleteLoading={deleteLoading}
              onToggleChange={handleLeaderBoardChange}
              onFieldChange={handleProfileChange}
              onSubmit={handleProfileSubmit}
              formData={profileData}
              formErrors={profileErrors}
              setFormData={setProfileData}
              setFormErrors={setProfileErrors}
              updateLoading={profileLoading}
              checkboxOptions={groupsCheckboxOptions}
              showNestedModal={showShoeSizeCheck}
              setNestedModalClose={setShowShoeSizeCheck}
              sendInviteData={sendInviteData}
              setSendInviteData={setSendInviteData}
              sendInviteErrors={sendInviteErrors}
              setSendInviteErrors={setSendInviteErrors}
              onSendInviteFieldChange={handleSendInviteChange}
              onSendInviteSubmit={handleSendInviteSubmit}
              sendInviteLoading={sendInviteLoading}
              setError={setError}
            />
          </PlayerProfilesContainer>
          <GroupsContainer>
            <SectionTitle
              text={'Groups'}
            />
            <GroupsList
              groups={groupsList}
              handleDelete={handleGroupDelete}
              deleteLoading={groupDeleteLoading}
              onFieldChange={handleGroupChange}
              onSubmit={handleGroupSubmit}
              formData={groupData}
              formErrors={groupErrors}
              setFormData={setGroupData}
              setFormErrors={setGroupErrors}
              updateLoading={groupLoading}
            />
          </GroupsContainer>
        </MainWrapper>
      }
    </>
  );
};

export default PlayerProfiles;
