import {
  styled,
  Button,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledButton = styled(Button)(({ theme, add, reset, small }) => ({
  ...theme.typography.h5,
  borderColor: 'transparent',
  borderRadius: add ? 20 : 40,
  color: reset ? colors.white : colors.primaryOrange,
  padding: small ? '10px 15px' : '10px 20px',
  '&.Mui-disabled': {
    opacity: 0.7,
    color: colors.primaryGrey,
  },
}));
