import {useState} from 'react';
import {
  StyledTable,
  LargeTableBody,
  StyledTableRow,
  StyledTableCell,
} from './ClubTopTenList.styles';
import {indexTopTenColumns} from '../../../options/tableColumns';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import CustomTableHead from '../CustomTableHead/CustomTableHead';

// Helpers
import {getComparator, stableSort, handleRequestSort} from '../../../utils/tableSortHelpers/tableSortHelpers';

const ClubTopTenList = ({data}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  return (
    <>
      <CustomTableWrapper
        noResultsText={(!data || data.length === 0) && 'No details to display'}
        fullHeight
      >
        <StyledTable>
          <CustomTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(e, id) => handleRequestSort(e, id, orderBy, order, setOrder, setOrderBy)}
            columnHeadings={indexTopTenColumns}
            small
          />
          <LargeTableBody>
            {
              data.length > 0 &&
              stableSort(data, getComparator(order, orderBy))
                .map(({id, rank, name, birthYear, topScore, avgScore, lastScore, worldRank}) => (
                  <StyledTableRow
                    key={id}
                  >
                    <StyledTableCell>
                      {rank}
                    </StyledTableCell>
                    <StyledTableCell>
                      {name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {birthYear}
                    </StyledTableCell>
                    <StyledTableCell>
                      {topScore}
                    </StyledTableCell>
                    <StyledTableCell>
                      {avgScore}
                    </StyledTableCell>
                    <StyledTableCell>
                      {lastScore}
                    </StyledTableCell>
                    <StyledTableCell>
                      {worldRank}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            }
          </LargeTableBody>
        </StyledTable>
      </CustomTableWrapper>
    </>
  );
};

export default ClubTopTenList;
