import {
  InfoBlockContainer,
  Icon,
  InfoText,
  InfoTextContainer,
} from './InfoBlock.styles';
import {
  LightbulbOutlined
} from '@mui/icons-material';
const InfoBlock = ({info, isMultiLine, noMargin}) => {
  return (
    <InfoBlockContainer
      multiline={isMultiLine ? 'true' : undefined}
      nomargin={noMargin ? 'true' : undefined}
    >
      <Icon><LightbulbOutlined /></Icon>
      <InfoTextContainer>
        {info.map((text) => <InfoText key={text}>{text}</InfoText>)}
      </InfoTextContainer>

    </InfoBlockContainer>
  );
};

export default InfoBlock;
