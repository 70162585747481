import {useEffect, useState, useCallback} from 'react';
import {
  InnerContainer,
  StyledText,
  FieldContainer,
} from './LoginVerify.styles';
import {routes} from '../../../options/routes';
import AuthenticationWrapper from '../../../components/Wrappers/AuthenticationWrapper/AuthenticationWrapper';
import AuthFormContainer from '../../../components/Containers/AuthFormContainer/AuthFormContainer';
import CustomTextField from '../../../components/Inputs/CustomTextField/CustomTextField';
import OutlinedButton from '../../../components/Buttons/OutlinedButton/OutlinedButton';
import SectionSpinner from '../../../components/Loading/SectionSpinner/SectionSpinner';

// Helpers
import {
  getUrlLoginIDParam,
  getUrlVerificationTypeParam,
} from '../../../utils/helpers/getUrlParam';
import setLocalStorage from '../../../utils/helpers/setLocalStorage';

// API calls
import {login} from '../../../api';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [verificationType] = useState(getUrlVerificationTypeParam());
  const [formData, setFormData] = useState({
    login_id: getUrlLoginIDParam(),
    code: '',
  });
  const [errors, setErrors] = useState({
    code: '',
    api: ''
  });

  const handleSubmit = useCallback(async () => {
    /* Memoize function to prevent creating new function on every render */
    setLoading(true);
    // Set form errors
    let formErrors = {...errors};
    let hasErrors = false;
    if (!formData.code) {
      formErrors = {...formErrors, code: 'Verification Code is required'};
      hasErrors = true;
    }

    // Set errors and return if there are errors
    setErrors(formErrors);
    if (hasErrors) {
      setLoading(false);
      return;
    }

    // Trigger API call
    const data = await login(formData);
    if (!data || !data.success || !(data.data.user && Object.keys(data.data.user).length)) {
      setErrors({
        ...errors,
        api: 'Please check your login details are correct. If you continue to experience issues, please go back to ' +
             'the initial login screen and try again'
      });
      setLoading(false);
      return;
    }

    // Save account details in local storage and redirect to home if direct login
    setLocalStorage(data.data);
  }, [errors, formData]);

  useEffect(() => {
    const onEnterKeyPress = e => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.preventDefault();
        if (!loading) {
          handleSubmit();
        }
      }
    };

    document.addEventListener('keydown', onEnterKeyPress);

    return () => {
      document.removeEventListener('keydown', onEnterKeyPress);
    };
  }, [formData, handleSubmit, loading]);

  const handleBannerClose = () => {
    setErrors({...errors, api: ''});
  };

  const handleFieldChange = e => {
    // Clear errors
    setErrors({
      email: '',
      login_id: '',
      code: '',
      api: ''
    });

    // Update formData
    let data = {...formData};
    data[e.target.id] = e.target.value;
    setFormData(data);
  };

  const handleLinkClick = () => {
    window.location.href = routes.login;
  };

  return (
    <AuthenticationWrapper
      apiMessage={errors.api}
      error={errors.api}
      handleBannerClose={handleBannerClose}
    >
      <AuthFormContainer
        btnText={'Back to login'}
        onClick={() => handleLinkClick()}
      >
        <InnerContainer>
          <StyledText>
            Please check your {verificationType === 'sms' ? 'texts' : 'emails'} for the verification code.
          </StyledText>
          <FieldContainer>
            <CustomTextField
              label={'Verification code'}
              id={'code'}
              placeholder={'123456'}
              required
              onChange={handleFieldChange}
              error={errors.code}
              type={'text'}
              value={formData.code}
            />
          </FieldContainer>
          {
            loading &&
            <SectionSpinner/>
          }
          {
            !loading &&
            <OutlinedButton
              text={'Submit'}
              onClick={handleSubmit}
              fullWidth
            />
          }
        </InnerContainer>
      </AuthFormContainer>
    </AuthenticationWrapper>
  )
};

export default Login;
