import {styled} from '@mui/material';

export const RezzilLogoContainer = styled('div')(() => ({}));

export const RezzilLogo = styled('img')(({ theme }) => ({
  width: 100,
  translate: 'translateY(2px)',
  [theme.breakpoints.down('sm')]: {
    width: 80,
  },
}));
