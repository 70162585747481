import {
  Container,
  DetailsContainer,
  TextContainer,
  TitleText,
  DescriptionText,
  ScoreContainer,
  ScoreText,
  MaxScoreText,
} from './ProgrammeStatsList.styles';
import {indexAccordionOptions} from '../../../options/indexAccordionOptions';

const ProgrammeStatsList = ({data}) => {
  return (
    <Container>
      {
        indexAccordionOptions && indexAccordionOptions.length > 0 && indexAccordionOptions.map(({statId, title, description}) => (
          <DetailsContainer
            key={statId}
          >
            <TextContainer>
              <TitleText>
                {title}
              </TitleText>
              <DescriptionText>
                {description}
              </DescriptionText>
            </TextContainer>
            <ScoreContainer>
              <ScoreText>
                {data[statId] ? data[statId] : '--'}
              </ScoreText>
              <MaxScoreText>
                / 100
              </MaxScoreText>
            </ScoreContainer>
          </DetailsContainer>
        ))
      }
    </Container>
  );
};

export default ProgrammeStatsList;
