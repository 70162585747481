import {
  Container,
  StyledText,
  WarningIcon,
} from './FieldError.styles';

const FieldError = ({error}) => {
  return (
    <Container>
      <WarningIcon/>
      <StyledText>
        {error}
      </StyledText>
    </Container>
  );
};

export default FieldError;
