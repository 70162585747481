import { useEffect, useState, useCallback, createContext, useContext } from 'react';

import { routes } from '../../options/routes';
// API calls
import { getSquadMembers, getSquadPrimaryInfo } from '../../api';
// Contexts
import { BaseContext } from '../Global/BaseProvider';
import { getSquadLeaderBoardData } from '../../utils/helpers/leaderboardsHelpers';
import { getAPIData, hasAPIError } from '../../utils/helpers/apiHelper';
import { squadLeaderboardTableNames } from '../../options/squadLeaderboards';

const GlobalLeaderBoardsContext = createContext({});

const GlobalLeaderBoardsProvider = ({ children }) => {
  const baseContext = useContext(BaseContext);
  const [globalLeaderBoardsData, setGlobalLeaderBoardsData] = useState({});

  const getGlobalLeaderBoardsData = useCallback(async () => {
    // Only trigger API call for globalLeaderBoards route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.rezzilPlayerGlobalLeaderBoards) return;
    // Call API
    if (!baseContext.baseData.loading && !globalLeaderBoardsData.loading) {
      setGlobalLeaderBoardsData({
        loading: true,
      })
      const { id } = baseContext.baseData.clubProfile;
      const squadPrimaryInfoResponse = await getSquadPrimaryInfo();
      if (
        hasAPIError(squadPrimaryInfoResponse)
      ) {
        setGlobalLeaderBoardsData({
          loading: false,
          globalLeaderBoardStats: null,
          squadMembers: [],
          apiError: true,
          allLoaded: true,
        });
        return;
      }
      const primarySquadInfo = getAPIData(squadPrimaryInfoResponse);
      if (!primarySquadInfo || (Array.isArray(primarySquadInfo) && !primarySquadInfo.length)) {
        setGlobalLeaderBoardsData({
          loading: false,
          globalLeaderBoardStats: null,
          squadMembers: [],
          apiError: false,
          allLoaded: true,
        });
        return;
      }
      const squadMembersResponse = await getSquadMembers(id);
      if (
        hasAPIError(squadMembersResponse)
      ) {
        setGlobalLeaderBoardsData({
          loading: false,
          globalLeaderBoardStats: null,
          squadMembers: [],
          apiError: true,
          allLoaded: true,
        });

        return;
      }

      const { squadMembers } = getAPIData(squadMembersResponse);
      const primarySquadId = primarySquadInfo.primaryId;
      const formattedSquadMembers = squadMembers.map((member) => {
        return {
          ...member,
          id: `${primarySquadId}...${member.name}`
        }
      })
      const leaderBoards = [];
      const PRELOADAED_TABLES = 10;
      for (let i = 0; i < PRELOADAED_TABLES; i++) {
        const leaderboardData = await getSquadLeaderBoardData({
          playerChoicesIds: [formattedSquadMembers[0].id],
          leaderboardName: squadLeaderboardTableNames[i],
        });
        leaderBoards.push(leaderboardData);
      }

      // Set data
      setGlobalLeaderBoardsData({
        loading: false,
        numberOfTablesPreLoaded: PRELOADAED_TABLES,
        leaderBoards,
        squadMembers: formattedSquadMembers,
        apiError: false,
        allLoaded: true,
      });
    }

  }, [baseContext]);

  useEffect(() => {
    getGlobalLeaderBoardsData();
  }, [getGlobalLeaderBoardsData]);

  return (
    <GlobalLeaderBoardsContext.Provider
      value={{
        globalLeaderBoardsData,
        getGlobalLeaderBoardsData
      }}
    >
      {children}
    </GlobalLeaderBoardsContext.Provider>
  );
}

export { GlobalLeaderBoardsProvider, GlobalLeaderBoardsContext };

export default GlobalLeaderBoardsProvider;
