export const indexBreakdownStandardScoresOptions = [
  {
    statId: 'selected',
    title: 'Selected',
  },
  {
    statId: 'lastScore',
    title: 'Last',
  },
  {
    statId: 'change',
    title: 'Change',
  },
];

export const indexBreakdownStatScoresOptions = [
  {
    statId: 'composure',
    title: 'Composure',
  },
  {
    statId: 'reaction',
    title: 'Reaction',
  },
  {
    statId: 'accuracy',
    title: 'Accuracy',
  },
  {
    statId: 'adaptability',
    title: 'Adaptability',
  },
];

export const indexBreakdownDrillScoresOptions = [
  {
    statId: 'rondo-scan',
    title: 'Rondo Scan',
  },
  {
    statId: 'colour-combos',
    title: 'Colour Combos',
  },
  {
    statId: 'wolfy',
    title: 'Wolfy',
  },
  {
    statId: 'shoulder-sums',
    title: 'Shoulder Sums',
  },
  {
    statId: 'pressure-pass',
    title: 'Pressure Pass',
  },
];

export const indexBreakdownSummaryOptions = {
  'rondo-scan': [
    {
      stat: 'goals',
      title: 'Goals',
    },
    {
      stat: 'accuracy',
      title: 'Accuracy',
    },
    {
      stat: 'avgDwell',
      title: 'Avg. Dwell',
    },
    {
      stat: 'footBias',
      title: 'Foot Bias',
    },
  ],
  'colour-combos': [
    {
      stat: 'strikes',
      title: 'Strikes',
    },
    {
      stat: 'missed',
      title: 'Missed',
    },
    {
      stat: 'strikeZone',
      title: 'Strike Zone',
    },
    {
      stat: 'avgDwell',
      title: 'Avg. Dwell',
    },
  ],
  wolfy: [
    {
      stat: 'correct',
      title: 'Correct',
    },
    {
      stat: 'timeouts',
      title: 'Timeout',
    },
    {
      stat: 'accuracy',
      title: 'Accuracy',
    },
    {
      stat: 'avgDecision',
      title: 'Avg. Decision',
    },
  ],
  'shoulder-sums': [
    {
      stat: 'goals',
      title: 'Goals',
    },
    {
      stat: 'scanFrequency',
      title: 'Scan Freq.',
    },
    {
      stat: 'avgDwell',
      title: 'Avg. Dwell',
    },
    {
      stat: 'footBias',
      title: 'Foot Bias',
    },
  ],
  'pressure-pass': [
    {
      stat: 'goals',
      title: 'Goals',
    },
    {
      stat: 'accuracy',
      title: 'Accuracy',
    },
    {
      stat: 'avgDwell',
      title: 'Avg. Dwell',
    },
    {
      stat: 'footBias',
      title: 'Foot Bias',
    },
  ],
};
