export const getClubPlayersList = (players, isLeaderboards) => {
  // Return a list of club players to display in the player selection dropdown
  // Return null if no data
  if (players.length === 0) return [];

  // Return data in form of array of objects with the required data structure
  let clubPlayersList = [];
  players.forEach(({id, firstname, lastname, leaderboards_active}) => {
    // Do not add players who don't appear on leaderboards if getting list for leaderboards screens
    if (!isLeaderboards || (isLeaderboards && Number(leaderboards_active))) {
      const playerProfile = {
        id,
        firstname,
        lastname,
      };
      clubPlayersList.push(playerProfile);
    }
  });

  return clubPlayersList.length > 0 ? clubPlayersList : null;
};
