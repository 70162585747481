import {
  styled,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import {colors} from '../../../options/colors';

export const StyledButton = styled(LoadingButton)(({ theme}) => ({
  ...theme.typography.h5,
  borderColor: colors.primaryOrange,
  backgroundColor: colors.primaryOrange,
  color: colors.buttonTextBlack,
  width: '100%',
}));
