import {styled} from '@mui/material';

export const PlayerProfilesContainer = styled('div')(({ theme }) => ({
  marginBottom: 40,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 20,
  },
}));

export const GroupsContainer = styled('div')(() => ({}));
