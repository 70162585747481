import {useEffect, useCallback} from 'react';
import {colors} from '../../../options/colors';
import {indexChartLabels} from '../../../options/indexChartOptions';
import Chart from 'chart.js/auto';
import SectionSpinner from '../../Loading/SectionSpinner/SectionSpinner';
import NoResultsText from '../../Typography/NoResultsText/NoResultsText';

// Helpers
import {getRadarChartMax} from '../../../utils/helpers/indexPanelHelpers';

const IndexChart = ({ loading, id, firstDataset, secondDataset, datasetArray, colourArray }) => {

  const createChart = useCallback((datasetOne, datasetTwo = []) => {
    // Get canvas by ID - allows rendering different chart for desktop and mobiles to set different font sizes
    const chartId = `${id}RadarChart`
    const ctx = document.getElementById(chartId).getContext('2d');

    const drawLabelTitle = (chartCtx, text, x, y) => {
      // Reusable function to draw label title
      chartCtx.textAlign = 'left';
      chartCtx.font = '7px GraphikRegular, sans-serif';
      chartCtx.fillStyle = colors.primaryGrey;
      chartCtx.fillText(text, x, y);
      chartCtx.restore();
    };

    const drawPointValue = (chartCtx, color, text, x, y, isPrimary) => {
      // Reusable function to draw point value in label - larger size for primary label
      chartCtx.textAlign = 'center';
      chartCtx.font = `${isPrimary ? '12' : '10'}px GraphikMedium, sans-serif`;
      chartCtx.fillStyle = color;
      chartCtx.fillText(text, x, y);
      chartCtx.restore();
    };

    // Draw custom labels before chart is drawn
    const drawLabels = {
      id: 'labels',
      beforeDraw: function(chart) {
        const chartInstance = chart;
        const chartCtx = chartInstance.ctx;
        const pointLabels = chartInstance.scales.r._pointLabelItems
        pointLabels.forEach((label, index) => {
          const labelText = indexChartLabels[index];
          const pointOneValue = datasetOne[index].toString();
          const pointTwoValue = datasetTwo.length > 0 ? datasetTwo[index].toString() : '';
          const pointOneColor = pointTwoValue ? colors.secondaryOrange : index > 0 ? colors.white : colors.primaryGreen;
          let textX, textY, valueOneX, valueTwoX, valueY;

          if (index === 0) {
            // Set text positions for label one
            textX = label.left;
            textY = label.bottom - 22;
            valueOneX = pointTwoValue ? chartInstance.width / 2 + 10 : chartInstance.width / 2;
            valueTwoX = pointTwoValue && chartInstance.width / 2 - 10;
            valueY = label.bottom - 8;

            // Draw label
            drawLabelTitle(chartCtx, labelText, textX, textY);
            drawPointValue(chartCtx, pointOneColor, pointOneValue, valueOneX, valueY, true);
            if (pointTwoValue) {
              drawPointValue(chartCtx, colors.primaryGreen, pointTwoValue, valueTwoX, valueY, true);
            }
          } else if (index === 2) {
            // Set text positions for label three
            textX = label.left - 4;
            textY = label.bottom + 4;
            valueOneX = pointTwoValue ? label.left + 20 : label.left + 11;
            valueTwoX = pointTwoValue && label.left + 2;
            valueY = label.bottom + 18;

            // Draw label
            drawLabelTitle(chartCtx, labelText, textX, textY);
            drawPointValue(chartCtx, pointOneColor, pointOneValue, valueOneX, valueY);
            if (pointTwoValue) {
              drawPointValue(chartCtx, colors.primaryGreen, pointTwoValue, valueTwoX, valueY);
            }
          } else if (index === 3) {
            // Set text positions for label three
            textX = label.left + 4;
            textY = label.bottom + 4;
            valueOneX = pointTwoValue ? label.left + 29 : label.left + 20;
            valueTwoX = pointTwoValue && label.left + 11;
            valueY = label.bottom + 18;

            // Draw label
            drawLabelTitle(chartCtx, labelText, textX, textY);
            drawPointValue(chartCtx, pointOneColor, pointOneValue, valueOneX, valueY);
            if (pointTwoValue) {
              drawPointValue(chartCtx, colors.primaryGreen, pointTwoValue, valueTwoX, valueY);
            }
          } else {
            // Set text positions for all other labels (two and five)
            textX = label.left;
            textY = label.bottom - 7;
            valueOneX = pointTwoValue ? label.left + 29 : label.left + 20;
            valueTwoX = pointTwoValue && label.left + 11;
            valueY = label.bottom + 7;

            // Draw label
            drawLabelTitle(chartCtx, labelText, textX, textY);
            drawPointValue(chartCtx, pointOneColor, pointOneValue, valueOneX, valueY);
            if (pointTwoValue) {
              drawPointValue(chartCtx, colors.primaryGreen, pointTwoValue, valueTwoX, valueY);
            }
          }
        });
      },
    };

    // Create second dataset object if datasetTwo is present
    const dataTwo = datasetTwo && {
      label: '',
      data: datasetTwo,
      fill: true,
      borderColor: colors.primaryGreen,
      borderWidth: 2,
      backgroundColor: colors.primaryGreenOpaque,
      pointBackgroundColor: colors.primaryGreen,
      pointBorderColor: 'transparent',
      pointRadius: 2,
      pointHoverBackgroundColor: colors.primaryGreen,
      pointHoverBorderColor: 'transparent',
      pointHoverRadius: 2,
      borderJoinStyle: 'round',
    };

    // Create data object for chart config
    const data = {
      labels: indexChartLabels,
      datasets: [
        {
          label: '',
          data: datasetOne,
          fill: true,
          borderColor: datasetTwo.length > 0 ? colors.secondaryOrange : colors.primaryGreen,
          borderWidth: 2,
          backgroundColor: datasetTwo.length > 0 ? colors.secondaryOrangeOpaque : colors.primaryGreenOpaque,
          pointBackgroundColor: datasetTwo.length > 0 ? colors.secondaryOrange : colors.primaryGreen,
          pointBorderColor: 'transparent',
          pointRadius: 2,
          pointHoverBackgroundColor: datasetTwo.length > 0 ? colors.secondaryOrange : colors.primaryGreen,
          pointHoverBorderColor: 'transparent',
          pointHoverRadius: 2,
          borderJoinStyle: 'round',
        },
        dataTwo,
      ],
    };

    // Create chart config object
    const config = {
      type: 'radar',
      data,
      options: {
        plugins: {
          // Hide legend
          legend: {
            display: false,
          },
          // Hide hover tooltip
          tooltip: {
            enabled: false,
          },
        },
        // Customise x and y axes, including ticks
        scales: {
          r: {
            beginAtZero: true,
            max: getRadarChartMax([...datasetOne, ...datasetTwo]),
            // Customise angle lines
            angleLines: {
              color: colors.primaryGrey,
            },
            // Customise grid lines - hide alternate grid lines and make border white
            grid: {
              circular: true,
              color: [
                'transparent', colors.primaryGrey,
                'transparent', colors.primaryGrey,
                'transparent', colors.primaryGrey,
                'transparent', colors.primaryGrey,
                'transparent', colors.white,
              ],
            },
            // Customise labels - make transparent to enable getting label positions when drawing custom labels
            pointLabels: {
              color: 'transparent',
              font: {
                family: 'GraphikRegular, sans-serif',
                size: 7,
              },
              padding: 5,
            },
            // Hide ticks
            ticks: {
              display: false,
            },
          },
        },
      },
      plugins: [drawLabels],
    };

    // Instantiate chart with config object
    new Chart(ctx, config);
  }, [id]);

  useEffect(() => {
    if (!loading && firstDataset && firstDataset.length > 0) {
      // Remove existing canvas before re-drawing to resolve error when parent re-renders
      const canvas = id === 'player' ? (
        '<canvas id="playerRadarChart"/>'
      ) : (
        '<canvas id="clubRadarChart"/>'
      );
      const chart = document.getElementById(`${id}RadarChart`);
      if (chart) chart.remove();
      const chartContainer = document.getElementById(`${id}ChartContainer`);
      chartContainer.innerHTML = canvas;
      createChart(firstDataset, secondDataset);
    }
  }, [id, loading, createChart, firstDataset, secondDataset]);

  return (
    <>
      {
        loading &&
        <SectionSpinner/>
      }
      {
        !loading && (!firstDataset || firstDataset.length === 0) &&
        <NoResultsText
          text={'No chart data'}
        />
      }
    </>
  );
};

export default IndexChart;
