import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({}));

export const DetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
  padding: '20px 0',
  borderTop: `1px solid ${colors.dividerGrey}`,
  '&:first-of-type': {
    paddingTop: 0,
    borderTop: 'none',
  },
  '&:last-of-type': {
    paddingBottom: 5,
  },
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
}));

export const TextContainer = styled('div')(({ theme }) => ({
  marginRight: 15,
  textAlign: 'left',
  [theme.breakpoints.down('xs')]: {
    margin: '0 0 5px',
  },
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 18,
  marginBottom: 5,
}));

export const DescriptionText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 13,
  color: colors.primaryGrey,
}));

export const ScoreContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexWrap: 'nowrap',
  marginLeft: 15,
  [theme.breakpoints.down('xs')]: {
    marginLeft: 0,
  },
}));

export const ScoreText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1,
  fontSize: 24,
  color: colors.secondaryOrange,
  marginRight: 5,
}));

export const MaxScoreText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  whiteSpace: 'nowrap',
}));
