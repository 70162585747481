import {styled, Button} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: 85,
  marginRight: 85,
  [theme.breakpoints.down('sm')]: {
    margin: 0,
  },
}));

export const HeadingContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  color: '#fff',
  marginBottom: 40
}));

export const TopContentContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridColumnGap: 40,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr'
  },
  gridTemplateColumns: '2fr 1.5fr'
}));

export const GridContentContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridColumnGap: 40,

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr'
  },
  gridTemplateColumns: 'repeat(3, 1fr)',
}));

export const ContentItem = styled('div')(({ border }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 17,
  marginBottom: 17,
  border: border ? `solid 1px ${colors.secondaryGrey}` : undefined,
  borderRadius: border ? 5 : undefined,
  padding: border ? '22px 20px' : undefined,
}));

export const MainPanelHeader = styled('div')(() => ({
  fontSize: 36,
  color: colors.white,
  marginBottom: 28
}));

export const MainPanelSubHeader = styled('div')(() => ({
  fontSize: 24,
  color: colors.white,
  marginTop: 10,
  marginBottom: 24,
}));

export const MainPanelBody = styled('div')(() => ({
  fontSize: 16,
  color: colors.primaryGrey,
  marginBottom: 17,
}));

export const PriceHeader = styled('div')(() => ({
  fontSize: 22,
  color: colors.white,
  marginTop: 11,
  marginBottom: 11,
}));


export const PriceBody = styled('div')(() => ({
  fontSize: 16,
  color: colors.primaryGrey,
  marginBottom: 11,
}));

export const StyledButton = styled(Button)(({ theme, full }) => ({
  ...theme.typography.h5,
  borderColor: colors.primaryOrange,
  padding: 0,
  height: 36,
  width: 146,
  '&.Mui-disabled': {
    opacity: 0.7,
    color: colors.primaryGrey,
  },
}));

