import {
  styled,
  FormGroup,
} from '@mui/material';
import {
  StyledLabel,
  UncheckedIcon,
  CheckedIcon,
  StyledCheckbox
} from '../CustomCheckbox/CustomCheckbox.styles';

export const Container = styled('div')(() => ({
  position: 'relative',
}));

export const StyledFormGroup = styled(FormGroup)(({ theme, error }) => ({
  position: 'relative',
  marginBottom: error ? 2 : 0,
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '20px 30px',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: '20px',
  },
}));

export {
  StyledLabel,
  UncheckedIcon,
  CheckedIcon,
  StyledCheckbox,
}
