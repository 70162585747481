import {
  Container,
  TextContainer,
  HeadlineContainer,
  DateText,
  SubjectText,
  BodyContainer,
  BodyText,
  ButtonContainer,
} from './UpdateRow.styles';
import {FileDownloadOutlined} from '@mui/icons-material';
import IconButton from '../Buttons/IconButton/IconButton';

const UpdateRow = ({created, subject, body, link}) => {
  const handleClick = () => {
    window.open(link, '_blank');
  }

  return (
    <Container>
      <TextContainer>
        <HeadlineContainer>
          <DateText>
            {created}
          </DateText>
          <SubjectText>
            {subject}
          </SubjectText>
        </HeadlineContainer>
        <BodyContainer>
          <BodyText>
            {body}
          </BodyText>
        </BodyContainer>
      </TextContainer>
      {
        link &&
        <ButtonContainer>
          <IconButton
            icon={<FileDownloadOutlined/>}
            onClick={handleClick}
          />
        </ButtonContainer>
      }
    </Container>
  );
};

export default UpdateRow;
