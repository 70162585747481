import {
  styled,
  Table,
} from '@mui/material';
import {
  StyledTableBody,
  StyledTableRow,
} from '../CustomTableWrapper/CustomTableWrapper.styles';
import {StyledTableCell} from '../CustomTableHead/CustomTableHead.styles';

export const StyledTable = styled(Table)(() => ({}));

export const FrequencyContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiButton-root': {
    marginLeft: 10,
  },
}));

export {
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
}
