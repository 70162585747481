import {styled, Button, Typography} from '@mui/material';
import {colors} from '../../options/colors';

export const DescriptionText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 13,
  color: colors.primaryGrey,
}));

export const EditableDataContainer = styled('div')(() => ({
  display: 'flex',
  marginBottom: 40,
  marginTop: 14,
  backgroundColor:  colors.dividerGrey,
  color: colors.primaryGrey,
  padding: 10,
  justifyContent: 'space-between',
  height: `46px`,
  alignItems: 'center',
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const StyledSectionContainer = styled('div')(({theme}) => ({
  flex: 2,
  marginBottom: 40,
  '&:last-of-type': {
    marginBottom: 0,
  },
  marginRight: 15,
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}));

export const CodeContainer = styled('div')(({theme}) => ({
  width: 245,
  border: `3px solid ${colors.primaryOrange}`,
  borderRadius: 5,
  marginLeft: 15,
  justifyContent: 'space-around',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 20,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    width: 'inherit',
    marginTop: 20,
  },
}));

export const EditableInfo = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginRight: 15,
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}));

export const Icon = styled('div')(({ color }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.primaryOrange,
  borderRadius: 25,
  height: 50,
  width: 50,
  color: colors.white,
}));


export const CodeTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  color: colors.primaryOrange,
}));


export const CodeBody = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: colors.secondaryGrey,
  fontSize: 13,
}));

export const Code =  styled(Typography)(({ theme }) => ({
  fontFamily: 'GraphikMedium, sans-serif',
  color: colors.white,
  fontSize: 26,
  letterSpacing: 2.6,
  lineHeight: `26px`,
}));

export const StyledButton = styled(Button)(({ theme}) => ({
  ...theme.typography.h5,
  borderColor: colors.primaryOrange,
  padding: '7px 20px',
  '.MuiSvgIcon-root': {
    transition: 'inherit',
    color: colors.primaryOrange,
  },
}));
