import {Fragment} from 'react';
import {
  ColorIcon,
  LegendText,
  LegendContainer,
  LabelContainer,
} from './BarChartLegend.styles';

const BarChartLegend = ({data, isDrills}) => {
  return (
    <LegendContainer>
      {
        data.length > 0 && data.map((player, idx) => {
          if (!player) {
            return <></>;
          }
          return (
            <Fragment
            key={idx}
            >
              <LabelContainer>
                <ColorIcon
                  color={player && player.color ? player.color : 'red'}
                />
                <LegendText>
                  {isDrills ? 'Session' : `P${idx + 1} Session`}
                </LegendText>
              </LabelContainer>
              <LabelContainer>
                <ColorIcon
                  color={player && player.avgColor ? player.avgColor : 'red'}
                />
                <LegendText>
                  {isDrills ? 'Player - Average' : `P${idx + 1} Avg.`}
                </LegendText>
              </LabelContainer>
            </Fragment>
          )
          })
      }
    </LegendContainer>
  );
};

export default BarChartLegend;
