import {useEffect, useState, useCallback, createContext, useContext} from 'react';
import { routes } from '../../options/routes';
import {
  getSquadMembers,
  getSquadStatAverages,
  getSquadStatsLast10,
  getSquadPrimaryInfo,
} from '../../api';
// Contexts
import {BaseContext} from '../Global/BaseProvider';

//Helpers
import {squadStatsHelper} from '../../utils/helpers/drillStatsHelper';
import { getAPIData, hasAPIError } from '../../utils/helpers/apiHelper';
const RezzilPlayerDrillStatsContext = createContext({});

const RezzilPlayerDrillStatsProvider = ({children}) => {
  const baseContext = useContext(BaseContext);
  const [drillStatsData, setDrillStatsData] = useState({
    playerStats: null,
    apiError: false,
    allLoaded: false,
  });

  const getDrillStatsData = useCallback(async () => {
    // Only trigger API call for drillStats route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.rezzilPlayerDrillStats) return;

    // Call API
    if (!baseContext.baseData.loading && !drillStatsData.loading) {
      const squadPrimaryInfoResponse = await getSquadPrimaryInfo();
      if (
        hasAPIError(squadPrimaryInfoResponse)
      ) {
        setDrillStatsData({
          loading: false,
          playerStats: {},
          squadMembers: null,
          allProgrammeSessions: [],
          drillStats: null,
          apiError: true,
          allLoaded: true,
        });
        return;
      }
      const primarySquadInfo = getAPIData(squadPrimaryInfoResponse);
      if (!primarySquadInfo || (Array.isArray(primarySquadInfo) && !primarySquadInfo.length)) {
        setDrillStatsData({
          loading: false,
          playerStats: {},
          squadMembers: null,
          allProgrammeSessions: [],
          drillStats: null,
          apiError: true,
          allLoaded: true,
        });
        return;
      }

      setDrillStatsData({ loading: true });
      const { clubProfile } = baseContext.baseData;
      // const clubData = await getClubPlayers(clubProfile.id);
      const squadMembersResponse = await getSquadMembers(clubProfile.id);

      if (hasAPIError(squadMembersResponse)) {
        setDrillStatsData({
          loading: false,
          playerStats: {},
          squadMembers: null,
          allProgrammeSessions: [],
          drillStats: null,
          apiError: true,
          allLoaded: true,
        });
        return;
      }

      // Check if userProfile id is in the current club data
      const { squadMembers } = getAPIData(squadMembersResponse);
      // Get first stats for the first selected player on load:
      const drillStatAverages = await getSquadStatAverages(squadMembers[0].id, '1');

      const drillHistory = await getSquadStatsLast10(squadMembers[0].id, '1');
      const formattedDrillStats = squadStatsHelper(
        getAPIData(drillStatAverages),
        getAPIData(drillHistory)
      );
      setDrillStatsData({
        loading: false,
        squadMembers,
        apiError: false,
        allLoaded: true,
        playerStats: formattedDrillStats,
      });
      return;
    }

  }, [baseContext,]);

  useEffect(() => {
    getDrillStatsData();
  }, [getDrillStatsData]);

  return (
    <RezzilPlayerDrillStatsContext.Provider
      value={{
        drillStatsData,
        getDrillStatsData
      }}
    >
      {children}
    </RezzilPlayerDrillStatsContext.Provider>
  );
}

export { RezzilPlayerDrillStatsProvider, RezzilPlayerDrillStatsContext};

export default RezzilPlayerDrillStatsProvider;
