// API calls
import {getAllProgrammeStats, getSingleProgrammeStats, getClubPlayerProfile, getDrillSummaryStats} from '../../api';
import getAgeFromDOB from './getAgeFromDOB';
import { getPlayerPositionString } from './indexPanelHelpers';

const getDrillLevel = score => {
  // Return level based on the score value - 0-4 is level 1, 5-9 is level 2, 10-14 is level 3
  let level = null;

  if (score !== null && !isNaN(score)) {
    if (score >= 0 && score < 5) {
      level = 1;
    } else if (score >= 5 && score < 10) {
      level = 2;
    } else {
      level = 3;
    }
  }

  return level;
};

const getScoreChangeValue = (lastScore, selectedScore) => {
  // Calculate difference between lastScore and selectedScore and convert to 2 d.p.
  const change = (lastScore - selectedScore).toFixed(2);

  // Strip the '-' from the result
  return change[0] === '-' ? change.toString().substring(1) : change;
};

const getTrendStringValue = (latestScore, selectedScore) => {
  // Get correct trend string based on difference between latest and selected scores
  let trend;

  if (latestScore > selectedScore) {
    trend = 'up';
  } else if (latestScore < selectedScore) {
    trend = 'down';
  } else {
    trend = 'unchanged';
  }

  return trend;
};

const getHeadlineScores = (latestProgramme, selectedProgramme) => {
  // Get headline scores for selected programme compared to latest programme
  const lastIndexScore = Number(latestProgramme.rezzilIndex.index)
    ? Number(latestProgramme.rezzilIndex.index).toFixed(2) :
    '0.00';
  const selectedIndexScore = Number(selectedProgramme.rezzilIndex.index)
    ? Number(selectedProgramme.rezzilIndex.index).toFixed(2) :
    '0.00';
  const indexChange = getScoreChangeValue(Number(lastIndexScore), Number(selectedIndexScore));
  const lastComposureScore = Number(latestProgramme.rezzilIndex.composure)
    ? Number(latestProgramme.rezzilIndex.composure).toFixed(2)
    : '0.00';
  const selectedComposureScore = Number(selectedProgramme.rezzilIndex.composure)
    ? Number(selectedProgramme.rezzilIndex.composure).toFixed(2)
    : '0.00';
  const lastReactionScore = Number(latestProgramme.rezzilIndex.reaction)
    ? Number(latestProgramme.rezzilIndex.reaction).toFixed(2)
    : '0.00';
  const selectedReactionScore = Number(selectedProgramme.rezzilIndex.reaction)
    ? Number(selectedProgramme.rezzilIndex.reaction).toFixed(2)
    : '0.00';
  const lastAccuracyScore = Number(latestProgramme.rezzilIndex.accuracy)
    ? Number(latestProgramme.rezzilIndex.accuracy).toFixed(2)
    : '0.00';
  const selectedAccuracyScore = Number(selectedProgramme.rezzilIndex.accuracy)
    ? Number(selectedProgramme.rezzilIndex.accuracy).toFixed(2)
    : '0.00';
  const lastAdaptabilityScore = Number(latestProgramme.rezzilIndex.technical)
    ? Number(latestProgramme.rezzilIndex.technical).toFixed(2)
    : '0.00';
  const selectedAdaptabilityScore = Number(selectedProgramme.rezzilIndex.technical)
    ? Number(selectedProgramme.rezzilIndex.technical).toFixed(2)
    : '0.00';

  return {
    selected: {
      score: selectedIndexScore,
      trend: '',
      showTrend: false,
      showMax: true,
    },
    lastScore: {
      score: lastIndexScore,
      trend: '',
      showTrend: false,
      showMax: true,
    },
    change: {
      score: indexChange,
      trend: getTrendStringValue(lastIndexScore, selectedIndexScore),
      showTrend: true,
      showMax: false,
    },
    composure: {
      score: selectedComposureScore,
      trend: getTrendStringValue(lastComposureScore, selectedComposureScore),
      showTrend: true,
      showMax: true,
    },
    reaction: {
      score: selectedReactionScore,
      trend: getTrendStringValue(lastReactionScore, selectedReactionScore),
      showTrend: true,
      showMax: true,
    },
    accuracy: {
      score: selectedAccuracyScore,
      trend: getTrendStringValue(lastAccuracyScore, selectedAccuracyScore),
      showTrend: true,
      showMax: true,
    },
    adaptability: {
      score: selectedAdaptabilityScore,
      trend: getTrendStringValue(lastAdaptabilityScore, selectedAdaptabilityScore),
      showTrend: true,
      showMax: true,
    },
    drillScores: {},
  };
};

const getRondoScanScores = drill => {
  // Get relevant stats for rondo scan drill
  return {
    level: getDrillLevel(Number(drill.latestStat.rondo_scan_score)),
    nextLevel: getDrillLevel(Number(drill.latestStat.rondo_scan_next)),
    path: Number(drill.latestStat.rondo_scan_score) ? Number(drill.latestStat.rondo_scan_score) : null,
    nextPath: Number(drill.latestStat.rondo_scan_next) ? Number(drill.latestStat.rondo_scan_next) : null,
    score: Number(drill.latestStat.score)
      ? Number(drill.latestStat.score).toFixed(0)
      : '-',
    highScore: Number(drill.allTime.topScore)
      ? Number(drill.allTime.topScore).toFixed(0)
      : '-',
    goals: `G${Number(drill.latestStat.goals) ? Number(drill.latestStat.goals).toFixed(0) : '-'} / `
      + `M${Number(drill.latestStat.missed) ? Number(drill.latestStat.missed).toFixed(0) : '-'}`,
    accuracy: Number(drill.latestStat.accuracy)
      ? `${Number(drill.latestStat.accuracy).toFixed(0)}%`
      : '-',
    avgDwell: Number(drill.latestStat.average_dwell)
      ? `${Number(drill.latestStat.average_dwell).toFixed(1)} secs`
      : '-',
    footBias: `L${
        Number(drill.latestStat.left_foot_shots)
          ? Number(drill.latestStat.left_foot_shots).toFixed(0)
          : '-'
      } / `
      + `R${
        Number(drill.latestStat.right_foot_shots)
          ? Number(drill.latestStat.right_foot_shots).toFixed(0)
          : '-'
      }`,
  };
};

const getColourCombosScores = drill => {
  // Get relevant stats for colour combo drill
  return {
    level: getDrillLevel(Number(drill.latestStat.colour_combos_score)),
    nextLevel: getDrillLevel(Number(drill.latestStat.colour_combos_next)),
    path: Number(drill.latestStat.colour_combos_score) ? Number(drill.latestStat.colour_combos_score) : null,
    nextPath: Number(drill.latestStat.colour_combos_next) ? Number(drill.latestStat.rondo_scan_next) : null,
    score: Number(drill.latestStat.score)
      ? Number(drill.latestStat.score).toFixed(0)
      : '-',
    highScore: Number(drill.allTime.topScore)
      ? Number(drill.allTime.topScore).toFixed(0)
      : '-',
    strikes: `C${Number(drill.latestStat.correct) ? Number(drill.latestStat.correct).toFixed(0) : '-'} / `
      + `I${Number(drill.latestStat.incorrect) ? Number(drill.latestStat.incorrect).toFixed(0) : '-'}`,
    missed: Number(drill.latestStat.missed)
      ? Number(drill.latestStat.missed).toFixed(0)
      : '-',
    strikeZone: Number(drill.latestStat.strike_zone)
      ? Number(drill.latestStat.strike_zone).toFixed(0)
      : '-',
    avgDwell: Number(drill.latestStat.average_dwell)
      ? `${Number(drill.latestStat.average_dwell).toFixed(1)} secs`
      : '-',
  };
};

const getWolfyScores = drill => {
  // Get relevant stats for wolfy drill
  return {
    level: getDrillLevel(Number(drill.latestStat.wolfy_score)),
    nextLevel: getDrillLevel(Number(drill.latestStat.wolfy_next)),
    path: Number(drill.latestStat.wolfy_score) ? Number(drill.latestStat.wolfy_score) : null,
    nextPath: Number(drill.latestStat.wolfy_next) ? Number(drill.latestStat.wolfy_next) : null,
    score: Number(drill.latestStat.score)
      ? Number(drill.latestStat.score).toFixed(0)
      : '-',
    highScore: Number(drill.allTime.topScore)
      ? Number(drill.allTime.topScore).toFixed(0)
      : '-',
    correct: `C${Number(drill.latestStat.correct) ? Number(drill.latestStat.correct).toFixed(0) : '-'} / `
      + `I${Number(drill.latestStat.incorrect) ? Number(drill.latestStat.incorrect).toFixed(0) : '-'}`,
    timeouts: Number(drill.latestStat.timeouts)
      ? Number(drill.latestStat.timeouts).toFixed(0)
      : '-',
    accuracy: Number(drill.latestStat.accuracy)
      ? `${Number(drill.latestStat.accuracy).toFixed(0)}%`
      : '-',
    avgDecision: Number(drill.latestStat.average_decision_time)
      ? `${Number(drill.latestStat.average_decision_time).toFixed(1)} secs`
      : '-',
  };
};

const getShoulderSumsScores = drill => {
  // Get relevant stats for shoulder sums drill
  return {
    level: getDrillLevel(Number(drill.latestStat.shoulder_sums_score)),
    nextLevel: getDrillLevel(Number(drill.latestStat.shoulder_sums_next)),
    path: Number(drill.latestStat.shoulder_sums_score) ? Number(drill.latestStat.shoulder_sums_score) : null,
    nextPath: Number(drill.latestStat.shoulder_sums_next) ? Number(drill.latestStat.shoulder_sums_next) : null,
    score: Number(drill.latestStat.top)
      ? Number(drill.latestStat.score).toFixed(0)
      : '-',
    highScore: Number(drill.allTime.topScore)
      ? Number(drill.allTime.topScore).toFixed(0)
      : '-',
    goals: `G${Number(drill.latestStat.goals) ? Number(drill.latestStat.goals).toFixed(0) : '-'} / `
      + `C${Number(drill.latestStat.ball_count) ? Number(drill.latestStat.ball_count).toFixed(0) : '-'}`,
    scanFrequency: Number(drill.latestStat.scan_frequency)
      ? Number(drill.latestStat.scan_frequency).toFixed(0)
      : '-',
    avgDwell: Number(drill.latestStat.average_dwell)
      ? `${Number(drill.latestStat.average_dwell).toFixed(1)} secs`
      : '-',
    footBias: `L${
        Number(drill.latestStat.left_foot_shots)
          ? Number(drill.latestStat.left_foot_shots).toFixed(0)
          : '-'
      } / `
      + `R${
        Number(drill.latestStat.right_foot_shots)
          ? Number(drill.latestStat.right_foot_shots).toFixed(0)
          : '-'
      }`,
  };
};

const getPressurePassScores = drill => {
  // Get relevant stats for pressure pass drill
  return {
    level: getDrillLevel(Number(drill.latestStat.pressure_pass_score)),
    nextLevel: getDrillLevel(Number(drill.latestStat.pressure_pass_next)),
    path: Number(drill.latestStat.pressure_pass_score) ? Number(drill.latestStat.pressure_pass_score) : null,
    nextPath: Number(drill.latestStat.pressure_pass_next) ? Number(drill.latestStat.pressure_pass_next) : null,
    score: Number(drill.latestStat.score)
      ? Number(drill.latestStat.score).toFixed(0)
      : '-',
    highScore: Number(drill.allTime.topScore)
      ? Number(drill.allTime.topScore).toFixed(0)
      : '-',
    correct: `C${Number(drill.latestStat.correct) ? Number(drill.latestStat.correct).toFixed(0) : '-'} / `
      + `I${Number(drill.latestStat.incorrect) ? Number(drill.latestStat.incorrect).toFixed(0) : '-'}`,
    accuracy: Number(drill.latestStat.accuracy)
      ? `${Number(drill.latestStat.accuracy).toFixed(0)}%`
      : '-',
    avgDwell: Number(drill.latestStat.average_dwell)
      ? `${Number(drill.latestStat.average_dwell).toFixed(1)} secs`
      : '-',
    footBias: `L${
        Number(drill.latestStat.left_foot_shots)
          ? Number(drill.latestStat.left_foot_shots).toFixed(0)
          : '-'
      } / `
      + `R${
        Number(drill.latestStat.right_foot_shots)
          ? Number(drill.latestStat.right_foot_shots).toFixed(0)
          : '-'
      }`,
  };
};

const getAverageScores = (drills) => {
  // Get average data for all drills - only for level, score and high score
  let levelCount = 0, totalLevel = null, avgLevel = null,
      scoreCount = 0, totalScore = 0, avgScore = 0,
      highScoreCount = 0, totalHighScore = 0, avgHighScore = 0;

  drills.forEach(drill => {
    if (drill) {
      if (drill.path !== null) {
        levelCount++;
        totalLevel += Number(drill.path);
      }
      if (Number(drill.score)) {
        scoreCount++;
        totalScore += Number(drill.score);
      }
      if (Number(drill.highScore)) {
        highScoreCount++;
        totalHighScore += Number(drill.highScore);
      }
    }
  });

  if (levelCount) avgLevel = totalLevel / levelCount;
  if (scoreCount) avgScore = totalScore / scoreCount;
  if (highScoreCount) avgHighScore = totalHighScore / highScoreCount;

  return {
    level: getDrillLevel(avgLevel),
    score: avgScore ? avgScore.toFixed(0) : '-',
    highScore: avgHighScore ? avgHighScore.toFixed(2): '-',
  };
};

export const getSortedProgrammes = sessions => {
  // Sort programmes in date order - newest to oldest
  sessions.sort((a, b) => {
    const dateA = new Date(a.created),
      dateB = new Date(b.created);
    return dateB - dateA;
  });
  return sessions;
};

export const getDeepProgrammeStats = async (profileId, programmes) => {
  // Get full stats for each programme (session)
  let hasErrors = false,
    data = [];

  let responses = [];
  for (const programme of programmes) {
    const singleProgrammeStatsData = await getSingleProgrammeStats(profileId, programme.id);
    responses.push(singleProgrammeStatsData);
  }

  // Set hasErrors to true if error with any of the API calls, else create array of the returned data
  responses.length > 0 && responses.forEach(response => {
    if (!response || !response.success || response.code !== 200) {
      hasErrors = true;
    } else {
      data.push(response.data);
    }
  });

  return {
    hasErrors,
    data,
  };
};

export const getPlayerStats = (profile, indexStats, clubleaderboard, globalLeaderboard) => {
  // Create data for player stats in index stats screen
  // if (programmes.length === 0) return null;

  // const latestProgramme = programmes && programmes.length ? programmes[0] : undefined;
  // const { profile } = latestProgramme;
  // const clubRank = clubRezzilIndexData.data;
  const clubPosition = clubleaderboard.find((player) => player.profileId === profile.id);
  const globalPosition = globalLeaderboard.find((player) => player.profileId === profile.id);
  return {
    rezzilId: profile.id,
    picture: profile.picture,
    firstname: profile.firstname,
    lastname: profile.lastname,
    age: getAgeFromDOB(profile.dob),
    position: getPlayerPositionString(profile.position),
    bestOverallScore: 59.50,
    lastTested: profile.last_tested ? new Date(profile.last_tested) : '--',
    clubRank: clubPosition && clubPosition.rank ? clubPosition.rank : '--',
    worldRank: globalPosition && globalPosition.rank ? globalPosition.rank : '--',
    avgScore: {
      rezzilIndex: indexStats && indexStats.avgRIndex ? indexStats.avgRIndex.toFixed(2) : '--',
      composure: indexStats && indexStats.avgComposure ? indexStats.avgComposure.toFixed(2) : '--',
      reaction:  indexStats && indexStats.avgReaction ? indexStats.avgReaction.toFixed(2) : '--',
      accuracy:  indexStats && indexStats.avgAccuracy ? indexStats.avgAccuracy.toFixed(2): '--',
      adaptability:  indexStats && indexStats.avgTechnical ? indexStats.avgTechnical.toFixed(2) : '--', // TODO: Double check that this is correct
      heartRate: indexStats && indexStats.avgHeartRate ? indexStats.avgHeartRate.toFixed(2) : '--',
    },
    bestScore: {
      rezzilIndex:  indexStats && indexStats.maxRIndex ? indexStats.maxRIndex.toFixed(2) : '--',
      composure: indexStats && indexStats.maxComposure ? indexStats.maxComposure.toFixed(2) : '--',
      reaction:  indexStats && indexStats.maxReaction ? indexStats.maxReaction.toFixed(2) : '--',
      accuracy:  indexStats && indexStats.maxAccuracy ? indexStats.maxAccuracy.toFixed(2) : '--',
      adaptability:  indexStats && indexStats.maxTechnical ? indexStats.maxTechnical.toFixed(2) : '--',
      heartRate: indexStats && indexStats.maxHeartRate ? indexStats.maxHeartRate.toFixed(2) : '--',
    },
  }

  /* Return the following format:
    rezzilId: 3452,
    picture: PlayerPic,
    firstname: 'Alistair',
    lastname: 'Smith',
    age: 21,
    position: '',
    bestOverallScore: 59.50,
    lastTested: new Date(date.setDate(date.getDate() - 10)),
    clubRank: 10,
    worldRank: 129,
    avgScore: {
      rezzilIndex: 54.48,
      composure: 65.81,
      reaction: 90.69,
      accuracy: 40.94,
      adaptability: 20.47,
      heartRate: 175,
    },
    bestScore: {
      rezzilIndex: 60.52,
      composure: 68.49,
      reaction: 82.36,
      accuracy: 56.62,
      adaptability: 32.75,
      heartRate: 154,
    },
  */

};

export const getWorldAverageStats = globalStats => {
  // Create data for world average stats in index stats screen
  return {
    rezzilIndex: globalStats && globalStats.avgRIndex ? Math.round(globalStats.avgRIndex) : '--',
    composure: globalStats && globalStats.avgComposure ? Math.round(globalStats.avgComposure) : '--',
    reaction: globalStats && globalStats.avgReaction ? Math.round(globalStats.avgReaction) : '--',
    accuracy: globalStats && globalStats.avgAccuracy ? Math.round(globalStats.avgAccuracy) : '--',
    adaptability: globalStats && globalStats.avgTechnical ? Math.round(globalStats.avgTechnical) : '--',
  };
}

const getAvgScore = (scores) => {
  return scores.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue),
      0
    ) / scores.length;
}

export const getProgrammeSessionStats = programmes => {
  // Create data for programme session stats in index stats screen
  // Return null if no programmes
  if (programmes.length === 0) return null;

  // Parse programme scores and return data in required format
  let rezzilScores = [],
      composureScores = [],
      reactionScores = [],
      accuracyScores = [],
      adaptabilityScores = [];

  programmes.forEach(programme => {
    // Only run logic for programme objects with data
    if (Object.keys(programme).length > 0) {
      const {date, rezzilIndex} = programme;
      const created = new Date(date);
      rezzilScores.push({
        created,
        score: rezzilIndex.index ? Number(rezzilIndex.index).toFixed(2) : '',
      });
      composureScores.push({
        created,
        score: rezzilIndex.composure ? Number(rezzilIndex.composure).toFixed(2) : '',
      });
      reactionScores.push({
        created,
        score: rezzilIndex.reaction ? Number(rezzilIndex.reaction).toFixed(2) : '',
      });
      accuracyScores.push({
        created,
        score: rezzilIndex.index ? Number(rezzilIndex.accuracy).toFixed(2) : '',
      });
      adaptabilityScores.push({
        created,
        score: rezzilIndex.technical ? Number(rezzilIndex.technical).toFixed(2) : '',
      });
    }
  });

  // TODO: Get averages once the data is returned from the API
  return {
    rezzilIndex: {
      scores: rezzilScores,
      average: getAvgScore(rezzilScores.map(entry => entry.score)),
    },
    composure: {
      scores: composureScores,
      average: getAvgScore(composureScores.map(entry => entry.score)),
    },
    reaction: {
      scores: reactionScores,
      average: getAvgScore(reactionScores.map(entry => entry.score)),
    },
    accuracy: {
      scores: accuracyScores,
      average: getAvgScore(accuracyScores.map(entry => entry.score)),
    },
    adaptability: {
      scores: adaptabilityScores,
      average: getAvgScore(adaptabilityScores.map(entry => entry.score)),
    },
  };
};

export const getIndexBreakdownData = (latestProgramme, selectedProgramme) => {
  // Create data for index breakdown stats in index stats screen
  // Return null if no programmes
  if (Object.keys(latestProgramme).length === 0 || Object.keys(selectedProgramme).length === 0) return null;

  // Parse programme scores for each available drill and return data in required format
  let rondoScanScores,
      colourCombosScores,
      wolfyScores,
      shoulderSumsScores,
      pressurePassScores;

  rondoScanScores = selectedProgramme.drillStats.pass_awareness_1_4
    && getRondoScanScores(selectedProgramme.drillStats.pass_awareness_1_4);
  colourCombosScores = selectedProgramme.drillStats.colour_combo_warmup
    && getColourCombosScores(selectedProgramme.drillStats.colour_combo_warmup);
  wolfyScores = selectedProgramme.drillStats.wolfy_180
    && getWolfyScores(selectedProgramme.drillStats.wolfy_180);
  shoulderSumsScores = selectedProgramme.drillStats.number_pass
    && getShoulderSumsScores(selectedProgramme.drillStats.number_pass);
  pressurePassScores = selectedProgramme.drillStats.blocking
    && getPressurePassScores(selectedProgramme.drillStats.blocking);

  const indexBreakdownData = getHeadlineScores(latestProgramme, selectedProgramme);
  if (rondoScanScores) indexBreakdownData.drillScores['rondo-scan'] = rondoScanScores;
  if (colourCombosScores) indexBreakdownData.drillScores['colour-combos'] = colourCombosScores;
  if (wolfyScores) indexBreakdownData.drillScores['wolfy'] = wolfyScores;
  if (shoulderSumsScores) indexBreakdownData.drillScores['shoulder-sums'] = shoulderSumsScores;
  if (pressurePassScores) indexBreakdownData.drillScores['pressure-pass'] = pressurePassScores;
  indexBreakdownData.drillScores.averages = getAverageScores([
    rondoScanScores,
    colourCombosScores,
    wolfyScores,
    shoulderSumsScores,
    pressurePassScores,
  ]);

  return indexBreakdownData;
};

export const getNewPlayerStats = async (playerId, clubleaderboard, globalLeaderboard) => {
  // Make relevant API calls to get player data for Index Stats page
  const profileInfo = await getClubPlayerProfile(playerId);

  let newPlayerStats = {
    allProgrammeSessions: null,
    allProgrammeSessionsDeep: null,
    playerStats: null,
    worldAvgStats: null,
    programmeSessionStats: null,
    indexBreakdownData: null,
  };

  const playerDrillData = await getDrillSummaryStats(playerId);
  const playerIndexdata = playerDrillData && playerDrillData.data ? playerDrillData.data.rezzil_index : undefined;
  // Call shallow programme stats API
  const allProgrammeStatsData = await getAllProgrammeStats(playerId);
  if (!allProgrammeStatsData || !allProgrammeStatsData.success || allProgrammeStatsData.code !== 200)
    return {success: false, newPlayerStats};

  // Call deep programme stats API for 10 most recent programmes
  const {programmes} = allProgrammeStatsData.data;
  const allProgrammeSessions = getSortedProgrammes(programmes);
  newPlayerStats.allProgrammeSessions = allProgrammeSessions;
  if (allProgrammeSessions.length > 0) {
    const latestTenProgrammes = allProgrammeSessions.length > 10
      ? allProgrammeSessions.slice(0, 10)
      : allProgrammeSessions;
    const {hasErrors, data} = await getDeepProgrammeStats(playerId, latestTenProgrammes);
    if (hasErrors) return {success: false, newPlayerStats};

    const allProgrammeSessionsDeep = data;
    newPlayerStats.allProgrammeSessionsDeep = allProgrammeSessionsDeep;

    // Get remaining data
    if (allProgrammeSessionsDeep.length > 0) {
      newPlayerStats.playerStats = getPlayerStats(profileInfo.data.profile, playerIndexdata.profile, clubleaderboard, globalLeaderboard);
      newPlayerStats.worldAvgStats = getWorldAverageStats(playerIndexdata.global);
      newPlayerStats.programmeSessionStats = getProgrammeSessionStats(allProgrammeSessionsDeep);
      newPlayerStats.indexBreakdownData = getIndexBreakdownData(data[0], data[0]);
    }
  } else {
    // No result data so just populate profile info..
    newPlayerStats.playerStats = getPlayerStats(profileInfo.data.profile, undefined, clubleaderboard, globalLeaderboard);
    newPlayerStats.worldAvgStats = getWorldAverageStats(playerIndexdata.global);
  }

  return {success: true, newPlayerStats};
};


export const getWorldAverageStatsForNoPlayers = async () => {
  // Use default Id of 1
  const playerDrillData = await getDrillSummaryStats(1);
  const playerIndexdata = playerDrillData && playerDrillData.data ? playerDrillData.data.rezzil_index : undefined;
  return getWorldAverageStats(playerIndexdata.global);
}