import {useEffect, useState, useCallback, createContext, useContext} from 'react';
import {routes} from '../../options/routes';

// Helpers
import {getClubPlayersList} from '../../utils/helpers/getClubPlayersList';
import {
  getRezzilIndexLeaderboardStats,
  getClubLeaderBoardStats,
} from '../../utils/helpers/leaderboardHelpers';

// API calls
import {
  getClubPlayers,
  getBoardsRezzilIndex,
  getBoardsClubDrills,
} from '../../api';

// Contexts
import {BaseContext} from '../Global/BaseProvider';

const ClubLeaderBoardsContext = createContext({});

const ClubLeaderBoardsProvider = ({children}) => {
  const baseContext = useContext(BaseContext);
  const [clubLeaderBoardsData, setClubLeaderBoardsData] = useState({
    playerProfiles: null,
    rezzilIndexLeaderBoardStats: null,
    clubLeaderBoardStats: null,
    apiError: false,
  });

  const getClubLeaderBoardsData = useCallback(async () => {
    // Only trigger API call for clubLeaderBoards route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.indexClubLeaderBoards && activeRoute !== routes.rezzilPlayerClubLeaderBoards) return;
    // Call API
    if (!baseContext.baseData.loading && !clubLeaderBoardsData.loading) {
      setClubLeaderBoardsData({
        ...clubLeaderBoardsData,
        loading: true,
      });
      const {id} = baseContext.baseData.clubProfile;
      // Call club player profiles API
      const playerProfilesData = await  getClubPlayers(id);
      if (!playerProfilesData || !playerProfilesData.success || playerProfilesData.code !== 200) {
        setClubLeaderBoardsData({
          loading: false,
          playerProfiles: null,
          rezzilIndexLeaderBoardStats: null,
          clubLeaderBoardStats: null,
          apiError: true,
          allLoaded: true,
        });
        return;
      }
      // Call Rezzil Index leaderboard API
      const clubRezzilIndexData = await getBoardsRezzilIndex(id);
      if (!clubRezzilIndexData || !clubRezzilIndexData.success || clubRezzilIndexData.code !== 200) {
        setClubLeaderBoardsData({
          loading: false,
          playerProfiles: null,
          rezzilIndexLeaderBoardStats: null,
          clubLeaderBoardStats: null,
          apiError: true,
          allLoaded: true,
        });
        return;
      }

      // Get playerProfiles and rezzilIndexLeaderBoardStats if previous API calls successful
      const {profiles} = playerProfilesData.data;
      const {leaderboard} = clubRezzilIndexData.data;
      const playerProfiles = getClubPlayersList(profiles, true);
      const rezzilIndexLeaderBoardStats = getRezzilIndexLeaderboardStats(leaderboard);

      // Call club best drills API
      const clubDrillsData = await getBoardsClubDrills(id);
      if (!clubDrillsData || !clubDrillsData.success || clubDrillsData.code !== 200) {
        // Show error but return playerProfiles and rezzilIndexLeaderBoardStats - those calls will have been
        // successful if this block is triggered
        setClubLeaderBoardsData({
          loading: false,
          playerProfiles,
          rezzilIndexLeaderBoardStats,
          clubLeaderBoardStats: null,
          apiError: true,
          allLoaded: true,
        });
        return;
      }

      // Get clubLeaderBoardStats
      const {leaderboards} = clubDrillsData.data;
      const clubLeaderBoardStats = getClubLeaderBoardStats(leaderboards);

      setClubLeaderBoardsData({
        loading: false,
        playerProfiles,
        rezzilIndexLeaderBoardStats,
        clubLeaderBoardStats,
        apiError: false,
        allLoaded: true,
      });
    }
  }, [baseContext]);

  useEffect(() => {
    getClubLeaderBoardsData();
  }, [getClubLeaderBoardsData]);

  return (
    <ClubLeaderBoardsContext.Provider
      value={{
        clubLeaderBoardsData,
        getClubLeaderBoardsData
      }}
    >
      {children}
    </ClubLeaderBoardsContext.Provider>
  );
}

export {ClubLeaderBoardsProvider, ClubLeaderBoardsContext};

export default ClubLeaderBoardsProvider;
