import differenceInHours from 'date-fns/differenceInHours';
import { getProducts, createSubscription } from '../../api';

import { currencyCodeToSymbol } from './currencyHelper';

export const hasActiveEliteLicence = (subscriptions, stripeProducts) => {
  const stripeProduct = stripeProducts?.data?.products.find(product => product?.metadata?.LicenseId === 'elite');
  const eliteSubscription = subscriptions[0].data.find((subscription) => subscription.plan.product === stripeProduct.id && subscription.status === 'active')
  return !!eliteSubscription;
}


// Return client secret and price of subscription...s
export const setupSubscriptionHelper = async (licenceId, billingPeriod, quantity) => {
  const stripeProducts = await getProducts();

  if (!stripeProducts?.data || !stripeProducts?.data.products || !stripeProducts?.data.products.length ||
    !stripeProducts?.data.prices || !stripeProducts?.data.prices.length) {
    return;
  }

  const stripeProduct = stripeProducts?.data?.products.find(product => product?.metadata?.LicenseId === licenceId);
  if (!stripeProduct) {
    return;
  }
  const subscriptionProduct = stripeProducts?.data?.prices.find(
    (price) => price.product === stripeProduct.id && price.recurring.interval === billingPeriod)
  const subscriptionProductId = subscriptionProduct.id;
  const subscriptionTokens = JSON.parse(localStorage.getItem('subscriptionTokens')) || [];
  // Check to see if there is an existing subscription token for this product that we can use
  // This is to reduce the amount of incomplete subscriptions created
  // Note the subscription token expires after 23 hours so we need to check the createdTimestamp

  const validSubscriptionTokens = subscriptionTokens.filter((token) => differenceInHours(new Date(), new Date(token.createdTimestamp)) < 23);
  if (validSubscriptionTokens && validSubscriptionTokens.length) {
    const existingToken = validSubscriptionTokens.find((token) => token.productId === subscriptionProductId);
    if (existingToken && existingToken.clientSecret) {
      // setClientSecret(existingToken.clientSecret);
      // setSubsriptionPrice(existingToken.subscriptionPrice ? existingToken.subscriptionPrice : undefined);
      // setLoading(false);
      return {
        clientSecret: existingToken.clientSecret,
        subscriptionPrice: existingToken.subscriptionPrice ? existingToken.subscriptionPrice : undefined,
      }
    }
  }

  // No existing tokens for this priceId so we need to create a new secret here (and store it in localstorage for if they
  // close the modal and open it again)
  const subscribeResponse = await createSubscription(subscriptionProductId);
  if (subscribeResponse && subscribeResponse.data && subscribeResponse.data.paymentIntent) {
    const clientSecret = subscribeResponse.data.paymentIntent.client_secret;
    const subscriptionPrice = `${currencyCodeToSymbol(subscribeResponse.data.paymentIntent.currency)}${subscribeResponse.data.paymentIntent.amount / 100}`;
    validSubscriptionTokens.push({
      productId: subscriptionProductId,
      subscriptionId: subscribeResponse.data.subscriptionId,
      clientSecret,
      subscriptionPrice,
      createdTimestamp: new Date().getTime(),
    });
    localStorage.setItem('subscriptionTokens', JSON.stringify(validSubscriptionTokens));
    return {
      clientSecret: clientSecret,
      subscriptionPrice: subscriptionPrice,
    }
  }
}

export const getSpecificLicences = (licences, licenceCode) => {
  return licences.filter((licence) => licence.code === licenceCode);
}

export const getSubscriptionPrice = (stripeProducts, licenceId, billingPeriod) => {
  // const stripeProduct = stripeProducts?.data?.products.find(product => product?.metadata?.LicenseId === licenceId);
  const product = stripeProducts.products.find((product) => product.metadata?.LicenseId === licenceId);

  const price = stripeProducts.prices.find((price) => price.product === product.id && price.recurring.interval === billingPeriod);

  return {
    formatted: `${currencyCodeToSymbol(price.currency)}${price.unit_amount / 100}.00`,
    price: price.unit_amount / 100,
    currencySymbol: currencyCodeToSymbol(price.currency),
  };
};
