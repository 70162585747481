import {
  Container,
  ButtonsContainer,
  ButtonContainer,
} from './SectionTitleWithArrows.styles';
import SectionTitle from '../../../components/Typography/SectionTitle/SectionTitle';
import IconButton from '../../../components/Buttons/IconButton/IconButton';
import {ArrowBackOutlined, ArrowForwardOutlined} from '@mui/icons-material';

const SectionTitleWithArrows = ({
  title,
  handleRangeChangePrev,
  handleRangeChangeNext,
  disablePrev,
  disableNext,
  loading,
}) => {
  return (
    <Container>
      <SectionTitle
        text={title}
      />
      <ButtonsContainer>
        <ButtonContainer>
          <IconButton
            icon={<ArrowBackOutlined/>}
            onClick={handleRangeChangePrev}
            disabled={loading || disablePrev}
          />
        </ButtonContainer>
        <ButtonContainer>
          <IconButton
            icon={<ArrowForwardOutlined/>}
            onClick={handleRangeChangeNext}
            disabled={loading || disableNext}
          />
        </ButtonContainer>
      </ButtonsContainer>
    </Container>
  );
};

export default SectionTitleWithArrows;
