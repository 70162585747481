import {
  Container,
  StyledTitle,
} from './CardTitle.styles';

const CardTitle = ({text, isRecommended}) => {
  return (
    <Container>
      <StyledTitle
        recommended={isRecommended ? 'true' : undefined}
      >
        {text}
      </StyledTitle>
    </Container>
  );
};

export default CardTitle;
