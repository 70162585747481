import {
  Container,
  StyledSelect,
  StyledMenuItem,
  SelectIcon,
  StyledColorIcon
} from './CustomSelect.styles';
import FieldError from '../FieldError/FieldError';
import Label from '../../Typography/Label/Label';

const CustomSelect = ({label, name, onChange, placeholder, required, value, error, options, disabled}) => {
  return (
    <Container>
      {
        label &&
        <Label
          text={label}
          required={required}
        />
      }
      <StyledSelect
        name={name}
        onChange={e => onChange(e)}
        displayEmpty
        value={value}
        IconComponent={SelectIcon}
        fullWidth
        selected={value}
        error={!!error}
        disabled={disabled}
      >
        <StyledMenuItem
          value=''
        >
          {placeholder}
        </StyledMenuItem>
        {
          options && options.map(({value, label, color}) => {
            return (
              <StyledMenuItem
                key={value}
                value={value}
              >
                {
                  color &&
                  <StyledColorIcon
                    color={color}
                  />
                }
                {label}
              </StyledMenuItem>
            )
          })
        }
      </StyledSelect>
      {
        error &&
        <FieldError
          error={error}
        />
      }
    </Container>
  );
};

export default CustomSelect;
