import {
  StyledCard,
  StyledCardContent,
  ImageContainer,
  Image,
  StyledCardActions,
} from './FileInputCard.styles';
import CustomFileInput from '../../Inputs/CustomFileInput/CustomFileInput';
import Label from '../../Typography/Label/Label';
import DefaultBadge from '../../../assets/images/default-badge.png';
import DefaultUser from '../../../assets/images/default-user.png';
import SectionSpinner from '../../Loading/SectionSpinner/SectionSpinner';

const FileInputCard = ({isClub, id, imageUrl, btnText, onChange, loading}) => {
  return (
    <StyledCard>
      <StyledCardContent>
        <Label
          text={`${isClub ? 'Club' : 'Profile'} Photo`}
        />
        <ImageContainer>
          <Image
            src={imageUrl ? imageUrl : isClub ? DefaultBadge : DefaultUser}
            alt={isClub ? 'Club Logo' : 'Profile Photo'}
          />
        </ImageContainer>
      </StyledCardContent>
      <StyledCardActions>
        {
          loading &&
          <SectionSpinner/>
        }
        {
          !loading &&
          <CustomFileInput
            id={id}
            btnText={btnText}
            onChange={onChange}
          />
        }
      </StyledCardActions>
    </StyledCard>
  );
};

export default FileInputCard;
