const convertToBase64 = async (file, setState, isLogo) => {
  // Convert image to base64 string to display in image area
  const reader = new FileReader();
  let picturefile;
  reader.onloadend = (loadEvent) => {
    picturefile = loadEvent.target.result;
    if (isLogo) {
      setState({
        logofile: picturefile,
      });
    } else {
      setState({
        picturefile,
      });
    }
  }
  reader.readAsDataURL(file);
};

export default convertToBase64;
