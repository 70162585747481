import {useState, useEffect, useCallback} from 'react';
import {
  CardsContainer,
} from './TwitterCards.styles';
import {buttonTypes} from '../../../options/buttonTypes';
import SectionContainer from '../../Containers/SectionContainer/SectionContainer';
import TwitterCard from '../../Cards/TwitterCard/TwitterCard';
import NoResultsText from '../../Typography/NoResultsText/NoResultsText';

// Helpers
import {formatStandardDateString} from '../../../utils/helpers/formatDateString';

const TwitterCards = ({posts}) => {
  const [postCount] = useState(4);
  const [twitterPosts, setTwitterPosts] = useState([]);
  const [indexToSlice, setIndexToSlice] = useState(0);
  const [showLoadMore, setShowLoadMore] = useState(true);

  const getInitialPosts = useCallback(() => {
    let slicedPosts;
    if (posts && posts.length > postCount) {
      slicedPosts = posts.slice(0, postCount);
      setIndexToSlice(postCount);
    } else {
      slicedPosts = [...posts];
      setShowLoadMore(false);
    }
    setTwitterPosts(slicedPosts);
  }, [posts, postCount]);

  useEffect(() => getInitialPosts(), [getInitialPosts]);

  const getMorePosts = () => {
    // Return if all posts have been loaded
    if (twitterPosts.length === posts.length) return;

    // Get end index to slice to if there are more than 4 additional posts remaining in the posts array
    let endIndex;
    if ((posts.length - twitterPosts.length) > postCount) {
      endIndex = indexToSlice + postCount;
      setIndexToSlice(endIndex);
    }

    // Get next 4 (or remaining if less than 4) posts and add to twitterPosts array
    const slicedPosts = posts.slice(indexToSlice, endIndex);
    const updatedPosts = [...twitterPosts, ...slicedPosts];
    if (updatedPosts.length === posts.length) setShowLoadMore(false);
    setTwitterPosts(updatedPosts);
  };

  return (
    <SectionContainer
      cardTitle={'Twitter Feed'}
      showBtn={showLoadMore}
      btnType={buttonTypes.trigger}
      btnText={'Load more'}
      onClick={getMorePosts}
      isLoad
    >
      <CardsContainer
        posts={posts.length > 0 ? 'true' : undefined}
      >
        {
          twitterPosts.length > 0 && twitterPosts.map(({id, avatarUrl, tag, publishDate, body}) => (
            <TwitterCard
              key={id}
              avatarUrl={avatarUrl}
              tag={tag}
              date={formatStandardDateString(publishDate, true)}
              body={body}
            />
          ))
        }
        {
          posts.length === 0 &&
          <NoResultsText
            text={'No posts'}
          />
        }
      </CardsContainer>
    </SectionContainer>
  );
};

export default TwitterCards;
