import {
  Container,
  StyledText,
  RequiredLabel,
} from './Label.styles';

const Label = ({text, required}) => {
  return (
    <Container>
      <StyledText>
        {text}
        {
          required &&
          <RequiredLabel>
            {' *'}
          </RequiredLabel>
        }
      </StyledText>
    </Container>
  );
};

export default Label;
