export const nationalities = [
  {value: 'british', label: 'British'},
  {value: 'american', label: 'American'},
  {value: 'afghan', label: 'Afghan'},
  {value: 'albanian', label: 'Albanian'},
  {value: 'algerian', label: 'Algerian'},
  {value: 'andorran', label: 'Andorran'},
  {value: 'angolan', label: 'Angolan'},
  {value: 'anguillan', label: 'Anguillan'},
  {value: 'citizen of antigua and barbuda', label: 'Citizen of Antigua and Barbuda'},
  {value: 'argentine', label: 'Argentine'},
  {value: 'armenian', label: 'Armenian'},
  {value: 'australian', label: 'Australian'},
  {value: 'austrian', label: 'Austrian'},
  {value: 'azerbaijani', label: 'Azerbaijani'},
  {value: 'bahamian', label: 'Bahamian'},
  {value: 'bahraini', label: 'Bahraini'},
  {value: 'bangladeshi', label: 'Bangladeshi'},
  {value: 'barbadian', label: 'Barbadian'},
  {value: 'belarusian', label: 'Belarusian'},
  {value: 'belgian', label: 'Belgian'},
  {value: 'belizean', label: 'Belizean'},
  {value: 'beninese', label: 'Beninese'},
  {value: 'bermudian', label: 'Bermudian'},
  {value: 'bhutanese', label: 'Bhutanese'},
  {value: 'bolivian', label: 'Bolivian'},
  {value: 'citizen of bosnia and herzegovina', label: 'Citizen of Bosnia and Herzegovina'},
  {value: 'botswanan', label: 'Botswanan'},
  {value: 'brazilian', label: 'Brazilian'},
  {value: 'british virgin islander', label: 'British Virgin Islander'},
  {value: 'bruneian', label: 'Bruneian'},
  {value: 'bulgarian', label: 'Bulgarian'},
  {value: 'burkinan', label: 'Burkinan'},
  {value: 'burmese', label: 'Burmese'},
  {value: 'burundian', label: 'Burundian'},
  {value: 'cambodian', label: 'Cambodian'},
  {value: 'cameroonian', label: 'Cameroonian'},
  {value: 'canadian', label: 'Canadian'},
  {value: 'cape verdean', label: 'Cape Verdean'},
  {value: 'cayman islander', label: 'Cayman Islander'},
  {value: 'central african', label: 'Central African'},
  {value: 'chadian', label: 'Chadian'},
  {value: 'chilean', label: 'Chilean'},
  {value: 'chinese', label: 'Chinese'},
  {value: 'colombian', label: 'Colombian'},
  {value: 'comoran	congolese (congo)', label: 'Comoran	Congolese (Congo)'},
  {value: 'congolese (drc)', label: 'Congolese (DRC)'},
  {value: 'cook Islander', label: 'Cook Islander'},
  {value: 'costa rican', label: 'Costa Rican'},
  {value: 'croatian', label: 'Croatian'},
  {value: 'cuban', label: 'Cuban'},
  {value: 'cymraes', label: 'Cymraes'},
  {value: 'cymro', label: 'Cymro'},
  {value: 'cypriot', label: 'Cypriot'},
  {value: 'czech', label: 'Czech'},
  {value: 'danish', label: 'Danish'},
  {value: 'djiboutian', label: 'Djiboutian'},
  {value: 'dominican', label: 'Dominican'},
  {value: 'citizen of the dominican republic', label: 'Citizen of the Dominican Republic'},
  {value: 'dutch', label: 'Dutch'},
  {value: 'east timorese', label: 'East Timorese'},
  {value: 'ecuadorean', label: 'Ecuadorean'},
  {value: 'egyptian', label: 'Egyptian'},
  {value: 'emirati', label: 'Emirati'},
  {value: 'english', label: 'English'},
  {value: 'equatorial guinean', label: 'Equatorial Guinean'},
  {value: 'eritrean', label: 'Eritrean'},
  {value: 'estonian', label: 'Estonian'},
  {value: 'ethiopian', label: 'Ethiopian'},
  {value: 'faroese', label: 'Faroese'},
  {value: 'fijian', label: 'Fijian'},
  {value: 'filipino', label: 'Filipino'},
  {value: 'finnish', label: 'Finnish'},
  {value: 'french', label: 'French'},
  {value: 'gabonese', label: 'Gabonese'},
  {value: 'gambian', label: 'Gambian'},
  {value: 'georgian', label: 'Georgian'},
  {value: 'german', label: 'German'},
  {value: 'ghanaian', label: 'Ghanaian'},
  {value: 'gibraltarian', label: 'Gibraltarian'},
  {value: 'greek', label: 'Greek'},
  {value: 'greenlandic', label: 'Greenlandic'},
  {value: 'grenadian', label: 'Grenadian'},
  {value: 'guamanian', label: 'Guamanian'},
  {value: 'guatemalan', label: 'Guatemalan'},
  {value: 'citizen of guinea-bissau', label: 'Citizen of Guinea-Bissau'},
  {value: 'guinean', label: 'Guinean'},
  {value: 'guyanese', label: 'Guyanese'},
  {value: 'haitian', label: 'Haitian'},
  {value: 'honduran', label: 'Honduran'},
  {value: 'hong konger', label: 'Hong Konger'},
  {value: 'hungarian', label: 'Hungarian'},
  {value: 'icelandic', label: 'Icelandic'},
  {value: 'indian', label: 'Indian'},
  {value: 'indonesian', label: 'Indonesian'},
  {value: 'iranian', label: 'Iranian'},
  {value: 'iraqi', label: 'Iraqi'},
  {value: 'irish', label: 'Irish'},
  {value: 'israeli', label: 'Israeli'},
  {value: 'italian', label: 'Italian'},
  {value: 'ivorian', label: 'Ivorian'},
  {value: 'jamaican', label: 'Jamaican'},
  {value: 'japanese', label: 'Japanese'},
  {value: 'jordanian', label: 'Jordanian'},
  {value: 'kazakh', label: 'Kazakh'},
  {value: 'kenyan', label: 'Kenyan'},
  {value: 'kittitian', label: 'Kittitian'},
  {value: 'citizen of kiribati', label: 'Citizen of Kiribati'},
  {value: 'kosovan', label: 'Kosovan'},
  {value: 'kuwaiti', label: 'Kuwaiti'},
  {value: 'kyrgyz', label: 'Kyrgyz'},
  {value: 'lao', label: 'Lao'},
  {value: 'latvian', label: 'Latvian'},
  {value: 'lebanese', label: 'Lebanese'},
  {value: 'liberian', label: 'Liberian'},
  {value: 'libyan', label: 'Libyan'},
  {value: 'liechtenstein citizen', label: 'Liechtenstein Citizen'},
  {value: 'lithuanian', label: 'Lithuanian'},
  {value: 'luxembourger', label: 'Luxembourger'},
  {value: 'macanese', label: 'Macanese'},
  {value: 'macedonian', label: 'Macedonian'},
  {value: 'malagasy', label: 'Malagasy'},
  {value: 'malawian', label: 'Malawian'},
  {value: 'malaysian', label: 'Malaysian'},
  {value: 'maldivian', label: 'Maldivian'},
  {value: 'malian', label: 'Malian'},
  {value: 'maltese', label: 'Maltese'},
  {value: 'marshallese', label: 'Marshallese'},
  {value: 'martiniquais', label: 'Martiniquais'},
  {value: 'mauritanian', label: 'Mauritanian'},
  {value: 'mauritian', label: 'Mauritian'},
  {value: 'mexican', label: 'Mexican'},
  {value: 'micronesian', label: 'Micronesian'},
  {value: 'moldovan', label: 'Moldovan'},
  {value: 'monegasque', label: 'Monegasque'},
  {value: 'mongolian', label: 'Mongolian'},
  {value: 'montenegrin', label: 'Montenegrin'},
  {value: 'montserratian', label: 'Montserratian'},
  {value: 'moroccan', label: 'Moroccan'},
  {value: 'mosotho', label: 'Mosotho'},
  {value: 'mozambican', label: 'Mozambican'},
  {value: 'namibian', label: 'Namibian'},
  {value: 'nauruan', label: 'Nauruan'},
  {value: 'nepalese', label: 'Nepalese'},
  {value: 'new zealander', label: 'New Zealander'},
  {value: 'nicaraguan', label: 'Nicaraguan'},
  {value: 'nigerian', label: 'Nigerian'},
  {value: 'nigerien', label: 'Nigerien'},
  {value: 'niuean', label: 'Niuean'},
  {value: 'north korean', label: 'North Korean'},
  {value: 'northern irish', label: 'Northern Irish'},
  {value: 'norwegian', label: 'Norwegian'},
  {value: 'omani', label: 'Omani'},
  {value: 'pakistani', label: 'Pakistani'},
  {value: 'palauan', label: 'Palauan'},
  {value: 'palestinian', label: 'Palestinian'},
  {value: 'panamanian', label: 'Panamanian'},
  {value: 'papua New Guinean', label: 'Papua New Guinean'},
  {value: 'paraguayan', label: 'Paraguayan'},
  {value: 'peruvian', label: 'Peruvian'},
  {value: 'pitcairn Islander', label: 'Pitcairn Islander'},
  {value: 'polish', label: 'Polish'},
  {value: 'portuguese', label: 'Portuguese'},
  {value: 'prydeinig', label: 'Prydeinig'},
  {value: 'puerto rican', label: 'Puerto Rican'},
  {value: 'qatari', label: 'Qatari'},
  {value: 'romanian', label: 'Romanian'},
  {value: 'russian', label: 'Russian'},
  {value: 'rwandan', label: 'Rwandan'},
  {value: 'salvadorean', label: 'Salvadorean'},
  {value: 'sammarinese', label: 'Sammarinese'},
  {value: 'samoan', label: 'Samoan'},
  {value: 'sao tomean', label: 'Sao Tomean'},
  {value: 'saudi arabian', label: 'Saudi Arabian'},
  {value: 'scottish', label: 'Scottish'},
  {value: 'senegalese', label: 'Senegalese'},
  {value: 'serbian', label: 'Serbian'},
  {value: 'citizen of seychelles', label: 'Citizen of Seychelles'},
  {value: 'sierra leonean', label: 'Sierra Leonean'},
  {value: 'singaporean', label: 'Singaporean'},
  {value: 'slovak', label: 'Slovak'},
  {value: 'slovenian', label: 'Slovenian'},
  {value: 'solomon islander', label: 'Solomon Islander'},
  {value: 'somali', label: 'Somali'},
  {value: 'south african', label: 'South African'},
  {value: 'south korean', label: 'South Korean'},
  {value: 'south sudanese', label: 'South Sudanese'},
  {value: 'spanish', label: 'Spanish'},
  {value: 'sri lankan', label: 'Sri Lankan'},
  {value: 'st helenian', label: 'St Helenian'},
  {value: 'st lucian', label: 'St Lucian'},
  {value: 'stateless', label: 'Stateless'},
  {value: 'sudanese', label: 'Sudanese'},
  {value: 'surinamese', label: 'Surinamese'},
  {value: 'swazi', label: 'Swazi'},
  {value: 'swedish', label: 'Swedish'},
  {value: 'swiss', label: 'Swiss'},
  {value: 'syrian', label: 'Syrian'},
  {value: 'taiwanese', label: 'Taiwanese'},
  {value: 'tajik', label: 'Tajik'},
  {value: 'tanzanian', label: 'Tanzanian'},
  {value: 'thai', label: 'Thai'},
  {value: 'togolese', label: 'Togolese'},
  {value: 'tongan', label: 'Tongan'},
  {value: 'trinidadian', label: 'Trinidadian'},
  {value: 'tristanian', label: 'Tristanian'},
  {value: 'tunisian', label: 'Tunisian'},
  {value: 'turkish', label: 'Turkish'},
  {value: 'turkmen', label: 'Turkmen'},
  {value: 'turks and caicos islander', label: 'Turks and Caicos Islander'},
  {value: 'tuvaluan', label: 'Tuvaluan'},
  {value: 'ugandan', label: 'Ugandan'},
  {value: 'ukrainian', label: 'Ukrainian'},
  {value: 'uruguayan', label: 'Uruguayan'},
  {value: 'uzbek', label: 'Uzbek'},
  {value: 'vatican citizen', label: 'Vatican citizen'},
  {value: 'citizen of vanuatu', label: 'Citizen of Vanuatu'},
  {value: 'venezuelan', label: 'Venezuelan'},
  {value: 'vietnamese', label: 'Vietnamese'},
  {value: 'vincentian', label: 'Vincentian'},
  {value: 'wallisian', label: 'Wallisian'},
  {value: 'welsh', label: 'Welsh'},
  {value: 'yemeni', label: 'Yemeni'},
  {value: 'zambian', label: 'Zambian'},
  {value: 'zimbabwean', label: 'Zimbabwean'},
];
