import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';
import {CheckOutlined} from '@mui/icons-material';

export const Container = styled('div')(() => ({
  display: 'flex',
  marginBottom: 12,
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
}));

export const StyledIcon = styled(CheckOutlined)(({ recommended }) => ({
  color: recommended ? colors.primaryOrange : colors.secondaryGrey,
  marginRight: 16,
  fontSize: 22,
}));
