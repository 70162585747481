import { useEffect, useState, useCallback, createContext } from 'react';

// API calls
import {getUpdates} from '../../api';

const UpdatesContext = createContext({});

const UpdatesProvider = ({children}) => {
  const [updatesData, setUpdatesData] = useState({
    loading: true,
    updates: null,
    apiError: false,
  });

  const getUpdatesData = useCallback(async () => {
    // Get clubId from local storage
    let clubId;
    try {
      const clubProfile = JSON.parse(localStorage.getItem('clubProfile'));
      clubId = clubProfile.id;
    } catch (error) {
      setUpdatesData({
        loading: false,
        updates: null,
        apiError: true,
      });
      return;
    }

    // Call API
    const updatesData = await getUpdates(clubId);
    if (!updatesData || !updatesData.success || !updatesData.data.updates.length) {
      setUpdatesData({
        loading: false,
        updates: null,
        apiError: true,
      });
      return;
    }

    // Set data
    setUpdatesData({
      loading: false,
      updates: updatesData.data.updates,
      apiError: false,
    });
  }, []);

  useEffect(() => {
    getUpdatesData();
  }, [getUpdatesData]);

  return (
    <UpdatesContext.Provider
      value={{
        updatesData,
        getUpdatesData
      }}
    >
      {children}
    </UpdatesContext.Provider>
  );
}

export {UpdatesProvider, UpdatesContext};

export default UpdatesProvider;
