import {useState} from 'react';
import {
  Container,
  StyledTextField,
} from './CustomDateInput.styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {LocalizationProvider, DesktopDatePicker} from '@mui/lab';
import Label from '../../Typography/Label/Label';
import FieldError from '../FieldError/FieldError';

const CustomDateInput = ({label, id, required, onChange, error, value}) => {
  const [dateVal, setDateVal] = useState(value ? value : null)

  const handleDateChange = val => {
    setDateVal(val);
    onChange(null, id, val);
  };

  return (
    <Container>
      <Label
        text={label}
        required={required}
      />
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
      >
        <DesktopDatePicker
          id={id}
          disableFuture
          onChange={date => handleDateChange(date)}
          value={dateVal}
          openTo={'year'}
          views={['year', 'month', 'day']}
          inputFormat={'dd/MM/yyyy'}
          renderInput={params => <StyledTextField {...params} error={!!error} />}
          PaperProps={{
            id: 'dob-date-picker'
          }}
        />
        {
          error &&
          <FieldError
            error={error}
          />
        }
      </LocalizationProvider>
    </Container>
  );
};

export default CustomDateInput;
