import { useEffect, useState, useCallback, createContext } from 'react';
import {routes, nonAuthenticatedRoutes} from '../../options/routes';

// API calls
import {getUserProfile, getClub, getClubLicences} from '../../api';

const BaseContext = createContext({});

const BaseProvider = ({children}) => {
  const [baseData, setBaseData] = useState({
    loading: true,
    userRole: '',
    userProfile: {},
    clubProfile: {},
    activeRoute: '',
  });

  const getBaseData = useCallback(async () => {
    // Get token, userProfile and clubProfile from local storage
    const activeRoute = window.location.pathname;
    let token, clubProfile;
    // Logout and redirect to login page if error parsing token or clubProfile
    try {
      token = JSON.parse(localStorage.getItem('token'));
      clubProfile = JSON.parse(localStorage.getItem('clubProfile'));
    } catch (error) {
      loginRedirect(activeRoute);
      return;
    }

    // Logout and redirect to login page if token or clubProfile not in local storage
    if (
      !token ||
      !(clubProfile && Object.keys(clubProfile).length)
    ) {
      loginRedirect(activeRoute);
      return;
    }

    // API call to get user profile and update local storage data
    const data = await getUserProfile();
    if (!data || !data.success || !(data.data && (Object.keys(data.data).length))) {
      loginRedirect(activeRoute);
      return;
    }
    const manageClubId = localStorage.getItem('manageClubId');
    let manageClubProfile = JSON.parse(localStorage.getItem('manageClubProfile'));

    if (manageClubId && (!manageClubProfile || manageClubProfile?.id !== manageClubId)) {
      // We need to fetch the manage club id and save to local storage
      const managedClubdata = await getClub(manageClubId);
      manageClubProfile = managedClubdata.data;
      localStorage.setItem('manageClubProfile', JSON.stringify(manageClubProfile));
    }
    const {role, user} = data.data;
    localStorage.setItem('userRole', JSON.stringify(role));
    localStorage.setItem('userProfile', JSON.stringify(user));

    // Redirect to home if trying to access any non-authenticated routes when logged in
    if (nonAuthenticatedRoutes.includes(activeRoute)) {
      window.location.href = routes.home;
      return;
    }

    const licences = await getClubLicences(manageClubId ? manageClubId : clubProfile.id);

    const hasEliteLicence = licences?.data && licences?.data.length ? !!licences?.data.find((licence) => licence.code === 'elite') : false;
    const hasRezzilPlayerLicence = licences?.data && licences?.data.length ? !!licences?.data.find((licence) => licence.code === 'squadspro') : false;
    // TODO: Should we redirect here if there are no active licences?
    setBaseData({
      loading: false,
      userRole: role,
      userProfile: user,
      clubProfile: manageClubId ? manageClubProfile : clubProfile,
      activeRoute,
      hasEliteLicence: hasEliteLicence || clubProfile.id === '1', // Hardcoding access for club id 1
      hasRezzilPlayerLicence: hasRezzilPlayerLicence,
    });
  }, []);

  useEffect(() => {
    getBaseData();
  }, [getBaseData]);

  const loginRedirect = (activeRoute) => {
    if (!nonAuthenticatedRoutes.some(v => activeRoute.includes(v))) {
      window.location.href = routes.logout;
    }
  };

  return (
    <BaseContext.Provider
      value={{
        baseData,
        getBaseData
      }}
    >
    {children}
  </BaseContext.Provider>
  );
}

export {BaseProvider, BaseContext};

export default BaseProvider;
