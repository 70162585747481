import {
  styled,
  Typography,
} from '@mui/material';

export const Container = styled('div')(() => ({}));

export const FieldContainer = styled('div')(() => ({
  marginBottom: 20,
  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const TwoStepContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const TwoStepText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
}));

export const FormContainer = styled('form')(() => ({}));