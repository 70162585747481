import {useEffect, useState, useCallback, createContext, useContext} from 'react';
import {routes} from '../../options/routes';

// API calls
import {
  getProducts,
} from '../../api';

// Contexts
import {BaseContext} from '../Global/BaseProvider';
import { hasAPIError } from '../../utils/helpers/apiHelper';

const SubscribeContext = createContext({});

const SubscribeProvider = ({children}) => {
  const baseContext = useContext(BaseContext);
  const [subscribeData, setSubscribe] = useState({
    loading: true,
    products: [],
    apiError: false,
  });

  const getSubscribeData = useCallback(async () => {
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.rezzilPlayerPanel) return;

    if (!baseContext.baseData.loading) {
      const {id} = baseContext.baseData.clubProfile;
      const products = await getProducts(id);

      if (hasAPIError(products)) {
        setSubscribe({
          loading: false,
          apiError: true,
          products: [],
        });
        return;
      }

      const productData = products.data;

      setSubscribe({
        loading: false,
        apiError: false,
        products: productData,
      });

    }
  }, [baseContext]);

  useEffect(() => {
    getSubscribeData();
  }, [getSubscribeData]);

  return (
    <SubscribeContext.Provider
      value={{
        subscribeData,
        getSubscribeData
      }}
    >
      {children}
    </SubscribeContext.Provider>
  );
}

export {SubscribeProvider, SubscribeContext};

export default SubscribeProvider;
