import {useState} from 'react';
import {
  StyledTable,
  LargeTableBody,
  StyledTableRow,
  StyledTableCell,
  WorldAvgContainer,
  // TrendingUpIcon,
  // TrendingDownIcon,
  // TrendingFlatIcon,
  // AvgStat,
} from './ClubBestDrillsList.styles';
import {indexBestDrillsColumns} from '../../../options/tableColumns';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import CustomTableHead from '../CustomTableHead/CustomTableHead';

// Helpers
import {getComparator, stableSort, handleRequestSort} from '../../../utils/tableSortHelpers/tableSortHelpers';

const ClubBestDrillsList = ({data}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  return (
    <>
      <CustomTableWrapper
        noResultsText={(!data || data.length === 0) && 'No details to display'}
        fullHeight
      >
        <StyledTable>
          <CustomTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(e, id) => handleRequestSort(e, id, orderBy, order, setOrder, setOrderBy)}
            columnHeadings={indexBestDrillsColumns}
          />
          <LargeTableBody>
            {
              data.length > 0 &&
              stableSort(data, getComparator(order, orderBy))
                .map(({id, drill, name, highScore, worldAvg, trend, positionChange}) => (
                  <StyledTableRow
                    key={id}
                  >
                    <StyledTableCell>
                      {drill}
                    </StyledTableCell>
                    <StyledTableCell>
                      {name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {highScore}
                    </StyledTableCell>
                    <StyledTableCell>
                      <WorldAvgContainer>
                        {
                          worldAvg > 0 &&
                          <>
                            {/* <AvgStat> */}
                              {worldAvg}
                            {/* </AvgStat> */}
                            {/* <AvgStat>
                              {
                                trend === 'up'
                                  ? <TrendingUpIcon/>
                                  : trend === 'down'
                                    ? <TrendingDownIcon/>
                                    : <TrendingFlatIcon/>
                              }
                            </AvgStat>
                            <AvgStat>
                              {`${trend === 'up' ? '+' : trend === 'down' ? '-' : ''}${positionChange}`}
                            </AvgStat> */}
                          </>
                        }
                        {
                          !worldAvg &&
                          'N/A'
                        }
                      </WorldAvgContainer>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            }
          </LargeTableBody>
        </StyledTable>
      </CustomTableWrapper>
    </>
  );
};

export default ClubBestDrillsList;
