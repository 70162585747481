import {
  Container,
  StyledLabel,
  StyledToggle
} from './CustomToggle.styles';

const CustomToggle = ({label, id, checked, onChange}) => {
  return (
    <Container>
      {
        label &&
        <StyledLabel>
          {label}
        </StyledLabel>
      }
      <StyledToggle
        id={id}
        checked={checked}
        onChange={onChange}
      />
    </Container>
  );
};

export default CustomToggle;
