export const indexAccordionOptions = [
  {
    statId: 'rezzilIndex',
    title: 'Rezzil Index',
    description: 'Be a great all rounder to increase your Rezzil Index',
  },
  {
    statId: 'composure',
    title: 'Composure',
    description: 'Keep your accuracy under pressure to improve',
  },
  {
    statId: 'reaction',
    title: 'Reaction',
    description: 'Make decisions quicker and this score will go up',
  },
  {
    statId: 'accuracy',
    title: 'Accuracy',
    description: 'Improve your shot success rate to boost this score',
  },
  {
    statId: 'adaptability',
    title: 'Adaptability',
    description: 'Use both feet to improve this score',
  },
  {
    statId: 'heartRate',
    title: 'Heart Rate',
    description: '',
  },
];
