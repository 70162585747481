import {
  styled,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {colors} from '../../../options/colors';
import {Check} from '@mui/icons-material';

export const Container = styled('div')(() => ({
  position: 'relative',
}));

export const StyledFormGroup = styled(FormGroup)(({ error }) => ({
  position: 'relative',
  marginBottom: error ? 2 : 0,
}));

export const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  ...theme.typography.body1,
  alignItems: 'flex-start',
}));

export const UncheckedIcon = styled('span')(() => ({
  borderRadius: 3,
  width: 24,
  height: 24,
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
}));

export const CheckedIcon = styled(Check)(() => ({
  color: colors.white,
  height: 24,
  width: 24,
  backgroundColor: colors.primaryOrange,
  borderRadius: 3,
}));

export const StyledCheckbox = styled(Checkbox)(() => ({
  padding: 0,
  marginRight: 10,
}));
