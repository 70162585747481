import {
  styled,
  Table,
} from '@mui/material';
import {
  StyledTableBody,
  StyledTableRow,
} from '../CustomTableWrapper/CustomTableWrapper.styles';
import {StyledTableCell} from '../CustomTableHead/CustomTableHead.styles';

export const StyledTable = styled(Table)(() => ({}));

export const LargeTableBody = styled(StyledTableBody)(() => ({
  '& .MuiTableCell-root': {
    padding: '20px 10px 20px 0',
    '&:first-of-type': {
      paddingLeft: 10,
    },
  },
}));

export const StyledTableContainer = styled('div')(({ expanded, count }) => ({
  transition: 'all 0.3s ease',
  overflow: 'hidden',
  // Dynamically set maxHeight based on number of rows (table head height is 35px and each row is 86.5px)
  maxHeight: expanded ? (count * 86.5) + 35 : 900,
}));


export {
  StyledTableRow,
  StyledTableCell,
};

export const FieldContainer = styled('div')(() => ({
  marginBottom: 20,
}));

export const LinkButtonContainer = styled('div')(() => ({
  width: 165,
}));