import { styled, Typography } from '@mui/material';

export const ShareContainer = styled('div')(({tvSize}) => ({
  display: 'flex',
  justifyContent: 'center',
  minWidth: tvSize ? '100%' : 'inherit',
}));

export const SectionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  flex: '1 0 50%',
  [theme.breakpoints.down(1000)]: {
    flex: 1,
  },
}));

export const ContainerWrapper = styled('div')(({ theme, datalength, displayForTV }) => ({
  maxWidth: displayForTV ? '100%' : '48.3%',
  width: '100%',
  marginBottom: 50,
  '&:first-of-type': {
    marginTop: 15,
  },
  '&:nth-of-type(2)': {
    marginTop: 15,
  },
  '&:nth-of-type(odd)': {
    marginRight: 15,
  },
  '&:nth-of-type(even)': {
    marginLeft: 15,
  },
  '&:nth-last-of-type(2)': {
    // Remove margin bottom from second to last item if even number of tables in dataset
    marginBottom: datalength % 2 === 0 ? 0 : 50,
  },
  '&:last-of-type': {
    marginBottom: 0,
  },
  '& > .MuiPaper-root': {
    height: 'calc(100% - 80px)',
  },
  [theme.breakpoints.down(1000)]: {
    maxWidth: '100%',
    margin: '0 0 30px 0 !important',
    '&:last-of-type': {
      marginBottom: '0 !important',
    },
    '& > .MuiPaper-root': {
      height: 'auto',
    },
  },
}));

export const Timestamp = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 12,
  marginTop: 10,
}));