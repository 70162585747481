import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import { Container, RowContainer, SecondaryTitle, SubscriptionDetails, PaymentAmount }  from './SubscriptionChangeForm.styles';
import { currencyCodeToSymbol } from '../../../utils/helpers/currencyHelper';

const SubscriptionChangeForm = ({ subscription, isDowngrade }) => {
  if (isDowngrade) {
    return (<Container>
      <Container>
        <RowContainer>
          <SecondaryTitle>Annual subscription ends</SecondaryTitle>
          <SecondaryTitle>First monthly payment due</SecondaryTitle>
        </RowContainer>
        <RowContainer>
          <SubscriptionDetails>{format(subscription.expires, 'd MMMM yyyy')}</SubscriptionDetails>
          <SubscriptionDetails>{`${format(addDays(subscription.expires, 1), 'd MMMM yyyy')} (${currencyCodeToSymbol(subscription.changeTo.currency)}${subscription.changeTo.unit_amount / 100} a month)`}</SubscriptionDetails>
        </RowContainer>
      </Container>
    </Container>);
  }

  // If this is an upgrade - confirm that the user want to go ahead with this as the request will instantly update the current month subscription

  return (
    <Container>
      <RowContainer>
        <SecondaryTitle>Subscription frequency</SecondaryTitle>
        <SecondaryTitle>Annual amount</SecondaryTitle>
      </RowContainer>
      <RowContainer>
        <SubscriptionDetails>Annual</SubscriptionDetails>
        <PaymentAmount>{`${currencyCodeToSymbol(subscription.changeTo.currency)} ${subscription.changeTo.unit_amount / 100}`}</PaymentAmount>
      </RowContainer>
    </Container>
  )

}

export default SubscriptionChangeForm;
