import {useState} from 'react';
import {
  Container,
  StyledAutocomplete,
  StyledTextField,
  DropdownIcon,
} from './CustomAutocomplete.styles';
import Label from '../../Typography/Label/Label';
import FieldError from '../FieldError/FieldError';

const CustomAutoComplete = ({ label, id, placeholder, required, onChange, error, value, options, telephoneCountryCode }) => {
  // Set input value to the option label, if value exists
  const [selectedVal, setSelectedVal] = useState(value ? options.find(o => o.value === value) : null);

  const handleChange = (e, value) => {
    const val = value ? value.value ? value.value : value : '';
    onChange(e, id, val);
    // Set input value to the option label, if value exists
    setSelectedVal(value ? value : null);
  };

  const handleBlur = (event) => {
    if (!telephoneCountryCode) {
      return;
    }

    if (!options.find((option) => option.value === event.target.value)) {
      // Update to blank value
      setTimeout(() => {
        onChange(event, id, '');
        setSelectedVal('');
      }, 200);
    }
  };

  return (
    <Container>
      <Label
        text={label}
        required={required}
      />
      <StyledAutocomplete
        id={id}
        onChange={(e, value) => handleChange(e, value)}
        onBlur={(event) => handleBlur(event)}
        value={selectedVal}
        options={options}
        popupIcon={<DropdownIcon/>}
        renderInput={params => <StyledTextField {...params} error={!!error} placeholder={placeholder} />}
        freeSolo={telephoneCountryCode}
        autoSelect={telephoneCountryCode}
      />
      {
        error &&
        <FieldError
          error={error}
        />
      }
    </Container>
  );
};

export default CustomAutoComplete;
