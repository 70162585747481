import {useState} from 'react';
import {
  StyledTable,
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  FrequencyContainer,
} from './SubscriptionsList.styles';
import {subscriptionColumns} from '../../../options/tableColumns';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import CustomTableHead from '../CustomTableHead/CustomTableHead';
import SectionSpinner from '../../Loading/SectionSpinner/SectionSpinner';
import CustomToggle from '../../Inputs/CustomToggle/CustomToggle';

// Helpers
import {getComparator, stableSort, handleRequestSort} from '../../../utils/tableSortHelpers/tableSortHelpers';
import {formatStandardDateString} from '../../../utils/helpers/formatDateString';
import LinkButton from '../../Buttons/LinkButton/LinkButton';

const SubscriptionsList = ({
  subscriptions,
  onAutoRenewChange,
  autoRenewLoading,
  onSubscriptionChangeClick
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  return (
    <>
      <CustomTableWrapper
        noResultsText={(!subscriptions || subscriptions.length === 0) && 'No Subscriptions to display'}
      >
        <StyledTable>
          <CustomTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(e, id) => handleRequestSort(e, id, orderBy, order, setOrder, setOrderBy)}
            columnHeadings={subscriptionColumns}
          />
          <StyledTableBody>
            {
              subscriptions.length > 0 &&
              stableSort(subscriptions, getComparator(order, orderBy))
                .map(({id, frequency, nextAmount, expires, autoRenew}) => (
                <StyledTableRow
                  key={id}
                >
                  {/* <StyledTableCell>
                    {licences}
                  </StyledTableCell>
                  <StyledTableCell>
                    {addons}
                  </StyledTableCell> */}
                  <StyledTableCell>
                    <FrequencyContainer>
                      {frequency}
                      <LinkButton
                        text={'Change'}
                        onClick={() => onSubscriptionChangeClick(id)}
                        small
                      />
                    </FrequencyContainer>
                  </StyledTableCell>
                  <StyledTableCell>
                    {nextAmount}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatStandardDateString(expires)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {
                      autoRenewLoading && autoRenewLoading === id &&
                      <SectionSpinner/>
                    }
                    {
                      autoRenewLoading !== id &&
                      <CustomToggle
                        id={'autoRenew'}
                        checked={!!autoRenew}
                        onChange={() => onAutoRenewChange(id)}
                      />
                    }
                  </StyledTableCell>
                </StyledTableRow>
              ))
            }
          </StyledTableBody>
        </StyledTable>
      </CustomTableWrapper>
    </>
  );
};

export default SubscriptionsList;
