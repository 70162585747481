import {styled} from '@mui/material';

export const Container = styled('div')(({ theme, print }) => ({
  display: 'flex',
  '& > div': {
    alignItems: 'flex-start',
  },
  'button:last-of-type': {
    marginLeft: print ? 10 : undefined,
  },
  [theme.breakpoints.down('sm')]: {
    '& button': {
      height: 36,
      width: 36,
      marginLeft: print ? 10 : undefined,
      '& .MuiSvgIcon-root': {
        fontSize: 25,
      },
    },
  },
  '@media print': {
    display: 'none',
  }
}));
