import {
  Container,
} from './ComparisonCardGroup.styles';
import ComparisonCard from '../../Cards/ComparisonCard/ComparisonCard';
import NoResultsText from '../../Typography/NoResultsText/NoResultsText';

const ComparisonCardGroup = ({data, isRezzilPlayer, highlightTop}) => {
  return (
    <Container
      players={data.length > 0 ? 'true' : undefined}
    >
      {
        data.length > 0 && data.map(player => (
          <ComparisonCard
            key={player.playerName}
            player={player}
            isRezzilPlayer={isRezzilPlayer}
            highlightTop
          />
        ))
      }
      {
        data.length === 0 &&
        <NoResultsText
          text={'No players'}
        />
      }
    </Container>
  );
};

export default ComparisonCardGroup;
