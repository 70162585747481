import {styled} from '@mui/material';

export const RowContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const StyledSectionContainer = styled('div')(() => ({
  flex: 2,
  marginBottom: 40,
  '&:last-of-type': {
    marginBottom: 0,
  },
  marginRight: 15,
}));