import {
  Container,
  StyledTitle,
} from './SectionTitle.styles';

const SectionTitle = ({text}) => {
  return (
    <Container>
      <StyledTitle>
        {text}
      </StyledTitle>
    </Container>
  );
};

export default SectionTitle;
