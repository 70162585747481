import {useEffect, useState, useCallback} from 'react';
import {
  InnerContainer,
  FieldContainer,
} from './Login.styles';
import {routes} from '../../../options/routes';
import AuthenticationWrapper from '../../../components/Wrappers/AuthenticationWrapper/AuthenticationWrapper';
import AuthFormContainer from '../../../components/Containers/AuthFormContainer/AuthFormContainer';
import CustomTextField from '../../../components/Inputs/CustomTextField/CustomTextField';
import CustomCheckbox from '../../../components/Inputs/CustomCheckbox/CustomCheckbox';
import OutlinedButton from '../../../components/Buttons/OutlinedButton/OutlinedButton';
import SectionSpinner from '../../../components/Loading/SectionSpinner/SectionSpinner';

// Helpers
import setLocalStorage from '../../../utils/helpers/setLocalStorage';

// API calls
import {login} from '../../../api';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    api: ''
  });
  const [remember, setRemember] = useState(false);

  const handleSubmit = useCallback(async () => {
    /* Memoize function to prevent creating new function on every render */
    setLoading(true);
    // Set form errors
    let formErrors = {...errors};
    let hasErrors = false;
    if (!formData.email) {
      formErrors = {...formErrors, email: 'Email is required'};
      hasErrors = true;
    }
    if (!formData.password) {
      formErrors = {...formErrors, password: 'Password is required'};
      hasErrors = true;
    }

    // Set errors and return if there are errors
    setErrors(formErrors);
    if (hasErrors) {
      setLoading(false);
      return;
    }

    // Trigger API call
    const data = await login(formData);
    if (!data || !data.success || !(data.data.login_id || (data.data.user && Object.keys(data.data.user).length))) {
      setErrors({...errors, api: 'Please check your login details are correct'})
      setLoading(false);
      return;
    }

    // Redirect to verify screen if user has 2FA enabled
    if (data.data.login_id) {
      window.location.href = `${routes.verify}/${data.data.login_id}/${data.data['2fa']}`;
      return;
    }

    // Save account details in local storage and redirect to home if direct login
    setLocalStorage(data.data);
  }, [errors, formData]);

  useEffect(() => {
    const onEnterKeyPress = e => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.preventDefault();
        if (!loading) {
          handleSubmit();
        }
      }
    };

    document.addEventListener('keydown', onEnterKeyPress);

    return () => {
      document.removeEventListener('keydown', onEnterKeyPress);
    };
  }, [formData, handleSubmit, loading]);

  const handleBannerClose = () => {
    setErrors({...errors, api: ''});
  };

  const handleFieldChange = e => {
    // Clear errors
    setErrors({
      email: '',
      password: '',
      api: ''
    });

    // Update formData
    if (e.target.id === 'remember') {
      setRemember(e.target.checked);
    } else {
      let data = {...formData};
      data[e.target.id] = e.target.value;
      setFormData(data);
    }
  };

  const handleLinkClick = (type) => {
    type === 'register'
      ? window.location.href = routes.register
      : window.location.href = routes.forgotPassword;
  };

  return (
    <AuthenticationWrapper
      apiMessage={errors.api}
      error={errors.api}
      handleBannerClose={handleBannerClose}
    >
      <AuthFormContainer
        btnText={'Forgot your password?'}
        onClick={() => handleLinkClick('forgot-password')}
        showSecondBtn
        secondBtnText={'Register'}
        secondBtnOnClick={() => handleLinkClick('register')}
      >
        <InnerContainer>
          <FieldContainer>
            <CustomTextField
              label={'Username (email)'}
              id={'email'}
              placeholder={'email@email.com'}
              required
              onChange={handleFieldChange}
              error={errors.email}
              type={'email'}
              value={formData.email}
            />
          </FieldContainer>
          <FieldContainer>
            <CustomTextField
              label={'Password'}
              id={'password'}
              placeholder={'Password'}
              required
              onChange={handleFieldChange}
              error={errors.password}
              type={'password'}
              value={formData.password}
            />
          </FieldContainer>
          <FieldContainer>
            <CustomCheckbox
              id={'remember'}
              checked={remember}
              onChange={handleFieldChange}
              label={'Remember me'}
            />
          </FieldContainer>
          {
            loading &&
            <SectionSpinner/>
          }
          {
            !loading &&
            <OutlinedButton
              text={'Login'}
              onClick={handleSubmit}
              fullWidth
            />
          }
        </InnerContainer>
      </AuthFormContainer>
    </AuthenticationWrapper>
  )
};

export default Login;
