import {useState} from 'react';
import {
  StyledModal,
  ModalHeader,
  ModalContent,
  ModalActions,
  CloseButton,
  CloseIcon,
} from './UpdatesModal.styles';
import ModalTitle from '../../Typography/ModalTitle/ModalTitle';
import OutlinedButton from '../../Buttons/OutlinedButton/OutlinedButton';

const UpdatesModal = ({children, open, onClose, title}) => {
  const [show, setShow] = useState(open);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  return (
    <>
      <StyledModal
        open={show}
        scroll={'body'}
      >
        <ModalHeader>
          <ModalTitle
            text={title}
          />
          <CloseButton
            aria-label='close'
            onClick={handleClose}
          >
            <CloseIcon/>
          </CloseButton>
        </ModalHeader>
        <ModalContent>
          {children}
        </ModalContent>
        <ModalActions>
          <OutlinedButton
            text={'Close'}
            onClick={handleClose}
          />
        </ModalActions>
      </StyledModal>
    </>
  );
};

export default UpdatesModal;
