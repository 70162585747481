import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 40,
}));

export const TextContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflowWrap: 'anywhere',
}));

export const StyledText = styled(Typography)(({ theme, discount }) => ({
  ...theme.typography.body1,
  margin: discount ? 0 : '15px 0 0 0',
}));

export const DiscountContainer = styled('div')(() => ({
  marginRight: 25,
}));

export const DiscountHeading = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  marginBottom: 2,
  color: colors.primaryGrey,
}));

export const DiscountText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
}));
