import { getNearUserLeaderboard, getTopLeaderboard } from "../../api";
import { getAPIData } from "./apiHelper";
import { createColorHex } from "./createColorHex";

export const getSingleLeaderBoardData = (leaderboard, playerChoicesIds) => {
  const leaderBoardName = leaderboard.name;
  const players = leaderboard.scores;
  let playersToDisplay = [];
  let selectedPlayerIds = [];

  if (players.length > 0) {
    // Get first 10 players and set showColor to true if logged in player appears within that dataset
    players.forEach((player, idx) => {
      if (idx < 10) {
        if (playerChoicesIds.includes(player.id)) {
          player.showColor = true;
          selectedPlayerIds.push(player.id);
        } else {
          player.showColor = false;
        }
        playersToDisplay.push(player);
      }
    });
    // If selected players do not appear in top 10, get their score and push them in
    if (selectedPlayerIds.length < playerChoicesIds.length) {
      playerChoicesIds.forEach(choiceId => {
        if (!selectedPlayerIds.includes(choiceId)) {
          const playerToDisplay = players.find(player => player.id === choiceId);
          // Only set details if player exists in scores array
          if (playerToDisplay && playerToDisplay.id) {
            playerToDisplay.showColor = true
            playersToDisplay.push(playerToDisplay);
            selectedPlayerIds.push(playerToDisplay.id);
          }
        }
      })
    }
  }

  return {
    name: leaderBoardName,
    scores: playersToDisplay,
  }
};

const getAllLeaderBoardsData = (rezzilIndexData, otherData, playerChoicesIds) => {
  // Get top 10 for each leaderboard - if player is in playerChoicesIds array, set showColor to true
  // If player in playerChoicesIds array is not in top 10, add their data
  let allLeaderBoardData = [];

  // Get Rezzil Index leaderboard data
  if (Object.keys(rezzilIndexData).length > 0) {
    const rezzilLeaderBoardData = getSingleLeaderBoardData(rezzilIndexData, playerChoicesIds);
    allLeaderBoardData.push(rezzilLeaderBoardData);
  }

  // Get other leaderboard data - iterate over the global leaderboards object keys and get value for each one
  if (Object.keys(otherData).length > 0) {
    Object.entries(otherData).forEach(leaderboard => {
      const leaderBoardData = getSingleLeaderBoardData(leaderboard[1], playerChoicesIds);
      allLeaderBoardData.push(leaderBoardData);
    });
  }

  return allLeaderBoardData;
};

export default getAllLeaderBoardsData;


export const getLeaderboardData = async (leaderboardName, leaderboardData, squadId) => {
  if (!leaderboardData) {
    const newTopLeadboardData = await getTopLeaderboard(leaderboardName, squadId);
    return getAPIData(newTopLeadboardData);
  }
  return leaderboardData.originalLeaderBoard;
}

export const getSquadLeaderBoardData = async ({ playerChoicesIds, leaderboardName, leaderboardData, squadId }) => {
  const newLeaderboardData = await getLeaderboardData(leaderboardName, leaderboardData, squadId);
  const { leaderboard, entries } = newLeaderboardData;
  const leaderBoardName = leaderboard.name;
  const players = entries;
  let playersToDisplay = [];
  let selectedPlayerIds = [];

  if (players.length > 0) {
    // Get first 10 players and set showColor to true if logged in player appears within that dataset
    players.forEach((player, idx) => {
      if (idx < 10) {
        if (playerChoicesIds.includes(player.userPlatformId)) {
          player.showColor = true;
          player.color = createColorHex(player.userPlatformId);
          selectedPlayerIds.push(player.userPlatformId);
        } else {
          player.showColor = false;
        }
        playersToDisplay.push(player);
      }
    });
    // If selected players do not appear in top 10, get their score and push them in
    if (selectedPlayerIds.length < playerChoicesIds.length) {
      for (let i = 0; i < playerChoicesIds.length; i++) {
        const choiceId = playerChoicesIds[i];
        if (!selectedPlayerIds.includes(choiceId)) {
          const playerLeaderboard = await getNearUserLeaderboard(leaderboardName, choiceId, squadId)
          const playerLeaderboardData = getAPIData(playerLeaderboard);
          // Only set details if player exists in scores array
          const playerToDisplay = playerLeaderboardData.entries && playerLeaderboardData.entries.length ? playerLeaderboardData.entries[0] : undefined;
          if (playerToDisplay && playerToDisplay.userPlatformId === choiceId) {
            playerToDisplay.showColor = true;
            playerToDisplay.color = createColorHex(playerToDisplay.userPlatformId);
            playersToDisplay.push(playerToDisplay);
            selectedPlayerIds.push(playerToDisplay.userPlatformId);
          }
        }
      }
    }
  }

  return {
    name: leaderBoardName,
    scores: playersToDisplay,
    originalLeaderBoard: newLeaderboardData,
  }
};