import {useEffect, useState, useCallback} from 'react';
import {
  Container,
  ColumnContainer,
  StyledImage,
  CaptionText,
} from './IndexBreakdownDrillStatHeader.styles';

// Helpers
import getLevelDetails from '../../../utils/helpers/getLevelIcon';

const IndexBreakdownDrillStatHeader = ({level, nextLevel}) => {
  const [currentIconDetails, setCurrentIconDetails] = useState({});
  const [nextIconDetails, setNextIconDetails] = useState({});

  const getIconDetails = useCallback(() => {
    // Set icon details based on level and nextLevel values
    const currentDetails = getLevelDetails(level);
    setCurrentIconDetails(currentDetails);
    const nextDetails = getLevelDetails(nextLevel);
    setNextIconDetails(nextDetails);
  }, [level, nextLevel]);

  useEffect(() => getIconDetails(), [getIconDetails]);

  return (
    <Container>
      <ColumnContainer>
        <StyledImage
          src={currentIconDetails.icon}
          alt={`${currentIconDetails.value} Shield`}
        />
        <CaptionText>
          Current
        </CaptionText>
      </ColumnContainer>
      <ColumnContainer>
        <StyledImage
          src={nextIconDetails.icon}
          alt={nextIconDetails.value}
        />
        <CaptionText
          next={'true'}
        >
          Next
        </CaptionText>
      </ColumnContainer>
    </Container>
  );
};

export default IndexBreakdownDrillStatHeader;
