import {useState, useEffect} from 'react';
import {
  StyledTable,
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  InlineFieldContainer,
  FieldContainer,
} from './PaymentMethodsList.styles';
import {paymentMethodColumns} from '../../../options/tableColumns';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import CustomTableHead from '../CustomTableHead/CustomTableHead';
import CustomTextField from '../../Inputs/CustomTextField/CustomTextField';
import StatusPill from '../../Pills/StatusPill/StatusPill';
import IconButton from '../../Buttons/IconButton/IconButton';
import StandardModal from '../../Modals/StandardModal/StandardModal';
import {EditOutlined, DeleteOutlined} from '@mui/icons-material';
import FieldError
  from '../../Inputs/FieldError/FieldError';
// Helpers
import {getComparator, stableSort, handleRequestSort} from '../../../utils/tableSortHelpers/tableSortHelpers';
import {DeleteText} from '../TeamMembersList/TeamMembersList.styles';

const PaymentMethodsList = ({
  paymentMethods,
  handleEditSubmit,
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  editLoading,
  handleDelete,
  deleteLoading,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMethod, setDeleteMethod] = useState({});
  const [cardExpiryMonth, setCardExpiryMonth] = useState();
  const [cardExpiryYear, setCardExpiryYear] = useState();

  useEffect(() => {
    setCardExpiryMonth(formData.expiryMonth);
    setCardExpiryYear(formData.expiryYear);
  }, [formData]);

  const handleFormModalClose = () => {
    // Reset state back to original values
    setFormModalOpen(false);
    setFormData({});
    setFormErrors();
  };

  const handleFormModalOpen = id => {
    // Find index of item with matching id and pass those details into modal
    const index = paymentMethods.findIndex(el => el.id === id);
    setFormData(paymentMethods[index]);
    setFormModalOpen(true);
  };

  const handleFormModalSubmit = async () => {
    const result = await handleEditSubmit(formData.id, Number(cardExpiryMonth), Number(cardExpiryYear));
    if (result) {
      handleFormModalClose();
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setDeleteMethod({});
  };

  const handleDeleteModalOpen = id => {
    const index = paymentMethods.findIndex(el => el.id === id);
    setDeleteMethod(paymentMethods[index]);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalSubmit = async () => {
    const result = await handleDelete(deleteMethod.id);
    if (result) {
      handleDeleteModalClose();
    }
  };

  return (
    <>
      <CustomTableWrapper
        noResultsText={(!paymentMethods || paymentMethods.length === 0) && 'No Payment Methods to display'}
      >
        <StyledTable>
          <CustomTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(e, id) => handleRequestSort(e, id, orderBy, order, setOrder, setOrderBy)}
            columnHeadings={paymentMethodColumns}
          />
          <StyledTableBody>
            {
              paymentMethods.length > 0 &&
              stableSort(paymentMethods, getComparator(order, orderBy))
                .map(({id, name, expiry, status, type}) => (
                <StyledTableRow
                  key={id}
                >
                  <StyledTableCell>
                    {name}
                  </StyledTableCell>
                  <StyledTableCell>
                    {expiry}
                  </StyledTableCell>
                  <StyledTableCell>
                    <StatusPill
                      text={status}
                      isActive={status === 'Active'}
                      small
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {type}
                  </StyledTableCell>
                  <StyledTableCell>
                    {
                      status === 'Active' &&
                      <IconButton
                        icon={<EditOutlined/>}
                        onClick={() => handleFormModalOpen(id)}
                      />
                    }
                  </StyledTableCell>
                  <StyledTableCell>
                    <IconButton
                      icon={<DeleteOutlined/>}
                      onClick={() => handleDeleteModalOpen(id)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            }
          </StyledTableBody>
        </StyledTable>
      </CustomTableWrapper>
      {
        formModalOpen &&
        <StandardModal
          open={formModalOpen}
          onClose={handleFormModalClose}
          title={'Edit Payment Method'}
          btnText={'Edit Method'}
          onClick={handleFormModalSubmit}
          loading={editLoading}
        >
          <p style={{color: 'white'}}>
            Edit Payment Method {formData.name}
          </p>
          <InlineFieldContainer>
            <FieldContainer>
              <CustomTextField
                label={'Expiry Month'}
                id={'expiryMonth'}
                placeholder={formData.expiryMonth}
                required
                onChange={(event) => setCardExpiryMonth(event.target.value)}
                type={'text'}
                value={cardExpiryMonth}
              />
            </FieldContainer>
            <FieldContainer>
              <CustomTextField
                label={'Expiry Year'}
                id={'expiryYear'}
                placeholder={formData.expiryYear}
                required
                onChange={(event) => setCardExpiryYear(event.target.value)}
                type={'text'}
                value={cardExpiryYear}
              />
            </FieldContainer>
            </InlineFieldContainer>
            {formErrors && <FieldError error={formErrors} />}
        </StandardModal>
      }
      {
        deleteModalOpen &&
        <StandardModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          title={'Delete Payment Method'}
          btnText={'Delete Method'}
          onClick={handleDeleteModalSubmit}
          loading={deleteLoading}
        >
          <DeleteText>
            Are you sure you want to delete {deleteMethod.name}?
            Once confirmed, this action cannot be undone.
          </DeleteText>
        </StandardModal>
      }
    </>
  );
};

export default PaymentMethodsList;
