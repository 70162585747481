import {StyledCard} from './AuthFormCard.styles';

const AuthFormCard = ({children}) => {
  return (
    <StyledCard>
      {children}
    </StyledCard>
  );
};

export default AuthFormCard;
