import {
  styled,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

export const StyledAccordion = styled(Accordion)(() => ({
  borderRadius: '5px !important',
  boxShadow: 'none',
  overflow: 'hidden',
  background: 'transparent',
  margin: '0 !important',
  '&::before': {
    display: 'none',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  background: 'transparent',
  borderRadius: 0,
  padding: 0,
  textAlign: 'center',
  cursor: 'text !important',
  '&.Mui-expanded' : {
    minHeight: 'unset',
  },
  '& .MuiAccordionSummary-content' : {
    margin: '0 !important',
    '& > div': {
      borderTop: 'none',
    },
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  background: 'transparent',
  borderRadius: 0,
  padding: 0,
  '& > div:last-of-type': {
    paddingBottom: 0,
  },
  [theme.breakpoints.down('md')]: {
    '& > div:last-of-type': {
      paddingBottom: 20,
    },
  },
}));

export const DetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
  padding: '20px 0',
  borderTop: `1px solid ${colors.dividerGrey}`,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
}));

export const TextContainer = styled('div')(({ theme }) => ({
  marginRight: 15,
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    margin: '0 0 5px',
  },
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 18,
  marginBottom: 5,
}));

export const DescriptionText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 13,
  color: colors.primaryGrey,
}));

export const ScoreContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexWrap: 'nowrap',
  marginLeft: 15,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

export const ScoreText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1,
  fontSize: 24,
  color: colors.secondaryOrange,
  marginRight: 5,
}));

export const MaxScoreText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  whiteSpace: 'nowrap',
}));

export const EmptyBlock = styled('div')(({ theme }) => ({
  height: 65,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'none',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}));
