import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';
import {Close} from '@mui/icons-material';

export const StyledMainModal = styled(Dialog)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.7)',
  '.MuiPaper-root': {
    margin: '30px 0',
    padding: 0,
    background: colors.black,
    width: '100%',
    maxWidth: '800px !important',
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
}));

export const ModalHeader = styled(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '30px 30px 0',
}));

export const ModalContent = styled(DialogContent)(() => ({
  padding: '0 30px 30px',
}));

export const ModalActions = styled(DialogActions)(() => ({
  padding: '17px 30px',
  borderTop: `1px solid ${colors.secondaryGrey}`
}));

export const CloseButton = styled(IconButton)(() => ({
  padding: 2,
  transition: 'all 0.3s ease',
  alignSelf: 'flex-start',
}));

export const CloseIcon = styled(Close)(() => ({
  color: colors.white,
  fontSize: 22,
}));

export const StyledNestedModal = styled(Dialog)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.7)',
  '.MuiPaper-root': {
    margin: '30px 0',
    padding: 0,
    background: colors.black,
    width: '100%',
    maxWidth: '600px !important',
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
}));

export const NestedModalTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 18,
  marginBottom: 15,
}));

export const NestedModalText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: colors.primaryGrey,
  marginBottom: 25,
  '&:last-of-type': {
    marginBottom: 0,
  },
}));
