import {styled} from '@mui/material';

export const Container = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
}));

export const LogoContainer = styled('div')(() => ({
  margin: '30px 0',
}));

export const StyledLogo = styled('img')(({ theme }) => ({
  width: 280,
  [theme.breakpoints.down('sm')]: {
    width: 240,
  },
}));

export const ButtonContainer = styled('div')(() => ({
  margin: '10px 0 30px',
  textAlign: 'center',
}));

export const SecondButtonContainer = styled('div')(() => ({
  marginTop: 10,
  textAlign: 'center',
}));
