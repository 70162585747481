import {styled} from '@mui/material';

export const SearchContainer = styled('div')(({ theme }) => ({
  marginBottom: 15,
  width: '50%',
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
