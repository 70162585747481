import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

// Will only have two cards if redirecting from Admin - Products screen
export const Container = styled('div')(({ theme, length }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  // width: length > 2 ? 'unset' : 650,
  '& > div': {
    margin: 0,
    flex: length > 2 ? '1 0 25%' : '1 0 50%',
    maxWidth: length > 2 ? '28%' : '48%',
  },
  [theme.breakpoints.down(1100)]: {
    flexWrap: 'wrap',
    '& > div': {
      flex: '1 0 50%',
      maxWidth: '48%',
      '&:nth-of-type(1)': {
        marginBottom: 30,
        '& > .MuiPaper-root' : {
          height: length > 2 &&'unset',
          margin: length > 2 && 0,
        },
      },
      '&:nth-of-type(2)': {
        marginBottom: 30,
        '& > .MuiPaper-root' : {
          height: 'unset',
          margin: 0,
        },
      },
    },
  },
  [theme.breakpoints.down(700)]: {
    width: 'unset',
  },
  [theme.breakpoints.down('sm')]: {
    '& > div': {
      flex: '1 0 100%',
      maxWidth: '100%',
      marginBottom: 30,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
}));

export const BannerContainer = styled('div')(({ theme }) => ({
  marginBottom: 40,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 20,
  },
}));

export const CaptionContainer = styled('div')(({ theme }) => ({
  marginTop: 40,
  [theme.breakpoints.down('sm')]: {
    marginTop: 20,
  },
}));

export const CaptionText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: colors.primaryGrey,
  marginRight: 5,
  display: 'inline',
}));
