import {useMediaQuery} from '@mui/material';
import {
  Container,
  MainContainer,
  BannerContainer,
} from './MainWrapper.styles';
import {adminExpandedRoutes} from '../../../options/navLinks';
import Banner from '../../Alerts/Banner/Banner';
import MainNavMenu from '../../NavMenus/MainNavMenu/MainNavMenu';
import MobileNavMenu from '../../NavMenus/MobileNavMenu/MobileNavMenu';
import AdminNavMenu from '../../NavMenus/AdminNavMenu/AdminNavMenu';
import PageSpinner from '../../Loading/PageSpinner/PageSpinner';

const MainWrapper = ({children, error, apiMessage, handleBannerClose, activeRoute, clubLogo, disableIndex, dataLoading, hasEliteLicence, hasRezzilPlayerLicence}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <>
      {
        matches &&
        <>
          <MainNavMenu
            activeRoute={activeRoute}
            clubLogo={clubLogo}
            disableIndex={disableIndex}
            hasEliteLicence={hasEliteLicence}
            hasRezzilPlayerLicence={hasRezzilPlayerLicence}
          />
          {
            adminExpandedRoutes.includes(activeRoute) &&
            <AdminNavMenu
              activeRoute={activeRoute}
            />
          }
        </>
      }
      {
        !matches &&
        <MobileNavMenu
          activeRoute={activeRoute}
          clubLogo={clubLogo}
          disableIndex={disableIndex}
          hasEliteLicence={hasEliteLicence}
          hasRezzilPlayerLicence={hasRezzilPlayerLicence}
        />
      }
      <Container
        admin={adminExpandedRoutes.includes(activeRoute) ? 'true' : undefined}
      >
        <MainContainer>
          {
            apiMessage &&
            <BannerContainer
              admin={adminExpandedRoutes.includes(activeRoute) ? 'true' : undefined}
            >
              <Banner
                onClose={handleBannerClose}
                open={!!apiMessage}
                text={apiMessage}
                isSuccess={!error}
                isFixed
                showClose
              />
            </BannerContainer>
          }
          {dataLoading ? <PageSpinner /> : children}
        </MainContainer>
      </Container>
    </>
  );
};

export default MainWrapper;
