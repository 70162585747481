import {
  styled,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import {colors} from '../../../options/colors';
import {KeyboardArrowDown} from '@mui/icons-material';

export const Container = styled(FormControl)(() => ({
  width: '100%',
}));

export const StyledSelect = styled(Select)(({ theme, value, error }) => ({
  ...theme.typography.body1,
  padding: 0,
  borderRadius: 5,
  color: value ? colors.white : colors.secondaryGrey,
  overflow: 'hidden',
  '.MuiOutlinedInput-input': {
    padding: '15px 40px 15px 20px !important',
    background: error ? colors.menuPrimaryBlack : 'transparent',
  },
  '.MuiOutlinedInput-notchedOutline': {
    padding: 0,
    borderColor: colors.secondaryGrey,
  },
  '&.Mui-focused': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: error ? `${colors.primaryRed} !important` : colors.secondaryGrey,
      borderWidth: 1,
    },
  },
  '&.Mui-disabled': {
    color: `${colors.disabledBlack} !important`,
    '.MuiSelect-icon': {
      color: `${colors.disabledBlack} !important`,
      right: 20
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: `${colors.disabledBlack} !important`,
      borderWidth: 1,
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.disabledBlack} !important`,
        borderWidth: 1,
      },
    },
  },
  '&:hover': {
    '.MuiOutlinedInput-notchedOutline': {
      padding: 0,
      borderColor: error ? colors.primaryRed : colors.secondaryGrey,
    },
  },
  '.MuiSelect-icon': {
    color: colors.secondaryGrey,
    right: 20
  },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.body1,
  '&.Mui-selected': {
    backgroundColor: `${colors.secondaryGrey} !important`,
    '&:hover': {
      backgroundColor: `${colors.secondaryGrey} !important`,
    }
  },
  '&:hover': {
    backgroundColor: `${colors.secondaryGrey} !important`,
  }
}));

export const SelectIcon = styled(KeyboardArrowDown)(() => ({}));

export const StyledColorIcon = styled('div')(({ color }) => ({
  height: 8,
  width: 8,
  borderRadius: 4,
  background: color,
  marginRight: 5,
}));
