import {
  styled,
  Tabs,
  Tab,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledTabs = styled(Tabs)(({ justify }) => ({
  width: justify ? '100%' : 'auto',
  '& .MuiTabs-flexContainer': {
    justifyContent: justify ? 'space-between' : 'flex-start',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: colors.secondaryOrange,
    height: 4,
    borderRadius: 5,
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  ...theme.typography.h3,
  color: colors.white,
  flexDirection: 'row',
  padding: '15px',
  minHeight: 'unset',
  minWidth: 'unset',
  marginRight: 15,
  '&:last-of-type': {
    marginRight: 0,
  },
  '&.Mui-selected': {
    color: colors.secondaryOrange,
  },
  '& .MuiSvgIcon-root': {
    marginBottom: 0,
    marginRight: 6,
  },
}));
