import {styled} from '@mui/material';

export const CardsContainer = styled('div')(({ theme, articles }) => ({
  display: articles ? 'grid' : 'flex',
  gridTemplateColumns: articles ? 'repeat(4, 1fr)' : undefined,
  gridGap: articles ? '20px 30px' : undefined,
  justifyContent: articles ? undefined : 'center',
  [theme.breakpoints.down(1100)]: {
    gridTemplateColumns: articles ? 'repeat(3, 1fr)' : undefined,
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: articles ? 'repeat(2, 1fr)' : undefined,
  },
  [theme.breakpoints.down(500)]: {
    gridTemplateColumns: articles ? '1fr' : undefined,
  },
}));
