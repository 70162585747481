import {
  CardGiftcardOutlined,
  RestoreOutlined,
  EventOutlined,
  VerifiedOutlined,
  ExtensionOutlined,
} from '@mui/icons-material';

export const indexStatsToggleOptions = [
  {
    id: 'rezzilIndex',
    icon: <CardGiftcardOutlined/>,
    title: 'Index',
    playerScores: [],
    worldScore: '',
  },
  {
    id: 'composure',
    icon: <RestoreOutlined/>,
    title: 'Composure',
    playerScores: [],
    worldScore: '',
  },
  {
    id: 'reaction',
    icon: <EventOutlined/>,
    title: 'Reaction',
    playerScores: [],
    worldScore: '',
  },
  {
    id: 'accuracy',
    icon: <VerifiedOutlined/>,
    title: 'Accuracy',
    playerScores: [],
    worldScore: '',
  },
  {
    id: 'adaptability',
    icon: <ExtensionOutlined/>,
    title: 'Adaptability',
    playerScores: [],
    worldScore: '',
  },
];
