import {
  CardsContainer,
} from './NewsCards.styles';
import SectionContainer from '../../Containers/SectionContainer/SectionContainer';
import NewsCard from '../../Cards/NewsCard/NewsCard';
import NoResultsText from '../../Typography/NoResultsText/NoResultsText';

// Helpers
import {formatNewsDateString} from '../../../utils/helpers/formatDateString';

const NewsCards = ({articles}) => {
  return (
    <SectionContainer
      cardTitle={'News'}
    >
      <CardsContainer
        articles={articles.length > 0 ? 'true' : undefined}
      >
        {
          articles.length > 0 && articles.map(({id, imageUrl, subject, created, link}) => (
              <NewsCard
                key={id}
                imageUrl={imageUrl}
                title={subject}
                date={formatNewsDateString(created)}
                link={link}
              />
          ))
        }
        {
          articles.length === 0 &&
          <NoResultsText
            text={'No articles'}
          />
        }
      </CardsContainer>
    </SectionContainer>
  );
};

export default NewsCards;
