import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const InnerContainer = styled('div')(() => ({
  padding: '30px 20px',
}));

export const StyledText = styled(Typography)(({ theme, success }) => ({
  ...theme.typography.body1,
  color: colors.primaryGrey,
  marginBottom: success ? 0 : 20,
}));

export const FieldContainer = styled('div')(() => ({
  marginBottom: 20,
}));
