import {styled, Typography} from '@mui/material';
import {colors} from '../../../options/colors';
import {TrendingUpOutlined, TrendingDownOutlined, TrendingFlatOutlined} from '@mui/icons-material';

export const Container = styled('div')(({ theme }) => ({
  marginRight: 25,
  [theme.breakpoints.down(750)]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 0,
    marginBottom: 10,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

export const StatName = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontSize: 18,
  color: colors.primaryGrey,
  lineHeight: 'unset',
  marginBottom: 15,
  [theme.breakpoints.down(750)]: {
    marginBottom: 0,
  },
}));

export const ScoreContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexWrap: 'nowrap',
}));

export const TrendingUpIcon = styled(TrendingUpOutlined)(() => ({
  color: colors.primaryGreen,
  marginRight: 7,
  transform: 'translateY(-3px)',
}));

export const TrendingDownIcon = styled(TrendingDownOutlined)(() => ({
  color: colors.primaryRed,
  marginRight: 7,
  transform: 'translateY(-3px)',
}));

export const TrendingFlatIcon = styled(TrendingFlatOutlined)(() => ({
  color: colors.secondaryOrange,
  marginRight: 7,
  transform: 'translateY(-3px)',
}));

export const ScoreText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1,
  fontSize: 26,
  marginRight: 5,
  lineHeight: 'unset',
}));

export const MaxScoreText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: colors.primaryGrey,
  lineHeight: 'unset',
  transform: 'translateY(-4px)',
}));
