import {
  styled,
  Link,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const SupportingText = styled('span')(({ theme }) => ({
  ...theme.typography.body1,
}));

export const StyledLink = styled(Link)(({ theme, mailto }) => ({
  ...theme.typography.body1,
  textDecoration: 'none',
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  color: mailto ? colors.primaryGreen : colors.primaryOrange,
  '&:hover': {
    color: colors.primaryGreen
  },
}));
