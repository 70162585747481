import {styled} from '@mui/material';

export const PlayerDropdownsContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  '@media print': {
    display: 'none',
  }
}));

export const SelectContainer = styled('div')(() => ({
  marginBottom: 15,
  marginRight: 30,
  width: 294,
}));

export const ButtonsContainer = styled('div')(() => ({
  marginBottom: 15,
  paddingTop: 6,
  '& button:first-of-type': {
    marginRight: 5,
  },
}));
