import {styled, Typography} from '@mui/material';
import {colors} from '../../../options/colors';

export const SectionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  '&#tablesWrapper > div': {
    marginBottom: 0,
    '& > .MuiPaper-root': {
      height: 'calc(100% - 80px)',
    },
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const ContainerWrapper = styled('div')(({ theme }) => ({
  width: '50%',
  marginBottom: 20,
  '&:first-of-type': {
    marginRight: 15,
  },
  '&:last-of-type': {
    marginLeft: 15,
  },
  '& > .MuiPaper-root': {
    height: 'calc(100% - 40px)',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '0 0 30px !important',
    '&#bestDrills': {
      margin: '0 !important',
    },
    '& > .MuiPaper-root': {
      height: 'auto',
    },
  },
}));

export const StatsWrapper = styled('div')(({ theme }) => ({
  paddingBottom: 40,
  display: 'flex',
  [theme.breakpoints.down(1200)]: {
    paddingBottom: 20,
    flexDirection: 'column-reverse',
    '&:first-of-type': {
      margin: '0 0 20px',
    },
    '&:last-of-type': {
      margin: 0,
    },
  },
}));

export const StatsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '&:first-of-type': {
    marginRight: 13,
  },
  '&:last-of-type': {
    marginLeft: 13,
  },
  [theme.breakpoints.down(1200)]: {
    flex: 'unset',
    width: '100%',
    '&:first-of-type': {
      margin: '0 0 20px',
    },
    '&:last-of-type': {
      margin: 0,
    },
  },
}));

export const DesktopAvatarContainer = styled('div')(({ theme }) => ({
  marginBottom: 20,
  [theme.breakpoints.down(1200)]: {
    display: 'none',
  },
}));

export const MobileAvatarContainer = styled('div')(({ theme }) => ({
  marginBottom: 20,
  [theme.breakpoints.up(1200)]: {
    display: 'none',
  },
}));

export const ChartContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  minWidth: 270,
  [theme.breakpoints.down(1200)]: {
    maxWidth: 300,
    minWidth: 300,
    margin: 'auto',
    '&#clubChartContainer': {
      marginBottom: 36,
    },
  },
  [theme.breakpoints.down('md')]: {
    '&#clubChartContainer': {
      marginBottom: 5,
    },
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: 'unset',
    minWidth: 'unset',
    width: '100%',
  },
}));

export const ChartLegendContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down(1200)]: {
    marginBottom: 20,
  },
}));

export const LegendLabelContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  '&:first-of-type': {
    marginRight: 20,
  },
}));

export const BestScoreIcon = styled('div')(() => ({
  height: 14,
  width: 14,
  borderRadius: '50%',
  background: colors.primaryGreen,
  marginRight: 5,
}));

export const AvgScoreIcon = styled('div')(() => ({
  height: 14,
  width: 14,
  borderRadius: '50%',
  background: colors.secondaryOrange,
  marginRight: 5,
}));

export const LegendText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: colors.primaryGrey,
  textTransform: 'uppercase',
  transform: 'translateY(1px)',
}));

export const AccordionWrapper = styled('div')(({ theme }) => ({
  paddingTop: 20,
  position: 'relative',
  marginTop: 'auto',
  '&::before': {
    content: '""',
    borderTop: `1px solid ${colors.dividerGrey}`,
    width: 'calc(100% + 40px)',
    position: 'absolute',
    top: 0,
    left: -20,
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: 0,
  },
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 40,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
