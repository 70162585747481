import {useState} from 'react';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledAccordionIcon,
  StyledAccordionLink,
} from './NavAccordion.styles';
import {
  indexLink,
  indexNavLinks,
  rezzilPlayerLink,
  rezzilPlayerNavLinks,
  indexExpandedRoutes,
  rezzilPlayerExpandedRoutes
} from '../../../options/navLinks';
import SubscriptionNavLink from '../../Links/SubscriptionNavLink/SubscriptionNavLink';
import { Link as RouterLink } from 'react-router-dom';

const NavAccordion = ({isRezzilPlayer, activeRoute, disabled, onlyShowHeader}) => {
  const [mainLink] = useState(isRezzilPlayer ? rezzilPlayerLink : indexLink);
  const [navLinks] = useState(isRezzilPlayer ? rezzilPlayerNavLinks : indexNavLinks);
  const [expandedRoutes] = useState(isRezzilPlayer ? rezzilPlayerExpandedRoutes : indexExpandedRoutes);

  return (
    <StyledAccordion
      expanded={expandedRoutes.includes(activeRoute)}
    >
      <StyledAccordionLink
        component={RouterLink}
        to={mainLink.isExternal ? { pathname: mainLink.route } : mainLink.route}
        target={mainLink.isExternal ? '_blank' : undefined}

      >
        <StyledAccordionSummary
          id={`${isRezzilPlayer ? 'rezzil-player' : 'index'}-nav-links`}
          expandIcon={<StyledAccordionIcon/>}
          index={isRezzilPlayer ? undefined : 'true'}
          expanded={!expandedRoutes.includes(activeRoute) ? 'true' : undefined}
        >
            {mainLink && mainLink.icon}
        </StyledAccordionSummary>
      </StyledAccordionLink>
      {!onlyShowHeader &&
        <StyledAccordionDetails
          index={isRezzilPlayer ? undefined : 'true'}
          disabled={disabled ? 'true' : undefined}
        >
          {
            navLinks && navLinks.map(({ icon, name, route, isExternal }) => (
              <SubscriptionNavLink
                key={name}
                icon={icon}
                name={name}
                route={route}
                isExternal={isExternal}
                active={activeRoute === route}
                disabled={disabled}
              />
            ))
          }
        </StyledAccordionDetails>
      }
    </StyledAccordion>
  );
};

export default NavAccordion;
