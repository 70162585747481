import {
  Container,
  FieldContainer,
  FormContainer,
  TwoStepContainer,
  TwoStepText,
} from './UserPasswordForm.styles';
import CustomTextField from '../../Inputs/CustomTextField/CustomTextField';
import CustomToggle from '../../Inputs/CustomToggle/CustomToggle';

const UserPasswordForm = ({formData, twoStepData, errors, onPasswordChange, onTwoStepChange}) => {
  return (
    <Container>
      <FormContainer>
        <FieldContainer>
          <CustomTextField
            label={'Update password'}
            id={'updatePassword'}
            placeholder={'********'}
            onChange={onPasswordChange}
            error={errors.updatePassword}
            type={'password'}
            autocomplete={'new-password'}
            value={formData.updatePassword}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Repeat password'}
            id={'confirmPassword'}
            placeholder={'********'}
            onChange={onPasswordChange}
            error={errors.confirmPassword}
            type={'password'}
            autocomplete={'new-password'}
            value={formData.confirmPassword}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Current password'}
            id={'oldPassword'}
              autocomplete={'password'}
            placeholder={'********'}
            onChange={onPasswordChange}
            error={errors.oldPassword}
            type={'password'}
            value={formData.oldPassword}
          />
        </FieldContainer>
        <FieldContainer>
          <TwoStepContainer>
            <TwoStepText>
              Enable 2-step
            </TwoStepText>
            <CustomToggle
              id={'twoStep'}
              checked={!!twoStepData.twoStep}
              onChange={onTwoStepChange}
            />
          </TwoStepContainer>
        </FieldContainer>
      </FormContainer>
    </Container>
  )
};

export default UserPasswordForm;
