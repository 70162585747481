import {styled} from '@mui/material';

export const UserProfileContainer = styled('div')(({ theme }) => ({
  marginBottom: 40,
  display: 'flex',
  justifyContent: 'space-between',
  '& > div': {
    margin: 0,
    minWidth: '220px',
    flex: 1,
    '&:first-of-type': {
      flex: 'unset',
      marginRight: 30,
    },
    '&:last-of-type': {
      maxWidth: '300px',
      marginLeft: 30,
    },
  },
  [theme.breakpoints.down(760)]: {
    flexDirection: 'column',
    '& > div': {
      maxWidth: 'unset !important',
      width: '100% !important',
      margin: '0 0 30px !important',
      '&:last-of-type': {
        marginBottom: '0 !important',
      },
    },
  },
}));

export const ClubProfileContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& > div': {
    margin: 0,
    minWidth: '220px',
    flex: 1,
    '&:first-of-type': {
      flex: 'unset',
      marginRight: 30,
    },
  },
  [theme.breakpoints.down(760)]: {
    flexDirection: 'column',
    '& > div': {
      maxWidth: 'unset !important',
      width: '100% !important',
      margin: '0 0 30px !important',
      '&:last-of-type': {
        marginBottom: '0 !important',
      },
    },
  },
}));
