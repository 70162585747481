import {
  styled,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledText = styled(Typography)(({ theme, success }) => ({
  ...theme.typography.h2,
  color: success ? colors.successDarkGreen : colors.primaryRed,
}));
