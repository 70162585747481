import {useState, useEffect} from 'react';
import {useMediaQuery} from '@mui/material';
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
  TitleContainer,
  Title,
  StatsContainer,
  PlayerStatsContainer,
  SingleStatContainer,
  PlayerColor,
  PlayerScore,
  WorldScore
} from './CustomToggleGroup.styles';

const CustomToggleGroup = ({options, value, onChange, singlePlayerScore, preventToggle}) => {
  // Dynamically set orientation based on breakpoint
  const matches = useMediaQuery((theme) => theme.breakpoints.up(750));
  // Set selected option to value, or the first option if no value
  const [alignment, setAlignment] = useState(value ? value : options[0]?.id);

  useEffect(() => setAlignment(value ? value : options[0]?.id), [value, options]);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment ? newAlignment : options[0]?.id);
    onChange(newAlignment ? newAlignment : options[0]?.id);
  };

  return (
    <StyledToggleButtonGroup
      value={preventToggle ? false : alignment}
      exclusive
      onChange={handleAlignment}
      orientation={matches ? 'horizontal' : 'vertical'}
    >
      {
        options && options.map(({id, icon, title, playerScores, worldScore}, idx) => (
          <StyledToggleButton
            key={`${id}-${idx + 1}`}
            value={id}
            options={options}
            matches={matches ? 'true' : undefined}
            disabled={preventToggle}
          >
            <TitleContainer>
              {icon}
              <Title>
                {title}
              </Title>
            </TitleContainer>
            <StatsContainer>
              <PlayerStatsContainer>
                {
                  singlePlayerScore &&
                  <SingleStatContainer
                    single='true'
                  >
                    <PlayerScore
                      single='true'
                    >
                      {playerScores[0] !== null && playerScores[0] !== undefined ? playerScores[0] : '--'}
                    </PlayerScore>
                  </SingleStatContainer>
                }
                {
                  !singlePlayerScore && playerScores && playerScores.map(({color, score}, idx) => (
                    <SingleStatContainer
                      key={`${color}-${idx + 1}`}
                    >
                      <PlayerColor
                        color={color}
                      />
                      <PlayerScore>
                        {score}
                      </PlayerScore>
                    </SingleStatContainer>
                  ))
                }
              </PlayerStatsContainer>
              <WorldScore>
                {worldScore}
              </WorldScore>
            </StatsContainer>
          </StyledToggleButton>
        ))
      }
    </StyledToggleButtonGroup>
  );
}

export default CustomToggleGroup;
