import { useEffect, useState, useCallback, createContext } from 'react';
import {routes} from '../../options/routes';

// API calls

const RevenueContext = createContext({});

const RevenueProvider = ({children}) => {
  const [revenueData, setRevenueData] = useState({
    loading: true,
    overview: null,
    breakdown: null,
    monthlyRevenue: null,
    apiError: false,
  });

  const getRevenueData = useCallback(async () => {
    // Only trigger API call for admin revenue route
    const activeRoute = window.location.pathname;
    if (activeRoute !== routes.revenue) return;

    // Call API - get payments and invoice data
    // const revenueApiData = await getRevenue();
    // if (!revenueApiData || !revenueApiData.success || !revenueApiData.length) {
    //   setRevenueData({
    //     loading: false,
    //     overview: null,
    //     breakdown: null,
    //     monthlyRevenue: null,
    //     apiError: true,
    //   });
    //   return;
    // }
    //
    // const invoicesData = await getInvoices();
    // if (!invoicesData || !invoicesData.success || !invoicesData.length) {
    //   setRevenueData({
    //     loading: false,
    //     overview: null,
    //     breakdown: null,
    //     monthlyRevenue: null,
    //     apiError: true,
    //   });
    //   return;
    // }
    //
    // // Set data
    // setRevenueData({
    //   loading: false,
    //   overview: revenueApiData,
    //   breakdown: revenueApiData,
    //   monthlyRevenue: revenueApiData,
    //   apiError: false,
    // });
    setRevenueData({
      loading: false,
      overview: null,
      breakdown: null,
      monthlyRevenue: null,
      apiError: false,
    });
  }, []);

  useEffect(() => {
    getRevenueData();
  }, [getRevenueData]);

  return (
    <RevenueContext.Provider
      value={{
        revenueData,
        getRevenueData
      }}
    >
      {children}
    </RevenueContext.Provider>
  );
}

export {RevenueProvider, RevenueContext};

export default RevenueProvider;
