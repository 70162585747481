import {
  Container,
} from './IndexBreakdownStatsList.styles';
import {indexBreakdownSummaryOptions} from '../../../options/indexBreakdownOptions';
import IndexBreakdownStatRow from '../../StatsPage/IndexBreakdownStatRow/IndexBreakdownStatRow';

const IndexBreakdownStatsList = ({data, statId, isAverage, rowWidth}) => {
  return (
    <Container>
      {
        data && statId &&
        <>
          <IndexBreakdownStatRow
            firstScoreLabel={'Score'}
            firstScoreValue={data.score}
            secondScoreLabel={'Highest Score'}
            secondScoreValue={data.highScore}
            isAverage={isAverage}
            rowWidth={rowWidth}
          />
          {
            statId !== 'averages' && indexBreakdownSummaryOptions[statId] &&
            <>
              <IndexBreakdownStatRow
                firstScoreLabel={indexBreakdownSummaryOptions[statId][0].title}
                firstScoreValue={data[indexBreakdownSummaryOptions[statId][0].stat]}
                secondScoreLabel={indexBreakdownSummaryOptions[statId][1].title}
                secondScoreValue={data[indexBreakdownSummaryOptions[statId][1].stat]}
                isAverage={isAverage}
                rowWidth={rowWidth}
              />
              <IndexBreakdownStatRow
                firstScoreLabel={indexBreakdownSummaryOptions[statId][2].title}
                firstScoreValue={data[indexBreakdownSummaryOptions[statId][2].stat]}
                secondScoreLabel={indexBreakdownSummaryOptions[statId][3].title}
                secondScoreValue={data[indexBreakdownSummaryOptions[statId][3].stat]}
                isAverage={isAverage}
                rowWidth={rowWidth}
              />
            </>
          }
        </>
      }
    </Container>
  );
};

export default IndexBreakdownStatsList;
