import {
  styled,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import {colors} from '../../../options/colors';

export const StyledCard = styled(Card)(() => ({
  padding: 20,
  background: 'rgba(0, 0, 0, 0.4)',
  borderRadius: 5,
  boxShadow: 'none',
  backdropFilter: 'blur(20px)',
}));

export const StyledCardContent = styled(CardContent)(() => ({
  padding: '0 !important',
  display: 'flex',
  alignItems: 'center',
}));

export const IconAvatarContainer = styled('div')(() => ({
  marginRight: 20,
}));

export const DetailsContainer = styled('div')(() => ({}));

export const CardTitle = styled(Typography)(() => ({
  fontFamily: 'GraphikMedium, sans-serif',
  fontSize: 18,
  lineHeight: '18px',
  margin: '0 0 8px',
  color: colors.primaryGrey,
}));

export const PriceText = styled(Typography)(() => ({
  fontFamily: 'GraphikMedium, sans-serif',
  fontSize: 26,
  lineHeight: '26px',
  margin: 0,
  color: colors.white,
}));
