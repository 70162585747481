import {
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from '@mui/material';
import {colors} from '../../../options/colors';
import {HeadingContainer} from '../../NavMenus/AdminNavMenu/AdminNavMenu.styles';

export const StyledAccordion = styled(Accordion)(() => ({
  borderRadius: '5px !important',
  boxShadow: 'none',
  overflow: 'hidden',
  background: 'rgba(255, 255, 255, 0.04) !important',
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  height: 50,
  background: 'transparent',
  borderRadius: '5px 5px 0 0',
  textAlign: 'center',
  padding: '0 20px',
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  background: 'transparent',
  borderRadius: '0 0 5px 5px',
  padding: 20,
}));

export const StyledAccordionLink = styled(Link)(() => ({
  fontFamily: 'GraphikMedium, sans-serif',
  fontSize: 18,
  margin: 0,
  textDecoration: 'none',
  color: colors.primaryGreen,
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    marginRight: 20,
    color: colors.primaryGreen,
  },
}));

export const AdminLinksContainer = styled('div')(() => ({
  marginBottom: 30,
}));

export const ManageLinksContainer = styled('div')(() => ({}));

export {
  HeadingContainer,
};
