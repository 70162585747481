import {
  TableWrapper,
  StyledTableContainer,
} from './CustomTableWrapper.styles';
import NoResultsText from '../../Typography/NoResultsText/NoResultsText';

const CustomTableWrapper = ({children, noResultsText, fullHeight}) => {
  return (
    <TableWrapper>
      <StyledTableContainer
        fullheight={fullHeight ? 'true' : undefined}
      >
        {children}
      </StyledTableContainer>
      {
        noResultsText &&
        <NoResultsText
          text={noResultsText}
        />
      }
    </TableWrapper>
  );
};

export default CustomTableWrapper;
