import {
  Container,
  InlineFieldContainer,
  PhoneFieldContainer,
  FieldContainer,
  RoleText,
} from './UserProfileForm.styles';
import CustomTextField from '../../Inputs/CustomTextField/CustomTextField';
import Label from '../../Typography/Label/Label';
import CustomAutocomplete from '../../Inputs/CustomAutocomplete/CustomAutocomplete';

// Helpers
import getSortedCountryCodes from '../../../utils/helpers/getSortedCountryCodes';

const UserProfileForm = ({formData, errors, onChange}) => {
  return (
    <Container>
      <InlineFieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'First name'}
            id={'firstname'}
            placeholder={'Joe'}
            onChange={onChange}
            error={errors.firstname}
            type={'text'}
            value={formData.firstname}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Last name'}
            id={'lastname'}
            placeholder={'Bloggs'}
            onChange={onChange}
            error={errors.lastname}
            type={'text'}
            value={formData.lastname}
          />
        </FieldContainer>
      </InlineFieldContainer>
      <PhoneFieldContainer>
        <FieldContainer>
          <CustomAutocomplete
            label={'Code'}
            id={'telephoneCountryCode'}
            placeholder={'44'}
            onChange={onChange}
            error={errors.telephoneCountryCode}
            value={formData.telephoneCountryCode}
            options={getSortedCountryCodes()}
            telephoneCountryCode
          />
        </FieldContainer>
        <FieldContainer>
          <CustomTextField
            label={'Phone'}
            id={'telephone'}
            placeholder={'eg. 7135684527'}
            onChange={onChange}
            error={errors.telephone}
            type={'text'}
            value={formData.telephone}
          />
        </FieldContainer>
      </PhoneFieldContainer>
      <FieldContainer>
        <CustomTextField
          label={'Email'}
          id={'email'}
          placeholder={'email@email.com'}
          onChange={onChange}
          error={errors.email}
          type={'email'}
          value={formData.email}
        />
      </FieldContainer>
      <FieldContainer
        id={'roleContainer'}
      >
        <Label
          text={'Role'}
        />
        <RoleText>
          {formData.role}
        </RoleText>
      </FieldContainer>
    </Container>
  )
};

export default UserProfileForm;
