import {useEffect, useContext, useState} from 'react';
import {
  SectionWrapper,
  ContainerWrapper,
} from './ClubLeaderBoards.styles';
import SectionSpinner from '../../../components/Loading/SectionSpinner/SectionSpinner';
import MainWrapper from '../../../components/Wrappers/MainWrapper/MainWrapper';
import Title from '../../../components/Typography/Title/Title';
import NoResultsText from '../../../components/Typography/NoResultsText/NoResultsText';
import PlayersDropdownSelects from '../../../components/StatsPage/PlayersDropdownSelects/PlayersDropdownSelects';
import SectionContainer from '../../../components/Containers/SectionContainer/SectionContainer';
import LeaderBoardList from '../../../components/Tables/LeaderBoardList/LeaderBoardList';

// Contexts & Helpers
import {BaseContext} from '../../../providers/Global/BaseProvider';
import {ClubLeaderBoardsContext} from '../../../providers/Index/ClubLeaderBoardsProvider';
import getAllLeaderBoardsData from '../../../utils/helpers/leaderboardsHelpers';
import {
  getPlayerDropdownOptions,
  getPlayerChoices,
  loggedInPlayerInChoices
} from '../../../utils/helpers/playerChoicesHelpers';
import duplicateExists from '../../../utils/helpers/duplicateExists';
import { routes } from '../../../options/routes';

const ClubLeaderBoards = () => {
  // Dynamically set orientation based on breakpoint
  const baseContext = useContext(BaseContext);
  const clubLeaderBoardsContext = useContext(ClubLeaderBoardsContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [playerDataLoading, setPlayerDataLoading] = useState(false);
  const [playerDropdownOptions, setPlayerDropdownOptions] = useState([]);
  const [playerChoices, setPlayerChoices] = useState([]);
  const [rezzilIndexLeaderBoardStats, setRezzilIndexLeaderBoardStats] = useState({});
  const [otherLeaderBoardStats, setOtherLeaderBoardStats] = useState({});
  const [clubLeaderBoardStats, setClubLeaderBoardStats] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    if (clubLeaderBoardsContext.clubLeaderBoardsData.allLoaded) {
      setDataLoading(false);
      if (clubLeaderBoardsContext.clubLeaderBoardsData.apiError) {
        setError({
          show: true,
          message: 'Unable to load some or all page content. Please refresh and try again.'
        });
      }

      // Get data from clubLeaderBoardsContext
      const {
        playerProfiles,
        rezzilIndexLeaderBoardStats,
        clubLeaderBoardStats,
      } = clubLeaderBoardsContext.clubLeaderBoardsData;

      // Set options for player dropdowns
      let options = getPlayerDropdownOptions(playerProfiles);
      setPlayerDropdownOptions(options);

      // Add logged in player id to playerChoices if they exist in options. If not, do not add any
      const {id} = baseContext.baseData.userProfile;
      const playerChoicesIds = [loggedInPlayerInChoices(id, options) ? id : ''];
      setPlayerChoices(playerChoicesIds);

      // Set leaderboard data
      let rezzilIndexLeaderBoard = {};
      let otherLeaderBoards = {};
      if (rezzilIndexLeaderBoardStats && rezzilIndexLeaderBoardStats.leaderboard) {
        rezzilIndexLeaderBoard = rezzilIndexLeaderBoardStats.leaderboard;
        setRezzilIndexLeaderBoardStats(rezzilIndexLeaderBoardStats.leaderboard);
      }
      if (clubLeaderBoardStats && Object.keys(clubLeaderBoardStats).length > 0) {
        otherLeaderBoards = clubLeaderBoardStats;
        setOtherLeaderBoardStats(clubLeaderBoardStats);
      }

      const allLeaderBoardData = getAllLeaderBoardsData(
        rezzilIndexLeaderBoard,
        otherLeaderBoards,
        playerChoicesIds,
      );
      setClubLeaderBoardStats(allLeaderBoardData);
    }
  }, [baseContext, clubLeaderBoardsContext]);

  const handleBannerClose = () => {
    setError({
      show: false,
      message: '',
    });
  };

  const handlePlayerChange = idx => async e => {
    // Remove any displayed banner errors
    handleBannerClose();

    const {id} = baseContext.baseData.userProfile;
    const rows = getPlayerChoices(playerChoices, idx, e.target.value, id);
    // Ensure duplicates do not exist
    if (duplicateExists(rows)) {
      setError({
        show: true,
        message: 'That player has already been selected',
      });
      return;
    }
    setPlayerChoices(rows);

    setPlayerDataLoading(true);

    // Set leaderboard data
    const allLeaderBoardData = getAllLeaderBoardsData(
      rezzilIndexLeaderBoardStats,
      otherLeaderBoardStats,
      rows,
    );
    setClubLeaderBoardStats(allLeaderBoardData);

    setPlayerDataLoading(false);
  };

  const handlePlayerAdd = () => {
    if (!playerChoices[playerChoices.length-1]) return;
    const row = '';
    setPlayerChoices([...playerChoices, row]);
  };

  const handlePlayerReset = () => {
    // Reset options and add logged in player id to playerChoices if they exist in playerDropdownOptions
    // If not, do not add any
    const {id} = baseContext.baseData.userProfile;
    const playerChoicesIds = [loggedInPlayerInChoices(id, playerDropdownOptions) ? id : ''];
    setPlayerChoices(playerChoicesIds);

    setPlayerDataLoading(true);

    // Set leaderboard data
    const allLeaderBoardData = getAllLeaderBoardsData(
      rezzilIndexLeaderBoardStats,
      otherLeaderBoardStats,
      playerChoicesIds,
    );
    setClubLeaderBoardStats(allLeaderBoardData);

    setPlayerDataLoading(false);
  };

  return (
    <>
      {
        <MainWrapper
          error={error.show}
          apiMessage={error.message}
          handleBannerClose={handleBannerClose}
          activeRoute={routes.indexClubLeaderBoards}
          clubLogo={baseContext.baseData.clubProfile.logo}
          dataLoading={dataLoading}
          hasEliteLicence={baseContext.baseData.hasEliteLicence}
          hasRezzilPlayerLicence={baseContext.baseData.hasRezzilPlayerLicence}
        >
          <Title
            text={'Club Leader Boards'}
          />
          {
            playerDataLoading &&
            <SectionSpinner/>
          }
          {
            !playerDataLoading && clubLeaderBoardStats.length === 0 &&
            <NoResultsText
              text={'No details to display'}
            />
          }
          {
            !playerDataLoading && clubLeaderBoardStats.length > 0 &&
            <>
              <PlayersDropdownSelects
                choices={playerChoices}
                dropdownOptions={playerDropdownOptions}
                handleChange={handlePlayerChange}
                handleAdd={handlePlayerAdd}
                handleReset={handlePlayerReset}
              />
              <SectionWrapper>
                {
                  clubLeaderBoardStats.map(({name, scores}) => (
                    <ContainerWrapper
                      key={name}
                      datalength={clubLeaderBoardStats.length}
                    >
                      <SectionContainer
                        sectionTitle={name}
                      >
                        <LeaderBoardList
                          data={scores}
                        />
                      </SectionContainer>
                    </ContainerWrapper>
                  ))
                }
              </SectionWrapper>
            </>
          }
        </MainWrapper>
      }
    </>
  );
};

export default ClubLeaderBoards;
