import {
  StyledCard,
  StyledCardContent,
  CardHeaderContainer,
  ImageContainer,
  TextContainer,
  StyledTitle,
  StyledDate,
  StyledBodyText,
} from './TwitterCard.styles';
import ClubLogoAvatar from '../../Avatars/ClubLogoAvatar/ClubLogoAvatar';
import DefaultTwitterAvatar from '../../../assets/svgs/rezzil-twitter-avatar.svg';

const TwitterCard = ({avatarUrl, tag, date, body}) => {
  return (
    <StyledCard>
      <StyledCardContent>
        <CardHeaderContainer>
          <ImageContainer>
            <ClubLogoAvatar
              clubLogo={avatarUrl ? avatarUrl : DefaultTwitterAvatar}
            />
          </ImageContainer>
          <TextContainer>
            <StyledTitle>
              @{tag}
            </StyledTitle>
            <StyledDate>
              {date}
            </StyledDate>
          </TextContainer>
        </CardHeaderContainer>
        <StyledBodyText>
          {body}
        </StyledBodyText>
      </StyledCardContent>
    </StyledCard>
  );
};

export default TwitterCard;
